<div class="alert-dismissible fade show" [ngClass]="messageClass" role="alert" *ngIf="message">
  <ng-container *ngIf="messageErrorSuggest">
    {{(message | translate: {properties: messageErrorSuggest} )}}
  </ng-container>
  <ng-container *ngIf="!messageErrorSuggest">
    {{(message | translate)}}
  </ng-container>
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="message = null">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="row align-items-center">
  <div class="col">
    <mh-spinner-classic *ngIf="loading()"></mh-spinner-classic>
  </div>
</div>

<ng-container *ngIf="!loading()">
  <form class="general_set_form" (ngSubmit)="saveData()" [formGroup]="profileForm" #formDir="ngForm">

    <div class="row align-items-center">
      <div class="{{is_super_admin ? 'col-3' : 'col-6'}} text-center">
        <mh-change-image
          [image]="user.profile_photo"
          [onlyCropp]="true"
          [ratio]="1"
          [inputStyle]="{width: '100px', height: '100px'}"
          modalSize='md'
          (changed)="imageChanged($event)"
        ></mh-change-image>
      </div>
      <div class="col-3">
        <div>
          Email: {{user.email}}
        </div>
        <div>
          <mat-slide-toggle *ngIf="is_super_admin || isAdmin" [id]="user.id" (change)="setUserType($event)" [checked]="user.flag_admin">
            {{'models.user.admin' | translate}}
          </mat-slide-toggle>
        </div>
        <div *ngIf="viewType === 'new' && is_super_admin">
          <mat-slide-toggle [id]="user.send_mail_new_user" (change)="setMailNewUser($event)"
            [checked]="user.send_mail_new_user">
            {{'models.user.email_confirm' | translate}}
          </mat-slide-toggle>
        </div>
      </div>
    </div>

    <div class="row">
      <ng-container *ngFor="let key of validates">
        <div class="{{is_super_admin ? 'col-4' : 'col-6'}}">
          <div class="form-group">
            <ng-container [ngSwitch]="key">
              <ng-container *ngSwitchCase="'language_id'">
                <ng-container
                  *ngTemplateOutlet="
                    dropdown;
                    context: {
                      key: key,
                      value: 'id',
                      options: languages,
                      labelTranslate: 'models.user_profile.language_id',
                      optionTranslate: 'languages.iso.'
                    }"
                >
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="['mobile_number','phone_number'].includes(key) && key">
                <ng-container *ngTemplateOutlet=" phone; context: { key: key }" >
                </ng-container>
              </ng-container>

              <ng-template [ngSwitchCase]="'role'">
                <ng-container
                  *ngTemplateOutlet="
                    dropdown;
                    context: {
                      key: key,
                      options: roles,
                      labelTranslate: 'models.user_profile.role',
                      optionTranslate: 'models.user_profile.roles.'
                    }"
                >
                </ng-container>
              </ng-template>

              <ng-template [ngSwitchCase]="'department'">
                <ng-container
                  *ngTemplateOutlet="
                    dropdown;
                    context: {
                      key: key,
                      options: departments,
                      labelTranslate: 'models.user_profile.department',
                      optionTranslate: 'models.user_profile.roles.'
                    }"
                  >
                </ng-container>
              </ng-template>

              <ng-container *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="input; context: {key: key}">
                </ng-container>
              </ng-container>
            </ng-container>

            <small class="alert-danger">
              <ng-container *ngIf="key=='confirm_password'">
                <div
                  [hidden]="profileForm.controls[key].valid || (profileForm.controls[key].pristine && !formDir.submitted)">
                  {{'forms.attributes.miss_match' | translate}}
                </div>
              </ng-container>
            </small>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row">
      <div class="col-9" *ngIf="isAdmin">
        <div class="row">
          <div class="col-6 col-sm-4">
            <div class="find_customer">
              <div class="inline">
                <label>{{'configuration.profile.chain.title' | translate:({type: (customerType | translate )}) }}</label>
              </div>
              <div class="inline">
                <mh-find-customer (customer)="addCustomer($event)"
                   [clientType]="'corporative'"
                   [showAllOption]="true"></mh-find-customer>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-5" *ngIf="is_super_admin">
            <div class="find_customer" *ngIf="is_super_admin">
              <div class="inline">
                <label>{{'configuration.profile.chain.all' | translate:({type: (customerType | translate )}) }}</label>
              </div>
              <div class="inline">
                <mh-find-customer (customer)="addCustomer($event)"
                   [clientType]="'client'"></mh-find-customer>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="list_customers">
          <ul class="tag_customers">
            <li class="tag" *ngFor="let customer of user.customers" [hidden]="customer.deleted">
              <span>{{customer.name}}<i class="far fa-times" (click)="deleteCustomer(customer)"></i></span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col button-col">
        <mh-spinner-classic *ngIf="loadingSave"></mh-spinner-classic>
        <div class="bottom" *ngIf="!loadingSave">
          <button type="submit"
            [disabled]="loadingSave || ['invited'].includes(currentUser.role)"
            class="btn btn-orange">{{'forms.save_changes' | translate}}</button>
          <label
            *ngIf="['invited'].includes(currentUser.role)">{{'errors.user.role_no_permission' | translate }}</label>
        </div>
      </div>
    </div>
  </form>

</ng-container>


<ng-template
  #phone
  let-key="key"
>
  <div class="row" [formGroup]="profileForm">
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-label>{{ 'models.user_profile.code' | translate }} </mat-label>
        <mat-select [formControlName]="key.split('_')[0] + '_country_code'">
          <mat-option *ngFor="let phone of phones" [value]="phone.code_phone">
            <b>{{ 'countries.' + phone.code2 | translate }}</b> +{{ phone.code_phone }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-8">
      <mat-form-field class="w-100">
        <input matInput type="number" class="mat-control" [formControlName]="key" [placeholder]="('models.user_profile.' + key | translate)" >
        <mat-hint *ngIf="profileForm.controls[key].errors && profileForm.controls[key].errors.required">
          *{{'forms.required' | translate}}
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
</ng-template>


<ng-template
  #dropdown
  let-key="key"
  let-options="options"
  let-labelTranslate="labelTranslate"
  let-optionTranslate="optionTranslate"
  let-value="value"
>
  <ng-container [formGroup]="profileForm">
    <mat-form-field class="w-100">
      <mat-label>{{ labelTranslate | translate }} </mat-label>
      <mat-select [formControlName]="key">
        <mat-option *ngFor="let option of options" [value]="value ? option[value] : option">
          {{ optionTranslate + option.id | translate }}
        </mat-option>
      </mat-select>
      <mat-hint *ngIf="profileForm.controls[key].errors && profileForm.controls[key].errors.required">
        <span class="required-label">
          *{{'forms.required' | translate}}
        </span>
      </mat-hint>
      <mat-error  *ngIf="profileForm.get(key).hasError('required')">
        {{'forms.attributes.not_empty' | translate}}
      </mat-error>
    </mat-form-field>
  </ng-container>
</ng-template>


<ng-template #input let-key="key">
  <ng-container [formGroup]="profileForm">
    <mat-form-field class="w-100" >
      <input matInput type="{{getInputType(key)}}" class="mat-control"  [formControlName]="key" [placeholder]="('models.user_profile.' + key | translate)" >
      <mat-hint *ngIf="profileForm.controls[key].errors && profileForm.controls[key].errors.required">
        <span class="required-label">
          *{{'forms.required' | translate}}
        </span>
      </mat-hint>
      <mat-hint *ngIf="key === 'pipedrive_id' && ![null, ''].includes(profileForm.controls[key].value)">
        <a href="{{PIPEDRIVE_URL + profileForm.controls[key].value}}" target="_blank">
          pipedrive link
        </a>
      </mat-hint>
      <mat-error  *ngIf="profileForm.get(key).hasError('required')">
        {{'forms.attributes.not_empty' | translate}}
      </mat-error>
      <mat-error *ngIf="profileForm.get(key).hasError('minlength')">
        {{'forms.attributes.min_lenght' | translate:({length: profileForm.controls[key].errors.minlength.requiredLength})}}
      </mat-error>
      <mat-error *ngIf="profileForm.get(key).hasError('maxlength')">
        {{'forms.attributes.max_lenght' | translate:({length: profileForm.controls[key].errors.maxlength.requiredLength})}}
      </mat-error>
      <mat-error *ngIf="key === 'email' && profileForm.get('email').hasError('email') && !profileForm.get('email').hasError('required')">
        {{ 'forms.attributes.email' | translate }}
      </mat-error>
    </mat-form-field>
  </ng-container>
</ng-template>
