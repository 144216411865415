import { Directive, ElementRef, HostListener, Input } from '@angular/core';

export enum TooltipPosition {
  ABOVE = 'above',
  BELOW = 'below',
  LEFT = 'left',
  RIGHT = 'right',
  DEFAULT = 'below'
}

enum WidthFit {
  FIT = 'fit-content'
}

@Directive({
  selector: '[mhTooltip]'
})

export class CoreLibTooltipDirective {
  @Input() mhTooltip = '';
  @Input() width: number | string = 285;
  @Input() alignText = 'left';
  @Input() position: TooltipPosition = TooltipPosition.DEFAULT;
  #_popup;

  constructor(private elref: ElementRef) { }

  ngOnDestroy(): void {
    if (this.#_popup) {
      this.#_popup.remove();
    };
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.mhTooltip) {
      this.createTooltipPopup();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.#_popup) {
      this.#_popup.remove();
    }
  }

  createTooltipPopup() {
    const {x, y} = this.setTooltipPosition();
    let popup = document.createElement('div');
    popup.innerHTML = this.mhTooltip;
    popup.setAttribute('class', `tooltip-container tooltip-container-${this.position}`);
    popup.style.top = y.toString() + 'px';
    popup.style.left = x.toString() + 'px';
    popup.style.width = ( this.width === WidthFit.FIT ) ?
      `${this.width}px` : WidthFit.FIT;
    popup.style.textAlign = this.alignText;
    document.body.appendChild(popup);
    this.#_popup = popup;
  }

  setTooltipPosition() {
    const { left, right, top, bottom } = this.elref.nativeElement.getBoundingClientRect();
    let x: number, y: number;

    switch(this.position) {
      case TooltipPosition.BELOW: {
        x = Math.round((right - left) / 2 + left);
        y = Math.round(bottom);
        break;
      }
      case TooltipPosition.ABOVE: {
        x = Math.round((right - left) / 2 + left);
        y = Math.round(top);
        break;
      }
      case TooltipPosition.RIGHT: {
        x = Math.round(right);
        y = Math.round(top + (bottom - top) / 2);
        break;
      }
      case TooltipPosition.LEFT: {
        x = Math.round(left);
        y = Math.round(top + (bottom - top) / 2);
        break;
      }
      default: {
        break;
      }
    }

    return {x, y};
  }
}
