<div *ngIf="datasets.length; else noData" style="display: block">
  <canvas baseChart width="550"
    [chartType]="'bar'"
    [datasets]="datasets"
    [labels]="labels"
    [colors]="COLORS"
    [options]="options"
    [legend]="true"
    (chartClick)="handleClick($event)">
  </canvas>
</div>

<ng-template #noData>
  <div>
    N.D.
  </div>
</ng-template>
