<div class="tw-relative tw-h-full">
  <div class="tw-w-full tw-text-center tw-py-2 tw-bg-main-text tw-relative">
    <span class="tw-font-semibold tw-text-lg tw-text-white">Estado PMS</span>
    <div class="tw-absolute tw-w-[16px] tw-h-[16px] tw-inset-y-0 tw-my-auto tw-right-[10px] tw-grid tw-place-content-center">
      <button class="focus:tw-outline-0" (click)="handleClose()">
        <i class="fas fa-times tw-text-xl tw-text-white"></i>
      </button>
    </div>
  </div>
  <div class="mt-3 tw-p-3">
    <p [innerHTML]="'header.tooltips.integration' | translate: {date: (lastUpdate | formatDate: currentLanguage)}"></p>
  </div>
</div>
