import { Injectable } from '@angular/core';

@Injectable()
export class HtmlHelper {
   constructor() {

   }

   imageStyle(url:string, style:string="cover"){
     return {
       'background' : `url('${url}') center no-repeat`,
       'background-size' : style
     }
   }

   colorLuminance(hex:string, lum:number):string{
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    let rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i*2,2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00"+c).substr(c.length);
    }

    return rgb;
  }

  getTableStyle(headerTable:any[],screenHeight, cellWidth=210){
    let width = (cellWidth)*headerTable.length;
    const height = screenHeight-253;
    return {
      'min-width': width >1000 ? width+'px' : '100%',
      'max-height' : (height>400 ? height : 400)+'px',
      'overflow-y' : 'auto'
    }
  }

  getTableAreaStyle(screenHeight){
    const height = screenHeight-248;
    return {
      width: '100%',
      'max-height' : (height>400 ? height : 400)+'px',
      'overflow-y' : 'auto'
    }
  }

  getTableContentStyle(headerTable:any[], widthCell = 210){
    let width = (widthCell)*headerTable.length;
    return {
      'min-width': width >1000 ? width+'px' : '100%'
    }
  }

}
