import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'widget-utils-arrow-variation',
  templateUrl: './arrow-variation.component.html',
  styleUrls: ['./arrow-variation.component.scss']
})
export class ArrowVariationComponent implements OnInit {

  @Input()
  number=0;

  @Input()
  height= 30;

  @Input()
  format: string;

  type;

  constructor(){}

  ngOnInit() {
    const direction = this.number > 0 ?  'up-green' : 'down-red';
    const inverseDirection = this.number > 0 ? 'up-red' : 'down-green';
    this.type = (this.format && ['exec_time', 'stagnant'].includes(this.format)) ? inverseDirection : direction;
    this.type = this.number === 0 || this.number===undefined || this.number === null ? 'none' : this.type;
  }

  style(){
    return {
      height: this.height+'px'
    }
  }



}
