import {Component, Output, EventEmitter} from '@angular/core';
import {UtilService} from "../../util.service";
import {Hotel} from '../../model/index';
import {CustomerService} from '../../../_services/customer.service';
import {plainToClass} from "class-transformer";

@Component({
  selector: 'mh-filter-competitors',
  templateUrl: './filter-competitors.component.html',
  styleUrls: ['./filter-competitors.component.scss'],
  providers:[CustomerService]
})
export class FilterCompetitorsComponent{

  @Output()
  competitor = new EventEmitter<Hotel>();

  competitors:Hotel[] = [];
  selected:number;
  waiting = true;
  util;



  constructor(private utilService:UtilService, private customerService:CustomerService) {
    this.util = this.utilService;
    this.utilService.isCustomerChanged().subscribe(hotel => {
      this.initFilter();
    });
  }

  ngOnInit(){
    this.initFilter();
  }

  initFilter(){
    this.competitors = [this.utilService.currentHotel];
    this.selected    = parseInt(this.utilService.currentHotel.id);
    this.util.setCurrentCustomerId(this.selected);
    this.selectCompetitor();
    this.findCompetitors();
  }

  findCompetitors(){
    this.waiting = true;
    this.customerService.getCompetitors(this.utilService.currentHotel.id).subscribe((response:any)=>{
      this.competitors = plainToClass(Hotel, response);
      if(this.competitors.length===0) this.competitors = [this.utilService.currentHotel];
      this.competitors = this.utilService.sortArray(this.competitors,'name');
      this.waiting = true;
    })
  }

  selectCompetitor(){
    this.utilService.ga('event','click','filter-competitor')
    let hotel = this.competitors.find(x=>parseInt(x.id) === this.selected);
    this.utilService.competitorsChanged(hotel);
    this.competitor.emit(hotel);
    this.util.setCurrentCustomerId(this.selected);

  }

}
