<mh-box-element [title]="('configuration.users.title' | translate )">
  <mh-wait-table *ngIf="waiting" [isHeavyQuery]="isHeavyQuery" [numberColumns]="5"></mh-wait-table>
  <mat-tab-group *ngIf="!waiting" [selectedIndex]="selectedTab" (selectedTabChange)="tabChanged($event)">
    <mat-tab label="{{'configuration.users.manager.title' | translate}}">
    </mat-tab>
    <mat-tab label="{{'configuration.users.entries_log.title' | translate}}">
      <mh-entries-log></mh-entries-log>
    </mat-tab>
  </mat-tab-group>
</mh-box-element>
