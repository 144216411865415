import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from "../../environments/environment";
import {plainToClass} from "class-transformer";
import { Hotel } from '../shared/model/index';
import { UtilService } from 'app/shared/util.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {DateService} from 'app/shared/services/date.service';

@Injectable()
export class ReportsService {

  segmentedBy = 'months';
  dateSubscribe: Subscription;


  constructor(
    private http:HttpClient,
    private utilService: UtilService,
    private dateService: DateService
  ) {

  }

  getReport(url: string, dates:any,customerId:string, language, productId=1, otherParams={}, chain:string="no",otaId: any=undefined){

    let params:HttpParams = new HttpParams();
    const chainIds = chain!=='no' ? (chain.split(',')).map(x=>{return parseInt(x)}) : [];
    const survey =  [1,2,4].includes(productId) ? this.utilService.getCurrentSurvey(chainIds) : null;
    this.segmentedBy = this.dateService.getSegmentation();

    if(survey && survey['chain']){
      chain = survey.chain;
    }

    params = params.set("start_date",dates.startDate);
    params = params.set("end_date",dates.endDate);

    params = params.set("language_id",language);
    params = params.set("product_id",''+productId);
    params = params.set("segmented_by",this.segmentedBy);

    if(chain!=="no"){
      params = params.set("customer_ids",chain);
      params = params.set("customers_ids",chain);
    }else{
      params = params.set("customer_id",customerId);
    }

    if(otaId){
      params = params.set("ota_id",otaId);
    }

    for(let key of Object.keys(otherParams)){
      params = params.set(key,otherParams[key]);
    }

    if(survey){
      params = params.set("surveys_ids", survey.ids);
    }


    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }

  getReportPrestay(url: string, dates:any, language, otherParams={}){
    let params:HttpParams = new HttpParams()
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("language_id",language);

    Object.entries(otherParams).forEach(([key, value]) => {
      params = params.set(key, value as string);
    });

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }

  summaryPrestay(dates:any, customerId:string, language:number, email=undefined) {
    const url = `${environment.reportPrestay}`+'/customers/'+customerId+'/summary';
    const params = {}
    if (email) {
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReportPrestay(url, dates, language, params);
  }

  checkinsPrestay(dates:any,customerId:string,language:number){
    const params = {
      'noEmail': true
    }
    const url = `${environment.reportPrestay}`+'/customers/'+customerId+'/checkins';
    return this.getReportPrestay(url, dates, language, params);
  }

  ordersPrestay(dates:any,customerId:string,language:number){
    const params = {
      'noEmail': true
    }
    const url = `${environment.reportPrestay}`+'/customers/'+customerId+'/orders';
    return this.getReportPrestay(url, dates, language, params);
  }



  summaryFollowup(dates:any,customerId:string,language:number, email=undefined, version = 1){
    const url = (version === 1)
      ? `${environment.reportManagementUrl}/followup/summary`
      : `${environment.reportsPath}followup/summary`;

    const params = {}
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 1, params);
  }

  summaryOnsite(dates:any,customerId:string,language:number, email=undefined, version = 1){
    const url = (version === 1)
      ? `${environment.reportManagementUrl}/followup/summary`
      : `${environment.reportsPath}followup/summary`;
    const params = {}
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 2, params);
  }



  pmsEmailFollowup(dates:any,customerId:string,language:number){
    const url = `${environment.reportManagementUrl}`+'/followup/'+'pms_emails';
    return this.getReport(url, dates,customerId, language, 1);
  }

  pmsEmailOnsite(dates:any,customerId:string,language:number){
    const params = {
      'noEmail': true
    };
    const url = `${environment.reportManagementUrl}`+'/followup/'+'pms_emails';
    return this.getReport(url, dates,customerId, language, 2, params);
  }

  questionsFollowup(dates:any,customerId:string,language:number, email=undefined, version = 1, surveyId){
    const url = (version === 1)
      ? `${environment.reportManagementUrl}/followup/questions`
      : `${environment.reportsPath}surveys/${surveyId}/answers`;
    const params = {}
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 1, params)
  }

  questionsOnsite(dates:any,customerId:string,language:number, email=undefined, version = 1, surveyId){
    const url = (version === 1)
      ? `${environment.reportManagementUrl}/followup/questions`
      : `${environment.reportsPath}surveys/${surveyId}/answers`;
    const params = {}
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 2, params)
  }

  guestsFollowup(dates:any,customerId:string,language:number, email=undefined, version = 1){
    const url = (version === 1)
      ? `${environment.reportManagementUrl}/followup/guests`
      : `${environment.reportsPath}surveys/guests`;
    const params = {}
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 1, params)
  }

  guestsOnSite(dates:any,customerId:string,language:number, email=undefined, version = 1){
    const url = (version === 1)
      ? `${environment.reportManagementUrl}/followup/guests`
      : `${environment.reportsPath}surveys/guests`;
      const params = {}
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 2, params)
  }

  trendsFollowup(dates:any,customerId:string,language:number, email:string = undefined, version = 1){
    const url = (version === 1)
      ? `${environment.reportManagementUrl}/followup/trends`
      : `${environment.reportsPath}followup/trends`;

    const params = {
      "segmented_by": this.segmentedBy
    };
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    };
    return this.getReport(url, dates,customerId, language, 1, params)
  }

  trendsOnsite(dates:any,customerId:string,language:number, email:string = undefined, version = 1){
    const url = (version === 1)
      ? `${environment.reportManagementUrl}/followup/trends`
      : `${environment.reportsPath}followup/trends`;

    const params = {
      "segmented_by": this.segmentedBy
    };
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    };
    return this.getReport(url, dates,customerId, language, 2, params)
  }

  trendsFollowupOther(dates:any,customerId:string,language:number){
    const customer = this.getCurrentHote();
    const chain = customer.id
    const url = `${environment.reportManagementUrl}`+'/'+'interchains/followup/trends';
    const params = {};

    return this.getReport(url, dates,customer.id, language, 1, params, chain)

  }

  summaryOnline(dates:any,customerId:string,language:number){
    const params = {
      'noEmail': true
    };
    const url = `${environment.reportManagementUrl}`+'/online/'+'summary';
    return this.getReport(url, dates,customerId, language, 3, params);
  }

  reviewsOnline(dates:any,customerId:string,language:number){
    const params = {
      'noEmail': true
    };
    const url = `${environment.reportManagementUrl}`+'/online/'+'reviews';
    return this.getReport(url, dates,customerId, language, 1, params)
  }

  rankingOtasOnline(dates:any,customerId:string,language:number){
    const params = {
      'noEmail': true
    };
    const url = `${environment.reportManagementUrl}`+'/online/'+'ranking_ota';
    return this.getReport(url, dates,customerId, language, 1, params)
  }

  demographyOnline(dates:any,customerId:string,language:number){
    const params = {
      'noEmail': true
    };
    const url = `${environment.reportManagementUrl}`+'/online/'+'demography';
    return this.getReport(url, dates,customerId, language, 1, params)
  }


  chainFollowupSummary(dates:any,customerId:string,language:number, chain:string, email=undefined, version = 1){
    const url = (version === 1)
      ? `${environment.reportsPath}reports/followup/interchains/summary`
      : `${environment.reportsPath}corporative/followup/properties`;

    const params = {}

    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }

    return this.getReport(url, dates,customerId, language, 1, params, chain)
  }

  chainFollowupTrends(dates:any,customerId:string,language:number, chain:string, email=undefined, version = 1){
    const url = (version === 1)
      ? `${environment.reportsPath}reports/followup/interchains/trends`
      : `${environment.reportsPath}corporative/followup/trends`;

    const params = {};
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 1, params, chain)
  }

  chainOnsiteFollowupTrends(dates:any,customerId:string,language:number, chain:string, email=undefined, version = 1){
    const url = (version === 1)
      ? `${environment.reportsPath}reports/followup/interchains/trends`
      : `${environment.reportsPath}corporative/followup/trends`;

    const params = {};
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 2, params, chain)
  }

  chainOnsiteFollowupSummary(dates:any,customerId:string,language:number, chain:string, email=undefined, version = 1){
    const url = (version === 1)
      ? `${environment.reportsPath}reports/followup/interchains/summary`
      : `${environment.reportsPath}corporative/followup/properties`;

    const params = {}

    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 2, params, chain)
  }

  chainOnlineSummary(dates:any,customerId:string,language:number, chain:string, email=undefined){
    const url = `${environment.reportsPath}reports/online/interchains/summary`;
    const params = {};
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 3, params, chain)
  }

  chainOnlineSummaryReview(dates, customerId, language, chain, email) {
    const url = `${environment.reportsPath}reports/online/interchains/reviews`;
    const params = {};
    if(email){
      params['is_async'] = true;
      params['email'] = email;
    } 
    return this.getReport(url, dates, customerId, language, 3, params, chain)
  }


  chainOnlineTripadvisor(dates:any,customerId:string,language:number, chain:string, email=undefined){
    const url = `${environment.reportsPath}reports/online/interchains/tripadvisor`;
    const params = {};
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 3, params, chain)
  }

  chainOnlineExecutive(dates:any,customerId:string,language:number, chain:string, email=undefined){
    const url = `${environment.reportsPath}reports/online/interchains/executive`;
    const params = {};
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 3, params, chain)
  }

  chainOnlineCoverageGeneral(dates:any,customerId:string,language:number, chain:string, email=undefined){
    const url = `${environment.reportsPath}reports/online/interchains/coverage/general`;
    const params = {};
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 3, params, chain)
  }

  chainOnlineCoverageRatings(dates:any,customerId:string,language:number, chain:string, email=undefined){
    const url = `${environment.reportsPath}reports/online/interchains/coverage/ratings`;
    const params = {};
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 3, params, chain)
  }

  chainOnlineCoverageCustomer(dates:any,customerId:string,language:number, chain:string, email=undefined){
    const url = `${environment.reportsPath}reports/online/interchains/coverage/customer`;
    const params = {};
    if(email){
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 3, params, chain)
  }

  chainCollectSummary(dates:any,customerId:string,language:number, chain:string, email=undefined){
    const params = {}
    if (email) {
      params['is_async'] = 'true';
      params['email'] = email;
    }
    const url = `${environment.reportsPath}reports/collect/interchains/summary`;
    return this.getReport(url, dates,customerId, language, 4, params, chain,1)
  }

  followupTripadvisorWidget(dates:any,customerId:string,language:number){
    const url = `${environment.reportManagementUrl}`+'/followup/summary/tripadvisor_widget';
    return this.getReport(url, dates,customerId, language, 1, {})
  }

  onsiteTripadvisorWidget(dates:any,customerId:string,language:number){
    const url = `${environment.reportManagementUrl}`+'/followup/summary/tripadvisor_widget';
    return this.getReport(url, dates,customerId, language, 2, {})
  }

  getCurrentHote():Hotel{
    const currentHotel: any = localStorage.getItem('currentHotel');
    const hotel = plainToClass<Hotel, Object>(Hotel, JSON.parse(currentHotel));
    return hotel;
  }

  casesPending(dates:any, customerId:any, language:number) {
    const params = {
      noEmail: true
    }
    const url = `${environment.reportsPath}cases/customers/${customerId}/cases/pending`;
    return this.getReport(url, dates, customerId, language, 1, params)
  }

  casesClosed(dates:any, customerId:any, language:number) {
    const params = {
      noEmail: true
    }
    const url = `${environment.reportsPath}cases/customers/${customerId}/cases/closed`;
    return this.getReport(url, dates, customerId, language, 1, params)
  }

  casesAssigned(dates:any, customerId:any, language:number) {
    const params = {
      noEmail: true
    }
    const url = `${environment.reportsPath}cases/customers/${customerId}/cases/assigned`;
    return this.getReport(url, dates, customerId, language, 1, params)
  }

  casesSummary(dates: any, customerId: any, language: number) {
    const params = {
      noEmail: true
    }
    const url = `${environment.reportsPath}cases/customers/${customerId}/cases/summary`;
    return this.getReport(url, dates, customerId, language, 1, params)

  }

  chainDeskSummary(dates, customerId, language, chain, email=null) {
    const url = `${environment.reportsPath}corporative/cases`;
    const params = {};
    if (email) {
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates,customerId, language, 6, params, chain);
  }

  responseRate(dates, language, surveyIds, email=null) {
    const url = `${environment.reportsPath}followup/customers/${this.customerId}/surveys/responses`;
    const params = {
      surveys_ids: surveyIds,
    };
    if (email) {
      params['is_async'] = 'true';
      params['email'] = email;
    }
    return this.getReport(url, dates, this.customerId, language, 1, params);
  }

  get customerId() { return this.utilService.currentHotel.id; }

}
