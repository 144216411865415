<ng-container *ngIf="typeNotification === 'new' else alertTemplate">
  <div
    class="tw-bg-white tw-p-3"
    [ngClass]="{
      '!tw-bg-cyan-600/20': data.notifications_status === 'UNREAD' && type === 'menu',
      '!tw-text-center': type === 'history'
    }"
  >
    <article class="tw-mb-2 tw-relative">
      <ng-container *ngIf="type === 'menu'">
        <ng-container *ngIf="data.notifications_status === 'UNREAD'">
          <section
            class="tw-mb-1 tw-flex tw-justify-between tw-items-center"
            [ngClass]="{ '!tw-justify-end': data.notifications_status !== 'UNREAD' }"
          >
            <div *ngIf="data.notifications_status === 'UNREAD'" class="tw-w-2 tw-h-2 tw-bg-cyan-600 tw-rounded-full"></div>
            <ng-container *ngIf="!['OMITTED', 'READ'].includes(data.notifications_status)">
              <ng-template [ngTemplateOutlet]="kebabMenu"></ng-template>
            </ng-container>
          </section>
        </ng-container>
        
      </ng-container>
  
      <section
        class="tw-flex tw-justify-between tw-items-center"
        [ngClass]="{ '!tw-justify-center': type === 'history' }"
      >
        <p class="tw-font-medium tw-text-base tw-text-gray-800 tw-max-w-[95%]">{{ data.translates[0].title }}</p>
      </section>
  
      <section class="tw-mt-1 tw-mb-2">
        <p class="tw-text-gray-500 tw-text-xs">
          {{
            data.time_ago_label !== 'moment'
              ? ('notifications.central.time'
                | translate : { ago: data.time_ago, label: ('notifications.central.' + data.time_ago_label | translate) })
              : ('notifications.central.short_time' | translate)
          }}
        </p>
      </section>
  
      <section class="mt-1 tw-flex tw-justify-center">
        <ng-container *ngIf="!data?.has_video">
          <img class="tw-max-h-60" [src]="data.translates[0].banner" [ngClass]="{ 'tw-mx-auto': type === 'history' }" />
        </ng-container>
      </section>
  
      <section class="tw-mt-4" [ngClass]="{ '!tw-mt-1': !data.banner }">
        <p class="tw-text-[13px] tw-text-gray-800">
          {{ data.translates[0].description }}
        </p>
      </section>
  
      <section class="tw-mt-4 tw-flex tw-justify-end" [ngClass]="{ '!tw-justify-center': type === 'history' }">
        <mh-core-lib-button
          [isDefault]="true"
          [text]="'commons.see_more' | translate"
          [customClass]="
            'tw-bg-product-corporative hover:tw-bg-transparent hover:tw-text-black hover:tw-border hover:tw-border-product-corporative'
          "
          [height]="32"
          [width]="140"
          (clicked)="handleClick(data?.notifications_type)"
        >
        </mh-core-lib-button>
      </section>
    </article>
  </div>
</ng-container>

<ng-template #alertTemplate>
  <div class="tw-relative">
    <ng-container *ngIf="!['OMITTED', 'READ'].includes(data.notifications_status) && type === 'menu'">
      <ng-template [ngTemplateOutlet]="kebabMenu"></ng-template>
    </ng-container>
    <div 
      class="tw-min-h-24 tw-border-b-[1px] tw-border-notification-border tw-px-6 tw-py-4"
      [ngClass]="{
        'tw-bg-notification-bg-unread hover:tw-bg-notification-bg-unread-hover': data.notifications_status === 'UNREAD',
        'tw-bg-notification-bg-read hover:tw-bg-notification-bg-read-hover': ['READ', 'OMITTED'].includes(data.notifications_status)}"
      (click)="handleClick()"
      >
        <div class="tw-flex tw-flex-col">
          <p class="tw-font-medium" [ngClass]="{
            'tw-text-notification-text': data.notifications_status === 'UNREAD',
            'tw-text-notification-unread-text': ['READ', 'OMITTED'].includes(data.notifications_status)}">{{ data.translates[0].title }}</p>
          <p class="tw-text-sm" [ngClass]="{
            'tw-text-notification-text': data.notifications_status === 'UNREAD',
            'tw-text-notification-unread-text': ['READ', 'OMITTED'].includes(data.notifications_status)}">{{ data.translates[0].description }}</p>
          <p class="tw-text-notification-text-time tw-text-xs tw-font-semibold">
            {{
              data.time_ago_label !== 'moment'
                ? ('notifications.central.time'
                  | translate : { ago: data.time_ago, label: ('notifications.central.' + data.time_ago_label | translate) })
                : ('notifications.central.short_time' | translate)
            }}
          </p>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #kebabMenu>
  <mh-core-lib-button
    class="tw-absolute tw-right-3 tw-top-3"
    [isAction]="true"
    (click)="openActionMenu = !openActionMenu"
    customClass="!tw-p-0 !tw-w-2 !tw-h-fit !tw-bg-transparent !tw-shadow-none"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    ><i class="fas fa-ellipsis-v tw-text-gray-500 tw-cursor-pointer"></i
  ></mh-core-lib-button>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="openActionMenu"
    [cdkConnectedOverlayOffsetY]="75"
    [cdkConnectedOverlayPanelClass]="'tw-mr-3'"
    (overlayBackdropClick)="openActionMenu = false"
    (overlayOutsideClick)="openActionMenu = false"
  >
    <ul class="tw-bg-white tw-rounded-lg tw-shadow-1 tw-relative tw-py-4 tw-px-5" id="overlayNotificationItem">
      <li class="tw-cursor-pointer" (click)="handleOmitted(data)">
        {{ 'notifications.central.mark_as_read' | translate }}
      </li>
    </ul>
  </ng-template>
</ng-template>
