import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class DemographyService {

  constructor(private http:HttpClient) { }

  public findDistributionReview(dates:any,customerId:string,languageId){
    const url=`${environment.onlineDistributionReview}`;
    const params:HttpParams= new HttpParams()
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("customer_id",customerId)
      .set("language_id",languageId);

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      )
  }

  public findDemographyReview(dates:any,customerId:string){
    const url=`${environment.onlineDemographyReview}`;
    const params:HttpParams= new HttpParams()
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("customer_id",customerId);

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      )
  }

  public reviewNpsScale(){
    const url=`${environment.onlineReviewsNpsScale}`;
    const params:HttpParams= new HttpParams();
    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      )
  }
}
