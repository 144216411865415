<div class="filter">
  <label class="order_by">{{'widgets.utils.sort-options.label' | translate }}</label>
  <form (ngSubmit)="sendData()" [formGroup]="controlForm" #formDir="ngForm">
    <div class="row align-items-center">
      <div class="col">
        <mat-radio-group formControlName="sort" >
          <mat-radio-button class="example-radio-button" *ngFor="let sort of sortTypes" [value]="sort">
            {{'commons.sort.'+sort | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-select  formControlName="filter">
            <mat-option *ngFor="let option of options"  [value]="option">{{(prefixTranslate+'.' + option)| translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>




  </form>
</div>
