import { Component, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { Hotel } from 'app/shared/model';
import { NgbDropdown, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

export class CustomerCheck{
  value:boolean=true;
  customer:Hotel;
  constructor(customer){
    this.customer = customer;
  }
}

@Component({
  selector: 'widget-filter-chain',
  templateUrl: './filter-chain.component.html',
  styleUrls: ['./filter-chain.component.scss']
})
export class FilterChainComponent implements OnChanges {

  @Input()
  customers:Hotel[] = [];

  @Output()
  actives = new EventEmitter<any[]>();

  @Output()
  customerFilter = new EventEmitter<any[]>();

  @Output()
  hideFilters = new EventEmitter<any[]>();

  @Input()
  filterProducts:string[]=[];

  @Input()
  viewType: 'normal' | 'button' = 'normal';

  customerActives:CustomerCheck[] = [];

  all:boolean=true;
  showFilter:boolean=false;

  @ViewChild(NgbDropdown, { static: false })
  dropdownButton: NgbDropdown;

  constructor(dropdownConfig: NgbDropdownConfig) {
    dropdownConfig.autoClose = false;
  }

  ngOnChanges() {

    this.customerActives = [];

    for(let customer of this.customers){
      if(this.filterProducts.length>0){
        for(let product of this.filterProducts){
          if(customer[product]) this.customerActives.push(new CustomerCheck(customer));
        }
      }else{
        this.customerActives.push(new CustomerCheck(customer));
        this.all = true;
      }
    }


  }

  setActives($event){
    this.all = false;
    this.emitChanges();
  }

  showAll($event){
    this.all = $event.checked;
    for (let i = 0; i < this.customerActives.length; ++i) {
      this.customerActives[i].value = !this.all && i === 0 ? true : this.all;
    }
    this.emitChanges();
  }

  emitChanges() {
    let actives = this.customerActives.filter((option) => option.value);
    if (actives.length === this.customerActives.length) this.all = true;
    if (actives.length === 0) {
      this.customerActives[0].value = true;
      actives = this.customerActives.filter((option) => option.value);
    }
    this.customerFilter.emit(actives.map((option) => option.customer));
    this.actives.emit(actives.map((option) => option.customer.id));
  }

  toggleFilters(){
    this.showFilter = !this.showFilter;
    this.hideFilters.emit();
  }

  closeDropdown(){
    this.dropdownButton.close();
  }

}
