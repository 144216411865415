import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'widget-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss']
})
export class ModalTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
