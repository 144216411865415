<div class="form-group" [formGroup]="form">
  <mat-form-field class="full_width">
    <mat-select formControlName="product" (selectionChange)="productChanged($event)" [placeholder]="('support.list_products' | translate)">
      <mat-option>{{'support.list_products' | translate}}</mat-option>
      <ng-container *ngFor="let product of products">
        <mat-option value="{{product}}">
          <span>{{'navbar.product.'+clientType+'.'+product | translate}}</span>
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>