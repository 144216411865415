<div *ngIf="!preview; else elseText">
  <mat-form-field class="w-100">
    <input matInput
      [disabled]="preview"
      [value]="getAnswer().answer_text || ''"
      (blur)="handleBlur($event)"
    />
  </mat-form-field>
</div>
<ng-template #elseText>
  <p class="raw-text">{{getAnswer().answer_text || ''}}</p>
</ng-template>
