import { Component } from '@angular/core';
import { UtilService } from 'app/shared/util.service';
@Component({
  selector: 'mobile-not-available',
  templateUrl: './mobile-not-available.component.html',
  styleUrls: ['../../../../../../assets/scss/shared/elements/mobile-not-available.scss'],
})
export class MobileNotAvailableComponent{

  constructor(private utilService:UtilService){}

  show(){
    return this.utilService.isMobile();
  }
}
