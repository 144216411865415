<button
  *ngIf="isPrimary"
  [disabled]="isDisabled"
  [style.width.px]="width"
  [style.height.px]="height"
  (click)="handleClick()"
  class="focus:tw-outline-0 tw-transition-all tw-text-base tw-font-medium tw-text-center tw-rounded-default-btn tw-px-3 tw-py-1 md:tw-py-8px md:tw-px-20px tw-text-fff tw-bg-main-blue hover:tw-bg-sky-600 active:tw-bg-sky-600 disabled:tw-bg-main-disabled disabled:tw-text-font-disabled {{customClass}}"
>
  <span [ngClass]="{'tw-flex tw-items-center tw-justify-center': hasImgIcon || emoji}" class="{{textClass}}">
    <span class="{{customIconClass}}" *ngIf="icon">
      <i class="{{icon}}"></i>
    </span>
    <span class="{{customEmojiClass}} emoji" *ngIf="emoji">
      {{ emoji }}
    </span>
    <img *ngIf="hasImgIcon" [src]="imgIconUrl" class="tw-w-5 tw-mr-2 tw-rounded-full">
    {{text}}
  </span>
  <ng-content select="[default-content]"></ng-content>
</button>

<button
  *ngIf="isDefault"
  [disabled]="isDisabled"
  [style.width.px]="width"
  [style.height.px]="height"
  (click)="handleClick()"
  class="focus:tw-outline-0 tw-transition-all tw-text-base tw-font-medium tw-text-center tw-rounded-default-btn tw-px-3 tw-py-1 md:tw-py-8px md:tw-px-20px tw-text-fff tw-bg-main-orange hover:tw-bg-main-orange-hover active:tw-bg-main-orange-hover disabled:tw-bg-main-disabled disabled:tw-text-font-disabled {{customClass}}"
>
  <ng-container *ngIf="!isDownloading else loader">
    <span [ngClass]="{'tw-flex tw-items-center tw-justify-center': hasImgIcon || emoji}" class="{{textClass}}">
      <span class="{{customIconClass}}" *ngIf="icon">
        <i class="{{icon}}"></i>
      </span>
      <span class="{{customEmojiClass}} emoji" *ngIf="emoji">
        {{ emoji }}
      </span>
      <img *ngIf="hasImgIcon" [src]="imgIconUrl" class="tw-w-5 tw-mr-2 tw-rounded-full">
      {{text}}
    </span>
  </ng-container>
</button>

<button
  *ngIf="isSecondary"
  [disabled]="isDisabled"
  [style.width.px]="width"
  [style.height.px]="height"
  (click)="handleClick()"
  class="focus:tw-outline-0 tw-transition-all tw-text-base tw-font-medium tw-text-center tw-rounded-default-btn tw-px-3 tw-py1 md:tw-py-8px md:tw-px-20px tw-border tw-text-button-secondary-default-text tw-bg-button-secondary-default-bg tw-border-button-secondary-default-border hover:tw-bg-button-secondary-hover-bg hover:tw-text-button-secondary-hover-text hover:tw-border-button-secondary-hover-border active:tw-bg-button-secondary-active-bg active:tw-text-button-secondary-active-text active:tw-border-button-secondary-active-border disabled:tw-bg-button-secondary-disabled-bg disabled:tw-text-button-secondary-disabled-text disabled:tw-border-button-secondary-disabled-border {{customClass}}"
>
<ng-container *ngIf="!isDownloading else loader">
  <span [ngClass]="{'tw-flex tw-items-center tw-justify-center': hasImgIcon || emoji}" class="{{textClass}}">
    <img *ngIf="hasImgIcon" [src]="imgIconUrl" class="tw-w-5 tw-mr-2 tw-rounded-full">
    <span class="{{customEmojiClass}} emoji" *ngIf="emoji">
      {{ emoji }}
    </span>
    {{text}}
    <span class="{{customIconClass}}" *ngIf="icon">
      <i class="{{icon}}"></i>
    </span>
  </span>

</ng-container>
</button>

<button
  *ngIf="isFeedback"
  [disabled]="isDisabled"
  [style.width.px]="width"
  [style.height.px]="height"
  (click)="handleClick()"
  class="focus:tw-outline-0 tw-transition-all tw-text-base tw-font-medium tw-text-center tw-rounded-default-btn tw-px-3 tw-py1 md:tw-py-8px md:tw-px-20px tw-border tw-text-button-secondary-default-bg tw-bg-button-secondary-default-text tw-border-button-secondary-default-border hover:tw-bg-button-secondary-hover-text hover:tw-text-button-secondary-hover-bg hover:tw-border-button-secondary-hover-border active:tw-bg-button-secondary-active-text active:tw-text-button-secondary-active-bg active:tw-border-button-secondary-active-border disabled:tw-bg-button-secondary-disabled-bg disabled:tw-text-button-secondary-disabled-text disabled:tw-border-button-secondary-disabled-border {{customClass}}"
>
<span [ngClass]="{'tw-flex tw-items-center tw-justify-center': hasImgIcon || emoji}" class="{{textClass}}">
  <img *ngIf="hasImgIcon" [src]="imgIconUrl" class="tw-w-5 tw-mr-2 tw-rounded-full">
  {{text}}
  <span class="{{customIconClass}}" *ngIf="icon">
    <i class="{{icon}}"></i>
  </span>
  <span class="{{customEmojiClass}} emoji" *ngIf="emoji">
    {{ emoji }}
  </span>
</span>
</button>

<button
  *ngIf="isAction"
  [disabled]="isDisabled"
  [style.width.px]="width"
  [style.height.px]="height"
  (click)="handleClick()"
  class="focus:tw-outline-0 tw-text-sm tw-font-medium tw-text-center tw-group tw-text-main-font tw-bg-fff tw-rounded-default-btn tw-py-13px tw-px-20px hover:tw-text-fff hover:tw-bg-action-hover active:tw-bg-main active:tw-text-fff disabled:tw-text-disabled-font disabled:tw-bg-main-disabled {{customClass}} focus:tw-outline-0"
>
<span class="tw-mr-10px tw-text-main group-hover:tw-text-fff group-active:tw-text-fff group-disabled:tw-text-disabled-font {{customIconClass}}" *ngIf="icon">
  <i class="fas fa-{{icon}}"></i>
</span>
<span class="{{customEmojiClass}} emoji" *ngIf="emoji">
  {{ emoji }}
</span>
<ng-content></ng-content>
</button>

<ng-template #loader>
  <mh-core-lib-loader-wrapper [type]="'spinner'"></mh-core-lib-loader-wrapper>
</ng-template>
