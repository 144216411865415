import { Component, Input } from '@angular/core';


@Component({
  selector: 'mh-spinner-classic',
  templateUrl: './spinner_classic.component.html',
  styleUrls: ['../../../../../../assets/scss/shared/elements/spinner_classic.scss'],
})
export class SpinnerClassicComponent{
  @Input() color:string = 'lightblue';

  constructor(){
  }
}
