import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, tap, catchError } from 'rxjs/operators';
import { throwError, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ProductsService } from 'app/_services/products.service';
import { Store } from '@ngxs/store';
import { ResetMenuState } from 'app/states/menus/menus.actions';

@Injectable()
export class AuthenticationService {
  private readonly CURRENT_JWT = 'currentJWT';
  jwtHelper: JwtHelperService = new JwtHelperService();
  private refreshTokenSubject = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private productsService: ProductsService,
    private store: Store,
  ) {}

  login(username: string, password: string) {
    const url = `${environment.loginPath}`;
    const data = {};
    data['username'] = username;
    data['password'] = Md5.hashStr(password);
    return this.http.post(url, data, { headers: { skip: 'true' } });
  }

  loginFromTokenId(userId: string, tokenId: string) {
    const url = `${environment.loginPath}/user/${userId}/token-id/${tokenId}`;
    return this.http.get<{ code: number; refreshToken: string; token: string }>(url);
  }

  isLogged(): boolean {
    const currentJWT: any = localStorage.getItem(this.CURRENT_JWT);
    const jwt = JSON.parse(currentJWT);
    let isLogged = false;
    if (jwt) {
      const token = jwt.token;
      isLogged = !this.jwtHelper.isTokenExpired(token);
    } else {
      this.logout();
      isLogged = false;
    }
    return isLogged;
  }

  refreshToken(token: string) {
    const url = `${environment.securityPath}refresh`;
    const body = {
      refresh_token: token,
    };

    this.refreshTokenSubject.next(true);

    return this.http.post<any>(url, body).pipe(
      tap((response) => {
        const jwt = response.token && response.refreshToken ? response : false;
        if (jwt) {
          localStorage.setItem(this.CURRENT_JWT, JSON.stringify(jwt));
        }
      }),
      catchError((error) => {
        localStorage.setItem(this.CURRENT_JWT, '');
        return throwError(error);
      }),
    );
  }

  getJWT() {
    const specialJWT = this.getSpecialJWT();
    if (specialJWT) return specialJWT;

    const currentJWT: any = localStorage.getItem(this.CURRENT_JWT);
    let jwt = currentJWT ? JSON.parse(currentJWT) : null;
    if (jwt === null) {
      jwt = localStorage.getItem('currentGuestToken');
      if (jwt) {
        jwt = { token: jwt };
      }
    }
    return jwt;
  }

  getSpecialJWT() {
    // right know, we coallesce (grab the first not-null) between the jwt's.
    // The logic should be, first, check if we need a guestJWT. Return it if so
    const currentUrl = this.route.snapshot['_routerState'].url;

    // survey tokens
    const surveyPublicRegex = /survey-public\/survey\/.+\?.*guest/;
    if (surveyPublicRegex.test(currentUrl)) {
      return { token: localStorage.getItem('currentSurveyGuestToken') };
    }
  }

  profile(userId) {
    const url = `${environment.customerProfile}`;
    const params: HttpParams = new HttpParams().set('user_id', userId);
    return this.http.get(url, { params: params });
  }

  fidelityConfiguration(customerId) {
    const url = `${environment.customerPath}fidelity/access/customers/${customerId}`;
    const params: HttpParams = new HttpParams();
    return this.http.get(url, { params: params }).pipe(
      map((response) => {
        return this.productsService.getProductsInLocalFormat(response);
      }),
    );
  }

  logout() {
    this.productsService.dataNavbar = null;
    const items = [
      'currentJWT',
      'currentUser',
      'currentHotel',
      'currentPrestayHotel',
      'currentGuestToken',
      'currentPrestayGuest',
      'scorePromoter',
      'scoreDetractor',
      'fidelityProperties',
      'currentGuestToken',
      'currentPrestayGuest',
      'currentPrestayHotel',
      'currentDateFilter',
      'alertClosed',
      'collect_hotel',
      'collect_guest_token',
      'cacheObjects',
      'currentSurvey',
      'currentFakeUser',
      'responseAlert',
      'chain-zone-selected-all',
      'chain-zone-selected-regions',
      'chain-zone-selected-brands',
      'currentAfterId',
    ];

    items.forEach((item) => {
      localStorage.removeItem(item);
    });

    this.store.dispatch(new ResetMenuState());
  }

  tokenRefreshed() {
    return this.refreshTokenSubject.asObservable();
  }
}
