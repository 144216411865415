import {Component, Input, EventEmitter, Output} from '@angular/core';
import {UtilService} from "../../../shared/util.service";
import {Money} from "../../model/index";
import {FilterCurrencyService} from  "./filter-currency.service";
import {plainToClass} from "class-transformer";

@Component({
  selector: 'mh-filter-currency',
  templateUrl: './filter-currency.component.html',
  styleUrls: ['../../../../assets/scss/shared/filters/filter-currency.scss'],
  providers:[FilterCurrencyService]
})


export class FilterCurrencyComponent {

  @Input()
  selected="USD";

  currencies: any = [];

  codes = [];
  waiting = true;

  @Output()
  currencyChanged=new EventEmitter<string>();

  constructor(private utilService:UtilService, private filterCurrencyService:FilterCurrencyService) {

  }

  ngOnInit(){
    this.getMoneys()
  }

  getMoneys(){
    this.waiting = true;
    this.filterCurrencyService.getMoneys().subscribe((response:any)=>{
      this.currencies = plainToClass(Money, response.currencies);
      this.utilService.currentCurrency = this.currencies.find(x=>x.code ===this.selected);
      this.waiting = false;
    })
  }


  setCurrency(item){
    this.selected = item.code;
    this.utilService.currentCurrency = item;
    this.currencyChanged.emit(item);
  }


}
