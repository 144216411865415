export class AnswerForm{
  id:number;
  guest_id:number;
  response_date:Date;
  details: AnswerDetail[] = []
}

export class AnswerDetail{
  id:number;
  input_id:number;
  answer: any;
}
