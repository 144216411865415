import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { v4 as uuid } from 'uuid';
import {SurveyAnswerService} from '../../survey-answer.service';

@Component({
  selector: 'mh-survey-question-comment',
  templateUrl: './survey-question-comment.component.html',
  styleUrls: ['./survey-question-comment.component.scss']
})
export class SurveyQuestionCommentComponent implements OnInit {
  @Input() questionId:number;
  @Input() answerId: number;
  @Input() alternatives:any[]
  @Input() answers:any[];
  @Input() language:string;
  @Input() languageId:number;
  @Input() surveyLanguageId:number;
  @Input() conditionalId:number;
  @Input() preview = false;
  @Input() productId = 1;
  @Input() answersComments;

  @Output() answer:EventEmitter<any> = new EventEmitter()

  answerTranslated: string;
  waitTranslate = false;
  isTranslatedPressed = false;

  constructor(
    private surveyAnswerService: SurveyAnswerService
  ) { }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    if (this.conditionalId && this.getAnswer().answer_text) {
      this.answer.emit(this.createAnswer());
    }
  }

  handleBlur(event:any) {
    const { target: { value } } = event

    this.answer.emit(this.createAnswer(value))
  }

  createAnswer(value?: string) {
    const prevAnswer = this.getAnswer();

    return {
      id: prevAnswer.id,
      tempId: prevAnswer.tempId || uuid(),
      question_id: this.questionId,
      answer_text: value,
      answer_alternative_id: this.alternatives[0].id,
      conditional_alternative_id: this.conditionalId,
    }
  }

  getAnswer() {
    return this.answers.find(({question_id, conditional_alternative_id}) =>
      question_id === this.questionId &&
        (!this.conditionalId || conditional_alternative_id === this.conditionalId)
    ) || {};
  }

  getAnswerIndex(): any {
    return this.answers.findIndex(({question_id, conditional_alternative_id}) =>
      question_id === this.questionId &&
        (!this.conditionalId || conditional_alternative_id === this.conditionalId)
    ) || null;
  }

  translate() {
    this.isTranslatedPressed = true;
    this.waitTranslate = true;
    const comment = this.getAnswer().answer_text;
    let commentId = this.answerId + this.getAnswerIndex();

    if (!commentId) {
      const questionId = this.getAnswer().question_id;

      if (this.answersComments) {
        const ansComment = this.answersComments?.find((ans) => ans.question_id === questionId);
        commentId = ansComment.comment_id;
      } else {
        commentId = this.conditionalId;
      }
    }

    const sourceLanguage = this.surveyLanguageId === this.languageId ? this.surveyLanguageId : 0;
    this.surveyAnswerService
      .translateComment(commentId, sourceLanguage, this.languageId, this.productId, '', comment)
      .subscribe((response: any) => {
        this.answerTranslated = response.description;
        this.waitTranslate = false;
      });
  }
}
