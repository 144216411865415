import {Component} from '@angular/core';
import {UtilService}    from "../../../../shared/util.service";
import {AppComponent} from "../../../../layouts/app/app-component";
import {CoverageService} from '../interchain-online-coverage.service';
import {
  PieData
}                         from '../../../../shared/model/index';
import {TranslateService} from '@ngx-translate/core';
import {ChartsDataSet} from "../../../../shared/utils/charts-data-set";
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';
import { Subscription } from 'rxjs/internal/Subscription';
import { DateService } from 'app/shared/services/date.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'mh-interchain-coverage-general',
  templateUrl: './interchain-online-coverage-general.component.html',
  styleUrls: ['../../../../../assets/scss/inter-chain/coverage-general.scss'],
  providers:[CoverageService,ChartsDataSet]
})


export class InterchainOnlineCoverageGeneral extends AppComponent{

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  initDate:string;
  chain;
  currentYear;

  prefixTranslate = "inter-chain.online.coverage.general.";
  translates;

  pieCharts: any[] = [];

  currentPeriod:any;
  previousPeriod:any;
  allData:any[]=[];


  public barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {
      display: false,
      onHover: function(e) {
        e.target.style.cursor = 'pointer';
      }
    },
    hover: {
      onHover: function(e) {
        let point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = 'pointer';
        else e.target.style.cursor = 'default';
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true
        }
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true
        }
      }]
    },



  };

  public pieOptions:{

  }

  public barChartType:string = 'bar';
  public barChartLegend:boolean = true;

  public barChartLabels:string[] = [];
  public barChartData:any[] = [];
  public lineChartColors:Array<any> = [];
  util;
  fidelityPermisions:string[] = [];
  customersStateSubscribe: Subscription;

  constructor(
    private utilService:UtilService,
    private coverageService:CoverageService,
    private translate: TranslateService,
    private chartsDataSet: ChartsDataSet,
    private store: Store,
    private dateService: DateService
  ){
    super()
    this.util = this.utilService;
    this.lineChartColors = this.chartsDataSet.lineChartColors();

    this.utilService.isCustomerChanged().subscribe(hotel => {
      if(this.utilService.currentUser.isSuperAdmin()) this.initGeneral();
    });
    this.customersStateSubscribe = this.customersReady$.subscribe(customers => this.loadData());
  }

  // events
  public chartClicked(e:any):void {

  }

  public chartHovered(e:any):void {

  }

  ngOnInit() {
    this.fidelityPermisions = this.utilService.getFidelityConfigurationsByProduct('interchain', 'interchain_online');
    if(this.utilService.currentHotel !== undefined && this.endDate !== undefined) this.initGeneral();
  }

  ngOnDestroy() {
    this.customersStateSubscribe.unsubscribe();
  }

  reset(){
    this.resetInterchain();
  }

  initGeneral(){
    let date = DateTime.fromISO(this.endDate);
    this.currentYear = DateTime.now().year || date.year;
    this.chain = this.utilService.getInnerChain(false,'online');
    let actives = this.chain.map(x=>x.id)
    this.reset();
    this.startChunkInterchain(actives,false)
    this.setWaiting(true);
    if(actives.length>0) this.loadData();
  }


  loadData(){
    this.allData = [];
    const customers = this.store.selectSnapshot(state => state.customers.relativeCurrentChain).map(c => c.id).join(',');

    if (customers.length) {
      this.coverageService.getGeneral({initDate:this.currentDates.startDate, endDate:this.currentDates.endDate},this.utilService.currentHotel.id,customers).subscribe((response:any)=>{
        this.nextChain();
        this.allData = response;
        this.translate.get([this.prefixTranslate+'period_answered',this.prefixTranslate+"period_not_answered",this.prefixTranslate+"previous",this.prefixTranslate+"actual",this.prefixTranslate+"total_reviews"]).subscribe((res: string) => {
          this.translates = res;
          this.setTooltips()
          this.setData(this.allData);
          this.setWaiting(false);
        })
      });
    }
  }

  setData(response){
    this.currentPeriod    = this.getDataPeriod(response,"period")
    this.previousPeriod   = this.getDataPeriod(response,"previous")
    this.barChartLabels   = response.map(x=>x.name_ota)
    this.pieCharts = []

    this.barChartData= [
      {
        data: this.currentPeriod.not_answered.data,
        label: this.translates[this.prefixTranslate+this.currentPeriod.answered.label],
        stack: 'stack_0'
      },
      {
        data: this.currentPeriod.answered.data,
        label: this.translates[this.prefixTranslate+this.currentPeriod.not_answered.label],
        stack: 'stack_0'
      },

      {
        data: this.previousPeriod.not_answered.data,
        label: this.translates[this.prefixTranslate+this.currentPeriod.answered.label],
        stack: 'stack_1'
      },
      {
        data: this.previousPeriod.answered.data,
        label: this.translates[this.prefixTranslate+this.currentPeriod.not_answered.label],
        stack: 'stack_1'
      },
    ];


    this.pieCharts.push([this.getPiePeriod(this.currentPeriod['totals'],'period_total',this.utilService.getNameFilterDate())])
    this.pieCharts.push([this.getPiePeriod(this.previousPeriod['totals'],'previous_total','')])

    this.pieCharts.push([this.getPiePeriod(this.currentPeriod['anual'],'period_anual',this.currentYear)])
    this.pieCharts.push([this.getPiePeriod(this.previousPeriod['anual'],'previous_anual',this.currentYear-1)])

  }

  setTooltips(){
    let self = this;
    this.barChartOptions['tooltips']= {
      enabled: true,
      displayColors: false,
      callbacks: {
        label: function (tooltipItems, data) {
          let dataResult = self.getTooltipData(tooltipItems, data);
          let total = dataResult["total"];
          return self.translates[self.prefixTranslate+"total_reviews"]+": "+total;
        },
        beforeLabel:function (tooltipItems, data){
          let dataResult = self.getTooltipData(tooltipItems, data);
          let state      = dataResult["state"];
          let current    = dataResult["current"]
          return self.translates[self.prefixTranslate+state]+": "+current;
        },
        afterLabel:function (tooltipItems, data){
          let dataResult = self.getTooltipData(tooltipItems, data);
          return dataResult["percent"]+"%";
        },
        title: function (tooltipItems){
          let index = tooltipItems[0].datasetIndex;
          let period = index < 2 ? "actual" : "previous";
          return self.translates[self.prefixTranslate+period]+" - "+tooltipItems[0].xLabel;
        },
        labelColor: function(tooltipItem, chart) {
          return {
            borderColor: 'rgb(255, 0, 0)',
            backgroundColor: 'rgb(255, 0, 0)'
          }
        }
      }
    }
  }

  getTooltipData(tooltipItems, data){
    let index         = tooltipItems.datasetIndex;
    let dataset       = data.datasets[index].data;
    let stackIndex    = index < 2 ? 0 : 1
    let stacks        = data.datasets.filter(x=>x.stack==="stack_"+stackIndex);
    let total_actual  = stacks[0].data
    let total_prev    = stacks[1].data
    let indexData     = data.labels.findIndex(x=>x===tooltipItems.xLabel);
    let current       = dataset[indexData];
    let total         = total_actual[indexData] + total_prev[indexData];
    let percent       = Math.round(current/total*100);
    let state         = [0,2].includes(index) ? "period_not_answered" : "period_answered";
    return {
      "state" : state,
      "current" : current,
      "total" : total,
      "percent" : percent
    }
  }

  getDataPeriod(activeOtas,period){
    let answered   = new DataChart(period+"_answered")
    let notAnswered = new DataChart(period+"_not_answered")
    let totals:PieData[] = []
    let anual:PieData[] = []
    for(let data of activeOtas){
      answered.data.push(data[period].answered)
      notAnswered.data.push(data[period].total - data[period].answered)
      let pieDataTotal:PieData = new PieData(data.name_ota,data[period].total, 'es', 0);
      totals.push(pieDataTotal)

      let pieDataAnual:PieData = new PieData(data.name_ota,data['total_reviews'+period], 'es', 0);
      anual.push(pieDataAnual)
    }
    return{
      "answered" : answered,
      "not_answered" : notAnswered,
      "totals" : totals,
      "anual" : anual
    }
  }

  getPiePeriod(activeOtas,key,subTitle){

    let objectPieData         = this.chartsDataSet.getPieData(activeOtas,'es' , true, 'normal')
    objectPieData['title']    = this.prefixTranslate+"pies."+key;
    objectPieData['subTitle'] = ''+subTitle;

    return objectPieData;

  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates)
    this.initGeneral();
    this.loadData();
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get startDate() {
    return this.currentDates.startDate;
  }

  get endDate() {
    return this.currentDates.endDate;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }
}

export class Period{
  period:DataChart;
  previuos:DataChart;
  constructor(){
    this.period = new DataChart("")
    this.previuos = new DataChart("");
  }
}


export class DataChart{
  data:number[] = [];
  label:string;
  constructor(label){
    this.label = label
  }
}


