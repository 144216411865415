<div class="list-group">

  <div class="list-group-item list-group-item-action">
    <h5 class="menu-title">{{userName}}</h5>
    <mh-core-lib-actions-wrapper
        slug="user_settings_personal_edit"
      >
      <a href="javascript:void(0)" class="menu-item" mat-menu-item (click)="navigateToProfile(); emitCloseMenu()">
        <div class="d-inline-flex">
          <h6 class="ml-2">
            {{('new-menu.user.personal_adjusts' | translate)}}
          </h6>
        </div>
      </a>
    </mh-core-lib-actions-wrapper>
    <mh-core-lib-actions-wrapper
        slug="user_settings_alert_edit"
      >
      <a *ngIf="!isMobile" href="javascript:void(0)" class="menu-item" mat-menu-item (click)="navigateToProfile('configuration'); emitCloseMenu()">
        <div class="d-inline-flex">
          <h6 class="ml-2">
            {{('new-menu.user.alerts_reports' | translate)}}
          </h6>
        </div>
      </a>
    </mh-core-lib-actions-wrapper>
  </div>

  <div class="splitter"></div>

  <div *ngIf="!isMobile" class="list-group-item list-group-item-action no-padding-bottom">
    <h5 class="menu-title">{{customerName}}</h5>
    <a href="javascript:void(0)" class="menu-item" mat-menu-item (click)="emitGa('option users'); emitCloseMenu()" [routerLink]="menuState['users_management'].link" *ngIf="allowedMenus.includes('users_management')">
      <div class="d-inline-flex">
        <h6 class="ml-1">
          {{('new-menu.customer.user_management' | translate)}}
        </h6>
      </div>
    </a>

    <a href="javascript:void(0)" class="menu-item" mat-menu-item (click)="emitCloseMenu()" *ngIf="allowedMenus.includes('company_adjustments')"
      [routerLink]="menuState['company_adjustments'].link">
      <div class="d-inline-flex">
        <h6 class="ml-2">
          {{('new-menu.customer.company_adjusts' | translate)}}
        </h6>
      </div>

    </a>

    <a href="javascript:void(0)" class="menu-item" mat-menu-item (click)="emitCloseMenu()" [routerLink]="menuState['changes_audit'].link" *ngIf="allowedMenus.includes('changes_audit')">
      <div class="d-inline-flex">
        <h6 class="ml-2">
          {{('new-menu.customer.logs' | translate)}}
        </h6>
      </div>
    </a>

    <a href="javascript:void(0)" class="menu-item" mat-menu-item (click)="emitCloseMenu(); setStatusNavbar(1)" *ngIf="allowedMenus.includes('products_configuration')">
      <div class="d-inline-flex">
        <h6 class="ml-2">
          {{('new-menu.customer.products_config' | translate)}}
        </h6>
      </div>
    </a>

    <a href="javascript:void(0)" mat-menu-item (click)="emitCloseMenu(); setStatusNavbar(2);" *ngIf="allowedMenus.includes('managers')"
      class="center menu-item menu-title">
      {{('new-menu.managers.title' | translate)}}
    </a>

  </div>

  <div class="list-group-item list-group-item-action no-padding-top">
    <a href="javascript:void(0)" mat-menu-item (click)="goToIntegrations()" *ngIf="allowedMenus.includes('integrations')"
      class="center menu-item menu-title">
      {{ 'online.integrations.title' | translate }}
    </a>

    <a *ngIf="!isMobile && allowedMenus.includes('invoices')" href="javascript:void(0)" mat-menu-item [routerLink]="menuState['invoices'].link"
      class="center menu-item menu-title">
      {{ 'new-menu.invoices.title' | translate }}
    </a>
  </div>

  <div *ngIf="isMobile" class="list-group-item list-group-item-action">
    <h5 class="menu-title">{{'new-menu.options.help' | translate}}</h5>
    <a href="javascript:void(0)" #triggerMenu="matMenuTrigger" [matMenuTriggerFor]="support" class="menu-item" mat-menu-item>
      <div class="d-inline-flex">
        <h6 class="ml-1">
          {{('navbar.product.default.support' | translate)}}
        </h6>
      </div>
    </a>
    <a class="menu-item" mat-menu-item [href]="tutorialLinks[currentLanguageCode]" target="_blank" (click)="emitCloseMenu()">
      <div class="d-inline-flex">
        <h6 class="ml-1">
          {{('new-menu.options.demo' | translate)}}
        </h6>
      </div>
    </a>
  </div>

  <div class="splitter"></div>

  <div class="list-group-item list-group-item-action !tw-py-0">
    <a href="javascript:void(0)" class="menu-item menu-title !tw-text-sky-500" mat-menu-item (click)="emitGa('option logout'); setStatusNavbar(0)" routerLink="/logout">
      {{('header.logout' | translate)}}
    </a>
  </div>

  

  <div class="list-group-item list-group-item-action force-desktop" *ngIf="isMobile">
    <a href="javascript:void(0)" class="menu-item" mat-menu-item (click)="toggleDesktopMobile()">
      {{('new-menu.options.desktop' | translate)}}
    </a>
  </div>

  <div class="tw-left-0 tw-bottom-1 tw-w-full tw-text-right tw-text-gray-500 tw-text-[10px] list-group-item pt-2">
    {{ 'commons.version' | translate:({version:version}) }}
  </div>
</div>

<mat-menu #support="matMenu" xPosition="before">
  <a href="javascript:void(0)" routerLink="/soporte" mat-menu-item class="flex-row-reverse d-flex" (click)="emitCloseMenu()">
    <span class="menu-item">{{ 'support.menu-header.create' | translate }}</span>
  </a>
  <a href="https://www.myhotel.cl/capacitaciones/?utm_source=Dashboard&utm_medium=Fidelity&utm_campaign=productos" target="_blank" mat-menu-item *ngIf="!isRetail" class="flex-row-reverse d-flex" (click)="emitCloseMenu()">
    <span class="menu-item">{{ 'support.menu-header.schedule' | translate}}</span>
  </a>
  <a [href]="tutorialLinks[currentLanguageCode]" target="_blank" mat-menu-item class="flex-row-reverse d-flex" (click)="emitCloseMenu()">
    <span class="menu-item">{{ 'support.menu-header.demos' | translate}}</span>
  </a>
</mat-menu>
