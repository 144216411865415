import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'widget-wrapper-wait',
  templateUrl: './wrapper-wait.component.html',
  styleUrls: ['./wrapper-wait.component.scss']
})
export class WrapperWaitComponent implements OnInit {

  @Input()
  width = 5;

  @Input()
  height = 5;

  @Input()
  radius = 30;

  @Input()
  drawTop:number[] = [0,0,0];

  @Input()
  drawMiddle:number[] = [0,0,0];

  @Input()
  drawBottom:number[] = [0,0,0];

  rows = ['TOP', 'MIDDLE', 'BOTTOM'];
  colsDefault = [12, 6, 4, 3, 2, 1];
  col;

  constructor() { }

  ngOnInit() {
    this.setCols();
  }

  setCols() {
    const untilIterator = (this.colsDefault.includes(this.width)) ? this.width : 3;
    if(this.drawTop.length === 0)
      for(let i = 0; i < untilIterator; i++) {
        this.drawTop[i] = 1;
      }
    if(this.drawMiddle.length === 0)
      for(let i = 0; i < untilIterator; i++) {
        this.drawMiddle[i] = 1;
      }
    if(this.drawBottom.length === 0)
      for(let i = 0; i < untilIterator; i++) {
        this.drawBottom[i] = 1;
      }
    this.col = `col-${12/untilIterator}`;
  }

  get style(){
    return {
      'height': `${this.height}px`,
      'border-radius': `${this.radius}px`
    }
  }

}
