//Sections
export {FollowupExecutiveSectionComponent} from './sections/followup/executive/followup-executive.component';
export {InterchainSectionsComponent} from './sections/interchain-sections.component';
export {SummarySectionComponent} from './sections/followup/summary/summary-section.component';
export {TrendsSectionComponent} from './sections/followup/trends/trends-section.component';
export {OnlineCoverageSectionsComponent} from './sections/online/coverage/online-covarage-section.component';
export {OnlineSummarySectionsComponent} from './sections/online/summary/online-summary-section.component';
export {OnlineTripadvisorSectionsComponent} from './sections/online/tripadvisor/online-tripadvisor-section.component';
export {SummaryCollectSectionComponent} from './sections/collect/summary/summary-collect-section.component';
export {OnlineExecutiveSectionsComponent } from './sections/online/executive/online-executive-section.component';
export {SummaryOnsiteSectionComponent} from './sections/onsite/summary/summary-onsite-section.component';
export {TrendsOnsiteSectionComponent} from './sections/onsite/trends/trends-onsite-section.component';
export { PrestaySectionSummaryComponent } from './sections/prestay-section/prestay-section-summary/prestay-section-summary.component';
export {PrestaySectionTrendsComponent} from './sections/prestay-section/prestay-section-trends/prestay-section-trends.component';
//Components

export { TrendsComponent } from './followup/trends/trends.component';
export { InterchainOnlineCoverageRanking } from './online/coverage/ranking/interchain-online-coverage-ranking.component';
export { InterchainOnlineCoverageHotel } from './online/coverage/hotel/interchain-online-coverage-hotel.component';
export { InterchainOnlineCoverageGeneral } from './online/coverage/general/interchain-online-coverage-general.component';
export { TripadvisorComponent } from './online/tripadvisor/interchain-online-tripadvisor.compontent';
export { OnlineSummaryComponent } from './online/summary/interchain-online-summary.compontent';
export { SummaryCollectComponent } from './collect/summary/summary-collect.component';
export { PrestaySummaryComponent } from './prestay/prestay-summary/prestay-summary.component';
export { PrestayTrendsComponent } from './prestay/prestay-trends/prestay-trends.component';
