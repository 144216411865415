import { Component, ElementRef, HostListener, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mh-hotel-search-accesses',
  templateUrl: './hotel-search-accesses.component.html',
  styleUrls: ['./hotel-search-accesses.component.scss']
})
export class HotelSearchAccessesComponent implements OnInit, OnDestroy {
  hotelsControl = new FormControl('');
  showHotels = false;
  hotelsFiltered = [];


  @Output() customersUpdated = new EventEmitter();

  #customers = [];
  #hotelsSelected = [];

  @Input() placeholder = 'add_property';

  @Input() set customers(value) {
    this.#customers = value;
    this.hotelsFiltered = this.#customers;
  }

  get customers() {
    return this.#customers
  }

  @Input() set hotelsSelected(value) {
    this.#hotelsSelected = value;
  }

  get hotelsSelected() {
    return this.#hotelsSelected
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.showHotels = false;
    }
  }

  subscription: Subscription;

  constructor(private eRef: ElementRef,) { }

  ngOnInit(): void {
    this.hotelsFiltered = this.customers;
    this.subscription = this.hotelsControl.valueChanges.subscribe((value) => {
      if (value.length >= 0) {
        this.hotelsFiltered = this.customers.filter((customer) => customer?.name?.toLowerCase().includes(value.toLowerCase()));
      } else {
        this.hotelsFiltered = this.customers;
      }
    })
  }

  addHotel(hotel) {
    this.hotelsSelected.push(hotel);
    this.customersUpdated.emit(this.hotelsSelected);
    this.showHotels = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
