import { AbstractControl } from '@angular/forms';

export class CustomValidators {
  static email(control: AbstractControl) {
    const { value } = control;
    const EMAIL_REGEXP = /^[a-z0-9+._-]+@[a-z0-9.-]+\.[a-z]{2,64}$/i;
    return (control.value !== "" && !EMAIL_REGEXP.test(value)) ?
      { "email": true } :
      null;
  }

  static isObject(control: AbstractControl) {
    const { value } = control;
    return (typeof value !== 'object') ?
      { "isObjectError": true} :
      null;
  }
}
