import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';

@Component({
  selector: 'mh-origin-modal',
  templateUrl: './origin-modal.component.html',
  styleUrls: ['./origin-modal.component.scss'],
})
export class OriginModalComponent extends CoreLibModalComponent implements OnInit, OnDestroy {

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }
}
