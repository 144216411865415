import { Component, OnInit, Input } from '@angular/core';

const MIN_WIDTH = 500;

@Component({
  selector: 'mh-core-lib-header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.scss']
})
export class CoreLibHeaderContentComponent implements OnInit {

  @Input()
  title: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  get isMobile() {
    return window.innerWidth <= MIN_WIDTH;
  }

}
