<div class="percentage_bar">
  <div class="box_number positive" [ngbTooltip]="('shared.semantic.mentions.tooltips.positive' | translate:{count:positiveLabel} )" container="body" [style.width]="positivePercentage+'%'">
    <div class="number">{{positivePercentage}}%</div>
  </div>

  <div class="box_number neutral" [ngbTooltip]="('shared.semantic.mentions.tooltips.neutral' | translate:{count:neutralLabel} )" container="body" [style.width]="neutralPercentage+'%'">
    <div class="number">{{neutralPercentage}}%</div>
  </div>

  <div class="box_number negative" [ngbTooltip]="('shared.semantic.mentions.tooltips.negative' | translate:{count:negativeLabel} )" container="body" [style.width]="negativePercentage+'%'">
    <div class="number">{{negativePercentage}}%</div>
  </div>
</div>