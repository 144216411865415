import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fidelityDeskAssigned'
})
export class FidelityDeskAssignedPipe implements PipeTransform {

  transform(cases, filters) {
    let filteredCases = cases;
    if(filters) {
      const filter = filters.find(filter => filter.name === 'assigned');
      const filtering = filter.options.some(option => option.isFiltering);
      if(filter && filtering) {
        const options = filter.options.filter(option => option.isFiltering).map(option => option.value);
        filteredCases = cases.filter(item => options.includes(item.assigned.id))
      }
    }
    return filteredCases;
  }

}
