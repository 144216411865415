<div class="open_faq inline">
  <div class="action" (click)="openModal(faq)">
    <mat-icon>help</mat-icon>
    <span>{{(prefixTranslate+'.title' | translate )}}</span>
  </div>
</div>

<ng-template #faq let-c="close" let-d="dismiss">
  <h1 class="title">{{(prefixTranslate+'.title' | translate )}}:</h1>

  <div class="modal-body">
    <ul class="list_faq">
      <li *ngFor="let faq of faqs; let i=index;">
        <div class="faq" [innerHtml]="(prefixTranslate+'.faq_'+i | translate)"></div>
      </li>
      <li *ngIf="showWeightedAverage">
        <div class="faq" [innerHTML]="explainedFormula"></div>
      </li>
    </ul>
  </div>

</ng-template>
