import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mh-core-lib-followup-onsite-widget',
  templateUrl: './followup-onsite-widgets.component.html',
  styleUrls: ['./followup-onsite-widgets.component.scss']
})
export class CoreLibFollowupOnsiteWidgetsComponent implements OnInit {

  @Input()
  title = '';

  @Input()
  className = '';

  @Input()
  articleClassName = '';

  @Input()
  heightClass = 'tw-h-52';

  constructor() { }

  ngOnInit(): void {
  }

}
