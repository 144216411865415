import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable,Subject } from 'rxjs';
import * as FileSaver from 'file-saver';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { FileData } from '../model/file-data.model';
@Injectable()
export class HttpClientMH {

  fileName:string;
  downloadPDF = new Subject<any>();
  downloadFilePrecheckin= new Subject<any>();

  constructor(private http: HttpClient) {}

  getJWT(){
    const currentJWT: any = localStorage.getItem('currentJWT');
    let jwt  = JSON.parse(currentJWT);
    if(jwt===null){
      jwt = localStorage.getItem('currentGuestToken');
      if(jwt){
        jwt = {token: jwt}
      }
    }
    return jwt;
  }


  downloadFile(url, fileName, isPdf = true){
    this.fileName = fileName;
    const contentType = (isPdf) ? 'application/pdf' : 'application/octet-stream';
    const type = (isPdf) ? 'application/pdf' : 'image/png';
    const self      = this;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.setRequestHeader('x-api-key',`${environment.myhotel_api_key}`);
    xhr.setRequestHeader('content-type', contentType);
    const jwt  = this.getJWT();
    if(jwt){
      const token = jwt.token
      xhr.setRequestHeader('Authorization',`bearer ${token}`);
    }

    xhr.onreadystatechange = function() {
      setTimeout( () => {
        (isPdf) ? self.downloadPDF.next() : self.downloadFilePrecheckin.next();
      }, 0);
      if(xhr.readyState === 4 && xhr.status === 200) {
        const blob = new Blob([this.response], { type });
        if(this.response['type'] === contentType) {
          FileSaver.saveAs(blob, fileName);
          if(!isPdf) self.downloadFilePrecheckin.next('complete');
        } else {
          self.downloadFilePrecheckin.next('error');
        }
      }
    };

    xhr.send();
  }

  uploadFile(url: string, file: FormData): Observable<FileData> {
    return this.http.post<FileData>(url, file, {
      reportProgress: true,
    });
  }

  uploadFileWithEvents(url: string, file: FormData): Observable<HttpEvent<any>> {
    return this.http.post(url, file, {
      reportProgress: true,
      observe: 'events',
    });
  }

  getFile() {
    return this.downloadFilePrecheckin.asObservable();
  }

  getPDF(): Observable<any> {
    return this.downloadPDF.asObservable();

  }
}
