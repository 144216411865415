import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mh-core-lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class CoreLibTableComponent {
  Math = Math;

  @Input() totalElements;
  @Input() totalPages;
  @Input() showPaginator = true;
  @Input() showFooter = true;
  @Input() showHeader = true;
  @Input() DefaultPaginationSize;
  @Input() className = '';
  @Input() footerHeight = 50;
  @Input() customCssClasses: string;
  @Output() pageChanged = new EventEmitter();
  @Output() paginationSize = new EventEmitter();
  pagination = 50;
  #currentPage = 0;

  @Input() set currentPage(value) {
    this.#currentPage = +value;
  }

  get currentPage() {
    return this.#currentPage;
  }

  setPagination(paginationSize) {
    this.paginationSize.emit(paginationSize);
    this.pagination = paginationSize;
  }
}
