import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuItem } from 'app/states/menus/menus.model';
import { UsersActions } from 'app/states/users-actions/users-actions.state';

@Injectable({
  providedIn: 'root',
})
export class UsersManagmentService {
  constructor(private http: HttpClient) {}

  paginate(status: string = 'all', page: string = '', pageSize: string = '', orderColumn: string = '', asc = false, textFilter: string = '', customerIds: string = '', chainIds: string = '') {
  const url = `${environment.customerPath}v3/users`;
    const params = new HttpParams()
      .set('status', status)
      .set('page', page)
      .set('page_size', pageSize)
      .set('order_column', orderColumn)
      .set('asc', asc)
      .set('text_filter', textFilter)
      .set('customer_ids', customerIds)
      .set('chain_ids', chainIds);

    return this.http.get(url, {params});
  }

  getUsersCount(customerIds = null) {
    const url = `${environment.customerPath}v3/users/count`;
    let params = {}
    if (customerIds) {
      params = new HttpParams()
        .set('customer_ids', customerIds);
    }
    return this.http.get(url, { params });
  }

  getCustomers() {
    const url = `${environment.customerPath}customers`;
    return this.http.get(url);
  }

  getAllSimpleCustomers() {
    const url = `${environment.customerPath}/simple/all/clients`;
    return this.http.get(url);
  }

  delete_access(userIds) {
    const url = `${environment.customerPath}v3/users/revoke/all/permissions`;
    const params = new HttpParams()
      .set('user_ids', userIds);
    return this.http.post(url, {}, {params});
  }

  restore_access(userIds) {
    const url = `${environment.customerPath}v3/users/email/password/reset`;
    const params = new HttpParams()
      .set('user_ids', userIds)
    return this.http.post(url, {}, {params});
  }

  disable_user(userIds) {
    const url = `${environment.customerPath}v3/users`;
    const params = new HttpParams()
      .set('user_ids', userIds)
    return this.http.delete(url, {params});
  }

  alert_setup(userIds, actions, product) {
    const [active, detractor, passive, promoter] = actions;
    const url = (product === 3) ? `${environment.customerPath}v3/users/online/alerts` : `${environment.customerPath}v3/users/followup/alerts`;
    let params = new HttpParams()
      .set('user_ids', userIds)
      .set('active', active)
      .set('detractor', detractor)
      .set('passive', passive)
      .set('promoter', promoter);

    if ([1,2].includes(product)) {
      params = params.set('product_id', product);
    }

    return this.http.post(url, {}, {params});
  }

  editAlertConfig(userId, productId, body) {
    const url = (productId === 3) ? `${environment.customerPath}v3/users/online/user/${userId}/alerts` : `${environment.customerPath}v3/users/followup/user/${userId}/alerts`;
    return this.http.put(url, body);
  }

  assign_properties(userIds, customerIds) {
    if (customerIds.length === 0) {
      return of([]);
    }
    const url = `${environment.customerPath}v3/users/add/permissions`;
    const params = new HttpParams()
      .set('user_ids', userIds)
      .set('customer_ids', customerIds);
    return this.http.post(url, {}, {params});
  }

  activate_user(userIds, resetAlerts=false, revokeAccess=false) {
    const url = `${environment.customerPath}v3/users/reactivate/user`;
    const params = new HttpParams()
      .set('reset_alerts', resetAlerts)
      .set('revoke_access', revokeAccess)
      .set('user_ids', userIds);
    return this.http.post(url, {}, { params });
  }

  revokeProperties(userIds, customerIds) {
    if (customerIds.length === 0) {
      return of([]);
    }
    const url = `${environment.customerPath}v3/users/revoke/permissions`;
    const params = new HttpParams()
      .set('user_ids', userIds)
      .set('customer_ids', customerIds);
    return this.http.post(url, {}, {params});
  }

  replaceProperties(userId, customerIds) {
    const url = `${environment.customerPath}v3/users/${userId}/replace/permissions`;
    const params = new HttpParams()
      .set('customer_ids', customerIds);
    return this.http.post(url, {}, {params});
  }

  findUser(email) {
    const url = `${environment.customerPath}v3/users/find`;
    const params = new HttpParams()
      .set('email', email);
    return this.http.get(url, { params });
  }

  changePassword(userId, password) {
    const url = `${environment.customerPath}v3/users/${userId}/change/password`;
    const params = new HttpParams()
      .set('password', btoa(password));
    return this.http.put(url, {}, { params });
  }

  reactivateUser(userId) {
    const url = `${environment.customerPath}v3/users/reactivate/user/${userId}`;
    return this.http.post(url, {});
  }

  getRolesAndDeparments() {
    const url = `${environment.customerPath}users/base`;
    return this.http.get(url);
  }

  createUser(body) {
    const url = `${environment.customerPath}v3/users/`;
    return this.http.post(url, body);
  }

  updateUser(body, userId) {
    const url = `${environment.customerPath}v3/users/${userId}`;
    return this.http.patch(url, body);
  }

  welcomeEmail(userId) {
    const url = `${environment.customerPath}v3/users/welcome/email/user/${userId}`;
    return this.http.post(url, {});
  }

  invalidatePassword(userId) {
    const url = `${environment.customerPath}v3/users/${userId}/invalidate/password`;
    return this.http.post(url, {});
  }

  getAlertConfigSetup(userId, productId) {
    const url = (productId === 3) ? `${environment.customerPath}v3/users/online/user/${userId}/alerts` : `${environment.customerPath}v3/users/followup/user/${userId}/alerts`;
    let params = new HttpParams();
    if ([1,2].includes(productId)) {
      params = params.set('product_id', productId);
    }
    return this.http.get(url, { params });
  }

  getOtas() {
    const url = `${environment.onlinePath}otas/base`;
    return this.http.get(url).toPromise();
  }

  getRoles() {
    const url = `${environment.customerPath}v3/users/roles`;
    return this.http.get(url);
  }

  getHotelAccesses(customerIds) {
    const url = `${environment.customerPath}v3/users/newuser/sidebar`;
    const params = new HttpParams().set('customer_ids', customerIds);
    return this.http.get(url, { params }).pipe(map((result: MenuItem[]) => this.setAllSelected(result)));
  }

  addUserAccesses(body, userId) {
    body = this.setParentActive(body);
    const url = `${environment.customerPath}v3/users/sidebar`;
    const params = new HttpParams().set('user_ids', userId);
    return this.http.put(url, body, { params });
  }

  setParentActive(menu: MenuItem[]) {
    for (const item of menu) {
      if (item.childrens.length > 0) {
        this.setParentActive(item.childrens);
        item.active = item.childrens.some((child) => child.active);
      }
    }
    return menu;
  }

  getUserAccesses(userId) {
    const url = `${environment.customerPath}v3/users/sidebar`;
    const params = new HttpParams().set('user_ids', userId);
    return this.http.get(url, { params }).pipe(map((result: MenuItem[]) => this.setAllSelected(result)));
  }

  setAllSelected(menu: MenuItem[]) {
    for (const item of menu) {
      if (item.slug === 'corporative_online_coverage') {
        item.childrens = [];
      }
      if (item.childrens.length > 0) {
        const allChildrenSelected = item.childrens.every((child) => child.active);
        item.allSelected = allChildrenSelected;
        this.setAllSelected(item.childrens);
      } else {
        item.allSelected = item.active;
      }
    }
    return menu;
  }

  getDetailsOfActionsByRole(languageId) {
    const url = `${environment.customerPath}v3/users/actions/all`;
    const params = new HttpParams().set('language_id', languageId);
    return this.http.get(url, { params });
  }

  setRole(roleId, userIds) {
    const url = `${environment.customerPath}v3/users/roles/${roleId}`;
    const params = new HttpParams().set('user_ids', userIds);
    return this.http.patch(url, null, { params });
  }
  getUserActions() {
    const url = `${environment.customerPath}v3/users/actions/role`;
    return this.http.get<UsersActions[]>(url);
  }
}
