import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mh-survey-section',
  templateUrl: './survey-section.component.html',
  styleUrls: ['./survey-section.component.scss']
})
export class SurveySectionComponent implements OnInit {
  @Input() titleTranslates:TitleTranslate[];
  @Input() language:string = 'es';

  constructor() { }

  ngOnInit() {
  }
}

type TitleTranslate = {
  id: number;
  language: { id: number, code: string, text: string };
  text: string;
}
