
export class PieData {


  private _type:string | any; //Label a mostrar
  private _data:number | any; // Valor en el gráfico
  private _language:string | any; //Lenguaje (DEPRECADO)
  private _note:number | any; //Satisfacción, sino dejar en 0

  constructor()
  constructor(type: string, data: number, language: string)
  constructor(type: string, data: number, language: string, note: number)
  constructor(type?: string, data?: number, language?: string)
  constructor(type?: string, data?: number, language?: string, note?:number) {
    this._type       = type?.toLowerCase();;
    this._data       = data;
    this._language   = language;
    this._note       = note;
  }


  // SETTERS
  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value.toLowerCase();;
  }

  get data(): number {
    return this._data;
  }

  set data(value: number) {
    this._data = value;
  }

  get language(): string {
    return this._language;
  }

  set language(value: string) {
    this._language = value;
  }

  get note(): number {
    return this._note;
  }

  set note(value: number) {
    this._note = value;
  }

}
