<div>
  <p *ngIf="(newPassControl.invalid || confirmControl.invalid) && confirmControl.dirty">
    <i class="fas fa-times tw-text-red-500"></i> {{ 'forms.attributes.miss_match' | translate }}
  </p>

  <p *ngFor="let error of possibleErrors">
    <i
      class="fas"
      [ngClass]="{
          'fa-times tw-text-red-500': newPassControl.errors?.passwordStrength?.[error] || !newPassControl.dirty,
          'fa-check-circle tw-text-green-500': !newPassControl.errors?.passwordStrength?.[error] && newPassControl.dirty
        }"
    >
    </i>
    {{ 'forms.attributes.' + error | translate }}
  </p>
</div>
