<article class="tw-absolute tw-right-10 tw-top-10">
  <mh-lang-selector [selectedLang]="language" type="button"></mh-lang-selector>
</article>

<div class="tw-container tw-m-auto tw-p-5 tw-flex tw-flex-col tw-place-content-center tw-justify-center tw-items-center tw-w-screen tw-h-screen tw-notSelectable">
  <mh-core-lib-loader-wrapper [loading]="loading" [type]="'spinner'">
    <section class="tw-flex tw-flex-col tw-justify-center tw-items-center notSelectable">
      <ng-container *ngIf="!error; else alertError">
        <ng-container *ngIf="!fileUploaded">
          <ng-template *ngTemplateOutlet="recordingTemplate" ></ng-template>
        </ng-container>
        <ng-container  *ngIf="fileUploaded">
          <ng-template *ngTemplateOutlet="successTemplate"></ng-template>
        </ng-container>
      </ng-container>
    </section>
  </mh-core-lib-loader-wrapper>
</div>

<ng-template #recordingTemplate>
  <h1 class="tw-font-medium tw-text-main-text tw-text-xl tw-mb-14">{{ ('voices.public_portal.' + (recording ? 'recording' : 'click')) | translate }}</h1>
  <button class="tw-rounded-full tw-border-4 tw-w-32 tw-p-5 notSelectable tw-shadow-1" [ngClass]="{'scaleEfect': recording }" (click)="startRecord()" *ngIf="!recording">
    <i class="fas fa-microphone fa-5x notSelectable tw-border-main tw-text-main"></i>
  </button>
  <button class="tw-rounded-full tw-border-4 tw-w-32 tw-p-5 notSelectable tw-shadow-1" [ngClass]="{'scaleEfect': recording }" (click)="stopRecord()" *ngIf="recording">
    <i class="fas fa-stop fa-5x notSelectable tw-border-detractors tw-text-detractors"></i>
  </button>
  <article [class.tw-hidden]="!audioURL" class="tw-flex tw-flex-row tw-gap-2">
    <audio  class="tw-pt-5 tw-ml-5" #player controls></audio>
    <i class="fas fa-trash-alt tw-pt-7 tw-text-detractors tw-cursor-pointer" (click)="audioURL= undefined"></i>
  </article>

  <p class="tw-text-main-text tw-font-bold tw-text-small tw-mt-8" *ngIf="recording">{{ 'voices.public_portal.time_to_record' | translate: { seconds: (limitInSeconds - countSeconds) } }}</p>

  <mh-core-lib-button [class.tw-hidden]="!audioURL" class="tw-my-14" [isDefault]="true" [text]="'commons.send' | translate" (click)="sendRecording()"></mh-core-lib-button>
</ng-template>

<ng-template #successTemplate>
  <h1 class="tw-font-medium tw-text-main-text tw-text-xl tw-mb-14">{{ 'voices.public_portal.success' | translate }}</h1>
  <div class="tw-rounded-full tw-border-4 tw-w-32 tw-p-5 tw-notSelectable">
    <i class="fas fa-check fa-5x notSelectable tw-border-main-green-success tw-text-main-green-success"></i>
  </div>
</ng-template>

<ng-template #alertError>
  <div class="tw-rounded-full notSelectable tw-mb-12">
    <i class="fad fa-times-circle fa-5x notSelectable tw-border-red-400 tw-text-red-700"></i>
  </div>
  <div class="tw-bg-red-100 tw-border tw-border-red-400 tw-text-red-700 tw-px-4 tw-py-3 tw-rounded tw-relative" role="alert">
    <span class="tw-block sm:tw-inline">{{ 'voices.public_portal.errors.mic' | translate }}</span>
  </div>
</ng-template>