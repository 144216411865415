import {Component, Input} from '@angular/core';
import {DataMention} from '../data-mention';
// TABLE IMPORTS





@Component({
  selector: 'semantic-mention',
  templateUrl: './mention.component.html',
  styleUrls: ['../../../../../assets/scss/shared/semantic/mention.scss'],

})

export class SemanticMentionComponent{
  @Input()
  data:DataMention;

  constructor() {}

  getLabel(type, category){
    let label:string | any;
    if(type === "themes")   label=category['theme'];
    if(type === "entities") label=category['entity'];
    if(type === "phrases")  label=category['phrase'];
    return label.toLowerCase();
  }

}

