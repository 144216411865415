<div *ngFor="let log of (logs | slice:pageEvent.pageSize * pageEvent.pageIndex:
     pageEvent.pageSize * (pageEvent.pageIndex + 1))"
>
  <mh-log-card [log]="log" [language]="language" [commercialExecutivesList]="commercialExecutivesList"></mh-log-card>
</div>

<mat-paginator
  [length]="logs.length"
  [pageIndex]="pageEvent.pageIndex"
  [pageSize]="pageEvent.pageSize"
  [pageSizeOptions]="PAGE_SIZE_OPTIONS"
  (page)="handlePaginate($event)">
</mat-paginator>

