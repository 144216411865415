import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User, Hotel } from '../../shared/model/index';
import { map } from 'rxjs/operators';
import { DeskConfig } from '../profile/Profile.model';
import { Observable } from 'rxjs';

export class OtaDTO {
  active_alerts: boolean;
  id: number;
  constructor(active_alerts, id) {
    this.active_alerts = active_alerts;
    this.id = id;
  }
}

export class OtasDataAlert {
  otas_dto: any;
  user_dto: any;
  constructor(customer, user, alerts, otas) {
    const otaDTO: any[] = [];

    for (const ota of otas) {
      otaDTO.push(new OtaDTO(ota.value, ota.id));
    }

    user.customers = [customer];
    user.alerts = alerts;
    this.otas_dto = otaDTO;
    this.user_dto = {
      ...user,
      role: null, // needed to fulfull back requirements, in which role is a string
      deparment: null,
    };
  }
}

@Injectable()
export class EditProductService {
  constructor(private http: HttpClient) {}

  getProductPrefix(product) {
    return product === 'followup' ? 'surveys_' : product + '_';
  }

  public getSummaries(customerId, userId) {
    const url = `${environment.customerSummaries}`;
    const params: HttpParams = new HttpParams().set('customer_id', customerId).set('user_id', userId);

    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public deleteSummary(customerId: string, userId) {
    const url = `${environment.customerSummaries}/${userId}/${customerId}`;
    return this.http.delete(url).pipe(map((response) => response));
  }

  public saveSummary(customerId: string, userId) {
    const url = `${environment.customerSummaries}`;
    const data = this.dataSaveSummaries(customerId, userId);
    return this.http.put(url, data).pipe(map((response) => response));
  }

  public saveAccess(userId, data) {
    const url = `${environment.customerUser}/${userId}/access`;
    return this.http.put(url, data).pipe(map((response) => response));
  }

  public getAlerts(customerId: string, product) {
    let url = '';
    let productId = '0';

    switch (product) {
      case 'followup':
        productId = '1';
        url = `${environment.followupAlert}/users`;
        break;
      case 'online':
        productId = '3';
        url = `${environment.onlineAlert}/users`;
        break;
      case 'onsite':
        productId = '2';
        url = `${environment.onsiteAlert}/users`;
        break;
    }

    const params: HttpParams = new HttpParams()
      .set('customer_id', customerId)
      .set('product_id', productId)
      .set('alert_type_id', '0');

    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public saveAlerts(customer, user, alerts: any[], product, otas = []) {
    let url = `${environment.followupAlert}/` + product;
    let data: any = this.dataSaveAlerts(customer, user, alerts, product);

    if (product === 'online') {
      url = `${environment.onlineAlert}/otas`;
      data = this.dataSaveOtaAlerts(customer, user, alerts, product, otas);
    }

    return this.http.put(url, data).pipe(map((response) => response));
  }

  dataSaveAlerts(customer: Hotel, user: User, alerts: any[], product) {
    return {
      alerts: alerts,
      customer: {
        id: customer.id,
      },
      id: user.id,
    };
  }

  dataSaveOtaAlerts(customer: Hotel, user: User, alerts: any[], product, otas = []) {
    return new OtasDataAlert(customer, user, alerts, otas);
  }

  dataSaveSummaries(customerId, userId) {
    return {
      customer: {
        id: customerId,
      },
      user: {
        id: userId,
      },
    };
  }

  getSurveyAlerts(customerId, userId, productId) {
    const url = `${environment.followupPath}alerts/customers/${customerId}/users/${userId}`;
    const params = new HttpParams().set('product_id', productId);
    return this.http.get(url, { params });
  }

  editSurveyAlerts(customerId, userId, productId, alerts) {
    const url = `${environment.followupPath}/alerts/customers/${customerId}/users/${userId}`;
    const params = new HttpParams().set('product_id', productId);
    return this.http.put(url, alerts, { params });
  }

  getOtasAlerts(customerId, userId) {
    const url = `${environment.onlinePath}/alerts/customers/${customerId}/users/${userId}`;
    return this.http.get(url);
  }

  editOtasAlerts(customerId, userId, alerts) {
    const url = `${environment.onlinePath}/alerts/customers/${customerId}/users/${userId}`;
    return this.http.put(url, alerts);
  }

  getDeskAlerts(customerId, userId): Observable<DeskConfig[]> {
    const url = `${environment.casesPath}alerts/customers/${customerId}/users/${userId}`;
    return this.http.get<DeskConfig[]>(url);
  }

  editDeskAlerts(customerId, userId, payload): Observable<DeskConfig[]> {
    const url = `${environment.casesPath}alerts/customers/${customerId}/users/${userId}`;
    return this.http.put<DeskConfig[]>(url, payload);
  }

  editMassiveSurveyAlerts(productId, params) {
    let product;
    if ([1, 2].includes(productId)) {
      product = 'followup';
    } else if (productId === 3) {
      product = 'online';
      params.product_id = null;
    } else {
      product = 'cases';
    }
    const url = `${environment.customerPath}v3/users/${product}/alerts`;

    let httpParams = new HttpParams();

    Object.keys(params).forEach((param) => {
      if (params[param] !== null && params[param] !== undefined) {
        httpParams = httpParams.set(param, params[param]);
      }
    });

    return this.http.post(url, null, { params: httpParams });
  }

  editMassiveReports(payload) {
    const url = `${environment.customerPath}automatic-reports/massive-config`;
    let params: HttpParams = new HttpParams()
      .append('user_ids', payload.user_ids)
      .append('flag_daily', payload.flag_daily)
      .append('flag_weekly', payload.flag_weekly)
      .append('flag_monthly', payload.flag_monthly);

    if (payload?.customer_id) params = params.append('customer_id', payload.customer_id);

    return this.http.put(url, {}, { params });
  }
}
