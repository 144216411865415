import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService }          from '@auth0/angular-jwt';
import { ProductsService }    from "../_services/index";
import { UtilService }         from '../shared/util.service';
import {NotificationsService} from '../notifications/notifications.service';
import { CustomerService } from 'app/shared/customer.service';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';

@Injectable()
export class AuthGuard implements CanActivate {

  jwtHelper: JwtHelperService = new JwtHelperService();
  
  constructor(
    private router: Router,
    private productsService: ProductsService,
    private utilService: UtilService,
    private notificationsService: NotificationsService,
    private customerService: CustomerService,
    private alertService: AlertService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentHotel = this.utilService.getCurrentHotel();

    if (this.isLogged()) {
      if (this.productsService.navItems.length === 0) {
        const currentUser = this.utilService.getCurrentUser();
        const isChain = await this.customerService.getIsChain(currentHotel, currentUser);
        this.productsService.dataNavbar = null;
        await this.productsService.loadProducts(
          this.utilService.getCurrentUser(),
          this.utilService.getCurrentHotel(),
          this.utilService.getFidelityConfigurations(),
          isChain,
        );
      }

      if (this.productsService.hasNotProduct(state.url) || +this.utilService.currentHotel.id === 12) {
        return true;
      }

      return this.checkViewItem(state);
    }

    this.logout();
    const queryParams = Object.assign({}, route.queryParams);
    queryParams.returnUrl =
      Object.keys(queryParams).length > 0 ? state.url.substring(0, state.url.indexOf('?')) : state.url;
    this.router.navigate(['/login'], { queryParams: queryParams });
    return false;
  }

  isLogged(): boolean {
    const currentJWT: any = localStorage.getItem('currentJWT');
    const jwt = currentJWT ? JSON.parse(currentJWT) : null;
    let isLogged = false;
    if(jwt) return true;
    else{
      this.logout();
      isLogged = false;
    }
    return isLogged;
  }

  checkViewItem(state: RouterStateSnapshot){
    const itemProduct = this.productsService.getProductItemByLink(this.clearUrl(state.url));
    this.checkCustomerStatus();

    if (itemProduct === undefined) {
      const url = this.productsService.findLeafItem(
        this.productsService.dataNavbar.filter((item) => item.slug !== 'corporative'),
      );
      this.alertService.handleAlert(
        'commons.not_permissions',
        5000,
        'bottom',
        'end',
        'snackbar-panel-error-user-config',
      );
      this.router.navigate([url]);
      return false;
    }

    return true;
  }

  logout() {
    this.productsService.dataNavbar = null;
    localStorage.removeItem('currentJWT');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentHotel');
  }

  checkCustomerStatus() {
    this.notificationsService.checkModalAlert('guard');
  }

  clearUrl(link){
    return link.indexOf("?") > 0 ? link.substring(0,link.indexOf("?")) : link;
  }

}
