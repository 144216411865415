<div class="tw-px-5">
  <mh-survey-public *ngIf="survey; else spinner"
     [survey]="survey"
     [answerId]="answerId"
     [answers]="surveyAnswers.answers"
     [language]="language$ | async"
     [languageId]="languageId$ | async"
     [surveyLanguageId]="surveyLanguageId"
     [preview]="true"
     [answersComments]="answersComments"
   ></mh-survey-public>
</div>

<ng-template #spinner>
  <div class="text-center">
    <mh-spinner-load></mh-spinner-load>
  </div>
</ng-template>
