<section class="comments">
  <button (click)="toggleShowComments()" class="btn btn-orange">
    {{ (showAll ? 'followup.summary.survey-comments.close_comments' : 'followup.summary.survey-comments.show_comments') | translate}}
  </button>

  <mh-spinner-classic [hidden]="!waiting"></mh-spinner-classic>

  <div class="empty" *ngIf="isEmpty">{{'followup.summary.survey-comments.no_comments' | translate }}</div>

  <ul class="comment-list" *ngIf="!waiting && showAll">
    <li *ngFor="let comment of comments">
      <mh-survey-guest-comment
        [survey]="survey"
        [productId]="productId"
        [responseComment]="comment"
      ></mh-survey-guest-comment>
    </li>
  </ul>

</section>
