import { NgModule }                     from '@angular/core';
import {Routes, RouterModule}             from "@angular/router";
import {ConfigurationAlertsComponent}     from "./sections/alerts/alerts.component";
import {ConfigurationSummaryComponent}    from "./sections/summary/summary.component";
import { AuthGuard }                      from '../_guards/index';

const routes: Routes = [
  { path: 'alerts',  component: ConfigurationAlertsComponent, canActivate: [AuthGuard], },
  { path: 'summary', component: ConfigurationSummaryComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})

export class ConfigurationRoutingModule {}
