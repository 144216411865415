import { Currency } from './currency.model';

export class Hotel {
  id: string;
  name: string;
  urlLogo: string;
  followup = false;
  online = false;
  onsite = false;
  collect = false;
  prestay = false;
  cases = false;
  semantic = false;
  voices = false;
  flagsIds: number[] = [];
  accessIds: number[] = [];
  flags: Flags[] = [];
  client = true;
  country_code = 'none';
  property_type = { id: 1, description: 'hotel' }; // 1.hotel,2.hostel
  integration_status_dto: IntegrationStatus;
  nick_id: string;
  currency: Currency = new Currency(179, 'USD');
  chain_internal_code: number;
  chain_brand: string;
  chain_region: string;
  smart_replies = false;
  whatsapp = false;

  static createFromApi(object: any) {
    return new Hotel().createFromApi(object);
  }

  constructor();
  constructor(id, name, flags, url_logo);
  constructor(id?, name?, flags?, url_logo?) {
    this.id = id;
    this.name = name;
    this.flags = flags;
    this.urlLogo = url_logo;
    this.integration_status_dto = new IntegrationStatus(true, new Date());
  }

  shortName(): string {
    return this.name.replace('Hotel ', '');
  }

  createFromApi(object: any): Hotel {
    this.id = object.id; //BUG QUE ARREGLAR
    this.name = object.name;
    this.flagsIds = object.flags_string ? object.flags_string.split(',').map((x) => parseInt(x)) : [];
    this.accessIds = object.access_string ? object.access_string.split(',').map((x) => parseInt(x)) : [];
    this.urlLogo = object.url_logo;
    this.onsite = object.onsite;
    this.followup = object.followup;
    this.online = object.online;
    this.collect = object.collect;
    this.prestay = object.prestay;
    this.cases = object.cases;
    this.semantic = object.semantic;
    this.voices = object.voices;
    this.smart_replies = object.smart_replies;
    this.country_code = object.country_code || 'none';
    this.property_type = object.property_type || { id: 1 };
    this.nick_id = object.nick_id;
    this.currency = object.currency;
    this.chain_internal_code = object.chain_internal_code || '';
    this.chain_brand = object.chain_brand || '';
    this.chain_region = object.chain_region || '';
    this.whatsapp = object.whatsapp;

    return this;
  }

  listActivePorducts() {
    const products: any[] = [];

    for (const product of ['followup', 'online', 'onsite']) {
      if (this[product]) products.push(product);
    }

    return products;
  }
}

export class Flags {
  private _id: number;
  private _description: string;
  private _value: boolean;

  constructor(id, description, value) {
    this._id = id;
    this._description = description;
    this._value = value;
  }

  public set id(v: number) {
    this._id = v;
  }
  public get id(): number {
    return this._id;
  }

  public set description(v: string) {
    this._description = v;
  }
  public get description(): string {
    return this._description;
  }

  public set value(v: boolean) {
    this._value = v;
  }
  public get value(): boolean {
    return this._value;
  }
}

export class IntegrationStatus {
  flag_ok = true;
  last_update: Date;
  constructor(flag_ok, last_update) {
    this.flag_ok = flag_ok;
    this.last_update = last_update;
  }

  toPlainObj(): { flag_ok: boolean; last_update: Date } {
    return Object.assign({}, this);
  }
}
