<div class="tw-flex tw-flex-col">
  <div class="tw-flex tw-justify-center tw-items-start">
    <h3 class="tw-text-modal-title tw-text-xl tw-grow tw-text-center tw-font-bold">{{ (!!title ? title : 'user_management.actions.success') | translate }}</h3>
    <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
  </div>

  <div class="tw-flex tw-justify-center tw-mt-10 tw-mb-10">
    <i class="fas fa-check-circle fa-3x tw-text-main-green-success"></i>
  </div>

  <div class="tw-flex tw-justify-center tw-font-medium text-center tw-mb-4" *ngIf="body">
    <p class="tw-text-modal-title" [innerHTML]="body"></p>
  </div>

  <div class="tw-flex tw-justify-center">
    <mh-core-lib-button [isDefault]="true" [text]="('forms.accept' | translate)" (click)="close()"></mh-core-lib-button>
  </div>
</div>
