<div class="filter-zone">
  <ng-container *ngTemplateOutlet="optionSelect;context:{type: 'all'}"></ng-container>
  <ng-container *ngIf="filters.all.selected!=='all'">
    <ng-container *ngTemplateOutlet="optionSelect;context:{type: filters.all.selected}"></ng-container>
  </ng-container>

  <ng-template #optionSelect let-type="type">
    <mat-form-field [hidden]="filters.all.values.length===1">
      <mat-label>{{'shared.filters.chain-zone.labels.'+type | translate }}</mat-label>
      <mat-select [(ngModel)]="filters[type].selected" (selectionChange)="chainResult()" [disabled]="disabled">
        <mat-option *ngFor="let value of filters[type].values"  [value]="value">
          {{type==='all' ? ('shared.filters.chain-zone.types.'+value | translate ): value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>
</div>
