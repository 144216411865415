import { Component, OnInit, HostListener } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FILTERS_NAME, OnlineReviewsState, NPS_VALUES, ANSWER_VALUES } from 'app/states/online-reviews/online-reviews.state';
import { Observable, Subscription } from 'rxjs';
import {
  ChangeAnswerFilter,
  ChangeLanguageFilter,
  ChangeNPSFilter,
  ChangeOTAFilter,
  ChangeQualificationFilter,
  ChangeReasonFilter,
  FilterClear,
} from 'app/states/online-reviews/online-reviews.actions';
import { animation } from 'app/shared/utils/animation-custom';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mh-core-lib-reviews-filter',
  templateUrl: './reviews-filter.component.html',
  styleUrls: ['./reviews-filter.component.scss'],
  animations: [animation.collapsedHeightState, animation.rotate],
})
export class CoreLibReviewsFilterComponent implements OnInit {
  isOpen = false;
  reviews = [];

  subscriptions = new Subscription();
  isOpenMobile = [
    { target: FILTERS_NAME.OTA, isOpen: false },
    { target: FILTERS_NAME.ANSWER, isOpen: false },
    { target: FILTERS_NAME.NPS, isOpen: false },
    { target: FILTERS_NAME.QUALIFICATION, isOpen: false },
    { target: FILTERS_NAME.REASON, isOpen: false },
    { target: FILTERS_NAME.LANGUAGE, isOpen: false },
  ];
  firstTimeFromIRONPS = true;
  firstTimeFromIROAnswer = true;
  @Select(OnlineReviewsState.reviews) reviews$: Observable<any>;

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const result = this.checkClickInside(event.target);
    if (!result) {
      this.isOpen = false;
    }
  }

  constructor(private store: Store, private translateService: TranslateService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.setMenuMobile();
    this.init();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  checkClickInside(target) {
    const idsToVerify = ['overlayFiltersOnlineReviews', 'filtersReviews'];
    const classesToVerify = ['chip-no-close', 'generic-option', 'checkbox-option'];
    if (idsToVerify.includes(target.id) || classesToVerify.some((clas) => target.className?.includes(clas))) {
      return true;
    } else if (target.parentNode) {
      return this.checkClickInside(target.parentNode);
    } else {
      return false;
    }
  }

  init() {
    this.subscriptions.add(
      this.reviews$.subscribe((reviews: any) => {
        if (reviews && reviews.length > 0) {
          this.reviews = reviews;
          this.setFilters(reviews);
        }
      }),
    );
  }

  setMenuMobile() {
    if (window.innerWidth >= 765) {
      this.isOpenMobile = this.isOpenMobile.map((filter) => {
        return {
          ...filter,
          isOpen: true,
        };
      });
    }
  }

  toggleMobileFilter(target) {
    if (this.isMobileMenu) {
      const index = this.isOpenMobile.findIndex((filter) => filter.target === target);
      this.isOpenMobile[index].isOpen = !this.isOpenMobile[index].isOpen;
    }
  }

  setFilters(reviews) {
    this.setOTAFilter(reviews);
    this.setAnswerFilter(reviews);
    this.setNPSFilter(reviews);
    this.setQualificationFilter(reviews);
    this.setReasonFilter(reviews);
    this.setLanguageFilter(reviews);
  }

  setOTAFilter(reviews, optionSelected = null) {
    const otas = reviews.reduce((prev, curr) => {
      const existState = prev.find((prevStates) => prevStates.id === curr.ota.id);
      if (!existState) {
        const filter = this.setFilterByKey(curr, FILTERS_NAME.OTA, optionSelected, this.otaOptions);
        prev.push(filter);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeOTAFilter(otas));
  }

  setAnswerFilter(reviews, optionSelected = null) {
    let optionSelectedAux = { ...optionSelected };
    if (this.firstTimeFromIROAnswer) {
      this.firstTimeFromIROAnswer = false;
      const answerFromIRO = this.route.snapshot.queryParams.answer;
      const filter = {
        [ANSWER_VALUES.RESPONDIDO.value]: ANSWER_VALUES.RESPONDIDO,
        [ANSWER_VALUES.NO_RESPONDIDO.value]: ANSWER_VALUES.NO_RESPONDIDO,
        [ANSWER_VALUES.NO_ADMITE_RESPUESTAS.value]: ANSWER_VALUES.NO_ADMITE_RESPUESTAS,
      };
      optionSelectedAux = filter[answerFromIRO];
    }
    const currentReviewsFilters = this.store
      .selectSnapshot(OnlineReviewsState.filters)
      .find((filter) => filter.name === FILTERS_NAME.ANSWER);
    const options = [...currentReviewsFilters.options];

    const result = options.reduce((prev, curr) => {
      if (curr.isFiltering === undefined && optionSelectedAux?.value === curr.value) {
        const newOption = { ...curr };
        newOption.isFiltering = true;
        newOption.id = curr.value;
        prev.push(newOption);
      } else if (curr.isFiltering === undefined) {
        const newOption = { ...curr };
        newOption.isFiltering = false;
        newOption.id = curr.value;
        prev.push(newOption);
      } else if (optionSelectedAux?.value === curr?.value) {
        const newOption = { ...curr };
        newOption.isFiltering = !newOption.isFiltering;
        newOption.id = curr.value;
        prev.push(newOption);
      } else {
        prev.push(curr);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeAnswerFilter(result));
  }

  setNPSFilter(reviews, optionSelected = null) {
    let optionSelectedAux = { ...optionSelected };
    if (this.firstTimeFromIRONPS) {
      this.firstTimeFromIRONPS = false;
      const npsFromIRO = this.route.snapshot.queryParams.nps;
      const filter = {
        [NPS_VALUES.PROMOTER.text]: NPS_VALUES.PROMOTER,
        [NPS_VALUES.PASSIVE.text]: NPS_VALUES.PASSIVE,
        [NPS_VALUES.DETRACTOR.text]: NPS_VALUES.DETRACTOR,
      };
      optionSelectedAux = filter[npsFromIRO];
    }
    const currentReviewsFilters = this.store
      .selectSnapshot(OnlineReviewsState.filters)
      .find((filter) => filter.name === FILTERS_NAME.NPS);
    const options = [...currentReviewsFilters.options];

    const result = options.reduce((prev, curr) => {
      if (curr.isFiltering === undefined && optionSelectedAux && optionSelectedAux.value === curr.value) {
        const newOption = { ...curr };
        newOption.isFiltering = true;
        newOption.id = curr.value;
        prev.push(newOption);
      } else if (curr.isFiltering === undefined) {
        const newOption = { ...curr };
        newOption.isFiltering = false;
        newOption.id = curr.value;
        prev.push(newOption);
      } else if (optionSelectedAux?.value === curr?.value) {
        const newOption = { ...curr };
        newOption.isFiltering = !newOption.isFiltering;
        newOption.id = curr.value;
        prev.push(newOption);
      } else {
        prev.push(curr);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeNPSFilter(result));
  }

  setQualificationFilter(reviews, optionSelected = null) {
    const currentReviewsFilters = this.store
      .selectSnapshot(OnlineReviewsState.filters)
      .find((filter) => filter.name === FILTERS_NAME.QUALIFICATION);
    const options = [...currentReviewsFilters.options];

    const result = options.reduce((prev, curr) => {
      if (curr.isFiltering === undefined && optionSelected && optionSelected.value === curr.value) {
        const newOption = { ...curr };
        newOption.isFiltering = true;
        newOption.id = curr.value;
        prev.push(newOption);
      } else if (curr.isFiltering === undefined) {
        const newOption = { ...curr };
        newOption.isFiltering = false;
        newOption.id = curr.value;
        prev.push(newOption);
      } else if (optionSelected?.value === curr.value) {
        const newOption = { ...curr };
        newOption.isFiltering = !newOption.isFiltering;
        newOption.id = curr.value;
        prev.push(newOption);
      } else {
        prev.push(curr);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeQualificationFilter(result));
  }

  setReasonFilter(reviews, optionSelected = null) {
    const reasons = reviews.reduce((prev, curr) => {
      const exists = prev.find((type) => type.id === curr.traveler_type.id);
      if (!exists && (!optionSelected || !optionSelected.length)) {
        const filter = {
          ...curr.traveler_type,
          isFiltering: false,
          selected: false,
        };
        prev.push(filter);
      }
      if (!exists && optionSelected && optionSelected.length > 0) {
        const currFilter = optionSelected.find((selected) => selected.id === curr.traveler_type.id);
        if (currFilter) {
          const filter = {
            ...currFilter,
            isFiltering: !currFilter.isFiltering,
            selected: !currFilter.selected,
          };
          prev.push(filter);
        } else {
          const filter = {
            ...curr.traveler_type,
            isFiltering: false,
            selected: false,
          };
          prev.push(filter);
        }
      }

      return [...prev];
    }, []);
    this.store.dispatch(new ChangeReasonFilter(reasons));
  }

  setLanguageFilter(reviews, optionSelected = null) {
    const languages = reviews.reduce((prev, curr) => {
      const existState = prev.find((prevStates) => prevStates.code === curr.guest.language.code);
      if (!existState) {
        const filter = this.setFilterByKey(
          curr.guest,
          FILTERS_NAME.LANGUAGE,
          optionSelected,
          this.languageOptions,
          'code',
        );
        prev.push(filter);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeLanguageFilter(languages));
  }

  setFilterByKey(option, key, optionSelected, options, keyToValue = 'id') {
    if (optionSelected && option[key][keyToValue] === optionSelected[keyToValue]) {
      return {
        ...option[key],
        isFiltering: !optionSelected.isFiltering,
        selected: !optionSelected.selected,
        value: optionSelected.id,
      };
    } else if (optionSelected) {
      const optionWithoutChanges = options.find((opt) => opt[keyToValue] === option[key][keyToValue]);
      return {
        ...optionWithoutChanges,
        value: optionWithoutChanges[keyToValue],
      };
    } else {
      return {
        ...option[key],
        isFiltering: false,
        selected: false,
        value: option[key][keyToValue],
      };
    }
  }

  clearFilters() {
    this.store.dispatch(new FilterClear());
    this.setFilters(this.reviews);
  }

  get otaOptions() {
    const filters = this.store.selectSnapshot(OnlineReviewsState.filters);
    const otaFilter = filters.find((filter) => filter.name === FILTERS_NAME.OTA);
    if (otaFilter) {
      return otaFilter.options;
    }
    return [];
  }

  get answerOptions() {
    const filters = this.store.selectSnapshot(OnlineReviewsState.filters);
    const answerFilter = filters.find((filter) => filter.name === FILTERS_NAME.ANSWER);
    if (answerFilter) {
      return answerFilter.options;
    }
    return [];
  }

  get npsOptions() {
    const filters = this.store.selectSnapshot(OnlineReviewsState.filters);
    const npsFilter = filters.find((filter) => filter.name === FILTERS_NAME.NPS);
    if (npsFilter) {
      return npsFilter.options;
    }
    return [];
  }

  get qualificationOptions() {
    const filters = this.store.selectSnapshot(OnlineReviewsState.filters);
    const qualificationFilter = filters.find((filter) => filter.name === FILTERS_NAME.QUALIFICATION);
    if (qualificationFilter) {
      return qualificationFilter.options;
    }
    return [];
  }

  get reasonOptions() {
    const filters = this.store.selectSnapshot(OnlineReviewsState.filters);
    const reasonFilter = filters.find((filter) => filter.name === FILTERS_NAME.REASON);
    if (reasonFilter) {
      return reasonFilter.options;
    }
    return [];
  }

  get languageOptions() {
    const filters = this.store.selectSnapshot(OnlineReviewsState.filters);
    const languageFilter = filters.find((filter) => filter.name === FILTERS_NAME.LANGUAGE);
    if (languageFilter) {
      return languageFilter.options;
    }
    return [];
  }

  get displayOTAOptions() {
    return (option: any) => {
      return option.name;
    };
  }

  get displayAnswerOptions() {
    enum VALUES {
      ANSWERED = 1,
      NOT_ANSWERED = 2,
      NOT_ALLOWED = 3,
    }
    return (option: any) => {
      return option.value === VALUES.ANSWERED
        ? this.translateService.instant('commons.answered_reviews')
        : option.value === VALUES.NOT_ANSWERED
        ? this.translateService.instant('commons.not_answered_reviews')
        : this.translateService.instant('commons.answer_not_allowed');
    };
  }

  get displayNpsOptions() {
    enum VALUES {
      PROMOTERS = 'promoters',
      PROMOTER = 'promoter',
      PASSIVES = 'passives',
      PASSIVE = 'passive',
      DETRACTORS = 'detractors',
      DETRACTOR = 'detractor',
    }
    return (option: any) => {
      return option.text === VALUES.PROMOTER
        ? this.translateService.instant(`qualifys.${VALUES.PROMOTERS}`)
        : option.text === VALUES.PASSIVE
        ? this.translateService.instant(`qualifys.${VALUES.PASSIVES}`)
        : this.translateService.instant(`qualifys.${VALUES.DETRACTORS}`);
    };
  }

  get displayQualificationOptions() {
    return (option: any) => {
      return this.translateService.instant(`commons.${option.text}`);
    };
  }

  get displayChipsFn() {
    const currentLanguage = this.store.selectSnapshot(DefaultLanguageState.languageCode);
    return (option) => {
      if (currentLanguage === 'es') return option.name_es;
      if (currentLanguage === 'en') return option.name_en;
      return '';
    };
  }

  get displayLanguageFn() {
    const PREFIX_TRANSLATE = 'languages.id.';
    return (option: any) => {
      return this.translateService.instant(`${PREFIX_TRANSLATE}${option.id || 0}`);
    };
  }

  get otaFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === FILTERS_NAME.OTA).isOpen;
  }

  get answerFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === FILTERS_NAME.ANSWER).isOpen;
  }

  get npsFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === FILTERS_NAME.NPS).isOpen;
  }

  get qualificationFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === FILTERS_NAME.QUALIFICATION).isOpen;
  }

  get reasonFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === FILTERS_NAME.REASON).isOpen;
  }

  get languageFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === FILTERS_NAME.LANGUAGE).isOpen;
  }

  get areFiltersApplied() {
    const filters = this.store.selectSnapshot(OnlineReviewsState.filters);
    return filters.some((filter) => filter.options.some((opt) => opt.isFiltering));
  }

  get isMobileMenu() {
    return window.innerWidth < 765;
  }
}
