import {Component, EventEmitter, Output, Input, ViewChild,TemplateRef, OnInit, OnDestroy, OnChanges, SimpleChanges} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import {NgbModal,NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {UtilService} from "../../util.service";
import {Survey} from "../../model/index";
import { SurveysAnswerComponent } from '../surveys-answer.component';
import { SurveyAnswerService} from "../survey-answer.service";
import { Subscription } from 'rxjs';
import { UsersState } from 'app/states/users/users.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'mh-survey-summary',
  templateUrl: './survey-summary.component.html',
  styleUrls: ['./survey-summary.component.scss'],
  providers:[SurveyAnswerService]
})
export class SurveySummaryComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  survey:Survey;

  @Input()
  productId;

  @Input()
  from:string;

  @Input()
  viewType:string="comment";

  @Input()
  openGuest;

  @Input()
  showCases: boolean = false;

  @Input()
  layoutView: 'list-view' | 'card-view' = 'list-view'

  @Input() showQualify = true

  @Input()
  fromComponent: 'summary' | 'guests' = 'guests';

  @ViewChild('calling', { static: true }) modalCalling: SurveysAnswerComponent;

  @Output()
  showingSurvey: EventEmitter<boolean> = new EventEmitter();

  @Output()
  manageSurvey: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('detailModal', { static: false }) detailModalTemplate: TemplateRef<any>;

  excerpt_is_open

  waitTranslate=false;
  waitTranslateId;

  util;
  currentLanguage;
  surveyLanguage;

  modal:NgbModalRef;
  modalSurvey:NgbModalRef;

  waitDelete    = false;

  can_edit_survey=false;
  customerParam: any = '';

  languageSubs: Subscription;

  constructor(
    private utilService:UtilService,
    private surveyAnswerService:SurveyAnswerService,
    private modalService:NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) {
    this.customerParam = this.route.snapshot.queryParams.customer;
    this.util = this.utilService;
    this.currentLanguage = this.utilService.getLanguageId();
  }

  ngOnInit(){
    this.canEditSurvey();
    this.surveyLanguage = this.survey && this.survey.guest.language ? this.survey.guest.language["id"] : 0;
    this.languageSubs = this.utilService.isLanguageChanged().subscribe(() => {
      this.currentLanguage = this.utilService.getLanguageId();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const openGuest = changes.openGuest;
    if(openGuest && openGuest.firstChange &&
        parseInt(this.utilService.getCurrentHotel().id) === parseInt(this.customerParam) ||
        this.openGuest?.guest?.id === this.survey?.guest?.id) {
      this.checkAutoOpen();
    }
  }

  previewComment(comment){
    return comment.hasExcerpt() &&  !this.excerpt_is_open ? comment.excerpt : comment.text
  }

  seeAllComment(survey_controller){
    this.excerpt_is_open = true;
  }

  showSurvey($event){
    this.showingSurvey.emit($event);
  }

  closeSurvey(){
    const content = this.detailModalTemplate;

    this.modalSurvey = this.modalService.open(content, {
      windowClass: 'modal-xl'
    })

    this.modalSurvey.close();
  }

  checkAutoOpen(){
    setTimeout(_ => {
      this.modalCalling.showModal(this.openGuest,this.productId);
    });
  }

  translate(comment){
    this.waitTranslateId = comment.id
    this.waitTranslate   = true;
    this.surveyAnswerService.translateComment(comment.id, this.surveyLanguage ,this.currentLanguage,this.productId, '', comment.text).subscribe((response:any)=>{
      comment.translate = response.description
      this.waitTranslate = false;
    })
  }

  canEditSurvey(){
    let currentUser = this.utilService.getCurrentUser();
    this.can_edit_survey = ['customer-success','full-admin'].includes(currentUser.role);
  }

  openDialog(content){
    this.modal = this.modalService.open(content, {
      windowClass: 'modal-confirm'
    })
  }

  markSurveyContacted() {
    this.surveyAnswerService.setSurveyContacted(this.survey.answer.id).subscribe((_)=>{
      this.manageSurvey.emit(true);
    });
  }

  closeDialog(){
    this.modalCalling.closeDialog();
  }

  deleteAnswerSurvey(){
    this.waitDelete = true;
    this.surveyAnswerService.deleteAnswerSurvey(this.survey.answer.id).subscribe((response:any)=>{
      if(response.delete) this.survey.deleted = response.delete===1;
      this.waitDelete = false;
      this.closeDialog()
    })
  }

  get productItem() {
    return {
      type: { id: 1 },
      product_id: this.productId,
      item_id: this.survey.answer.id
    }
  }

  sendInfoToGA(survey) {
    this.utilService.ga('guest see survey', 'click', `survey id ${survey.id}`);
  }

  get gridFirst() {
    if(this.fromComponent === 'summary') return 'col-2';
    if(this.layoutView === 'list-view'){
      return {
        'col-2 center-info': !this.isAnonymous,
        'col-7': this.isAnonymous && !this.isAirline,
        'col-6': this.isAnonymous && this.isAirline,
      }
    } else {
      return 'col-12 text-center flex-grow-1 items-center-center';
    }
  }

  get gridSecond() {
    if(this.layoutView === 'list-view'){
      return {
        'col-10': !this.isAnonymous,
        'col-5': this.isAnonymous && !this.isAirline,
        'col-6': this.isAnonymous && this.isAirline,
        'adjust-col': this.isAnonymous && !this.isAirline
      }
    } else {
      return 'col-12';
    }
  }

  get gridSecondCol() {
    if(this.layoutView === 'list-view'){
      return 'col'
    } else {
      return 'col-12';
    }
  }

  get isCardView() { return this.layoutView === 'card-view'}

  get language() { return this.utilService.getCurrentLanguage(); }

  get showDetail(){
    return !this.util.customerIsRetail;
  }

  get checkinLabel(){
    if(this.isAirline) return 'departure';
    if(this.isRetail) return 'init';
    return 'checkin';
  }

  get checkoutLabel(){
    return this.utilService.customerIsRetail ? 'end' : 'checkout';
  }

  get showLabelNights(){
    return !this.utilService.customerIsRetail;
  }

  get placeTranslate() {
    if(this.isAirline) return 'fly_number';
    return 'room';
  }

  get isAirline() { return this.utilService.customerIsAirline };
  get isRetail() { return this.utilService.customerIsRetail };

  get titleBox() {
    if(this.isAirline) return 'followup.guests.info_passenger';
    if(this.isRetail) return 'followup.guests.info_client';
    return 'followup.guests.info_guest';
  }

  get isAnonymous() {
    return this.survey.integration_channel?.id === 3
  }

  get url() {
    return this.router.url
  }

  get checkinDate() {
    if (this.survey?.guest.show_checkin) {
      return this.survey.guest?.checkin || '';
    }
    return ''
  }

  get checkoutDate() {

    if (this.survey?.guest.show_checkout) {
      return this.survey.guest?.checkout || '';
    }
    return ''
  }

  ngOnDestroy() {
    this.languageSubs.unsubscribe();
  }

  openNewModal(template: TemplateRef<any>) {
    const hasProduct = this.utilService.currentHotel['cases'];
    const status = hasProduct ? 'enabled' : 'disabled';
    this.utilService.ga('cases', 'click', `create-case-${this.from}-${status}`)
    if(!hasProduct) return
    this.openModal(template)
  }

  openModal(template: TemplateRef<any>) {
    this.modal = this.modalService.open(template, {
      windowClass: 'modal-md'
    });
  }

  afterCreate() {
    this.closeModal();
    this.manageSurvey.emit(true);
  }

  get users() {
    return this.store.selectSnapshot(UsersState.currentUsers);
  }

  public closeModal = () => {
    this.modal.close();
  }
}




