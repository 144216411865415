import { Pipe, PipeTransform } from '@angular/core';

import { FollowupOnsiteGuestFilter } from 'app/states/followup-onsite/followup-onsite.state';

@Pipe({
  name: 'followupOnsiteFilterSelect'
})
export class FollowupOnsiteFilterSelectPipe implements PipeTransform {

  constructor(
  ) { }

  transform(option: any, type: string, filters: FollowupOnsiteGuestFilter): any {
    switch(type) {
      case 'nps':
        return filters.nps[option.text];
      case 'language':
        return filters.languages ? filters.languages.some(l => l === option) : null
      case 'origin-survey':
        return filters.origin_survey[option.text]
      case 'selector-survey':
        return filters.surveys ? filters.surveys.some(s => s.id === option.id) : null
      case 'cases':
        return filters.cases[option.text];
      case 'contact':
        return filters.contacted[option.text];
    }
  }
}
