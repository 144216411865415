import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NotificationsService } from 'app/notifications/notifications.service';
import { UtilService } from 'app/shared/util.service';
import { Subscription } from "rxjs";

interface Alert{
  translate: [];
  alert_property_type: AlertPropertyType;
  products_ids: number[];
  show_account_number: boolean;
}

interface AlertPropertyType{
  id: number;
  name: string;
  priority: number;
}

@Component({
  selector: 'mh-box-element',
  templateUrl: './box_element.component.html',
  styleUrls: ['./box_element.component.scss'],
})
export class BoxElementComponent{

  @Input()
  title: string = '';

  @Input()
  subTitle: string = '';

  //Actualmente no veo que se ocupe, pero lo dejo dado que en ciertas partes ya lo mandamoss
  @Input()
  tooltip: string | boolean = false;

  @Input()
  placement: string = "center";

  @Input()
  protectedPaid = false;

  @Input()
  variant: string = 'legacy'

  @Input()
  externalLink: any = null;

  @Input()
  backButton = {
    hasBackButton: false,
    textButton: null,
    link: null
  };

  @Input()
  extraWarning = false;

  @Output()
  handleExternalLinkClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  handleBackLink: EventEmitter<any> = new EventEmitter<any>();

  isPaid: boolean;
  hasAlerts: boolean;

  subscription: Subscription;
  alertsListener: Subscription;
  isPaidListener: Subscription;

  constructor(
    private notificationsService: NotificationsService,
    private utilService: UtilService) {
      this.isPaid = this.utilService.getIsPaid();
      this.checkAlerts();
      this.alertsListener = this.notificationsService.hasModalAlerts().subscribe(_ => {
        this.checkAlerts();
      });
      this.subscription = this.utilService.isCustomerChanged().subscribe(hotel => {
        this.checkIsPaid();
      });
      this.isPaidListener = this.utilService.isPaid().subscribe(paid => {
        this.isPaid = paid;
      });
  }

  ngOnInit() {

  }

  ngOnChanges(){
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.alertsListener.unsubscribe();
  }

  checkAlerts(): void{
    let alert: any = this.notificationsService.getResponseAlert();
    if(alert !== undefined && alert !== null && (alert.length > 2)) {
      alert = JSON.parse(alert);
      const typeAlert = this.getTypeAlert(alert);
      this.hasAlerts = (alert !== undefined && alert.length > 0 && typeAlert === 'blocking');
    } else {
      this.hasAlerts = false;
    }
  }

  getTypeAlert(alert: Alert[]): string{
    return alert[0].alert_property_type.name;
  }

  checkIsPaid() {
    if (!this.utilService.getIsPaid()) this.utilService.checkWarings('box_element');
  }


  hasTitle(){
    return this.title!=='';
  }

  hasSubTitle() {
    return this.subTitle !== '';
  }

  checkCustomerStates() {
    this.notificationsService.checkModalAlert('box_elememt');
  }

  handleLinkClick() {
    this.handleExternalLinkClick.emit();
  }

  handleBackLinkClick(link) {
    this.handleBackLink.emit(link);
  }

}
