import { Component } from '@angular/core';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-wait-full',
  templateUrl: './wait_full.component.html',
  styleUrls: ['../../../../../../assets/scss/shared/elements/wait_full.scss'],
})
export class WaitFullComponent{

  isReady:boolean=false;
  hidden:boolean=false;
  progess=0;
  numberOfElementWait = 1;
  elementLoad = 0;

  constructor(
    private utilService:UtilService
  ) {  }

  ngAfterViewInit() {
    this.utilService.isLoadCustomerReady().subscribe(response=>{
      this.readyToLoad();
    });
  }

  readyToLoad(){
    this.elementLoad = this.elementLoad<=this.numberOfElementWait ? (this.elementLoad+1) : this.numberOfElementWait;
    this.progess = Math.round(100/this.numberOfElementWait*this.elementLoad);
    if(this.elementLoad>=this.numberOfElementWait) this.setReady();
  }

  setReady(){
    setTimeout(_ => {
      this.isReady = true;
      setTimeout(_ => {
        this.hidden = true;
      },100);

    },1700);
  }

}
