import { Component, OnInit, Input } from '@angular/core';
import { Guest } from 'app/shared/model/guest.model';
import { TranslateService } from '@ngx-translate/core';
import {Subscription} from 'rxjs';

export interface ResponseComment{
  answered_survey: any;
  comment: any;
  guest: Guest;
  semantic_info: any;
}

@Component({
  selector: 'mh-survey-guest-comment',
  templateUrl: './survey-guest-comment.component.html',
  styleUrls: ['./survey-guest-comment.component.scss']
})
export class SurveyGuestCommentComponent implements OnInit {

  @Input()
  responseComment: any;

  @Input()
  survey;

  @Input()
  productId: number;

  @Input()
  isDateType: boolean;

  guest: Guest;
  comment: string;

  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.survey[0].surveys[0].integration_channel = this.responseComment.guest.integration_channel;
    this.survey[0].surveys[0].origin_name = this.responseComment.answered_survey.origin;
    this.guest = (new Guest()).createFromApi(this.responseComment.guest);
    this.setComment();
  }

  setComment() {
    if( this.isDateType ) {
      const date = this.responseComment?.comment.text.split('-');
      const month = this.translateService.instant(`datepicker.normal-months.${date[1]}`);
      this.comment = `${date[2]} ${month} ${date[0]}`;
    } else {
      this.comment = this.responseComment?.comment;
    }
  }

  ngOnDestroy() {
  }
}
