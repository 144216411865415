import { Pipe, PipeTransform } from '@angular/core'

import { SemanticComment } from 'app/shared/model/semantic-comment.model';
import { SemanticCommentFilter } from 'app/states/semantic/semantic.state'

@Pipe({ name: 'semanticNotationFilter' })
export class SemanticNotationFilterPipe implements PipeTransform {

  transform(value: SemanticComment[], filters: SemanticCommentFilter): SemanticComment[] {
    const notations = value.filter((comment) =>
      (comment.has_negative_notation && filters.notations.negative) ||
      (comment.has_positive_notation && filters.notations.positive)
    )
    if(notations.length > 0)
      return notations
    else
      return value
  }
}
