export interface MenuItem {
  active?: boolean;
  allSelected?: boolean;
  id: number;
  type_id: number;
  product_id: number | null;
  slug: string;
  link: string;
  icon: string;
  title: string;
  industry_id: number;
  menu_id: number;
  mobile: boolean;
  superadmin: boolean;
  tag: string | null;
  childrens: MenuItem[];
}

export enum MENU_IDS {
  SIDEBAR = 1,
  TOPBAR,
  USERMENU,
  MANAGERS,
  CONFIGS,
}
