import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mh-table-setup',
  templateUrl: './table-setup.component.html',
  styleUrls: ['./table-setup.component.scss']
})
export class TableSetupComponent implements OnInit {

  @Input() survey
  @Input() showStatusChildren: boolean

  constructor() { }

  ngOnInit() {
  }

  processStatusClass(status: string) {
    return {
      'setup-ok': status === 'Ok',
      'setup-warning': status === 'Warning',
      'setup-error': status === 'Error'
    }
  }

}
