<mh-wait-full #waitFull></mh-wait-full>

<section class="tw-flex tw-justify-between tw-w-full tw-p-2 tw-rounded-lg tw-bg-red-200 tw-my-3 tw-border-red-950" *ngIf="displayErrorCredentials">
  <span class="tw-text-red-950">
    {{errorMessage | translate: {attempts: remainingAttempts} }}
  </span>
  <i class="far fa-times tw-text-red-950 tw-self-center tw-mr-3 tw-cursor-pointer" (click)="displayErrorCredentials = false"></i>
</section>

<form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
  <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
    <label for="username">{{'themes.public.login.email' | translate }}</label>
    <input type="text" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel" required />
    <div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
  </div>
  <div class="form-group tw-relative" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
    <label for="password">{{'themes.public.login.password' | translate }}</label>
    <input [type]="inputType" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required />
    <mat-icon class="tw-absolute tw-top-1/2 tw-right-1" (click)="togglePasswordType()">{{ inputType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
    <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
  </div>

  <div class="form-group">
    <div class="row no-gutters align-items-center">
      <div class="languages col-6">
        <mat-form-field>
          <mat-select [(value)]="currentLanguage">
            <ng-container *ngFor="let iso of availableLanguages">
              <mat-option [value]="iso" (click)="setLanguage(iso)">{{'languages.iso.'+iso | translate}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <div class="recovery_password">
          <a routerLink="/recordar-contrasena" (click)="trackForgot()">{{'forms.forgot_password' | translate}}<i class="fal fa-key"></i></a>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="buttons_actions">
      <button [disabled]="loading" class="btn btn_send">{{'themes.public.login.go' | translate }}<i class="far fa-sign-in"></i></button>
      <mh-spinner-classic [class.loading]="!loading" ></mh-spinner-classic>
    </div>
  </div>

  <div class="not_account row">
      <div class="col-6"> <a [href]="needHelpUrl" target="_blank">{{'themes.public.login.need_help' | translate}}</a></div>
      <div class="col-6"> <a [href]="demoUrl" target="_blank">{{'themes.public.login.not_account' | translate}}</a></div>
  </div>
</form>




