import {Component, Input, Output, EventEmitter, SimpleChange, OnChanges, OnDestroy} from '@angular/core';
import {UtilService}    from "../../../shared/util.service";
import {TripadvisorService} from "./interchain-online-tripadvisor.service";
import {forkJoin}   from "rxjs";
import {AppComponent} from "../../../layouts/app/app-component";
import {CustomerService} from "../../../shared/customer.service";

import {ViewChild} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Period } from 'app/shared/model';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mh-inter-chain-tripadvisor',
  templateUrl: './interchain-online-tripadvisor.compontent.html',
  styleUrls: ['./interchain-online-tripadvisor.component.scss'],
  providers:[TripadvisorService,CustomerService]
})


export class TripadvisorComponent extends AppComponent implements OnChanges, OnDestroy {

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  chain;
  @Input()
  currentDates = null;

  data;
  firstCall = true;
  totals = {}
  chainActives = [];

  @Output()
  isReady: EventEmitter<boolean> = new EventEmitter();

  showFilter:boolean=true;

  public months: any[] = [];
  actives = [];
  customers:any[]=[];
  singleElement;

  headerTable = [
    'customer',
    'total_period',
    'qualification',
    'ranking',
    'cleaning',
    'rooms',
    'service',
    'location',
    'value'
  ]

  tooltipHeaders = this.headerTable.filter(x => {
    if(x==='customer' || x==='ranking') {
      return false;
    }
    return true;
  });

  dataTable: any[] = [];
  dataSource: any = new MatTableDataSource([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  customersStateSubscribe: Subscription;

  constructor(
    private utilService: UtilService,
    private tripadvisorService:TripadvisorService,
    private store: Store
  ) {
    super();
    this.months = utilService._monthNames["es"];
    this.customersStateSubscribe = this.customersReady$.subscribe(customers => {
      if ( this.currentDates ) {
        this.init();
      }
    });
  }

  ngOnChanges(changes): void {
    this.chainActives = this.chain;
    if (changes?.chain || changes.currentDates) {
      this.init();
    }
  }

  ngOnDestroy() {
    this.customersStateSubscribe.unsubscribe();
  }

  init(fromFilterCustomers = false) {
    this.waiting = true;
    const activesChains = fromFilterCustomers ? this.actives : this.chainActives;
    const customers =
      activesChains.length > 0
        ? activesChains.map((hotel) => hotel.id).join(',')
        : this.store
            .selectSnapshot((state) => state.customers.relativeCurrentChain)
            .map((c) => c.id)
            .join(',');

    if (customers.length) {
      forkJoin([
        this.tripadvisorService.getRanking({"startDate": this.startDate,"endDate": this.endDate}, customers)
      ]).subscribe(response => {
        this.data = response[0];
        this.createTable();
        this.waiting = false;
        this.firstCall = false;
        this.isReady.emit(true);
      })
    }
  }

  reset(){
    this.dataTable = [];
    this.isReady.emit(false);
    this.dataSource = new MatTableDataSource([]);
    this.totals = {};
  }

  filterCustomers($event){
    this.actives = $event;
    this.init(true);
  }

  createTable() {
    this.reset();
    this.headerTable = this.data?.average
      ? ['customer', ...Object.keys(this.data.average).filter((key) => this.headerTable.includes(key))]
      : this.headerTable;
    this.chainActives.forEach((customer: any) => {

      const data = this.data.values.find((x: any) => x.customer_id === customer.id);
      if (data) {
        const cell = {};
  
        this.headerTable.forEach(key=> {
          if(key === 'customer') {
            cell[key] = customer;
          } else if(key === 'ranking') {
            cell[key] = data[key] ? this.utilService.convertFormatRankingTA(data.ranking, data) : new Period();
          }else {
            cell[key] = data[key] ? data[key] : new Period();
          }
        });
        this.dataTable.push(cell);
      }
    });

    this.totals = {};

    this.headerTable.forEach(key=> {
      if(key!=='customer') {
        this.totals[key] = this.data.average[key] ? this.data.average[key] : new Period();
      }
    });

    this.dataSource = new MatTableDataSource(this.dataTable);
    this.isReady.emit(true);
  }

  get startDate() {
    return this.currentDates.startDate;
  }

  get endDate() {
    return this.currentDates.endDate;
  }
}


