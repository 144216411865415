import { Pipe, PipeTransform } from '@angular/core';

enum ANSWER {
  HAS_ANSWER = 0,
  HAS_NO_ANSWER = 1,
  NO_ADMIT = 2
}
const FILTER_VALUES = [
  {id: 0, value: 1, text: 'Respondido'},
  {id: 1, value: 2, text: 'No Respondido'},
  {id: 2, value: 3, text: 'No admite respuestas'},
];

@Pipe({
  name: 'onlineReviewsAnswerFilter'
})
export class OnlineReviewsAnswerFilterPipe implements PipeTransform {

  transform(reviews, filters): unknown {
    let filteredReviews = [...reviews];
    if ( filteredReviews && filteredReviews.length > 0 ) {
      const filter = filters.find(filter => filter.name === 'answer');
      const filtering = filter.options.some(option => option.isFiltering);
      if(filter && filtering) {
        const options = filter.options.filter(option => option.isFiltering).map(option => option.value);
        filteredReviews = filteredReviews.filter(item => {
          return options.includes(
            (item.flag_answered === 0) 
              ? FILTER_VALUES.find(value => value.id === ANSWER.HAS_NO_ANSWER).value 
              : (item.flag_answered === 1) 
              ? FILTER_VALUES.find(value => value.id === ANSWER.HAS_ANSWER).value
              : FILTER_VALUES.find(value => value.id === ANSWER.NO_ADMIT).value
          )
        })
      }
    }
    return filteredReviews;
  }

}
