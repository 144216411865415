import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ChainExcutiveService {

  private metricsEndpoint ={
    '1': {
      'nps' : `${environment.followupPath}interchains/executive/nps`,
      'semantic': `${environment.followupPath}interchains/executive/semantic`,
      'semantic_index': `${environment.customerPath}executive/semantic/index`,
      'rates': `${environment.followupPath}interchains/executive/rates`,
      'pms': `${environment.followupPath}interchains/executive/emails`
    },
    '3' : {
      'chains': `${environment.onlinePath}interchains/executive/chains`
    }
  }

  constructor(private http:HttpClient) { }

  getExcutiveAreas(dates: any, customerIds: string, surveyIds: any, version = 1) {
    const url = (version === 2)
      ? `${environment.followupPath}corporative/executive/areas`
      : `${environment.followupPath}interchains/executive/areas`;

    const params = new HttpParams()
      .set('surveys_ids',surveyIds)
      .set('customers_ids', customerIds)
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)

    return this.http.get(url, {params});
  }

  getExcutiveAreasAccummulated(year:any,customerIds:string,surveyIds:any, version = 1){
    const url = (version === 2)
      ? `${environment.followupPath}corporative/executive/areas/accumulated/${year}`
      : `${environment.followupPath}interchains/executive/areas/accumulated/${year}`;
    const params = new HttpParams()
      .set("surveys_ids",surveyIds)
      .set("customers_ids",customerIds);
    return this.http.get(url,{params});
  }

  public getExcutiveMetric(type:string, dates:any,customerIds:string, productId:number, surveyIds:string){
    const url= this.metricsEndpoint[productId][type];
    let params:HttpParams= new HttpParams();

    if(productId === 3){
      params = params.set('customer_ids',customerIds)
    }else{
      params = params.set('customers_ids',customerIds)
      params = params.set("surveys_ids",surveyIds)
    }
    if(productId) params = params.set("product_id",productId.toString());
    params = params.set("start_date",dates.startDate);
    params = params.set("end_date",dates.endDate);
    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      );
  }

  public getExcutiveMetricAccumulated(type:string, year,customerIds:string, productId:number, surveyIds:string){
    const url= `${this.metricsEndpoint[productId][type]}/accumulated/${year}`;
    let params:HttpParams= new HttpParams()
      .set('customers_ids',customerIds);

    if(productId !== 3){
      params = params.set("surveys_ids",surveyIds)
    }
    if(productId) params =  params.set("product_id",productId.toString());
    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      );
  }

  getExecutiveMetricRateResponse(customerIds, surveysIds, dates, productId, version = 1) {
    let url;
    if (productId  !== 1) {
      url = (version === 2)
        ? `${environment.followupPath}corporative/executive/response/rate`
        : `${environment.followupPath}interchains/executive/rates`;
    } else {
      url = (version === 2)
        ? `${environment.followupPath}corporative/executive/surveys/response/rate`
        : `${environment.followupPath}interchains/executive/surveys/rates`;
    }
    const params = new HttpParams()
      .set('customers_ids', customerIds)
      .set('surveys_ids', surveysIds)
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('product_id', productId);

    return this.http.get(url, {params});
  }

  getExecutiveMetricRateResponseAcummulated(customerIds, surveyIds, year, productId, version = 1) {
    let url;
    if (productId !== 1) {
      url = (version === 2)
        ? `${environment.followupPath}corporative/executive/response/rate/accumulated/${year}`
        : `${environment.followupPath}interchains/executive/rates/accumulated/${year}`;
    } else {
      url = (version === 2)
      ? `${environment.followupPath}corporative/executive/surveys/response/rate/accumulated/${year}`
      : `${environment.followupPath}interchains/executive/surveys/rates/accumulated/${year}`;
    }
    const params = new HttpParams()
      .set('customers_ids', customerIds)
      .set('surveys_ids', surveyIds)
      .set('product_id', productId);

      return this.http.get(url, {params});
  }

  getExecutiveNpsMetric(customerIds, surveysIds, dates, version) {
    const url = (version === 2)
      ? `${environment.followupPath}corporative/executive/nps`
      : `${environment.followupPath}interchains/executive/nps`;
    const params = new HttpParams()
      .set('customers_ids', customerIds)
      .set('surveys_ids', surveysIds)
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate);
    return this.http.get(url, {params});
  }


  getExecutiveNpsMetricAccumulated(customerIds, surveysIds, year, dates, version) {
    const url = (version === 2)
      ? `${environment.followupPath}corporative/executive/nps/accumulated/${year}`
      : `${environment.followupPath}interchains/executive/nps/accumulated/${year}`;
    const params = new HttpParams()
      .set('customers_ids', customerIds)
      .set('surveys_ids', surveysIds)
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate);
    return this.http.get(url, {params});
  }

  getExecutiveIRO(customersIds, startDate, endDate) {
    const url = `${environment.onlinePath}interchains/iro/executive/chains`;
    const params = new HttpParams()
      .set('customers_ids', customersIds)
      .set('start_date', startDate)
      .set('end_date', endDate);
    return this.http.get(url, { params });
  }

  getExecutiveIROAccumulated(customersIds, year) {
    const url = `${environment.onlinePath}interchains/iro/executive/chains/accumulated/${year}`;
    const params = new HttpParams().set('customers_ids', customersIds);
    return this.http.get(url, { params });
  }
}
