import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Communications } from './models/communications';
import { NotificationsService } from './services/notifications.service';
import { environment } from 'src/environments/environment';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { MuteNotificationsModalComponent } from './components/mute-notifications-modal/mute-notifications-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mh-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @Input() alertsData;
  @Input() newsData;
  @Input() type;
  @Input() hasMoreAlerts;
  @Input() hasMoreNews;
  @Input() currentUser;
  @Input() currentLanguageId;
  @Output() emitCloseCentral = new EventEmitter<boolean>();
  @Output() emitShowMore = new EventEmitter<{ type: string }>();
  @Output() emitUpdate = new EventEmitter<boolean>();
  public pageSizeAlerts = 10;
  public pageSizeNews = 10;
  public page = 0;
  public subscriptions: Subscription = new Subscription();
  openActionMenu = false;
  tabIndex = 1;

  constructor(private notificationService: NotificationsService, private modalService: ModalService) {}

  ngOnInit(): void {
    if (this.type === 'history') {
      this.tabIndex = 0;
    } else {
      this.tabIndex = this.alertsData?.length > 0 ? 0 : 1;
    }
  }

  async handleNotificationStatus(event: { communication: Communications; status: string }) {
    if (['OMITTED', 'READ'].includes(event.communication.notifications_status)) {
      return;
    }

    try {
      const isAlert = event.communication.notifications_type === 'ALERT';
      const url = `${environment.notificationsPath}notification-${isAlert ? 'alerts' : 'news'}/${
        event.communication.id
      }/users/${this.currentUser.id}/status/${event.status}`;
      const res = await this.notificationService.changeNotificationStatus(url).toPromise();
      if (res) {
        const notificationsRef = isAlert ? this.alertsData : this.newsData;
        notificationsRef.forEach((item) => {
          if (item.id === event.communication.id) item.notifications_status = event.status;
        });
        this.emitUpdate.emit(isAlert);
      }
    } catch (error) {
      console.error(error);
    }
  }

  trackByFn(index: number, item: Communications) {
    return item.id;
  }

  closeCentral() {
    this.emitCloseCentral.emit(true);
  }

  openMuteNotificationsModal() {
    const modalClass = ['overlay-panel', 'lg', '!tw-h-auto', '!tw-overflow-y-auto'];
    const modalData = {
      data: {
        currentUser: this.currentUser,
      },
    };
    this.modalService.open(MuteNotificationsModalComponent, modalData, modalClass);
    this.closeCentral();
  }

  async markAllAsRead() {
    try {
      const notificationType = this.tabIndex === 0 ? 'ALERT' : 'COMMUNICATION';
      const res = await this.notificationService.markAllAsRead(this.currentUser.id, notificationType).toPromise();
      if (res) {
        const isAlert = this.tabIndex === 0;
        this.emitUpdate.emit(isAlert);
        this.closeCentral();
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleChangeTab(event) {
    this.tabIndex = event;
  }

  ngOnDestroy(): void {
    this.pageSizeAlerts = 10;
    this.pageSizeNews = 10;
  }
}
