<mh-box-element [title]="title">
  <div class="box box-summary" [ngStyle]="style" [class.waiting]="waiting">
    <ng-container *ngIf="waiting">
      <widget-wrapper-wait [width]="4" [drawTop]="[1,1,0,1]" [drawMiddle]="[1,1,0,1]" [drawBottom]="[1,1,0,1]" [height]="20">
        <ng-container *ngTemplateOutlet="summarySurveys"></ng-container>
      </widget-wrapper-wait>
    </ng-container>
    <div *ngIf="!waiting">
      <ng-container *ngTemplateOutlet="summarySurveys"></ng-container>
    </div>
  </div>
</mh-box-element>

<ng-template #summarySurveys>
  <div class="row align-items-center no-gutters full-height">
    <div class="col-8">
      <div class="widgets-rates">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="col-4">
      <div class="total-rate">
        <div class="title-rate" [ngbTooltip]="tooltipRate ? tooltipMetric : null">
          <span>{{'inter-chain.summary.table.mh_rate_response' | translate}}</span>
        </div>
        <div class="icon-rate"><i class="far fa-tachometer"></i></div>
        <div class="percent-rate" *ngIf="percentRate">
          <div class="percent">{{percentRate | formatPercent}}</div>
          <div class="formula">{{formulaRate}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tooltipMetric>
  <div [innerHtml]="tooltipRate"></div>
</ng-template>
