import { Observable ,  Subject ,  interval } from 'rxjs';
import { plainToClass } from "class-transformer";
export class AppComponent{

  waiting = true;
  isHeavyQuery = false;
  messageIndex:number = 0;
  messagesWaiting:string[] =["wait_please", "wait_timeout", "wait_sorry","wait_minute", "wait_finish", "wait_fail"];

  isManyInterchain:boolean = false;
  callingInterchain:number = 0;
  chunkInterchain:any[] = [];
  currentChain:any[] = [];
  totalSizeChain:number = 0;
  totalSizeBlock:number = 0;

  loadingCustomersData:boolean=false;

  heavyQuerySuscribe: any;
  timer;
  waitingSubject = new Subject<boolean>();
  customersDetails: any[] = [];
  error=false;


  constructor(){
    this.isWatingChange().subscribe(waiting => {
      this.heavyQuery();
    });
  }

  heavyQuery(){
    this.heavyQuerySuscribe = interval(3000).subscribe(x => {
      if(this.waiting){
        this.isHeavyQuery = true;
      }else{
        this.heavyQuerySuscribe.unsubscribe()
      }

    });
  }


  setWaiting(waiting: boolean, messagesWaiting=[]){
    this.waiting = waiting;
    if(!waiting){
      this.isHeavyQuery = false;
      this.clearMessages()
      this.heavyQuerySuscribe.unsubscribe()
    }

    if(waiting && this.timer === undefined){
      this.timer = setInterval(()=> {this.showMessages()},10000);
    }

    this.waitingSubject.next(waiting);
  }

  isWatingChange(): Observable<boolean> {
    return this.waitingSubject.asObservable();
  }

  showMessages(){
    this.messageIndex = this.messageIndex + 1;
    if(this.messageIndex > this.messagesWaiting.length){
      this.clearMessages();
    }
  }

  clearMessages(){
    clearInterval(this.timer);
    this.isHeavyQuery = false;
    this.timer = undefined;
    this.messageIndex = 0;
  }

  checkSizeInterchain(chain:any[]=[]){
    this.isManyInterchain = chain.length>3;
  }

  resetInterchain(){
    this.currentChain=[];
    this.callingInterchain = 0;
    this.loadingCustomersData = false;
  }

  nextChain(){
    this.callingInterchain = this.callingInterchain + 1;
    this.currentChain = this.chunkInterchain[this.callingInterchain];
  }

  startChunkInterchain(actives:any[], split=true){
    let size             = split ? this.getChunkSizeArray(actives, split) : actives.length;
    this.chunkInterchain = this.chunkArray(actives,size);
    this.totalSizeChain  = actives.length;
    this.totalSizeBlock  = this.chunkInterchain.length;
    this.currentChain    = this.chunkInterchain[0];
  }

  getChunkSizeArray(actives:any[],split=true){
    if(!split) return actives.length;
    let chunk =5;

    if(actives.length>15){
      chunk = 7;
    }

    if(actives.length>30){
      chunk = 9;
    }

    if(actives.length>40){
      chunk = 10;
    }

    if(actives.length>50){
      chunk = 12;
    }

    if(chunk+3 >= actives.length) chunk = actives.length;

    return chunk;

  }

  chunkArray(array,block){
    let chunks: any[] = [];
    let i = 0;
    let n = array.length;
    while (i < n){
      chunks.push(array.slice(i, i += block));
    }
    return chunks;
  }

  isChainReady(chain:any[]){
    return chain.length > 0
  }

  isFinishLoadData(){
    return this.callingInterchain >= this.chunkInterchain.length;;
  }

  isLoadingData(){
    return this.loadingCustomersData;
  }

  finishLoadData(){
    this.loadingCustomersData = false;
  }

  startLoadData(){
    this.loadingCustomersData = true;
  }
  canLoadData(){
    return this.loadingCustomersData;
  }

  setCustomerDetail(response){
    this.customersDetails = [];
    for(let customer of response){
      let setup = plainToClass(Setup,customer.customer_setup);
      this.customersDetails.push(new CustomerSetup(customer.id, setup))
    }
  }

}

class CustomerSetup{
  id_customer:number;
  setup: Setup[] = [];
  constructor(id,setup){
    this.id_customer = id;
    this.setup       = setup;
  }
}

class Setup{
  id_ota:number;
  url_customer_ota:string;
  constructor(id_ota,url_customer_ota){
    this.id_ota = id_ota;
    this.url_customer_ota = url_customer_ota;
  }
}
