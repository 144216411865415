import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CasesHelperService } from 'app/core-lib/dialogs/create-case-modal/services/cases-helper.service';
import { Review } from 'app/new-fs/online/reviews/data-review.model';
import { OnsiteGuests } from 'app/shared/cases/types/OnsiteGuests.model';
import { Survey } from 'app/shared/model';
import { PrecheckinGuest } from 'app/shared/prestay/precheckin-guest/precheckin-guest.interface';
import { PrestayOrderWebCheckin } from 'app/prestay/models/Order.model';
import { Info } from 'app/shared/model/guest-answer.model';
import { Case } from 'app/new-fs/desk/models/Case.model';

@Component({
  selector: 'mh-core-lib-product-for-case',
  templateUrl: './core-lib-product-for-case.component.html',
  styleUrls: ['./core-lib-product-for-case.component.scss'],
})
export class CoreLibProductForCaseComponent implements OnInit, OnChanges {
  @Input() case: Case;
  @Input() item: Survey & PrecheckinGuest & Review & OnsiteGuests & PrestayOrderWebCheckin & Info;
  @Input() type: string;
  @Input() language: string;
  @Input() createCase = false;
  @Input() source: string;
  @Input() customerId: number;
  @Input() associatedGuest: OnsiteGuests;
  @Input() isMobileOrTablet = false;
  @Input() customClassContainer: string;
  productName: string;
  associated;
  loading = false;
  url: string;

  private _product;
  @Input() set product(value) {
    if (value) {
      this._product = value?.product_id ? value : { product_id: value };
    }
  }

  get product() {
    return this._product;
  }

  services = {
    'survey-answer-followup': (itemId) => this.casesHelperService.getFollowupAnswer(itemId),
    'survey-answer-onsite': (itemId) => this.casesHelperService.getOnsiteAnswer(itemId),
    review: (itemId) => this.casesHelperService.getOnlineReview(itemId),
    'pre-checkin': (itemId) => this.casesHelperService.getPrestayPrecheckin(this.customerId, itemId),
    order: (itemId) => this.casesHelperService.getPrestayOrder(this.customerId, itemId),
  };

  associations = {
    'survey-answer-followup': 'nps',
    'survey-answer-onsite': 'nps',
    review: 'review',
    'pre-checkin': 'precheckin',
    order: 'order',
  };

  constructor(private casesHelperService: CasesHelperService) {}

  ngOnChanges({ item }: SimpleChanges) {
    if (item && !item.firstChange && item.currentValue) {
      this.type = this.case?.product_item?.type?.name;
      if (this.source === 'detail' && (!this.associatedGuest || this.type === 'survey-answer')) this.fetchData();
    }
  }

  ngOnInit(): void {
    if (this.source === 'detail' && (!this.associatedGuest || this.case.product_item)) this.fetchData();
    this.getProductName();
  }

  async fetchData() {
    if (this.type === 'survey-answer') this.associatedGuest = null;
    this.loading = true;
    const typeName =
      this.product?.product_id === 1
        ? `${this.type}-followup`
        : this.product?.product_id === 2
        ? `${this.type}-onsite`
        : `${this.type}`;
    const itemId = this.product.item_id;
    this.type = this.associations[typeName];

    try {
      this.item = await this.services[typeName](itemId).toPromise();
      this.loading = false;
      this.setUrls();
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  getProductName() {
    if (this.type === 'review') {
      this.productName = 'navbar.product.default.review_on';
      return;
    }
    const nameFromProductId = this.product?.product_id === 1 ? 'followup' : 'onsite';
    this.productName = `navbar.product.default.${this.type === 'nps' ? nameFromProductId : this.type}`;
  }

  setUrls() {
    if (this.type === 'order') {
      const orderId = this.item?.orders[0]?.id;
      const date = this.item?.orders[0]?.created_at.split(' ')[0];
      this.url = `/prestay/ordenes?order=${orderId}&customer=${this.customerId}&date=${date}`;
      return;
    }

    if (this.type === 'precheckin') {
      const date = this.item?.guest?.checkin.split(' ')[0];
      this.url = `/prestay/precheckin?guest=${this.item?.guest?.id}&customer=${this.customerId}&date=${date}`;
      return;
    }

    if (this.type === 'nps') {
      const product = this.product?.product_id === 1 ? 'followup' : 'onsite';
      const date = this.item?.answered_survey?.date.split(' ')[0];
      this.url = `/${product}/huespedes?guest=${this.item?.guest?.guess_id}&customer=${this.customerId}&date=${date}`;
    }

    if (this.type === 'review') {
      const reviewId = this.item?.id;
      const date = this.item?.publication_date;
      this.url = `/online/resenas?review=${reviewId}&customer=${this.customerId}&date=${date}`;
    }
  }

  goToProduct() {
    window.open(this.url, '_blank');
  }

  get fullName() {
    if (this.associatedGuest) {
      return `${this.associatedGuest.guest_name.split(' ')[0]} ${this.associatedGuest.guest_last_name.split(' ')[0]}`;
    }

    if (this.source === 'detail') {
      const lastName = this.type === 'order' || this.type === 'precheckin' ? 'lastname' : 'last_name';
      return `${this.item?.guest?.name.split(' ')[0]} ${this.item?.guest[lastName].split(' ')[0]}`;
    }

    const guest = this.type === 'nps' || this.type === 'order' ? this.item?.guest : this.item;
    const lastName = this.type === 'nps' ? 'lastName' : 'lastname';
    return `${guest?.name.split(' ')[0]} ${guest?.[lastName].split(' ')[0]}`;
  }

  get hasRoom() {
    return this.case?.product_item?.product_id === 1 || this.case?.product_item?.product_id === 2;
  }

  get isPrecheckin() {
    return this.type !== 'precheckin' && this.type !== 'pre-checkin';
  }
}

export interface ProductItem {
  product_id: number;
  item_id?: number;
  type?: { id: number; name: string };
}
