import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'widget-circle-letters',
  templateUrl: './circle-letters.component.html',
  styleUrls: ['./circle-letters.component.scss']
})
export class CircleLettersComponent implements OnInit {

  @Input()
  satisfaction;

  @Input()
  letters: string;

  @Input()
  size = 25;

  constructor() { }

  ngOnInit() {
  }

}
