import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mh-core-lib-error-widget',
  templateUrl: './error-widget.component.html',
  styleUrls: ['./error-widget.component.scss']
})
export class CoreLibErrorWidgetComponent {
  @Input() titleTranslate: string;
  @Input() messageTranslate: string;
  @Input() centerTitle = false;
  @Output() retryFetch = new EventEmitter();

  get message() {
    return this.messageTranslate ? this.messageTranslate : 'error.generic.message';
  }
}
