<section class="tw-p-[10px] tw-m-[10px]">
  <div class="content-container tw-max-h-[40rem] md:tw-max-h-full tw-overflow-y-auto tw-px-5">
    <div class="tw-flex tw-flex-row tw-justify-center tw-my-6" *ngIf="stepNames.length > 0; else normalSteps">
      <ng-container *ngFor="let step of steps; index as idx">
        <div class="tw-rounded-full tw-border-2 tw-p-1 tw-w-6 tw-h-6 tw-flex tw-justify-center tw-items-center"
          [ngClass]="{'tw-border-border-main tw-font-bold': idx === selectedIndex}">{{idx + 1}}</div>
        <div class="tw-mx-5" [ngClass]="{'tw-font-bold': idx === selectedIndex}">{{ 'user_management.create_user.stepper.' + stepNames[idx] | translate }}</div>
        <div class="tw-mr-5" *ngIf="idx < steps.length - 1"><i class="fal fa-greater-than"></i></div>
      </ng-container>
    </div>
    <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
  </div>

  <footer class="tw-flex tw-justify-center tw-pt-3 tw-pb-0 tw-mt-0">
    <button
      [hidden]="selectedIndex === 0"
      class="tw-mx-2 tw-h-[42px] tw-w-40 tw-text-sm tw-font-medium tw-text-center tw-group tw-text-main-font tw-bg-fff tw-rounded-default-btn tw-py-13px tw-px-20px hover:tw-text-fff hover:tw-bg-action-hover active:tw-bg-main active:tw-text-fff disabled:tw-text-disabled-font disabled:tw-bg-main-disabled"
      cdkStepperPrevious>< {{ 'user_management.create_user.previous' | translate }}</button>
    <button
      class="tw-mx-2 tw-h-[42px] tw-w-40 tw-transition-all tw-text-base tw-font-medium tw-text-center tw-rounded-default-btn tw-py-8px tw-px-20px tw-text-fff tw-bg-main-orange hover:tw-bg-main-orange-hover active:tw-bg-main-orange-hover disabled:tw-bg-main-disabled disabled:tw-text-font-disabled"
      [disabled]="isFromProfilePage && disableNextBtn"
      *ngIf="showSaveBtn"
      (click)="save()"
      >{{ 'forms.save' | translate }}</button>
    <button
      [hidden]="selectedIndex + 1 >= steps.length"
      [disabled]="disableNextBtn"
      class="tw-mx-2 tw-h-[42px] tw-w-40 tw-text-sm tw-font-medium tw-text-center tw-group tw-text-main-font tw-bg-fff tw-rounded-default-btn tw-py-13px tw-px-20px hover:tw-text-fff hover:tw-bg-action-hover active:tw-bg-main active:tw-text-fff disabled:tw-text-disabled-font disabled:tw-bg-main-disabled"
      cdkStepperNext>{{ 'user_management.create_user.next' | translate }} ></button>
    </footer>
  </section>

<ng-template #normalSteps>
  <div class="tw-flex tw-flex-row tw-justify-center tw-my-6">
    <h2>Step {{ selectedIndex + 1 }}/{{ steps.length }}</h2>
  </div>
</ng-template>
