<article 
    class="tw-flex tw-flex-col tw-bg-white tw-shadow-1 tw-pl-5 tw-pr-16 tw-py-3 tw-rounded-lg tw-border-l-[9px]"
    [ngClass]="{
        'tw-border-invoice-paid': amountStateColor === 'UP_TO_DATE',
        'tw-border-invoice-pending': amountStateColor === 'PENDING_INVOICES',
        'tw-border-invoice-expired': amountStateColor === 'DEFAULTER'
    }"
>
    <ng-container *ngIf="amountInfo?.amount > 0; else upToDateMsg">
        <span class="tw-text-2xs">{{ 'configuration.invoice.total_payable' | translate }}</span>
        <span class="tw-font-medium">{{ amountInfo?.amount | currency:amountInfo?.code:(amountInfo?.code+' '+amountInfo?.symbol) }}</span>
    </ng-container>
    <ng-template #upToDateMsg>
        <div *ngIf="waiting">
            <mh-spinner-load [size]="40"></mh-spinner-load>
        </div>
        <ng-container *ngIf="!waiting">
            <span class="tw-text-sm tw-font-medium">¡{{ 'commons.congrats' | translate }}! 🎉</span>
            <span class="tw-text-sm">{{ 'configuration.invoice.up_to_date' | translate }}</span>
        </ng-container>
    </ng-template>
</article>