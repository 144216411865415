<ng-container *ngIf="!loading">
  <section class="tw-container tw-m-auto">
    <mh-core-lib-loader-wrapper [loading]="loading" [type]="'spinner'">
      <mh-core-lib-table
        [showPaginator]="false"
        [footerHeight]="60"
        class="tw-w-full"
        className="!tw-shadow-1"
      >
        <ng-container thead *ngIf="origins.length > 0">
          <ng-container *ngTemplateOutlet="head"></ng-container>
        </ng-container>
        <ng-container tbody *ngIf="origins.length > 0">
          <ng-container *ngTemplateOutlet="body"></ng-container>
        </ng-container>
        <ng-container tfooter>
          <ng-container *ngTemplateOutlet="footer"></ng-container>
        </ng-container>
      </mh-core-lib-table>
      <mh-core-lib-pagination-selector
        [totalElements]="origins.length"
        (paginationSize)="setPagination($event)"
        (pageChanged)="handlePageChanged($event)"
      >
      </mh-core-lib-pagination-selector>
    </mh-core-lib-loader-wrapper>

    <ng-template #head>
      <ng-container *ngFor="let key of headerKeys; index as i">
        <th
          (click)="key === 'origin_name' ? sortTable(key) : null"
          class="tw-cursor-pointer md:tw-min-w-[100px] xl:tw-min-w-[150px] tw-px-4"
        >
          <span>
            {{ "voices.config." + key | translate }}
          </span>

          <span class="tw-ml-2" *ngIf="key === 'origin_name'">
            <i
              class="fas fa-long-arrow-down"
              [@rotate]="currentWaySort === 'DESC' ? '0' : '-180'"
            ></i>
          </span>
        </th>
      </ng-container>
    </ng-template>

    <ng-template #body>
      <tr
        *ngFor="
          let origin of origins.slice(currentPage, currentPage + pagination);
          trackBy: trackByOrigins; index as i
        "
        class="!tw-h-10 hover:tw-bg-neutral-300 tw-cursor-pointer"
      >
        <td *ngFor="let key of headerKeys;">
          <ng-container [ngSwitch]="key">
            <ng-container *ngSwitchCase="'flag_active'">
              <div
                class="tw-w-4 tw-h-4 {{
                  origin[key] ? 'tw-bg-green-600' : 'tw-bg-red-600'
                }} tw-rounded-full"
              ></div>
            </ng-container>
            <ng-container *ngSwitchCase="'token'">
              <a
                class="tw-text-main tw-underline"
                [href]="hostUrl + '/voices/' + origin[key]"
                target="_blank"
                >Link</a
              >
              <i
                class="far fa-copy tw-ml-2"
                (click)="copyToClipboard(origin[key])"
              ></i>
            </ng-container>
            <ng-container *ngSwitchCase="'qr_image'">
              <i class="far fa-download" (click)="downloadQR(origin[key], i)"></i>
            </ng-container>
            <span *ngSwitchDefault class="tw-font-medium tw-text-main-font">
              {{ origin[key] }}
            </span>
          </ng-container>
        </td>
      </tr>
    </ng-template>

    <ng-template #footer>
      <tr>
        <td COLSPAN=2>
          <mh-core-lib-actions-wrapper
              slug="voices_messages_addorigin"
              >
              <input
                  class="tw-w-2/4 tw-h-3/4 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font focus:tw-border-border-light-gray focus:tw-ring-0"
                  type="text"
                  [placeholder]="'voices.config.origin_name' | translate"
                  [formControl]="addOriginCtrl"
                  />
              <select class="tw-w-18 tw-h-[40px] tw-mx-4 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font focus:tw-border-transparent focus:tw-ring-0" [formControl]="langCtrl">
                <option *ngFor="let lang of languages" [ngValue]="lang.id">{{ lang.code | translate | uppercase }}</option>
              </select>
              <mh-core-lib-button
                  [isDisabled]="duplicateError || addOriginCtrl?.value === ''"
                  [isDefault]="true"
                  height="37"
                  [text]="'commons.add' | translate"
                  (click)="addNewOrigin()"
                  ></mh-core-lib-button>
          </mh-core-lib-actions-wrapper>
        </td>
        <td>
          <p
            class="tw-text-small tw-text-detractors tw-font-bold"
            *ngIf="duplicateError"
          >
            {{ "voices.config.errors.duplicate" | translate }}
          </p>
        </td>
        <td *ngIf="isSuperAdmin"></td>
      </tr>
    </ng-template>
  </section>
</ng-container>
