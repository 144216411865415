<button (click)="openModal(changeInfo)" class="{{style}}">
  <div class="icon" *ngIf="icon">
    <i class="{{icon}}"></i>
  </div>
  <span>
    {{title | translate}}
  </span>

</button>

<ng-template #changeInfo let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="title">{{title | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alerts" *ngIf="!waiting">
      <ul *ngIf="configurationService.alert">
        <li *ngFor="let alert of configurationService.alert">
          <div class="alert alert-danger" role="alert">
            {{alert | translate }}
          </div>
        </li>
      </ul>
    </div>
    <div class="fade_waiting" [hidden]="!waiting">
      <mh-spinner-load></mh-spinner-load>
    </div>
    <ng-container *ngIf="type=='profile'">
      <mh-edit-user-profile
        [user]="user"
        (waitingState)="setWaiting($event)"
        (onUpdateUser)="updateProfileUser($event)"
      ></mh-edit-user-profile>
    </ng-container>

    <ng-container *ngIf="type=='update'">
      <mh-edit-user-profile
        [user]="user"
        [viewType]="'update'"
        (onUpdateUser)="updateProfileUser($event)"
        (waitingState)="setWaiting($event)"
      ></mh-edit-user-profile>
    </ng-container>

    <ng-container *ngIf="type=='summaries'">
      <mh-edit-user-summaries
        [user]="user"
        [customer]="customer"
        (waitingState)="setWaiting($event)"
      ></mh-edit-user-summaries>
    </ng-container>

    <ng-container *ngIf="type=='new'">
      <mh-new-user
        [customer]="customer"
        (closeModal)="closeModal($event)"
        (waitingState)="setWaiting($event)"
      ></mh-new-user>
    </ng-container>

  </div>
</ng-template>
