import Answer from './Answer.model';
import Status from './Status.model';

export default class SurveyAnswer {
  id: number;
  guest_id: number;
  survey_id: number;
  flag_standard_questions: number;
  flag_survey_questions: number;
  creation_date: Date;
  answer_date: Date;
  satisfaction: number;
  flag_app_android: boolean;
  answer_language_id: number;
  answers: Answer[];
  flag_deleted: boolean;
  status: Status;

  static createFromApi(obj:any) {
    return Object.assign(new SurveyAnswer(), {
      ...obj,
      answers: (obj.answers || [])
        .map((a:any) => new Answer(a)),
    })
  }
}
