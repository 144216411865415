 <div class="table_waiting">
  <div class="row no-gutters" *ngFor="let row of numberRows | fill" >
    <div *ngFor="let item of numberColumns | fill" class="col">
      <div class="wait_line"></div>
    </div>
  </div>
  <div class="heavy_query" *ngIf="isHeavyQuery">
    <mh-heavy-query [index]="index" [messages]="messages"></mh-heavy-query>
  </div>
</div>
