import { Pipe, PipeTransform } from '@angular/core'

import { Survey } from "app/shared/model/index";
import { FollowupOnsiteGuestFilter } from 'app/states/followup-onsite/followup-onsite.state';

@Pipe({ name: 'followupOnsiteOriginSurveyFilter' })
export class FollowupOnsiteOriginSurveyFilterPipe implements PipeTransform {

  transform(value: Survey[], filters: FollowupOnsiteGuestFilter): Survey[] {
    if(filters.origin_survey) {
      if(!filters.origin_survey.origin_email && !filters.origin_survey.origin_open_survey) return value
      const filtered = value.filter(survey =>
        (survey.origin_name === 'Email' && filters.origin_survey.origin_email) ||
        (survey.origin_name !== 'Email' && filters.origin_survey.origin_open_survey)
      )
      return filtered
    } else
      return value
  }
}
