import { UtilService } from 'app/shared/util.service';
import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'mh-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss'],
  providers: []
})
export class ChangeLanguageComponent{

  @Input()
  style="normal";

  @Input()
  language;

  @Output()
  changeLanguage: EventEmitter<string> = new EventEmitter();

  availableLanguages: any[] = [];

  constructor(private utilService: UtilService){
    this.availableLanguages = this.utilService.translateLanguages;
  }

  ngOnInit(){
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  setLanguage(iso:string){
    this.utilService.ga('language','click', iso);
    this.utilService.setCurrrentLanguage(iso);
    this.changeLanguage.emit(iso);
  }

  setSelectlValue($event){
    this.setLanguage($event.value);
  }

}
