<button
  class="tw-rounded-full md:tw-w-fit tw-w-14 tw-h-14 tw-bg-white tw-text-main tw-shadow-1 tw-flex tw-justify-center md:tw-px-7 md:tw-py-2 focus:tw-outline-0 tw-transition-all"
  (click)="isOpen = !isOpen"
  cdkOverlayOrigin #trigger="cdkOverlayOrigin"
  [class.!tw-bg-main]="isOpen"
  [class.!tw-text-white]="isOpen"
>
  <i class="fas fa-search tw-self-center"></i>
  <span class="tw-hidden md:tw-inline-block tw-ml-2 tw-text-base tw-font-medium tw-self-center tw-text-main-text" [class.!tw-text-white]="isOpen">
    {{'core-lib.selectors.desk.button' | translate}}
  </span>
</button>


<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOffsetX]="100"
  [cdkConnectedOverlayOpen]="isOpen"
>
<div id="overlaySelectorDesk" class="tw-mt-3 tw-max-w-[350px] md:tw-max-w-[450px]">
  <mh-core-lib-generic-box customClass="tw-h-fit">
    <mh-core-lib-autocomplete-input
        [options]="cases"
        [openBox]="true"
        [filterFn]="autocompleteFilterFn"
        [showGlassIcon]="true"
        [displayOptionsFn]="displayDeskOptions"
        [displayOptionsEmailFn]="displayIdOptions"
        (optionSelected)="handleCaseSelected($event)"
        >
    </mh-core-lib-autocomplete-input>
  </mh-core-lib-generic-box>
</div>

</ng-template>
