
class KeepCheckSetupRes {
  _checkSetupRes?: CheckSetupRes
}

export class CheckSetup extends KeepCheckSetupRes {
  customerId?: number
  messages: Message[]
  status: string
  children?: CheckSetupChildren[]

  static createFromResponse(resp: CheckSetupRes) {
    const setup = {
      customerId: resp.customer_id,
      status: this.convertStatusText(resp.status),
      messages: resp.messages.map(mess => {
        return {
          status: this.convertStatusText(mess.status),
          text: mess.text
        }
      }),
      children: resp.childs && resp.childs.length > 0 ? CheckSetupChildren.createFromListResponse(resp.childs) : [],
      _checkSetupRes: resp
    }
    return setup
  }

  static convertStatusText(status: string) {
    if (status === 'APPROVED') {
      return 'Ok'
    } else if (status === 'APPROVED_WITH_WARNINGS') {
      return 'Warning'
    } else if (status === 'DECLINED') {
      return 'Error'
    } else {
      return status
    }
  }

  static addTypeToSetupChildren(setup: CheckSetup, surveys: any[]) {
    setup.children?.forEach(child => {
      const survey = surveys.find(s => s.id === child.surveyId)
      if (survey) {
        child.type = survey.type.type
      }
    })
    return setup
  }

  constructor(props: any) {
    super()
    Object.assign(this, props);
  }

}

class KeepCheckSetupChildrenRes {
  _checkSetupChildrenRes?: CheckSetupChildrenRes
}

export class CheckSetupChildren extends KeepCheckSetupChildrenRes {
  surveyId?: number
  messages: Message[]
  status: string
  type?: string

  static createFromListResponse(resp: CheckSetupChildrenRes[]) {
    const children = resp.map(child => {
      return {
        surveyId: child.survey_id,
        status: CheckSetup.convertStatusText(child.status),
        messages: child.messages.map(mess => {
          return {
            status: CheckSetup.convertStatusText(mess.status),
            text: mess.text
          }
        })
      }
    })
    return children
  }

  static createFromResponse(resp: CheckSetupChildrenRes) {
    return {
      surveyId: resp.survey_id,
      status: CheckSetup.convertStatusText(resp.status),
      messages: resp.messages.map(mess => {
        return {
          status: CheckSetup.convertStatusText(mess.status),
          text: mess.text
        }
      }),
      _checkSetupChildrenRes: resp
    }
  }

  static addTypeToSetup(setup: CheckSetupChildren, survey: any) {
    if (survey.id === setup.surveyId)
      setup.type = survey.type.type
    return setup
  }

  constructor(props: any) {
    super()
    Object.assign(this, props);
  }
}

export class CheckSetupChildrenRes {
  survey_id: number
  messages: Message[]
  status: string
}

export interface CheckSetupRes {
  customer_id: number
  messages: Message[]
  status: string
  childs?: CheckSetupChildrenRes[]
}

export interface Message {
  text: string
  status: string
}
