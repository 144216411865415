import {Component} from '@angular/core';
import {UtilService}    from "../../../../shared/util.service";
import {CoverageService} from '../interchain-online-coverage.service';
import { Hotel, Period, FilterDate } from '../../../../shared/model/index';
import {ViewChild} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { Subscription, forkJoin, Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';
import { DateService } from 'app/shared/services/date.service';

@Component({
  selector: 'mh-interchain-coverage-ranking',
  templateUrl: './interchain-online-coverage-ranking.component.html',
  styleUrls: ['./interchain-online-coverage-ranking.component.scss'],
  providers:[CoverageService]
})


export class InterchainOnlineCoverageRanking{

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  chain: Hotel[] = [];
  isReady = false;
  waiting = true;

  fidelityPermisions:string[] = [];
  productId = 3;
  data = {values: [], average: []}
  totals = {};
  customerSubscribe: Subscription;
  customersStateSubscribe: Subscription;

  headerTable = [
    'label',
    'coverage',
    'total',
    'total_answered'
  ];

  dataTable: any[] = [];
  dataSource: any = new MatTableDataSource([]);
  prefixTranslateFAQ = 'online.coverage.by_hotel.faq';
  faqs = (new Array(2).fill(0));
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private utilService:UtilService,
    private coverageService:CoverageService,
    private store: Store,
    private dateService: DateService
  ){
    this.customerSubscribe = this.utilService.isCustomerChanged().subscribe(response=>{
      this.init();
    })
    this.customersStateSubscribe = this.customersReady$.subscribe(customers => this.init());
  }

  ngOnInit(){
    this.fidelityPermisions = this.utilService.getFidelityConfigurationsByProduct('interchain', 'interchain_online');
  }

  reset(){

    this.totals = {};
    this.dataTable = [];
    this.dataSource = new MatTableDataSource([])
  }

  init(){
    this.chain = this.utilService.getChain('online');
    const customers = this.store.selectSnapshot(state => state.customers.relativeCurrentChain).map(c => c.id).join(',');
    const date = new FilterDate(this.startDate, this.endDate);
    this.waiting = true;

    if ( customers.length ) {
      forkJoin([
        this.coverageService.getRanking(date,customers)
      ]).subscribe((result:any)=>{
        this.isReady     = true;
        this.waiting     = false;
        this.data        = result[0];
        this.createTable();
      })
    }
  }

  ngOnDestroy(){
    this.customerSubscribe.unsubscribe();
    this.customersStateSubscribe.unsubscribe();
  }

  createTable(){
    this.reset();
    this.data.values.forEach(data=>{
      const cell = this.getData(data);
      this.dataTable.push(cell);
    })

    this.headerTable.forEach(key=> {
      if(key!=='customer') {
        this.totals[key] = this.getTotal(key);
      }
    });

    this.dataSource = new MatTableDataSource(this.dataTable);
  }

  getData(data){
    const cell = {};
    this.headerTable.forEach(key=>{
      if(key==='label'){
        cell[key] = data.rating_classification;
      }else{
        cell[key] = data && data[key] ? data[key] : new Period();
      }
    });

    return cell;
  }

  getTotal(key){
    return this.data.average[key] ? this.data.average[key] : new Period();
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates)
    this.init();
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }

  get startDate() {
    return this.dateService.currentDates.startDate;
  }

  get endDate() {
    return this.dateService.currentDates.endDate;
  }
}
