import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {HtmlHelper} from '../../../_helpers/html-helper.service';
import { UtilService } from 'app/shared/util.service';
import { Period, Currency } from 'app/shared/model';

@Component({
  selector: 'mh-interchain-prestay-table',
  templateUrl: './prestay-table.component.html',
  styleUrls: ['./prestay-table.component.scss']
})
export class PrestayTableComponent implements OnInit {

  @Input()
  dataSource = new MatTableDataSource([]);

  @Input()
  customersDetails = [];

  @Input()
  hasActiveServices: boolean = false;

  @Input()
  hasActiveWebcheckin: boolean = false;

  currency: Currency = new Currency(179,'USD');

  blackListHeaderTooltip = [
    'customer'
  ];

  headerTable: string[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private utilService: UtilService, private htmlHelper: HtmlHelper) {
    //this.currency = this.utilService.currentHotel.currency;
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.setTableHeaders();
  }

  setTableHeaders() {
    const headers = ['customer'];
    const commonHeaders = ['email_rate', 'total_guests', 'with_email', 'valid_emails'];
    if(this.hasActiveServices) {
      headers.push(...['total_price', 'quantity', 'unique_orders', 'order_rate']);
    }
    if(this.hasActiveWebcheckin) headers.push('checkins');
    headers.push(...commonHeaders);
    this.headerTable = headers;
  }

  getTableStyle(){
    return this.htmlHelper.getTableStyle(this.headerTable,this.utilService.screenHeight);
  }

  getTotal(key){
    const data = this.dataSource.data.map(x=>x[key]);
    const period = new Period();

    ['actual','previous','variation'].forEach(type=>{
      const current = data.map(x=>x[type]);
      const sum     = this.utilService.sumArray(current);
      period[type]  = this.utilService.formatDecimals(sum);
    })

    return period;
  }

  getAverage(key){

    const data = this.dataSource.data.map(x=>x[key]);
    const period = new Period();

    ['actual','previous','variation'].forEach(type=>{
      const current = data.map(x=>x[type]);
      const average     = this.utilService.getAverageArray(current,true);
      period[type]  = average;
    })

    return period;
  }

}
