import {Component, OnInit, Input, EventEmitter, Output, ViewChild} from '@angular/core';
import {CustomerService} from "../../customer.service";
import {UtilService} from "../../util.service";
import {Hotel} from "../../model/hotel";
import {NgbDropdownConfig, NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import {ProfileService} from '../../../configuration/profile/profile.service';
import {Observable, Subject,  interval } from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms'; //DELETE FORM CONTROL
import {startWith, map, debounceTime} from 'rxjs/operators';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatInput } from '@angular/material/input';


export class CountryGroup{
  country_code:string;
  customers:Hotel[];
  constructor(country_code){
    this.country_code = country_code;
  }
}


export const _filter = (hotel: Hotel[], value: string): Hotel[] => {
  return hotel.filter(item =>{
    let lowerName: any = item.name.toLowerCase();
    let lowerInput = value.toLowerCase();
    let expresion = new RegExp(lowerInput,"g");
    let id = item.id.toString()
    if((lowerName.indexOf(lowerInput) === 0) || (lowerName.match(expresion) && lowerName.match(expresion)?.length>0) || (id===lowerInput)) return item;
  });
};


@Component({
  selector: 'mh-filter-customer',
  templateUrl: './filter-customer.component.html',
  styleUrls: ['./filter-customer.component.scss'],
  providers:[CustomerService, NgbDropdownConfig, ProfileService]
})

export class FilterCustomerComponent implements OnInit {
  hotelList:Hotel[];

  @Input()
  changeHotel: Hotel;

  @Input()
  liveDemo: boolean = false;


  @Output()
  customer: EventEmitter<Hotel> = new EventEmitter();

  @Output()
  customers: EventEmitter<Hotel[]> = new EventEmitter();

  @Output()
  isReady: EventEmitter<boolean> = new EventEmitter();

  @Input()
  adminChain:boolean = false;

  @Input()
  viewType="normal";

  @Input()
  filter = [];

  @Input()
  selecteable= false;

  @Input()
  emptyMessage = 'shared.filters.customers.empty_message'

  @Input()
  forceToogle = false;

  @Input()
  filterId = 'hotelsList';

  waiting = false;
  model: any;

  loadingAdminHotels:boolean = true;
  stateGroupOptions: Observable<CountryGroup[]>;

  connectLoadHotels = new Subject<any>();
  customerGroups:CountryGroup[] = []
  is_super_admin:boolean = false;
  current_user;

  show_toggle:boolean= true;

  placement = 'bottom-left';


  inputFiltering='';

  stateForm: FormGroup = this.fb.group({
    stateGroup: '',
  });

  @ViewChild('inputAuto', { read: MatAutocompleteTrigger, static: false }) inputTrigger: MatAutocompleteTrigger;
  @ViewChild('inputAuto', { read: MatInput, static: false }) inputAuto: MatInput;
  @ViewChild('auto', { read: MatAutocomplete, static: false }) inputAutoComplete: MatAutocomplete;
  @ViewChild('myDrop', { static: false }) dropDown: NgbDropdown;




  constructor(private customerService:CustomerService,private utilService:UtilService, private config: NgbDropdownConfig, private profileService:ProfileService, private fb: FormBuilder) {
    this.config.autoClose = false;
    this.current_user     = this.utilService.getCurrentUser()
    this.is_super_admin   = this.current_user.isSuperAdmin();
    this.placement        = this.utilService.isMobile() ? 'bottom-right' :'bottom-left';
    this.utilService.isAddANewHotel().subscribe(response=>{
      this.hotelList = [];
    })
    this.loadFilter();
  }


  setListHotelOptions(){
    this.waiting = true;
    this.loadCustomerClient();
  }

  ngOnInit() {
    this.setListHotelOptions();
  }

  ngOnChanges() {
    this.setListHotelOptions();
  }

  loadFilter(){

    this.loadHotels().subscribe((response) => {
      response.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
      this.utilService.interChainHotels = response
      this.showOptions()
      this.hotelList    = this.liveDemo ? this.utilService.demoInterChainHotels : response;
      let filter = this.filter.map((x: any)=>x.id);

      setTimeout(_ => {
        this.isReady.emit(!this.waiting);
        this.waiting = false;
      });

      if(this.hotelList.length>=1){
        setTimeout(_ => {
          if(this.inputTrigger){
            this.inputTrigger.panelClosingActions.subscribe((state:MatOptionSelectionChange)=>{
              this.validateViews(state)
            })
          }
        });

        let hotels          = this.hotelList.filter(customer=> !filter.includes(customer.id));
        this.customerGroups =  this.formatCustomersCountry(hotels);

        this.stateGroupOptions = this.stateForm.get('stateGroup')!.valueChanges
          .pipe(
            debounceTime(300),
            startWith(''),
            map(value => this._filterGroup(value))
          );
      }
    })
  }

  loadAdminHotels(){
    this.loadingAdminHotels = true;
    let hotels: any[] = [];
    let flag = this.viewType==='normal' ? '1' : '0';
    if(this.hotelList && this.hotelList.length>1){
      this.hotelAdminReady(this.hotelList)
    }else{
      this.current_user     = this.utilService.getCurrentUser()
      const showPrivates = ['full-admin','mh-private-admin'].includes(this.current_user.role);

      this.customerService.findAll(flag,showPrivates).subscribe((response: Hotel[])=>{
        hotels = response.map(x=>{
          let hotel = (new Hotel()).createFromApi(x);
          return hotel;
        })
        this.hotelAdminReady(hotels)
      });
    }
  }

  hotelAdminReady(hotels){
    this.getDemoHotels(hotels);
    this.loadingAdminHotels = false;
  }

  loadCustomerClient(){
    this.loadingAdminHotels = false;
    if(this.liveDemo){
      this.loadingAdminHotels = true;
      this.loadAdminHotels()
    }else{
      this.connectLoadHotels.next(this.utilService.currentUser.getAccessHotels());
    }

  }

  loadHotels(): Observable<any> {
    return this.connectLoadHotels.asObservable();
  }


  getDemoHotels(allHotels){
    if(this.is_super_admin && this.liveDemo){
      this.utilService.ga("hotel-list","show","live demo");

      this.profileService.usersInCustomer(this.current_user.superAdminProperties.id_fake_customer).subscribe((response:any) => {
        let user = response.find(x=>x.id === this.current_user.superAdminProperties.id_fake_user)
        if(user===undefined){
          let users = response;
          users.sort(function(a,b) {return (a.customers.length < b.customers.length) ? 1 : ((b.customers.length < a.customers.length) ? -1 : 0);} );
          users = users.filter(x=>!x.superAdmin);
          user = users[0];
          this.utilService.setCurrenFakeUser(users[0]);
        }
        this.loadingAdminHotels = false;
        this.utilService.setDemoInterChain(user);
        this.connectLoadHotels.next(this.utilService.demoInterChainHotels);
      })
    }else{
      this.connectLoadHotels.next(allHotels);
    }
  }


  updateCustomer($event){
    let hotelClicked = $event.option._element.nativeElement.dataset;
    let hotelId      = hotelClicked.hotelId;
    let hotel        = this.hotelList.find(x=>x.id+''===hotelId);
    this.utilService.ga('hotel',hotel!.name);
    this.customer.emit(hotel);
    this.dropDown.close();
    //this.stressTest();
  }

  selectAll(){
    this.customers.emit(this.hotelList);
    this.dropDown.close();
  }

  validateViews(state:MatOptionSelectionChange){
    this.dropDown.close()
  }



  private _filterGroup(value: string){
    if (value) {
      return this.customerGroups
        .map(group => ({country_code: group.country_code, customers: _filter(group.customers, value)}))
        .filter(group => group.customers.length > 0);
    }

    return this.forceToogle ?  [] : this.customerGroups;
  }

  toggleHotelList($dropdown){

    if(this.is_super_admin && !this.adminChain && !this.liveDemo){
      this.customerGroups = [];
      this.loadingAdminHotels = true;
      setTimeout(_ => {
        this.loadAdminHotels();
      }, 0);
    }
    if(this.adminChain){
      this.connectLoadHotels.next(this.utilService.getInnerChain(false));
    }
    if($dropdown.open){
      this.utilService.ga("hotel-list","click","show list");
      this.showHotelList()
    }
  }

  showHotelList(){
    this.stateForm.setValue({
      'stateGroup' : ''
    })
    setTimeout(_ => {
      this.inputAuto.focus();
    });
  }

  showOptions(){
    if(this.hotelList===undefined){this.show_toggle = false;};
    this.show_toggle =  this.hotelList!==undefined && (this.hotelList.length > 1 || this.is_super_admin || this.viewType!=="normal");
    if(this.forceToogle){
      this.show_toggle = true;
    }
  }



  formatCustomersCountry(response){
    let customers_groups:CountryGroup[] = [];
    let countries = Array.from(new Set(response.map(x=>x.country_code)));
    for(let country of countries){
      let group = new CountryGroup(country);
      group.customers = response.filter(x=>x.country_code === country).sort(function(a,b) {return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0);} );
      customers_groups.push(group)
    }
    customers_groups = this.utilService.sortArray(customers_groups,'country_code');
    return customers_groups;
  }

  stressTest(){
    let max = 150;
    let hotels = this.hotelList.filter(x=>x.followup);
    const source = interval(3000);
    if(this.utilService.isLocal()){
        let subscribe = source.subscribe(val => {
        let hotel = hotels[val];
        if(![339,1640,12].includes(parseInt(hotel.id))){
          console.log("Nº:",(val+1)," ID:", hotel.id, " NAME: ", hotel.name)
          this.utilService.customerChanged(hotel);
          if(val===max) subscribe.unsubscribe();
        }

      });
    }

  }
}
