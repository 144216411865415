import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { SemanticSurveysFilterPipe } from 'app/pipes/semantic/semantic-surveys-filter.pipe';
import { SemanticProductsFilterPipe } from 'app/pipes/semantic/semantic-products-filter.pipe';
import { SemanticNotationFilterPipe } from 'app/pipes/semantic/semantic-notation-filter.pipe';
import { SemanticThemeFilterPipe } from 'app/pipes/semantic/semantic-theme-filter.pipe';
import { SemanticAreaFilterPipe } from 'app/pipes/semantic/semantic-area-filter.pipe';
import { SemanticMatchFilterPipe } from 'app/pipes/semantic/semantic-match.filter.pipe';
import { Survey } from 'app/survey-public/models';
import { RankingThemeArea, SemanticComment } from '../../shared/model/semantic-comment.model';
import { Semantic } from './semantic.actions';

export interface SemanticCommentFilter {
  surveys: Survey[];
  products: number[];
  notations: { positive: boolean; negative: boolean };
  themes: any[];
  areas: any[];
}

export interface SemanticStateModel {
  comments: SemanticComment[];
  filters: SemanticCommentFilter;
  areasUniq: RankingThemeArea[];
  areasPositives: RankingThemeArea[];
  areasNegatives: RankingThemeArea[];
  themesUniq: RankingThemeArea[];
  themesPositives: RankingThemeArea[];
  themesNegatives: RankingThemeArea[];
}

const semanticSurveysFilterPipe = new SemanticSurveysFilterPipe();
const semanticProductsFilterPipe = new SemanticProductsFilterPipe();
const semanticNotationFilterPipe = new SemanticNotationFilterPipe();
const semanticThemeFilterPipe = new SemanticThemeFilterPipe();
const semanticAreaFilterPipe = new SemanticAreaFilterPipe();
const semanticMatchFilterPipe = new SemanticMatchFilterPipe();

const DEFAULT_FILTERS = {
  surveys: [],
  products: [],
  notations: { positive: false, negative: false },
  themes: [],
  areas: [],
};

@State<SemanticStateModel>({
  name: 'semantic',
  defaults: {
    comments: null,
    filters: DEFAULT_FILTERS,
    areasUniq: null,
    areasPositives: null,
    areasNegatives: null,
    themesUniq: null,
    themesPositives: null,
    themesNegatives: null,
  },
})
@Injectable()
export class SemanticState {
  @Selector()
  static commentsSorted(state: SemanticStateModel) {
    return semanticSurveysFilterPipe.transform(
      semanticProductsFilterPipe.transform(
        semanticThemeFilterPipe.transform(
          semanticAreaFilterPipe.transform(
            semanticNotationFilterPipe.transform(
              semanticMatchFilterPipe.transform(state.comments, state.filters),
              state.filters,
            ),
            state.filters,
          ),
          state.filters,
        ),
        state.filters,
      ),
      state.filters,
    );
  }

  @Selector()
  static commentsTotal(state: SemanticStateModel) {
    return state.comments;
  }

  @Selector()
  static filters(state: SemanticStateModel) {
    return state.filters;
  }

  @Selector()
  static areasUniq(state: SemanticStateModel) {
    return state.areasUniq;
  }

  @Selector()
  static areasPositives(state: SemanticStateModel) {
    return state.areasPositives;
  }

  @Selector()
  static areasNegatives(state: SemanticStateModel) {
    return state.areasNegatives;
  }

  @Selector()
  static themesUniq(state: SemanticStateModel) {
    return state.themesUniq;
  }

  @Selector()
  static themesPositives(state: SemanticStateModel) {
    return state.themesPositives;
  }

  @Selector()
  static themesNegatives(state: SemanticStateModel) {
    return state.themesNegatives;
  }

  constructor() {}

  @Action(Semantic.StoreCommentList)
  storeCommentList(context: StateContext<SemanticStateModel>, action: Semantic.StoreCommentList) {
    context.patchState({
      comments: action.comments,
    });
  }

  @Action(Semantic.CommentListClear)
  commentListClear(context: StateContext<SemanticStateModel>) {
    context.patchState({
      comments: null,
    });
  }

  @Action(Semantic.ChangeSurveyFilter)
  changeSurveyFilter(context: StateContext<SemanticStateModel>, action: Semantic.ChangeSurveyFilter) {
    const filters = { ...context.getState().filters };
    filters.surveys = action.surveys;
    context.patchState({
      filters,
    });
  }

  @Action(Semantic.ChangeProductsFilter)
  changeProductsFilter(context: StateContext<SemanticStateModel>, action: Semantic.ChangeProductsFilter) {
    const filters = { ...context.getState().filters };
    filters.products = action.products;
    context.patchState({
      filters,
    });
  }

  @Action(Semantic.ChangeNotationsFilter)
  changeNotationsFilter(context: StateContext<SemanticStateModel>, action: Semantic.ChangeNotationsFilter) {
    const filters = { ...context.getState().filters };
    filters.notations = action.notations;
    context.patchState({
      filters,
    });
  }

  @Action(Semantic.ChangeThemeFilter)
  changeThemeFilter(context: StateContext<SemanticStateModel>, action: Semantic.ChangeThemeFilter) {
    const filters = { ...context.getState().filters };
    filters.themes = action.themes;
    context.patchState({
      filters,
    });
  }

  @Action(Semantic.ChangeAreaFilter)
  changeAreaFilter(context: StateContext<SemanticStateModel>, action: Semantic.ChangeAreaFilter) {
    const filters = { ...context.getState().filters };
    filters.areas = action.areas;
    context.patchState({
      filters,
    });
  }

  @Action(Semantic.FilterClear)
  filterClear(context: StateContext<SemanticStateModel>) {
    context.patchState({
      filters: DEFAULT_FILTERS,
    });
  }

  @Action(Semantic.StoreAreasUniqRanking)
  storeAreasUniqRanking(context: StateContext<SemanticStateModel>, action: Semantic.StoreAreasUniqRanking) {
    context.patchState({
      areasUniq: action.areas,
    });
  }

  @Action(Semantic.StoreAreasPositivesRanking)
  storeAreasPositivesRanking(context: StateContext<SemanticStateModel>, action: Semantic.StoreAreasPositivesRanking) {
    context.patchState({
      areasPositives: action.areas,
    });
  }

  @Action(Semantic.StoreAreasNegativesRanking)
  storeAreasNegativesRanking(context: StateContext<SemanticStateModel>, action: Semantic.StoreAreasNegativesRanking) {
    context.patchState({
      areasNegatives: action.areas,
    });
  }

  @Action(Semantic.StoreThemesUniqRanking)
  storeThemesUniqRanking(context: StateContext<SemanticStateModel>, action: Semantic.StoreThemesUniqRanking) {
    context.patchState({
      themesUniq: action.themes,
    });
  }

  @Action(Semantic.StoreThemesPositivesRanking)
  storeThemesPositivesRanking(context: StateContext<SemanticStateModel>, action: Semantic.StoreThemesPositivesRanking) {
    context.patchState({
      themesPositives: action.themes,
    });
  }

  @Action(Semantic.StoreThemesNegativesRanking)
  storeThemesNegativesRanking(context: StateContext<SemanticStateModel>, action: Semantic.StoreThemesNegativesRanking) {
    context.patchState({
      themesNegatives: action.themes,
    });
  }
}
