export class DataMention{
  type:string;
  waiting:boolean=true;
  data=[];
  constructor(type,data){
    this.type = type;
    this.data = data;
  }
}


export class DataTheme{
  theme:string;
  total:number;
  positives:number;
  negatives:number;
  neutrals:number;
  positives_percentage:number;
  neutrals_percentage:number;
  negatives_percentage:number;
}

export class DataPhrase{
  negatives:number;
  negatives_percentage:number;
  neutrals:number;
  neutrals_percentage:number;
  phrase:string;
  positives:number;
  positives_percentage:number;
  total:number;
}

export class DataEntity{
  entity:string;
  entity_type:string;
  negatives:number;
  negatives_percentage:number;
  neutrals:number;
  neutrals_percentage:number;
  positives:number;
  positives_percentage:number;
  total:number;
}