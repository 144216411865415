<mh-alerts-wrapper>

  <section class="flex flex-row justify-between">
    <div>
      <mh-core-lib-datepicker
        [currentDates]="currentDates"
        [currentFilter]="currentFilterDate"
        (datepickerUpdated)="handleDatepickerUpdated($event)">
      </mh-core-lib-datepicker>
    </div>
  </section>
  <mh-box-element [title]="('inter-chain.prestay.summary.title' | translate )" *ngIf="fidelityPermisions.includes('interchain_prestay_summary_result')">
    <widget-filter-chain-zone [productId]="productId" (chainChanged)="setChain($event)" [disabled]="!isReady"></widget-filter-chain-zone>
    <mh-chain-prestay-summary [currentDates]="currentDates" [chain]="chainFilter" (isReady)="dataIsReady($event)"></mh-chain-prestay-summary>
  </mh-box-element>

</mh-alerts-wrapper>
