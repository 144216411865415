import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlineReviewsOtaFilter'
})
export class OnlineReviewsOtaFilterPipe implements PipeTransform {

  transform(reviews, filters) {
    let filteredReviews = [...reviews];
    if ( filteredReviews && filteredReviews.length > 0 ) {
      const filter = filters.find(filter => filter.name === 'ota')
      const filtering = filter.options.some(option => option.isFiltering);
      if(filter && filtering) {
        const options = filter.options.filter(option => option.isFiltering).map(option => option.value);
        filteredReviews = filteredReviews.filter(item => {
          const value = item.ota?.id || 0;
          return options.includes(value);
        })
      }
    }
    return filteredReviews;
  }

}
