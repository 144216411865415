export class Action{
  label:string;
  icon:string;
  constructor(label, icon){
    this.icon = icon;
    this.label = label;
  }

  is(label){return this.label===label};

}