import { Injectable } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FormArray, FormGroup, FormBuilder, Validators, AbstractControl } from "@angular/forms";
import { Subject } from "rxjs";
import { bufferTime } from "rxjs/operators";
import { Language, Guest } from "app/shared/model";
import { TranslateSection } from "./models/translate-section.model";
import { TranslateQuestion } from "./models/translate-question.model";
import { TranslateAlternative } from "./models/translate-alternative.model";
import { Survey } from "./models/survey.model";
import { Question } from "./models/question.model";
import { SessionService } from "app/_services/session.service";
import { Answer } from 'app/features/surveys/models/answer.model';
import { SurveyLineal } from "./models/survey-lineal.model";
import { Alternative } from "./models/alternative.model";
import { SurveyConfiguration } from "./models/survey-configuration.model";
import { LanguageSurvey } from "./models/language-survey.model";
import { Section } from "./models/section.model";
import { orderValidator, orderValidatorConditional } from './form/sections-form/order-validator';
import { Translate } from "app/survey-public/models";
import { TagArea } from "./models/tag-area.model";
import { QuestionConditionalOption } from "./models/question-conditional-option.model";

export class ErrorSurvey{
  code = 0;
  description = '';
  constructor(code=0, description=''){
    this.code = code;
    this.description = description;
  }
}

@Injectable()
export class SurveysUtilService {

  private sbjLanguagesActive  = new Subject<number[]>();
  private sbjLoadingTokens    = new Subject<any>();
  private sbjCheckConditional      = new Subject<any>();
  private sbjConditionals = new Subject<any>();

  private _errorSurvey: ErrorSurvey = new ErrorSurvey();

  headerHeight = 65;
  footerHeight = 65;
  answerId = 1;
  answerStatus: any = 'open';

  survey: Survey;
  surveyConfiguration: SurveyConfiguration = new SurveyConfiguration();
  guest: Guest = new Guest();
  answers:Answer[] = [];
  customerId = 0;
  isMyHotelUser = false;
  conditionalIds: any[] = [];

  private _language:string  = 'es';
  private _base:any;

  scoreAlternativeTypes = [
    'radiobutton',
    'slider'
  ];

  alternativesWithAreas = [
    'grid'
  ];

  alternativeHasConditionalQuestion = [
    'radiobutton',
    'checkbox',
    'grid',
    "subGrid"
  ];

  conditionalQuestionsAvailable: [] = [];

  surveyFormStatus = 0;
  timeWait;
  languages = [
    {id :1, code :'es'},
    {id :2, code :'en'},
    {id :7, code :'pt'},
  ]

  languagesActives:number[] = [];

  constructor(
    private sessionService: SessionService,
    private fb: FormBuilder,
  ){ }

  dropSort(control:FormArray,event: CdkDragDrop<string[]>,key:string, parentArray:null | any[] = null) {
    control['controls'][event.previousIndex]['controls'][key].setValue(event.currentIndex+1,{emitEvent: false});
    control['controls'][event.currentIndex]['controls'][key].setValue(event.previousIndex+1,{emitEvent: false});
    moveItemInArray(control['controls'], event.previousIndex, event.currentIndex);
    if(parentArray) moveItemInArray(parentArray, event.previousIndex, event.currentIndex);
  }

  removeFromControl(control, data:any[],index:number, markAsDirty: boolean = true){
    data.splice(index,1);
    control.removeAt(index);
    if (markAsDirty) {
      control.markAsDirty()
    }
  }

  isLanguageActiveChanges(){
    return this.sbjLanguagesActive.asObservable();
  }

  isTokensReady(){
    return this.sbjLoadingTokens.asObservable();
  }

  checkHasConditionals(){
    return this.sbjCheckConditional.asObservable();
  }

  isConditionalsUpdated() {
    return this.sbjConditionals.asObservable();
  }

  updateConditionals(conditional_associate, alternative_id: number = 0) {
    this.sbjConditionals.next({conditional_associate: conditional_associate, alternative_id: alternative_id});
  }

  showConditionals(alternative: any, question:Question, subAlternative: any = null){
    this.sbjCheckConditional.next({
      alternative: alternative,
      question: question,
      subAlternative: subAlternative
    })
  }

  setTokensReady(){
    this.sbjLoadingTokens.next({
      ready: true
    });
  }

  setActiveLanguages(languages:LanguageSurvey[]){
    this.languagesActives = languages.filter(x=>x.active).map(x=>x.language.id);
    this.sbjLanguagesActive.next(this.languagesActives);
  }

  getTranslates(
    languages:Language[],
    translates:any[],
    translateObj: TranslateSection | TranslateQuestion | TranslateAlternative
  ) : TranslateSection[] |  TranslateQuestion[] | TranslateAlternative[] {
    languages.forEach(language=>{
      translates.push({...translateObj, language});
    })
    return translates;
  }

  setLanguagesActivesIds(survey: Survey) {
    const languagesActives = survey.languages;
    const languagesIds = languagesActives.map(lang => lang.language.id);
    return languagesIds;
  }

  parserSectionsToSave(survey: Survey, sections: Section[]) {
    const languagesIds = this.setLanguagesActivesIds(survey);
    const newSection: any[] = [];
    sections.forEach(section => {
      section.translates = section.translates.filter(translate => languagesIds.includes(translate.language.id));
      section.questions = this.parserQuestionsToSave(section.questions, survey);
      newSection.push(section)
    });
    return newSection;
  }

  parserQuestionsToSave(questions: Question[], survey: Survey) {
    const languagesIds = this.setLanguagesActivesIds(survey);
    const newQuestions: any[] = [];
    questions.forEach(question => {
      question.translates = question.translates.filter(translate => languagesIds.includes(translate.language.id));

      if (question.alternatives.length > 0) {
        question.alternatives = question.alternatives.map(alt => this.parserAlternativesSurvey(alt, survey));
      } else if (['texto', 'comentario'].includes(question.question_type)) {
        question.alternatives = this.getTextAlternative(question.question_type);
      }

      if((question.sub_alternatives || []).length > 0) {
        let newSubAlternatives: any[] = [];
        question.sub_alternatives.forEach(sub_alternative => {
          sub_alternative.translates = sub_alternative.translates.filter(translate => languagesIds.includes(translate.language.id));
          newSubAlternatives.push(sub_alternative);
        });
        question.sub_alternatives = newSubAlternatives;
      }

      newQuestions.push(question);
    });

    return newQuestions;
  }

  parserAlternativesSurvey(alternative: Alternative, survey: Survey) {
    const languagesIds = this.setLanguagesActivesIds(survey);

    alternative.translates = alternative.translates
      .filter(translate => languagesIds.includes(translate.language.id));

    if(alternative.sub_alternatives && alternative.sub_alternatives.length) {
      alternative.sub_alternatives = alternative.sub_alternatives
        .map((sub_alternative: any) => {
          sub_alternative.translates = sub_alternative.translates
            .filter(translate => languagesIds.includes(translate.language.id))
            .map(trans => ({...trans, id: null}));

          return sub_alternative;
        })
    }

    return alternative
  }

  parserGeneralInfoSurvey(survey: Survey) {
    const languagesActives = survey.languages.filter(x=>x.active);
    const languagesIds     = languagesActives.map(x=>x.language.id);

    survey.translates = survey.translates.filter(x=>languagesIds.includes(x.language.id));
    survey.languages = survey.languages.filter(x=>languagesIds.includes(x.language.id));
    return survey;
  }

  clearGridIds(question: Question){
    let rows = question.sub_alternatives;
    rows = rows.map((row: any) => {
      row.id = null;
      row.translates = row.translates.map(translate=>{
        translate.id = null;
        return translate;
      })
      return row;
    })
    return rows;
  }

  findAnswers(questionId: number): Answer[]{
    const answers = this.answers.filter(x=>x.question_id===questionId);
    return answers;
  }

  addOrUpdateAnswer(questionId: number,answer){
    const oldAnswer = this.findAnswers(questionId);
    if(oldAnswer.length === 0){
      this.answers.push(answer[0])
    }
  }

  getLinealSurvey(survey:Survey){
    const surveyLineal = new SurveyLineal();

    survey.sections.forEach(section=>{
      section.questions.forEach(question=>{
        question._type = question.alternatives[0].alternative_type.name;
        surveyLineal.questions.push(question);
      })
    })

    return surveyLineal;
  }

  createMapAnswer($event,type){
    return  ['checkbox'].includes(type) ? $event : [$event];
  }

  getTextAlternative(type: string) {
    const typeDict = { texto: 1, comentario: 5 }
    return <any[]>[{
      id: null,
      active: true,
      alternative_type: {
        name: type,
        id: typeDict[type],
      },
      translates: [],
      order: 0,
      score: 0,
    }]
  }

  getSectionFormGroup(section: Section): FormGroup {
    const questionForms = section.questions
      .filter(q => q.alternatives.length)
      .map(q => this.getQuestionFormGroup(q));
    const translateFormArray = this.getTranslatesFormArray(section.translates, 'title');

    const formGroup = this.fb.group({
      id: [section.id],
      active: [section.active],
      order: [section.order, orderValidator],
      page: [section.page],
      questions: this.fb.array(questionForms),
      translates: translateFormArray,
    });
    return formGroup;
  }

  getQuestionFormGroup(question: Question, isConditionalQuestion = false): FormGroup {
    const alternativesForm = question.alternatives.map(a => this.getAlternativeFormGroup(a));
    const subAltnativesForm = (question.alternatives[0].sub_alternatives || [])
      .map(s => this.getAlternativeFormGroup(s))
    const tagForms = question.tags.map(t => this.getTagFormGroup(t));
    const translateFormArray = this.getTranslatesFormArray(question.translates);

    return this.fb.group({
      id: [question.id],
      active: [question.active],
      alternatives: this.fb.array(alternativesForm),
      sub_alternatives: this.fb.array(subAltnativesForm),
      conditional_order: [question.conditional_order],
      created: [question.created],
      order:  [question.order, (!isConditionalQuestion) ? orderValidator : orderValidatorConditional],
      tags: this.fb.array(tagForms),
      translates: translateFormArray,
      required: [question.required],
      question_type: [question.alternatives[0].alternative_type.name],
      flag_default_value: [question.flag_default_value],
      flag_pregunt_standard: [question.flag_pregunt_standard],
      colored_grid: [question.colored_grid],
    });

  }

  getAlternativeFormGroup(alternative:Alternative, copy = false): FormGroup {
    const isGrid = (alternative.alternative_type.name === 'grid')

    const translateFormArray = this.getTranslatesFormArray(alternative.translates, 'text', copy);
    const tagForms = alternative.tags.map(t => this.getTagFormGroup(t, copy));
    const subAlternativeForms = isGrid
      ? alternative.sub_alternatives.map(s => this.getAlternativeFormGroup({...s}, copy))
      : [];
    const conditionalQuestionForms = alternative.conditional_questions.map(q => this.getConditionalQuestionOptionForm(q));
    const hasNotOrderValidator = ['comentario', 'texto'];

    return this.fb.group({
      id: [copy ? null : alternative.id],
      alternative_type: [alternative.alternative_type],
      conditional_questions: this.fb.array(conditionalQuestionForms),
      active: [alternative.active],
      order: [alternative.order, (hasNotOrderValidator.includes(alternative.alternative_type.name)) ? null : orderValidator],
      score: [alternative.score],
      tags: this.fb.array(tagForms),
      translates: translateFormArray,
      sub_alternatives: this.fb.array(subAlternativeForms),
    });
  }

  getTagFormGroup(tag: TagArea, copy = false): FormGroup {
    return this.fb.group({
      id: [copy ? null : tag.id],
      clasification: {
        id: tag.clasification.id
      }
    })
  }

  getConditionalQuestionOptionForm(option: QuestionConditionalOption) {
    return this.fb.group({
      id: [option.id],
      conditional_question_id: option.conditional_question_id,
    })
  }

  getTranslatesFormArray(translates: any[], key = 'text', copy = false): FormArray {
    const formArray = this.fb.array(
      this.languages.map(language => {
        const translate = translates.find(t => t.language.id === language.id)
          || new Translate({language});
        return this.getTranslateFormGroup(translate, key, copy);
      })
    );

    // check if required
    formArray.valueChanges.pipe(bufferTime(500)).subscribe((_) => {
      this.setTranslatesValidators(formArray, key)
    });

    // check if need to add translateForm
    this.isLanguageActiveChanges().subscribe((languagesIds) => {
      languagesIds.forEach(languageId => {
        const languageForm = formArray.controls.find(c => c.value.language.id === languageId);
        if (!languageForm) {
          const language = this._base.languages.find(({id}) => id === languageId)
          const languageForm = this.getTranslateFormGroup(new Translate({ language }));

          formArray.push(languageForm);
          formArray.markAsTouched();
          this.setTranslatesValidators(formArray, key)
          formArray.updateValueAndValidity();
        } else {
          (languageForm as FormGroup).patchValue({active: true});
        }
      })
      // check if need to deactivate
      formArray.controls.forEach(languageForm => {
        if (!languagesIds.includes(languageForm.value.language.id)) {
          languageForm.patchValue({active: false})
        }
      })
    })

    return formArray;
  }

  setTranslatesValidators(translateFormArray: FormArray, key = 'text') {
    const anyTranslate = translateFormArray.value
      .filter((t) => this.languagesActives.includes(t.language.id))
      .some((t) => t[key]);

    translateFormArray.controls.forEach((translateFormGroup:FormGroup) => {
      const validators = anyTranslate && translateFormGroup.value.active ? [Validators.required] : [];
      const prevRequired = translateFormGroup.value['required'];
      const translateFormControl = translateFormGroup.controls[key];

      if (anyTranslate && !prevRequired) {
        translateFormControl.setValidators(validators);
        translateFormGroup.patchValue({required: true}, {emitEvent: false})
        this.updateFormControl(translateFormControl)
      } else if (!anyTranslate && prevRequired){
        translateFormControl.clearValidators();
        translateFormGroup.patchValue({required: false}, {emitEvent: false})
        this.updateFormControl(translateFormControl)
      }
    });
  }

  updateFormControl(form: AbstractControl) { form.updateValueAndValidity({onlySelf: false, emitEvent: false})}

  getTranslateFormGroup(translate: Translate | TranslateSection | TranslateQuestion, key = 'text', copy = false): FormGroup {
    const active = this.languagesActives.includes(translate.language.id);

    return this.fb.group({
      id: [copy ? null : translate.id],
      language: [translate.language],
      active: [active],
      min_text: [translate['min_text']],
      max_text: [translate['max_text']],
      [key]: [translate[key] || ''],
      required: false,
      header_msg: [translate['header_msg'] || '']
    })
  }

  updateLanguages(form: FormGroup) {
    Object.keys(form.controls).forEach((formKey) => {
      if (form.controls[formKey]["controls"]) {
        this.updateLanguages(form.controls[formKey] as FormGroup);
      }

      if (formKey === "translates") {
        if (!(<FormArray>form.controls["translates"]).controls)
        (form.controls["translates"] as FormArray).controls.forEach(
          (translateForm) => {
            const active = this.languagesActives.includes(
              translateForm.value.language.id
            );
            translateForm.patchValue({ active });
          }
        );
      }
    });
  }

  triggerValidation(form, key) {
    form.controls.forEach(control => {
      control.get(key).updateValueAndValidity();
    })
  }

  get hasError(){
    return this._errorSurvey.code!==0;
  }

  get errorSurvey(){
    return this._errorSurvey;
  }

  get surveyId():number{
    return this.sessionService.currentSurveyId;
  }

  set surveyId(id:number){
    this.sessionService.currentSurveyId = id;
  }

  set errorSurvey(error:ErrorSurvey){
    this._errorSurvey = error;
  }

  get language(){
    return this._language;
  }

  set language(language:string){
    this._language = language;
  }

  get base() {
    return this._base;
  }

  set base(base: any) {
    this._base = base
  }
}
