import { Component, Input } from '@angular/core';


@Component({
  selector: 'mh-add-product',
  templateUrl: './add_product.component.html',
  styleUrls: ['../../../../../assets/scss/shared/elements/add_product.scss'],
})
export class AddProductComponent{
  @Input() title: String;
}
