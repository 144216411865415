import { Component, Inject } from '@angular/core';
import { DialogRef } from './dialog-ref';
import { DIALOG_DATA } from './dialog-tokens';

@Component({
  selector: 'mh-core-lib-modal',
  templateUrl: './modal.component.html',
})
export class CoreLibModalComponent {
  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: string
  ) {}

  close(result?) {
    this.dialogRef.close(result);
  }

}
