  <article class="tw-flex tw-flex-col error-container">
    <h2 class="tw-font-bold tw-text-xl tw-text-main-text" [class.tw-text-center]="centerTitle">{{titleTranslate | translate}}</h2>
    <section class="tw-grid tw-grid-cols-2">
      <figure class="tw-grid tw-place-content-center">
        <img class="tw-max-w-[250px]" src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/no+connection.png" alt="no connection">
      </figure>
      <article class="tw-grid tw-grid-rows-3 error-msg">
        <p class="tw-text-main-text tw-font-medium tw-row-start-2 tw-self-center tw-text-lg">
        {{message | translate}}
        </p>
        <button class="focus:tw-outline-0 tw-row-start-3 tw-text-main tw-font-medium tw-self-end tw-place-self-end tw-mt-1.5 tw-text-xl" (click)="retryFetch.emit()">{{'error.generic.reload' | translate}}</button>
      </article>
    </section>
  </article>
