import { Pipe, PipeTransform } from '@angular/core';

const UNNASSIGNED_ID = 5;
const GUEST = 6;

@Pipe({
  name: 'fidelityDeskProduct',
})
export class FidelityDeskProductPipe implements PipeTransform {
  transform(cases, filters) {
    let filteredCases = [...cases];
    if (filters) {
      const filter = filters.find((filterOption) => filterOption.name === 'product_item.product_id');
      const filtering = filter.options.some((option) => option.isFiltering);
      if (filter && filtering) {
        const options = filter.options
          .filter((option) => option.isFiltering)
          .map((option) => option.value?.toString() || 'null');
        filteredCases = cases.filter((item) => {
          const noProductorGuest = item.product_item === null && item.guest_params !== null ? GUEST : UNNASSIGNED_ID;
          const value = item.product_item?.product_id.toString() || noProductorGuest;
          return options.includes(value.toString());
        });
      }
    }
    return filteredCases;
  }
}
