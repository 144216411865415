import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'mh-survey-question-radio',
  templateUrl: './survey-question-radio.component.html',
  styleUrls: ['./survey-question-radio.component.scss']
})
export class SurveyQuestionRadioComponent implements OnInit {
  @Input() questionId:number;
  @Input() alternatives:any[]
  @Input() answers:any[]
  @Input() language:string
  @Input() conditionalId: number;
  @Input() preview = false;

  @Output() answer:EventEmitter<any> = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (!this.conditionalId) return;

    const answer = this.getAlternativeAnwser();

    if (answer.id || answer.tempId)
      this.answer.emit(this.createAnswer())
  }

  handleChange(alternative:any) {
    const answer = this.createAnswer(alternative, true);

    this.answer.emit(answer);
  }

  createAnswer(alternative:any = {}, selected = false) {
    const prevAnswer = this.getAlternativeAnwser();

    return {
      answer_alternative_id: alternative.id || prevAnswer.answer_alternative_id,
      score: alternative.score,
      selected,
      question_id: this.questionId,
      conditional_alternative_id: this.conditionalId,
      tempId: prevAnswer.tempId || uuid(),
      id: prevAnswer.id,
    }
  }

  isSelected(alternative:any) {
    const answer = this.getAlternativeAnwser()

    return answer.answer_alternative_id === alternative.id
      && answer.selected;
  }

  getAlternativeAnwser() {
    return this.answers.find(a => (
      a.question_id === this.questionId &&
        (!this.conditionalId || this.conditionalId === a.conditional_alternative_id)
    )) || {};
  }

  isDisabled() {
    if (this.preview) return true;
    const alternativeAnswer = this.answers
      .find(a => a.question_id === this.questionId) || {};

    return alternativeAnswer.waiting;
  }
}
