import {Directive, ElementRef, Input} from '@angular/core';
declare var jQuery: any;

@Directive({
  selector: '[flot-chart]'
})

export class FlotChartDirective {


  $el: any
  private innerOptions:any;
  private innerData:any;

  @Input() set data(data: any){
    this.innerData=data;
    this.render();
  }
  @Input() set options(options: any){
    this.innerOptions=options;
    this.render();
  }

  constructor(el: ElementRef) {
    this.$el = jQuery(el.nativeElement);
  }



  render(): void {
    // set any default options you want for flot chart
    jQuery.plot(this.$el, this.innerData, this.innerOptions || {
        series: {
          lines: {
            show: true,
            lineWidth: 1,
            fill: false,
            fillColor: { colors: [{ opacity: .001 }, { opacity: .5}] }
          },
          points: {
            show: false,
            fill: true
          },
          shadowSize: 0
        },
        xaxis: {
          mode: 'time',
          timezone: 'browser'
        },
        legend: false,
        grid: {
          show: false,
          margin: 0,
          labelMargin: 0,
          axisMargin: 0,
          hoverable: true,
          clickable: true,
          tickColor: 'rgba(255,255,255,1)',
          borderWidth: 0
        }
      });

  }

  ngOnInit(): void {
    this.render();
  }

}
