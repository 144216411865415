<section class="tw-flex">
  <button
    class="tw-rounded-full md:tw-w-fit tw-w-14 tw-h-14 tw-bg-white tw-text-main tw-shadow-1 tw-flex tw-justify-center md:tw-px-7 md:tw-py-2 focus:tw-outline-0"
    (click)="isOpen = !isOpen"
    cdkOverlayOrigin #trigger="cdkOverlayOrigin"
    [class.!tw-bg-main]="isOpen"
    [class.!tw-text-white]="isOpen"
  >
    <i class="fas fa-search tw-self-center xs:tw-hidden"></i>
    <i class="fas fa-clipboard-list tw-self-center tw-hidden xs:tw-inline-block"></i>
    <span class="tw-hidden md:tw-inline-block tw-ml-2 tw-text-base tw-font-medium tw-self-center tw-text-main-text" [class.!tw-text-white]="isOpen">
      <ng-container>
        <span [innerHTML]="'core-lib.selectors.competitors.button' | translate: { competitor: competitorSelected }"></span>
      </ng-container>
    </span>
  </button>

  <button
    class="tw-rounded-full tw-bg-white tw-text-main tw-shadow-1 tw-w-14 tw-h-14 tw-ml-3 hover:tw-text-white hover:tw-bg-main tw-transition-all focus:tw-outline-0"
    *ngIf="hasActiveFilters"
    (click)="cleanFilters()"
  >
      <div class="tw-relative">
        <i class="fas fa-filter xs:tw-hidden"></i>
        <i class="fas fa-clipboard-list tw-hidden xs:tw-inline-block"></i>
        <i class="fas fa-times-circle tw-absolute tw-text-red-500 tw-text-small tw-right-3.5"></i>
      </div>
  </button>
</section>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPanelClass]="['tw-top-[22%]', 'tw-left-[8%]', 'sm:tw-left-[inherit]']"
>
  <div>
    <article id="overlaySelectorGuest" class="tw-mt-3">
      <mh-core-lib-generic-box>
        <mh-core-lib-autocomplete-input
            [options]="competitors"
            [openBox]="true"
            [filterFn]="autocompleteFilterFn"
            [showGlassIcon]="true"
            [displayOptionsFn]="displayHotelOptions"
            (optionSelected)="handleCompetitorSelected($event)"
            >
        </mh-core-lib-autocomplete-input>
      </mh-core-lib-generic-box>
    </article>
  </div>
</ng-template>
