<section class="tw-flex tw-flex-row tw-justify-center tw-w-full tw-p-7">
  <div class="tw-text-main-text tw-hidden sm:tw-flex tw-items-center">{{ 'user_management.pagination_items' | translate }}</div>

<div class="tw-mx-5">
  <button class="pagination-selector tw-border tw-px-2 tw-py-1 tw-rounded-md tw-border-border-light-gray" (click)="paginationSelectorOpen = !paginationSelectorOpen" cdkOverlayOrigin #trigger="cdkOverlayOrigin">{{pagination === 0 ?  ('user_management.all' | translate) : pagination }}<i class="tw-text-main tw-ml-4 far fa-angle-down" [@rotate]="paginationSelectorOpen ? '0' : '-180'"></i></button>

</div>

  <div class="tw-text-main-text tw-items-center tw-hidden sm:tw-flex">{{ (pagination === 0 ? 'user_management.pagination_all' : 'user_management.pagination_status') | translate: { 'min': pagination !== 0 ? (pagination * (currentPage)) + 1 : totalElements, 'max': (currentPage + 1) * pagination, 'total': totalElements } }}</div>

  <div class="tw-ml-12 tw-flex tw-items-center" *ngIf="pagination !== 0">
    <ng-container *ngIf="currentPage !== 0">
      <i class="tw-text-main fal fa-angle-double-left tw-cursor-pointer tw-text-base" (click)="handlePageChanged(0, 'first')"></i>

      <i class="tw-text-main tw-mx-1 fal fa-angle-left tw-cursor-pointer tw-text-base" (click)="handlePageChanged(currentPage, 'back')"></i>

    </ng-container>
    <ng-container *ngIf="currentPage < Math.ceil(totalElements / pagination) - 1">
      <i class="tw-text-main tw-mx-1 fal fa-angle-right tw-cursor-pointer tw-text-base" (click)="handlePageChanged(currentPage, 'forward')"></i>
      <i class="tw-text-main tw-mx-1 fal fa-angle-double-right tw-cursor-pointer tw-text-base" (click)="handlePageChanged(null, 'last')"></i>
    </ng-container>
  </div>

</section>

<ng-template
  cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="paginationSelectorOpen"
>
  <div class="pagination-menu tw-bg-white tw-shadow-lg tw-rounded-lg tw-px-3 tw-py-1 tw-my-1">
    <ul>
      <li *ngFor="let page of PAGINATION_VALUES" class="pagination-option tw-py-1 tw-cursor-pointer hover:tw-text-main-orange tw-transition-all" (click)="setPagination(page)">{{page === 0 ?  ('user_management.all' | translate) : page }}</li>
    </ul>
  </div>
</ng-template>
