import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import { UserProfile } from 'app/shared/model';

@Component({
  selector: 'mh-user-thumbnail',
  templateUrl: './user-thumbnail.component.html',
  styleUrls: ['./user-thumbnail.component.scss'],
})
export class UserThumbnailComponent {
  @Input()
  user:UserProfile;

  @Input()
  measure = 'px';

  @Input()
  size:number=33;

  @Input()
  showBorder = true;

  @Input()
  activeMenu = false;

  constructor(){}

  get border() {
    return (this.showBorder) ?
      'new-icons' : '';
  }
}

@Pipe({name: 'photoOrDefault'})
export class PhotoOrDefaultPipe implements PipeTransform {
  transform(value: UserProfile = new UserProfile, activeMenu = false) {
    if (activeMenu && this.checkIsDefaultPhoto(value.profile_photo)) return value.profile_photo_active;
    return value.profile_photo
  }

  checkIsDefaultPhoto(photo: string) {
    return 'https://statics.myhotel.io/uploads/fidelity-suite/users/default_user.png' === photo;
  }

}
