<div class="row">
  <div class="col-md-12">
    <section id="trends">
      <widget-trends
        [showFilters]="showFilters"
        [waiting]="waiting"
        [chain]="chain"
        [trendKeys]="lists"
        [trendSelected]="trend"
        [areasKeys]="areas"
        [areaSelected]="areaSelected"
        [segmentedSelected]="segmentedBy"
        [lineChartData]="lineChartData"
        [lineChartLabels]="lineChartLabels"
        [lineChartColors]="lineChartColors"
        [showDefaultLegends]="showDefaultLegends"
        (filterTypeChange)="setSelectlValue($event.option,$event.key)"
        (filterCustomerChange)="filterCustomers($event)"
      ></widget-trends>
    </section>
  </div>
</div>
