import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {

  constructor() { }

  isMobile() {
    return window.innerWidth < 640;
  }

  isSm() {
    return window.innerWidth >= 640;
  }

  isMd() {
    return window.innerWidth >= 768;
  }

  isLg() {
    return window.innerWidth >= 1024;
  }

  isXl() {
    return window.innerWidth >= 1280;
  }

  is2xl() {
    return window.innerWidth >= 1536;
  }

  isMinorThanSm() {
    return window.innerWidth < 640;
  }

  isMinorThanMd() {
    return window.innerWidth < 768;
  }

  isMinorThanLg() {
    return window.innerWidth < 1024;
  }

  isMinorThanXl() {
    return window.innerWidth < 1280;
  }

  isMinorThan2xl() {
    return window.innerWidth < 1536;
  }
}
