import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { UtilService } from 'app/shared/util.service';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmCreateComponent } from '../confirm-create/confirm-create.component';
import { PersonalInformationComponent } from '../personal-information/personal-information.component';

@Component({
  selector: 'mh-create-user-main-modal',
  templateUrl: './create-user-main-modal.component.html',
  styleUrls: ['./create-user-main-modal.component.scss']
})
export class CreateUserMainModalComponent extends CoreLibModalComponent implements OnInit {

  @ViewChild(PersonalInformationComponent) PersonalInformationCmp;

  disableNextBtn = false;
  steps = ['personal_data', 'assign_properties', 'assign_team'];
  personalInfoValid = false;
  hotelsSelected = [];
  hotelsToDelete = [];
  showSave = false;
  user;
  userToEdit;
  isSuperAdmin;
  isAdmin;
  isFromProfilePage = false;
  isInternalUser = false;

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private userManagementService: UsersManagmentService,
    private modalService: ModalService,
    private utilService: UtilService,
  ) {
    super(dialogRef, data);
  }
  ngOnInit(): void {
    this.isInternalUser = this.data?.action === 'internal_user';
    if (this.data?.action === 'edit') {
      this.userToEdit = this.data.user;
      this.disableNextBtn = true;
      this.isInternalUser ||= this.data?.user.flag_super_admin; 
    }
    this.isSuperAdmin = this.utilService.currentUser.isSuperAdmin();
    this.isAdmin = this.utilService.currentUser.isAdmin();
    this.isFromProfilePage = this.data?.isFromProfilePage;
  }

  updateNextBtnState(value) {
    this.personalInfoValid = value;
    if(this.PersonalInformationCmp) {
      this.PersonalInformationCmp.emitUser();
    }
  }

  updateHotelsToDelete(hotels) {
    this.hotelsToDelete = hotels;
    this.showSave = (!!this.hotelsSelected?.length || !!this.hotelsToDelete.length) && !this.disableNextBtn
  }

  updateHotelsSelected(hotels) {
    this.hotelsSelected = hotels;
    this.showSave = (!!this.hotelsSelected?.length || !!this.hotelsToDelete.length) && !this.disableNextBtn
  }

  updateUser(user) {
    this.user = user;
    this.disableNextBtn = false;
    this.showSave = (!!this.hotelsSelected?.length || !!this.hotelsToDelete.length) && !this.disableNextBtn || this.isFromProfilePage || this.isInternalUser;
  }

  saveUser() {
    const body = {
      email: this.user.email || '',
      username: this.user.email || '',
      first_name: this.user.name || '',
      last_name: this.user.lastName || '',
      company_position: this.user.job || '',
      flag_admin: this.user.flagAdmin,
      language_id: this.user.language,
      mobile_number: this.user.cellphone || '',
      mobile_country_code: this.user.cellphoneCountryCode || '',
      phone_number: this.user.phone || '',
      phone_country_code: this.user.phoneCountryCode || '',
      flag_super_admin: this.data?.action === 'internal_user' || this.isInternalUser,
      profile_photo_raw: this.user.profilePhotoRaw || null,
      department: this.user.department || null,
      pipedrive_id: this.user.pipedriveId || null,
      role: this.user.role || null,
      birth_date: this.user?.birthdate || null,
    };


    let propertiesId = this.hotelsSelected.filter(Boolean).map((hotel) => hotel.id);
    if (this.userToEdit) {
      if (!this.isSuperAdmin && !this.isFromProfilePage && !this.isInternalUser) {
        const idsNotShown = this.data.user.customers_ids.map((customer) => customer.id).filter((id) => !this.data.customers.map((customer) => customer.id).includes(id));
        propertiesId = [...propertiesId, ...idsNotShown.filter(Boolean)];
      }
      this.userManagementService.updateUser(body, this.userToEdit.id).subscribe((user: any) => {
        if (user && !this.isFromProfilePage && !this.isInternalUser) {
          const propertiesToDelete = this.hotelsToDelete.map((hotel) => hotel.id);
          forkJoin({
            add: this.userManagementService.assign_properties(this.data.userIds, propertiesId),
            delete: this.userManagementService.revokeProperties(this.data.userIds, propertiesToDelete),
          }).subscribe(( result: { add: any, delete: any}) => {
            this.close(result.add || result.delete);
          });
        } else if ((this.isFromProfilePage || this.isInternalUser) && user) {
          this.close(user);
        }
      });
    } else {
      this.userManagementService.createUser(body).subscribe((user: any) => {
        if (user && !this.isFromProfilePage && !this.isInternalUser) {
          this.userManagementService.assign_properties(user.id, propertiesId).subscribe(result => {
            this.launchConfirmUserAction(user);
          });
        } else if (user) {
          this.launchConfirmUserAction(user);
        }
      });
    }
  }

  launchConfirmUserAction(user) {
    const confirmModalRef= this.modalService.open(ConfirmCreateComponent, { data: user }, ['overlay-panel', 'lg']);
    confirmModalRef.afterClosed().pipe(take(1)).subscribe(({toggles, userId}) => {
      ['welcomeEmail', 'invalidatePassword'].forEach((value, idx) => {
        if (toggles[idx]) {
          this.userManagementService[value](userId).subscribe();
        }
      });
    });
    this.close(true);
  }

  get footerMT() {
    return (this.isSuperAdmin) ? 'tw-mt-[-30px]' : 'tw-mt-[62px]';
  }
}
