import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mh-core-lib-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
})
export class CoreLibCheckboxInputComponent {
  @Input()
  options = [];

  @Input()
  translatePrefix = '';

  @Input()
  labelText = 'text';

  @Input()
  needTranslate = true;

  @Input()
  needObjectTranslate = false;

  @Input()
  title = '';

  @Input()
  displayOptionsFn = null;

  @Input()
  displayHtml = false;

  @Input()
  keySelected = 'selected';

  @Input()
  labelClass = '';

  @Input()
  inputClass = '';

  @Input()
  listClass = '';

  @Input()
  isSingleSelection = false;

  @Output()
  optionClicked = new EventEmitter();

  handleClick(option, input) {
    if (this.isSingleSelection) {
      this.options.forEach((opt) => (opt.selected = false));
      option.selected = true;
    }
    input.checked = !input.checked;
    this.optionClicked.emit(option);
  }

  optionById(index, option) {
    return option.id;
  }
}
