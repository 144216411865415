import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-support-field-product-tag',
  templateUrl: './support-field-product-tag.component.html',
  styleUrls: ['./support-field-product-tag.component.scss']
})
export class SupportFieldProductTagComponent implements OnInit {

  @Input()
  product = '';

  @Input()
  products:any;

  @Input()
  form:FormGroup;

  tags:any;
  tagsName: any = [];
  clientType = '';
  constructor(
    private  utilService:UtilService
  ) { }

  ngOnInit() {
    this.clientType = (this.isRetail) ? 'retail' : 'default';
  }

  ngOnChanges() {
    this.setTagList();
  }

  setTagList() {
    this.tagsName = [];
    this.tags = this.products.find(x=>x.name===this.product)
    if(this.tags) {
      this.tags.children.forEach(tag => {
        this.tagsName.push(tag.name)
      });
    }
  }

  get isRetail() {
    return this.utilService.customerIsRetail;
  }
}
