import { Component, Input } from '@angular/core';
import { FormBuilder,FormControl, FormGroup, Validators } from '@angular/forms';
import { EditUserService } from "../edit-user.service";
import { UtilService } from "../../../shared/util.service";
import { User } from "../../../shared/model/index";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Md5 } from 'ts-md5/dist/md5';
import { strengthValidator } from '../../../shared/validators/strength-password.validator';

@Component({
  selector: 'mh-edit-user-password',
  templateUrl: './edit-user-password.component.html',
  providers:[EditUserService]
})
export class EditUserPassword{
  setForm:FormGroup;
  newPassword:SetPassword=new SetPassword();
  validates= ['password','new_password','confirm_password'];
  waiting:boolean = false;

  @Input()
  user:User;

  @Input()
  style:string='';

  @Input()
  title:string='forms.edit_password';

  status_change='error';

  util;

  currentUser;
  possibleErrors = ['hasUpperCase', 'hasLowerCase', 'hasNumber', 'hasSpecialCharacters', 'hasValidLength', 'exception'];

  constructor(private utilService:UtilService, private editUserService:EditUserService, private modalService: NgbModal,private fb: FormBuilder){
    this.util = this.utilService;
  }

   ngOnInit(){
    this.currentUser = this.utilService.getCurrentUser();
    this.setForm = this.fb.group({
      'password': new FormControl(this.newPassword.password, [
        Validators.required,
        Validators.minLength(4),
      ]),
      'new_password': new FormControl('', [
        Validators.required,
        strengthValidator(),
      ]),
      'confirm_password': new FormControl(this.newPassword.confirm_password, [
        Validators.required,
        Validators.minLength(8),
      ])

    });
  }

  openModal(content) {
    this.modalService.open(content, {
      windowClass: 'modal-confirm left'
    });
  }

  get names() {            return this.setForm.get('names'); }
  get password() {         return this.setForm.get('password'); }
  get new_password() {     return this.setForm.get('new_password'); }
  get confirm_password() { return this.setForm.get('confirm_password'); }


  isEmpty($val):boolean{
    return !$val.value;
  }

  changePassword(){
    this.waiting = true;
    let form                      = this.setForm.value;
    this.newPassword.password     = form.password;
    this.newPassword.new_password = form.new_password;
    this.editUserService.changePassword(this.user.id, this.newPassword.getMd5()).subscribe((response:any)=>{
      this.status_change = response.code === 401 ? 'incorrect' : 'confirm';
      this.waiting = false;
    }, () => {
      this.status_change='error';
      this.waiting = false;
    })
  }
}



export class SetPassword{
  password:string;
  new_password:string;
  confirm_password:string;
  getMd5(){
    return {
      'password' : Md5.hashStr(this.password),
      'new_password' : Md5.hashStr(this.new_password)
    }
  }
}
