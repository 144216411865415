import { Component, Input } from '@angular/core';


@Component({
  selector: 'mh-semantic-bar',
  templateUrl: './semantic-bar.component.html',
  styleUrls: ['../../../../../assets/scss/shared/elements/semantic-bar.scss'],
})
export class SemanticBarComponent{


  @Input()
  positivePercentage;
  @Input()
  neutralPercentage;
  @Input()
  negativePercentage;

  @Input()
  positiveLabel;
  @Input()
  neutralLabel;
  @Input()
  negativeLabel;

}
