import { plainToClass } from "class-transformer";
import {Hotel} from '../hotel';

export class ProductOptions{
  id:number;
  name:string;
  constructor(id,name){
    this.id   = id;
    this.name = name;
  }
}

export class Product{
  static DEFAULT_IMAGE = 'http://statics.myhotel.io/uitls/product_default.png';
  id: number;
  image: string = Product.DEFAULT_IMAGE;
  type: ProductOptions = new ProductOptions(1,'price');
  deleted:boolean=false;
  categories:ProductOptions[] = [];
  categoriesIds: any[]=[];
  translates:ProductTranslate[]  = [];
  properties:ProductProperties[] = [];
  response: any

  constructor(){}

  createFromApi(product, customerId?){
    if(customerId) {
      const prodCustomer = product.properties.find(p => p.customer.id === customerId)
      this.deleted        = !product.deleted && !prodCustomer.deleted ? false : true
    }
    this.id             = product.id;
    this.image          = product.image || Product.DEFAULT_IMAGE;
    this.type           = product.type;
    this.categories     = plainToClass(ProductOptions, product.categories);
    this.translates     = plainToClass(ProductTranslate, product.translates);
    this.properties     = plainToClass(ProductProperties, product.properties);
    this.response       = product
    this.afterCreate();
    return this;
  }

  getTranslates(){
    let languages = {}
    this.translates.map(x=>{
      let lan = x.language.code;
      languages[lan]=x;
      return x;
    })
    return languages;
  }

  getProperty(customer){
    return this.properties.find(x=>x.customer.id===customer.id);
  }

  private afterCreate(){
    this.setCateogiresIds();
  }

  private setCateogiresIds(){
    this.categoriesIds = this.categories.map(x=>x.id);
  }
}

export class ProductTranslate{
  id: number;
  language:any              = {id:1, code:'es'};
  name: string              = '';
  description: string       = '';
  short_description: string = '';
  has_question: boolean     = false;
  question: string          = '';

}

export class ProductProperties{
  id:number;
  customer_id:number;
  customer:any;
  active:boolean       = false;
  create:boolean       = false;
  discount: number     = 10;
  price: number        = 5000;
  aux_price: number    = 4500;
  max_quantity:number  = 0;
  has_quantity:boolean = false;
  price_type:ProductOptions = new ProductOptions(1,'price');
  sku:number = 0;
  comment_required  = true;
  position          = 0;
  referral_value    = 0;
  referral_percent  = 50;
  referral_type:ProductOptions    = new ProductOptions(1,'aditional');

  show_price:boolean= true;
  show_aux_price:boolean = true;
  show_discount:boolean = true;

  constructor(customer){
    this.customer = customer;
  }

  fillCustomer(chain:Hotel[]){
    this.customer = chain.find(x=>parseInt(x.id)===this.customer_id)
  }


}


