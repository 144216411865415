<div class="tw-relative tw-h-full">
  <div class="tw-w-full tw-text-center tw-py-2 tw-bg-[#ff6f5b] tw-relative tw-h-[10%] md:tw-h-[15%] tw-grid tw-place-content-center">
    <span class="tw-font-semibold tw-text-lg tw-text-white">{{'notifications.payment_none.title' | translate}}</span>
    <div class="tw-absolute tw-w-[16px] tw-h-[16px] tw-inset-y-0 tw-my-auto tw-right-[10px] tw-grid tw-place-content-center">
      <button class="focus:tw-outline-0" (click)="handleClose()">
        <i class="fas fa-times tw-text-xl tw-text-white"></i>
      </button>
    </div>
  </div>
  <div class="tw-py-3 tw-px-10 tw-flex tw-flex-col tw-h-[90%] md:tw-h-[85%] tw-overflow-y-scroll md:tw-overflow-y-hidden md:tw-w-[70%] md:tw-mx-auto">
    <section *ngIf="isWarning" class="tw-flex tw-flex-col tw-gap-[0.5rem]" [innerHTML]="'notifications.payment_warning.message' | translate: {name}">
    </section>
    <section *ngIf="isSuspension" class="tw-flex tw-flex-col tw-gap-[0.5rem]" [innerHTML]="'notifications.payment_none.message' | translate: {name}">
    </section>
    <section>
      <ng-container *ngIf="isWarning">
        <ng-container *ngTemplateOutlet="warning"></ng-container>
      </ng-container>
      <ng-container *ngIf="isSuspension">
        <ng-container *ngTemplateOutlet="suspension"></ng-container>
      </ng-container>
    </section>
  </div>
</div>

<ng-template #warning>
  <div class="tw-flex tw-flex-col md:tw-flex-row tw-px-3 md:tw-justify-between tw-mt-10 tw-gap-3" [ngClass]="{'tw-justify-center': !isAdmin,'md:tw-justify-center': !isAdmin}">
    <button type="button" class="focus:tw-outline-0 tw-text-white tw-px-5 tw-py-2 tw-rounded-full tw-bg-[#9c9c9c] tw-w-[210px] tw-text-center" (click)="handleClose()">{{'commons.close' | translate}}</button>
    <button *ngIf="isAdmin" type="button" class="focus:tw-outline-0 tw-text-white tw-px-5 tw-py-2 tw-rounded-full tw-bg-main tw-w-[210px] tw-text-center" (click)="handleInvoiceRedirect()">{{'configuration.invoice.go_to_invoice' | translate}}</button>
  </div>
</ng-template>

<ng-template #suspension>
  <div class="tw-flex tw-flex-col md:tw-flex-row tw-px-3 md:tw-justify-between tw-mt-10 tw-gap-3" [ngClass]="{'tw-justify-center': !isAdmin,'md:tw-justify-center': !isAdmin}">
    <button *ngIf="!isAdmin" type="button" class="focus:tw-outline-0 tw-text-white tw-px-5 tw-py-2 tw-rounded-full tw-bg-[#9c9c9c] tw-w-[210px] tw-text-center" (click)="handleClose()">{{'commons.close' | translate}}</button>
    <a *ngIf="isAdmin" href="mailto:cobranza@myhotel.cl" class="focus:tw-outline-0 tw-text-white tw-px-5 tw-py-2 tw-rounded-full tw-bg-[#9c9c9c] tw-w-[210px] tw-text-center">{{'notifications.payment_none.talk_doubt_collection' | translate}}</a>
    <button *ngIf="isAdmin" type="button" class="focus:tw-outline-0 tw-text-white tw-px-5 tw-py-2 tw-rounded-full tw-bg-main tw-w-[210px] tw-text-center" (click)="handleInvoiceRedirect()">Ir a facturación</button>
  </div>
</ng-template>
