import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckDateDiffDatepickerPipe } from './pipes/check-date-diff-datepicker.pipe';

@NgModule({
  declarations: [CheckDateDiffDatepickerPipe],
  imports: [CommonModule],
  exports: [CheckDateDiffDatepickerPipe],
})
export class CoreLibModule {}
