import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mh-support-field-attached-files',
  templateUrl: './support-field-attached-files.component.html',
  styleUrls: ['./support-field-attached-files.component.scss']
})
export class SupportFieldAttachedFilesComponent implements OnInit {

  @Output()
  attachment: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  setAttachment(attachment){
    this.attachment.emit(attachment);
  }

}
