import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mh-accesses-table',
  templateUrl: './accesses-table.component.html',
  styleUrls: ['./accesses-table.component.scss']
})
export class AccessesTableComponent implements OnInit {
  hotelsSelecteds = [];
  chainSelecteds = [];
  hotelsToDetele = [];
  userCustomersFiltered = [];
  @Input() dataCustomers;
  @Input() userCustomers;
  @Input() chains;
  @Input() progressRate;
  userChains;
  @Input() height = 'tw-h-[410px]';

  @Output() updateHotelsSelected = new EventEmitter();
  @Output() updateHotelsToDelete = new EventEmitter();
  @Output() updateIndex = new EventEmitter();

  ngOnInit(): void {
    this.userChains = this.chains;
    this.filterHotelsToShow();
  }

  setSelectedHotels(hotels) {
    this.hotelsSelecteds = hotels;
    const hotelsToDeleteIds = this.hotelsToDetele.map((hotel) => hotel?.id);
    const hotelFound = hotels.find((hotel) => hotelsToDeleteIds.includes(hotel?.id));
    if (hotelFound) {
      this.hotelsToDetele = this.hotelsToDetele.filter((hotel) => hotel?.id !== hotelFound?.id);
      this.updateHotelsToDelete.emit(this.hotelsToDetele);
    }
    this.updateDataCustomers(hotels, true);
  }

  setChainSelected(chains) {
    const chainIds = [...new Set(chains.map((chain) => chain.id))];
    this.chainSelecteds = this.userChains.filter((chain) => chainIds.includes(chain.id));
    this.userChains = this.userChains.filter((chain) => !chainIds.includes(chain.id));
    const hotels = this.dataCustomers.filter((customer) =>
      customer.chain_property?.chain_id?.id ? chainIds.includes(customer.chain_property.chain_id.id) : false,
    );
    this.setSelectedHotels([...this.hotelsSelecteds, ...hotels]);
  }

  deleteHotel(hotelToDelete) {
    this.hotelsSelecteds = this.hotelsSelecteds.filter((hotel) => hotel?.id !== hotelToDelete?.id);
    this.hotelsToDetele = [...this.hotelsToDetele, hotelToDelete];
    this.dataCustomers.unshift(hotelToDelete);

    if (
      hotelToDelete?.chain_property?.chain_id &&
      !this.userChains.find((chain) => chain.id === hotelToDelete?.chain_property?.chain_id?.id)
    ) {
      this.userChains.unshift(hotelToDelete?.chain_property?.chain_id);
    }
    
    this.updateHotelsSelected.emit(this.hotelsSelecteds);
    this.updateHotelsToDelete.emit(this.hotelsToDetele);
  }

  updateDataCustomers(hotels, emit = false) {
    this.dataCustomers = this.dataCustomers.filter((customer) => {
      const exists = hotels.find((hotel) => hotel?.id === customer?.id);
      return !exists;
    });

    if (emit) {
      this.hotelsSelecteds = this.hotelsSelecteds.filter(Boolean);
      if (this.hotelsSelecteds?.length > 0) {
        this.updateHotelsSelected.emit(this.hotelsSelecteds);
      }
    }
  }

  filterHotelsToShow() {
    if (this.userCustomers?.length > 0) {
      this.userCustomers.forEach((customer) => {
        const customerFound = this.dataCustomers.find((dataCustomer) => dataCustomer.id === customer);
        this.hotelsSelecteds = [...this.hotelsSelecteds, customerFound];
        this.updateDataCustomers(this.hotelsSelecteds);
      });
    }
  }

  get hotels() {
    return this.hotelsSelecteds;
  }

  get customers() {
    return this.dataCustomers;
  }

  updateIndexEmit(value) {
    this.updateIndex.next(value);
  }
}
