import { Pipe, PipeTransform } from '@angular/core'

import { Survey } from "app/shared/model/index";
import { FollowupOnsiteGuestFilter } from 'app/states/followup-onsite/followup-onsite.state';

@Pipe({ name: 'followupOnsiteLanguagesFilter' })
export class FollowupOnsiteLanguagesFilterPipe implements PipeTransform {

  transform(value: Survey[], filters: FollowupOnsiteGuestFilter): Survey[] {
    if(filters.languages) {
      const filtered = value.filter(survey => !!filters.languages.find(country => country === survey.guest.language.code) )
      if(filtered?.length > 0) return filtered
      else return value
    } else
      return value
  }
}
