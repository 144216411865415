<div>
  <mat-form-field class="hotel-finder" [appearance]="isCorporative && areCustomersFilled ? '' : 'none'">
    <ng-container *ngIf="isCorporative">
      <span class="hotel-name pointer ellipsis" [matMenuTriggerFor]="menu" (menuOpened)="setFocus()">
        <ng-container *ngIf="!isNavbarCorp && !isMobile; else fullCorporative">
          {{ clientNameCorporative }}
        </ng-container>
      </span>
      <input
        #input
        matInput
        class="pointer focus:tw-ring-0"
        type="text"
        [value]=""
        [readonly]="true"
        [matMenuTriggerFor]="menu"
        (menuOpened)="setFocus()"
        aria-label="test"
      />

      <ng-template #fullCorporative>
        {{ corpPrefix | translate }} <ng-container *ngIf="isNavbarCorp && !isMobile"><span> | </span></ng-container>
        <strong *ngIf="!isMobile">{{ clientNameCorporative }}</strong>
        <span *ngIf="isMobile">{{ clientNameCorporative }}</span>
      </ng-template>
      <!-- <ng-container *ngIf="!areCustomersFilled">
        <input class="focus:tw-ring-0" #input matInput type="text" [value]="clientNameCorporative" [readonly]="true" aria-label="test-2">
      </ng-container> -->
    </ng-container>

    <ng-container *ngIf="!isCorporative">
      <input matInput type="text" [(ngModel)]="inputValue" [hidden]="true" />
      <mh-find-customer
        (isReady)="isReadyToEmit($event)"
        (customer)="updateCustomer($event)"
        [clientType]="findClientType"
        [source]="'header'"
        [isSearcherMenuOpened]="isSearcherMenuOpened"
        *ngIf="is_super_admin"
      ></mh-find-customer>
    </ng-container>

    <button
      *ngIf="isCorporative && areCustomersFilled"
      matSuffix
      mat-icon-button
      aria-label="Open"
      (click)="triggerMenu()"
    >
      <mat-icon>expand_more</mat-icon>
    </button>

    <button
      mat-button
      *ngIf="!isCorporative && inputValue"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="inputValue = ''"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-menu class="clients-list {{ adjustMenuSize }}" #menu="matMenu">
    <ng-template matMenuContent>
      <div mat-menu-item (click)="$event.stopPropagation()" class="d-flex align-items-end item-search">
        <form class="search-form">
          <mat-form-field class="example-full-width">
            <mat-label>{{ 'commons.search' | translate }}</mat-label>
            <input type="text" class="focus:tw-ring-0" #inputSearch matInput autofocus [formControl]="clientControl" />
          </mat-form-field>
        </form>
      </div>
      <button mat-menu-item *ngFor="let customer of filteredOptions | async" (click)="updateCustomer(customer)">
        <span> {{ customer.name }} ({{ customer.id }}) </span>
      </button>
    </ng-template>
  </mat-menu>
</div>
