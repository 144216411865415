<div class="content_arrow" [class.row]="isCardView" [ngClass]="classArrow">
  <div class="col-2 text-center" *ngIf="isCardView">
    <i class="fas fa-suitcase-rolling"></i>
  </div>
  <div class="text" [ngClass]="classText">{{ label }}</div>
  <div class="time" [class.col-5]="isCardView">
    <ng-container *ngIf="!isAirline && days; else elseEmpty">
      {{'commons.stay_'+(labelInNigths ? 'nights.' : 'days.')+(days>1 ? 'plural' : 'single') | translate:({number: days }) }}
    </ng-container>
  </div>
</div>
<ng-template #elseEmpty>
  -
</ng-template>
