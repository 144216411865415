import { Component, OnInit, Input } from '@angular/core';
import { FollowupPropertiesService } from '../../properties/followup-properties.service';
import { FilterDate } from 'app/shared/model/filters/filter-date.model';
import { UtilService } from 'app/shared/util.service';
import { calculateWeightedSum } from 'app/shared/helpers/arrays';
import { Subscription } from 'rxjs/internal/Subscription';
import { Hotel, Period } from 'app/shared/model';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';

export interface DataResponse{
  areas: DataAreaResponse[];
}

export interface DataAreaResponse{
  area_id: number;
  answers: Period;
  rating: Period;
}

@Component({
  selector: 'mh-chain-followup-areas',
  templateUrl: './chain-followup-areas.component.html',
  styleUrls: ['./chain-followup-areas.component.scss'],
  providers: [FollowupPropertiesService]
})
export class ChainFollowupAreasComponent implements OnInit {

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  productId = 1;

  @Input()
  chain: Hotel[] = [];

  @Input()
  currentDates = null;

  totals = {};
  tooltipHeaders = ['answers']

  waiting = true;
  data: DataResponse;

  firstCall = true;
  surveySubscribe: Subscription;
  customersStateSubscribe: Subscription;

  headerTable: any[] = [];
  dataTable: any[] = [];
  dataSource: any = new MatTableDataSource([]);

  constructor(
    private utilService: UtilService,
    private followupPropertiesService: FollowupPropertiesService,
    private store: Store
  ) {

    this.surveySubscribe = this.utilService.isCurrentSurveyChanged().subscribe(surveId => {
      if ( this.currentDates ) {
        this.init();
      }
    })

    this.customersStateSubscribe = this.customersReady$.subscribe(customers => {
      if ( this.currentDates ) {
        this.init();
      }
    });
  }

  ngOnDestroy(){
    this.surveySubscribe.unsubscribe();
    this.customersStateSubscribe.unsubscribe();
  }

  reset(){
    this.dataTable = [];
    this.dataSource = new MatTableDataSource(this.dataTable);
    this.headerTable = ['area','rating','answers'];
    this.totals = {};
  }

  ngOnInit() {
  }

  ngOnChanges({currentDates}) {
    if ( currentDates ) {
      this.init();
    }
  }

  init(){
    this.waiting = true;
    this.reset();
    const date      = new FilterDate(this.currentDates.startDate, this.currentDates.endDate);
    const survey    = this.utilService.getCurrentSurvey(this.chain.map(x=>parseInt(x.id)));
    const surveyIds = survey.ids;
    const version = survey.survey[0].version;
    const language  = this.utilService.getLanguageId();
    const customers = this.chain ?this.chain.map((c) => c.id).join(',') : this.store.selectSnapshot(CustomersState.relativeCurrentChain).map((c) => c.id).join(',');

    if ( customers.length ) {
      this.followupPropertiesService.getRankignAreas(date, customers,this.productId, language,surveyIds, version).subscribe((response:any)=>{
        this.data = response.average;
        this.createTable();
        this.firstCall = false;
        this.waiting = false;
      })
    }
  }


  createTable(){
    this.dataTable = [];

    const weightedSum: any   = new Period();
    const qtyAnswer: any     = new Period();

    this.data.areas.forEach(area=>{
      this.dataTable.push({
        'area' : area.area_id,
        'rating' : area.rating,
        'answers' : area.answers
      })
      weightedSum.addInKey('actual',calculateWeightedSum(area,'actual'));
      weightedSum.addInKey('previous',calculateWeightedSum(area,'previous'));
      qtyAnswer.addInKey('actual',(area.answers.actual || null));
      qtyAnswer.addInKey('previous',(area.answers.previous || null));
    })

    const totalRating  = new Period();
    const totalAnswers = new Period();

    totalRating.actual   = weightedSum.actual/qtyAnswer.actual;
    totalRating.previous = weightedSum.previous/qtyAnswer.previous;
    totalRating.calculateVaration();

    totalAnswers.actual   = qtyAnswer.actual;
    totalAnswers.previous = qtyAnswer.previous;
    totalAnswers.calculateVaration();

    this.totals = {
      'rating' : totalRating,
      'answers' : totalAnswers
    }

    this.dataSource = new MatTableDataSource(this.dataTable);

  }
}
