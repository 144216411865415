import { UtilService } from 'app/shared/util.service';
import { Component, Input,ViewChild,TemplateRef, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ChangeImageService } from './change_image.service';

@Component({
  selector: 'mh-change-image',
  templateUrl: './change-image.component.html',
  styleUrls: ['../../../../../assets/scss/shared/elements/change-image.scss'],
  providers: [ChangeImageService]
})
export class ChangeImageComponent{

  @Input()
  image;

  @Input()
  ratio:number=4/3;

  @Input()
  waitToUpload:boolean = false;

  @Input()
  triggerUpload:boolean = false;

  @Input()
  onlyCropp:boolean = false;

  @Input()
  customerId:string;

  @Input()
  disabled:boolean = false;

  @Input()
  textButton = 'change';

  @Input()
  inputStyle = '';

  @Input()
  modalSize = 'lg';

  @Output()
  changed: EventEmitter<any> = new EventEmitter();

  @Output()
  waiting: EventEmitter<boolean> = new EventEmitter();

  @Output()
  attachment: EventEmitter<any> = new EventEmitter();

  imageChangedEvent: any;
  croppedImage: any = '';
  uploading:boolean = false;
  modalObject;

  @ViewChild('setImageSize', { static: true })
  imageUploaded: TemplateRef<any>;


  constructor(
    private utilService: UtilService,
    private modalService: NgbModal,
    private changeImageService:ChangeImageService){}

  ngOnInit(){
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['triggerUpload'] && changes['triggerUpload'].currentValue){
      this.uploadImage();
    }
  }

  openModal(content) {
    this.modalObject = this.modalService.open(content, {
      windowClass: 'modal-confirm ',
      size: this.modalSize
    })
  }

  closeModal(){
    this.modalObject.close()
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.openModal(this.imageUploaded)
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {

  }
  loadImageFailed() {

  }

  cancelEditImage(){
    this.croppedImage = this.image;
    this.closeModal()
  }

  acceptEditImage(){
    this.image     = this.croppedImage;
    this.closeModal();
    if(!this.waitToUpload) this.uploadImage();
  }

  loadImageNoCropp(files){
    if(files.length===0)
      return;
    let type = files[0].type;
    if(type.match(/image\/*/) == null){
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.image = reader.result;
      this.attachment.emit(this.image);
    }
  }

  uploadImage(){
    let image = this.croppedImage.split(',');
    this.uploading = true;
    this.waiting.emit(true);

    if(this.croppedImage !=="" && !this.onlyCropp){
      this.changeImageService.uploadImage(this.customerId,this.utilService.currentUser.id,image[1],'png').subscribe(response=>{
        this.changed.emit(response);
        this.uploading = false;
        this.waiting.emit(false);
      })
    }else{
      let response = {
        'data': image[1],
        'format' : image[0],
        'user_id' : this.utilService.currentUser.id,
        'extension' : 'png'
      }
      this.changed.emit(response);
      this.uploading = false;
      this.waiting.emit(false);
    }

  }


}
