import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export interface WidgetCardConversion{
  label: string;
  count: number;
  rate: number;
  translatePrefix: string;
  tooltip: string;
}
@Component({
  selector: 'widget-card-conversion',
  templateUrl: './card-conversion.component.html',
  styleUrls: ['./card-conversion.component.scss']
})
export class CardConversionComponent implements OnInit {

  modal:NgbModalRef;

  @Input()
  conversions: WidgetCardConversion[] = [];  //ej ['rates','orders','sents'];

  @Input()
  totalSents    = 1;

  @Input()
  tooltipRate;

  @Input()
  waiting = true;

  @Input()
  labelRate = 'followup.summary.summary-survey.title_box.rate';

  modalTitle:string;
  ratePercent;

  @Output()
  clickRate: EventEmitter<WidgetCardConversion> = new EventEmitter();


  constructor(private modalService: NgbModal) { }


  ngOnInit(){

    
  }
  ngOnChanges() {
    
  }

  openSentDialog(content){
    const sents: any = this.conversions.find(x=>x.label==='sents');
    this.openDialog(content,sents);
  }

  openDialog(content,rate:WidgetCardConversion){
    this.modalTitle = rate.translatePrefix+'.rates.modal_'+rate.label;
    this.clickRate.emit(rate);
    this.modal = this.modalService.open(content, {
      size: 'lg',
      windowClass: ''
    })
  }

  closeDialog(){
    if(this.modal) this.modal.close();
  }

}
