<div class="cover-mask tw-h-full tw-w-full">
  <div class="section-inactive d-flex justify-content-center"
    [ngClass]="sectionStyle"
    (mouseover)="show = true"
    (mouseleave)="show = false"
    (click)="globalLink ? navigateToGlobalLink() : null"
    *ngIf="!isActive">
    <div class="section-inactive-container" [ngClass]="sectionContainerStyle" *ngIf="show" @fade>
      <p class="mb-2" *ngIf="!innerHtmlText">{{(translationTextTooltip | translate)}}</p>
      <p class="mb-2" [innerHtml]="translationTextTooltip | translate: translationParams" *ngIf="innerHtmlText"></p>
      <a [href]="upsellLink" *ngIf="!withoutLink" target="_blank"><strong>{{translationTextLink | translate}}</strong></a>
    </div>
  </div>
  <div class="tw-h-full" [style.filter]="!isActive ? ('blur(5px)' | safeStyle) : null">
    <ng-content></ng-content>
  </div>
</div>
