import { Component, OnInit } from '@angular/core';
import { DateService } from 'app/shared/services/date.service';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit {

  public waiting: boolean = false;
  public state: any = null;

  constructor(
    private utilService: UtilService,
    private dateService: DateService) { }

  ngOnInit() {}

  get currentDates() {
    return this.dateService.currentDates;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }

  get currentHotelId() {
    return this.utilService.currentHotel.id;
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates);
  }

  handleStateFilter(event) {
    this.state = [...event];
  }

}
