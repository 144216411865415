import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OnlinePropertiesService {
  constructor(private http: HttpClient) {}

  public getSummary(dates: any, customerIds: any, productId, languageId) {
    const url = `${environment.onlinePath}interchains/main/summary`;
    const params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customer_ids', customerIds)
      .set('product_id', productId)
      .set('language_id', languageId);

    return this.http.get(url, { params });
  }

  public getSemanticIndex(dates: any, customerIds: string, productId: number) {
    const url = `${environment.customerPath}customers/semantic/index`;
    const params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customers_ids', customerIds)
      .set('product_id', productId)
      .set('previous_period', true);
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }
}
