import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EditProductService } from "../../edit-product.service";
import { UtilService } from "../../../../shared/util.service";
import { UserProfile,CustomerProfile } from "../../../../shared/model/index";

export class Summary{
  id:string;
  customer:CustomerProfile;
  user:UserProfile;
  state:boolean=false;
  existInDB:boolean=false; //dice si la alerta existe o no en BD
  constructor(customer, user, state, existInDB){
    this.customer = customer;
    this.user = user;
    this.state = state;
    this.existInDB = existInDB;
    this.id = this.customer.id+'_'+this.user.id;
  }
}

@Component({
  selector: 'mh-edit-user-summaries',
  templateUrl: './edit-user-summaries.component.html',
  styleUrls: ['../../../../../assets/scss/configuration/user-summaries.scss'],
  providers:[EditProductService]
})
export class EditUserSummaries{

  @Input()
  customer:CustomerProfile;

  @Input()
  user:UserProfile;

  @Output() waitingState = new EventEmitter<boolean>();

  waiting:boolean = true;

  summary:Summary;

  prefixTranslate='configuration.users.modal.summaries.';

  modalTitle:string;



  constructor(private utilService:UtilService, private editProductService:EditProductService){}

  ngOnChanges() {
    this.getSummaries()
    this.modalTitle = this.utilService.currentUser.id === this.user.id  ? this.prefixTranslate+'title_self' : this.prefixTranslate+'title';
  }

  getSummaries(){
    this.waiting = true;
    this.waitingState.emit(this.waiting);
    this.editProductService.getSummaries(this.customer.id, this.user.id).subscribe((response:any)=>{
      this.summary = new Summary(this.customer,this.user, (response.length>0),(response.length>0));
      this.waiting = false;
      this.waitingState.emit(this.waiting);
    })
  }

  setSummary($slide){
    this.summary.state = $slide.checked
  }

  saveChanges(){
    let action = this.summary.state ? "saveSummary" : "deleteSummary";

    this.waitingState.emit(true);
    this.editProductService[action](this.customer.id, this.user.id).subscribe(response=>{
      this.waitingState.emit(false);
    })
  }



}
