<div id="alert_modal">
  <h2>Configurar alertas para {{ota.name}}</h2>

  <div class="box_survey">
    <ul class="list_suvey_alerts">
      <li class="survey_alert" *ngFor="let alert of allAlerts">
        <div class="alert_added type_{{alert}}">
          <mat-slide-toggle
            [color]="color"
            [checked]="hasOnlinceOtaActiveAlert(alert)"
            [disabled]="disabled">
            {{alert.description}}
        </mat-slide-toggle>
        </div>
      </li>
    </ul>
  </div>

</div>