import { Injectable }               from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot }
                                     from '@angular/router';
import { JwtHelperService }          from '@auth0/angular-jwt';
import { PSGeneralConfigurationService } from 'app/prestay/configuration/general/prestay-general-configuration.service';
import { PrestayUtilService }        from '../layouts/themes/prestay/prestay-util.service';
import { UtilService }         from '../shared/util.service';
@Injectable()
export class AuthPrestayGuard implements CanActivate {

  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private router: Router,
    private utilService: UtilService,
    private prestayUtilService: PrestayUtilService,
    private generalConfigurationService: PSGeneralConfigurationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if(this.isLogged()) {
      this.checkViewItem(state);
      return true;
    }

    this.logout();
    let path = this.prestayPath('login')
    path = this.verifyUrl(path);
    this.router.navigate([path]);
    return false;
  }

  canLoad() {
    this.generalConfigurationService.getProperties(this.currentHotelId).subscribe();
    return true;
  }

  get currentHotelId() {
    return this.utilService.getCurrentHotel() ? this.utilService.getCurrentHotel().id : null
  }

  isLogged():boolean{
    const currentJWT: any = localStorage.getItem('currentJWT');
    let isLogged = false;
    let admin    = JSON.parse(currentJWT);

    if(admin){
      let hotel = this.utilService.getCurrentHotel();
      let user  = this.utilService.getCurrentUser();
      this.prestayUtilService.loginByAdmin(hotel,user);;
    }
    let jwt = this.prestayUtilService.getGuestToken();
    if(jwt && jwt!=='empty'){
      isLogged = !this.jwtHelper.isTokenExpired(jwt)
    }else{
      this.logout();
      isLogged = false;
    }
    return isLogged;
  }


  logout() {
    this.prestayUtilService.logout()
  }

  checkViewItem(state: RouterStateSnapshot){
    const config = this.prestayUtilService.getCurrentHotelConfig();
    const url    = state.url;
    if(config){
      if(config.checkin_active && !config.service_active && url.includes('services')) this.router.navigate([this.prestayPath('precheckin/index')]);
      if(!config.checkin_active && config.service_active && url.includes('precheckin')) this.router.navigate([this.prestayPath('services/index')]);
      if(!config.checkin_active && !config.service_active) {
        this.logout();
        this.router.navigate([this.prestayPath('404')])
      };
    }
  }

  prestayPath(url){
    return this.prestayUtilService.prestayPath(url);
  }

  verifyUrl(path) {
    const startIndexPath = this.getIndex(path, '/', 1) + 1;
    const endIndexPath = this.getIndex(path, '/', 2);
    const pathNickId = path.substring(startIndexPath, endIndexPath);

    if(pathNickId === 'undefined') {
      const url = window.location.href;
      const startIndexUrl = this.getIndex(url, '/', 3) + 1 ;
      const endIndexUrl = this.getIndex(url, '/', 4);
      const nickId = url.substring(startIndexUrl, endIndexUrl);

      this.prestayUtilService.setNickId(nickId);
      path = this.prestayPath('login');
    }
    return path;
  }

  getIndex(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

}
