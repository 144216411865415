/**
 * Created by brayan on 10-07-17.
 */
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'search' })
export class SearchPipe implements PipeTransform {
  transform(setData: any, searchText: any): any {
    if(searchText == null) return setData;
    return setData.filter(function(data){
      return String(data.lowestPrices[0].roomPrice).toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        String(data.lowestPrices[1].roomPrice).toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        String(data.lowestPrices[2].roomPrice).toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        String(data.lowestPrices[3].roomPrice).toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    })
  }
}
