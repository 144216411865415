import { OTAS_DATA } from "app/shared/utils/statics/otas-urls.static";
import { Language } from "../language.model";

export class Ota{
  active: boolean;
  oauth: boolean;
  id: number;
  logo: string;
  name: string;
  param: string;
  get_reviews: boolean;
  url_home: string
  search_q: string
}


export class OtasErrors {
  customer_id: number
  error: OtaError
  last_update: string
  ota_id: number
  since: string
  ota_name: string

  static createFromListResponse(resp: OtasErrorsRes[]) {
    return resp.map(data => {
      const ota = OTAS_DATA.find(o => o.id === data.ota_id)
      return {
        ...data,
        ota_name: ota ? ota.name : 'XXX'
      }
    })
  }

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export interface OtaError {
  code: number
  public_error: boolean
  translates: {
    language: Language
    text: string
  }[]
}

export class OtasErrorsRes {
  customer_id: number
  error: OtaError
  last_update: string
  ota_id: number
  since: string
}
