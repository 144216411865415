import { Component, Input } from '@angular/core';

@Component({
  selector: 'mh-excerpt',
  templateUrl: './excerpt.component.html',
  styleUrls: ['../../../../../assets/scss/shared/elements/excerpt.scss'],
})
export class ExcerptComponent{

  @Input()
  text='';

  cut=100;
  opened=false;
  excerpt:string;
  has_excerpt: any = false;

  constructor(){}

  ngOnInit(){
    this.setExcerpt();
  }

  setExcerpt(){
    this.has_excerpt = this.text && (this.text.length > this.cut && (this.text.length - this.cut) > 80);
    this.excerpt     = this. has_excerpt ? this.text.substring(0,this.cut)+'...' : this.text;
    this.opened      = !this.has_excerpt;
  }

  toggleState(){
    this.opened = !this.opened;
  }
}
