<div class="box_score {{type}} {{style}}">
  <div class="score {{type_number}}" >
    <div class="value" [innerHTML]="(value | validateShowData)"></div>
    <div class="sub_value" *ngIf="subValue" [innerHTML]="subValue"></div>
  </div>


  <div class="last_time">
    <div class="percent" *ngIf="previous_value_percent!=''" [innerHTML]="previous_value_percent"></div>
    <div class="previous_value" *ngIf="previous_value!=''" [innerHTML]="previous_value"></div>
    <div class="previous">{{previous}}</div>
  </div>
</div>
