import { Component, OnInit, Inject } from '@angular/core';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { CoreLibModalComponent } from '../modal/modal.component';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'mh-core-lib-sender-variables-modal',
  templateUrl: './sender-variables-modal.component.html',
  styleUrls: ['./sender-variables-modal.component.scss']
})
export class CoreLibSenderVariablesModalComponent extends CoreLibModalComponent implements OnInit {

  variables = [
    'hotel_name',
    'hotel_logo_url',
    'hotel_default_checkin',
    'hotel_default_checkout',
    'guest_name',
    'guest_last_name',
    'guest_phone',
    'guest_room',
    'guest_country',
    'guest_reservation_id',
    'guest_checkin',
    'guest_checkout',
    'guest_language'
  ]

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private clipboard: Clipboard
  ) {
    super(dialogRef, data)
  }

  ngOnInit(): void {
  }

  handleClose() {
    this.close();
  }

  copyToClipboard(variable) {
    this.clipboard.copy(`<var>${variable}</var>`)
  }

}
