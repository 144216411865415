<div class="number_period {{type}}" [class.waiting]="waiting" [class.no_data]="noData">

  <ng-container *ngTemplateOutlet="titlePeriod"></ng-container>

  <div class="row align-items-center indicator">
    <div class="col-12 content_current">
      <div class="total">
        <ng-container [ngSwitch]="numberType">
          <ng-template [ngSwitchCase]="['currency'].includes(numberType) ? numberType : false">
            <ng-container *ngTemplateOutlet="currencyNumber;context:{number: period?.actual}"></ng-container>
          </ng-template>
          <ng-template ngSwitchDefault>
            {{period?.actual}}
          </ng-template>
        </ng-container>
      </div>


    </div>
    <div class="col-12 content_old">
      <ng-container *ngIf="(type==='indicator')">
        <ng-container *ngTemplateOutlet="contentOld"></ng-container>
      </ng-container>
      <div class="info_no_data" *ngIf="noData">
        {{'inter-chain.summary.no_data' | translate}}
      </div>
    </div>
  </div>
</div>



<ng-template #contentOld>
  <div class="variation">
    <span>{{period?.variation}}%</span>
    <span *ngIf="type==='indicator'" ><widget-utils-arrow-variation [number]="period?.variation" [height]="25"></widget-utils-arrow-variation></span>
  </div>
  <div class="previous ellipsis">
    <ng-container [ngSwitch]="numberType">
      <ng-template [ngSwitchCase]="['currency'].includes(numberType) ? numberType : false">
        <ng-container *ngTemplateOutlet="currencyPrevious;context:{number: period?.previous}"></ng-container>
      </ng-template>
      <ng-template ngSwitchDefault>
        {{ 'inter-chain.summary.table.previous_value' | translate:({score: period?.previous }) }}
      </ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-template #currencyNumber let-number="number">
  {{number | currency:currencyCode:currencySymbol}}
</ng-template>

<ng-template #currencyPrevious let-number="number">
  {{ 'inter-chain.summary.table.previous_value' | translate:({score: (number | currency:currencyCode:currencySymbol)} ) }}
</ng-template>


<ng-template #titlePeriod>
  <div class="number_title">{{title}}<i *ngIf="tooltip" container="body" class="description-tooltip fas fa-question-circle" [ngbTooltip]="tooltipHtml"></i></div>
</ng-template>

<ng-template #tooltipHtml>
  <div [innerHtml]="tooltip"></div>
  <div class="period_indicator" *ngIf="(type!=='indicator')" >
    <widget-period-indicator
      [variation]="period?.variation"
      [currentValue]="period?.actual"
      [previousValue]="period?.previous"
      [variationFormat]="(period?.variation | formatPercent )"
      [currentFormat]="(['currency'].includes(numberType) ? (period?.actual | currency:currencyCode:currencySymbol) : period?.actual )"
      [previousFormat]="(['currency'].includes(numberType) ? (period?.previous | currency:currencyCode:currencySymbol) : period?.previous)"
      [inTooltip]="true"
    ></widget-period-indicator>
  </div>
</ng-template>
