import { Component, HostListener, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { FollowupOnsiteState } from 'app/states/followup-onsite/followup-onsite.state';
import { Observable } from 'rxjs';
import { Survey } from "app/shared/model/index";
import { Select } from '@ngxs/store';
import { GuestSelectorService } from 'app/core-lib/services/guest-selector/guest-selector.service'

@Component({
  selector: 'mh-core-lib-guest-selector',
  templateUrl: './guest-selector.component.html',
  styleUrls: ['./guest-selector.component.scss']
})
export class CoreLibGuestSelectorComponent implements OnChanges {

  @Select(FollowupOnsiteState.guestsSorted) guestsSorted$: Observable<Survey[]>
  guests = [];

  isOpen = false;

  @Input()
  externalOptions;

  @Input()
  hasOptions = true;

  @Input()
  isAirline = false;

  @Input()
  isRestaurant = false;
  
  @Output() writtenText: EventEmitter<string> = new EventEmitter();

  @HostListener('document:click', ['$event'])
  onClick(event){
    const result = this.checkClickInside(event.target);
    if ( !result ) {
      this.isOpen = false;
    }
  }

  autocompleteFilterFn = (options, value) => {
    return options.filter((option: any) => {
      return option.answer.id.toString().includes(value.toLowerCase()) ||
        option.guest.name.toLowerCase().includes(value.toLowerCase()) ||
        `${option.guest.name.toLowerCase()} ${option.guest.lastName.toLowerCase()}`.includes(value.toLowerCase())
    });
  }

  displayUsersOptions = (option) => {
    return option.name;
  };

  constructor(
    private guestSelectorService: GuestSelectorService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.externalOptions) {
      this.guests = changes.externalOptions.currentValue;
    }
  }


  checkClickInside(target) {
    if ( target.id === 'overlaySelectorGuest' || target.id === 'selectorGuest' || target.className?.includes('autocomplete-option')) {
      return true;
    } else if ( target.parentNode ) {
      return this.checkClickInside(target.parentNode)
    } else {
      return false;
    }
  }

}
