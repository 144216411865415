<label>
  <ng-container *ngIf="inner; else textPlain">
    <div [innerHtml]="label"></div>
  </ng-container>
  <div class="tw-relative">
    <input type="checkbox" class="tw-sr-only" [disabled]="isDisabled" [checked]="value" (click)="onClickEmit()" />
    <div
      class="tw-block {{ sizeToggle[size].container }} tw-rounded-full tw-border tw-border-gray-300 {{ color }}"
      [ngClass]="{ '!tw-bg-gray-200': isDisabled }"
    ></div>
    <div
      class="tw-absolute tw-border tw-border-gray-200 tw-top-[2px] tw-left-[2px] tw-bg-white {{ sizeToggle[size].toggle }} tw-rounded-full tw-transition"
      [ngClass]="{ 'tw-translate-x-3/4': value }"
    ></div>
  </div>
</label>

<ng-template #textPlain>
  {{ label }}
</ng-template>
