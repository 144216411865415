import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mh-core-lib-video-image-slider',
  templateUrl: './video-image-slider.component.html',
  styleUrls: ['./video-image-slider.component.scss'],
})
export class CoreLibVideoImageSliderComponent<T> {
  @Input() items: T[] = [];
  @Input() currentSlide = 0;
  @Output() updateCurrentSlide = new EventEmitter<number>();
  @Output() resetTimer = new EventEmitter<void>();

  handlePreviousClick() {
    if (this.firstItemOnCarousel) return;
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.items.length - 1 : previous;
    this.updateCurrentSlide.emit(this.currentSlide);
    this.resetTimer.emit();
  }

  handleNextClick() {
    if (this.lasItemOnCarousel) return;
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.items.length ? 0 : next;
    this.updateCurrentSlide.emit(this.currentSlide);
    this.resetTimer.emit();
  }

  get firstItemOnCarousel() {
    return this.currentSlide === 0;
  }

  get lasItemOnCarousel() {
    return this.currentSlide === this.items.length - 1;
  }
}
