<div class="row box_period no-gutters align-items-center {{style}}" [class.in-tooltip]="inTooltip" [class.invisible]="invisible">
  <div class="col-6">
    <div class="current_value" >
      <div class="value">
        <span [ngbTooltip]="tooltip ? tooltipTemplate : null" container="body" [innerHtml]="(currentFormat ? currentFormat : currentValue)"></span>
      </div>
      <div class="arrow-point">
        <widget-utils-arrow-variation
          [number]="variation"
          [height]="(inTooltip ? 20 : 27)"
          [format]="format"
        ></widget-utils-arrow-variation>
      </div>
    </div>

  </div>
  <div class="col-6">
    <div class="previous_value">
      <div class="variation">{{ variationFormat ? variationFormat : variation}}</div>
      <div class="value">{{ 'inter-chain.summary.table.previous_value' | translate:({score: (previousFormat ? previousFormat : previousValue)}) }}</div>
    </div>
  </div>
</div>

<ng-template #tooltipTemplate>
  <div>{{tooltip}}</div>
</ng-template>
