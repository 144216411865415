<div class="customer_detail" [class.current]="isCurrent()">
  <div class="customer" mat-button [matMenuTriggerFor]="menu">
    <span class="name">{{customer?.name}} <i class="fal fa-chevron-down"></i></span>
  </div>
  <mat-menu #menu="matMenu">
    <ng-container  *ngFor="let item of options">
      <ng-container  *ngFor="let value of item.values">
        <a *ngIf="(item.type=='product')" class="option {{item.type}}" (click)="setCurrentCustomer(value)" [hidden]="(hideOption(item.type,value))" mat-menu-item [routerLink]="[getLink(item.type,value)]">
          <span [innerHtml]="('shared.elements.customers.detail.see_profile' | translate:({product:('navbar.product.'+navarProduct+'.'+value | translate )}))"></span>
        </a>
        <a  *ngIf="(item.type=='otas')" [href]="getLink(item.type,value)" class="option {{item.type}}" [hidden]="(hideOption(item.type,value))" target="_blank" mat-menu-item>
          <span [innerHtml]="('shared.elements.customers.detail.see_profile' | translate:({product:( 'shared.filters.names.otas.'+value.id_ota | translate )}))"></span>
        </a>
      </ng-container>
    </ng-container>
  </mat-menu>
</div>
