import { InputField } from '../form/input.model';
import { plainToClass } from "class-transformer";
export class Precheckin{
  id:number;
  customer_id:number;
  inputs:InputField[] = [];
  step_1:InputField[] = [];
  step_2:InputField[] = [];
  step_3:InputField[] = [];

  createFromApi(response){
    this.id          = response.id;
    this.customer_id = response.customer_id;
    this.inputs      = plainToClass(InputField, response.inputs);
    return this;
  }
  constructor(customer_id){
    this.customer_id = customer_id;
  }
}
