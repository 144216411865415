import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fidelityDeskProduct'
})
export class FidelityDeskTypePipe implements PipeTransform {

  transform(cases, filters) {
    let filteredCases = [ ...cases ];
    if(filters) {
      const filter = filters.find(filter => filter.name === 'type');
      const filtering = filter.options.some(option => option.isFiltering);
      if(filter && filtering) {
        const options = filter.options.filter(option => option.isFiltering).map(option => option.value?.toString() || 'null');
        filteredCases = cases.filter(item => {
          const noType = (!item.case_type) ? 'null' : '';
          const value = item.case_type?.id.toString() || noType;
          return options.includes(value);
        })
      }
    }
    return filteredCases;
  }

}
