import { Component, OnInit, Input } from '@angular/core';
import { Guest, Survey } from 'app/shared/model';
import { SurveyAnswerService } from 'app/shared/surveys/survey-answer.service';
import { UtilService } from 'app/shared/util.service';
import { AddSemantic } from 'app/shared/model/extends/semantic.extend';

export interface SemanticInfo{
  semantic_rating: string;
  semantic_areas: any[];
}

export class CommentGuest extends AddSemantic{
  id: number;
  text: string;
  alternative_id: number;
  semanticRating: any = null;
  semanticAreas:any = [];

  constructor(id: number, text: string, alternative_id: any =null){
    super();
    this.id = id;
    this.text = text;
    this.alternative_id = alternative_id;
  }
}

@Component({
  selector: 'widget-card-guest-comment',
  templateUrl: './card-guest-comment.component.html',
  styleUrls: ['./card-guest-comment.component.scss'],
  providers: [SurveyAnswerService]
})
export class CardGuestCommentComponent implements OnInit {

  @Input()
  guest: Guest;

  @Input()
  comment: any;

  @Input()
  survey:any[] = [];

  @Input()
  semanticInfo: SemanticInfo;

  @Input()
  productId;

  @Input()
  satisfaction: any;

  @Input()
  isDateType = false;


  surveyGuest: Survey;
  commentGuest: CommentGuest;

  waitTranslate = false;
  textTranslate: any = null;

  constructor(
    private utilService: UtilService,
    private surveyAnswerService:SurveyAnswerService
  ) { }

  ngOnInit() {
    this.commentGuest = new CommentGuest(this.comment.id, this.comment.text, this.comment.alternative_id);
    if(this.semanticInfo){
      this.commentGuest.semanticRating = this.semanticInfo.semantic_rating;
      this.commentGuest.semanticAreas = this.semanticInfo.semantic_areas;
      this.commentGuest.getThemes();
    }

    this.setSurveyGuest();
  }

  setSurveyGuest(){
    this.survey.forEach(element=>{
      element.surveys.forEach(survey=>{
        if(survey.guest.id === this.guest.id){
          this.surveyGuest = survey;
          this.surveyGuest['satisfaction'] = this.satisfaction;
        }
      })
    })
  }

  get surveyLanguage(){
    return this.surveyGuest  && this.surveyGuest && this.surveyGuest.guest.language && this.surveyGuest.guest.language ? this.surveyGuest.guest.language["id"] : 0;
  }

  get currentLanguage(){
    return this.utilService.getLanguageId();
  }

  get showSemantic(){
    return this.commentGuest.hasSentimentInside();
  }

  get isRetail(){
    return this.utilService.customerIsRetail
  }

  get canTranslateComment(){
    return this.textTranslate === null && this.currentLanguage!== this.surveyLanguage;
  }

  get currentCodeLanguage(){
    return this.utilService.getCurrentLanguage();
  }

  translate(comment){
    this.waitTranslate   = true;
    this.surveyAnswerService.translateComment(comment.id, this.surveyLanguage ,this.currentLanguage,this.productId, '', comment.text).subscribe((response:any)=>{
      this.textTranslate = response.description
      this.waitTranslate = false;
    })
  }

}
