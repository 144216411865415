import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'mh-core-lib-cases-alerts',
  templateUrl: './cases-alerts.component.html',
  styleUrls: ['./cases-alerts.component.scss'],
})
export class CoreLibCasesAlertsComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<CoreLibCasesAlertsComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data,
  ) {}

  handleShowCase() {
    window.open(this.data.url, '_blank');
  }

  handleCopyURL() {
    navigator.clipboard.writeText(this.data.url);
  }
}
