import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Case, UserMention } from 'app/shared/cases/types';
import { CasesService } from 'app/shared/cases/services/cases.service';
import { UtilService } from 'app/layouts/main';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, startWith } from 'rxjs/operators';
import { CustomValidators } from 'app/shared/custom-validators';
import { Subscription } from 'rxjs';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {MentionConfig} from 'angular-social-mentions';
import {SurveyService} from 'app/features/surveys/surveys.service';
import {MatChipInputEvent} from '@angular/material/chips';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { User, Hotel } from 'app/shared/model';
import { ConfigCasesService } from 'app/cases/services/config-cases.service';
import { Store } from '@ngxs/store';
import { UsersState } from 'app/states/users/users.state';

@Component({
  selector: 'mh-new-case-form',
  templateUrl: './new-case-form.component.html',
  styleUrls: ['./new-case-form.component.scss']
})
export class NewCaseFormComponent implements OnInit, OnDestroy {
  private OPEN_STATE = {id: 1};

  @Input() users: any[];
  @Input() productItem: any;
  @Input() survey: any;

  @Output() close: EventEmitter<null> = new EventEmitter();
  @Output() onCreate: EventEmitter<Case> = new EventEmitter();

  caseForm: FormGroup;
  savingCase: boolean = false;

  userOptions: any[] = [];
  stateOptions: any[] = [];
  priorityOptions: any[] = [];
  caseTypesOptions: any[] = [];

  assignedSubs: Subscription;
  filteredOptions: any[];
  disableAssignedInput = false;

  mentionConfig: MentionConfig = {};
  mentionState = [];
  areas = [];
  filteredAreaOptions: any;
  areasControl: FormControl = new FormControl('', [Validators.required]);
  areasChips = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectable = true;
  removable = true;
  areaFieldInvalid = false;
  modal: NgbModalRef;

  @ViewChild('areaInput', {read: MatAutocompleteTrigger}) areaInput: MatAutocompleteTrigger;
  @ViewChild('areasField') areaField: ElementRef;
  @ViewChild('limitCasesModal') limitCasesModal: any;

  user: User;
  hotel: Hotel;
  monthlyLimit: number;

  isSuperAdmin: boolean = false;
  isAdmin: boolean = false;

  readonly UPSELL_LINK = {
    'es': 'https://bit.ly/35og5e4',
    'pt': 'https://bit.ly/3sdopqh',
    'en': 'https://bit.ly/3h6pgCO',
  }

  readonly UPSELL_LINK_ADMIN = {
    'es': 'https://bit.ly/3h87izJ',
    'pt': 'https://bit.ly/3scXF9h',
    'en': 'https://bit.ly/3IhkBtw',
  };

  constructor(
    private casesService: CasesService,
    private utilService: UtilService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private surveyService: SurveyService,
    private modalService: NgbModal,
    private configCasesService: ConfigCasesService,
    private store: Store
  ) { }

  ngOnInit() {
    this.setUsers();
    this.initForm();
    this.loadOptions();
    this.ga('new-form', 'new-form');

    this.createItemsForMentions();
    this.getAreas();
    const customerId = this.utilService.getCurrentCustomer().id;
    this.user = this.utilService.getCurrentUser();
    this.hotel = this.utilService.getCurrentHotel();

    this.isSuperAdmin = this.user.isSuperAdmin();
    this.isAdmin = this.user.admin;

    this.configCasesService.getCasesLimit(customerId).subscribe((result) => {
      if (result) {
        this.monthlyLimit = result?.monthly_limit || 0;
      }
    })
  }

  ngAfterViewChecked() {
    this.assignedSubs = this.caseForm.get('assigned').valueChanges
      .pipe(
        filter(value => typeof value === 'string'),
        map(value => this._filter(value))
      )
      .subscribe(data => {
        this.filteredOptions = data;
      });
  }

  setUsers() {
    this.users = this.store.selectSnapshot(UsersState.currentUsers);
  }

  initForm() {
    this.caseForm = this.fb.group({
      title: ["", [Validators.required, Validators.maxLength(500)]],
      description: ["", [Validators.required, Validators.maxLength(3000)]],
      state: [this.OPEN_STATE, [Validators.required]],
      priority: ['', [Validators.required]],
      case_type: ['', [Validators.required]],
      author: [this.utilService.currentUser],
      assigned: [null, [Validators.required, CustomValidators.isObject]],
      related_areas: [null, [Validators.required]]
    });
  }

  async getAreas() {
    this.areas = await this.translate.get('areas').toPromise();
    const { areas, areas_translates }: any = await this.surveyService.getBase(this.utilService.getCurrentCustomer().id).toPromise()
    if (areas) {
      const mappedAreas = this.mapAreas(areas_translates, areas);
      this.areas = mappedAreas;
      this.initAreasFilter();
    }
  }
  
  mapAreas(areas_translates, areas) {
    return areas_translates.reduce((prev, curr) => {
      const considerArea = areas.find(area => area.id === curr.area_id);
      if (!considerArea) {
        return [
          ...prev
        ]
      }
      const existArea = prev.findIndex(area => area.id === curr.area_id);
      const key = (curr && curr.language.code === 'es') ?
        'clasification_text' :
        (curr && curr.language.code === 'en') ? 
        'clasification_text_en' :
        'clasification_text_pt';
      if ( existArea >= 0 ) {
        prev[existArea][key] = curr.name;
        return [
          ...prev
        ]

      } else {
        return [
          ...prev,
          {
            id: curr.area_id,
            [key]: curr.name
          }
        ];
      }
    }, []);
  }

  initAreasFilter() {
    this.filteredAreaOptions = this.areasControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.areasFilter(value))
      )
  }

  areasFilter(value: string | any): string[] {
    const availableAreas = this.areas.map(area => {
      const chip = this.areasChips.find(chip => chip.id === area.id);
      if ( !chip ) return area
    }).filter(area => area !== undefined)
    if (value) {
      const filterValue = (typeof(value) === 'string') ?
        value.toLowerCase() :
        (this.language === 'es') ? value.clasification_text : value.clasification_text_en;
      return availableAreas.filter(option => {
        if (this.language === 'es') {
          const { clasification_text } = option;
          return clasification_text.toLowerCase().includes(filterValue)
        }
        const { clasification_text_en } = option;
        return clasification_text_en.toLowerCase().includes(filterValue)
      });
    } else {
      return availableAreas.slice();
    }
  }

  selected({option}): void {
    this.markAreaAsInvalid();
    const inputValue = option.value;
    const value = this.areas.find(area => area.clasification_text === inputValue.clasification_text);
    if (value) {
      this.areasChips.push(value);
    }
    this.areasControl.setValue(null);
    setTimeout(() => this.areaInput.openPanel(), 200);
  }

  add(event: MatChipInputEvent): void {
    const inputValue = event.input;
    if (inputValue) inputValue.value = '';
    const value = this.areas.find(area => {
      return (area.clasification_text === inputValue)
        ? true
        : area.clasification_text_en === inputValue
    });

    if (value) {
      this.areasChips.push(value);
    }
    this.areasControl.setValue(null);
  }

  remove(area: any): void {
    const index = this.areasChips.indexOf(area);
    if (index >= 0) {
      this.areasChips.splice(index, 1);
    }
    this.handleRequiredAreas();
  }

  createItemsForMentions(): void {
    const usersToMention = this.users.map(({ id, first_name, last_name, email, company_position }) => ({
      email,
      id,
      mentionId: `@[${email}:${btoa(String(id))}]`,
      name: `${first_name} ${last_name} (${company_position})`,
    })
    );
    this.mentionConfig = {
      mentions: [
        {
          items: usersToMention,
          labelKey: 'name',
          triggerChar: '@',
          mentionSelect: this.formatMention,
          dropUp: true,
        },
      ]
    }
  }

  formatMention(item: UserMention): string {
    return `@[${item.email}:${btoa(String(item.id))}]`;
  }

  onInputText(event): void {
    const { value } = event.target;
    if (value && value.length > 0) {
      const controlText = this.caseForm.get('description');
      const matches = value.match(/\@\[(.*?)\]/g) || [];
      let newValue;
      matches.forEach((match: string) => {
        try {
          let match_hash = match.slice(2);
          match_hash = match_hash.slice(0, -1);
          const hashId = match_hash?.split(':') || '';
          const base64Id = +atob(hashId[1]);
          const hash = match;
          const user = this.users.find(user => user.id === base64Id);
          newValue = user && value.replace(hash, `@[${user.first_name} ${user.last_name}]`);
        } catch {
          const user = match;
          newValue = (!this.mentionState.find(mention => mention.key === user)) ?
            value.replace(user, '') :
            null;
        }
      });

      if (newValue !== null && newValue !== undefined) {
        controlText.setValue(newValue);
      }
    }
  }

  selectMention(mention): void {
    const value = mention.mentionId;
    const user = this.users.find((user) => user.id === mention.id);
    const key = `@[${user.first_name} ${user.last_name}]`;
    if (!this.mentionState.find((mention) => mention.key === key)) {
      this.mentionState = [...this.mentionState, { key, value }];
    }
  }

  loadOptions() {
    this.casesService.getBase()
      .subscribe(({priorities, states, case_types}) => {
        this.priorityOptions = priorities;
        this.stateOptions = states;
        this.caseTypesOptions = case_types;
        const usersCopy = [...this.users];
        this.userOptions = this.filteredOptions = usersCopy
          .sort((u1,u2) => (
            (u1.last_name + u1.first_name).toLowerCase() < (u2.last_name + u2.first_name).toLowerCase()) ? -1 : 1);

        this.initForm();
      })
  }

  areMissingAreas() {
    return this.areasChips.length === 0;
  }

  markAreaAsInvalid(isInvalid = false) {
    this.areaFieldInvalid = isInvalid;
    if (!isInvalid) {
      this.caseForm.get('related_areas').setErrors(null);
    }
  }

  handleRequiredAreas() {
    if (this.areMissingAreas()) {
      this.markAreaAsInvalid(true);
    } else {
      this.markAreaAsInvalid();
    }
  }

  save() {

    this.handleRequiredAreas();
    if(this.caseForm.invalid || this.savingCase || this.areMissingAreas()) {
      this.caseForm.markAllAsTouched();
      return;
    }

    const value = this.caseForm?.value?.description;
    const matches = value.match(/\@\[(.*?)\]/g) || [];
    matches.forEach((match) => {
      const mention = this.mentionState.find((mention) => mention.key === match);
      if (mention) {
        this.caseForm.value.description = this.caseForm?.value?.description.replace(match, mention.value);
      }
    });

    this.savingCase = true;
    this.casesService.save(this.getFormatCaseForSave(), this.utilService.currentHotel.id)
      .subscribe(response => {
        this.savingCase = false;
        this.showNotification('snackbar-panel-success', 'cases.messages.created');
        this.onCreate.emit(<Case>response)
        this.ga('new-form', 'save-form', response['id'])
      },
      (response) => {
        if (response.code === 4007) {
          this.limitCasesError();
        } else {
          this.savingCase = false;
          this.onCreate.emit(<Case>response)
          this.ga('new-form', 'save-form', response['id'])
          this.showNotification('snackbar-panel-error', 'cases.messages.created_error');
        }
      });
  }

  showNotification(panelClass, message) {
    const snackbarOptions = <MatSnackBarConfig>{
      duration: 6000,
      panelClass: panelClass,
      verticalPosition: 'top'
    };

    this.openSnackBar(snackbarOptions, message);
  }

  openSnackBar(snackbarOptions, message) {
    this.translate.get(message)
      .subscribe(text => {
        this.snackbar.open(text, 'x', snackbarOptions);
      });
  }

  limitCasesError() {
    this.modal = this.modalService.open(this.limitCasesModal, {
      size: 'md'
    })
  }

  getFormatCaseForSave() {
    return {
      ...this.caseForm.value,
      product_item: this.productItem,
      related_areas: this.areasChips.map(area => ({area_entity: {...area}}))
    }
  }

  ga(action:string, label?:string, value?:number) {
    const gaEventAction = `${action}-${this.currentPath}`
    this.utilService.ga('cases', gaEventAction, label, value);
  }
  get currentPath() {
    const possitblePaths: any[] = ['user', 'history', 'active'];
    const currentPath = this.activatedRoute.routeConfig?.path;

    if (possitblePaths.includes(currentPath)) return currentPath;

    return 'product';
  }

  get language() { return this.utilService.getCurrentLanguage(); }
  get currentUser() { return this.utilService.currentUser; }
  get requiredLengthTitleError() {
    return this.caseForm.get('title').errors?.maxlength?.requiredLength;
  }
  get currentLengthTitleError() {
    return this.caseForm.get('title').errors?.maxlength?.actualLength;
  }
  get requiredLengthDescriptionError() {
    return this.caseForm.get('description').errors?.maxlength?.requiredLength;
  }

  get currentLengthDescriptionError() {
    return this.caseForm.get('description').errors?.maxlength?.actualLength;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.userOptions
      .filter(option => `${option.first_name} ${option.last_name} ${option.company_position} ${option.email}`.toLowerCase().includes(filterValue));
  }

  getOptionText(option) {
    return option ? `${option.first_name} ${option.last_name} (${option.company_position})`: '';
  }

  resetAndEnableAssignedControl(): void {
    this.caseForm.get('assigned').reset();
    this.disableAssignedInput = false;
    this.filteredOptions = this.userOptions;
  }

  setAssignedControl(selection: MatAutocompleteSelectedEvent): void {
    this.caseForm.get('assigned').setValue(selection?.option?.value || '');
    this.disableAssignedInput = true;
  }

  checkAssigned() {
    if (this.caseForm.controls.assigned?.errors?.isObjectError) {
      this.resetAndEnableAssignedControl();
    }
  }


  get currentLanguage() { return this.utilService.currentLanguage; }

  get upsellLink() {
    return `${this.isSuperAdmin || this.isAdmin ? this.UPSELL_LINK_ADMIN[this.currentLanguage] : this.UPSELL_LINK[this.currentLanguage]}`;
  }

  ngOnDestroy() {
    this.assignedSubs.unsubscribe();
  }
}
