<div class="row">
  <div class="col content_filter">
    <ng-container *ngIf="isFromHeader else legacyFinder">
      <form class="showing_hotels_list">
        <mat-form-field>
          <input class="focus:tw-ring-0" type="text" [placeholder]="('commons.search' | translate )" matInput autofocus #inputAuto [formControl]="filterControl" [matAutocomplete]="auto">
          <div class="result-state">
            <mh-spinner-classic *ngIf="waitingOptions"></mh-spinner-classic>
            <div *ngIf="noResultsFlag" class="not-results">{{('shared.filters.customers.empty_message' | translate)}}</div>
            <div *ngIf="minCharsFlag" class="not-results">{{('shared.filters.customers.min_chars' | translate)}}</div>
            <div *ngIf="errorFlag" class="not-results link">
              <a (click)="fillListCorporative()">{{ 'errors.default' | translate }}</a>
            </div>
          </div>


          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updateCustomer($event.option.value)">
            <mat-option [value]="'all'" *ngIf="showAllOption">
              <div class="row align-items-center no-gutters">
                <div class="hotel_name">
                  <div class="ellipsis">{{ 'commons.select_all' | translate}}</div>
                </div>
              </div>
            </mat-option>

            <mat-optgroup *ngFor="let group of filteredGroups | async"
                       [label]="('countries.'+ (group.country_code || 'N.D.') | translate)"
                       >
                       <mat-option *ngFor="let hotel of group.customers" [value]="hotel" [attr.data-hotel-id]="hotel.id">
                         <div class="row align-items-center no-gutters">
                           <div class="hotel_name">
                             <div class="ellipsis">{{ hotel?.name }}<span> ({{hotel?.id}})</span></div>
                           </div>
                         </div>
                       </mat-option>
            </mat-optgroup>

          </mat-autocomplete>
        </mat-form-field>
      </form>
    </ng-container>
  </div>
</div>

<ng-template #legacyFinder>
  <div ngbDropdown #myDrop="ngbDropdown" class="d-inline-block" *ngIf="show_toggle" [autoClose]="true">
    <button type="button" [class.button-toggled]="opened" class="btn btn-outline-primary btn-open" [id]="filterId" ngbDropdownToggle (click)="toggleSearch(myDrop)" NgbDropdownToggle></button>
    <div ngbDropdownMenu aria-labelledby="hotelsList">
      <form class="showing_hotels_list">
        <mat-form-field>
          <input class="focus:tw-ring-0" type="text" [placeholder]="('commons.search' | translate )" matInput autofocus #inputAuto [formControl]="filterControl" [matAutocomplete]="auto">
          <div class="result-state">
            <mh-spinner-classic *ngIf="waitingOptions"></mh-spinner-classic>
            <div *ngIf="noResultsFlag" class="not-results">{{('shared.filters.customers.empty_message' | translate)}}</div>
            <div *ngIf="minCharsFlag" class="not-results">{{('shared.filters.customers.min_chars' | translate)}}</div>
            <div *ngIf="errorFlag" class="not-results link">
              <a (click)="fillListCorporative()">{{ 'errors.default' | translate }}</a>
            </div>
          </div>


          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updateCustomer($event.option.value)">
            <mat-option [value]="'all'" *ngIf="showAllOption">
              <div class="row align-items-center no-gutters">
                <div class="hotel_name">
                  <div class="ellipsis">{{ 'commons.select_all' | translate}}</div>
                </div>
              </div>
            </mat-option>

            <mat-optgroup *ngFor="let group of filteredGroups | async"
                       [label]="('countries.'+ (group.country_code || 'N.D.') | translate)"
                       >
                       <mat-option *ngFor="let hotel of group.customers" [value]="hotel" [attr.data-hotel-id]="hotel.id">
                         <div class="row align-items-center no-gutters">
                           <div class="hotel_name">
                             <div class="ellipsis">{{ hotel?.name }}<span> ({{hotel?.id}})</span></div>
                           </div>
                         </div>
                       </mat-option>
            </mat-optgroup>

          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
  </div>
</ng-template>
