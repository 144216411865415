<div class="banner_feedback">
  <span class="text" (click)="openModal(banner_feedback)">
    {{'banners.new_plataform.title' | translate }}
  </span>
</div>

<ng-template #banner_feedback let-c="close" let-d="dismiss">
  <h2 class='title' [innerHtml]="('banners.new_plataform.modal.title' | translate)"></h2>
  <div class="content_banner_feedback">
    <div class="new_opinion" [hidden]="send_feedback || send_opinion">
      <form class="example-form" *ngIf="show_form">
        <h3>{{'banners.new_plataform.modal.question' | translate }}</h3>
        <mat-form-field class="full_width">
          <textarea matInput placeholder="{{'banners.new_plataform.modal.write_here' | translate}}" name="message_feedback" id="message_feedback" [(ngModel)]="message_feedback"></textarea>
        </mat-form-field>
        <h3>{{'banners.new_plataform.modal.question_star' | translate }}</h3>
        <ul class="stars">
          <li class="star" *ngFor="let item of [1,2,3,4,5]">
            <i class="fas fa-star" (mouseenter)="isHover(item)" (mouseleave)="setHover()" (click)="emitVote(item)" [class.on]="showOnStar(item)"></i>
          </li>
        </ul>
        <div class="send_button">
          <button class="btn btn-orange" (click)="sendFeedback()">{{'banners.new_plataform.modal.send_feedback' | translate}}</button>
        </div>
      </form>
    </div>

    <div class="thanks" [hidden]="!send_opinion">
      <h4>{{('banners.new_plataform.modal.thank_feedback' | translate:{name: currentUser.name} )}}</h4>
      <span class="send_new" (click)="sendNew()">{{'banners.new_plataform.modal.send_new' | translate}}</span>
    </div>

    <div class="sending" [hidden]="!send_feedback">
      <h3>{{'banners.new_plataform.modal.sending' | translate}}</h3>
      <mh-spinner-load></mh-spinner-load>
    </div>

  </div>
</ng-template>