import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StoreFullCurrentChain, StoreRelativeCurrentChain, StoreCurrentCustomer } from './customers.actions';

export interface CustomerModel {
  currentCustomer: any;
  fullCurrentChain: any[];
  relativeCurrentChain: any[];
}

@State<CustomerModel>({
  name: 'customers',
  defaults: {
    currentCustomer: null,
    fullCurrentChain: [],
    relativeCurrentChain: []
  }
})
export class CustomersState {

  @Selector()
  static currentCustomer(state: CustomerModel) {
    return state.currentCustomer;
  }

  @Selector()
  static fullCurrentChain(state: CustomerModel) {
    return state.fullCurrentChain;
  }

  @Selector()
  static relativeCurrentChain(state: CustomerModel) {
    return state.relativeCurrentChain;
  }

  constructor() {}

  @Action(StoreCurrentCustomer)
  storeCurrentCustomer(context: StateContext<CustomerModel>, action: StoreCurrentCustomer) {
    context.patchState({
      currentCustomer: action.currentCustomer
    });
  }

  @Action(StoreFullCurrentChain)
  storeFullCurrentChain(context: StateContext<CustomerModel>, action: StoreFullCurrentChain) {
    context.patchState({
      fullCurrentChain: action.fullCurrentChain
    });
  }

  @Action(StoreRelativeCurrentChain)
  storeRelativeCurrentChain(context: StateContext<CustomerModel>, action: StoreRelativeCurrentChain) {
    context.patchState({
      relativeCurrentChain: action.relativeCurrentChain
    });
  }
}
