<div *ngIf="message" [ngClass]="{ 'alert': message, 'alert-success': message.type === 'success', 'alert-danger': message.type === 'error' }">
<div class="row no-gutters align-items-center">
  <div class="col-10">
    <span [innerHtml]="(message.text | translate)"></span>
  </div>
  <div class="col-2 align-right">
    <i class="far fa-times pointer" (click)="close()"></i>
  </div>
</div>


</div>
