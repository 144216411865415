<div class="public-theme">
  <div class="main_content" [style.height.px]="util.screenHeight" >
    <div class="row full_height align-items-center no-gutters">
      <div class="col-sm-12 col-md-6 col-lg-6 full_height">
        <section id="login">
          <div class="row  full_height justify-content-center align-items-center">
            <div class="col-md-11 col-xl-10">
              <div class="block_form">
                <div class="alerts">
                  <alert></alert>
                </div>
                <div class="content_logo">
                  <ng-container *ngIf="canRedirect">
                    <a routerLink="/"><img [src]="myhotelLogoLogin" (error)="setLogo(true)" class="logo" alt="logo"/></a>
                  </ng-container>
                </div>
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
          <div class="myhotel" *ngIf="canRedirect">
            {{ 'themes.public.myhotel' | translate:({year:year,version:version, envName:envName})}}
          </div>
        </section>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 frame_side" *ngIf="canRedirect">
        <iframe class="info_extern" [src]="widget" frameborder="0"></iframe>
      </div>
    </div>

  </div>
  <div class="img_bottom" *ngIf="canRedirect">
    <img src="assets/images/background-login-myhotel.svg" alt="logo">
  </div>
</div>
