import { NPSFilter } from "app/followup/guest/guest.component";
import { Survey } from "app/shared/model/index";
import { FollowupOnsiteFilterDataGuests } from "./followup-onsite.state";

export namespace FollowupOnsite {
  export class StoreGuestsList {
    static readonly type = '[Followup-Onsite] Store Guests List'
    constructor(public guests: Survey[]) { }
  }

  export class GuestListClear {
    static readonly type = '[Followup-Onsite] Guest List Clear'
  }

  export class StoreSurveys {
    static readonly type = '[Followup-Onsite] Store Surveys'
    constructor(public surveys: Survey[]) { }
  }

  export class StoreFilterDataGuests {
    static readonly type = '[Followup-Onsite] Store Filter Data Guests'
    constructor(public data: FollowupOnsiteFilterDataGuests) { }
  }

  export class ChangeNPSFilter {
    static readonly type = '[Followup-Onsite] Change NPS Filter'
    constructor(public nps: NPSFilter) { }
  }

  export class ChangeCountriesFilter {
    static readonly type = '[Followup-Onsite] Change Countries Filter'
    constructor(public countries: string[]) { }
  }

  export class ChangeRoomsFilter {
    static readonly type = '[Followup-Onsite] Change Rooms Filter'
    constructor(public rooms: string[]) { }
  }

  export class ChangeLanguageFilter {
    static readonly type = '[Followup-Onsite] Change Language Filter'
    constructor(public languages: string[]) { }
  }

  export class ChangeSurveyFilter {
    static readonly type = '[Followup-Onsite] Change Survey Filter'
    constructor(public surveys: any[]) { }
  }

  export class ChangeOriginNameFilter {
    static readonly type = '[Followup-Onsite] Change OrigenName Filter'
    constructor(public origin_name: string[]) { }
  }

  export class ChangeOriginSurveyFilter {
    static readonly type = '[Followup-Onsite] Change OrigenSurvey Filter'
    constructor(public origin_survey: any) { }
  }

  export class ChangeChannelFilter {
    static readonly type = '[Followup-Onsite] Change IntegrationChannel Filter'
    constructor(public channels:  {id: number, source: string}[]) { }
  }

  export class ChangeCasesFilter {
    static readonly type = '[Followup-Onsite] Change Cases Filter'
    constructor(public cases:  any) { }
  }

  export class ChangeContactedFilter {
    static readonly type = '[Followup-Onsite] Change Contacted Filter'
    constructor(public contacted:  any) { }
  }

  export class FilterClear {
    static readonly type = '[Followup-Onsite] Filter Clear'
  }

  export class ChangeLayoutView {
    static readonly type = '[Followup-Onsite] Change Layout View'
    constructor(public view: 'list-view' | 'card-view') { }
  }
}
