<div class="change_language {{style}}">
  <widget-list-options
    [name]="'element-change-language'"
    [label]="'language'"
    [list]="availableLanguages"
    [selected]="language"
    [prefixTranslate]="'languages.iso'"
    (select)="setSelectlValue($event)">
  </widget-list-options>
</div>
