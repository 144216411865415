import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

import { CustomerService } from 'app/shared/customer.service';
import { UtilService } from 'app/shared/util.service';
import { ModalCheckSetupComponent } from 'app/shared/modal-check-setup/modal-check-setup.component';
import { Hotel } from '../model';

@Component({
  selector: 'mh-check-setup-button',
  templateUrl: './check-setup-button.component.html',
  styleUrls: ['./check-setup-button.component.scss'],
  providers: [CustomerService]
})
export class CheckSetupButtonComponent implements OnInit {

  @Input() surveys

  modalCheckSetupRef: NgbModalRef;

  constructor(
    private utilService: UtilService,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private customerService: CustomerService,
  ) { }

  ngOnInit() {
    const customerId = this.activeRoute.snapshot.queryParamMap.get("customer")
    const product = this.utilService.getProductNameFromUrl()
    if (customerId && (product === 'Online' || product === 'PreStay')) {
      this.newCustomerFromUrl(customerId)
    }
  }

  private async newCustomerFromUrl(customerId: string) {
    try {
      const responseHotel: any = await this.customerService.findHotel(customerId).toPromise()
      if (responseHotel) {
        const newHotel = (new Hotel()).createFromApi(responseHotel);
        this.utilService.customerChanged(newHotel)
        this.onCheckSetup()
      }
    } catch (err) {
      console.log(err)
    }
  }

  onCheckSetup() {
    this.modalCheckSetupRef = this.modalService.open(ModalCheckSetupComponent, {
      windowClass: 'modal-xl'
    });
    if (this.surveys) {
      this.modalCheckSetupRef.componentInstance.surveys = this.surveys
    }
    this.utilService.clearQueryParams(this.activeRoute, {customer: null})
  }

}
