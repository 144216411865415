<mh-alerts-wrapper>

  <section class="flex flex-row justify-between">
    <div>
      <mh-core-lib-datepicker
        [currentDates]="currentDates"
        [currentFilter]="currentFilterDate"
        (datepickerUpdated)="handleDatepickerUpdated($event)">
      </mh-core-lib-datepicker>
    </div>
    <div>
      <mh-filter-date
          [startDate]="startDate"
          [endDate]="endDate"
          [report]="fidelityPermisions.includes('interchain_online_coverage_report') ? 'chainOnlineCoverageGeneral' : null "
          [reportActiveEmail]="true"
          [checkPermissions]="true"
          slug="corporative_online_coverage_general_report">
      </mh-filter-date>
    </div>
  </section>

  <section id="general">
    <mh-box-element [title]="('inter-chain.online.coverage.general.title_otas' | translate )">
      <mh-wait-chain *ngIf="waiting" [currentChain]="currentChain" [block]="callingInterchain" [total]="totalSizeChain" [totalBlock]="totalSizeBlock"></mh-wait-chain>
      <div class="content_graph_bar" *ngIf="!waiting">
        <div class="row">
          <div class="offset-md-2 col-md-8" *ngIf="fidelityPermisions.includes('interchain_online_coverage_graph_bar')">
            <canvas baseChart
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [legend]="barChartLegend"
                    [chartType]="barChartType"

                    (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)"></canvas>
          </div>
        </div>
      </div>

      <div class="pies" *ngIf="fidelityPermisions.includes('interchain_online_coverage_pie')">
        <div class="row" *ngIf="!waiting">
          <div class="col-6" *ngFor="let item of pieCharts">
            <mh-box-element [subTitle]="(item[0].subTitle | translate )" [title]="(item[0].title | translate )">
              <mh-chart-pie
                [datasets]="item"
                [pieLabels]="barChartLabels"
                [waiting]="waiting"
                [positionLegend]="'right'">
              </mh-chart-pie>
            </mh-box-element>
          </div>
        </div>
      </div>

    </mh-box-element>
  </section>


</mh-alerts-wrapper>
