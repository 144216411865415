import Question from './Question.model';
import Translate from './Translate.model'
import Answer from './Answer.model'

export default class Section {
  id: number;
  questions: Question[];
  survey_id: number;
  active: boolean;
  order: number;
  page: number;
  translates: Translate[];
  valid: boolean = false;

  static createFromApi(obj: any) {
    return Object.assign((new Section()), {
      ...obj,
      questions: obj.questions.filter(q => q.alternatives.length)
        .map(q => Question.createFromApi(q)),
    })
  }

  updateState(answers: Answer[]) {
    this.questions.forEach(q => q.updateState(answers))

    this.valid = this.questions.every(q => q.valid);
  }
}
