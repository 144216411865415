<div class="modal-header text-center">
  Check Setup {{productName}}
  <button type="button" class="close" aria-label="Close" (click)="modal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-wrapper">
  <div class="waiting_center" *ngIf="loading">
    <mh-spinner-classic></mh-spinner-classic>
  </div>
  <div class="text-center" *ngIf="message" [ngClass]="messageClass">
    {{message}}
  </div>
  <div class="px-3" *ngIf="!loading && checkSetup">
    <p class="text-center mt-2">Estado:
      <strong class="status-value" [ngClass]="processStatusClass(checkSetup.status)">
        {{checkSetup.status}}
      </strong>
    </p>
    <mh-table-setup [survey]="checkSetup"></mh-table-setup>
    <div *ngIf="checkSetup.children?.length > 0">
      <div *ngFor="let child of checkSetup?.children">
        <mh-table-setup [survey]="child" [showStatusChildren]="true"></mh-table-setup>
      </div>
    </div>
  </div>
</div>
