import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PublicConfig, PublicConfigModel } from './models';
import { StorePublicConfig } from './public-survey.actions';

const publicConfig = new PublicConfig();

@State<PublicConfigModel>({
  name: 'publicConfig',
  defaults: {
    publicConfig: publicConfig,
    surveyId: 0 
  }
})
export class PublicConfigState {

  @Selector()
  static publicConfig(state: PublicConfigModel) {
    return state.publicConfig;
  }

  @Selector()
  static surveyId(state: PublicConfigModel) {
    return state.surveyId;
  }


  constructor() {}

  @Action(StorePublicConfig)
  storePublicConfig(context: StateContext<PublicConfigModel>, action: StorePublicConfig) {
    context.patchState({
      surveyId: action.surveyId,
      publicConfig: action.publicConfig
    })
    
  }

}
