import { Component, OnInit, Input } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'mh-core-lib-nps-case',
  templateUrl: './nps-case.component.html',
  styleUrls: ['./nps-case.component.scss']
})
export class CoreLibNpsCaseComponent implements OnInit {

  @Input()
  survey;

  @Input()
  source: 'create' | 'detail' = 'create';

  @Input()
  customerId = null;

  @Input()
  product = '';

  constructor() { }

  ngOnInit(): void {
  }

  get isCreate() {
    return this.source === 'create';
  }

  get stay() {
    const checkinDate = new Date(this.survey.guest.checkin);
    const checkoutDate = new Date(this.survey.guest.checkout);
    const checkin = {
      day: checkinDate.getDate(),
      month: checkinDate.getMonth() + 1,
      year: checkinDate.getFullYear()
    };
    const checkout = {
      day: checkoutDate.getDate(),
      month: checkoutDate.getMonth() + 1,
      year: checkoutDate.getFullYear()
    }
    const luxonCheckin = DateTime.fromObject(checkin);
    const luxonCheckout = DateTime.fromObject(checkout);
    return luxonCheckout.diff(luxonCheckin, 'days').toObject().days;
  }

  get url() {
    const date = this.survey.answered_survey.date.split(' ')[0];
    return `/${this.product}/huespedes?guest=${this.survey.guest.guess_id}&customer=${this.customerId}&date=${date}`
  }
}
