import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { Hotel } from 'app/shared/model';
import { BaseChartDirective } from 'ng2-charts';
import { TrendLegendData } from './trends-legend/trend-legend.model';
import {UtilService} from '../../../shared/util.service';
import { TrendsTableComponent } from './trends-table/trends-table.component';

@Component({
  selector: 'widget-trends',
  templateUrl: './trends.component.html',
  styleUrls: ['./trends.component.scss']
})
export class TrendsComponent implements OnInit {

  @Input()
  chain: Hotel[] = [];

  @Input()
  trendKeys = [];

  @Input()
  areasKeys = [];

  @Input()
  showDefaultLegends = false;

  @Input()
  waiting = true;

  @Input()
  lineChartData;

  @Input()
  lineChartLabels;

  @Input()
  lineChartColors;

  @Input()
  trendSelected;

  @Input()
  areaSelected;

  @Input()
  segmentedSelected;

  @Input()
  firstColumn = 'hotel';

  @Input()
  showDetail = false;

  @Input()
  totals = {}

  @Input()
  showFilters;

  @Input()
  prefixTranslateFilters = 'widgets.trends.filters';

  @Input()
  showOtherFilters = true;

  @Output()
  filterTypeChange: EventEmitter<any> = new EventEmitter();

  @Output()
  filterCustomerChange: EventEmitter<number[]> = new EventEmitter();

  @Output()
  detailSelected: EventEmitter<any> = new EventEmitter();

  @Output()
  chartClick: EventEmitter<any> = new EventEmitter();

  chartLegends='';


  lists = {
    'trend' : [],
    'areas' : [],
    'segmentedBy' : [
      'months',
      'weeks',
      'days'
    ]
  };

  lineChartLegend:boolean = true;
  lineChartType:string = 'line';

  lineChartOptions:any = {
    maintainAspectRatio: false,
    responsive: true,
    elements:{
      line: {
        tension: 0.5,
      }
    },
    scales:{
      yAxes: [
        {
          ticks: {
          }
        }
      ]
    },

    legend: {
      display: false,
      onHover: function(e) {
        e.target.style.cursor = 'pointer';
      }
    }
  };

  @ViewChild(BaseChartDirective, { static: false })
  chart: BaseChartDirective | any;

  @ViewChild('tableTrend', { static: false })
  tableTrend: TrendsTableComponent;


  constructor(
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.lists.trend  = this.trendKeys;
    this.lineChartOptions.legend.display = this.showDefaultLegends;
    if(this.showDetail){
      this.lineChartOptions['hover'] = {
        onHover: function(e) {
          const point = this.getElementAtEvent(e);
          if (point.length) e.target.style.cursor = 'pointer';
          else e.target.style.cursor = 'default';
        }
      }
    }
    this.setAxes();

  }

  setOnlyAverage() {
    let containAverage = false;
    this.lineChartData.forEach(data => {
      if(data.isAverage) {
        containAverage = true;
      }
    });
    if(this.trendSelected!=='ranking' && containAverage) {
      this.lineChartData.forEach(element => {
        if(!element.isAverage) {
          element.hidden = true;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['areasKeys']) this.lists.areas = this.areasKeys;
    this.lists.trend  = this.trendKeys;
    if(!this.waiting) {
      this.setAxes();
      this.setOnlyAverage();
    }
  }

  filterCustomers($event:TrendLegendData[]){
    if(this.chart) {
      this.chart.chart.data.datasets.forEach((element,index) => {
        element.hidden = $event[index].hidden;
      });
      this.chart.chart.update();
    }
  }

  setSelectlValue(option, key){
    if(key==='trend') this.trendSelected = option.value;
    if(key==='segmentedBy') this.segmentedSelected = option.value;
    this.filterTypeChange.emit({
      option: option,
      key: key
    });
    this.setAxes();
  }


  setAxes(){
    const self = this;
    const inPercent = ['ordersRate','emailRate','pms'].includes(self.trendSelected);
    const reverse   = ['rankingTripAdvisor','ranking'].includes(self.trendSelected);

    const properties = {
      reverse: reverse,
      callback: function(value, index, values) {
        if('totalPrice'===self.trendSelected) return `$${value}`;
        if(inPercent) return `${value}%`;
        if(['rankingTripAdvisor','ranking'].includes(self.trendSelected))return `#${value}`;
        return value;
      }
    }

    if(['rankingTripAdvisor','ranking'].includes(self.trendSelected)){
      properties["min"] = 1;
    }

    if(inPercent){
      properties["suggestedMin"] = 0;
      properties["suggestedMax"] = 100;
    }

    if(['nps'].includes(self.trendSelected)) {
      properties["max"] = 100;
      properties["min"] = this.setMinNpsGraphic();
    }


    const tooltips = {};
    tooltips['callbacks'] = {
      label: function(tooltipItem, chart) {
        const customer = chart.datasets[tooltipItem.datasetIndex];
        let value = tooltipItem.yLabel;
        if(['totalPrice','ordersRate','emailRate'].includes(self.trendSelected)){
          value = 'totalPrice'===self.trendSelected ? `$${value}`: `${value}%`;
        }
        if(['nps'].includes(self.trendSelected)){
          value =  value >0 ? `+${value}`: value;
        }
        if(['rankingTripAdvisor','ranking'].includes(self.trendSelected)){
          value = `#${value}`;
        }
        if(['pms'].includes(self.trendSelected)){
          value = `${value}%`;
        }

        if(['note','ranking_areas'].includes(self.trendSelected)){
          value =  self.utilService.formatDecimals(value);
        }

        return customer.label+': '+value;
      }
    }

    this.lineChartOptions.tooltips= tooltips;
    this.lineChartOptions.scales.yAxes[0].ticks = properties;
  }

  setMinNpsGraphic() {
    let min = 0;
    this.lineChartData.forEach(customer => {
      customer.data.forEach(value => {
        min = Math.min(min, value);
      })
    });
    return min;
  }

  emitDetailSelected($event){
    this.detailSelected.emit($event);
  }

  chartClicked(e:any):void {
    if(e.active.length > 0 && this.showDetail){
      const chart        = e.active[0]._chart.controller;
      const activePoint  = chart.getElementAtEvent(event);
      const cell         = this.lineChartData[activePoint[0]._datasetIndex]
      const result = {};
      result[this.firstColumn] = cell.label;

      cell.data.forEach((data,i)=>{
        result[this.lineChartLabels[i]] = data
      });
      this.tableTrend.openDialog(result);
    }

  }

}
