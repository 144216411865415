import { Pipe, PipeTransform } from '@angular/core'

import { Survey } from "app/shared/model/index";
import { FollowupOnsiteGuestFilter } from 'app/states/followup-onsite/followup-onsite.state';

@Pipe({ name: 'followupOnsiteOriginNameFilter' })
export class FollowupOnsiteOriginNameFilterPipe implements PipeTransform {

  transform(value: Survey[], filters: FollowupOnsiteGuestFilter): Survey[] {
    if(filters.origin_name) {
      const filtered = value.filter(survey => !!filters.origin_name.find(country => country === survey.origin_name) )
      if(filtered?.length > 0) return filtered
      else return value
    } else
      return value
  }
}
