import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StoreDefaultLanguage } from './default-language.actions';

export interface DefaultLanguageModel {
  languageId: number;
  code: string;
}

@State<DefaultLanguageModel>({
  name: 'defaultLanguage',
  defaults: {
    languageId: null,
    code: null,
  }
})
export class DefaultLanguageState {

  @Selector()
  static languageCode(state: DefaultLanguageModel) {
    return state.code;
  }

  @Selector()
  static languageId(state: DefaultLanguageModel) {
    return state.languageId;
  }

  @Selector()
  static language(state: DefaultLanguageModel) {
    return state;
  }

  constructor() { }

  @Action(StoreDefaultLanguage)
  storeDefaultLanguage(context: StateContext<DefaultLanguageModel>, action: StoreDefaultLanguage) {
    context.patchState({
      code: action.code,
      languageId: action.languageId
    })
  }

}
