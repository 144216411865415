import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Survey } from 'app/shared/model';
import { SurveyAnswerService } from '../survey-answer.service';
import { HistoryMessage } from 'app/shared/model';

@Component({
  selector: 'mh-survey-messages',
  templateUrl: './survey-messages.component.html',
  styleUrls: ['./survey-messages.component.scss'],
})
export class SurveyMessagesComponent implements OnInit, OnChanges {
  @Input() survey: Survey;
  @Input() messageRegistered: { data: boolean; from: string; type: string };

  @Output() messagesQty = new EventEmitter<number>();

  public channelIcons = {
    1: 'far fa-comment-check',
    2: 'far fa-envelope',
    3: 'far fa-phone',
    4: 'fab fa-whatsapp',
    5: 'far fa-user',
    6: 'fas fa-ellipsis-h',
  };
  public messages: HistoryMessage[] = [];
  public selectedMessage: HistoryMessage;
  public showHistory = true;
  public isNewMessage = false;

  constructor(private surveyAnswerService: SurveyAnswerService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.messageRegistered && changes.messageRegistered.currentValue) {
      this.getHistory();
      this.isNewMessage = true;
    }
  }

  ngOnInit() {
    this.getHistory();
  }

  async getHistory() {
    const answerId = this.survey.id;
    try {
      const res = await this.surveyAnswerService.getContactHistory(answerId).toPromise();
      if (res) {
        this.messages = res.data as any;
        this.messages.sort((a, b) => new Date(b.action_date).getTime() - new Date(a.action_date).getTime());
        this.messagesQty.emit(this.messages.length);
      }
    } catch (error) {
      console.error(error);
    }
  }

  showMessage(message: HistoryMessage) {
    this.selectedMessage = message;
    this.showHistory = false;
  }

  back() {
    this.selectedMessage = null;
    this.showHistory = true;
  }
}
