import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UtilService } from "../../../shared/util.service";
import { Hotel,UserProfile } from '../../../shared/model/index';
import {NgbModal,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ConfigurationService} from 'app/configuration/configuration.service';

@Component({
  selector: 'mh-configuration-edit-user',
  templateUrl: './user-edit.component.html',
  styleUrls: ['../../../../assets/scss/configuration/user-edit.scss'],
  providers:[]
})
export class UserEditComponent{

  @Input()
  style:string='';

  @Input()
  title:string='forms.edit_info';

  @Input()
  icon:string;

  @Input()
  user:UserProfile;

  @Input()
  type:string;

  @Input()
  customer:Hotel;

  @Output()
  onUpdateUser = new EventEmitter<UserProfile>();

  waiting:boolean=true;

  modalReference: NgbModalRef;


  constructor(
    private utilService:UtilService,
    private modalService: NgbModal,
    private _configurationService: ConfigurationService) {}

  openModal(content) {
    let can_edit_customers = this.utilService.currentUser.isAdmin();
    let modalClass = can_edit_customers ? 'modal-xl' : 'modal-lg';
    this.modalReference = this.modalService.open(content, {
      windowClass: 'modal-confirm left '+modalClass
    });
  }

  setWaiting($wating){
    setTimeout(_ => {
      this.waiting = $wating;
    });
  }

  closeModal($state){
    this.modalReference.close();
  }

  is(type){
    return this.type===type
  }

  updateProfileUser(event:UserProfile) {
    this.closeModal(event);
    this.onUpdateUser.emit(event);
  }

  get configurationService() {return this._configurationService}
}


