import {Component, Input} from '@angular/core';

@Component({
  selector: 'mh-semantic-detail',
  templateUrl: './semantic-detail.component.html',
  styleUrls: ['../../../../assets/scss/shared/semantic/detail.scss'],
  providers:[],
})

export class SemanticDetailComponent{


  @Input()
  productId;

  @Input()
  semanticAreas:any[]=[]

  @Input()
  showDetail = true;

  waitDetail= true;

  constructor() { }

  hasAreas(){
    return this.semanticAreas.length>0;
  }

  getDetail($event){
    this.waitDetail = !this.hasAreas()
  }


  getDetailMention(semantic){
    let positive = semantic.categories.filter(x=>x.sentiment_polarity === 'positive').length;
    let negative = semantic.categories.filter(x=>x.sentiment_polarity === 'negative').length;
    let neutral = semantic.categories.filter(x=>x.sentiment_polarity === 'neutral').length;
    let detailData = new DetailData(positive,neutral,negative);
    return detailData;
  }

  getIcon(type){
    let style = '';
    if(type === "positive") style = "far fa-smile-wink";
    if(type === "negative") style = "far fa-frown";
    if(type === "neutral") style = "far fa-meh";
    return `${style} ${type}`;
  }

}

export class DetailData{
  positivePercentage=0
  neutralPercentage=0
  negativePercentage=0
  positiveLabel=0;
  neutralLabel=0;
  negativeLabel=0;
  constructor(positiveLabel, neutralLabel, negativeLabel){
    this.positiveLabel = positiveLabel;
    this.neutralLabel  = neutralLabel;
    this.negativeLabel = negativeLabel;
    let total = positiveLabel+neutralLabel+negativeLabel;
    this.positivePercentage = Math.round(positiveLabel/total*100);
    this.neutralPercentage  = Math.round(neutralLabel/total*100);
    this.negativePercentage = Math.round(negativeLabel/total*100);
  }
}




