import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Hotel, FilterDate } from 'app/shared/model';
import { AppComponent } from 'app/layouts/app/app-component';
import { UtilService } from 'app/shared/util.service';
import { InterChainPrestayService } from '../inter-chain-prestay.service';
import { Subscription, forkJoin } from 'rxjs';
import {CustomerService} from '../../../shared/customer.service';
import { ChartsDataSet } from "../../../shared/utils/charts-data-set";
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';
import {DateService} from 'app/shared/services/date.service';

export interface DataPeriod{
  customer_id : number;
  periods : PeriodTrend[]
}

export interface PeriodTrend{
  date : string;
  data: any;
}

@Component({
  selector: 'mh-chain-prestay-trends',
  templateUrl: './prestay-trends.component.html',
  styleUrls: ['./prestay-trends.component.scss'],
  providers: [InterChainPrestayService, CustomerService,ChartsDataSet]
})
export class PrestayTrendsComponent  extends AppComponent{

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  chain : Hotel[] = [];

  @Input()
  currentDates = null;

  segmentedBy = 'months';
  trend  = 'totalPrice';

  actives:any[] = [];
  data = [];

  actions   = {
    totalPrice : 'getTrendSales',
    totalServices : 'getTrendSales',
    totalOrders : 'getTrendSales',
    ordersRate : 'getTrendOrdersRate',
    pms : 'getTrendEmailsRate',
    checkins : 'getTrendCheckin'
  }

  @Output()
  isReady: EventEmitter<boolean> = new EventEmitter();

  customerSubscribe: Subscription;
  customersStateSubscribe: Subscription;

  // lineChart
  parserData = {};
  parserDataLabels:string[];
  lineChartData:Array<any> = [];
  lineChartLabels:Array<any> = [];
  lineChartColors:Array<any> = [];

  trendKeys= [
    'totalPrice',
    'totalOrders',
    'totalServices',
    'ordersRate',
    'pms',
    'checkins'
  ];


  constructor(
    private utilService: UtilService,
    private interChainPrestayService:InterChainPrestayService,
    private customerService: CustomerService,
    private chartsDataSet: ChartsDataSet,
    private store: Store,
    private dateService: DateService
  ) {
    super();
    this.customerSubscribe = this.utilService.isCustomerChanged().subscribe(response=>{
      this.init();
    })

    this.customersStateSubscribe = this.customersReady$.subscribe(customers => this.init());
  }

  ngOnChanges({currentDates}) {
    if ( currentDates ) {
      this.init();
    }
  }

  ngOnDestroy(){
    this.customerSubscribe.unsubscribe();
  }

  reset(){

  }

  init(){
    this.data = [];
    this.segmentedBy = this.dateService.getSegmentation();
    this.setWaiting(true);
    this.isReady.emit(false);
    this.resetInterchain();
    this.actives = this.chain.map(x=>x.id)
    this.startChunkInterchain(this.actives)
    if(this.canLoadWidget()){
      if(this.actives.length>0) this.loadData();
    }
  }

  loadData(){
    const date      = new FilterDate(this.currentDates.startDate, this.currentDates.endDate);
    const customers = this.store.selectSnapshot(state => state.customers.relativeCurrentChain).map(c => c.id).join(',');

    if (customers.length) {
      forkJoin([
        this.interChainPrestayService[this.actions[this.trend]](customers,date,this.segmentedBy)
      ]).subscribe((response:DataPeriod[])=>{
        response.forEach((data: any) => {
          this.data = this.data.concat(data);
        })

        this.nextChain();
        if(this.callingInterchain === this.chunkInterchain.length){
          this.customerService.findByIds(this.chain.map(x=>x.id).join(',')).subscribe(response=>{
            this.setCustomerDetail(response);
            this.setGraph();
            this.setWaiting(false);
            this.isReady.emit(true);
            setTimeout(_ => {
              this.utilService.forceResize();
            },3000);
          })
        }else{
          setTimeout(_ => {
            this.loadData();
          },100);
        }
      })
    }

  }

  canLoadWidget(){
    if(!this.utilService.currentUser.isSuperAdmin()) return true;
    if(this.utilService.currentUser.fakeUser) return true;
    return false;
  }

  setGraph(){
    this.waiting = true;
    const dataLabels      = this.utilService.getIntervalLabels(this.utilService.stringDateToDate(this.currentDates.startDate),this.utilService.stringDateToDate(this.currentDates.endDate),this.segmentedBy);
    this.parserData = {}
    this.data.forEach((data: any) => {
      if(this.actives.includes(data.customer_id)){
        const customer: any = this.chain.find(x=>parseInt(x.id)===data.customer_id)
        this.parserData[customer.name] = {data: data.periods.map(x=>x.data[this.trend])}
      }
    });

    this.parserDataLabels = dataLabels.map((x: any) => x.format);
    const lineDataSet      = this.chartsDataSet.getLineData(this.parserData, this.parserDataLabels);

    this.lineChartData     = lineDataSet["data"];
    this.lineChartLabels   = lineDataSet["labels"];
    this.lineChartColors   = lineDataSet["colors"];
    this.waiting = false;
  }

  setSelectlValue(option, key){
    this[key] = option.value;
    this.init();
  }

  filterCustomers($event){
    this.actives = $event;
  }

}
