import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment'

@Injectable()
export class CaseProductService {

  constructor(private http: HttpClient) { }

  public getOnlineReview(reviewId: param): Observable<any> {
    const url = `${environment.onlinePath}reviews/${reviewId}`
    const params = {language_id: '1'}

    return this.http.get(url, { params });
  }

  public getOnsiteAnswer(answerId: param): Observable<any> {
    const url = `${environment.followupPath}surveys/answereds/${answerId}/guest-info`;
    const params = { product_id: '2' };

    return this.http.get(url, { params });
  }

  public getFollowupAnswer(answerId: param): Observable<any> {
    const url = `${environment.followupPath}surveys/answereds/${answerId}/guest-info`;
    const params = { product_id: '1' };

    return this.http.get(url, { params });
  }

  public getPrestayPrecheckin(customerId: param, checkinId: param): Observable<any> {
    const url = `${environment.prestayPath}customer/${customerId}/view/checkins/${checkinId}`;
    const params = {language_id: '1'} //TODO: REPLACE

    return this.http.get(url, { params });
  }

  public getPrestayOrder(customerId: param, orderId: param): Observable<any> {
    const url = `${environment.prestayPath}customer/${customerId}/view/orders/${orderId}`;
    const params = {language_id: '1'} //TODO: REPLACE

    return this.http.get(url, { params });
  }
}

type param = string | number;
