<widget-filter-chain [hidden]="waiting" [customers]="chain" (customerFilter)="filterCustomers($event)"></widget-filter-chain>
<mh-spinner-classic [hidden]="!waiting"></mh-spinner-classic>

<ng-container *ngIf="!waiting">
  <widgets-table-periods
    [dataSource]="dataSource"
    [headerTable]="headerTable"
    [chain]="chainActives"
    [totals]="totals"
    [tooltipHeaders]="tooltipHeaders"
    [prefixTranslate]="'inter-chain.online.tripadvisor.table'"
  ></widgets-table-periods>
</ng-container>
