import { Component, OnInit } from '@angular/core';
import {UtilService} from "../../shared/util.service";
import {EmailsService} from '../../_services/emails.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { HotelDto } from 'app/shared/model';
import { plainToClass } from 'class-transformer';
import { HotelService } from 'app/shared/hotel/hotel.service';

@Component({
  selector: 'mh-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['../../../assets/scss/support/feedback.scss'],
  providers:[EmailsService, HotelService]
})
export class FeedbackComponent implements OnInit {

  send_ticket = false;
  send_success = false;
  body:string;
  title:string;
  urgency:string;
  currentUser;
  currentHotel;
  currentHotelDto;

  show_form = false;
  send_feedback=false;
  feedbackVote=0;
  feedbackHover=0;
  message_feedback='';
  send_opinion = false;

  constructor(private  utilService:UtilService, private emailsService:EmailsService,private modalService: NgbModal,private hotelService:HotelService) {
    this.currentUser      = this.utilService.getCurrentUser();
    this.currentHotel     = this.utilService.getCurrentHotel();
    this.utilService.isCustomerChanged().subscribe(response=>{
      this.loadInfo();
    })
  }
  ngOnInit() {
    this.loadInfo();
  }

  loadInfo(){
    this.utilService.setGeneralFade(true);
    this.resetForm();
    this.hotelService.getHotel(this.currentHotel.id).subscribe(response=>{
      this.currentHotelDto = plainToClass<HotelDto, Object>(HotelDto, response);
      this.currentHotel = this.utilService.getCurrentHotel();
      this.utilService.setGeneralFade(false);
    })
  }

  resetForm(){
    this.body = '';
    this.title = '';
    this.urgency = '';
    this.send_ticket = false;

    setTimeout(_ => {
      this.resetSucces()
    },5000);

  }

  resetSucces(){
    this.send_success = false;
  }


  sendTicket(){
    this.send_ticket = true;
    let result =
      "<b>Titulo: </b>"+this.title+
      "<br><b>Prioridad: </b>"+this.urgency+
      "<br><b>Cuerpo: </b>"+this.body+
      "<br><b>Usuario: </b>"+this.currentUser.name+' '+this.currentUser.lastName+
      "<br><b>Email: </b>"+this.currentUser.email+
      "<br><b>Telefono Usuario</b>" + this.currentUser.profile.phone +
      "<br><b>Cargo Persona</b>" + this.currentUser.profile.company_position +
      "<br><b>Hotel: </b>"+this.currentHotel.name+
      "<br><b>Hotel ID: </b>"+this.currentHotel.id+
      "<br><b>status / Licencia</b>" + this.currentHotelDto.licence_type +
      "<br><b>Tipo de Cliente</b>" + this.currentHotelDto.property_type.description +
      "<br><b>Nombre Cadena</b>" + (this.currentHotelDto.chain_property ? this.currentHotelDto.chain_property.brand : '-')+
      "<br><b>Ciudad</b>" + this.currentHotelDto.city.name +
      "<br><b>Código País</b>" + this.currentHotelDto.city.country_code +
      "<br><b>Idioma</b>" + this.currentHotelDto.language.code;
    this.utilService.ga("support","click","sending form");
    this.emailsService.send('cs@myhotel.cl','Nuevo Ticket FS','Ticket de soporte',result).subscribe(response=>{
      this.send_ticket = false;
      this.send_success = true;
      this.utilService.ga("support","click","send form success");
      this.resetForm();
    })
  }

  sendNew(){
    this.send_feedback = false;
    this.send_opinion = false;
    this.feedbackHover = 0;
    this.feedbackVote = 0;
    this.message_feedback = '';
  }

  sendFeedback(){
    this.send_feedback = true;
    let result = "<b>Comentario: </b>"+this.message_feedback+"<br><b>Calificación: </b>"+this.feedbackVote+" Estrellas<br><b>Usuario: </b>"+this.currentUser.name+' '+this.currentUser.lastName+"<br><b>email: </b>"+this.currentUser.email+"<br><b>Hotel: </b>"+this.currentHotel.name;
    this.emailsService.send('fidelity-notifications@myhotel.cl','Fidelity Suite','Nuevo Feedback',result).subscribe(response=>{
      this.send_feedback = false;
    })
  }

  openModal(content) {
    this.modalService.open(content, {
      windowClass: 'modal-general'
    }).result.then((result) => {
      this.show_form = false;
    }, (reason) => {
      this.show_form = false;
    });

    setTimeout(_ => {
      this.show_form = true;
    },100);
  }

  isHover(index){
    this.feedbackHover = index
  }
  setHover(){
    this.feedbackHover = 0;
  }
  emitVote(index){
    this.feedbackVote = index;
  }

  showOnStar(index){
    return this.feedbackHover>index || this.feedbackVote >=index;
  }


}
