import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';

@Component({
  selector: 'mh-hotel-finder',
  templateUrl: './hotel-finder.component.html',
  styleUrls: ['./hotel-finder.component.scss'],
})
export class HotelFinderComponent implements OnInit {
  @Select(CustomersState.fullCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  currentUser;

  @Input()
  currentClient;

  @Input()
  showLabel = false;

  @Input()
  isCorporative = false;

  @Input()
  isSearcherMenuOpened = false;

  @Input()
  currentChain = null;

  @Input()
  isMobile = false;

  @Input()
  isNavbarCorp = 0;

  @Output()
  updated: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  isReady: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('input')
  input: any;

  @ViewChild('inputSearch')
  inputSearch: any;

  corporativeList = [];
  inputValue = '';
  findClientType = 'client';
  clientControl = new FormControl();
  filteredOptions: Observable<any[]>;
  adjustMenuSize = '';
  customersStateSubscribe: Subscription;

  constructor() {
    this.customersStateSubscribe = this.customersReady$.subscribe((customers) => {
      if (this.currentClient && this.isCorporative) {
        this.fillCorporative(customers);
      }
    });
  }

  ngOnInit(): void {
    if (this.currentClient && this.isCorporative) {
      this.fillCorporative();
      this.initFilter();
    }
  }

  ngOnDestroy() {
    this.customersStateSubscribe.unsubscribe();
  }

  fillCorporative(customers = []) {
    if (customers.length || this.currentUser.customers.length > 1) {
      this.corporativeList = customers;
      const currUserCustomers = this.currentUser.customers.length > 1 ? this.currentUser.customers : [];
      this.corporativeList = this.is_super_admin ? customers : currUserCustomers;

      this.adjustMenuSize = this.corporativeList.length > 5 ? 'adjusted' : '';
    } else {
      this.corporativeList = [];
    }
    this.isReadyToEmit(true);
  }

  triggerMenu() {
    this.input.nativeElement.click();
  }

  updateCustomer(customer: any) {
    this.updated.emit(customer);
  }

  isReadyToEmit($event) {
    setTimeout(() => {
      this.isReady.emit($event);
    }, 500);
  }

  initFilter() {
    this.filteredOptions = this.clientControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter(name) : this.corporativeList.slice())),
    );
  }

  private _filter(name: string) {
    const filterValue = name.toLowerCase();
    return this.corporativeList.filter((option) => option.name.toLowerCase().includes(filterValue));
  }

  setFocus() {
    setTimeout(() => {
      this.inputSearch.nativeElement.focus();
    }, 0);
  }

  get clientNameCorporative() {
    if (this.isNavbarCorp && this.isMobile) {
      return `${this.currentChain}`;
    }
    return this.isNavbarCorp ? `${this.currentChain}` : `${this.currentClient?.name}`;
  }

  get corpPrefix() {
    return this.isNavbarCorp && !this.isMobile ? 'header.corp-statistics.title' : '';
  }

  get clientName() {
    return this.currentClient?.name || '';
  }

  get is_super_admin() {
    return this.currentUser?.superAdmin;
  }

  get areCustomersFilled() {
    return this.corporativeList.length;
  }
}
