import { Component, ViewChild,ElementRef,Output,EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Store } from '@ngxs/store'
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


import {SurveyService} from "../../followup/survey/survey.service";
import { SurveyAnswerService} from "../../shared/surveys/survey-answer.service";
import { UtilService} from "../util.service";
import { Survey } from "../model/survey.model";
import { environment } from 'src/environments/environment';
import { FollowupOnsiteState } from 'app/states/followup-onsite/followup-onsite.state'
import { animation } from 'app/shared/utils/animation-custom';
import {ActivatedRoute} from '@angular/router';

@Component({
  templateUrl: './survey-answer-modal.html',
  providers: [SurveyService,SurveyAnswerService],
  styleUrls: ['./survey-answer-modal.scss'],
  animations: [animation.heightExpand],
})
export class SurveysAnswerModal{

  satisfaction = Object;
  answers;
  comments
  answersComments = []
  loading = true;
  waiting = true;
  generatingPDF:boolean=false;
  survey:Survey;
  surveys: any
  surveySelected: any
  productId;
  allQuestions: any[]=[];
  currentLanguage;
  surveyLanguage;
  util;
  waitTranslate=false;
  waitTranslateId;
  txtTranslate;
  commentsTraslates: any[]=[];
  open: boolean
  createCase = false;

  @ViewChild('resultSurvey', { static: false }) resultSurvey:ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
    private surveyService: SurveyService,
    private utilService:UtilService,
    private surveyAnswerService:SurveyAnswerService,
    private store: Store,
    private route: ActivatedRoute
  ) {
    this.util = this.utilService;
    this.currentLanguage = this.utilService.getLanguageId();

    this.utilService.isLanguageChanged().subscribe(_ =>{
      this.currentLanguage = this.utilService.getLanguageId();
    })
  }

  ngOnInit(){
    this.surveys = this.store.selectSnapshot(FollowupOnsiteState.surveys)
    this.surveySelected = this.surveys.find(s => s.id === this.survey.id)
    this.surveyLanguage = this.survey && this.survey.guest.language ? this.survey.guest.language["id"] : 0;
    this.loadComments()
    this.route.queryParams.subscribe(params => {
      this.createCase = !!params?.createCase || false;
    });
  }

  private async loadComments() {
    const resp = await this.surveyAnswerService.getSurveyComments(this.survey.id, this.survey.answer.id).toPromise()
    if(resp) {
      this.comments = resp
      this.comments.sort((a, b) => parseFloat(a.question.order) - parseFloat(b.question.order));
      this.comments.forEach(item => {
        this.answersComments.push({
          answer_alternative_id: item.question.alternatives[0].id,
          answer_text: item.comment,
          question_id: item.question.id,
        })
      })
    }
  }

  onToggle() {
    this.open = !this.open
  }

  closeDialog(){
    this.activeModal.close()
  }

  getProductItem(id: number) {
    return {
      type: { id: 1 },
      product_id: this.productId,
      item_id: id
    }
  }

  translate(comment,id){
    this.txtTranslate = '';
    this.waitTranslate   = true;
    const sourceLanguage = (this.surveyLanguage===this.currentLanguage) ? this.surveyLanguage : 0;
    this.surveyAnswerService.translateComment(this.survey.answer.id+id, sourceLanguage ,this.currentLanguage,this.productId, '', comment).subscribe((response:any)=>{
      this.txtTranslate = response.description;
      this.commentsTraslates[this.survey.answer.id+id] = this.txtTranslate;
      this.waitTranslate = false;
    })
  }

  setModal(survey,productId='1') {
    this.utilService.setGeneralFade(true);
    this.loading                      = true;
    this.waiting                      = true;
    this.survey                       = survey;
    this.productId                    = productId;
    this.waiting                      = false;

    //basically, if version = 2, do nothing
    if (this.survey.version === 2) {
      this.loading = false;
      this.utilService.setGeneralFade(false);
      return
    }

    // due to version compatibility, we will have to fetch all survey related data here
    this.surveyService.getSurvey(
      this.survey.answer.id,
      productId,
      this.survey.id,
    ).subscribe((response:any)=>{
      this.loading = false;

      this.answers = response.answers;
      this.allQuestions = this.surveyService.fillQuestion(response);
      this.utilService.setGeneralFade(false);
    });
  }

  getAnswers(question, id_alternative=undefined,sub_question: any=undefined) {
    let answer = this.answers[question.id];
    if(answer===undefined){
      return [5].includes(question.type) ? '' : false;
    }
    if(id_alternative){
      if(sub_question){
        let sub_answer = answer.find(x=>x.id===sub_question.id);
        return sub_answer ? sub_answer.alternatives[0].id === id_alternative : false;
      }
      return answer.find(x=>x.id===id_alternative)
    }else{
      return answer[0].text || '';
    }

    return false;
  }

  downloadPDF(){
    this.generatingPDF = true;
    this.open = true
    setTimeout(() => {
      this.processCanvas()
    }, 500)
  }

  private processCanvas() {
    const element: any = document.getElementById('resultSurvey');
    this.updateStyleSections(document, 'survey-section-detail', 'height: 100%;')
    this.updateStyleSections(document, 'comment-section-detail', 'height: 100%;')
    this.updateStyleSections(document, 'current_survey', 'height: 100%;')
    const options = {
      scrollY: 0,
      proxy: environment.proxyHtml2Canvas,
      onclone: (doc) => {
        const textAreas = doc.querySelectorAll('textarea')
        const textAreasLength = textAreas.length
        for (let i = 0; i < textAreasLength; i++) {
          const item = textAreas[i]
          if(item){
            const tmpObj = document.createElement("div");
            tmpObj.innerHTML = item.value;
            const objParent = item.parentNode;
            objParent.replaceChild(tmpObj, item);
          }
        }
      }
    }
    html2canvas(element, options).then(canvas => {
      this.updateStyleSections(document, 'survey-section-detail', '50vh')
      this.updateStyleSections(document, 'comment-section-detail', '23vh')
      this.updateStyleSections(document, 'current_survey', '100vh')
      this.open = false
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const doc = new jsPDF();
      let position = 0;
      doc.addImage(imgData, 'PNG',0, position, imgWidth, imgHeight, '', 'FAST');
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }

      let file_name = 'survey_result_'+this.survey.guest.email
      doc.save(file_name + '.pdf');
      this.generatingPDF = false;
    });
  }

  private updateStyleSections(doc, className: string, styleValue: string) {
    const currentSurvey = doc.getElementsByClassName(className)[0]
    if(currentSurvey) currentSurvey.setAttribute('style', styleValue);
  }

  get from() { return this.productName + '_guest_list'}
  get language() { return this.utilService.getCurrentLanguage(); }
  get languageId() { return this.utilService.getCurrentLanguageId()}
  get productName() { return this.util.getProductById(this.productId) }
  get fromTag() { return `${this.productName}_guest_modal`; }
  get isAirline() { return this.utilService.customerIsAirline };
  get isRetail() { return this.utilService.customerIsRetail };

  get titleBox() {
    if(this.isAirline) return 'followup.guests.info_passenger';
    if(this.isRetail) return 'followup.guests.info_client';
    return 'followup.guests.info_guest';
  }
}

@Component({
  selector: 'mh-surveys-answer-guest',
  template: ''
})
export class SurveysAnswerComponent{

  @Output()
  showingSurvey: EventEmitter<boolean> = new EventEmitter();
  modalRef: NgbModalRef;


  constructor(private modalService: NgbModal) { }

  ngOnInit(): void { }

  showModal(survey,productId='1',from=''){
    this.showingSurvey.emit(true);
    this.modalRef = this.modalService.open(SurveysAnswerModal,{
      windowClass: 'modal-xl modal-tables'
    });
    this.modalRef.componentInstance.setModal(survey,productId)
  }

  closeDialog(){
    this.modalService.dismissAll();
  }
}
