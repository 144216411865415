export class PublicConfig {
  active: boolean = true;
  id: number = 0;
  loop_finish: boolean = true;
  loop_finish_auto: boolean = true;
  loop_finish_time: number = 0;
  name: string = '';
  show_finish_button: boolean = true;
  show_tripadvisor: boolean = false;
  timeout: number = 0;
  source_information: {
    config_id: number,
    source: {
      id: number,
      source: string
    },
    show_logo?: boolean,
    background_color?: string,
    translates?: Translates[],
    show_cover: boolean,
    show_use_personal_data: boolean,
  };
  guest_action: {
    id: number,
    action: string
  };
  info_use_name: boolean;
  info_use_lastname: boolean;
  info_use_email: boolean;
  info_use_room: boolean;
  info_use_phone: boolean;
  info_use_reservation_id: boolean;
  info_use_country: boolean;
  info_use_checkin: boolean;
  info_use_checkout: boolean;
  info_use_guest_code: boolean;
  info_required_checkin: boolean;
  info_required_checkout: boolean;
  info_required_country: boolean;
  info_required_email: boolean;
  info_required_guest_code: boolean;
  info_required_lastname: boolean;
  info_required_name: boolean;
  info_required_phone: boolean;
  info_required_reservation_id: boolean;
  info_required_room: boolean;
  request_info: boolean;
  show_room_list: boolean;
  checkout_autofill_date: boolean;
}

interface Translates {
  config_id: number;
  language: {
    id: number,
    code: string
  };
  text: string;
}
export interface PublicConfigModel {
  publicConfig: PublicConfig,
  surveyId: number
}
