<mat-accordion class="clean">
  <mat-expansion-panel [expanded]="true" [hideToggle]="true">
    <mat-expansion-panel-header>
      <mat-panel-title class="{{displayBtn}}">
        <button class="btn btn-icon-left btn-filter">
          <mat-icon class="inline">filter_list</mat-icon>
          <span>{{'shared.filters.chain.select' | translate }}</span>
          <mat-icon class="inline arrow-up">arrow_drop_up</mat-icon>
          <mat-icon class="inline arrow-down">arrow_drop_down</mat-icon>
        </button>
      </mat-panel-title>
    </mat-expansion-panel-header>


    <div class="filters">
      <label class="inline">{{'shared.filters.chain.select_one_or_more' | translate }}</label>
      <button class="btn btn-link" (click)="selectedAll(true)">{{'commons.select_all' | translate }}</button>
      <button class="btn btn-link" (click)="selectedAll(false)">{{'commons.unselect_all' | translate }}</button>
    </div>


    <div class="row list-legends align-items-center" >
      <div class="col-3 legend-col" *ngFor="let data of lineChartData; let i=index">
        <div class="legend" (click)="toggleLegend(data)" [class.unselected]="data.hidden">
          <i class="fas fa-circle point-customer" [ngStyle]="{'color': lineChartColors[i]?.backgroundColor || ''}"></i>
          <div class="ellipsis">
            {{data.label}}
          </div>
        </div>
      </div>
    </div>

  </mat-expansion-panel>

</mat-accordion>
