import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CustomMatPaginatorIntl}         from '../../shared/utils/custom-mat-paginator-int';

import {
  FormsModule,
  ReactiveFormsModule
}                                  from '@angular/forms';

// i18n

import { TranslateModule } from '@ngx-translate/core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';

import { ChartsModule }            from 'ng2-charts';

import {
  FilterPipe,
  SanitizeHtmlPipe,
  QualifyScore,
  FillPipe,
  FormatScore,
  FormatNPS,
  FormatPercent,
  FormatDate,
  FormatNumber,
  ValidateShowData,
  HideCurrentName,
  CamelToSnake,
  QualifyOnlineNPS,
  WaitHtml,
  NameTranslate,
  ProtectEmail,
  ProtectQuestion,
  FormatCoverage,
  FormatBy,
  MillisecondsToUTCPipe
}                                  from '../../shared/pipe';

import {
  EqualValidator,
  FilterReviewDirective,
  OnlyNumber,
  FindAlternativesDirective,
  FindTranslateDirective,
}                                  from '../directives/index';


@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatChipsModule,
    MatInputModule,
    MatListModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatSliderModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatStepperModule,
    DragDropModule,

    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatCardModule,
    MatTreeModule,
    MatButtonModule
  ],
  declarations: [
    FilterPipe,
    SanitizeHtmlPipe,
    QualifyScore,


    FillPipe,
    FormatScore,
    FormatNPS,
    FormatPercent,
    FormatDate,
    FormatNumber,
    ValidateShowData,
    HideCurrentName,
    CamelToSnake,
    QualifyOnlineNPS,
    WaitHtml,
    NameTranslate,
    ProtectEmail,
    ProtectQuestion,
    FormatCoverage,
    FormatBy,
    MillisecondsToUTCPipe,

    EqualValidator,
    FilterReviewDirective,
    FindAlternativesDirective,
    OnlyNumber,
    FindTranslateDirective
  ],
  exports: [
    ChartsModule,

    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatChipsModule,
    MatInputModule,
    MatListModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatSliderModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatStepperModule,
    DragDropModule,

    FilterPipe,
    SanitizeHtmlPipe,
    QualifyScore,
    FillPipe,
    FormatScore,
    FormatNPS,
    FormatPercent,
    FormatDate,
    FormatNumber,
    ValidateShowData,
    HideCurrentName,
    CamelToSnake,
    QualifyOnlineNPS,
    WaitHtml,
    NameTranslate,
    ProtectEmail,
    ProtectQuestion,
    FormatCoverage,
    FormatBy,
    MillisecondsToUTCPipe,

    EqualValidator,
    FilterReviewDirective,
    FindAlternativesDirective,
    OnlyNumber,
    FindTranslateDirective,

    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatCardModule,
    MatTreeModule,
    MatButtonModule

  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
  ]
})
export class StyleBaseModule { }
