import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilService } from 'app/shared/util.service';
import { Product } from 'app/shared/model';
import { OrdersService } from 'app/prestay/orders/orders.service';

@Component({
  selector: 'mh-prestay-order-detail-reservation',
  templateUrl: './order-detail-reservation.component.html',
  styleUrls: ['./order-detail-reservation.component.scss'],
  providers : [OrdersService]
})
export class OrderDetailReservationComponent implements OnInit {

  @Input()
  viewType:string;

  @Input()
  order;

  @Input()
  guest;

  @Input()
  products:Product[] = [];

  @Input()
  money;

  language;
  language_id:number | any;

  headerTable:string[] = [
    'image',
    'service',
    'quantity',
    'comment',
    'price'
  ];

  dataSource: any = new MatTableDataSource([]);
  dataTable: any[]  = [];

  currency = 'USD';

  downloadingPDF = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private utilService: UtilService,  private ordersService : OrdersService) { }

  ngOnInit() {
    this.currency = this.utilService.currentHotel.currency.code;
    this.createTable();
  }

  createTable(){
    this.dataTable    = [];
    this.language_id  = this.utilService.getLanguageId();
    this.language     = this.utilService.getCurrentLanguage();

    this.order.reservations.forEach(reservation=>{
      const product = this.products.find(x=>x.properties[0].id === reservation.product_property.id)
      this.dataTable.push({
        'product'     : product,
        'reservation' : reservation
      })
    })

    this.dataSource = new MatTableDataSource(this.dataTable);
    const self = this;
    this.dataSource.sortingDataAccessor = function (data, sortHeaderId) {

      let value = data[sortHeaderId];
      if(sortHeaderId==='service'){
        value = self.utilService.findInputTranslate(data.product.translates, self.language, 'name');
      }

      if(['quantity','comment'].includes(sortHeaderId)){
        value = data.reservation[sortHeaderId]
      }

      if(sortHeaderId==='price'){
        value = data.product.properties[0].price;
      }

      if (typeof value === 'string' && !value.trim()) {
        return value;
      }
      return isNaN(+value) ? value : +value;
    };
    this.dataSource.sort = this.sort;
  }

  downloadPDF(){
    this.downloadingPDF = true;
    this.ordersService.downloadOrder(this.utilService.currentHotel.id, this.guest.id, this.order.id).subscribe(response=>{
      this.downloadingPDF = false;
    })
  }
}
