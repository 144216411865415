import RESOURCE_PRODUCT_DICT from './resource_product_dict'

export default [
  {
    name: 'type',
    options: ['create', 'edit', 'delete'],
    titleTranslate: 'filters.actions.title',
    optionTranslatePrefix: 'filters.actions.options.',
    optionTranslateSuffix: '',
  },
  {
    name: 'product',
    options: Object.keys(RESOURCE_PRODUCT_DICT),
    titleTranslate: 'filters.products.title',
    optionTranslatePrefix: 'filters.products.options.',
    optionTranslateSuffix: '',
  },
  {
    name: 'resourceName',
    options: ['ota', 'customer', 'user', 'alert', 'checkin_form', 'competitor', 'city',
      'ota', 'mailing', 'product', 'user', 'prestay_general',
      'summary_subscription'],
    titleTranslate: 'filters.resources.title',
    optionTranslatePrefix: 'logs.resources.',
    optionTranslateSuffix: '.title',
  },
  {
    name: 'user',
    options: [],
    titleTranslate: 'filters.users.title',
    optionTranslatePrefix: '',
    optionTranslateSuffix: '',
  },
]
