<article class="tw-flex tw-flex-col tw-relative">
  <i class="far fa-times tw-cursor-pointer tw-absolute tw-right-4 tw-top-3" (click)="close()"></i>
  <section class="tw-w-full tw-py-3 tw-flex tw-justify-center tw-bg-background-modal-title tw-rounded-t-[10px]">
    <h1 class="tw-font-medium tw-text-main-text tw-text-lg" [innerHtml]="('followup.summary.emails-pms.table.title' | translate)">
    </h1>
  </section>

  <section class="tw-flex tw-flex-col tw-items-center tw-py-3 tw-text-main-text">
    <span class="tw-text-lg">
      <ng-container *ngIf="type === 'restaurant'; else otherLabelTemplate">
        {{ 'followup.summary.emails-pms.table.legend_restaurant' | translate }}
      </ng-container>
      <ng-template #otherLabelTemplate>
        {{'followup.summary.emails-pms.table.legend' | translate: {guests: (tooltipTranslate.guests | translate), checkout: (tooltipTranslate.checkout | translate)} }}
      </ng-template>
    </span>
    <span class="tw-text-xl tw-font-bold">
      <span>{{currentDateFilter | translate}}</span>
    </span>
  </section>

  <section class="tw-flex tw-flex-col tw-items-center tw-py-3 {{status}} tw-text-white" [mhTooltip]="'followup.summary.emails-pms.tooltips.modal_qualify' | translate">
    <span class="tw-font-bold tw-text-xl">
      {{'followup.summary.emails-pms.table.valid_emails' | translate:({'count':(validEmailPercentage | formatPercent:true:false:true )})}}
    </span>
    <span class="tw-font-medium tw-text-lg">
      ({{guestsWithValidEmail}}/{{totalGuests}})
    </span>
  </section>

  <section class="tw-divide-y tw-px-10 tw-text-main-text tw-text-lg">
    <ng-template #tooltipTotalGuests>
      <div [innerHtml]="('followup.summary.emails-pms.tooltips.total_guests' | translate:{pms: (tooltipTranslate.pms | translate), guests: (tooltipTranslate.guests | translate)})"></div>
    </ng-template>
    <div class="tw-flex tw-justify-between tw-py-2" [ngbTooltip]="tooltipTotalGuests">
      <span class="tw-font-medium">{{'followup.summary.emails-pms.table.total_guest' | translate: {guests: (tooltipTranslate.guests | translate) } }}</span>
      <span class="tw-font-semibold">{{totalGuests}}</span>
    </div>

    <ng-template #tooltipTotalEmails>
      <div [innerHtml]="('followup.summary.emails-pms.tooltips.total_emails' | translate:{pms: (tooltipTranslate.pms | translate), guests: (tooltipTranslate.guests | translate)})"></div>
    </ng-template>
    <div class="tw-flex tw-justify-between tw-py-2" [ngbTooltip]="tooltipTotalEmails">
      <span class="tw-font-medium">{{'followup.summary.emails-pms.table.email_guest' | translate: {guests: (tooltipTranslate.guests | translate) } }}</span>
      <span class="tw-font-semibold">{{emailsCount}}</span>
    </div>

    <ng-template #tooltipTotalValidEmails>
      <div   [innerHtml]="('followup.summary.emails-pms.tooltips.total_valid_emails' | translate:{pms: (tooltipTranslate.pms | translate), guests: (tooltipTranslate.guests | translate)})"></div>
    </ng-template>
    <div class="tw-flex tw-justify-between tw-py-2" [ngbTooltip]="tooltipTotalValidEmails">
      <span class="tw-font-medium">{{'followup.summary.emails-pms.table.valid_guest' | translate: {guests: (tooltipTranslate.guests | translate) } }}</span>
      <span class="tw-font-semibold">{{guestsWithValidEmail}}</span>
    </div>
  </section>

</article>
