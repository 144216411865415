import { Pipe, PipeTransform } from '@angular/core'

import { Survey } from "app/shared/model/index";
import { FollowupOnsiteGuestFilter } from 'app/states/followup-onsite/followup-onsite.state';

@Pipe({ name: 'followupOnsiteRoomsFilter' })
export class FollowupOnsiteRoomsFilterPipe implements PipeTransform {

  transform(value: Survey[], filters: FollowupOnsiteGuestFilter): Survey[] {
    if(filters.rooms) {
      const filtered = value.filter(survey => !!filters.rooms.find(country => country === survey.guest.room.toString()) )
      if(filtered?.length > 0) return filtered
      else return value
    } else
      return value
  }
}
