import {Component, OnInit, Input, EventEmitter, Output, ViewChild, ChangeDetectorRef} from '@angular/core';
import {CustomerService} from "../../customer.service";
import {UtilService} from "../../util.service";
import {Hotel} from "../../model/hotel";
import {NgbDropdownConfig, NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import {ProfileService} from '../../../configuration/profile/profile.service';
import {Subject} from 'rxjs';
import {FormControl} from '@angular/forms';
import {startWith, map} from 'rxjs/operators';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { User } from 'app/shared/model';
import { CountryGroup } from 'app/shared/model/country_group.model';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';

const MIN_CHARS = 3;
const DELAY_KEY = 500;

@Component({
  selector: 'mh-find-customer',
  templateUrl: './find-customer.component.html',
  styleUrls: ['./find-customer.component.scss'],
  providers:[CustomerService, NgbDropdownConfig, ProfileService]
})

export class FindCustomerComponent implements OnInit {

  @Select(CustomersState.fullCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  selecteable= false;

  @Input()
  filterId = 'hotelsList';

  @Input()
  clientType: 'client' | 'all' | 'not client' | 'corporative' | 'prestay' = 'client';

  @Input()
  showAllOption = false;

  @Input()
  source: 'header' | 'section' = 'section';

  @Input()
  isSearcherMenuOpened = false;

  @Output()
  customer: EventEmitter<Hotel> = new EventEmitter();

  @Output()
  isReady: EventEmitter<boolean> = new EventEmitter();

  filterControl = new FormControl();
  filteredGroups:any;
  options = [];
  hotelList:Hotel[] = [];

  connectLoadHotels = new Subject<any>();
  current_user:User;

  show_toggle = true;
  waitingOptions = false;
  noResultsFlag = false;
  minCharsFlag = true;
  waiting = false;
  opened = false;

  errorFlag = false;

  corporativeGroup: any[] = []
  corporativeHotelList = []
  prestayClientList = []
  private timeCreating = setTimeout(_ => {},DELAY_KEY);
  customersStateSubscribe: Subscription;

  @ViewChild('inputAuto', { read: MatAutocompleteTrigger, static: false }) inputTrigger: MatAutocompleteTrigger;
  @ViewChild('inputAuto', { read: MatInput, static: false }) inputAuto: MatInput;
  @ViewChild('auto', { read: MatAutocomplete, static: false }) inputAutoComplete: MatAutocomplete;
  @ViewChild('myDrop', { static: false }) dropDown: NgbDropdown;


  constructor(
    private customerService:CustomerService,
    private utilService:UtilService,
    private config: NgbDropdownConfig,
    private cdr: ChangeDetectorRef,
    private store: Store
  ) {
    this.config.autoClose = false;
    this.current_user     = this.utilService.getCurrentUser()
    this.utilService.isAddANewHotel().subscribe(response=>{
      this.hotelList = [];
    })
    this.utilService.isCustomerChanged().subscribe(_=>{
      this.fillListCorporative();
    })
    this.setFilter();
    this.customersStateSubscribe = this.customersReady$.subscribe(customers => this.fillListCorporative());
  }


  setFilter(){
    this.waiting = true;
    this.minCharsFlag = !this.clientIsInMemory
    this.setSearcher();
  }

  setSearcher() {
    this.connectLoadHotels.next(this.utilService.currentUser.getAccessHotels());
    this.filteredGroups = this.filterControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => {
          if (this.clientIsCorporate && !name) {
            return this._filter('');
          }
          if(name) {
            return this._filter(name);
          } else {
            this.waitingOptions = false;
            return this.options.slice();
          }
        })
      );
    setTimeout(_ => {
      this.isReady.emit(!this.waiting);
      this.waiting = false;
    });
  }

  ngOnInit() {
    this.setFilter();
    this.fillListCorporative()
    this.fillPrestayList()
  }

  ngOnDestroy(){
    this.customersStateSubscribe.unsubscribe();
  }

  fillListCorporative() {
    if (!this.clientIsCorporate) return;

    const customers = this.store.selectSnapshot(state => state.customers.fullCurrentChain);

    if (customers.length) {
      this.errorFlag = false;
      this.waitingOptions = true;
      this.corporativeHotelList = customers
      const groups = this.setCustomersCountryGroups(customers);
      groups.forEach(group => {
        this.corporativeGroup.push(group)
      });
      this.waitingOptions = false;
    }
  }

  fillPrestayList() {
    if (this.clientType !== 'prestay') return;

    this.errorFlag = false;
    this.waitingOptions = true;
    this.customerService.getPrestayCustomers().subscribe(
      (data:any) => {
        if (data && data.length){
          this.prestayClientList = data;
          this.setCustomersCountryGroups(data)
          this.waitingOptions = false;
        } else {
          this.errorFlag = true;
          this.waitingOptions = false;
        }
      },
      _ => {
        this.errorFlag = true;
        this.waitingOptions = false;
      }
    )
  }

  ngOnChanges({isSearcherMenuOpened}) {
    this.setFilter();
    if (isSearcherMenuOpened?.currentValue) {
      this.setFocus();
    }
  }

  setFocus() {
    this.inputAuto.focus();
  }

  checkName(name: string){
    if(!isNaN(Number(name))) {
      return true;
    }

    if (this.clientIsCorporate){
      this.minCharsFlag = false
      return true
    }

    if(name.length < MIN_CHARS) {
      this.minCharsFlag = true;
      return false;
    }
    return true;
  }

  private _filter(name: string) {
    this.noResultsFlag = false;
    let options: any[] = [];

    if(!this.checkName(name)) {
      return options;
    }
    this.waitingOptions = true;

    const showPrivates = ['full-admin','mh-private-admin'].includes(this.current_user.role);

    if (this.clientIsCorporate) {
      const result = this._filterCorporate(name);
      this.waitingOptions = false;

      return result;
    } else if (this.clientIsPrestay) {
      const result = this._filterPrestay(name)
      this.waitingOptions = false;

      return result;
    } else {
      clearTimeout(this.timeCreating);
      this.timeCreating = setTimeout(_ => {
        this.customerService.findByWord(name, this.clientType,showPrivates).subscribe(response=> {
          this.waitingOptions = false;
          if(response!==null) {
            const groups = this.setCustomersCountryGroups(response);
            groups.forEach(group => {
              options.push(group)
            });
          } else {
            this.noResultsFlag = true;
            return options;
          }
          this.noResultsFlag = false;
        });
      }, DELAY_KEY);
    }
    this.minCharsFlag = false;
    return options;
  }

  private _filterPrestay(name: string) {
    if (!this.errorFlag){
      const filteredClients = this.clientList.filter(
        (hotel: any)=> (hotel.name.toLowerCase() + hotel.id).includes(name.toLowerCase())
      )
      this.corporativeGroup = this.setCustomersCountryGroups(filteredClients);
      this.noResultsFlag = this.corporativeGroup.length === 0;
      return this.corporativeGroup;
    }
  }

  private _filterCorporate(name: string) {
    if (!this.errorFlag){
      const filteredClients = this.clientList.filter(
        (hotel: any)=> (hotel.name.toLowerCase() + hotel.id).includes(name.toLowerCase())
      )
      this.corporativeGroup = this.setCustomersCountryGroups(filteredClients);
      this.noResultsFlag = this.corporativeGroup.length === 0;
      return this.corporativeGroup;
    }
  }

  setCustomersCountryGroups(response){
    let customers_groups: any[] = [];
    let countries = Array.from(new Set(response.map(x=>x.country_code)));
    countries.forEach(country => {
      let group = new CountryGroup(country);
      group.customers = response.filter(x=>x.country_code === country).sort(function(a,b) {return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0);} );
      customers_groups.push(group)
    });
    customers_groups = this.utilService.sortArray(customers_groups,'country_code');
    return customers_groups;
  }

  toggleSearch($dropDown){
    if($dropDown.open){
      this.filterControl.setValue('');
      this.setFilter();
      this.opened = true;
      setTimeout(_ => {
        this.inputAuto.focus();
      });
    }

    if(!$dropDown._open) {
      this.opened = false;
    }
  }

  updateCustomer($event){
    const customers = ($event === 'all')
      ? this.corporativeHotelList
      : $event;
    this.customer.emit(customers);
    if (!this.isFromHeader) {
      this.dropDown.close();
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  get clientList() { return this.clientIsCorporate ? this.corporativeHotelList : this.prestayClientList;}
  get clientIsCorporate() { return this.clientType === 'corporative'; }
  get clientIsPrestay() { return this.clientType === 'prestay'; }
  get clientIsInMemory() { return this.clientIsCorporate || this.clientIsPrestay; }
  get isFromHeader() { return this.source === 'header' }
}
