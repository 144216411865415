import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilService } from 'app/layouts/main';
import { ReviewService } from 'app/shared/review/review.service';
import { Review } from 'app/shared/model';

@Component({
  selector: 'widget-translate-options',
  templateUrl: './translate-options.component.html',
  styleUrls: ['./translate-options.component.scss']
})
export class TranslateOptionsComponent implements OnInit {

  @Input()
  review:Review;

  @Input()
  showTranslations = false;

  @Output()
  reviewTranslated = new EventEmitter();

  @Output()
  waitTranslate = new EventEmitter;

  hideOptions = true;
  activeTranslateOptions = false;

  availableLanguages: any[] = [];
  currentLanguage: any;
  currentLanguageISO:string;
  languageReview:string;
  selectedLanguage:string;
  selectLanguageValue:string;

  constructor(
    private utilService: UtilService,
    private reviewService: ReviewService) {

    this.utilService.isLanguageChanged().subscribe(_=>{
      this.init();
    })
   }

  ngOnInit() {
    this.init();
  }

  init(){
    this.availableLanguages = this.utilService.translateLanguages;
    this.currentLanguageISO = this.utilService.getCurrentLanguage();
    this.currentLanguage = this.utilService.getLanguageId();
    this.languageReview = this.review.guest.language.code;
    this.availableLanguages = this.availableLanguages.filter(x=>x!==this.languageReview);
    this.setSelectedLanguage(true);
    this.listenerHideOptions();
  }

  setSelectedLanguage(onInit:boolean, value?){
    if(onInit) {
      this.selectedLanguage = this.languageReview !== this.currentLanguageISO ? this.currentLanguageISO : '';
    } else {
      this.selectedLanguage = value;
    }
  }


  selectLanguage() {
    this.waitTranslate.emit(true);

    let sourceLanguage;
    let targetLanguage
    let flagTranslate = false;
    if(this.showTranslations) {
      sourceLanguage = this.currentLanguage;
      targetLanguage = this.utilService.languages.filter(x=>x.code===this.selectedLanguage);
      flagTranslate = targetLanguage.length === 0 ? false : true;
      if(!flagTranslate) {
        this.waitTranslate.emit(false);
        return;
      }
      targetLanguage = targetLanguage[0].id;
    } else {
      sourceLanguage = this.review.guest.language.id;
      targetLanguage = this.currentLanguage;
      flagTranslate = true;
    }

    if(flagTranslate) {
      this.reviewService.translateReview(this.review.id, sourceLanguage, targetLanguage, '3', this.review.title, this.review.description).subscribe(response => {
        this.reviewTranslated.emit(response);
      });
    }
  }

  listenerHideOptions(){
    document.addEventListener("click", $evt => {
      let exceptions: any[] = [];
      let elements: any[] = [];
      let spanElements: any[] = [];
      const clicked:any = $evt.target;
      const targetElements:any = document.getElementsByClassName("translate-options");
      const exceptionElements = document.getElementsByClassName("translate_widget");
      const spanOptionsElements = document.getElementsByClassName("mat-option-text");

      elements = Array.from(exceptionElements);
      spanElements = Array.from(spanOptionsElements);
      exceptions = exceptions.concat(elements);
      exceptions = exceptions.concat(spanElements);
      let flag = false;

      for(let el of targetElements){
        if(el.contains(clicked)) {
          flag = true;
        }
      }

      if(!exceptions.includes(clicked) && !flag) {
        this.hideOptions = true;
        this.activeTranslateOptions = false;
      }
    });
  }

  toggleTranslateOptions(){
    this.activeTranslateOptions = !this.activeTranslateOptions;
    this.hideOptions = !this.hideOptions;
  }

  hasDescription(){
    return this.review.description && this.review.description.length > 1;
  }

}
