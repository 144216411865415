import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../shared/util.service';
import { ProfileService } from './profile.service';
import { User, UserProfile, CustomerProfile } from '../../shared/model/index';
import { SessionService, AuthenticationService } from 'app/_services';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CreateUserMainModalComponent } from 'app/new-fs/gestor-usuarios/create-user/create-user-main-modal/create-user-main-modal.component';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { CorecLibSuccessModalComponent } from 'app/core-lib/dialogs/success-modal/success-modal.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-configuration-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['../../../assets/scss/configuration/profile.scss'],
  providers: [ProfileService],
})
export class ProfileComponent implements OnInit {
  currentProfile: User;
  userProfile: UserProfile;
  customer: CustomerProfile;
  submittedDataUser = false;
  is_super_admin: boolean;
  is_admin: boolean;
  isUserPage = true;
  userAdapter;
  userIds;
  isGlobal;
  setupType;

  constructor(
    private utilService: UtilService,
    private sessionService: SessionService,
    private authenticationService: AuthenticationService, //part of the hack
    private route: ActivatedRoute,
    private modalService: ModalService,
    private usersManagmentService: UsersManagmentService,
  ) {}

  ngOnInit() {
    this.checkUsers();
    this.setPage();
    this.updateUserProfile();
    this.updateHotel();
    this.updateIsSuperAdmin();
    this.updateIsAdmin();

    this.usersManagmentService.findUser(this.currentProfile.email).subscribe((user) => (this.userAdapter = user));
  }

  checkUsers() {
    const queryParams: ParamMap = this.route.snapshot.queryParamMap;
    this.userIds = queryParams.get('users');
    this.isGlobal = queryParams.get('isGlobal') === 'true';
    this.setupType = queryParams.get('setupType');
  }

  setPage() {
    this.route.queryParams.subscribe((params) => {
      const page = params.page;
      this.isUserPage = page === 'profile';
    });
  }

  canSetDemo() {
    return this.currentProfile.role !== 'mh-demo-admin' && this.is_super_admin;
  }

  updateIsSuperAdmin() {
    this.is_super_admin = (this.currentProfile && this.currentProfile.isSuperAdmin()) || false;
  }

  updateIsAdmin() {
    this.is_admin = (this.currentProfile && this.currentProfile.isAdmin()) || false;
  }

  updateUserProfile(): void {
    this.currentProfile = this.utilService.getCurrentUser();
    this.userProfile = new UserProfile(
      this.currentProfile.id,
      this.currentProfile.admin,
      this.currentProfile.customers,
      this.currentProfile.superAdmin,
      this.currentProfile.userName,
      this.currentProfile.profile,
      this.currentProfile.send_mail_new_user,
      this.currentProfile.profile.profile_photo,
    );

    this.userProfile.profile.company_position = this.currentProfile.companyPosition;
    this.userProfile.profile.language_code = this.currentProfile.languageCode || 1;
  }

  updateHotel(): void {
    const hotel = this.utilService.getCurrentHotel();
    const accesses = this.currentProfile.access.map((access) => ({
      ...access,
      value: hotel.accessIds.includes(access.id),
    }));

    this.customer = new CustomerProfile(
      hotel.id,
      hotel.name,
      0,
      hotel.followup,
      hotel.online,
      hotel.onsite,
      accesses,
      hotel.collect,
    );
  }

  editUser() {
    const modalComponent = CreateUserMainModalComponent;
    const modalClass = ['overlay-panel', 'xlg', '!tw-h-auto', '!tw-overflow-y-auto'];

    const dialogRef = this.modalService.open(
      modalComponent,
      { data: { user: this.userAdapter, isIndividualActions: true, action: 'edit', isFromProfilePage: true } },
      modalClass,
    );
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((value) => {
        if (value) {
          this.modalService.open(CorecLibSuccessModalComponent, null, ['overlay-panel', 'sm']);
        }
      });
  }

  // NOTE: ESTO ES UN HACK! no es correcto en temas de responsabilidades
  // lo que vamos a hacer, es fetchear el perfil del usuario de nuevo, y empujarlo a la sesion
  // si bien esto debiese hacerlo la sesion, prefiero ordenar esa logica antes de complejizarla mas
  afterUpdateUser(user: UserProfile) {
    this.authenticationService.profile(user.id).subscribe((profile: any[]) => {
      this.sessionService.setCurrentUserProfile(new User().createFromApi(profile));
      this.updateUserProfile();
    });
  }
}
