<article>
  <!-- Slider of news -->
  <mh-core-lib-video-image-slider
    [items]="videos" 
    [currentSlide]="currentSlide"
    (updateCurrentSlide)="handleCurrentSlideUpdate($event)"
    (resetTimer)="handleResetTimer()"
  ></mh-core-lib-video-image-slider>

  <!-- Buttons section -->
  <section class="tw-flex tw-justify-center tw-pt-3 tw-pb-7 tw-mt-2">
    <ng-container *ngIf="data?.simpleClose; else buttonsTemplate">
      <mh-core-lib-button customClass="!tw-min-w-[160px] !tw-w-auto" [text]="'commons.close' | translate" [isSecondary]="true" class="tw-mr-2" (click)="handleClosePopup()"></mh-core-lib-button>
    </ng-container>
    
    <ng-template #buttonsTemplate>
      <mh-core-lib-button customClass="!tw-min-w-[160px] !tw-w-auto" [text]="videos[currentSlide]?.translates[0]?.negative_button" [isSecondary]="true" class="tw-mr-2" (click)="handleSentimentClick(videos[currentSlide], 'OMITTED')"></mh-core-lib-button>
      <mh-core-lib-button customClass="!tw-min-w-[160px] !tw-w-auto" [text]="videos[currentSlide]?.translates[0]?.positive_button" [isPrimary]="true" (click)="handleSentimentClick(videos[currentSlide], 'READ')"></mh-core-lib-button>
    </ng-template>
  </section>
</article>
