<div class="d-flex justify-content-center align-items-end">
  <ng-container>
    <div [ngbTooltip]="('models.preckeckin-guest.companion_quantity' | translate)">
      <ng-container *ngIf="guest.companion_quantity === 0">
        <i class="far fa-user-slash guest-icon"></i>
      </ng-container>
      <ng-container *ngIf="guest.companion_quantity === 1">
        <i class="fal fa-user guest-icon"></i>
      </ng-container>
      <ng-container *ngIf="guest.companion_quantity > 1">
        <i class="fal fa-users guest-icon"></i>
      </ng-container>
      <span class="guest-quantity">{{guest.companion_quantity}}</span>
    </div>
  </ng-container>
  <ng-container>
    <div [ngbTooltip]="('models.preckeckin-guest.attachments_quantity' | translate)">
      <ng-container *ngIf="guest.attachments_quantity >= 0">
        <mat-icon class="attachment-icon">attach_file</mat-icon>
      </ng-container>
      <span class="attachment-quantity">{{guest.attachments_quantity}}</span>
    </div>
  </ng-container>
</div>
