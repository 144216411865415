import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UtilService } from "app/shared/util.service";
import { Access, UserProfile, CustomerProfile, Role, Department } from "app/shared/model/index";
import { UsersService } from "app/configuration/users/users.service";
import { ConfigurationService } from 'app/configuration/configuration.service';
import PhoneCodesWorldWide  from 'app/shared/utils/phones-codes-world-wide';
import { User, Language } from 'app/shared/model/index';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CustomValidators } from 'app/shared/custom-validators'

@Component({
  selector: 'mh-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: [
    './edit-user-profile.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditUserProfile {
  PIPEDRIVE_URL = 'https://myhotel.pipedrive.com/person/'

  validates = [
    'first_name',
    'last_name',
    'email',
    'company_position',
    'role',
    'department',
    'phone_number',
    'mobile_number',
    'language_id'
  ];
  waiting = false;

  //tslint:disable-next-line:no-input-rename
  @Input('user')
  inputUser: UserProfile;

  @Input()
  viewType: string = "profile";

  @Output() waitingState = new EventEmitter<boolean>();
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() onUpdateUser = new EventEmitter<UserProfile>();

  access: Access[] = [];
  profileForm: FormGroup;
  currentUser: User;

  user: UserProfile = new UserProfile();
  isAdmin = false;
  is_super_admin = false;
  send_mail_new_user = false;
  chainFilterAssign:CustomerProfile[] = [];
  languages: Language[] = [];
  phones = PhoneCodesWorldWide;
  roles:Role[] = []
  departments:Department[] = []
  waiting_base = true;
  loadingBase = true
  loadingSave = false
  loadingForm = true
  showSuccess = false
  my_hotel_demo: any = null
  MY_HOTEL_DEMO_HIDDEN = 12;
  message: string
  messageClass: string
  messageErrorSuggest: string;

  constructor(
    private utilService: UtilService,
    private usersService: UsersService,
    private configurationService: ConfigurationService,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.languages = this.utilService.languages;
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.waiting = false;
    this.waitingState.emit(this.waiting);

    this.access = this.utilService.currentUser.access;
    this.currentUser = this.utilService.getCurrentUser();
    this.loadingBase = true
    this.usersService.base().subscribe(
      (response: any) => {
        if (response !== null && response.roles) {
          this.roles = response.roles;
          this.departments = response.deparments;
          if (this.viewType === 'new') {
            this.loadingBase = false
            this.user = new UserProfile();
            this.createForm();
          } else {
            this.usersService.getUser(this.inputUser.id).subscribe(
              (response: any) => {
                this.user = response;
                this.createForm();
                this.loadingBase = false
              }
            )
          }
        } else {
          this.loadingBase = false
          this.utilService.sendAlert('errors.default', 'error', 'commons.error', 'modal');
        }

      },
      _ => {
        this.utilService.sendAlert('errors.default', 'error', 'commons.error', 'modal');
      }
    )
  }

  ngOnChanges() {
    if (this.showSuccess) return;

    this.createForm();
    if (this.viewType !== 'new') {
      for (let item of ['email']) {
        let index = this.validates.findIndex(x => x === item);
        if (index > -1) this.validates.splice(index, 1);
      }
    }
  }

  createForm() {
    this.loadingForm = true
    this.canEditCustomer();
    this.is_super_admin = this.utilService.currentUser.isSuperAdmin();

    if (this.is_super_admin === false) {
      this.user.send_mail_new_user = true;
    } else {
      if (!this.validates.includes('pipedrive_id')) {
        this.validates = ['pipedrive_id'].concat(this.validates)
      }
    }

    this.my_hotel_demo = this.inputUser.customers.find(x => x.id === this.MY_HOTEL_DEMO_HIDDEN)

    if (this.inputUser.customers) {
      this.user.customers = this.inputUser.customers.filter(x => x.id !== this.MY_HOTEL_DEMO_HIDDEN);
    }

    this.refreshFilter()

    this.profileForm = this.fb.group({
      pipedrive_id: [this.user.pipedrive_id],
      first_name: [this.user.first_name, [Validators.required, Validators.maxLength(25), Validators.minLength(3)]],
      last_name: [this.user.last_name, [Validators.required, Validators.maxLength(25), Validators.minLength(3)]],
      email: [this.user.email, [Validators.required, CustomValidators.email]],
      company_position: [this.user.company_position, [Validators.maxLength(50), Validators.required]],
      role: [this.user.role, [Validators.required]],
      department: [this.user.department, [Validators.required]],
      phone_country_code: [this.user.phone_country_code],
      phone_number: [this.user.phone_number],
      mobile_country_code: [this.user.mobile_country_code],
      mobile_number: [this.user.mobile_number],
      customers: this.buildCustomers(),
      language_id: [this.user.language_id, [Validators.required]],
    });
    if (this.user.department) {
      this.profileForm!.get('department')!.setValue(this.departments.find(m => m.id === this.user.department.id))
    }
    if (this.user.role) {
      this.profileForm!.get('role')!.setValue(this.roles.find(role => role.id === this.user.role.id))
    }
    if (this.viewType === 'new') {
      this.profileForm!.get('company_position')!.setValidators([Validators.required, Validators.maxLength(50)])
      this.profileForm!.get('role')!.setValidators(Validators.required)
      this.profileForm!.get('department')!.setValidators(Validators.required)
    }
    this.loadingForm = false
  }

  setSelectlValue($event, key) {
    if ($event) this.profileForm.controls[key].setValue($event.original, { emitEvent: true });
  }

  buildCustomers() {

    let customers_not_delete: any[] = [];
    if (this.user.customers) {
      customers_not_delete = this.user.customers.map(customer => {
        return this.fb.control(!customer.deleted);
      });
    }

    let customer = this.user.super_admin ? [] : customers_not_delete;
    if (this.viewType === 'new' && customer.length === 0) this.addCustomer(this.utilService.currentHotel);
    return this.fb.array(customer);
  }

  get name() { return this.profileForm.get('name'); }
  get last_name() { return this.profileForm.get('last_name'); }
  get company_position() { return this.profileForm.get('company_position'); }
  get mobil_phone() { return this.profileForm.get('mobil_phone'); }
  get language_code() { return this.profileForm.get('language_code'); }
  get email() { return this.profileForm.get('email'); }
  get customers() { return this.profileForm.get('customers'); }
  get department() { return this.profileForm.get('department'); }
  get role() { return this.profileForm.get('role'); }


  isEmpty($val): boolean {
    if ($val.value === "" || $val.value == null || $val.value === undefined) return true;
    return false;
  }

  setNewStateCustomers(customers: any[] = []) {
    if (this.user.super_admin) {
      this.user.customers = [];
    } else {
      for (let i = 0; i < customers.length; ++i) {
        this.user.customers[i].access = this.createAccess();
      }
    }
  }

  createAccess() {
    let activeAccess = this.access.map(x => x.id).join(',');
    let activesParser = activeAccess.split(',');
    let actives = activesParser.length > 0 ? activesParser.map(x => parseInt(x)) : [];
    let accessResult: any[] = [];
    for (let access of this.access) {
      access.value = actives.includes(access.id)
      accessResult.push(access)
    }
    return accessResult;
  }

  saveData() {
    const customers = this.user.customers;
    this.setNewStateCustomers(customers);
    if(!this.profileForm.invalid)
      this.viewType === 'new' ? this.createUser() : this.updateUser();
    this.validateOnSubmit();
  }

  updateUser() {
    this.showSuccess = false
    this.loadingSave = true
    let modelSave: ModelSave = {
      id: null,
      customers: [],
      flag_admin: null,
      language_id: null,
      username: null,
      profile_photo: this.user.profile_photo,
      profile_photo_raw: this.user.profile_photo_raw,
    }

    Object.assign(modelSave, this.profileForm.value)

    modelSave.id = this.user.id
    modelSave.customers = this.chainFilterAssign
    modelSave.username = this.user.username
    modelSave.profile_photo = this.user.profile_photo
    if (this.my_hotel_demo) {
      modelSave.customers = modelSave.customers.concat([this.my_hotel_demo]);
    }

    modelSave.flag_admin = this.user.flag_admin
    modelSave.language_id = this.profileForm.value.language_id

    this.usersService.updateUser(modelSave).subscribe(
      (response: any) => {
        if (response['message'] !== undefined) {
          let message = response['message'];
          this.configurationService.alert = typeof (response['message']) === 'string' ? [message] : message;
        } else {
          this.showSuccess = true
          if (this.user.customers) {
            this.user.customers = this.user.customers.filter(x => x.id !== this.MY_HOTEL_DEMO_HIDDEN);
          }
        }
        this.loadingSave = false
        this.onUpdateUser.emit(this.user);
      },
      _ => {
        this.loadingSave = false
        this.utilService.sendAlert('errors.default', 'error', 'commons.error', 'modal');
      }
    )
  }

  setUserType($slide) {
    this.user.flag_admin = $slide.checked
  }

  createUser() {
    this.showSuccess = false
    this.loadingSave = true
    let modelSave: ModelSave = {
      id: null,
      customers: [],
      flag_admin: null,
      language_id: null,
      username: null,
      send_mail_new_user: this.user.send_mail_new_user,
      profile_photo: this.user.profile_photo,
      profile_photo_raw: this.user.profile_photo_raw,
    }
    Object.assign(modelSave, this.profileForm.value)
    modelSave.customers = this.chainFilterAssign
    modelSave.flag_admin = this.user.flag_admin
    modelSave.username = this.profileForm!.get('email')!.value;
    modelSave.language_id = this.profileForm.value.language_id
    this.usersService.createUser(modelSave).subscribe(
      (response: any) => {
        if (response['message'] !== undefined) {
          this.handleMessage('errors.login.something_went_wrong','danger')
        } else {
          this.user.id = response.id
          this.user.customers = response.customers
          this.utilService.refreshUsers(this.user)
          this.showSuccess = true;
          this.handleMessage('models.user.data_saved_correctly','success')
          this.openUserCreatedMsg();
        }
        this.loadingSave = false;
      }, error => {
        if( error !== null && (error?.code === 6001 || error?.code === 6002)) {
          this.messageErrorSuggest = error?.message;
          const msgText = (error.code === 6001) ?
            'errors.user.6001' : 
            'errors.user.6002';
          this.handleMessage(msgText, 'danger')
        } else if (error !== null && error?.status === 400) {
          this.handleMessage('errors.user.the_username_already_exists_for_another_user','danger')
        } else {
          this.handleMessage('errors.login.something_went_wrong','danger')
        }
        this.loadingSave = false;
      })
  }

  validateOnSubmit() {
    Object.keys(this.profileForm.controls).forEach(control => {
      const field = this.profileForm.get(control);
      field!.markAsTouched({onlySelf: true});
    });
  }

  private handleMessage(msgText: string, msgClass: string) {
    this.message = msgText
    this.messageClass = `alert alert-${msgClass}`;
  }

  imageChanged(response) {
    this.user.profile_photo_raw = response
  }

  setMailNewUser($slide) {
    this.user.send_mail_new_user = $slide.checked
  }

  getGrid(key) {
    return ['phone', 'language'].includes(key) ? '6' : '12';
  }
  getInputType(key) {
    if (key === "email") return "email";
    if (key === "phone") return "phone";
    return "text";
  }

  canEditCustomer() {
    this.isAdmin = this.utilService.currentUser.isAdmin();
  }
  addCustomer($event) {
    if (Array.isArray($event)) {
      $event.forEach(customer => {
        let index = this.user.customers.findIndex(x => x.id === customer.id)
        if (index >= 0) {
          this.user.customers[index].deleted = false;
        } else {
          let hotel = this.createCustomerProfile(customer)
          this.user.customers.push(hotel);
        }
      })
    } else {
      let index = this.user.customers.findIndex(x => x.id === $event.id)
      if (index >= 0) {
        this.user.customers[index].deleted = false;
      } else {
        let hotel = this.createCustomerProfile($event)
        this.user.customers.push(hotel);
      }
    }
    this.refreshFilter()
  }

  addAll($customers) {
    for (let customer of $customers) {
      this.addCustomer(customer);
    }
  }

  deleteCustomer(customer) {
    customer.deleted = true;
    this.user.customers = this.utilService.sortArray(this.user.customers, 'deleted')
    this.refreshFilter()
  }

  refreshFilter() {
    if (this.user.customers) {
      this.chainFilterAssign = this.user.customers.filter(x => !x.deleted)
    }
  }

  createCustomerProfile(customer, deleted = false) {
    return new CustomerProfile(customer.id, customer.name, deleted, customer.followup, customer.online, customer.onsite, customer.access, customer.collect)
  }

  async openUserCreatedMsg() {
    const seconds = 3;
    if(this.message) {
      const messageTranslated: string = await this.translate.get(this.message).toPromise();
      this._snackBar.open(messageTranslated, '', {
        duration: seconds * 1000,
      })
    }

  }

  get isRetail() { return this.utilService.customerIsRetail }
  get isAirline() { return this.utilService.customerIsAirline }
  get customerType() {
    if(this.isRetail) return 'commons.store';
    if(this.isAirline) return 'commons.airline';
    return 'commons.hotel';
  }

  loading() {
    return this.loadingBase || this.loadingForm
  }
}

interface ModelSave {
  id: any,
  customers: any,
  flag_admin: any,
  language_id: any,
  username: any,
  profile_photo: any,
  profile_photo_raw: any,
  send_mail_new_user?: any
}
