export class StoreReviewsList {
  static readonly type = '[Online Reviews] Store Reviews List';
  constructor(public reviews: any) {}
}

export class ChangeOTAFilter {
  static readonly type = '[Online Reviews] Change OTA Filter';
  constructor(public otaFilter: any) {}
}

export class ChangeAnswerFilter {
  static readonly type = '[Online Reviews] Change Answer Filter';
  constructor(public answerFilter: any) {}
}

export class ChangeNPSFilter {
  static readonly type = '[Online Reviews] Change NPS Filter';
  constructor(public npsFilter: any) {}
}

export class ChangeQualificationFilter {
  static readonly type = '[Online Reviews] Change Qualification Filter';
  constructor(public qualificationFilter: any) {}
}

export class ChangeReasonFilter {
  static readonly type = '[Online Reviews] Change Reason Filter';
  constructor(public reasonFilter: any) {}
}

export class ChangeLanguageFilter {
  static readonly type = '[Online Reviews] Change Language Filter';
  constructor(public languageFilter: any) {}
}

export class UpdateReviewsFilterForApi {
  static readonly type = '[Online Reviews] Change new filter for API';
  constructor(public filterForApi: any) {}
}

export class ClearFilterForApi {
  static readonly type = '[Online Reviews] Clear filter for API';
}

export class FilterClear {
  static readonly type = '[Online Reviews] Filter Clear';
}
