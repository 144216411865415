<button class="tw-text-2xs tw-text-notification-text tw-bg-gray-300 tw-rounded-full tw-w-16 tw-h-5 tw-p-[1px]" *ngIf="userIds?.length === 1" (click)="goToBack()">{{'commons.exit' | translate }}</button>

<ng-container *ngIf="canSetDemo && !userIds; else configurarion">
  <profile-superadmin-properties [currentProfile]="currentProfile"></profile-superadmin-properties>
</ng-container>
<ng-template #configurarion>
  <ng-container *ngIf="usersNumber > 1; else individualConfig">
    <article class="tw-w-4/5">
      <ng-container *ngIf="setupType === 'alert_setup'">
        <ng-template *ngTemplateOutlet="configAlerts" ></ng-template>
      </ng-container>
      <ng-container *ngIf="setupType === 'report_setup'">
        <ng-template *ngTemplateOutlet="configReports" ></ng-template>
      </ng-container>
    </article>
  </ng-container>
</ng-template>

<ng-template #individualConfig>
  <section>
    <!-- Tabs -->
    <article class="tw-w-4/5">
      <mh-core-lib-tabs [className]="'!tw-px-10 !tw-py-3'" [selectedTab]="selectedIndex">
        <mh-core-lib-tab [title]="'configuration.profile.alerts' | translate">
          <div class="tw-pt-4">
            <ng-container *ngTemplateOutlet="configAlerts"></ng-container>
          </div>
        </mh-core-lib-tab>
        <mh-core-lib-tab [title]="'configuration.profile.reports' | translate">
          <div class="tw-pt-4">
            <ng-container *ngTemplateOutlet="configReports"></ng-container>
          </div>
        </mh-core-lib-tab>
      </mh-core-lib-tabs>
    </article>

  </section>
</ng-template>

<ng-template #configAlerts>
  <div *ngIf="loading" class="tw-absolute tw-h-full tw-w-full tw-bg-white tw-z-50">
    <mh-core-lib-loader-wrapper
      [type]="'spinner'"
      [loading]="loading"
      className="tw-self-center"
      spinnerClassName="tw-text-[50px]">
    </mh-core-lib-loader-wrapper>
  </div>

  <ng-container *ngIf="userIds?.length > 1">
    <ng-container *ngTemplateOutlet="massiveConfigTemplate"></ng-container>
  </ng-container>

  <!-- Titles -->
  <div class="tw-relative tw-overflow-x-auto">
    <table class="reports-table tw-w-full tw-table-fixed tw-text-base tw-font-bold tw-text-left tw-text-gray-900 tw-bg-white tw-py-6">
        <thead>
            <tr>
                <th scope="col" class="tw-pl-4 tw-py-5">
                    {{ 'configuration.profile.module' | translate }}
                </th>
                <th *ngFor="let source of sources" scope="col" class="tw-py-5" [ngClass]="{'tw-text-gray-400': !source.enable}">
                    <span *ngIf="source.enable" [mhTooltip]="source?.tooltip ? (source.tooltip | translate) : null">{{ source.name }}</span>
                </th>
            </tr>
        </thead>
    </table>
  </div>

  <!-- Accordions -->
  <div>
    <mh-core-lib-nested-accordion
      [items]="accordionAlertItems"
      [options]="sources"
      [npsOptions]="npsOptions"
      [hideMassiveActions]="usersNumber > 1"
      (nestedDataChanged)="onNestedAccordionDataChanged($event)">
    </mh-core-lib-nested-accordion>
  </div>

  <div class="tw-my-5 tw-flex tw-gap-6 tw-justify-end" *ngIf="editMassiveAlerts?.length > 0">
    <mh-core-lib-button [isSecondary]="true" [text]="'commons.cancel' | translate" (clicked)="getData()"></mh-core-lib-button>
    <mh-core-lib-button [text]="'forms.save' | translate" [isDefault]="true" (click)="saveMassiveAlerts()"></mh-core-lib-button>
  </div>
</ng-template>


<ng-template #configReports>
  <div *ngIf="loading" class="tw-absolute tw-h-full tw-w-full tw-bg-white tw-z-50">
    <mh-core-lib-loader-wrapper
      [type]="'spinner'"
      [loading]="loading"
      className="tw-self-center"
      spinnerClassName="tw-text-[50px]">
    </mh-core-lib-loader-wrapper>
  </div>

  <ng-container *ngIf="userIds?.length > 1">
    <ng-container *ngTemplateOutlet="massiveConfigTemplate"></ng-container>
  </ng-container>

  <!-- Titles -->
  <div class="tw-relative tw-overflow-x-auto tw-mb-4">
    <table class="reports-table tw-w-full tw-table-fixed tw-text-base tw-font-bold tw-text-left tw-text-gray-900 tw-bg-white tw-py-6">
        <thead>
            <tr>
                <th scope="col" class="tw-pl-4 tw-pr-6 tw-py-5">
                  {{ 'configuration.profile.mailing' | translate }}
                </th>
                <th *ngFor="let source of sourcesReports" scope="col" class="tw-pr-6 tw-py-5" [ngClass]="{'tw-text-gray-400': !source.enable}">
                  <span *ngIf="source.enable">{{ source.name }}</span>
                </th>
            </tr>
        </thead>
    </table>
  </div>

  <!-- Accordions -->
  <div>
    <mh-core-lib-accordion
      [items]="accordionReportItems"
      [options]="sourcesReports"
      [isCustom]="true"
      (dataChanged)="onAccordionDataChanged($event)">
    </mh-core-lib-accordion>
  </div>

  <div class="tw-my-5 tw-flex tw-gap-6 tw-justify-end" *ngIf="editMassiveReports && usersNumber > 1">
    <mh-core-lib-button [isSecondary]="true" [text]="'commons.cancel' | translate" (clicked)="getData()"></mh-core-lib-button>
    <mh-core-lib-button [text]="'forms.save' | translate" [isDefault]="true" (click)="saveMassiveReports()"></mh-core-lib-button>
  </div>
</ng-template>

<ng-template #massiveConfigTemplate>
  <div class="tw-flex tw-min-h-20 tw-mt-10 tw-mb-7" [ngClass]="{'tw-justify-around': isGlobal, 'tw-justify-start': !isGlobal}">
    <div class="tw-flex tw-flex-col tw-basis-1/4">
      <button class="tw-text-2xs tw-text-notification-text tw-bg-gray-300 tw-rounded-full tw-w-16 tw-h-5 tw-p-[1px]" (click)="goToBack()">{{'navbar.back_button' | translate }}</button>
      <p class="tw-font-semibold tw-text-lg">{{ (setupType === 'report_setup' ? 'notifications.manager.manage_reports' : 'notifications.manager.manage_alerts') | translate }}</p>
      <p class="tw-italic" *ngIf="usersNumber === 1">{{ 'notifications.manager.manage_alerts_subt' | translate:{ users: userIds?.length } | slice:0:-1 }}</p>
      <p class="tw-italic" *ngIf="usersNumber > 1">{{ 'notifications.manager.manage_alerts_subt' | translate:{ users: userIds?.length } }}</p>
    </div>
    <div class="tw-flex tw-gap-6 tw-bg-notification-bg-unread tw-justify-around tw-px-6 tw-basis-3/4 tw-py-3" *ngIf="userIds.length > 1">
      <i class="fad fa-exclamation-triangle fa-lg tw-basis-[2%] tw-pt-3 tw-pl-3" style="--fa-primary-color: #000000; --fa-secondary-color: #ffd527; --fa-secondary-opacity: 1;"></i>
      <p [innerHTML]="'notifications.manager.config_alerts_multiple' | translate"></p>
    </div>
  </div>
</ng-template>
