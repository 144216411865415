<mh-box-element [title]="'Resúmenes Configurados'">

  <ul class="list_alerts">
    <li class="box_alert" *ngFor="let alert of alerts">
      <div class="row align-items-center">
        <div class="col">
          <div class="alert_type" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>access_alarms</mat-icon>
            <div class="alert_name">{{alert}}</div>
          </div>


          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <span>Diario</span>
            </button>
            <button mat-menu-item>
              <span>Semanal</span>
            </button>
            <button mat-menu-item>

              <span>Mensual</span>
            </button>
          </mat-menu>

        </div>
        <div class="col">
          <div class="name">Diego Moncada</div>
          <div class="username">diego@myhotel.cl</div>
        </div>
        <div class="col align-right">
          <mat-icon>delete</mat-icon>
        </div>
      </div>
    </li>
  </ul>



</mh-box-element>

<mat-icon>add_circle</mat-icon>