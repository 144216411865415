import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PrecheckinGuestService} from 'app/shared/prestay/precheckin-guest/precheckin-guest.service';
import {CustomerService} from 'app/shared/customer.service';

@Component({
  selector: 'mh-private-download',
  templateUrl: './private-download.component.html',
  styleUrls: ['./private-download.component.scss'],
  providers: [PrecheckinGuestService, CustomerService]
})
export class PrivateDownloadComponent implements OnInit {

  nickId: string | null;
  filename: any;
  waitingFile = true;
  errorOnDownload = false;
  customerId;

  constructor(
    private activatedRoute: ActivatedRoute,
    private precheckinService: PrecheckinGuestService,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.handleLogic();
  }

  async handleLogic() {
    this.getValuesUrl();
    this.waitingFile = true;
    await this.getCustomer();
    if(!this.errorOnDownload) this.downloadFile();
  }

  getValuesUrl() {
    this.filename = this.activatedRoute.snapshot.paramMap.get('filename');
    this.nickId = this.activatedRoute.snapshot.paramMap.get('nick_id');
  }

  async getCustomer() {
    try {
      const response: any = await this.customerService.findHotelByNickname(this.nickId).toPromise();
      if(response) this.customerId = response.id;
      else this.errorOnDownload = true;
    } catch(err) {
      this.errorOnDownload = true;
    }
  }

  downloadFile() {
    this.precheckinService.downloadFile(this.customerId, this.filename).subscribe(response => {
      switch(response) {
        case 'complete':
          this.waitingFile = false;
          break;
        case 'error':
          this.errorOnDownload = true;
          break;
      }
    })
  }
}
