import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Review } from '../model';

@Component({
  selector: 'mh-modal-reply-review',
  templateUrl: './modal-reply-review.component.html',
  styleUrls: ['./modal-reply-review.component.scss']
})
export class ModalReplyReviewComponent implements OnInit {

  @Input()
  formGroup!: FormGroup;

  @Input()
  review: Review;

  @Input()
  replying = false;

  @Input()
  success = false;

  @Input()
  failed = false;

  @Output()
  reviewReplied = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  replyReview() {
    this.replying = true;
    this.reviewReplied.emit(this.formGroup.value);
  }

  get otaName() {
    return this.review.ota.name;
  }

  get isFromPublicGuest() {
    return this.review.guest && this.review.guest?.username;
  }

  get usernameGuest() {
    return this.review.guest?.username || '';
  }

}
