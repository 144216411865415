import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FidelityDeskState } from 'app/states/fidelity-desk/fidelity-desk.state';
import { Observable, Subscription } from 'rxjs';
import {
  ChangeAreasFilter,
  ChangeStatusFilter,
  ChangePriorityFilter,
  ChangeProductFilter,
  ChangeAuthorFilter,
  ChangeAssignedFilter,
  ChangeCaseTypeFilter,
  FilterClear,
  ChangeStageFilter,
  ChangeSeenFilter,
  ChangeTeamFilter,
} from 'app/states/fidelity-desk/fidelity-desk.actions';
import { UtilService } from 'app/shared/util.service';
import { animation } from 'app/shared/utils/animation-custom';
import { CustomersState } from 'app/states/customers/customers.state';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mh-core-lib-desk-filter',
  templateUrl: './desk-filter.component.html',
  styleUrls: ['./desk-filter.component.scss'],
  animations: [animation.collapsedHeightState, animation.rotate],
})
export class CoreLibDeskFilterComponent implements OnInit, OnDestroy {
  isOpen = false;
  isOpenMobile = [
    { target: 'author', isOpen: false },
    { target: 'assigned', isOpen: false },
    { target: 'status', isOpen: false },
    { target: 'product', isOpen: false },
    { target: 'priority', isOpen: false },
    { target: 'type', isOpen: false },
    { target: 'area', isOpen: false },
  ];
  stageFromParams: string;
  stateFromParams: string;
  firstLoadOnStageFilterReady = false;
  firstLoadOnStatesFilterReady = false;
  @Select(FidelityDeskState.casesTotal) cases$: Observable<any>;
  inputTagFilter = (options, value) => {
    return options.filter((option: any) => {
      return (
        option?.clasification_text?.toLowerCase().includes(value) ||
        option?.clasification_text_en?.toLowerCase().includes(value) ||
        option?.clasification_text_pt?.toLowerCase().includes(value)
      );
    });
  };
  cases;
  areas = [];
  subscription: Subscription;

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const result = this.checkClickInside(event.target);
    if (!result) {
      this.isOpen = false;
    }
  }

  constructor(
    private store: Store,
    private utilService: UtilService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.init();
    this.setMenuMobile();
    this.checkParamsToFilter();
  }

  init() {
    this.subscription = this.cases$.subscribe((cases: any) => {
      if (cases && cases.length > 0) {
        this.cases = cases;
        this.setFilters(cases);
      }
    });
  }

  checkParamsToFilter() {
    this.route.queryParams.subscribe(({ stage, state }) => {
      this.stageFromParams = stage;
      this.stateFromParams = state;
    });
  }

  setFilters(cases) {
    this.setAreas(cases);
    this.setAreaFilter(cases);
    this.setStatesFilter(cases);
    this.setStageFilter(cases);
    this.setSeenFilter(cases);
    this.setPriorityFilter(cases);
    this.setTeamFilter(cases);
    this.setAuthorFilter(cases);
    this.setAssignedFilter(cases);
    this.setTypeFilter(cases);
  }

  setMenuMobile() {
    if (window.innerWidth >= 765) {
      this.isOpenMobile = this.isOpenMobile.map((filter) => {
        return {
          ...filter,
          isOpen: true,
        };
      });
    }
  }

  setFilterByKey(option, key, optionSelected, options, keyToValue = 'id', isSimple = false) {
    const singleOption = isSimple ? option[key] : option[key]?.[keyToValue];
    if (optionSelected && singleOption === optionSelected[keyToValue]) {
      return {
        ...option[key],
        isFiltering: !optionSelected.isFiltering,
        selected: !optionSelected.selected,
        value: optionSelected[keyToValue],
      };
    } else if (optionSelected) {
      const optionWithoutChanges = options.find((opt) => opt[keyToValue] === singleOption);
      return {
        ...optionWithoutChanges,
        value: optionWithoutChanges[keyToValue],
      };
    } else {
      return {
        ...option[key],
        isFiltering: false,
        selected: false,
        value: isSimple ? option[key] : option[key]?.[keyToValue],
      };
    }
  }

  setAreas(cases) {
    this.areas = cases.reduce((prev, curr) => {
      curr.related_areas.forEach((area) => {
        const existArea = prev.find((prevArea) => prevArea.id === area.area_entity.id);
        if (!existArea) {
          const filter = {
            ...area.area_entity,
            isFiltering: false,
            selected: false,
          };
          prev.push(filter);
        }
      });
      return [...prev];
    }, []);
  }

  setStatesFilter(cases, optionSelected = null) {
    if (this.stageFromParams && this.statusOptions.length > 0) {
      const stateId = this.stageFromParams === 'pending' ? 1 : 2;
      optionSelected = this.statusOptions.find((option) => option.id === stateId);
      this.stageFromParams = null;
    }
    const states = cases.reduce((prev, curr) => {
      const existState = prev.find((prevStates) => prevStates.id === curr.state.id);
      if (!existState) {
        const filter = this.setFilterByKey(curr, 'state', optionSelected, this.statusOptions);
        prev.push(filter);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeStatusFilter(states)).subscribe(() => {
      if (this.firstLoadOnStatesFilterReady) return;
      this.firstLoadOnStatesFilterReady = true;
      this.setStatesFilter(cases);
    });
  }

  setAreaFilter(cases, optionSelected = null) {
    const areas = cases.reduce((prev, curr) => {
      curr.related_areas.forEach((area) => {
        const existArea = prev.find((prevArea) => prevArea.id === area.area_entity.id);
        if (!existArea && (!optionSelected || optionSelected.length === 0)) {
          const filter = {
            ...area.area_entity,
            isFiltering: false,
            selected: false,
          };
          prev.push(filter);
        }

        if (!existArea && optionSelected && optionSelected.length > 0) {
          const existFilterArea = optionSelected.find((selected) => selected.id === area.area_entity.id);
          if (existFilterArea) {
            const filter = {
              ...existFilterArea,
              isFiltering: !existFilterArea.isFiltering,
              selected: !existFilterArea.selected,
            };
            prev.push(filter);
          }
        }
      });
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeAreasFilter(areas));
  }

  setPriorityFilter(cases, optionSelected = null) {
    const priorities = cases.reduce((prev, curr) => {
      const existPriority = prev.find((prevPriority) => prevPriority.id === curr.priority.id);
      if (!existPriority) {
        const filter = this.setFilterByKey(curr, 'priority', optionSelected, this.priorityOptions);
        prev.push(filter);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangePriorityFilter(priorities));
  }

  setTeamFilter(cases, optionSelected = null) {
    const teams = cases.reduce((prev, curr) => {
      if (!curr.team_details) {
        return [...prev];
      }
      const existTeam = prev.find((prevTeam) => prevTeam.id === curr.team_details?.id);
      if (!existTeam) {
        const filter = this.setFilterByKey(curr, 'team_details', optionSelected, this.teamOptions);
        prev.push(filter);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeTeamFilter(teams));
  }

  setStageFilter(cases, optionSelected = null) {
    if (this.stateFromParams && this.stageOptions.length > 0) {
      optionSelected = this.stageOptions.find((option) => option.subcategory_state_generic === this.stateFromParams);
      this.stateFromParams = null;
    }
    const stages = cases.reduce((prev, curr) => {
      const existStage = prev.find(
        (prevStage) => prevStage.subcategory_state_generic === curr.time_management.subcategory_state_generic,
      );
      if (!existStage) {
        const filter = this.setFilterByKey(
          curr,
          'time_management',
          optionSelected,
          this.stageOptions,
          'subcategory_state_generic',
        );
        prev.push(filter);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeStageFilter(stages)).subscribe(() => {
      if (this.firstLoadOnStageFilterReady) return;
      this.firstLoadOnStageFilterReady = true;
      this.setStageFilter(cases);
    });
  }

  setSeenFilter(cases, optionSelected = null) {
    const seen = cases.reduce((prev, curr) => {
      const existSeen = prev.find((prevSeen) => prevSeen.text === (curr.viewed ? 'seen' : 'unseen'));
      if (!existSeen) {
        const filter = this.setFilterByKey(curr, 'viewed', optionSelected, this.seenOptions, 'viewed', true);
        filter.text = filter.value ? 'seen' : 'unseen';
        filter.viewed = filter.value;
        prev.push(filter);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeSeenFilter(seen));
  }

  setAuthorFilter(cases, optionSelected = null) {
    const author = cases.reduce((prev, curr) => {
      const existAuthor = prev.find((prevAuthor) => prevAuthor.id === curr.author.id);
      if (!existAuthor) {
        const filter = this.setFilterByKey(curr, 'author', optionSelected, this.authorOptions);
        prev.push(filter);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeAuthorFilter(author));
  }

  setAssignedFilter(cases, optionSelected = null) {
    const assigned = cases.reduce((prev, curr) => {
      const existAssigned = prev.find((prevAssigned) => prevAssigned.id === curr.assigned.id);
      if (!existAssigned) {
        const filter = this.setFilterByKey(curr, 'assigned', optionSelected, this.assignedOptions);
        prev.push(filter);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeAssignedFilter(assigned));
  }

  setTypeFilter(cases, optionSelected = null) {
    const types = cases.reduce((prev, curr) => {
      const existType = prev.find((prevType) => prevType.id === curr.case_type.id);
      if (!existType) {
        const filter = this.setFilterByKey(curr, 'case_type', optionSelected, this.typeOptions);
        prev.push(filter);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeCaseTypeFilter(types));
  }

  setProductFilter(cases, optionSelected = null) {
    const currentDeskFilters = this.store
      .selectSnapshot(FidelityDeskState.filters)
      .find((filter) => filter.name === 'product_item.product_id');
    const options = [...currentDeskFilters.options];

    const result = options.reduce((prev, curr) => {
      if (curr.isFiltering === undefined && optionSelected.value === curr.value) {
        const newOption = { ...curr };
        newOption.isFiltering = true;
        newOption.id = curr.value;
        prev.push(newOption);
      } else if (curr.isFiltering === undefined) {
        const newOption = { ...curr };
        newOption.isFiltering = false;
        newOption.id = curr.value;
        prev.push(newOption);
      } else if (optionSelected.value === curr.value) {
        const newOption = { ...curr };
        newOption.isFiltering = !newOption.isFiltering;
        newOption.id = curr.value;
        prev.push(newOption);
      } else {
        prev.push(curr);
      }
      return [...prev];
    }, []);
    this.store.dispatch(new ChangeProductFilter(result));
  }

  ga(filter: string) {
    this.utilService.ga('cases', `desk-click-filters-${filter}`);
  }

  checkClickInside(target) {
    if (
      target.id === 'overlayFiltersDesk' ||
      target.id === 'filtersDesk' ||
      target.className?.includes('chip-no-close') ||
      target.className?.includes('generic-option') ||
      target.className?.includes('checkbox-option')
    ) {
      return true;
    } else if (target.parentNode) {
      return this.checkClickInside(target.parentNode);
    } else {
      return false;
    }
  }

  clearFilters() {
    this.store.dispatch(new FilterClear());
    this.setFilters(this.cases);
  }

  toggleMobileFilter(target) {
    if (this.isMobileMenu) {
      const index = this.isOpenMobile.findIndex((filter) => filter.target === target);
      this.isOpenMobile[index].isOpen = !this.isOpenMobile[index].isOpen;
    }
  }

  get authorOptions() {
    const filters = this.store.selectSnapshot(FidelityDeskState.filters);
    const authorFilter = filters.find((filter) => filter.name === 'author');
    if (authorFilter) {
      return authorFilter.options;
    }
    return [];
  }

  get assignedOptions() {
    const filters = this.store.selectSnapshot(FidelityDeskState.filters);
    const assignedFilter = filters.find((filter) => filter.name === 'assigned');
    if (assignedFilter) {
      return assignedFilter.options;
    }
    return [];
  }

  get statusOptions() {
    const filters = this.store.selectSnapshot(FidelityDeskState.filters);
    const statusFilter = filters.find((filter) => filter.name === 'state');
    if (statusFilter) {
      return statusFilter.options;
    }
    return [];
  }

  get stageOptions() {
    const filters = this.store.selectSnapshot(FidelityDeskState.filters);
    const statusFilter = filters.find((filter) => filter.name === 'stage');
    if (statusFilter) {
      return statusFilter.options;
    }
    return [];
  }

  get seenOptions() {
    const filters = this.store.selectSnapshot(FidelityDeskState.filters);
    const statusFilter = filters.find((filter) => filter.name === 'seen');
    if (statusFilter) {
      return statusFilter.options;
    }
    return [];
  }

  get productOptions() {
    const UNNASIGNED_ID = 5;
    const GUEST = 6;
    const filters = this.store.selectSnapshot(FidelityDeskState.filters);
    const currentCustomer = this.store.selectSnapshot(CustomersState.currentCustomer);
    const productFilter = filters.find((filter) => filter.name === 'product_item.product_id');
    if (productFilter) {
      const options = productFilter.options
        .filter((option) => {
          if (option.value === UNNASIGNED_ID || option.value === GUEST) return true;
          return currentCustomer[option.text];
        })
        .map((option) => {
          return {
            ...option,
            id: option.value,
          };
        });
      return options;
    }
    return [];
  }

  get priorityOptions() {
    const filters = this.store.selectSnapshot(FidelityDeskState.filters);
    const priorityFilter = filters.find((filter) => filter.name === 'priority');
    if (priorityFilter) {
      return priorityFilter.options;
    }
    return [];
  }

  get teamOptions() {
    const filters = this.store.selectSnapshot(FidelityDeskState.filters);
    const teamFilter = filters.find((filter) => filter.name === 'team');
    if (teamFilter) {
      return teamFilter.options;
    }
    return [];
  }

  get typeOptions() {
    const filters = this.store.selectSnapshot(FidelityDeskState.filters);
    const typeFilter = filters.find((filter) => filter.name === 'type');
    if (typeFilter) {
      return typeFilter.options;
    }
    return [];
  }

  get areasOptions() {
    const filters = this.store.selectSnapshot(FidelityDeskState.filters);
    const areaFilter = filters.find((filter) => filter.name === 'areas');
    if (areaFilter) {
      return areaFilter.options;
    }
    return [];
  }
  get displayChipsFn() {
    return (option) => {
      if (this.utilService.currentLanguage === 'es') return option.clasification_text;
      if (this.utilService.currentLanguage === 'en') return option.clasification_text_en;
      if (this.utilService.currentLanguage === 'pt') return option.clasification_text_pt;
    };
  }

  get displayPersonOptionsFn() {
    return (option: any) => {
      return `${option.first_name} ${option.last_name}`;
    };
  }

  get displayTeamOptionsFn() {
    return (option: any) => {
      return option.name;
    };
  }

  get areFiltersApplied() {
    const filters = this.store.selectSnapshot(FidelityDeskState.filters);
    return filters.some((filter) => filter.options.some((opt) => opt.isFiltering));
  }

  get authorFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'author').isOpen;
  }

  get assignedFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'assigned').isOpen;
  }

  get statusFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'status').isOpen;
  }

  get productFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'product').isOpen;
  }

  get priorityFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'priority').isOpen;
  }

  get areaFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'area').isOpen;
  }

  get typeFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === 'type').isOpen;
  }

  get displayProductOptions() {
    return (option) => {
      const UNNASIGNED_ID = 5;
      const GUEST = 6;
      if (option.value === GUEST) return this.translateService.instant('cases.commons.guest');
      if (option.value !== UNNASIGNED_ID) return option.text;
      return this.translateService.instant('cases.commons.no_product_tag');
    };
  }

  get isMobileMenu() {
    return window.innerWidth < 765;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
