import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mh-forms-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  textButtonYes = 'yes';

  @Input()
  textButtonNo = 'no';

  @Input()
  showButtonYes = true;

  @Input()
  showButtonNo = true;

  @Input()
  actionClass = 'btn-clean btn-action';

  @Input()
  actionIcon;

  @Input()
  actionText;

  @Input()
  showActionButton = true;

  ready = false;

  @Input()
  modalSize: 'sm' | 'lg' = 'sm';

  @Input()
  detectModalClose = false;

  @Input() disabled: boolean = false;

  @Input() breakTextbtn: boolean

  @Output()
  buttonPressed: EventEmitter<any> = new EventEmitter();

  @Output()
  beforeOpen: EventEmitter<any> = new EventEmitter();

  @Output()
  isModalClose: EventEmitter<any> = new EventEmitter();

  modal:NgbModalRef;
  waitDelete = false;
  constructor(private modalService: NgbModal) {}

  ngOnInit() {
  }

  openDialog(content){
    this.ready = false;
    this.beforeOpen.emit(true);
    this.modal = this.modalService.open(content, {
      windowClass: 'modal-confirm modal-md',
      size: this.modalSize
    })
    setTimeout(_ => {
      this.ready = true;
    },100);
    this.modal.result.then(_ => {
      if(this.detectModalClose) this.emitModalClose();
    }, _ => {
      if(this.detectModalClose) this.emitModalClose();
    });
  }


  closeDialog(){
    if(this.modal) this.modal.close();
  }

  emitModalClose() {
    this.isModalClose.emit(true);
  }

  buttonAction(type:string){
    this.closeDialog();
    this.buttonPressed.emit({
      'action' : type
    });
  }

}
