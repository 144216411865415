export class Currency{
  id:number;
  code:string;
  country: string;
  name: string;
  symbol = '$';
  unit:string;

  constructor(id=179, code='USD'){
    this.id = id;
    this.code = code;
  }
}
