import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AdminCustomerService } from 'app/admin/customers/admin-customer.service';
import { Competitor } from '../model/competitor.model';
import { Ota } from 'app/shared/model/admin/_index';

@Component({
  selector: 'mh-modal-otas',
  templateUrl: './modal-otas.component.html',
  styleUrls: ['./modal-otas.component.scss'],
  providers: [AdminCustomerService]
})
export class ModalOtasComponent implements OnInit {

  NON_CONFIGURABLE_OTA_IDS = [3, 5] // ESTAS SON OTAS QUE SE "MERGEARON" en expedia group

  @Input() competitor: Competitor
  otasConfigurations: Ota[] = [];
  waiting: boolean;
  message: string
  messageClass: string

  constructor(
    public modal: NgbActiveModal,
    private adminCustomerService: AdminCustomerService,
  ) { }

  ngOnInit() {
    this.loadOtasByCompetitor()
  }

  private async loadOtasByCompetitor() {
    try {
      this.waiting = true;
      const response: Ota[] = await this.adminCustomerService.getOtas(this.competitor.id).toPromise() as Ota[]
      if(response){
        this.otasConfigurations = response
      } else {
        this.handleMessage('errors.data.not_exist','warning')
      }
      this.waiting = false;
    } catch(err) {
      this.waiting = false;
      this.handleMessage('errors.default','danger')
    }
  }

  private handleMessage(msgText: string, msgClass: string) {
    this.message = msgText
    this.messageClass = `alert alert-${msgClass}`;
  }

}
