<ng-container *ngIf="!item.isParent(); else itemNoLink">
  <ng-container *ngIf="item.flag === 'corporative' else linkNoInterchain">
  <a class="navbar_item full_link" (click)="handleInterchainClicked()" href="javascript:void(0)">
    <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
  </a>
  </ng-container>
</ng-container>

<ng-template #linkNoInterchain>

  <a class="navbar_item full_link" (click)="changeRoute()" routerLink="{{item.link}}" >
    <div class="item-separator" *ngIf="item.flag !== 'home'" [class.config]="statusNavbar === 1"></div>
    <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
  </a>

</ng-template>

<ng-template #itemNoLink>
  <div class="item-separator" *ngIf="item.name === 'coverage'" [class.config]="statusNavbar === 1"></div>
  <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
</ng-template>


<ng-template #itemTemplate>
  <div
    class="item_menu depth_{{item.depth}}"
    [class.notLink]="item.isParent()"
    [class.depth_0_collapse]="sideBarCollapsed && item.depth === 0"
    [class.config]="statusNavbar === 1"
    [attr.role]="item.isParent() ? 'button' : null"
    (click)="utilService.toggleNavbar(false)"
  >
    <div class="icon" *ngIf="statusNavbar !== 1">
      <mat-icon *ngIf="item.matIcon">{{item.icon}}</mat-icon>
      <i *ngIf="!item.matIcon" class="{{item.icon}}"></i>
    </div>
    <div class="name_item" [class.config]="statusNavbar === 1" [class.selected]="item.flag === 'home' && router.url.includes('dashboard') || item.flag === 'corporative' && statusNavbar === 3" *ngIf="!sideBarCollapsed">
      {{'navbar.product.'+type+'.'+item.name | translate }}
    </div>

    <div class="tag-label beta" *ngIf="item.beta && !sideBarCollapsed" >
      {{ 'commons.beta' | translate }}
    </div>

    <div class="tag-label new" *ngIf="item.new && !sideBarCollapsed">
      {{ 'commons.new' | translate  }}
    </div>

    <div class="tag-label superadmin" *ngIf="item.superAdmin && item.children.length === 0 && !sideBarCollapsed">
        &nbsp;
    </div>
  </div>
</ng-template>
