import { Component, Input } from '@angular/core';
import { UtilService } from 'app/shared/util.service';
import { Hotel } from 'app/shared/model';

@Component({
  selector: 'customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss'],
})
export class CustomerDetailComponent{
  @Input() 
  customer:any;

  @Input() 
  setup:any[];
  
  @Input()
  chain: Hotel[] = [];


  options: any[] = []

  mh_products:string[] = [];

  constructor(private utilService:UtilService){
    this.mh_products = this.utilService.getProductsMH().filter((product) => product !== 'collect');
  }

  ngOnInit(){
    const customer = this.useChain
      ? this.chain?.find((x) => x.id === this.customer?.id) || []
      : this.setup?.find((x) => x.id_customer === this.customer?.id) || [];
    const keySetup = this.useChain ? 'customer_setup' : 'setup';
    this.options.push(
      new Option('product',this.mh_products),
      new Option('otas',customer[keySetup])
    );
  }

  isCurrent(){
    return parseInt(this.utilService.currentHotel?.id) === this.customer?.id
  }

  setCurrentCustomer(value){
    if(!this.isCurrent()){
      this.utilService.currentSection = this.getUrlSummary(value);
      this.utilService.customerChanged(this.customer);
    }
  }

  hideOption(type,value){
    if(type === 'otas') return false;
    return this.customer && !this.customer[value]
  }

  getLink(type,value){
    if(type === 'otas') return value.url_customer_ota;
    return this.getUrlSummary(value);
  }

  getUrlSummary(value){
    if (['online', 'followup', 'onsite', 'prestay'].includes(value)) {
      return '/' + value + '/resumen';
    } else {
      return '/' + value + '/summary';
    }
  }

  get isRetail(){
    return this.utilService.customerIsRetail;
  }

  get navarProduct(){
    return this.isRetail ? 'retail' : 'default';
  }

  get useChain(){
    return this.chain.length>0;
  }

}


class Option{
  type:string;
  values=[];
  constructor(type,values){
    this.type=type;
    this.values=values;
  }
}
