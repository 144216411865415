import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'widget-card-rate-summary-item',
  templateUrl: './card-rate-summary-item.component.html',
  styleUrls: ['./card-rate-summary-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardRateSummaryItemComponent {

  @Input()
  title: string;

  @Input()
  quantity: number;

  @Input()
  quantityTooltipText: string;

  @Input()
  showDetail = true;

  @Input()
  tooltip;

  @Input()
  waiting = false;
}
