import { Currency } from './currency.model';

export class HotelImage{
  static DEFAULT_IMAGE = 'http://statics.myhotel.io/uitls/product_default_square.png';
  deleted: boolean = false;
  description: string = "";
  id: number = 0;
  logo: boolean = true;
  path:string = HotelImage.DEFAULT_IMAGE;
  url: string = "";
}

export class Chain {
  id:number | null=null;
  name:string='';
}

export class ChainProperty{
  chain:Chain=new Chain();
  internal_code:string='';
  internal_name:string='';
  business_line:string='';
  brand:string='';
  region:string='';
}

export class SiteMinderIntegration{
  flag_active = false;
}

export class PropertyType{
  id= 1;
  description = "hotel"
}

export class HotelDto{

  static DEFAULTS = {
    "tramo" : "",
    "segment" : "E",
    "licence_type" : "Cliente",
    "default_checkin" : "12:00",
    "default_checkout" : "10:00",
    "language" : {id:1, code:'es'},
    "city" : {id: 554},
    "country" : {code2: 'CL'},
    "currency" : new Currency(179,'USD'),
    "chain_property": new ChainProperty(),
    "integration_siteminder" : new SiteMinderIntegration(),
    "property_type" : new PropertyType()
  }

  address1: string = "";
  address2: string = "";
  app: boolean = false;
  check_contact: string = "";
  check_contact2: string = "";
  city:any = HotelDto.DEFAULTS.city;
  country:any = HotelDto.DEFAULTS.country;
  client: boolean = true;
  collect: boolean = false;
  contact1:string="";
  contact2:string="";
  web_site:string="";
  commercial_executive:string="";
  monthly_billing_usd:number;
  currency:Currency=HotelDto.DEFAULTS.currency;
  default_checkin:string=HotelDto.DEFAULTS.default_checkin;
  default_checkout:string=HotelDto.DEFAULTS.default_checkout;
  deleted: boolean = false;
  description:string="";
  fall_integration: boolean = false;
  flag_integracion_caida: boolean = false;
  integration_siteminder = HotelDto.DEFAULTS.integration_siteminder;
  fidelity_alerts: boolean = false;
  fidelity_summaries: boolean = false;
  free_test_notice: boolean = false;
  free_test_notice_end: boolean = false;
  free_test_notice_followup: boolean = false;
  free_test_notice_followup_end: boolean = false;
  free_test_notice_online: boolean = false;
  free_test_notice_online_end: boolean = false;
  free_test_notice_onsite: boolean = false;
  free_test_notice_onsite_end: boolean = false;
  front:string="";
  hours_before_pre_stay_purchase:number =0;
  hours_until_pre_stay_purchase:number = 0;
  id: number = 0;
  images: HotelImage[] = [new HotelImage()];
  integration_type:string="";
  language:any = HotelDto.DEFAULTS.language;
  licence_type:string= HotelDto.DEFAULTS.licence_type;
  more_info:string="";
  name:string="";
  followup:boolean = false;
  online: boolean = false;
  online_alerts: boolean = false;
  online_competitor: boolean = false;
  onsite: boolean = false;
  cases: boolean = false;
  paid: boolean = true;
  payment_warning: boolean = false;
  phone:string="";
  pre_stay: boolean = false;
  pre_stay2: boolean = false;
  pre_stay_mailing: boolean = false;
  rooms_quantity:number= 0;
  seasonal: boolean = false;
  segment:string=HotelDto.DEFAULTS.segment;
  smart_checkin: boolean = false;
  state: boolean = false;
  tramo:string=HotelDto.DEFAULTS.tramo;
  utc:number = 0;
  automatic_utc:boolean = true;
  nick_id:string="";
  chain_property:ChainProperty = new ChainProperty();
  property_type = HotelDto.DEFAULTS.property_type;
  pipedrive_id;
  flag_show_translations;
  cs_executive;
  id_xero;
  hubspot_id;
  subscription: string;
  subscription_as_string: string;
  stage: number | null;
  whatsapp: boolean;

  group_id = 2;

  checkHotelDefaults(){
    for(let key of Object.keys(HotelDto.DEFAULTS)){
      this[key] = this[key]==="" || this[key] ===null || this[key]==="00:00" ? HotelDto.DEFAULTS[key] : this[key];
    }
  }

}




