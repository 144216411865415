<div class="translate_widget" (click)="selectLanguage()" *ngIf="!showTranslations && ( (this.review.guest.language.id != currentLanguage) || (this.review.guest.language.id != currentLanguage))  && hasDescription() ">
  <ng-container *ngTemplateOutlet="buttonTranslate; context:{style: 'icon-action'}"></ng-container>
</div>

<div [class.active_translate]="activeTranslateOptions" *ngIf="showTranslations">

  <span class="translate_widget" (click)="toggleTranslateOptions()">
    <ng-container *ngTemplateOutlet="buttonTranslate; context:{style: 'icon-action'}"></ng-container>
  </span>

  <div class="translate-options" [class.hidden_box]="hideOptions">
    <div class="language-option">
      <span>{{'shared.reviews.translate_origin' | translate}}: {{'languages.iso.'+review.guest.language.code | translate}}
        <i class="fas fa-arrow-right"></i>
      </span>
    </div>
    <div class="language-option">
      <div class="translate_options_destiny input_translate">
        <span>{{'shared.reviews.translate_destiny' | translate}}:</span>
        <div class="content-field">
          <mat-form-field>
            <mat-select [(value)]="selectedLanguage" (selectionChange)="selectLanguage()">
              <mat-option *ngFor="let iso of availableLanguages" [value]="iso" (click)="setSelectedLanguage(false, iso)">
                {{'languages.iso.'+iso | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="language-option translate-button translate_widget" (click)="selectLanguage()">
      <ng-container *ngTemplateOutlet="buttonTranslate; context:{style: ''}"></ng-container>
    </div>
  </div>
</div>


<ng-template #buttonTranslate let-style="style">
  <i class="fal fa-language icon_position {{style}}"></i>
  {{'commons.translate' | translate }}
</ng-template>


