<button
  class="btn btn-white btn-filter"
  #trigger="matMenuTrigger"
  [matMenuTriggerFor]="selector"
  *ngIf="surveys?.length > 1">
  <i class="fas fa-clipboard-list-check"></i> <span>{{(filterPrefix+'.select_survey' | translate)}}</span>
</button>
<mat-menu #selector>
  <div class="origin-survey-filter" (click)="$event.stopPropagation()">
    <mat-selection-list #surveyList *ngIf="filters$ | async as guestFilter">
      <mat-list-option *ngFor="let survey of surveys"
        checkboxPosition="before"
        [value]="survey"
        [selected]="survey | followupOnsiteFilterSelect:'selector-survey':guestFilter"
        (click)="setSurveyFilter(surveyList.selectedOptions)">
        {{survey.translates | translateObject:'title':language}}
      </mat-list-option>
    </mat-selection-list>
  </div>
</mat-menu>
