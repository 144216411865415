import { Component, Input, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { FormEditor } from '../../../../shared/utils/form-editor';
import { UtilService } from '../../../../shared/util.service';
import { HotelService } from '../../hotel.service';
import { DialogComponent } from 'app/material/widgets/forms/dialog/dialog.component';

function existsInArrayValidator(list: string[]): ValidatorFn {

  return (control: AbstractControl): { [key: string]: boolean } | null => {

    const listLowerCase = list.map(v=>v.toLowerCase());
    const valueTxt      = control.value.toLowerCase();

    if (listLowerCase.includes(valueTxt)) {
      return { 'existChainInArray': true };
    }
    return null;
  };
}

@Component({
  selector: 'mh-customer-hotel-chain-form',
  templateUrl: './hotel-chain-form.component.html',
  providers: [FormEditor, HotelService]
})
export class CustomerHotelChainFormComponent {

  hotel_chain_property = ['chain', 'internal_code', 'internal_name', 'brand', 'region']

  controlFormAddChain: FormGroup;
  waitingAddChain: boolean = false;

  listChains: any;

  @Input()
  parentForm: FormGroup;

  @Input()
  hotelBase;

  @Input()
  hotelDto;

  @Input()
  listOptions = {};

  @Input()
  canEdit = false;

  @Output()
  isReady: EventEmitter<string> = new EventEmitter();

  @Output()
  waiting: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('dialogAddChain', { static: false })
  dialogAddChain: DialogComponent;


  constructor(
    private utilService: UtilService,
    private fb: FormBuilder,
    private hotelService: HotelService) {

  }

  ngOnInit() {
    this.createForm();
    this.isReady.emit("chain");
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hotelDto !== undefined) this.setForChain();
  }

  setForChain() {
  }

  get chain_property() { return this.parentForm.get('chain_property'); }

  setSelectlValue($event, key) {
    if($event) {
      const control = this.chain_property!.get(key);
      control!.patchValue($event.original, {emitEvent: true})
    }
  }

  createForm() {
    this.controlFormAddChain = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(35), Validators.minLength(3),existsInArrayValidator(this.listOptions['chain'].map(x=>x.name))]]
    });
  }

  sendAddChain() {
    this.waitingAddChain = true;
    let chainDto = this.controlFormAddChain.value;

    this.hotelService.createChain(chainDto).subscribe(response => {
      if (response && response !== 0) {
        this.waitingAddChain = false;
        this.dialogAddChain.closeDialog();
        this.utilService.customerChanged(this.utilService.currentHotel)
      } else {
        this.alertError();
      }
    }, error => {
      this.alertError();
    })

  }

  alertError() {
    this.utilService.sendAlert('errors.default');
    this.waitingAddChain = false;
  }

}
