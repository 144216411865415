<section id="section_otas">
  <mh-box-element [title]="('online.configurations.otas.title' | translate )">

    <mh-spinner-classic [hidden]="!waiting"></mh-spinner-classic>

    <ng-container *ngIf="!waiting">
      <div *ngIf="currentClient">
        <ul class="jumbotron lead">
          <ng-container *ngFor="let hotel of hotelInfo">
            <li *ngIf="hotel.value" @fade>
              <p class="mb-0"><strong>{{('models.hotel_dto.'+hotel.elem | translate)}}</strong>: {{hotel.value}}</p>
            </li>
          </ng-container>
        </ul>
      </div>
      <form class="general_set_form show_invalids" *ngIf="otas" [formGroup]="controlForm" #formDir="ngForm">
        <div formArrayName="otas">
          <ng-container *ngFor="let ota of otas; let index = index">
            <div class="form-group wrapp_form" [formGroupName]="ota.value.index">

              <div class="row no-gutters align-items-center box-config">
                <div class="col-md-1">
                  <div class="d-flex">
                    <div class="d-flex flex-column align-items-center w-100">
                      <div class="logo-section">
                        <a [href]="ota.value.url_home" target="_blank">
                          <img [src]="('https://statics.myhotel.io/logos/otas/square/'+ota.value.id+'.png')" alt="ota logo"
                            class="ota-logo" [class.desactive]="!ota.value.active">
                        </a>
                        <a [href]="getSearchClient(ota.value)" *ngIf="ota.value.search_q" class="ml-1 search-ota" target="_blank">
                          <i class="far fa-search"></i>
                        </a>
                        <div 
                          *ngIf="ota?.value?.id === 4 || ota?.value?.id === 34" 
                          class="ml-1 tw-absolute tw-z-50 -tw-bottom-[6px] -tw-right-5"
                          [mhTooltip]="(ota?.value?.id === 4 ? 'online.otas.ranking_table.tooltips.Despegar.com': 'online.otas.ranking_table.tooltips.Expedia') | translate"
                        >
                          <i class="far fa-info-circle"></i>
                        </div>
                      </div>
                      <div class="ota-name">
                        {{ ota.value.name }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 align-center">
                  <mat-slide-toggle #active formControlName="active">
                    {{'admin.otas.ota_active' | translate }}
                  </mat-slide-toggle>
                  <ng-container *ngIf="!ota.value.active && ota.value.isEdit">
                    <ng-container *ngTemplateOutlet="btnsAction; context:{ota:ota, index:index}"></ng-container>
                  </ng-container>

                </div>
                <div class="col-md-8">
                  <div class="ota_properties" [hidden]="!ota.value.active">
                    <mat-form-field *ngIf="!ota.value.oauth">
                      <input matInput [placeholder]="ota.value.name" formControlName="param">
                      <mh-form-alerts [element]="ota" [class]="'top'"></mh-form-alerts>
                    </mat-form-field>
                    <div class="url_oauth" *ngIf="ota.value.oauth && ![null,''].includes(ota.value.param)">
                      <a [href]="ota.value.param" target="_blank">{{ota.value.param}}</a>
                    </div>

                    <div class="configurations">
                      <div class="oauth" *ngIf="ota.value.oauth && [null,''].includes(ota.value.param)">
                        <a href="https://auth.myhotel.cl/myhotel" target="_blank">{{'admin.otas.configure' |
                          translate }}</a>
                      </div>
                      <div class="d-flex align-items-center" *ngIf="ota.value.param">
                        <mat-slide-toggle formControlName="get_reviews">
                          {{'admin.otas.get_reviews' | translate }}
                        </mat-slide-toggle>
                        <a [href]="ota.value.param" class="mx-4" target="_blank">
                          <strong>
                            {{'admin.otas.see_ota' | translate }}
                            <i class="far fa-external-link-alt"></i>
                          </strong>
                        </a>
                        <div class="tw-flex tw-flex-row tw-gap-1 tw-cursor-pointer" *ngIf="ota.value?.id === 1 && !isScraperLaunched && !scraperResult" (click)="launchScraper(ota.value.id)">
                          <i class="tw-cursor-pointer fas fa-arrow-circle-down tw-mt-0.5"></i> 
                          <a class="tw-font-bold">{{ 'admin.otas.get_reviews_scraper' | translate }}</a>
                        </div>
                        <i class="fas fa-check-double tw-text-detractors" *ngIf="ota.value?.id === 1 && isScraperLaunched && !scraperResult"></i>
                        <i class="fas fa-check-double tw-text-promoters" *ngIf="ota.value?.id === 1 && isScraperLaunched && scraperResult"></i>
                        <ng-container *ngTemplateOutlet="btnsAction; context:{ota:ota, index:index}"></ng-container>
                      </div>

                    </div>

                  </div>
                </div>

              </div>
              <div class="d-flex justify-content-center" >
                <div *ngIf="message && index === indexOta" [ngClass]="messageClass" @fade>
                  <ng-container *ngIf="!!errorFormatUrl">
                    {{(message | translate: { url: errorFormatUrl } )}}
                  </ng-container>

                  <ng-container *ngIf="!!!errorFormatUrl">
                    {{(message | translate )}}
                  </ng-container>
                  <button type="button" class="close ml-2" data-dismiss="alert" aria-label="Close" (click)="message = null">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>

            </div>
          </ng-container>
        </div>

      </form>
    </ng-container>

  </mh-box-element>
</section>

<ng-template #btnsAction let-ota="ota" let-index="index">
  <div class="button-action" *ngIf="ota.value.isEdit" @fade>
    <button (click)="onCancel(index, ota.value)" class="btn btn-small btn-stroked btn-rounded btn-send mt-0 mr-2">
      {{'forms.cancel' | translate}}
    </button>
    <button (click)="saveData(index)" [disabled]="sendingData" class="btn btn-orange btn-send btn-small mt-0">
      <div class="d-flex justify-content-center" >
        <mh-spinner-classic *ngIf="sendingData && index === indexOta" @widthExpand></mh-spinner-classic>
        {{'forms.save_changes' | translate}}
      </div>
    </button>
  </div>
</ng-template>
