import { Component, OnInit, Input } from '@angular/core';
import { CasesHelperService } from 'app/core-lib/dialogs/create-case-modal/services/cases-helper.service';

@Component({
  selector: 'mh-core-lib-product-case',
  templateUrl: './product-case.component.html',
  styleUrls: ['./product-case.component.scss'],
})
export class CoreLibProductCaseComponent implements OnInit {
  @Input()
  item = null;

  @Input()
  associated = null;

  @Input()
  caseData = null;

  @Input()
  customerId = null;

  @Input()
  source: 'detail' | 'create' = 'create';

  @Input()
  showTitle = true;

  services = {
    'survey-answer-followup': (itemId) => this.casesHelperService.getFollowupAnswer(itemId),
    'survey-answer-onsite': (itemId) => this.casesHelperService.getOnsiteAnswer(itemId),
    review: (itemId) => this.casesHelperService.getOnlineReview(itemId),
    'pre-checkin': (itemId) => this.casesHelperService.getPrestayPrecheckin(this.customerId, itemId),
    order: (itemId) => this.casesHelperService.getPrestayOrder(this.customerId, itemId),
  };

  associations = {
    'survey-answer-followup': 'nps',
    'survey-answer-onsite': 'nps',
    review: 'review',
    'pre-checkin': 'precheckin',
    order: 'order',
  };

  constructor(private casesHelperService: CasesHelperService) {}

  ngOnInit(): void {
    if (!this.item && !this.associated && this.caseData.product_item) {
      this.fetchData();
    }
  }

  fetchData() {
    const productId = this.caseData.product_item.product_id;
    const typeName =
      productId === 1
        ? `${this.caseData.product_item.type.name}-followup`
        : productId === 2
        ? `${this.caseData.product_item.type.name}-onsite`
        : `${this.caseData.product_item.type.name}`;
    const itemId = this.caseData.product_item.item_id;
    this.associated = this.associations[typeName];
    this.services[typeName](itemId).subscribe((response) => (this.item = response));
  }

  get isReview() {
    return this.associated === 'review';
  }

  get isNps() {
    return this.associated === 'nps';
  }

  get isPrestayOrder() {
    return this.associated === 'order';
  }

  get isPrestayPrecheckin() {
    return this.associated === 'precheckin';
  }

  get caseProduct() {
    return this.caseData.product_item.product;
  }
}
