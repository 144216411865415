<div id="modal_download_excel" class="general_modal">
  <div class="modal-header">
    <h4 class="modal-title">{{'reports.downloads.excel.title_modal' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body align-center">
    <h5 [innerHtml]="('reports.downloads.excel.sending' | translate:{user: util.getCurrentUser()})"></h5>
    <p>{{'reports.downloads.excel.wait' | translate}}</p>

    <div *ngIf="waiting" class="spinner_wait">
      <mh-spinner-classic></mh-spinner-classic>
    </div>

    <p [hidden]="waiting">{{'reports.downloads.excel.ready' | translate}}</p>

  </div>
</div>