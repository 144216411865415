<section id="properties">
  <div class="container_table">
    <div class="area_table" [ngStyle]="tableAreaStyle">
      <mat-table #table  [matSortActive]="activeSort"  matSortDisableClear matSortDirection="desc" matSort
        class="table-mh sticky centered-header centered-content capitalize-header"
        [ngStyle]="tableContentStyle"
        [dataSource]="dataSource">

        <ng-container *ngFor="let key of headerTable" [matColumnDef]="key">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <ng-template #tooltipTemplate>
              <div [innerHtml]="(getPrefixTranslate(key,'tooltip') | translate)"></div>
            </ng-template>
            <div [ngSwitch]="key" [class.ota]="key.includes('ota_')" [ngbTooltip]="tooltipHeaders.includes(key) ? tooltipTemplate : null" container="body" >
              {{key.includes('area_') ? processTranslates(dataSource.data[0][key].translates) : (getPrefixTranslate(key) | translate:translateParams) }} <i *ngIf="tooltipHeaders.includes(key)" container="body" class="tooltip-icon fas fa-question-circle"></i>
            </div>
          </mat-header-cell>

          <mat-cell *matCellDef="let element">
            <div class="full-width" [ngSwitch]="key">
              <ng-template [ngSwitchCase]="'customer'">
                <customer-detail [customer]="element[key]" [chain]="chain" ></customer-detail>
              </ng-template>

              <ng-template [ngSwitchCase]="'area'">
                {{'areas.'+element[key] | translate }}
              </ng-template>

              <ng-template [ngSwitchCase]="'ota'">
                <div class="text-center">
                  <widget-card-ota
                    [align]="'left'"
                    [viewStyle]="'inline'"
                    [size]="'small'"
                    [ota]="(element['ota'] ? element['ota'] : null)"
                    [otaId]="(element['ota'] ? null : element['ota_id'])"></widget-card-ota>
                </div>
              </ng-template>

              <ng-template [ngSwitchCase]="'label'">
                <div class="enphasis">
                  {{prefixTranslate+'.'+element[key] | translate }}
                </div>
              </ng-template>

              <ng-template [ngSwitchCase]="'annualAccumulated'">
                <div *ngFor="let item of element['annualAccumulated'];" class="element_from_group text-center">
                  <div class="value">
                    {{ item.value | formatBy:item.formatBy }}
                  </div>
                  <div class="value_label">
                    {{ prefixTranslate +'.' + item.label  | translate }}
                  </div>
                </div>
              </ng-template>

              <ng-template [ngSwitchCase]="showRanking(element,key) ? key : false">
                <widget-period-formater-ranking
                  [period]="element[key]"
                ></widget-period-formater-ranking>
              </ng-template>

              <ng-template ngSwitchDefault>
                <ng-container *ngTemplateOutlet="PeriodTemplate;context:{element: element, key: key}"></ng-container>
              </ng-template>
            </div>
          </mat-cell>

          <ng-container *ngIf="showTotals">
            <mat-footer-cell [ngClass]="{'fixed-cell' : key==='customer'}" *matFooterCellDef>
              <div class="full-width" [ngSwitch]="key">
                <ng-template [ngSwitchCase]="['customer','area','label', 'ota'].includes(key) ? key : false">
                  <b>{{'commons.total' | translate }}</b>
                </ng-template>

                <ng-template [ngSwitchCase]="showRanking(null,key) ? key : false">
                  -
                </ng-template>

                <ng-template [ngSwitchCase]="'annualAccumulated'">
                  <div *ngFor="let item of totals['annualAccumulated'];" class="element_from_group">
                    <div class="value">
                      {{ item.value | formatBy:item.formatBy }}
                    </div>
                    <div class="value_label">
                      {{ prefixTranslate +'.' + item.label  | translate }}
                    </div>
                  </div>
                </ng-template>

                <ng-template ngSwitchDefault>
                  <ng-container *ngTemplateOutlet="PeriodTemplate;context:{element: totals, key: key}"></ng-container>
                </ng-template>
              </div>
            </mat-footer-cell>
          </ng-container>
        </ng-container>
        <mat-header-row *matHeaderRowDef="headerTable"></mat-header-row>
        <mat-row *matRowDef="let row; columns: headerTable;"></mat-row>

        <ng-container *ngIf="showTotals">
          <mat-footer-row *matFooterRowDef="headerTable"></mat-footer-row>
        </ng-container>

      </mat-table>
    </div>
  </div>

</section>


<ng-template let-element="element" let-key="key" #PeriodTemplate>
  <widget-period-formater
    [period]="key.includes('area_') ?  element[key]?.rating : element[key]"
    [format]="getFormatPeriod(element,key)"
    [invert]="inverseKeys.includes(key)"
  ></widget-period-formater>
</ng-template>
