export class MapData {

  private _id:string | any;
  private _name:string | any;
  private _value:number | any=0;

  constructor()
  constructor(id:string, name:string, value:number)
  constructor(id?:string, name?:string, value?:number){
    this._id = id;
    this._name = name;
    this._value = value;
  }

  get id() : string {
    return this._id;
  }
  set id(v : string) {
    this._id = v;
  }

  get name() : string {
    return this._name;
  }
  set name(v : string) {
    this._name = v;
  }

  get value() : number {
    return this._value;
  }
  set value(v : number) {
    this._value = v;
  }







}
