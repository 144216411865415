<mh-alerts-wrapper>

  <section class="flex flex-row justify-between">
    <div>
      <mh-core-lib-datepicker
          [currentDates]="currentDates"
          [currentFilter]="currentFilterDate"
          (datepickerUpdated)="handleDatepickerUpdated($event)">
      </mh-core-lib-datepicker>
    </div>
    <div>
      <mh-filter-date
          [startDate]="startDate"
          [endDate]="endDate"
          [report]="fidelityPermisions.includes('interchain_online_coverage_hotel_report') ? 'chainOnlineCoverageCustomer' : null "
          [reportActiveEmail]="true"
          [checkPermissions]="true"
          slug="corporative_online_coverage_hotel_report">
      </mh-filter-date>
    </div>
  </section>
  <mh-box-element [title]="titleTranslatePath | translate " *ngIf="fidelityPermisions.includes('interchain_online_coverage_hotel_result')">
    <div class="filter_zone">
      <div class="row align-items-center">
        <div class="col-8">
          <widget-filter-chain-zone [productId]="productId" (chainChanged)="setChain($event)" [disabled]="!isReady"></widget-filter-chain-zone>
        </div>
        <div class="col-4 align-right">
          <widget-modal-faq
            [faqs]="faqs"
            [prefixTranslate]="prefixTranslateFAQ"
            ></widget-modal-faq>
        </div>
      </div>
    </div>
    <widget-filter-chain  [hidden]="waiting" [customers]="chain" (actives)="filterCustomers($event)" [filterProducts]="['online']"></widget-filter-chain>

    <mh-spinner-classic [hidden]="!waiting"></mh-spinner-classic>
    <ng-container *ngIf="!waiting">
      <widgets-table-periods
        [dataSource]="dataSource"
        [headerTable]="headerTable"
        [chain]="chainActives"
        [totals]="totals"
        [prefixTranslate]="'inter-chain.online.table'"
      ></widgets-table-periods>
    </ng-container>

  </mh-box-element>

</mh-alerts-wrapper>
