import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AddDataMenu, AddDataSidebar, AddDataTopbar, AddDataUsermenu, ResetMenuState } from './menus.actions';
import { MENU_IDS, MenuItem } from './menus.model';
import { Injectable } from '@angular/core';
import { ProductsService } from 'app/_services';
import { forkJoin } from 'rxjs';
export interface MenusModel {
  sidebar: MenuItem[];
  topbar: MenuItem[];
  usermenu: MenuItem[];
  configs: MenuItem[];
  managers: MenuItem[];
}

@State<MenusModel>({
  name: 'menus',
  defaults: {
    sidebar: [],
    topbar: [],
    usermenu: [],
    configs: [],
    managers: [],
  },
})
@Injectable()
export class MenusState {
  constructor(private productService: ProductsService) {}

  @Selector()
  static currentMenuSidebar(state: MenusModel) {
    return {
      sidebar: state.sidebar,
      configs: state.configs,
      managers: state.managers,
    };
  }

  @Selector()
  static currentMenuTopbar(state: MenusModel) {
    return state.topbar;
  }

  @Selector()
  static currentUserMenu(state: MenusModel) {
    return state.usermenu;
  }

  @Action(AddDataMenu)
  async addDataMenu(context: StateContext<any>, action: { hotelId: string }) {
    const menus = await forkJoin({
      sidebar: this.productService.getMenuConfig(action.hotelId, MENU_IDS.SIDEBAR),
      topbar: this.productService.getMenuConfig(action.hotelId, MENU_IDS.TOPBAR),
      usermenu: this.productService.getMenuConfig(action.hotelId, MENU_IDS.USERMENU),
      managers: this.productService.getMenuConfig(action.hotelId, MENU_IDS.MANAGERS),
      configs: this.productService.getMenuConfig(action.hotelId, MENU_IDS.CONFIGS),
    }).toPromise();

    context.patchState({
      ...menus,
    });
  }

  @Action(AddDataSidebar)
  addDataSidebar(context: StateContext<any>, action: AddDataSidebar) {
    context.patchState({
      sidebar: action.dataMenu,
    });
  }

  @Action(AddDataTopbar)
  addDataTopbar(context: StateContext<any>, action: AddDataTopbar) {
    context.patchState({
      topbar: action.dataMenu,
    });
  }

  @Action(AddDataUsermenu)
  addDataUserMenu(context: StateContext<any>, action: AddDataUsermenu) {
    context.patchState({
      usermenu: action.dataMenu,
    });
  }

  @Action(ResetMenuState)
  resetMenuState(context: StateContext<any>) {
    context.setState({
      sidebar: [],
      topbar: [],
      usermenu: [],
      configs: [],
      managers: [],
    });
  }
}
