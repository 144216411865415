<div class="thumbnail-wrapper {{border}}">
  <div class="thumbnail">
    <img
      [src]="user | photoOrDefault: activeMenu"
      alt="user profile photo"
      class="thumbnail-img"
      [ngStyle]="{width: size + measure, height: size + measure}"
      />
  </div>
</div>
