<mat-accordion [class.invalid]="invalid">
  <mat-expansion-panel [expanded]="false">
    <mat-expansion-panel-header>
      <mat-panel-title *ngIf="label">
        <b>{{label}}</b>
      </mat-panel-title>
      <mat-panel-description>
        <span
          mhFindTranslate
          [language]="language"
          [translates]="translates"
          [keyTranlsate]="keyTranlsate"
          (inputTranslate)="onInputTranslate($event)"
          >
        </span>
        <i *ngIf="checkEmptyInputs(translates, keyTranlsate) && translateText?.length === 0 && keyTranlsate == 'title' && !label">{{'survey.configurations.sections.empty_section_title' | translate}}</i>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
  </mat-expansion-panel>
</mat-accordion>
