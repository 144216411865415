<mh-box-element [title]="( (isTop() ? 'shared.semantic.mentions.title_'+top : 'shared.semantic.mentions.title') | translate:({count:topQuantity}))">
  <mh-wait-table *ngIf="waiting" [isHeavyQuery]="isHeavyQuery" [index]="messageIndex" [messages]="messagesWaiting" [numberColumns]="5"></mh-wait-table>

  <mat-tab-group *ngIf="!waiting">
    <ng-container *ngFor="let data of dataMentions">
      <mat-tab label="{{('shared.semantic.mentions.filters.'+data.type | translate)}}">
        <semantic-mention [data]="data"></semantic-mention>
      </mat-tab>
    </ng-container>

  </mat-tab-group>

</mh-box-element>