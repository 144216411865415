import { Component, Input } from '@angular/core';
//DEPRECADO!!! USAR WIDGET/UTILS/ARROW
@Component({
  selector: 'mh-arrow',
  templateUrl: './arrow.component.html',
  styleUrls: ['../../../../../assets/scss/shared/elements/arrow.scss'],
})
export class ArrowComponent{

  @Input()
  number=0

  @Input()
  height='30px';

  type;

  constructor(){}

  ngOnInit(){
    this.type = this.number > 0 ?  'up' : 'down';
    this.type = this.number === 0 || this.number === undefined ? 'none' : this.type;
  }

  style(){
    return {
      height: this.height
    }
  }
}
