<article class="tw-flex tw-space-x-4">
  <button
      class="tw-rounded-full md:tw-w-fit tw-w-14 tw-h-14 tw-bg-white tw-text-main tw-shadow-1 tw-flex tw-justify-center md:tw-px-7 md:tw-py-2 focus:tw-outline-0 tw-transition-all"
      [class.!tw-bg-main]="isOpen"
      [class.tw-text-white]="isOpen"
      (click)="isOpen = !isOpen"
      cdkOverlayOrigin #trigger="cdkOverlayOrigin"
      >
      <i class="fas fa-filter tw-self-center"></i>
      <span class="tw-hidden md:tw-inline-block tw-ml-2 tw-text-base tw-font-medium tw-self-center tw-text-main-text" [class.!tw-text-white]="isOpen">
        {{'core-lib.selectors.desk.filter_by' | translate}}
      </span>
  </button>

  <button class="tw-rounded-full tw-bg-white tw-text-main tw-shadow-1 tw-w-14 tw-h-14 hover:tw-text-white hover:tw-bg-main tw-transition-all focus:tw-outline-0" (click)="clearFilters()" *ngIf="areFiltersApplied">
    <div class="tw-relative">
      <i class="fas fa-filter tw-self-center"></i>
      <i class="fas fa-times-circle tw-absolute tw-text-red-500 tw-text-small tw-right-3.5"></i>
    </div>
  </button>
</article>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPanelClass]="['tw-mx-auto', 'tw-absolute', 'overlay-centered']"
>
<div class="tw-bg-white tw-shadow-1 tw-rounded tw-flex tw-flex-col tw-mt-3" id="overlayFiltersDesk">

  <div class="tw-flex tw-flex-col xl:tw-flex-row xl:tw-border-b-2 xl:tw-border-border-light-gray">
      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-pb-1 tw-pt-3 tw-px-3 xl:tw-p-3 tw-overflow-y-scroll">
        <div class="tw-flex" (click)="toggleMobileFilter('author')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{ 'cases.commons.created_by' | translate }}</span>
          <i [@rotate]="authorFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="authorFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="authorOptions"
              [displayOptionsFn]="displayPersonOptionsFn"
              [keySelected]="'isFiltering'"
              (click)="ga('authors')"
              (optionClicked)="setAuthorFilter(cases, $event)">
          </mh-core-lib-checkbox-input>

        </div>
      </div>

      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3 tw-overflow-y-scroll">
        <div class="tw-flex" (click)="toggleMobileFilter('assigned')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{ 'cases.commons.assigned_to' | translate }}</span>
          <i [@rotate]="assignedFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="assignedFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="assignedOptions"
              [displayOptionsFn]="displayPersonOptionsFn"
              [keySelected]="'isFiltering'"
              (click)="ga('assigned')"
              (optionClicked)="setAssignedFilter(cases, $event)">
          </mh-core-lib-checkbox-input>
        </div>
      </div>
      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3">
        <div class="tw-flex" (click)="toggleMobileFilter('status')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{ 'cases.commons.stage' | translate }}</span>
          <i [@rotate]="statusFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="statusFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="statusOptions"
              [needTranslate]="false"
              [needObjectTranslate]="true"
              [keySelected]="'isFiltering'"
              (click)="ga('stages')"
              (optionClicked)="setStatesFilter(cases, $event)">
          </mh-core-lib-checkbox-input>
        </div>
      </div>
      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3">
        <div class="tw-flex" (click)="toggleMobileFilter('stage')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{ 'cases.commons.status' | translate }}</span>
          <i [@rotate]="statusFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="statusFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="stageOptions"
              [needTranslate]="true"
              [translatePrefix]="'cases.commons.'"
              [labelText]="'subcategory_state_generic'"
              [keySelected]="'isFiltering'"
              (click)="ga('states')"
              (optionClicked)="setStageFilter(cases, $event)">
          </mh-core-lib-checkbox-input>
        </div>
      </div>
      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3">
        <div class="tw-flex" (click)="toggleMobileFilter('seen')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{ 'cases.commons.seen' | translate }}</span>
          <i [@rotate]="statusFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="statusFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="seenOptions"
              [needTranslate]="true"
              [translatePrefix]="'cases.commons.'"
              [keySelected]="'isFiltering'"
              (click)="ga('seen')"
              (optionClicked)="setSeenFilter(cases, $event)">
          </mh-core-lib-checkbox-input>
        </div>
      </div>
  </div>
  <div class="tw-flex tw-flex-col xl:tw-flex-row xl:tw-border-b-2 xl:tw-border-border-light-gray">
    <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3 tw-overflow-y-scroll">
      <div class="tw-flex" (click)="toggleMobileFilter('product')">
        <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{ 'cases.detail_modal.source' | translate }}</span>
        <i [@rotate]="productFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
      </div>
      <div  [@collapsedHeightState]="productFilterStatus ? 'expand' : 'collapse'">
        <mh-core-lib-checkbox-input
            [options]="productOptions"
            [needTranslate]="false"
            [keySelected]="'isFiltering'"
            [displayOptionsFn]="displayProductOptions"
            (click)="ga('products')"
            (optionClicked)="setProductFilter(cases, $event)">
        </mh-core-lib-checkbox-input>
      </div>
    </div>
      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3">
        <div class="tw-flex" (click)="toggleMobileFilter('priority')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{ 'cases.commons.priority' | translate }}</span>
          <i [@rotate]="priorityFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="priorityFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="priorityOptions"
              [needTranslate]="false"
              [needObjectTranslate]="true"
              [keySelected]="'isFiltering'"
              (click)="ga('priorities')"
              (optionClicked)="setPriorityFilter(cases, $event)">
          </mh-core-lib-checkbox-input>
        </div>
      </div>
      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3 tw-overflow-y-scroll">
        <div class="tw-flex" (click)="toggleMobileFilter('type')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{ 'cases.detail_modal.incident_type' | translate }}</span>
          <i [@rotate]="typeFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="typeFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="typeOptions"
              [needTranslate]="false"
              [needObjectTranslate]="true"
              [keySelected]="'isFiltering'"
              (click)="ga('types')"
              (optionClicked)="setTypeFilter(cases, $event)">
          </mh-core-lib-checkbox-input>
        </div>
      </div>
      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3 tw-overflow-y-scroll">
        <div class="tw-flex" (click)="toggleMobileFilter('team')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{ 'cases.commons.team' | translate }}</span>
          <i [@rotate]="assignedFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="assignedFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="teamOptions"
              [displayOptionsFn]="displayTeamOptionsFn"
              [keySelected]="'isFiltering'"
              (click)="ga('teams')"
              (optionClicked)="setTeamFilter(cases, $event)">
          </mh-core-lib-checkbox-input>
        </div>
      </div>
      <div class="tw-min-w-[240px] xl:tw-min-h-[200px] tw-max-w-[240px] tw-max-h-[200px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3 tw-overflow-y-scroll">
        <div class="tw-flex" (click)="toggleMobileFilter('area')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{ 'cases.commons.areas' | translate }}</span>
          <i [@rotate]="areaFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="areaFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-tag-input
              [options]="areas"
              [displayChipsFn]="displayChipsFn"
              [displayChipsListFn]="displayChipsFn"
              [allSelected]="false"
              [checkedAllOption]="false"
              [showAutocompleteInput]="true"
              [inputTagFilter]="inputTagFilter"
              [displayCheckInput]="false"
              [boxOpen]="false"
              [placeholder]="'core-lib.input.dialog_cases.areas' | translate"
              (click)="ga('areas')"
              (optionClicked)="setAreaFilter(cases, $event)"
              ></mh-core-lib-tag-input>
        </div>
      </div>
  </div>
</div>
</ng-template>
