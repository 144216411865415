<button
  class="tw-rounded-full md:tw-w-fit tw-w-14 tw-h-14 tw-bg-white tw-text-main tw-shadow-1 tw-flex tw-justify-center md:tw-px-7 md:tw-py-2 focus:tw-outline-0"
  (click)="isOpen = !isOpen" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
  [class.!tw-bg-main]="isOpen"
  [class.!tw-text-white]="isOpen"
  >
  <i class="fas fa-align-left tw-self-center tw-text-lg md:tw-text-base"></i>
  <span class="tw-hidden tw-ml-2 md:tw-inline-block tw-text-base tw-font-medium tw-self-center tw-text-main-text" [class.!tw-text-white]="isOpen">
    {{'commons.surveys' | translate}}:
    <ng-container *ngTemplateOutlet="selectedSurveys"></ng-container>
  </span>
</button>


<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
>
<article class="tw-rounded-lg tw-shadow-xl tw-p-3 tw-bg-white tw-max-w-sm tw-mt-1" id="overlaySelectorSurvey">
  <mh-core-lib-tag-input
    [options]="surveys$ | async"
    [selectedOptions]="surveysSelected"
    [displayChipsFn]="displayFn"
    [displayChipsListFn]="displayFn"
    (optionClicked)="handleOptionClicked($event)"
  ></mh-core-lib-tag-input>
</article>
</ng-template>

<ng-template #selectedSurveys>
  <ng-container *ngIf="areAllSurveysSelected">
    {{buttonLabel | translate}}
  </ng-container>
  <ng-container *ngIf="!areAllSurveysSelected">
    {{surveysSelected.length}} {{buttonLabel | translate}}
  </ng-container>
</ng-template>
