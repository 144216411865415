<div class="tw-flex">
  <ng-container *ngFor="let type of options; let i = index">
    <button
      (click)="handleSelectedOption(type)"
      type="button"
      class="tw-p-0 tw-shadow-lg tw-bg-gray-100 tw-text-sm tw-font-semibold tw-text-gray-600 first:tw-rounded-l-full last:tw-rounded-r-full focus:tw-outline-none tw-h-[42px] tw-w-[50px] transition-all duration-200 ease-in-out hover:tw-bg-gray-200"
      [ngClass]="{ 'tw-bg-white !tw-text-black tw-shadow-md': selectedType === type }"
      [mhTooltip]="('answer_survey.' + type | translate)"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center" *ngIf="i === 0">
        <div class="tw-w-5 tw-h-[3.3px] tw-bg-gray-400 transition-all duration-200" [ngClass]="{ 'tw-bg-black': selectedType === type }"></div>
        <div class="tw-w-5 tw-h-[3.3px] tw-my-[2px] tw-bg-gray-400 transition-all duration-200" [ngClass]="{ 'tw-bg-black': selectedType === type }"></div>
        <div class="tw-w-5 tw-h-[3.3px] tw-bg-gray-400 transition-all duration-200" [ngClass]="{ 'tw-bg-black': selectedType === type }"></div>
      </div>
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center" *ngIf="i === 1">
        <div class="tw-w-5 tw-h-[5px] tw-bg-gray-400 transition-all duration-200 tw-mb-[2px]" [ngClass]="{ 'tw-bg-black': selectedType === type }"></div>
        <div class="tw-w-5 tw-h-[5px] tw-bg-gray-400 transition-all duration-200" [ngClass]="{ 'tw-bg-black': selectedType === type }"></div>
      </div>
    </button>
  </ng-container>
</div>
