<perfect-scrollbar class="scrollbar">

  <aside id="main_menu" class="d-flex flex-column" [style.paddingTop.px]="statusNavbar !== 0 ? '0' : paddingTop">

    <div *ngIf="statusNavbar !== 0" [ngClass]="{'fadein': !isLoading, 'fadeout': isLoading}" class="back-button pointer" (click)="setStatus(0)">
      <span class="mr-2"><i class="fal fa-arrow-left"><span *ngIf="!sideBarCollapsed" class="back-button-label pointer">{{ 'navbar.back_button' | translate }}</span></i></span>
    </div>

  <tree-root #navbar_tree [ngClass]="{'fadein': !isLoading, 'fadeout': isLoading}" [nodes]="nodes" [options]="options" (stateChange)="stateChange($event)" (updateData)="updateData($event)" (changeFilter)="changeFilter($event)">
      <ng-template #treeNodeFullTemplate
        let-node
        let-index="index"
        let-templates="templates">
        <div
          [class]="node.getClass()+' '+node.data.product"
          [class.tree-node]="true"
          [class.tree-node-expanded]="node.isExpanded && node.hasChildren"
          [class.tree-node-collapsed]="node.isCollapsed && node.hasChildren"
          [class.tree-node-leaf]="node.isLeaf"
          [class.tree-node-active]="node.isActive"
          [class.tree-node-focused]="node.isFocused"
          >

          <tree-node-drop-slot
            *ngIf="index === 0"
            [dropIndex]="node.index"
            [node]="node.parent">
          </tree-node-drop-slot>
          <div class="node-wrapper {{node.data.product}}" [class.active]="isCurrent(node)" [ngClass]="{'no-color-interchain-root': (node.data.product === 'interchain' && (!node.isLeaf && node.data.isRoot())), 'color-interchain-leaf': node.data.product === 'interchain' && (node.isLeaf && !node.data.isRoot())}">
              <div class="node-content-wrapper {{node.data.product}}"
                [class.node-content-wrapper-active]="node.isActive"
                [class.node-content-wrapper-focused]="node.isFocused"
                (click)="node.mouseAction('click', $event)"
                (contextmenu)="node.mouseAction('contextMenu', $event)"
                (treeDrop)="node.onDrop($event)"
                [treeAllowDrop]="node.allowDrop"
                [treeDrag]="node"
                [treeDragEnabled]="node.allowDrag()"
                >
                <mh-navbar-item [item]="node.data" [statusNavbar]="statusNavbar" (isInterchainClicked)="updateStatus($event)"></mh-navbar-item>
              </div>
          </div>

          <tree-node-children [node]="node" [templates]="templates">
          </tree-node-children>
          <tree-node-drop-slot [dropIndex]="node.index + 1" [node]="node.parent">
          </tree-node-drop-slot>
        </div>
      </ng-template>
  </tree-root>
  <div class="tw-relative tw-my-auto tw-w-full" *ngIf="(statusNavbar === 0 || statusNavbar === 3) && +customerId !== 12 && displayStates && !isMobile && showIntegrationState">
    <mh-core-lib-integration-status
        [currentHotel]="currentCustomer$ | async"
        [otasErrors]="chainOtasErrors"
        [simpleOtasErrors]="otasErrors"
        [currentLanguage]="currentLanguage"
        [menuContext]="menuContext"
        [loading]="loadingOtaStatus"
        [sideBarCollapsed]="sideBarCollapsed"
        [statusNavbar]="statusNavbar"
        (integrationButtonClicked)="handleIntegration($event)"
      ></mh-core-lib-integration-status>
  </div>

  </aside>
</perfect-scrollbar>
<span [ngClass]="{'fadein': !isLoading, 'fadeout': isLoading, 'toggle-button-collapse justify-content-center': !showCollapseLabel, 'toggle-button-expanded ml-4': showCollapseLabel }"
  class="mb-3 d-flex flex-fill align-items-end toggle-button"
  *ngIf="!isMobile">
  <i [ngClass]="{'fa-angle-double-left': !sideBarCollapsed, 'fa-angle-double-right': sideBarCollapsed}" class="fal pointer" (click)="collapseSidenav()"><span *ngIf="showCollapseLabel" class="collapse-button-text pointer">{{ 'navbar.collapse_button' | translate }}</span></i>
</span>


<ng-template #StatusOTA let-dismiss="dismiss">
  <mh-modal
    [title]="'admin.otas.modal_title' | translate"
    [close]="dismiss"
    [headerStyles]="['custom-header']"
    >
    <div class="my-3">
      <div class="list-group">
        <ng-container *ngFor="let ota of otasErrors">
          <div class="list-group-item ota-error-container"
            *ngIf="(ota.error.public_error && currentUser.admin) || currentUser.superAdmin">
            <div class="mb-1 d-flex justify-content-between">
              <h5>{{ota.ota_name}}:</h5>
              <a [href]="manualLink" target="_blank">
                {{('admin.otas.error.reset_link' | translate)}}
              </a>
            </div>
            <div class="error-body">
              <p><strong>{{('admin.otas.error.since' | translate)}}:</strong> {{ota.since | date:'dd-MM-y'}}</p>
              <p><strong>{{('admin.otas.error.last_update' | translate)}}:</strong> {{ota.last_update | date:'dd-MM-y'}}</p>
              <p><strong>{{('admin.otas.error.error' | translate)}}:</strong> {{ota.error.translates |
                translateObject:'text':currentLanguage }}</p>
              <p><strong>{{('admin.otas.error.type_error' | translate)}}:</strong> {{(getTranslateTypeError(ota) | translate)}}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </mh-modal>
</ng-template>

<ng-template #StatusPMS let-dismiss="dismiss">
  <mh-modal
    [title]="'admin.pms.modal_title' | translate"
    [close]="dismiss"
    [headerStyles]="['custom-header']">
    <div class="py-3 text-center"
      [innerHtml]="('header.tooltips.integration' | translate:{date: (util.currentHotel.integration_status_dto.last_update | formatDate )})">
    </div>
  </mh-modal>
</ng-template>
