import { Component, HostListener} from '@angular/core';
import { UtilService } from "../../shared/util.service";

@Component({
  template: '',
  providers:[]
})
export class LayoutsComponent{

  public screenWidth: number;
  public screenHeight: number;
  public route: string;
  public url;
  public section;
  theme:string;
  currentLanguage:string='es';

  constructor(protected utilService:UtilService){
    this.setSizes();
    this.utilService.isForceResize().subscribe(reponse=>{
      this.setSizes();
    })
  }

  setLanguage(iso){
    this.utilService.setCurrrentLanguage(iso);
  }


  setSizes(){
    let padding = this.utilService.getCurrentUser() ? 65 : 0;
    this.utilService.screenWidth = (window.screen.width);
    this.utilService.screenHeight = document.documentElement.clientHeight - padding;
    this.screenHeight = this.utilService.screenHeight;
  }

  setCurrentSection(){
    this.url  = this.route
    this.utilService.setCurrentSection(this.url);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setSizes();
  }


}
