<div class="row no-gutters align-items-center card-conversion" [class.waiting]="waiting">
  <div class="col-12">
    <div class="row no-gutters align-items-center sents">
      <div class="col-4 align-right">
        <div class="number">
          {{totalSents}}
        </div>
      </div>
      <div class="col-8 align-left">
        <div class="rate_result">
          <div class="label">
            {{'prestay.summary.conversion.rates.sents' | translate }}
            <i *ngIf="tooltipRate" container="body" placement="left" class="description-tooltip fas fa-question-circle" [ngbTooltip]="tooltipDescription"></i>
            <ng-template #tooltipDescription>
              <div [innerHtml]="('prestay.summary.conversion.tooltips.sents' | translate:({totalAnswered:totalAnswered,totalSents:totalSents}) )"></div>
            </ng-template>
          </div>
          <div class="see_detail">
            <button class="btn btn-link" (click)="openSentDialog(modalDetail)">{{'commons.see_details' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>  
  
  <div class="col-12 details">
    <div class="row no-gutters align-items-center" >
      <ng-container *ngFor="let conversion of conversions">
        <div class="col-4 conversion_box" *ngIf="conversion.label!=='sents'" >
          <div class="rate_type">
            <div class="type">
              <span>
                {{conversion.translatePrefix+'.'+conversion.label | translate }}
                <i *ngIf="conversion.tooltip" container="body"  class="description-tooltip fas fa-question-circle" [ngbTooltip]="tooltipDescription"></i>
              </span>
              <ng-template #tooltipDescription>
                <div [innerHtml]="(conversion.tooltip | translate )"></div>
              </ng-template>
            </div>
          </div>
          <div class="quantity">{{conversion.rate | formatPercent }}</div>
          <div class="formula">({{conversion.count}}/{{totalSents}})</div>
          <button class="btn btn-link" (click)="openDialog(modalDetail,conversion)">{{'commons.see_details' | translate }}</button>
        </div>
      </ng-container>
    </div>

  </div>
  
</div>

<ng-template #modalDetail>
  <div class="modal-header">
    <h4 class="title" [innerHTML]="(modalTitle | translate)"></h4>
    <div class="close" (click)="closeDialog()">
      <i class="far fa-times"></i>
    </div>
  </div>

  <div class="modal-body">
    <ng-content></ng-content>
  </div>
</ng-template>


