<div class="row">
  <div class="col-12">
    <div class="waiting" [hidden]="!waiting">
      <mh-spinner-load></mh-spinner-load>
    </div>

    <div class="change_password" *ngIf="!waiting && !notToken && !hasError && showForm">
      <form class="general_set_form" (ngSubmit)="changePassword()" [formGroup]="setForm" #formDir="ngForm">
        <div [hidden]="formDir.submitted">
          <div *ngFor="let key of validates" class="form-group">
            <label for="{{key}}">{{'forms.'+key | translate}}</label>

            <ng-container [ngSwitch]="key">
              <ng-template [ngSwitchCase]="'new_password'">
                <input [type]="showPassword ? 'text' : 'password'" id="{{key}}" class="form-control" formControlName="{{key}}"
                  [ngClass]="{
                    'is-valid'   : confirm_password.valid
                  }"
                  required >
                  <div class="tw-absolute tw-top-8 tw-right-5 tw-cursor-pointer">
                    <i class="fas fa-eye{{showPassword ? '-slash' : ''}}" (click)="showPassword = !showPassword"></i>
                  </div>
              </ng-template>

              <ng-template [ngSwitchCase]="'confirm_password'">
                <input [type]="showRepeatPassword ? 'text' : 'password'" id="{{key}}" class="form-control" validateEqual="new_password" reverse="false" formControlName="{{key}}"
                  [ngClass]="{
                    'is-valid'   : confirm_password.valid,
                    'is-invalid' : (!isEmpty(confirm_password) && !confirm_password.valid)
                  }"
                  required >
                  <div class="tw-absolute tw-top-8 tw-right-5 tw-cursor-pointer">
                    <i class="fas fa-eye{{showRepeatPassword ? '-slash' : ''}}" (click)="showRepeatPassword = !showRepeatPassword"></i>
                  </div>
              </ng-template>


              <ng-template [ngSwitchCase]="'email'">
                <input type="hidden" id="{{key}}" class="form-control" formControlName="{{key}}" >
                <div class="username">{{user.profile.email}}</div>
              </ng-template>
            </ng-container>
          </div>
          <!-- Validation Errors -->
          <mh-strength-password 
            [possibleErrors]="possibleErrors" 
            [newPassControl]="new_password" 
            [confirmControl]="confirm_password">
          </mh-strength-password>
          <div class="submit_form">
            <button type="submit" [disabled]="setForm.invalid" class="btn submit">{{'forms.save_changes' | translate}}</button>
          </div>
        </div>
      </form>
    </div>

    <div class="has_error" *ngIf="hasError || notToken">
      <span [innerHtml]="(errorTag | translate:({alive_time:token_alive_time}))"></span>
      <div class="recovery_password">
        <a routerLink="/recordar-contrasena" class="btn btn-orange icon-left"><i class="fal fa-key"></i>{{'forms.forgot_password' | translate}}</a>
      </div>
      <div class="contact" [innerHtml]="( 'support.contacts.post_sale.type_1' | translate)"></div>
    </div>

    <div class="go_home">
      <a routerLink="/login">{{'forgot_password.go_home' | translate}}</a>
    </div>

  </div>
</div>
