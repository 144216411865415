<ng-container *ngIf="isCreate else itemDetail">
  <ng-container *ngTemplateOutlet="itemCreate">
  </ng-container>
</ng-container>

<ng-template #itemCreate>
  <div class="tw-relative">

    <article class="tw-px-6 tw-py-4 tw-flex tw-flex-col tw-text-main-text">

      <section class="tw-self-center tw-flex tw-flex-col tw-items-center tw-gap-0.5 tw-mb-2">
        <span class="tw-font-medium">{{item.name}} {{item.lastname}}</span>
        <span class="tw-text-sm">{{item.email}}</span>
        <span class="tw-text-sm">{{item.phone}}</span>
        <span class="tw-flex tw-items-center tw-gap-3">
          <span [ngStyle]="{'width': 20+'px', 'height': 20+'px'}" class="flag-icon flag-icon-squared flag-icon-{{item.country_code.toLowerCase()}} tw-rounded-full"></span>
          <span class="tw-text-sm tw-font-medium tw-self-center">{{'countries.'+item.country_code | translate}}</span>
        </span>
      </section>

      <section class="tw-flex tw-flex-col tw-gap-1">
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="fas fa-portrait tw-mr-2 tw-w-4"></i> <span>{{'models.prestay_login.reservation_code' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{item.reservation_id}}</span>
        </div>
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="fas fa-calendar-check tw-mr-2 tw-w-4"></i> <span>{{'models.preckeckin-guest.response_date' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{(item.response_date ? (item.response_date | formatDate: 'es') : '-')}}</span>
        </div>
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="far fa-calendar-alt tw-mr-2 tw-w-4"></i> <span>{{'models.order.checkin' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{item.checkin | formatDate: 'es'}}</span>
        </div>
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="fas fa-calendar-alt tw-mr-2 tw-w-4"></i> <span>{{'models.order.checkout' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{item.checkout | date: 'shortTime'}}</span>
        </div>
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="fas fa-clock tw-mr-2 tw-w-4"></i> <span>{{'models.preckeckin-guest.checkin_hour' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{item.checkin_hour}} hrs</span>
        </div>
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="fas fa-bed tw-mr-2 tw-w-4"></i> <span>{{'models.order.room' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{item.room}}</span>
        </div>
      </section>

    </article>

  </div>
</ng-template>

<ng-template #itemDetail>
  <div class="tw-hidden md:tw-block">
    <ng-container *ngTemplateOutlet="desktopDetail"></ng-container>
  </div>
  <div class="md:tw-hidden">
    <ng-container *ngTemplateOutlet="mobileDetail"></ng-container>
  </div>
</ng-template>

<ng-template #mobileDetail>
  <div class="tw-px-2 tw-py-3 tw-gap-1 tw-flex tw-flex-col tw-text-main-text">
    <!-- info huesped -->
    <span class="tw-font-semibold tw-text-sm">{{item.guest.name}} {{item.guest.last_name}}</span>
    <span class="tw-text-sm">{{item.guest.email}}</span>
    <div class="tw-flex tw-gap-5">
      <i class="fas fa-phone"></i>
      <span class="tw-text-sm">{{'forms.phone' | translate}}: {{item.guest.phone_number}}</span>
    </div>
    <div class="tw-flex tw-gap-5">
      <i class="fas fa-barcode"></i>
      <span class="tw-text-sm">{{'models.prestay_login.reservation_code' | translate}}: <span class="tw-font-semibold">{{item.guest.reservation_id}}</span></span>
    </div>
    <div class="tw-flex tw-gap-5">
      <i class="fas fa-calendar-check"></i>
      <span class="tw-text-sm">{{'models.preckeckin-guest.response_date' | translate}}: <span class="tw-font-semibold">{{item.answer_date ? (item.answer_date | formatDate) : '-'}}</span></span>
    </div>
    <div class="tw-flex tw-gap-5">
        <i class="fas fa-calendar"></i>
        <span class="tw-text-sm">{{'models.preckeckin-guest.checkin' | translate}}: <span class="tw-font-semibold">{{item.guest.checkin | formatDate}}</span></span>
    </div>
    <div class="tw-flex tw-gap-5">
        <i class="fas fa-calendar-alt"></i>
        <span class="tw-text-sm">{{'models.preckeckin-guest.checkout' | translate}}: <span class="tw-font-semibold">{{item.guest.checkout | formatDate}}</span></span>
    </div>
    <div class="tw-flex tw-gap-5">
      <i class="fas fa-clock"></i>
      <span class="tw-text-sm">{{'models.preckeckin-guest.checkin_hour' | translate}}: <span class="tw-font-semibold">{{item.checkin_hour}}</span></span>
    </div>
    <div class="tw-flex tw-gap-5">
      <i class="fas fa-bed tw-text-sm"></i>
      <span class="tw-text-sm">{{'commons.room' | translate}}: <span class="tw-font-semibold">{{item.guest.room}}</span></span>
    </div>
    <div class="tw-flex tw-gap-5">
      <a href="{{url}}" target="_blank" class="tw-text-main tw-font-semibold">{{'commons.see_details' | translate}}</a>
    </div>
  </div>

</ng-template>

<ng-template #desktopDetail>
  <div class="tw-px-5 tw-py-6 tw-flex">
    <!-- info huesped -->
    <section class="tw-min-w-[200px] tw-flex tw-flex-col tw-text-main-text">
      <span class="tw-font-semibold tw-text-sm">{{item.guest.name}} {{item.guest.last_name}}</span>
      <span class="tw-text-sm">{{item.guest.email}}</span>
      <span class="tw-text-sm">{{'forms.phone' | translate}}: {{item.guest.phone}}</span>
      <span class="tw-text-sm">{{'models.order.reservation_id' | translate}}: <span class="tw-font-semibold">{{item.guest.reservation_id}}</span></span>
      <a href="{{url}}" target="_blank" class="tw-text-main tw-font-semibold">{{'commons.see_details' | translate}}</a>
    </section>

    <!-- info precheckin -->
    <section class="tw-flex tw-gap-5 tw-text-main-text tw-items-center">
      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-calendar"></i>
        <span class="tw-text-sm">{{'models.preckeckin-guest.response_date' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm">{{item.answer_date ? (item.answer_date | formatDate) : '-'}}</span>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-calendar"></i>
        <span class="tw-text-sm">{{'models.preckeckin-guest.checkin' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm">{{item.guest.checkin | formatDate}}</span>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-calendar-alt"></i>
        <span class="tw-text-sm">{{'models.preckeckin-guest.checkout' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm">{{item.guest.checkout | formatDate}}</span>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-clock"></i>
        <span class="tw-text-sm">{{'models.preckeckin-guest.checkin_hour' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm">{{item.checkin_hour}}</span>
      </div>


      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-bed"></i>
        <span class="tw-text-sm">{{'commons.room' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm">{{item.guest.room}}</span>
      </div>

    </section>
  </div>
</ng-template>
