import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User, Comment, UserMention } from 'app/shared/cases/types';
import { LogType } from 'app/shared/logs/types';
import { ActivitiesService } from 'app/shared/cases/services/activities.service';
import { UtilService } from 'app/layouts/main';
import { ConfirmationModalService } from 'app/shared/confirmation-modal';
import { MentionConfig } from 'angular-social-mentions';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FilesService } from 'app/shared/services/files.service';
import { Subscription } from 'rxjs';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { Store } from '@ngxs/store';

const LOG_ICONS = {
  caseChangeState: 'bell',
  caseChangeAssigned: 'user-edit',
  editCase: 'pencil',
  createCase: 'plus',
  default: 'pencil',
}
const FOLLOWUP_SURVEY:string = '1_1';
const ONSITE_SURVEY:string = '2_1';
const ONLINE_REVIEW:string = '3_2';
const PRESTAY_PRECHECKIN:string = '4_3';
const PRESTAY_ORDER:string = '4_4';

@Component({
  selector: 'mh-case-activity',
  templateUrl: './case-activity.component.html',
  styleUrls: ['./case-activity.component.scss']
})
export class CaseActivityComponent implements OnInit, OnDestroy {
  languageId = this.store.selectSnapshot(DefaultLanguageState.languageId);
  PRODUCT_MODULE_NAMES = {
    [FOLLOWUP_SURVEY]: 'Follow Up',
    [ONSITE_SURVEY]: 'Onsite',
    [ONLINE_REVIEW]: 'Online',
    [PRESTAY_PRECHECKIN]: 'Prestay Web Check in',
    [PRESTAY_ORDER]: 'Prestay Servicios',
  }
  SUBSCRIPTION_REQUEST_METHOD = 'caseSubscribeAction';
  GENERAL_ERROR = 'waiting.messages.wait_fail';
  ACTIVITY_COMMENTY_TYPE = {id: 1}

  @Input() caseId: number;
  @Input() users: User[];
  @Input() lastChange: Date; // to force fetches when something changes

  @Output() commentAdded = new EventEmitter<void>()

  comments: any[] = [];
  logs: any[] = [];
  commentsAndLogs: any[];

  commentForm: FormGroup | null;
  originalComment: any;

  loading: boolean = true;
  waitingFilesUploadStatus: any[] = [];
  waitingFileDownload: any[] = [];
  error: boolean = false;
  message: string | null;

  mentionConfig: MentionConfig = {};
  mentionState = [];
  selected = 'activity_all';
  showComments = true;
  isDraggingFile = false;

  uploadSubscriptions$: Subscription[] = [];

  @ViewChild('file_input')
  fileInput: ElementRef;

  constructor(
    private activitiesService: ActivitiesService,
    private utilService: UtilService,
    private confirmModal: ConfirmationModalService,
    private ref: ChangeDetectorRef,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private filesService: FilesService,
    private store: Store,
  ) { }

  ngOnInit() {
    this.initForm();
    this.fetchComments();
    this.fetchActivities();
    this.createItemsForMentions();
  }

  ngOnChanges({lastChange}) {
    if (lastChange && lastChange.previousValue !== lastChange.promoter_reviews) {
      this.fetchActivities();
    }
  }

  ngOnDestroy() {
    this.uploadSubscriptions$.forEach((subs) => {
      subs.unsubscribe();
    });
  }

  fetchComments() {
    this.activitiesService.getCommentsForCase(this.caseId)
      .subscribe(this.setComments)
  }

  fetchActivities() {
    this.activitiesService.getLogsForCase(this.caseId, this.languageId)
      .subscribe(this.setLogs)
  }

  initForm(event?: any, comment = {id: null, text: null}) {
    if(event) event.stopPropagation();
    if(comment.id)
      this.originalComment = comment;

    this.commentForm = this.fb.group({
      id: [comment.id],
      author_id: [this.utilService.currentUser.id],
      case_id: [this.caseId],
      type: [this.ACTIVITY_COMMENTY_TYPE],
      text: [comment.text || "", Validators.maxLength(2000)],
      attachments: this.fb.array([]),
    })
  }

  setComments = (comments: any[]): void => {
    this.comments = (<Comment[]>comments || [])
      .map(comment => ({
        ...comment,
        timestamp: comment.created_at,
        user: this.users.find(u => comment.author_id === u.id),
        icon: 'fa-comment',
      }))
      .sort((c1, c2) => c1.timestamp > c2.timestamp ? -1 : 1);

    this.loading = false;
    this.refreshCommentsAndLogs()
  }

  setLogs = (logs: any[]):void => {
    const NON_SHOWN_CHANGES = ['id', 'case_id', 'typeid', 'typename', 'item_id', 'product_id'];

    this.logs = (logs || []).filter(log => log.sub_resource_name !== 'action')
      .map(log => {
        const operation = log.request_method === 'POST' ? 'ADD' : 'EDIT'; //podria corregirlo el back
        const productType = (log.modifications.find((l:any) => l.name === 'product_id') || {}).actual
        const changes = (log.modifications || [])
          .filter((c:any) => !NON_SHOWN_CHANGES.includes(c.name))
          .map((c:any) => ({ ...c, operation }))
          .map((c:any) => c.name !== 'createdAt' ? c : {...c, actual: `${c.actual} UTC`})
          .map((c:any) => c.name !== 'product_item_type' ? c : {
            ...c,
            actual: {
              ...c.actual,
              name: this.PRODUCT_MODULE_NAMES[`${productType}_${c.actual.id}`],
            },
          });

        return {
          ...log,
          changes,
          user: this.users.find(u => log.user_id === u.id),
          resourceName: log.resource_name,
          type: LogType[log.request_method as keyof LogType],
          icon: 'fa-' + (LOG_ICONS[log.request_method_name] || LOG_ICONS.default),
          operation,
        }
      })
      .sort((l1, l2) => l1.timestamp > l2.timestamp ? -1 : 1);
    this.refreshCommentsAndLogs()
  }

  refreshCommentsAndLogs() {
    this.commentsAndLogs = [...this.comments, ...this.logs]
      .sort((a1, a2) => a1.timestamp > a2.timestamp ? -1 : 1);
  }

  handleSaveComment(): void {
    if (!this.commentForm?.value.text) return

    const value = this.commentForm?.value?.text;
    const matches = value.match(/\@\[(.*?)\]/g) || [];
    matches.forEach((match) => {
      const mention = this.mentionState.find((mention) => mention.key === match);
      if (mention) {
        this.commentForm.value.text = this.commentForm?.value?.text.replace(match, mention.value);
      }
    });

    this.ref.detach();
    this.confirmModal.withConfirmation(
      'cases.messages.confirm_comment',
      () => this.activitiesService.saveComment(this.commentForm?.value)
        .subscribe(this.afterCommentCreate, this.generalError),
      () => this.ref.reattach(),
    );
  }

  afterCommentCreate = () => {
    this.ref.reattach();
    this.loading = true;
    this.generalMessage('cases.messages.comment_add_succed', false)
    this.commentForm?.reset();
    this.initForm();
    this.originalComment = null;
    this.commentAdded.next()
    this.fetchComments();
    this.ga('comment', this.utilService.currentUser.id);
  }

  generalMessage = (errorTranslatePath: string = 'waiting.messages.wait_fail', isError: boolean) => {
    this.error = isError;
    this.message = errorTranslatePath;
    setTimeout(() => {
      this.message = null
    }, 5000)
  }

  handleEditComment() {
    throw "NOT IMPLEMENTED";
  }

  handleCancelComment() {
    this.commentForm = null;
    this.originalComment = null;
  }

  handleSaveEditComment() {
    throw "NOT IMPLEMENTED";
  }

  generalError = (error: any) => {
    if(error) this.processCaseError(error?.code)
  }

  private processCaseError(code: number) {
    const errorTranslate = this.utilService.getCaseErrorTranslate(code)
    this.generalMessage(errorTranslate, true)
  }

  isNumber(arg:any):boolean { return typeof arg === 'number';}
  ga(label?:string, value?:number) {
    const gaEventAction = `form-detail-${this.currentPath}`
    this.utilService.ga('cases', gaEventAction, label, value);
  }

  onDragOver($event) {
    $event.preventDefault();
    this.isDraggingFile = true;
  }

  onDragLeave($event) {
    const whitelist = [
      'dropzone-msg',
      'msg',
      'mat-form-field-infix',
      'col'
    ];
    const element = $event.fromElement;
    const classes = element.classList.value;
    this.isDraggingFile = whitelist.some(tag => classes.includes(tag))
  }

  handleClickFileInput() {
    this.fileInput.nativeElement.click();
  }

  handleFileInput($event) {
    const files = $event.srcElement.files;
    const fromSingleInput = true;
    this.handleDroppedFile(files, fromSingleInput);
    this.fileInput.nativeElement.value = '';
  }

  handleDroppedFile($event, fromSingleInput = false) {
    if(!fromSingleInput)
      $event.preventDefault();
    this.isDraggingFile = false;
    const files: any[] = (!fromSingleInput) ?
      [...$event.dataTransfer.files] :
      [...$event];
    files.forEach(file => {
      const size = file['size'];
      const maxSize = parseInt((size / (1024*1024)).toFixed(2));
      if(5 < maxSize) return;

      const obj = {
        name: file.name,
        uploading: true
      };
      this.waitingFilesUploadStatus.push(obj);
      const data: FormData = new FormData();
      data.append('attachment', file);
      this.uploadSubscriptions$.push(
        this.filesService.uploadPrivate(this.currentCustomerId, data)
          .subscribe((response) => {
            if(response && response.name) {
              const fileObj = {
                file_key: response.name,
                file_name: file.name
              };
              this.attachmentsControl.push(this.fb.control(fileObj));
              this.waitingFilesUploadStatus = this.waitingFilesUploadStatus.filter(fileStatus => fileStatus.name !== file.name);
            }
          })
      );
    });
  }

  handleDowloadEvent($event) {
    const downloadObj = {
      file_key: $event,
      downloading: true
    };
    this.waitingFileDownload.push(downloadObj);
    const key = $event;
    this.filesService.downloadFile(this.currentCustomerId, key).subscribe(response => {
      this.waitingFileDownload = this.waitingFileDownload.map(file => {
        if(file.file_key === key) file.downloading = false;
        return file;
      });
    });
  }

  handleRemoveFile(filekey) {
    const index = this.attachments.findIndex(attach => attach.file_key === filekey);
    this.attachmentsControl.removeAt(index);
  }

  get currentPath() {
    const possitblePaths: any[] = ['user', 'history', 'active'];
    const currentPath = this.activatedRoute.routeConfig?.path;

    if (possitblePaths.includes(currentPath)) return currentPath;

    return 'product';
  }

  get language() { return this.utilService.getCurrentLanguage(); }

  get requiredLengthError() {
    return this.commentForm.get('text').errors?.maxlength?.requiredLength;
  }

  get currentLengthError() {
    return this.commentForm.get('text').errors?.maxlength?.actualLength;
  }

  createItemsForMentions(): void {
    const usersToMention = this.users.map(({ id, first_name, last_name, email, company_position }) => ({
      email,
      id,
      mentionId: `@[${email}:${btoa(String(id))}]`,
      name: `${first_name} ${last_name} (${company_position})`,
    })
    );
    this.mentionConfig = {
      mentions: [
        {
          items: usersToMention,
          labelKey: 'name',
          triggerChar: '@',
          mentionSelect: this.formatMention,
          dropUp: true,
        },
      ]
    }
  }

  formatMention(item: UserMention): string {
    return `@[${item.email}:${btoa(String(item.id))}]`;
  }

  onInputText(event): void {
    const { value } = event.target;
    if (value && value.length > 0) {
      const controlText = this.commentForm.get('text');
      const matches = value.match(/\@\[(.*?)\]/g) || [];
      let newValue;
      matches.forEach((match: string) => {
        try {
          let match_hash = match.slice(2);
          match_hash = match_hash.slice(0, -1);
          const hashId = match_hash?.split(':') || '';
          const base64Id = +atob(hashId[1]);
          const hash = match;
          const user = this.users.find(user => user.id === base64Id);
          newValue = user && value.replace(hash, `@[${user.first_name} ${user.last_name}]`);
        } catch {
          const user = match;
          newValue = (!this.mentionState.find(mention => mention.key === user)) ?
            value.replace(user, '') :
            null;
        }
      });

      if (newValue !== null && newValue !== undefined) {
        controlText.setValue(newValue);
      }
    }
  }

  selectMention(mention): void {
    const value = mention.mentionId;
    const user = this.users.find((user) => user.id === mention.id);
    const key = `@[${user.first_name} ${user.last_name}]`;
    if (!this.mentionState.find((mention) => mention.key === key)) {
      this.mentionState = [...this.mentionState, { key, value }];
    }
  }

  tabChanged(event: MatTabChangeEvent): void {
    this.showComments = event.index !== 2;
  }

  get currentCustomerId() {
    return this.utilService?.currentHotel?.id;
  }

  get fileName() {
    return this.commentForm.get('file_name')?.value;
  }

  get attachments() {
    const control = this.attachmentsControl;
    return control.value;
  }

  get attachmentsControl() {
    return this.commentForm.get('attachments') as FormArray;
  }
}
