<div class="tw-flex tw-flex-col">
    <div class="tw-flex tw-justify-end">
        <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
    </div>

    <section class="tw-m-6">
        <h1 class="tw-text-xl tw-font-medium tw-text-title-color">
            {{ "voices.config.origins" | translate }}
        </h1>
    </section>
    <section class="tw-m-6">
        <mh-origins></mh-origins>
    </section>
</div>
