<mh-box-element [title]="('support.title' | translate)" [protectedPaid]="true">
  <div class="container box" *ngIf="!waiting">
    <div class="row">
      <div class="col-12">
        <div class="form_contact">
          <form (ngSubmit)="sendData()" [formGroup]="controlForm" #formDir="ngForm">
            <div class="fade_form"></div>
            <div class="content_form">
              <div class="form-group">
                <mat-form-field class="full_width">
                  <mat-select formControlName="question" (selectionChange)="updateOption($event)" [placeholder]="('support.form_title' | translate)">
                    <ng-container *ngFor="let questionOption of questionOptions; let i = index">
                      <mat-option [value]="i">
                        <span  mhFindTranslate [language]="language" [translates]="questionOption.translate" [keyTranlsate]="'option'"></span>
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mh-form-alerts [element]="controlForm.controls['question']"></mh-form-alerts>
                </mat-form-field>
              </div>

              <div class="form-group" [hidden]="option!==0">
                <mat-form-field class="full_width">
                    <mat-select [formControlName]="'otas'" [placeholder]="('support.list_otas' | translate)">
                      <mat-option>{{'support.list_otas' | translate}}</mat-option>
                      <ng-container *ngFor="let option of options">
                        <mat-option [value]="option">{{option}}</mat-option>
                      </ng-container>
                    </mat-select>
                </mat-form-field>
              </div>

              <mh-support-field-product 
                *ngIf="[1,2,3].includes(option)"
                [form]="controlForm"
                [products]="products"
                (productChange)="setCurrentProduct($event)">
              </mh-support-field-product>

              <mh-support-field-product-tag 
                *ngIf="[2,3].includes(option)"
                [form]="controlForm"
                [product]="currentProduct" 
                [products]="productsFull">
              </mh-support-field-product-tag>

              <div class="form-group" [hidden]="option!==3">
                <mat-form-field class="full_width">
                  <textarea class="focus:tw-ring-0" [formControlName]="'period'" matInput [placeholder]="('support.period_detail' | translate)"></textarea>
                </mat-form-field>
              </div>

              <div class="form-group" *ngIf="[4,6].includes(option)">
                <mat-form-field class="full_width">
                  <mat-select
                    [formControlName]="(option===4) ? 'inconveniences' : 'troubles'" 
                    [placeholder]="(option===4) ? ('support.list_inconveniences' | translate) : ('support.list_troubles' | translate)">
                    <mat-option>{{(option===4) ? ('support.list_inconveniences' | translate) : ('support.list_troubles' | translate)}}</mat-option>
                    <ng-container *ngFor="let element of options">
                      <mat-option [value]="element">
                          <span  mhFindTranslate [language]="language" [translates]="element.translate" [keyTranlsate]="'option'"></span>
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
              <mh-support-field-product
                [hidden]="option!==6"
                [form]="controlForm"
                [products]="products"
                (productChange)="setCurrentProduct($event)">
              </mh-support-field-product>

              <div class="form-group" [hidden]="option===null" >
                <mat-form-field class="full_width">
                  <textarea 
                  class="focus:tw-ring-0"
                  [formControlName]="'details'" 
                  matInput 
                  [placeholder]="([1,4,6,7].includes(option)) ? ('support.detail_specific_'+option | translate) : ('support.details_common' | translate)">
                </textarea>
                </mat-form-field>
              </div>

              <div class="form-group" [hidden]="option===null">
                <mh-support-field-attached-files (attachment)="setAttachment($event)"></mh-support-field-attached-files>
              </div>
              <div class="align-center">
                <button type="submit" [disabled]="controlForm.invalid || sendingData" class="btn btn-orange">{{'support.send_message' | translate}}</button>
              </div>
            </div>
          </form>
          <div class="message">
            <ng-container *ngIf="sendingData">
              <p>{{'support.send.sending' | translate}}</p>
              <mh-spinner-load></mh-spinner-load>
            </ng-container>

            <ng-container *ngIf="send_success">
              <p>{{'support.send.success' | translate}}</p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</mh-box-element>
