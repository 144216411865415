import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as Editor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';

@Component({
  selector: 'mh-editor-html',
  templateUrl: './editor-html.component.html',
  styleUrls: ['./editor-html.component.scss'],
})
export class EditorHtmlComponent implements OnInit, OnChanges {

  editor = Editor;
  @Input() data
  @Input() reset
  @Input() key
  @Output() ready: EventEmitter<any> = new EventEmitter()
  @Output() change: EventEmitter<any> = new EventEmitter()
  @Output() refresh: EventEmitter<any> = new EventEmitter()

  editorsList: any = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reset) {
      const { currentValue } = changes.reset
      if (currentValue && currentValue[0]) {
        this.editorsList.forEach((instance: any) => {
          if (instance.field === currentValue[0]) {
            instance.editor.setData(this.data)
          }
        });
      }
    }
    if (changes.data) {
      const { currentValue } = changes.data
      this.editorsList.forEach((instance: any) => {
        if (instance.field === this.key) {
          this.refresh.emit(true)
          instance.editor.setData(currentValue)
        }
      });
    }
  }

  ngOnDestroy() {
    this.editorsList = null
  }

  setEditor(event) {
    this.editorsList.push({
      editor: event,
      field: this.key
    });
  }

  setValue(value: any) {
    const fieldText = this.fixImgTag(value.editor.getData())
    this.change.emit(fieldText)
    this.refresh.emit(false)
  }

  fixImgTag(textInput) {
    const figureTag = 'figure>';
    if (!textInput.includes(figureTag)) return textInput;
    const expression1 = /<\/?figure( class="image")?>/gm;
    const expression2 = /<img /g;
    const fixedImg = textInput.replace(expression1, '');
    const fixedWidth = '<img style="max-width:485px;"';
    return fixedImg.replace(expression2, fixedWidth);
  }

}
