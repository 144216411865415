import { ChangeDetectorRef, Component,Input, OnDestroy, OnInit } from '@angular/core';
import {UtilService} from "../../../../shared/util.service";
import {User} from "../../../../shared/model/user.model";
import { Subject, Subscription} from 'rxjs';
import { Hotel } from 'app/shared/model';
import { DateService } from 'app/shared/services/date.service';

@Component({
  selector: 'mh-section-followup-properties',
  templateUrl: './section-followup-properties.component.html',
  styleUrls: ['./section-followup-properties.component.scss']
})
export class SectionFollowupPropertiesComponent implements OnInit, OnDestroy {

  @Input()
  productId=1;
  ready: boolean;
  users:User[];
  waiting:boolean = true;
  selected:User;
  userSelected = new Subject<any>();

  util: UtilService;
  report;

  chainFilter: Hotel[] = [];
  chainToReport = [];
  isReady = false;

  faqs = (new Array(1).fill(0));
  prefixTranslateFAQ = 'inter-chain.followup.properties.faq';
  showWeightedAverage = true;

  fidelityPermisions:string[] = [];
  headerTable: any[] = [];
  subscription = new Subscription();

  constructor(private utilService: UtilService, private dateService: DateService, private cd: ChangeDetectorRef) {}

  ngOnInit(){
    this.util = this.utilService;
    this.subscription = this.utilService.isCustomerChanged().subscribe(() => {
      this.initSummary();
    });
    this.utilService.currentProductId = this.productId;
    this.fidelityPermisions = this.utilService.getFidelityConfigurationsByProduct('interchain', 'interchain_followup');
    this.report = this.productId === 1 ? 'chainFollowupSummary' : 'chainOnsiteFollowupSummary';
    this.initSummary();
    this.headerTable = this.getHeaderTable();
  }

  getHeaderTable(){
    const guest_suffix = (this.isRetail && '_retail') ||
      (this.isClinic && '_clinic') ||
      '';
    const headers = [
      'customer',
      'nps',
      'tripAdvisorRanking',
      'pms',
      'mh_rate_response',
      'email_surveys',
      'open_surveys',
      'answers_quantity',
      'guests_quantity' + guest_suffix,
      'valid_guests_quantity' + guest_suffix,
      'total_mentions',
      'semantic_index'
    ]
    return (this.isRetail || this.isClinic) ? headers.filter(x=>x!=='tripAdvisorRanking') : headers;
  }

  initSummary(){
    this.headerTable = this.getHeaderTable();
    this.waiting = false;
  }

  setChain(chain){
    this.chainFilter = chain;
    this.cd.detectChanges();
  }

  setChainToReport($event) {
    this.chainToReport = $event;
  }

  dataIsReady(state){
    setTimeout(_ => {
      this.isReady = state;
    },200);
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get startDate() {
    return this.currentDates.startDate;
  }

  get endDate() {
    return this.currentDates.endDate;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }
  get isRetail(){ return this.utilService.customerIsRetail; }
  get isClinic(){ return this.utilService.customerIsClinic; }

  get reportSlug() {
    return this.productId === 1
      ? 'corporative_followup_properties_report'
      : 'corporative_onsite_properties_report';
  }
}
