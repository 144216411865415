import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Store } from '@ngxs/store'
import { Subscription } from 'rxjs';

import { UtilService } from 'app/shared/util.service';
import { SurveyWrapperService } from './survey-wrapper.service';
import { Hotel } from 'app/shared/model/hotel';
import { FollowupOnsite } from 'app/states/followup-onsite/followup-onsite.actions';
import { CurrentSurvey } from 'app/states/survey/models';
import { StoreCurrentSurvey } from 'app/states/survey/survey.actions';
import { StoreRelativeCurrentChain } from 'app/states/customers/customers.actions';


@Component({
  selector: 'mh-survey-wrapper',
  templateUrl: './survey-wrapper.component.html',
  styleUrls: ['./survey-wrapper.component.scss']
})
export class SurveyWrapperComponent implements OnChanges {

  @Input()
  productId;

  @Input()
  chain: Hotel[] = [];

  @Input()
  showOptionAll = true;

  @Input()
  listenCustomerSubscribe = true;

  @Input()
  forceHide = false;

  @Input() showSurveySelector = true

  @Output()
  currentSurveySetted = new EventEmitter<boolean>();

  waiting = true;

  listSurveys: any[] = [];
  listTypes: any[] = [];
  selectedSurvey = 0;

  customerSubscribe: Subscription;

  selectedType = 1;

  lastCustomerId = 0;

  constructor(
    private utilService: UtilService,
    private surveyWrapperService: SurveyWrapperService,
    private store: Store) {
      this.customerSubscribe = this.utilService.isCustomerChanged().subscribe(response=>{
        if(this.listenCustomerSubscribe){
          if(this.lastCustomerId !== parseInt(this.utilService.currentHotel.id)){
            this.lastCustomerId = parseInt(this.utilService.currentHotel.id);
            this.getSurveys();
          }
        }
      })
    }

  ngOnChanges(changes): void {
    if(this.listenOnChanges && changes.chain && changes.chain.currentValue.length!==0 && !changes.chain.firstChange) this.getSurveys();
  }

  ngOnInit(){
    this.getSurveys();
  }

  ngOnDestroy(){
    this.customerSubscribe.unsubscribe();
  }

  getSurveys(){
    this.waiting = true;
    this.getCustomerSurveys();
  }

  getCustomerSurveys(){
    const ids = this.isChain ? this.chain.map(x=>x.id).join(',') : this.utilService.currentHotel.id;
    const productId = this.productId === 5 ? 1 : this.productId;
    this.surveyWrapperService.getChainCustomerSurveys(ids,productId).subscribe((response:any)=>{
      this.listSurveys    = response || [];
      this.store.dispatch(new FollowupOnsite.StoreSurveys(this.listSurveys))
      if(!this.showOptionAll){
        this.selectedSurvey = this.isChain ? this.listSurveys[0].type.id : this.listSurveys[0].id;
      } else this.selectedSurvey = 0;

      this.setSurveyTypes();
      this.setCurrentSurvey(this.selectedSurvey)
      this.waiting = false;
    })
  }

  setSurveyTypes(){
    this.listTypes = this.listSurveys.reduce((acc, curr) => {
      const id = curr.type.id;
      const version = curr.version;
      const translates = curr.translates;

      const survey = {
        type: id,
        version,
        translates,
      }

      const surveyFound = acc.find(surv => surv.type === id);
      if (!surveyFound) {
        acc = [...acc, survey]
      }
      return acc;
    }, [])
  }

  setCurrentSurvey(filter:number){
    const surveys = filter === 0 ? this.listSurveys : this.selectSurveyByTypeOrId(this.isChain ? 'type' : 'id', filter);
    const ids = surveys.map(x=>x['id']).join(',');
    const currentSurvey = new CurrentSurvey(ids, surveys);
    this.store.dispatch(new StoreCurrentSurvey(currentSurvey));
    this.utilService.setCurrentSurvey(ids,surveys);
    this.currentSurveyUpdated();
  }

  selectSurveyByTypeOrId(condition: 'id' | 'type', filter){
    if(condition==='type'){
      return this.listSurveys.filter(x=>x.type.id===filter);
    }else{
      return [this.listSurveys.find(x=>x.id===filter)];
    }
  }

  currentSurveyUpdated() {
    const waitForCurrentSurvey = false;
    this.currentSurveySetted.emit(waitForCurrentSurvey);
  }

  get isChain(){ return this.chain && this.chain.length>0}

  get showFilter(){ return this.isChain ? this.listTypes.length > 1 && !this.forceHide : this.listSurveys.length > 1 && !this.forceHide }

  get listenOnChanges() { return !this.listenCustomerSubscribe}

  get language() { return this.utilService.getCurrentLanguage() }

}
