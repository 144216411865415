import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';
import { VoicesService } from 'src/app/new-fs/voices/voices.service';
import { IOrigin } from '../model/origin.model';
import { FormControl } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { UtilService } from 'app/shared/util.service';
import { animation } from 'app/shared/utils/animation-custom';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mh-origins',
  templateUrl: './origins.component.html',
  animations: [ animation.rotate ]
})
export class OriginsComponent implements OnInit {
  origins: IOrigin[];
  headerKeys = [
    'origin_name',
    'qr_image',
    'flag_active',
  ];
  loading = false;
  customer;
  addOriginCtrl = new FormControl('');
  hostUrl = '';
  duplicateError = false;
  subscription: Subscription;
  currentSort = 'origin_name';
  currentWaySort = 'DESC';
  paginationSize = '50';
  currentPage = 0;
  pagination = 10;
  languages = [];
  langCtrl = new FormControl();
  isSuperAdmin = false;
  currentUser;

  constructor(
    private voicesService: VoicesService,
    private store: Store,
    private clipboard: Clipboard,
    private utilService: UtilService,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.utilService.getCurrentUser();
    this.isSuperAdmin = this.currentUser.isSuperAdmin();

    if (this.isSuperAdmin) {
      this.headerKeys.splice(1, 0, 'token');
    }

    this.customer = this.store.selectSnapshot(CustomersState.currentCustomer);
    this.getOrigins();
    this.hostUrl = window.location.origin;
    this.subscription = this.addOriginCtrl.valueChanges.subscribe(() => {
      if (this.duplicateError) {
        this.duplicateError = false;
      }
    });
    this.languages = this.utilService.languages;
    this.langCtrl.setValue(this.languages[0].id);
  }

  getOrigins() {
    this.loading = true;
    this.voicesService.getAllOrigins(this.customer?.id ?? this.utilService.getCurrentCustomer()?.id)
      .subscribe((data) => {
        this.origins = data;
        this.sortTable(this.currentSort);
        this.loading = false;
      });
  }

  trackByOrigins(index: number, item: any): number {
    return item.origin_id;
  }

  addNewOrigin() {
    if (!this.origins.find((origin) => origin.origin_name === this.addOriginCtrl.value)) {
      this.loading = true;
      this.origins = [];
      const langCode = this.languages.find(lang => lang.id === this.langCtrl.value)?.code || 'es';
      this.voicesService.addNewOrigin(this.customer.id, { origin_name: this.addOriginCtrl.value, language_code: langCode, flag_active: 'true'})
        .subscribe((_) => {
          this.addOriginCtrl.reset();
          this.getOrigins();
          this.loading = false;
        });
    } else {
      this.duplicateError = true;
    }
  }

  copyToClipboard(text: string) {
    this.clipboard.copy(this.hostUrl + '/voices/' + text);
  }

  sortTable(key) {
    if ( this.currentSort === key && this.currentWaySort === 'DESC' ) {
      this.currentWaySort = 'ASC';
    } else if ( this.currentSort === key && this.currentWaySort === 'ASC' ) {
      this.currentWaySort = 'DESC';
    }
    this.currentSort = key;
    this.handleSorting();
  }

  handleSorting() {
    this.origins.sort((a, b) => {
      const desc = ( a[this.currentSort] < b[this.currentSort] ) ? 1 : -1;
      const asc = ( b[this.currentSort] < a[this.currentSort] ) ? 1 : -1;
      return ( this.currentWaySort === 'DESC' ) ? desc : asc;
    });
  }

  setPagination($event) {
    this.pagination = $event === 0 ? this.origins.length : $event;
    this.currentPage = 0;
  }

  handlePageChanged($event) {
    this.currentPage = this.pagination * $event;
  }

  downloadQR(linkSource, idx) {
    const downloadLink = this.renderer.createElement('a');
    const fileName = this.origins[idx]?.origin_name + '.png';
    downloadLink.href = 'data:image/jpg;base64,' + linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
