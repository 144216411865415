import { Component, Input } from '@angular/core';

import { Survey } from "../../model/index";

@Component({
  selector: 'mh-survey-info',
  templateUrl: './survey-info.component.html',
  styleUrls: ['./survey-info.component.scss'],
})
export class SurveyInfoComponent {

  @Input()
  survey: Survey;

  @Input()
  layoutView: 'list-view' | 'card-view' = 'list-view'

  get isLanguageCodeNumber() {
    const code = this.survey.guest.language.code;
    return typeof code === 'number'; 
  }

  get languageCode() {
    const code = this.survey.guest.language.code;
    if(code === 0) return 14;
    return code;
  }
}
