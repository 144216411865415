import {FilterSearchItem} from './filter-search-item.model'

export class FilterReview{
  private _slug:string;
  private _title:string;
  options:FilterSearchItem[]=[];
  private _checked:boolean=true;

  constructor(slug:string, options:FilterSearchItem[]){
    this._slug = slug;
    this.options = options;
  }

  get title() : string {
    return this._title;
  }
  get slug() : string {
    return this._slug;
  }


  get checked() : boolean{
    return this._checked;
  }

  set checked(v : boolean) {
    this._checked = v;
  }

}