import { Access } from './access.model';

export class CustomerProfile{
  id:number;
  name:string;
  deleted:boolean;
  followup:boolean;
  online:boolean;
  onsite:boolean;
  collect:boolean;
  url_logo:string;
  access:Access[] = [];

  constructor(id,name,deleted,followup,online,onsite, access, collect){
    this.id       = id;
    this.name     = name;
    this.deleted  = deleted;
    this.followup = followup;
    this.online   = online;
    this.onsite   = onsite;
    this.access   = access;
    this.collect  = collect;
  }

}
