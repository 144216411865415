import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface SortResponse{
  sort : string;
  filter: string;
}

@Component({
  selector: 'widget-sort-options',
  templateUrl: './sort-options.component.html',
  styleUrls: ['./sort-options.component.scss']
})
export class SortOptionsComponent implements OnInit, OnDestroy {

  @Input()
  options: string[] = [];

  @Input()
  prefixTranslate = '';

  @Input()
  sortDefault = 'DESC';

  @Output()
  change: EventEmitter<SortResponse> = new EventEmitter();

  sortTypes = [
    'ASC',
    'DESC'
  ];

  controlForm:FormGroup;
  subscription: Subscription;

  constructor(private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.createForm();
  }


  createForm(){
    this.controlForm = this.fb.group({
      sort:     this.sortDefault,
      filter:   this.options[0]
    });

    this.subscription = this.controlForm.valueChanges.subscribe(val => {
      this.change.emit(val);
    });
  }

  sendData(){

  }

  ngOnDestroy(): void {
    if (this.subscription) { 
      this.subscription.unsubscribe();
    }
  }
}
