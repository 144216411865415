import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mh-card-user-thumbnail',
  templateUrl: './card-user-thumbnail.component.html',
  styleUrls: ['./card-user-thumbnail.component.scss']
})
export class CardUserThumbnailComponent implements OnInit {
  @Input() action: any;
  @Input() expandable: boolean = true;
  @Input() userKey: string = 'user'
  @Input() titleKey: string = 'title'
  @Input() userTargetKey: string = 'userTarget'
  @Input() customer: string = 'customer'

  constructor() { }

  ngOnInit() {
  }

}
