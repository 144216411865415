import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartOptions, ChartLegendOptions, PositionType, ChartTooltipItem } from 'chart.js';
import { ChartsDataSet } from 'app/shared/utils/charts-data-set';

@Component({
  selector: 'mh-chart-bar',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  COLORS = (new ChartsDataSet()).lineChartColors()

  @Input() datasets: {data: string[], label: string, tooltips: string[]}[] = [];
  @Input() labels: string[] = [];
  @Input() legendDisplay:boolean = true;
  @Input() legendPosition:PositionType = 'bottom';

  @Output() chartClick = new EventEmitter<any>()

  options:ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      onHover: function(e:any) {
        e.target.style.cursor = 'pointer';
      }
    },
    scales:{
      yAxes: [
        {
          ticks: {
            callback: (value:number) => `${value}%`,
            suggestedMin: 0,
            suggestedMax: 100,
            stepSize: 10,
          }
        }
      ]
    },
    hover: {
      onHover: function(e:any) {
        e.target.style.cursor = 'default';
      }
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: (item:ChartTooltipItem | any) => this.datasets[item.datasetIndex].tooltips[item.index],
      }
    }
  }

  constructor() { }

  ngOnInit() {
  }

  handleClick(e:any) {
    this.chartClick.emit(e)
  }

  get legendOptions(): ChartLegendOptions{
    return {
      position: this.legendPosition,
      display: this.legendDisplay,
    }
  }
}

