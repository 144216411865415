import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function strengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    if (!value) return null;
    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasNumber = /[0-9]+/.test(value);
    const hasSpecialCharacters = /[^\w\s]+/g.test(value);
    const exception = !value.includes(':');
    const hasValidLength = value.length >= 8;
    const passwordValid =
      hasUpperCase && hasLowerCase && hasNumber && hasSpecialCharacters && hasValidLength && exception;

    return !passwordValid
      ? {
          passwordStrength: {
            hasUpperCase: !hasUpperCase,
            hasLowerCase: !hasLowerCase,
            hasNumber: !hasNumber,
            hasSpecialCharacters: !hasSpecialCharacters,
            hasValidLength: !hasValidLength,
            exception: !exception,
          },
        }
      : null;
  };
}
