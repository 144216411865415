import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'mh-survey-question-checkbox',
  templateUrl: './survey-question-checkbox.component.html',
  styleUrls: ['./survey-question-checkbox.component.scss']
})
export class SurveyQuestionCheckboxComponent implements OnInit {
  @Input() questionId:number;
  @Input() alternatives:any[]
  @Input() answers:any[]
  @Input() language:string
  @Input() conditionalId: number;
  @Input() preview = false;

  @Output() answer:EventEmitter<any> = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.conditionalId) {
      this.alternatives.map(alt => {
        const answer = this.getAlternativeAnwser(alt)

        if (answer.selected)
          return this.createAnswer(alt);

      })
      .filter(Boolean)
      .forEach(answer => this.answer.emit(answer))
    }
  }

  handleChange(alternative:any, event:any) {
    const answer = this.createAnswer(alternative, event.checked);

    this.answer.emit(answer);
  }

  createAnswer(alternative:any, value = false) {
    const prevAnswer = this.getAlternativeAnwser(alternative);

    return {
      answer_alternative_id: alternative.id,
      score: alternative.score,
      selected: value,
      question_id: this.questionId,
      tempId: prevAnswer.tempId || uuid(),
      id: prevAnswer.id
    }
  }

  isChecked(alternative:any) {
    return this.getAlternativeAnwser(alternative).selected;
  }

  getAlternativeAnwser(alternative:any) {
    return this.answers.find(a => (
      a.answer_alternative_id === alternative.id
    )) || {};
  }

  isDisabled(alternative:any) {
    if (this.preview) return true;

    const alternativeAnswer = this.answers
      .find(a => a.answer_alternative_id === alternative.id &&
        (!this.conditionalId || this.conditionalId === a.conditional_alternative_id)
      ) || {};

    return alternativeAnswer.waiting;
  }
}

