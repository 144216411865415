<h5 class="edit_title" [innerHtml]="('configuration.users.modal.access.title' | translate:{'product': product})"></h5>
<ul id="access_edit" class="list_switch">
  <ng-container *ngFor="let access of customer.access">
    <li  *ngIf="showSlide(access)">
      <mat-slide-toggle
        [id]="access.description"
        (change)="setAccessSate($event)"
        [checked]="access.value">
        {{'models.access.'+access.description | translate}}
      </mat-slide-toggle>
    </li>
  </ng-container>
</ul>

<div [class.waiting]="waiting" class="bottom" (click)="saveChanges()">
  <button type="submit" class="btn btn-orange submit">{{'forms.save_changes' | translate}}</button>
</div>

