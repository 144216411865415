<widget-card-nps
  [title]="('followup.summary.nps.title' | translate )"
  [passives]="data?.details.actual.passives"
  [detractors]="data?.details.actual.detractors"
  [promoters]="data?.details.actual.promoters"
  [satisfactionNPS]="data?.nps?.actual"
  [externalLink]="'https://statics.myhotel.io/documents/Guias/Qué+es+el+NPS.pdf'"
  [externalLinkText]="('followup.summary.nps.ebook' | translate)"
  [waiting]="waiting"
  [customerType]="customerType"
  (ebookClick)="handleEbookClick()"
></widget-card-nps>
