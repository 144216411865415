import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'core-lib-widget-qualification',
  templateUrl: './widget-qualification.component.html',
  styleUrls: ['./widget-qualification.component.scss']
})
export class CoreLibWidgetQualificationComponent implements OnInit {

  readonly MAX_SCORE = '5.0';

  @Input()
  areaDetail: any;

  constructor() { }

  ngOnInit(): void {
  }

}
