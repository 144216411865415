<!-- paginate -->
<!-- NO VA TODAVIA!!!! (click)="initForm($event, comment)" -->

<h4>{{'cases.titles.activity_section' | translate}}</h4>

<ng-container *ngTemplateOutlet="messageSection"></ng-container>

<div class="mt-3" *ngIf="this.showComments">
  <button (click)="initForm()" *ngIf="!commentForm" class="btn btn-primary">
    {{'cases.commons.add_comment' | translate}}
  </button>
  <ng-container *ngIf="!originalComment">
    <form [formGroup]="commentForm" id="new-comment">
      <div class="row no-gutters">
        <div class="col" (drop)="handleDroppedFile($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" [class.drop-zone]="isDraggingFile">
          <mat-form-field appearance="outline">
            <div *ngIf="isDraggingFile" class="dropzone-msg d-flex justify-content-center">
              <div class="align-self-center">
                <span class="msg">
                  {{'cases.files.drop-here' | translate}}
                </span>
              </div>
            </div>
            <textarea matInput id="new-comment-input" formControlName="text" [mentionConfig]="mentionConfig"
              [placeholder]="'cases.messages.write_comment' | translate" (input)="onInputText($event)"
              (itemSelected)="selectMention($event)"></textarea>
          </mat-form-field>
        </div>
        <div class="col-auto mr-2 ml-1">
          <button (click)="handleSaveComment()" [disabled]="waitingFilesUploadStatus.length > 0" class="btn btn-white">
            {{'forms.save' | translate}}
          </button>
        </div>
      </div>
      <div class="row no-gutters" *ngIf="commentForm.get('text').errors?.maxlength">
        <div class="col">
          <small class="error-validator">
            {{'cases.errors.maxlength' | translate : { max: requiredLengthError, current: currentLengthError} }}
          </small>
        </div>
      </div>
    </form>
  </ng-container>
</div>

<div class="mb-3">
  <mat-icon class="pointer" (click)="handleClickFileInput()">attach_file</mat-icon>
  <input type="file" #file_input (change)="handleFileInput($event)" hidden multiple>
  <span placement="right" ngbTooltip="{{'cases.tooltips.attachment' | translate}}">
    <mat-icon class="info-tooltip">info</mat-icon>
  </span>
</div>

<mat-list *ngIf="attachments.length > 0 || waitingFilesUploadStatus.length > 0">
  <div mat-subeader>{{'cases.files.attachments' | translate}}</div>
  <mat-list-item *ngFor="let status of waitingFilesUploadStatus">
    <div>
      <div mat-line>
        <mh-spinner-classic></mh-spinner-classic>
      </div>
    </div>
  </mat-list-item>
  <mat-list-item *ngFor="let attachment of attachments">
    <mat-icon mat-list-icon>description</mat-icon>
      <div mat-line>
        {{attachment.file_name}}
        <mat-icon class="pointer" (click)="handleRemoveFile(attachment.file_key)">delete</mat-icon>
      </div>
  </mat-list-item>
</mat-list>

<mat-tab-group mat-align-tabs="center" (selectedTabChange)="tabChanged($event)" dynamicHeight>
  <mat-tab label="{{'cases.titles.activity_all' | translate}}">
    <ng-container *ngFor="let action of commentsAndLogs">
      <mh-log-wrapper [iconClass]="action.icon">
        <ng-container *ngIf="isNumber(action.id) else logTemplate">
          <mh-card-user-thumbnail
            [action]="action"
            [expandable]="false"
            titleKey="company_position"
          >
            <mh-user-mention [text]="action.text" [files]="action.attachments" [fileName]="null" [fileKey]="action.file_key" [users]="users" [waitingDownload]="waitingFileDownload" (downloadFile)="handleDowloadEvent($event)"></mh-user-mention>
          </mh-card-user-thumbnail>
        </ng-container>
      <ng-template #logTemplate>
        <mh-log-card *ngIf="SUBSCRIPTION_REQUEST_METHOD !== action.request_method_name else subscription"
          [log]="action"
          [language]="language"
        ></mh-log-card>
        <ng-template #subscription>
          <mh-card-user-thumbnail
            [action]="action"
            [expandable]="false"
            titleKey="company_position"
          >
          {{'logs.resources.case.' + (action.modifications[0].actual ? 'subscribe' : 'unsubscribe') | translate}}
          </mh-card-user-thumbnail>
        </ng-template>

      </ng-template>
      </mh-log-wrapper>
    </ng-container>
  </mat-tab>


  <mat-tab label="{{'cases.titles.activity_actions' | translate}}">
    <ng-container *ngFor="let comment of comments">
      <mh-log-wrapper [iconClass]="comment.icon">
        <mh-card-user-thumbnail *ngIf="originalComment !== comment"
          [action]="comment"
          [expandable]="false"
          titleKey="company_position"
        >
          <mh-user-mention [text]="comment.text" [users]="users"></mh-user-mention>
        </mh-card-user-thumbnail>
      </mh-log-wrapper>
    </ng-container>
  </mat-tab>


  <mat-tab label="{{'cases.titles.activity_changes' | translate}}">
    <ng-container *ngFor="let log of logs">
      <mh-log-wrapper [iconClass]="log.icon">
        <mh-log-card [log]="log" [language]="language"></mh-log-card>
      </mh-log-wrapper>
    </ng-container>
  </mat-tab>
</mat-tab-group>

<ng-template #messageSection>
  <div *ngIf="message"
    class="alert alert-{{error ? 'danger' : 'success'}} alert-dismissible fade show"
    role="alert"
  >
    <span [innerHTML]="(message | translate)"></span>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="message = null">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</ng-template>
