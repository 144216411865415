import { Component, Input } from '@angular/core';
import {UtilService} from 'app/shared/util.service';

@Component({
  selector: 'mh-wait-chain',
  templateUrl: './wait_chain.component.html',
  styleUrls: ['./wait_chain.component.scss'],
})
export class WaitChainComponent{
  @Input()
  currentChain:number[] = []

  @Input()
  block:number=0;

  @Input()
  total:number=0;

  @Input()
  totalBlock:number=0;

  @Input()
  error=false;

  mode = 'determinate';
  value = 0;
  isReady:boolean=false;


  constructor(private utilService: UtilService){}

  ngOnChanges() {
    this.value = this.block*100/this.totalBlock;
    this.isReady = this.block===this.totalBlock;
  }

  get type() { return this.utilService.customerIsRetail ? 'commons.stores' : 'commons.hotels'}
}
