import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StoreChainOtasErrors, CleanCurrentErrors } from './otas.actions';

export interface OtasModel {
  chainOtasErrors: null;
}

@State<OtasModel>({
  name: 'otas',
  defaults: {
    chainOtasErrors: null,
  },
})
export class OtasState {
  @Selector()
  static chainOtasErrors(state: OtasModel) {
    return state.chainOtasErrors;
  }

  @Action(StoreChainOtasErrors)
  storeChainOtasErrors(context: StateContext<OtasModel>, action: StoreChainOtasErrors) {
    context.patchState({
      chainOtasErrors: action.chainOtasErrors,
    });
  }

  @Action(CleanCurrentErrors)
  cleanCurrentErrors(context: StateContext<OtasModel>) {
    context.patchState({
      chainOtasErrors: null,
    });
  }
}
