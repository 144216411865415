import { Component } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

import { environment } from '../../environments/environment'

@Component({
  template: `
    <h2><a [href]="libLink" target="_blank">
      Go to Library
    </a></h2>
    <iframe [src]="mhLibLink" style="position: relative; height: 100vh; width: 100%;"></iframe>
    `,
})

export class MhLibComponent {

  libLink = environment.mhFrontLib

  constructor(
    private sanitizer: DomSanitizer,
    ) { }

  get mhLibLink(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.libLink)
  }
}

