<mh-box-element [title]="('prestay.precheckin.download.pageDownload.title' | translate)">

  <div class="box d-flex justify-content-center">
    <ng-container *ngIf="waitingFile else downloadReady">
      <ng-container *ngIf="!errorOnDownload else downloadFailed">

        <div class="container">
          <div class="row justify-content-center">
            <span class="text mt-3">{{('prestay.precheckin.download.pageDownload.inProgress' | translate)}}</span>
          </div>
          <div class="row justify-content-center">
            <mh-spinner-load></mh-spinner-load>
          </div>
        </div>

      </ng-container>

    </ng-container>
  </div>


</mh-box-element>

<ng-template #downloadReady>

  <span class="text mt-3">{{('prestay.precheckin.download.pageDownload.complete' | translate)}}<i class="fas fa-check-circle icon"></i></span>
</ng-template>

<ng-template #downloadFailed>
  <span class="text mt-3">{{('prestay.precheckin.download.pageDownload.error' | translate)}} <i class="warning-icon far fa-exclamation-triangle"></i></span>
</ng-template>
