<div class="tw-flex tw-flex-row tw-justify-center tw-w-full tw-p-7" *ngIf="waiting">
    <mh-spinner-load></mh-spinner-load>
</div>
<mh-core-lib-table 
    [DefaultPaginationSize]="pageSize"
    [totalElements]="totalElements"
    [showFooter]="showTableFooter"
    (paginationSize)="paginationSizeChange($event)"
    (pageChanged)="pageChange($event)"
    [currentPage]="currentPage"
    *ngIf="!waiting"
>
    <ng-container thead>
        <ng-container *ngTemplateOutlet="head"></ng-container>
    </ng-container>
    <ng-container tbody>
        <ng-container *ngTemplateOutlet="body"></ng-container>
    </ng-container>
</mh-core-lib-table>

<ng-template #head>
    <tr>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            {{ 'configuration.invoice.number' | translate }}
          </div>
          <div>
            <button (click)="getInvoicesBy('invoice_number', 0)">
              <i class="fas {{columnsToSort[0].sort_icon}}"></i>
            </button>
          </div>
        </div>
      </th>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            {{ 'configuration.invoice.issue_date' | translate }}
          </div>
          <div>
            <button (click)="getInvoicesBy('invoice_date', 1)">
              <i class="fas {{columnsToSort[1].sort_icon}}"></i>
            </button>
          </div>
        </div>
      </th>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            {{ 'configuration.invoice.expiration_date' | translate }}
          </div>
          <div>
            <button (click)="getInvoicesBy('invoice_due_date', 2)">
              <i class="fas {{columnsToSort[2].sort_icon}}"></i>
            </button>
          </div>
        </div>
      </th>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            {{ 'configuration.invoice.amount' | translate }}
          </div>
        </div>
      </th>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            {{ 'configuration.invoice.currency' | translate }}
          </div>
        </div>
      </th>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            {{ 'configuration.invoice.pay_date' | translate }}
          </div>
          <div>
            <button (click)="getInvoicesBy('invoice_fully_paid_date', 3)">
              <i class="fas {{columnsToSort[3].sort_icon}}"></i>
            </button>
          </div>
        </div>
      </th>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            {{ 'configuration.invoice.status' | translate }}
          </div>
          <div>
            <button (click)="getInvoicesBy('status', 4)">
              <i class="fas {{columnsToSort[4].sort_icon}}"></i>
            </button>
          </div>
        </div>
      </th>
      <th>
        &nbsp;
      </th>
    </tr>
  </ng-template>
  
  <ng-template #body>
    <tr *ngIf="invoices.length === 0; else tableBody">
        <td colspan="8" class="tw-text-center">
            <span class="tw-font-medium tw-py-6">{{ 'configuration.invoice.no_invoices' | translate }}</span>
        </td>
    </tr>

    <ng-template #tableBody>
        <tr *ngFor="let invoice of invoices">
          <td class="tw-py-2">
            <article class="tw-flex">
              <div class="tw-self-center">
                <span class="tw-font-medium tw-text-main-text">{{ invoice.invoice_number }}</span>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <article class="tw-flex">
              <div class="tw-self-center" [mhTooltip]="'configuration.invoice.date_tooltip' | translate" [width]="95" position="right">
                <span class="tw-font-medium tw-text-main-text">{{ invoice.invoice_date | date: 'dd/MM/yyyy' }}</span>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <article class="tw-flex">
              <div class="tw-self-center" [mhTooltip]="'configuration.invoice.date_tooltip' | translate" [width]="95" position="right">
                <span class="tw-font-medium tw-text-main-text">{{ invoice.invoice_due_date | date: 'dd/MM/yyyy' }}</span>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <article class="tw-flex">
              <div class="tw-self-center">
                <span class="tw-font-medium tw-text-main-text">{{ invoice.invoice_total | currency:invoice.currency_symbol }}</span>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <article class="tw-flex">
              <div class="tw-self-center">
                <span class="tw-font-medium tw-text-main-text">{{ invoice.currency_code }}</span>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <article class="tw-flex">
              <div class="tw-self-center" [mhTooltip]="'configuration.invoice.date_tooltip' | translate" [width]="95" position="right">
                <span class="tw-font-medium tw-text-main-text">{{ (invoice.invoice_fully_paid_date | date: 'dd/MM/yyyy') || '-' }}</span>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <article class="tw-flex">
              <div class="tw-self-center">
                <span 
                    class="tw-block tw-mr-1.5 tw-rounded-full tw-w-2 tw-h-2"
                    [ngClass]="{
                        'tw-bg-invoice-paid': invoice.status === 'PAID',
                        'tw-bg-invoice-pending': invoice.status === 'PENDING',
                        'tw-bg-invoice-expired': invoice.status === 'EXPIRED'
                    }"
                >
                </span>
              </div>
              <div class="tw-self-center">
                <span class="tw-font-medium tw-text-main-text">{{ 'configuration.invoice.' + invoice.status | translate }}</span>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <article class="tw-flex tw-justify-between tw-align-middle">
              <div class="tw-self-center" [mhTooltip]="'configuration.invoice.invoice_tooltip' | translate" [width]="90" position="below">
                <span class="tw-font-medium tw-p-2 tw-cursor-pointer" (click)="showInvoice(invoice)">
                  <i class="far fa-search tw-w-3.5 tw-h-2.5"></i>
                </span>
              </div>
              <div class="tw-self-center" *ngIf="invoice.status !== 'PAID'">
                <span class="tw-font-medium tw-p-2 tw-cursor-pointer">
                  <mh-core-lib-button
                    class="tw-ml-1 tw-h-8"
                    customClass="!tw-h-8"
                    [isDefault]="true"
                    [text]="('configuration.invoice.pay' | translate)"
                    [width]="100"
                    (click)="showInvoice(invoice)"
                  >
                  </mh-core-lib-button>
                </span>
              </div>
            </article>
          </td>
        </tr>
    </ng-template>
  </ng-template>
  