import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { UtilService } from 'app/shared/util.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'mh-roles-actions-modal',
  templateUrl: './roles-actions-modal.component.html',
  styleUrls: ['./roles-actions-modal.component.scss'],
})
export class RolesActionsModalComponent extends CoreLibModalComponent implements OnInit {
  roles;
  actions;
  loading = false;

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private userManagementService: UsersManagmentService,
    private utilService: UtilService,
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.loading = true;
    forkJoin({
      roles: this.userManagementService.getRoles(),
      actions: this.userManagementService.getDetailsOfActionsByRole(+this.utilService.getLanguageId()),
    }).subscribe(({ roles, actions }) => {
      this.roles = roles;
      this.actions = Object.entries(actions).map((value) => {
        const splittedIndex = value[0].split('|');
        const title = splittedIndex ? splittedIndex[0] : '';
        const subTitle = splittedIndex ? splittedIndex[1] : '';
        return {
          title,
          subTitle,
          ...value[1],
        };
      });
      this.loading = false;
    });
  }

  roleTrack(index, item) {
    return item.id;
  }
}
