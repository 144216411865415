import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService } from 'app/notifications/notifications.service';
import { UtilService } from 'app/shared/util.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mh-alerts-wrapper',
  templateUrl: './alerts-wrapper.component.html',
  styleUrls: ['./alerts-wrapper.component.scss'],
})
export class AlertsWrapperComponent implements OnInit, OnDestroy {
  public subs: Subscription;
  public isFilter = false;

  constructor(private notificationService: NotificationsService, private utilService: UtilService) {}

  ngOnInit() {
    this.checkAlerts();
    this.subs = this.utilService.isFilterClicked().subscribe((resp) => {
      this.isFilter = resp;
    });
  }

  checkAlerts(): void {
    if (!this.isFilter) {
      this.notificationService.checkModalAlert('wrapper');
    }
    this.isFilter = false;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
