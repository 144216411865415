import { Component, OnInit, Inject } from '@angular/core';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { CoreLibModalComponent } from '../modal/modal.component';

enum Options {
  ACCEPT = 'ACCEPT',
  CANCEL = 'CANCEL'
}

@Component({
  selector: 'mh-core-lib-generic-confirmation-modal',
  templateUrl: './generic-confirmation-modal.component.html',
  styleUrls: ['./generic-confirmation-modal.component.scss']
})
export class CoreLibGenericConfirmationModalComponent extends CoreLibModalComponent implements OnInit {
  isMobile = false;
  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.isMobile = this.data.isMobile;
  }

  handleButtonClick($event) {
    if ( $event === Options.ACCEPT ) {
      this.close(Options.ACCEPT)
    }
    this.close(Options.CANCEL)
  }

  get text() {
    return this.data.text;
  }

}
