import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-core-lib-selector-users',
  templateUrl: './selector-users.component.html',
  styleUrls: ['./selector-users.component.scss']
})
export class CoreLibSelectorUsersComponent implements OnInit {

  @Input()
  activeUsers: number = 0;

  @Input()
  inactiveUsers: number = 0;

  @Input()
  internalUsers: number = 0;

  @Input()
  currentStatus = 'active';

  @Input()
  isSuperAdmin = false;

  @Output()
  filterUpdated = new EventEmitter<string>();

  activeUsersActive = true;
  inactiveUsersActive = false;
  internalsUsersActive = false;

  showInternalUser = true;

  constructor(
    private utilService: UtilService,
  ) { }

  ngOnInit(): void {
    this.showInternalUser = 'full-admin' === this.utilService.currentUser.role;
    this.handleClick(this.currentStatus, false);
  }

  handleClick(filter: string, emit = true) {
    switch (filter) {
      case 'active':
        this.activeUsersActive = true;
        this.inactiveUsersActive = false;
        this.internalsUsersActive = false;
        if (emit) {
          this.filterUpdated.emit('active');
        }
        break;
      case 'inactive':
        this.activeUsersActive = false;
        this.inactiveUsersActive = true;
        this.internalsUsersActive = false;
        if (emit) {
          this.filterUpdated.emit('inactive');
        }
        break;
      case 'internal':
        this.activeUsersActive = false;
        this.inactiveUsersActive = false;
        this.internalsUsersActive = true;
        if (emit) {
          this.filterUpdated.emit('internal');
        }
        break;
    }
  }
}
