import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  FilterClear,
  ChangeAnswerFilter,
  ChangeLanguageFilter,
  ChangeNPSFilter,
  ChangeOTAFilter,
  ChangeQualificationFilter,
  ChangeReasonFilter,
  StoreReviewsList,
  UpdateReviewsFilterForApi,
  ClearFilterForApi,
} from './online-reviews.actions';
import { OnlineReviewsOtaFilterPipe } from 'app/pipes/online-reviews/online-reviews-ota-filter.pipe';
import { OnlineReviewsAnswerFilterPipe } from 'app/pipes/online-reviews/online-reviews-answer-filter.pipe';
import { OnlineReviewsNpsFilterPipe } from 'app/pipes/online-reviews/online-reviews-nps-filter.pipe';
import { OnlineReviewsQualificationFilterPipe } from 'app/pipes/online-reviews/online-reviews-qualification-filter.pipe';
import { OnlineReviewsReasonFilterPipe } from 'app/pipes/online-reviews/online-reviews-reason-filter.pipe';
import { OnlineReviewsLanguageFilterPipe } from 'app/pipes/online-reviews/online-reviews-language-filter.pipe';

export enum FILTERS_NAME {
  OTA = 'ota',
  ANSWER = 'answer',
  NPS = 'nps',
  QUALIFICATION = 'qualification',
  REASON = 'reason',
  LANGUAGE = 'language',
  CASES = 'cases',
}

export const NPS_VALUES = {
  PROMOTER: { id: 1, value: 1, text: 'promoter', type: 'nps' },
  PASSIVE: { id: 2, value: 2, text: 'passive', type: 'nps' },
  DETRACTOR: { id: 3, value: 3, text: 'detractor', type: 'nps' },
};

export const ANSWER_VALUES = {
  NO_RESPONDIDO: { id: 0, value: 2, text: 'No respondido', type: 'answer' },
  RESPONDIDO: { id: 1, value: 1, text: 'Respondido', type: 'answer' },
  NO_ADMITE_RESPUESTAS: { id: 2, value: 3, text: 'No admite respuestas', type: 'answer' },
  EN_ESPERA_DE_APROBACION: { id: 3, value: 4, text: 'En espera de aprobación', type: 'answer' },
};

export const DETECTED_CASES_VALUES = {
  DETECTED: { value: 3, text: 'detected', type: 'cases' },
}

const DEFAULT_FILTERS = [
  {
    name: FILTERS_NAME.OTA,
    options: [],
    titleTranslate: 'ota',
  },
  {
    name: FILTERS_NAME.ANSWER,
    options: [
      ANSWER_VALUES.RESPONDIDO,
      ANSWER_VALUES.NO_RESPONDIDO,
      ANSWER_VALUES.NO_ADMITE_RESPUESTAS,
      ANSWER_VALUES.EN_ESPERA_DE_APROBACION,
    ],
    titleTranslate: 'answer',
  },
  {
    name: FILTERS_NAME.NPS,
    options: [NPS_VALUES.PROMOTER, NPS_VALUES.PASSIVE, NPS_VALUES.DETRACTOR],
    titleTranslate: 'nps',
  },
  {
    name: FILTERS_NAME.QUALIFICATION,
    options: [
      { id: 1, value: 'A', text: '1_star', description: '0-1', type: 'qualification' },
      { id: 2, value: 'B', text: '2_star', description: '1-2', type: 'qualification' },
      { id: 3, value: 'C', text: '3_star', description: '2-3', type: 'qualification' },
      { id: 4, value: 'D', text: '4_star', description: '3-4', type: 'qualification' },
      { id: 5, value: 'E', text: '5_star', description: '4-5', type: 'qualification' },
    ],
    titleTranslate: 'qualification',
  },
  {
    name: FILTERS_NAME.REASON,
    options: [
      { id: 1, name_es: 'Trabajo', name_en: 'Work', type: 'reason' },
      { id: 2, name_es: 'Familia', name_en: 'Family', type: 'reason' },
      { id: 3, name_es: 'Parejas', name_en: 'Couple', type: 'reason' },
      { id: 4, name_es: 'Solo', name_en: 'Solo', type: 'reason' },
      { id: 5, name_es: 'Amigos', name_en: 'Friends', type: 'reason' },
      { id: 6, name_es: 'Otros', name_en: 'Other', type: 'reason' },
    ],
    titleTranslate: 'reason',
  },
  {
    name: FILTERS_NAME.LANGUAGE,
    options: [
      { id: 1, code: 'es', type: 'language' },
      { id: 2, code: 'en', type: 'language' },
      { id: 7, code: 'pt', type: 'language' },
      { id: 10, code: 'de', type: 'language' },
      { id: 11, code: 'fr', type: 'language' },
      { id: 14, code: 'other', type: 'language' },
    ],
    titleTranslate: 'idioma',
  },
  {
    name: FILTERS_NAME.CASES,
    options: [
      { value: 1, text: 'created', type: 'cases' },
      { value: 2, text: 'non_created', type: 'cases' },
      { value: 3, text: 'detected', type: 'cases' },
    ],
  },
];

interface OnlineReviewsStateModel {
  reviews: any[];
  filters: any;
  filterForApi: any;
}

const reviewsOtaFilterPipe = new OnlineReviewsOtaFilterPipe();
const reviewsAnswerFilterPipe = new OnlineReviewsAnswerFilterPipe();
const reviewsNPSFilterPipe = new OnlineReviewsNpsFilterPipe();
const reviewsQualificationFilterPipe = new OnlineReviewsQualificationFilterPipe();
const reviewsReasonFilterPipe = new OnlineReviewsReasonFilterPipe();
const reviewsLanguageFilterPipe = new OnlineReviewsLanguageFilterPipe();

@State<OnlineReviewsStateModel>({
  name: 'reviews',
  defaults: {
    reviews: null,
    filterForApi: null,
    filters: DEFAULT_FILTERS,
  },
})
export class OnlineReviewsState {
  @Selector()
  static filteredReviews(state: OnlineReviewsStateModel) {
    return reviewsOtaFilterPipe.transform(
      reviewsAnswerFilterPipe.transform(
        reviewsNPSFilterPipe.transform(
          reviewsQualificationFilterPipe.transform(
            reviewsReasonFilterPipe.transform(
              reviewsLanguageFilterPipe.transform(state.reviews, state.filters),
              state.filters,
            ),
            state.filters,
          ),
          state.filters,
        ),
        state.filters,
      ),
      state.filters,
    );
  }

  @Selector()
  static reviews(state: OnlineReviewsStateModel) {
    return state.reviews;
  }

  @Selector()
  static filters(state: OnlineReviewsStateModel) {
    return state.filters;
  }

  @Selector()
  static filterForApi(state: OnlineReviewsStateModel) {
    return state.filterForApi;
  }

  @Action(StoreReviewsList)
  storeReviewsList(context: StateContext<OnlineReviewsStateModel>, action: StoreReviewsList) {
    context.patchState({
      reviews: action.reviews,
    });
  }

  @Action(ChangeOTAFilter)
  changeOTAFilter(context: StateContext<OnlineReviewsStateModel>, action: ChangeOTAFilter) {
    const filters: any = Object.values({ ...context.getState().filters });
    const otaIndex = filters.findIndex((filter: any) => filter.name === FILTERS_NAME.OTA);
    const filter: any = { ...filters[otaIndex] };
    filter.options = action.otaFilter;
    filters[otaIndex] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangeAnswerFilter)
  changeAnswerFilter(context: StateContext<OnlineReviewsStateModel>, action: ChangeAnswerFilter) {
    const filters: any = Object.values({ ...context.getState().filters });
    const answerIndex = filters.findIndex((filter) => filter.name === FILTERS_NAME.ANSWER);
    const filter = { ...filters[answerIndex] };
    filter.options = action.answerFilter;
    filters[answerIndex] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangeNPSFilter)
  changeNPSFilter(context: StateContext<OnlineReviewsStateModel>, action: ChangeNPSFilter) {
    const filters: any = Object.values({ ...context.getState().filters });
    const npsIndex = filters.findIndex((filter: any) => filter.name === FILTERS_NAME.NPS);
    const filter = { ...filters[npsIndex] };
    filter.options = action.npsFilter;
    filters[npsIndex] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangeQualificationFilter)
  ChangeQualificationFilter(context: StateContext<OnlineReviewsStateModel>, action: ChangeQualificationFilter) {
    const filters: any = Object.values({ ...context.getState().filters });
    const qualificationIndex = filters.findIndex((filter) => filter.name === FILTERS_NAME.QUALIFICATION);
    const filter = { ...filters[qualificationIndex] };
    filter.options = action.qualificationFilter;
    filters[qualificationIndex] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangeReasonFilter)
  changeReasonFilter(context: StateContext<OnlineReviewsStateModel>, action: ChangeReasonFilter) {
    const filters: any = Object.values({ ...context.getState().filters });
    const reasonIndex = filters.findIndex((filter) => filter.name === FILTERS_NAME.REASON);
    const filter = { ...filters[reasonIndex] };
    filter.options = action.reasonFilter;
    filters[reasonIndex] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangeLanguageFilter)
  changeLanguageFilter(context: StateContext<OnlineReviewsStateModel>, action: ChangeLanguageFilter) {
    const filters: any = Object.values({ ...context.getState().filters });
    const languageIndex = filters.findIndex((filter) => filter.name === FILTERS_NAME.LANGUAGE);
    const filter = { ...filters[languageIndex] };
    filter.options = action.languageFilter;
    filters[languageIndex] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(UpdateReviewsFilterForApi)
  UpdateReviewsFilterForApi(context: StateContext<OnlineReviewsStateModel>, action: UpdateReviewsFilterForApi) {
    const contextFilters = context.getState().filterForApi;
    const prevFilters = contextFilters && Object.keys(contextFilters).length !== 0 ? contextFilters : [];
    const newFilter = [action.filterForApi];
    let filterForApi = [];
    const TYPES = {
      ota: 'name',
      answer: 'text',
      nps: 'text',
      qualification: 'text',
      reason: 'name_en',
      language: 'code',
      cases: 'text',
    };
    if (prevFilters && prevFilters.length > 0) {
      const filterToReduce = prevFilters.map((filter) => filter);
      filterToReduce.push(...newFilter);
      filterForApi = filterToReduce.reduce(function (acc, curr) {
        if (acc.some((element) => element[TYPES[element.type]] === curr[TYPES[curr.type]])) {
          acc.splice(acc.indexOf(curr), 1);
        } else {
          curr = Array.isArray(curr) ? curr[curr.length - 1] : curr;
          acc.push(curr);
        }
        return acc;
      }, []);
    } else {
      filterForApi = newFilter;
    }
    context.patchState({
      filterForApi,
    });
  }

  @Action(ClearFilterForApi)
  ClearFilterForApi(context: StateContext<OnlineReviewsStateModel>) {
    context.patchState({
      filterForApi: {},
    });
  }

  @Action(FilterClear)
  filterClear(context: StateContext<OnlineReviewsStateModel>) {
    context.patchState({
      filters: DEFAULT_FILTERS,
    });
  }
}
