import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Products } from '../../shared/model/admin/admin-alerts';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminAlertsService {

  private refreshAlertsSubj    = new Subject<boolean>();

  private refreshCreateAlertsSubj    = new Subject<boolean>();

  private closeFormSubj    = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  public isCloseForm(){
    return this.closeFormSubj.asObservable();
  }

  public closeForm(){
    this.closeFormSubj.next(true);
  }

  public isAlertRefreshed(){
    return this.refreshAlertsSubj.asObservable();
  }

  public refreshAlerts(){
    this.refreshAlertsSubj.next(true);
  }

  public isAlertCreateRefreshed(){
    return this.refreshCreateAlertsSubj.asObservable();
  }

  public refreshCreateAlerts(){
    this.refreshCreateAlertsSubj.next(true);
  }

  public getAlertActives(){

    const url=`${environment.customerPath}alerts`;
    const params:HttpParams = new HttpParams();

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }

  public getAlertBase(){
    const url=`${environment.customerPath}alerts/base`;
    const params:HttpParams = new HttpParams();

    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      );
  }

  public getAlert(customerId,language_Id){
    const url=`${environment.customerPath}alerts/`+customerId;
    const params:HttpParams = new HttpParams()
      .set("language_id",language_Id);

    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      );
  }

  public formatProducts(products){
    const onlyproduct: any[]=[];
    let id;

    products.forEach(product => {
      id = product.id ? product.id : null;
      onlyproduct.push(new Products(id,product.product_id));
    });
    return (onlyproduct);
  }

  public formatArrayDelimited(array,index){
    const customers: any[] = [];
    array.forEach(element => {
      customers.push(element[index]);
    });
    return(customers);
  }

  public createAlert(alert,type_alert,customers,products){
    const url=`${environment.customerPath}alerts/`;
    const data = {
      customers_ids: customers,
      new_alert : {
        alert: {
          id: alert
        },
        products_alert: products,
        alert_property_type: { id: type_alert }
      }
    }

    return this.http.post(url,data)
      .pipe(
        map(response=>response)
      );
  }

  public editAlert(alert_id,alert,type_alert,customers,products){
    const url=`${environment.customerPath}alerts/`;
    const data = {
      id: alert_id,
      alert: {
        id: alert
      },
      customer_id: customers,
      products_alert: products,
      alert_property_type: { id: type_alert }
    }

    return this.http.patch(url,[data])
      .pipe(
        map(response=>response)
      );
  }

  public deleteAlert(ids){
    const url=`${environment.customerPath}alerts/delete/${ids[0]}`;
    return this.http.delete(url)
      .pipe(
        map(response=>response)
      );


  }
}
