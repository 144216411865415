import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from 'app/shared/util.service';
@Component({
  selector: 'widget-card-ota',
  templateUrl: './card-ota.component.html',
  styleUrls: ['./card-ota.component.scss']
})
export class CardOtaComponent implements OnInit {

  @Input()
  otaId: any = null;

  @Input()
  ota: any = null;

  @Input()
  size: 'big' | 'medium' | 'small' = 'medium';

  @Input()
  viewStyle: 'inline' | 'block' = 'block';

  @Input()
  align: 'left' | 'center' | 'right' = 'center';

  constructor(
    private utilService: UtilService,
  ) { }

  ngOnInit() {
  }

  get logo(){
    return this.utilService.getOtaLogo(this.id);
  }

  get id(){
    return this.isOtaObject ? this.ota.id : this.otaId;
  }

  get isOtaObject(){
    return this.ota !== null;
  }

  get isDespegar() {
    return this.id === 4;
  }

  get isExpedia() {
    return this.id === 34;
  }

}
