import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ElementRef } from '@angular/core';

@Component({
  selector: 'mh-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{provide: CdkStepper, useExisting: StepperComponent}],
})
export class StepperComponent extends CdkStepper implements OnInit {
  @Input()
  stepNames = [];

  @Input()
  disableNextBtn = false;

  @Input()
  showSaveBtn = false;

  @Input()
  footerMT = 'tw-mt-[20px]'

  @Input() isFromProfilePage = false;

  @Output() saveEmit = new EventEmitter();

  constructor(
    dir: Directionality,
    changeDetectorRef: ChangeDetectorRef,
    el: ElementRef,
  ) {
    super(dir, changeDetectorRef, el);
  }

  ngOnInit(): void {
  }

  save() {
    this.saveEmit.emit(true);
  }
}
