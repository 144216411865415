import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationModalService {
  public openModalSubject = new Subject<any>();
  public closeModalSubject = new Subject<boolean>();
  private callback: any;
  private cancelCallback: any;
  public usersIdsToSubscribe = [];

  constructor() {
    this.closeModalSubject.subscribe(this.closeModalHandler)
  }

  public withConfirmation(
    translatePath:string,
    callback:Function,
    cancelCallback?: Function,
    data?: any,
  ) {
    this.callback = callback;
    this.cancelCallback = cancelCallback;
    this.openModalSubject.next({translatePath, data});
  }

  private closeModalHandler = (confirm:boolean) => {
    if(confirm) {
      this.callback();
    } else if (this.cancelCallback) {
      this.cancelCallback();
    }

    this.callback = null;
    this.cancelCallback = null;
  }
}
