import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss']
})
export class ProfilePhotoComponent implements OnInit {
  readonly IMG_URL = 'https://statics.myhotel.io/uploads/fidelity-suite/users/default_user.png';
  @Input() image;

  @Output()
  changed: EventEmitter<any> = new EventEmitter();

  @Output()
  attachment: EventEmitter<any> = new EventEmitter();

  @ViewChild('fileInput', { static: true }) fileInput!: ElementRef;

  constructor(
    private utilService: UtilService,
  ) { }

  ngOnInit(): void { 
  }

  uploadImage(files){
    if(files.length===0)
      return;
    let type = files[0].type;
    if(type.match(/image\/*/) == null){
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.image = reader.result;
      this.emitImage();
    }
  }

  emitImage() {
    let image = this.image.split(',');

    let response = {
      'data': image[1],
      'format' : image[0],
      'user_id' : this.utilService.currentUser.id,
      'extension' : 'png'
    }
    this.changed.emit(response);
  }

}
