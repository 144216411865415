<mat-table  class="table-mh" #table [dataSource]="dataSource" matSortActive="number_question" matSortDisableClear matSortDirection="asc" matSort>

  <ng-container *ngFor="let key of headerTable" matColumnDef="{{key}}">
    <mat-header-cell *matHeaderCellDef mat-sort-header [class]="key">
      {{'commons.'+key | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element" [class]="key">

      <ng-container [ngSwitch]="key">
        <ng-template [ngSwitchCase]="'question'">
          <span  mhFindTranslate [language]="language" [translates]="element[key].translates"></span>
        </ng-template>

        <ng-template [ngSwitchCase]="'answer'">
          <div class="question_answer">
            <ng-container *ngTemplateOutlet="answerInput; context: {input:element['question'].input_type.type, element: element, key: key}"></ng-container>
          </div>
        </ng-template>

        <ng-template ngSwitchDefault>
          {{element[key]}}
        </ng-template>

      </ng-container>

    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="headerTable"></mat-header-row>
  <mat-row *matRowDef="let row; columns: headerTable;"></mat-row>
</mat-table>

<ng-template #answerInput let-input="input" let-element="element" let-key="key">
  <ng-container [ngSwitch]="input">

    <ng-template [ngSwitchCase]="'country'">
      {{'countries.'+element[key].answer  | translate }}
    </ng-template>

    <ng-template [ngSwitchCase]="'attachment'">
      <mh-core-lib-actions-wrapper
        slug="prestay_precheckin_attach_download"
        >
        <span class="input-attachment" [ngbTooltip]="('prestay.precheckin.download.download' | translate)" (click)="getFile(element[key].file_key, element.answer.id)">{{element[key]?.answer}}</span>
      </mh-core-lib-actions-wrapper>
      <ng-container *ngIf="waitingDownload && isAnswer(element.answer.id)">
        <mh-spinner-classic></mh-spinner-classic>
      </ng-container>
    </ng-template>

    <ng-template ngSwitchDefault>
      <span mhFindAlternatives [language]="language" [input]="element['question']" [answer]="element[key]" ></span>
    </ng-template>

  </ng-container>
</ng-template>
