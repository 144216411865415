import { Injectable  } from '@angular/core';
import {environment} from "../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class AdminUtilService {

  constructor(private http:HttpClient) { }

  usersInCustomer(customerId){
    const url=`${environment.customerUsers}`;
    const params:HttpParams= new HttpParams()
      .set("customer_id",customerId);
    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }
}
