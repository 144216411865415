<div class="box_element not_image {{variant == 'legacy' && placement}}" [class.subTitle]="subTitle">
  <div [class.warning_not_paid]="hasAlerts && !extraWarning" [hidden]="isPaid || protectedPaid"></div>
  <div [class.super_warning_not_paid]="hasAlerts && extraWarning" [hidden]="isPaid || protectedPaid"></div>

  <ng-container *ngTemplateOutlet="variant === 'legacy' ? legacyLayout : newLayout">
  </ng-container>
</div>


<ng-template #legacyLayout>
  <ng-container *ngIf="backButton.hasBackButton">
    <button type="button" class="btn btn-link back-button" (click)="handleBackLinkClick(backButton.link)">
      <i class="fas fa-arrow-circle-left"></i> {{(backButton.textButton | translate) }}
    </button>
  </ng-container>
  <h2 class="box_title" [hidden]="!hasTitle()">
    <span [innerHTML]="title"></span>
    <ng-container *ngIf="externalLink">
      <span class="external-link">
        <a [href]="externalLink" target="_blank" (click)="handleLinkClick()"><i class="fal fa-external-link"></i></a>
      </span>
    </ng-container>
  </h2>
  <h5 class="box_sub_title" [innerHtml]="subTitle" [hidden]="!hasSubTitle()"></h5>

  <div class="box_body">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #newLayout>
  <h2 class="new_box_title" [innerHTML]="title" [hidden]="!hasTitle()"></h2>
  <h5 class="box_sub_title" [innerHtml]="subTitle" [hidden]="!hasSubTitle()"></h5>

  <div class="box_body">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
