import { Component} from '@angular/core';
import { LayoutsComponent, UtilService } from '../../main/index';
import { ActivatedRoute } from '@angular/router';
import { PrestayUtilService } from './prestay-util.service';
import { PrestayLoginService }  from "../../../prestay/public/login/prestay-public-login.service";
import {
  GoogleAnalyticsService,
} from '../../../_services/google-analytics.service';
import { environment } from '../../../../environments/environment';

@Component({
    templateUrl: './prestay-theme.component.html',
    styleUrls: ['../../../../assets/scss/layouts/prestay.scss'],
    providers: [PrestayLoginService]
})

export class PrestayThemeComponent extends LayoutsComponent{

  utilPrestay:PrestayUtilService;
  util:UtilService;

  constructor(utilService:UtilService,  private activeRoute: ActivatedRoute, private prestayUtilService:PrestayUtilService,private googleAnalyticsService: GoogleAnalyticsService){
    super(utilService)
    const idGA = environment.googleAnalyticsGuest;
    this.googleAnalyticsService.appendGaTrackingCode(idGA);
    this.utilPrestay = prestayUtilService;
    this.theme = "prestay";
    let nickname = this.activeRoute.snapshot.paramMap.get("hotel_nickname");
    this.prestayUtilService.nickname = nickname;

    this.activeRoute.queryParams.subscribe(params => {
      this.currentLanguage = params['language'] || utilService.getCurrentLanguage();
      this.prestayUtilService.currentLanguage = this.currentLanguage;
      this.setLanguage(this.currentLanguage);
    });
  }



  ngOnChange(){

  }


}


