import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'widget-card-nps',
  templateUrl: './card-nps.component.html',
  styleUrls: ['./card-nps.component.scss'],
})
export class CardNpsComponent {
  @Input()
  title: string;

  @Input()
  titleWidget = 'followup.summary.nps.widget_title';

  @Input()
  waiting = false;

  @Input()
  passives = 0;

  @Input()
  detractors = 0;

  @Input()
  promoters = 0;

  @Input()
  dataInPercent = false; //if the data is in percent

  @Input()
  externalLink: string;

  @Input()
  externalLinkText: string;

  @Input()
  customerType: 'hotel' | 'retail' | 'restaurant' = 'hotel';

  @Input()
  satisfactionNPS: number;

  @Output() ebookClick: EventEmitter<null> = new EventEmitter();

  handleEbookClick() {
    window.open(this.externalLink, '_blank');
    this.ebookClick.emit();
  }

  get satisfaction() {
    if (this.satisfactionNPS) return this.satisfactionNPS;
    return this.promotersValue !== null && this.detractorsValue !== null
      ? this.promotersValue - this.detractorsValue
      : 0;
  }

  get isRetail() {
    return this.customerType === 'retail';
  }

  get totals() {
    return this.promoters + this.passives + this.detractors;
  }

  get promotersValue() {
    return this.getPercent('promoters');
  }

  get passivesValue() {
    return this.getPercent('passives');
  }

  get detractorsValue() {
    return this.getPercent('detractors');
  }

  get guestTranslateType() {
    return this.isRetail ? 'commons.clients' : 'commons.guests';
  }

  get customerTranslateType() {
    return this.isRetail ? 'commons.store' : 'commons.client';
  }

  getPercent(type: 'promoters' | 'passives' | 'detractors') {
    return this.dataInPercent ? this[type] : (this[type] * 100) / this.totals;
  }
}
