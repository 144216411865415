import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-support-field-product',
  templateUrl: './support-field-product.component.html',
  styleUrls: ['./support-field-product.component.scss']
})
export class SupportFieldProductComponent implements OnInit {

  @Input()
  products:any;

  @Input()
  form:FormGroup;

  @Output() productChange = new EventEmitter();

  clientType = '';
  constructor(
    private  utilService:UtilService
  ) { }

  ngOnInit() {
    this.clientType = (this.isRetail) ? 'retail' : 'default';
  }

  productChanged(product){
    this.productChange.emit(product.value);
  }

  get isRetail() {
    return this.utilService.customerIsRetail;
  }

}
