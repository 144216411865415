import { PieData }   from "../../shared/model/charts/pie-data.model";
import { MapData }   from "../../shared/model/charts/map-data.model";
import { MapExtras } from "../../shared/model/charts/extras/map-extras.extras";
import { Utils }     from '../../shared/model/utils';

export class ChartsDataSet extends Utils{

  mapExtras:MapExtras = new MapExtras();

  very_good    = "#11b72d";
  good         = "#9ac60c";
  regular      = "#f38b19";
  bad          = "#f54109";
  very_bad     = "#d00000";
  other        = "#2A58AD";

  private _randomColors:object = [
    '#e76f94',
    '#264453',
    '#23cb87',
    '#9933ff',
    '#ffcc00',
    '#78c3e4',
    '#ff99cc',
    '#4d917c',
    '#73439b',
    '#ffdf57',
    '#6a89aa',
    '#24c1da',
  ];

  private _chartColors:object = {
    "positive"  : this.very_good,
    "neutral"   : this.regular,
    "negative"  : this.very_bad,
    "state4"    : "#9b59b6",
    "state5"    : "#3498db"
  };



  private _dataTypes:any={
    "positive" : ["positivo","positive", "positiva"],
    "neutral"  : ["neutral","regular"],
    "negative" : ["negativo","negative", "negativa"],
    "state4"   : ["state4","state4"],
    "state5"   : ["state5","state5"]
  }

  private satisfactionNPS: string[]     = [this.very_good, this.regular, this.very_bad]; //note order 0,1,2

  colorsBySocore = {
    "0" : this.other,
    "1" : this.very_bad,
    "2" : this.bad,
    "3" : this.regular,
    "4" : this.good,
    "5" : this.very_good,
  }

  public latlong:object = this.mapExtras.latlong;

  public getPieData(pieDataArray:PieData[], language:string, randomColor:boolean=false, type="satisfaction", in_percent=false): object{
    let data: any  = [];
    let types: any = []
    let backgroundColor: any = [];
    let hoverBackgroundColor: any = [];

    for (let i = 0; i < pieDataArray.length; ++i) {
      let pieData: any = pieDataArray[i];
      if(pieData.language === language){
        data.push(pieData.data);
        types.push(this.formatText(pieData.type));
        let colors = this.getColorPie(pieData, randomColor, i, type, pieDataArray);
        backgroundColor.push(colors["principal"]);
        hoverBackgroundColor.push(colors["hover"]);
      }
    }

    return {
      "data" : data,
      "types" : types,
      "inPercent": in_percent,
      "backgroundColor" : backgroundColor,
      "hoverBackgroundColor": hoverBackgroundColor
    };
  }


  public getLineData(parserData, labels, dataSelector='none', stack='none',dataArray='data') {
    //parserData = [label:{data[]}]
    let data: any = [];
    let lineChartColors: any = [];

    Object.keys(parserData).forEach((key,index)=>{
      let dataCollect = parserData[key][dataArray]
      const isAverage = parserData[key]['selector'] === 'average'

      if(dataSelector!=='none') dataCollect = dataCollect.map(x=>x[dataSelector]);
      data.push({
        data: dataCollect,
        label: key,
        hidden: false,
        isAverage: isAverage
      });

      const color = this.colorRandomIndex(index,isAverage);
      lineChartColors.push(this.formatChartColor(color,isAverage));

    });

    let response =  {
      "data" : data,
      "labels" : labels,
      "colors" : lineChartColors
    };

    if(stack!=='none'){
      response['stack'] = stack
    }

    return response;

  }

  public getUniqueColorFromIndex(index: number, isAverage: boolean) {
    const color = this.colorRandomIndex(index,isAverage);
    return this.formatChartColor(color,isAverage)
  }

  formatChartColor(color, isAverage = false){
    const boderDash = isAverage ? [5,15] : [];
    return {
      backgroundColor: color,
      fill: false,
      borderDash: boderDash,
      borderColor: color,
      pointBackgroundColor: this.colorLuminance(color, -0.1),
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: this.colorLuminance(color, 0.1),
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    }
  }


  getMapData(countries:MapData[]):object{
    const areaData: object[] = [];
    const images: object[] = [];
    const minBulletSize = 10;
    const maxBulletSize = 25;
    let min = Infinity;
    let max = -Infinity;


    for (let i = 0; i < countries.length; ++i) {
      let value = countries[ i ].value;
      if ( value < min ) min = value;
      if ( value > max ) max = value;
    }

    let maxSquare = maxBulletSize * maxBulletSize * 2 * Math.PI;
    let minSquare = minBulletSize * minBulletSize * 2 * Math.PI;


    for ( let i = 0; i < countries.length; i++ ) {
      let dataItem = countries[ i ];
      let value = dataItem.value;
      let square = ( value - min ) / ( max - min ) * ( maxSquare - minSquare ) + minSquare;

      if ( square < minSquare ) square = minSquare;

      let size = Math.sqrt( square / ( Math.PI * 2 ) );
      let id = dataItem.id;

      let color = this._randomColors[i];

      areaData.push({
        "id": countries[i].id,
        "color": this.colorLuminance(color, -0.2)
      });

      images.push( {
        "type": "circle",
        "theme": "light",
        "width": size,
        "height": size,
        "color": color,
        "longitude": this.latlong[ id ].longitude,
        "latitude": this.latlong[ id ].latitude,
        "title": dataItem.name,
        "value": value
      });
    }

    return {
      "type": "map",
      "dataProvider": {
        "map": "worldLow",
        "getAreasFromMap": true,
        "images": images,
        "areas": areaData
      },
      "areasSettings": {
        "autoZoom": true,
        "selectedColor": "#7f8c8d",
        "color": "#eaeaea",
        "outlineColor" : "#7f8c8d",
      },
    }
  }

  private getColorPie(pieData:PieData, randomColor:boolean, index, satisfaction, data:PieData[]=[]):object{

    let color="#000000";

    if(satisfaction === "satisfaction" || satisfaction === "nps"){
      let setColor = satisfaction === 'nps' ? this.satisfactionNPS : this.colorsBySocore;
      color = setColor[pieData.note]
    }else{
      let type  = this.getTypePieData(pieData);
      color = randomColor ? this.colorRandomIndex(index) : this._chartColors[type];
    }

    return {
      "principal" : color,
      "hover" : this.colorLuminance(color, -0.1)
    };
  }

  colorRandomIndex(index, isAverage=false){
    let size_colors = Object.keys(this._randomColors).length
    let iteration   = Math.floor(index/size_colors+1);
    let color_index = size_colors <= index ? Math.floor(size_colors - index/iteration) : index;
    let luminance   = iteration===1 ? 0 : 1/(25*iteration);
    const color = isAverage ? this.very_bad : this._randomColors[color_index];
    return this.colorLuminance(color,luminance);
  }

  getSatisfactionColor(pieData:PieData, index:number){

  }

  private getTypePieData(pieData:PieData):string{
    let type = pieData.type;
    let type_result = "";
    for (let k in this._dataTypes){
      if(this._dataTypes[k].includes(type)){
        type_result = k;
        break;
      }
    }
    return type_result;
  }

  colorLuminance(hex:string, lum:number):string{

    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    let rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i*2,2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00"+c).substr(c.length);
    }

    return rgb;
  }

  hexToRGB(hex, alpha) {
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  lineChartColors(){

    let colors = ['#289ccd', '#2A58AD'];
    let lineChartColors:Array<any> = [];

    for(let color of colors){
      lineChartColors.push({
        backgroundColor: this.hexToRGB(color,0.8),
        borderColor: this.colorLuminance(color, 0.1),
        hoverBackgroundColor: this.colorLuminance(color, -0.1)
      });
    }





    return lineChartColors;
  }

  setEmptyChartData(labelsLength, translateLabel) {

    let data: any = [{
      data:[],
      label: translateLabel,
      hidden: false,
      isAverage: false
    }];
    labelsLength.forEach(element => {
      data[0].data.push(null)
    });
    return data;
  }

  parseTrendData(response, startDate, endDate, competitors, utilService) {
    const language = utilService.getCurrentLanguage();
    const unit: any = {};
    const dataLabels = utilService.getIntervalLabels(
      utilService.stringDateToDate(startDate),
      utilService.stringDateToDate(endDate),
    );
    const labelKeys = dataLabels.map((x: any) => x.label);
    const formatLabels = dataLabels.map((x: any) => x.format);

    for (let customer of competitors) {
      const key = customer['name'];
      for (let period of Object.keys(response)) {
        let date = utilService.getDateFromPeriod(period);
        let labelDate = utilService.formatDateLabel(date, language);

        if (unit[key] === undefined) {
          unit[key] = {
            coverage: labelKeys.map((x) => null),
            reviews: labelKeys.map((x) => null),
            score: labelKeys.map((x) => {}),
            qualification: labelKeys.map((x) => {}),
            formatLabels: formatLabels,
          };
        }

        let data = response[period];
        let customerData = data[customer['id'] + ''];
        let index = dataLabels.findIndex((x: any) => x.format === labelDate);
        if (index > -1) {
          unit[key].reviews[index] = customerData['reviews'];
          unit[key].score[index] = customerData['score'];
          unit[key].coverage[index] = customerData['coverage'];
          unit[key].qualification[index] = customerData['qualification'];
        }
      }
    }

    let result = {
      unit: unit,
      formatLabels: formatLabels,
    };
    return result;
  }


}


