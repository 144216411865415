import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, forkJoin } from 'rxjs';
import { AutocaseConfig, CasesGeneralConfig } from 'src/app/cases/types/index';
import { map } from 'rxjs/operators';
import { CasesLimit } from 'app/core-lib/dialogs/create-case-modal/model/detected-case.model';
@Injectable({
  providedIn: 'root'
})
export class ConfigCasesService {

  constructor(
    private http: HttpClient
  ) {}

  getCasesLimit(customerId: any): Observable<CasesLimit> {
    const url = `${environment.casesPath}customers/${customerId}/cases/usage`;
    return this.http.get<CasesLimit>(url);
  }

  getCasesUsageTrend(customerId: any) {
    const url = `${environment.casesPath}customers/${customerId}/cases/usage/trend`;
    return this.http.get(url);
  }

  saveCasesConfiguration(customerId: any, { limit, corporative }: { limit: any, corporative: any }) {
    const url = `${environment.casesPath}customers/${customerId}/cases/config`;
    const params = new HttpParams()
      .set('cases_monthly_limit', limit && limit.toString() || 0)
      .set('corporative', corporative.toString());
    return this.http.put(url, {}, {params});
  }

  getCasesCurrentConfig(customerId): Observable<CasesGeneralConfig> {
    const url = `${environment.casesPath}customers/${customerId}/cases/general-config`;
    return this.http.get<CasesGeneralConfig>(url);
  }

  setCasesCurrentConfig(customerId, body): Observable<CasesGeneralConfig> {
    const url = `${environment.casesPath}customers/${customerId}/cases/general-config`;
    return this.http.put<CasesGeneralConfig>(url, body);
  }

  getAutocaseConfig(customerId): Observable<AutocaseConfig[]> {
    const url = `${environment.casesPath}customers/${customerId}/cases/autocase-config`;
    return this.http.get<AutocaseConfig[]>(url);
  }

  setAutocaseConfig(customerId, body): Observable<AutocaseConfig[]> {
    const url = `${environment.casesPath}customers/${customerId}/cases/autocase-config`;
    return this.http.put<AutocaseConfig[]>(url, body);
  }

  getAllCasesConfig(customerId): Observable<{ casesConfig: CasesGeneralConfig; autocaseConfig: AutocaseConfig[] }> {
    const casesConfig = this.getCasesCurrentConfig(customerId);
    const autocaseConfig = this.getAutocaseConfig(customerId);

    return forkJoin([casesConfig, autocaseConfig]).pipe(
      map(([response1, response2]) => {
        return { casesConfig: response1, autocaseConfig: response2 };
      }),
    );
  }

  setFlagExpired(flag: boolean) {
    localStorage.setItem('cases_flag_expired', JSON.stringify(flag));
  }

  getFlagExpired(): boolean {
    return JSON.parse(localStorage.getItem('cases_flag_expired'));
  }
}
