<div *ngIf="style === 'input'; else chipsStyle" class="tw-w-full tw-min-h-[2.5rem] tw-py-2 tw-rounded-xl tw-border tw-border-gray-400 tw-relative tw-pl-5 tw-pr-8 tw-cursor-pointer" (click)="handleFileInputClick(fileInput)">
  <span *ngIf="files.length === 0" class="tw-text-gray-500">{{ 'core-lib.input.dialog_cases.files' | translate }}</span>
  <div class="tw-flex tw-gap-2 tw-flex-wrap tw-h-full tw-content-center">
    <ng-container *ngFor="let file of files">
      <span class="tw-rounded-xl tw-py-1 tw-px-5 tw-bg-notification-bg-back-button tw-text-black tw-text-xs">
        {{ file.name }}
      </span>
    </ng-container>
  </div>
  <i
    class="fal fa-paperclip tw-absolute tw-right-2 tw-top-3 tw-cursor-pointer tw-text-gray-500"
  ></i>
</div>

<ng-template #chipsStyle>
  <div class="tw-w-full tw-flex tw-gap-3 tw-flex-wrap">
    <div *ngFor="let file of files">
      <span class="tw-rounded-xl tw-py-1 tw-px-5 tw-bg-notification-bg-back-button tw-text-black tw-text-xs">
        {{ file.name }}
      </span>
    </div>
  </div>
</ng-template>

<input #fileInput type="file" multiple [accept]="fileTypesAccepted" (change)="onFileInput($event)" class="tw-hidden" />
<p *ngIf="maxFileSizeError" class="tw-text-red-500 tw-text-sm tw-text-center tw-px-4 tw-mt-1">{{ 'cases.create_modal.max_file_size_error' | translate }}</p>
<p *ngIf="maxNumberOfFilesError" class="tw-text-red-500 tw-text-sm tw-text-center tw-px-4 tw-mt-1">{{ 'cases.create_modal.max_number_of_files_error' | translate }}</p>

<ng-container *ngIf="uploadingFiles">
  <div class="tw-mt-4">
    <mh-core-lib-loader-progress-bar></mh-core-lib-loader-progress-bar>
  </div>
</ng-container>
