
export class Alerts{
    id: number;
    alert_id: number;
    name: string;
    property_type: string;
    property_type_id: number;
    products: Products[]; 
}
export class Customers {
    id: number;
    name: string;
    list_alerts: Alerts[];
}
export class Product {
  constructor(
    public id: any,
    public product_id: number,
    public name: string,
    public checked:boolean
  ) {}
}
export class Products {
  constructor(
    public id: number,
    public product_id: number      
  ) {}
}


  
