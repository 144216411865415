import {Injectable} from '@angular/core';
import {Question} from "../../../shared/model/question.model";
import { environment } from '../../../../environments/environment';
import { Subject} from "rxjs";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommentaryFeedService {

  overallSurvey: any;
  allQuestions:Question[] = [];
  subjectSurveyDetail = new Subject<any>();

  constructor(private http: HttpClient) {}

  public getCommentsFeed(
    dates: any,
    customerId: string,
    productId:any,
    languageId,
    surveysIds,
    version: number
  ) {
    const url = version === 1 ?
      `${environment.followupPath}summary/surveys/comments/feeds` :
      `${environment.surveysPath}comments`;

    const params:HttpParams = new HttpParams()
      .set("start_date", dates.initDate)
      .set("end_date", dates.endDate)
      .set("customer_id", customerId)
      .set("product_id", productId)
      .set("language_id", languageId)
      .set("surveys_ids", surveysIds);

    return this.http.get(url,{params: params})
      .pipe(
        map((response:any[]) =>
          // transform v2 to v1
          (response || []).map(r => ({
            ...r,
            open_date: r.answered_survey.open_date || r.open_date,
            answered_survey: {
              ...r.answered_survey,
              ...r.answered_survey.nps,
              date: r.answered_survey.answered_date || r.answered_survey.date,
            }
          }))
        )
      );
  }

  getCommentsFeedV2(
    languageId,
    dates,
    surveysId,
    page,
    pageSize,
    paged
  ) {
    const url = `${environment.surveysPath}comments`;
    const params = new HttpParams()
      .set('language_id', languageId)
      .set('start_date', dates.initDate)
      .set('end_date', dates.endDate)
      .set('surveys_ids', surveysId)
      .set('page', page)
      .set('page_size', pageSize)
      .set('paged', paged);
    return this.http.get(url, { params });
  }

  public getSurvey(answeredId, productId:string) {
    const url = `${environment.followupPath}surveys/answereds/${answeredId}`;
    let params:HttpParams = new HttpParams()
      .set("product_id", productId);
    return this.http.get(url,{params: params})
      .pipe(map(response=> response));
  }

  public getAnswerService(questionid, alternativeId) {
    let AnswerObject=this.overallSurvey.answers[questionid];
    let response = false;
    if (AnswerObject) {
      for (let i = 0; i < AnswerObject.length; i++) {
        if ((AnswerObject[i].alternative_id === alternativeId)) {
          response = true;
        }
      }
    }
    return response;
  }



  fillQuestion(data:any): Question[]{
    let allQuestions: Question[] = [];
    for(let item_question of data.survey.questions){
      let question = new Question(item_question.id,item_question.order,item_question.statement,item_question.sort);
      question.createFromApi(item_question, data.guest);
      allQuestions.push(question);
    }
    allQuestions.sort(function(a,b) {return (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0);} );
    return allQuestions;
  }

  public translateComment(commentId, sourceLanguage, targetLanguage,productId, title, description){
    const url=`${environment.customerPath}translate`;
    const body= {
      "source_language_id": sourceLanguage,
      "target_language_id": targetLanguage,
      "product_id": productId,
      "comment_id": commentId,
      "title": title,
      "description": description
    }

    return this.http.post(url,body)
      .pipe(
        map(response=>response)
      );
  }
}
