import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { SurveysService } from 'app/core-lib/services/surveys/surveys.service';
import { TranslateObjectPipe } from 'app/shared/pipe';
import { FollowupOnsiteState } from 'app/states/followup-onsite/followup-onsite.state';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'mh-core-lib-survey-selector',
  templateUrl: './survey-selector.component.html',
  styleUrls: ['./survey-selector.component.scss']
})
export class CoreLibSurveySelectorComponent implements OnInit {

  isOpen = false;
  @Select(FollowupOnsiteState.surveys) surveys$: Observable<any>;
  surveys;
  surveysSelected = [];

  buttonLabel = 'commons.alls';
  displayFn = (option) => {
    return this.translateObjectPipe.transform(option.translates, 'title');
  }
  subscriptions = new Subscription();

  @HostListener('document:click', ['$event'])
  onClick(event){
    const result = this.checkClickInside(event.target);
    if ( !result ) {
      this.isOpen = false;
    }
  }

  constructor(
    private surveysService: SurveysService,
    private store: Store,
    private translateObjectPipe: TranslateObjectPipe,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.detectRouteChanges();
    this.getSurveys();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  detectRouteChanges() {
    this.router.events.subscribe((event: any) => {
      if ( event instanceof NavigationEnd ) {
        this.surveys = this.store.selectSnapshot(FollowupOnsiteState.surveys);
        this.surveysSelected = this.surveys;
        this.handleOptionClicked(this.surveys)
      }
    })
  }

  getSurveys() {
    const subscription = this.surveys$.subscribe((response) => {
      if ( response ) {
        this.surveys = this.store.selectSnapshot(FollowupOnsiteState.surveys);
        this.surveysSelected = this.surveys;
      }
    });
    this.subscriptions.add(subscription);
  }

  handleOptionClicked($event) {
    this.setLabelButton($event);
    this.surveysService.setCurrentSurveyV2($event);
  }

  setLabelButton($event) {
    this.surveysSelected = $event;
    if ( this.surveys.length === $event.length ) {
      this.buttonLabel = 'commons.alls';
    } else {
      this.buttonLabel = ( $event.length > 1 || $event.length === 0 ) ?
        'commons.surveys_selected' :
        'commons.survey_selected';
    }
  }

  checkClickInside(target) {
    if ( target.id === 'overlaySelectorSurvey' || target.id === 'surveySelector' || target.className?.includes('chip-no-close')) {
      return true;
    } else if ( target.parentNode ) {
      return this.checkClickInside(target.parentNode)
    } else {
      return false;
    }
  }



  get areAllSurveysSelected() {
    return this.buttonLabel === 'commons.alls';
  }

}
