<section class="tw-flex tw-flex-col tw-justify-center tw-gap-10 tw-h-full">
  <section class="tw-flex tw-justify-center">
    <p class="tw-text-main-text tw-text-xl">{{text}}</p>
  </section>

  <section class="tw-flex tw-justify-center {{ isMobile ? 'tw-gap-2' : 'tw-gap-5'}}">
    <mh-core-lib-button
        [isDefault]="true"
        [text]="'commons.accept' | translate"
        [width]="isMobile ? 120 : 160"
        (clicked)="handleButtonClick('ACCEPT')"
        ></mh-core-lib-button>

    <mh-core-lib-button
        [isSecondary]="true"
        [text]="'commons.cancel' | translate"
        [width]="isMobile ? 120 : 160"
        (clicked)="handleButtonClick('CANCEL')"
        ></mh-core-lib-button>

  </section>
</section>
