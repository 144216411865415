export class StoreCurrentCustomer {
  static readonly type = '[Current Customer] Store Current Customer';
  constructor(public currentCustomer: any) {}
}

export class StoreFullCurrentChain {
  static readonly type = '[Full Customers Chain] Store Full Current Chain';
  constructor(public fullCurrentChain: any[]) {}
}

export class StoreRelativeCurrentChain {
  static readonly type = '[Relative Customers Chain] Store Relative Current Chain';
  constructor(public relativeCurrentChain: any[]) {}
}
