<ng-container *ngIf="isPromoter">
  <i class="far fa-smile tw-text-promoters {{size}}"></i>
</ng-container>

<ng-container *ngIf="isPassive">
  <i class="far fa-meh tw-text-passives {{size}}"></i>
</ng-container>

<ng-container *ngIf="isDetractor">
  <i class="far fa-frown tw-text-detractors {{size}}"></i>
</ng-container>
