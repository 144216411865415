<div #buttonsActions
  [disableTooltip]="hasProduct"
  [ngbTooltip]="noProductTooltip"
  class="action-buttons h-100"
  [class.flex-column]="flexColumn"
  [class.align-items-center]="alignCenter"
  [class.justify-content-center]="justifyCenter"
  [ngClass]="showFlex ? 'd-flex' : 'inline'"
>
  <button *ngIf="cases?.length && hasProduct && !insideModal"
    class="btn btn-white"
    [class.button-width]="showFlex"
    [matMenuTriggerFor]="casesMenu"
  >
    {{'cases.commons.created_cases' | translate}}
    <mat-icon aria-lable="dropdown">keyboard_arrow_down</mat-icon>
  </button>

  <button class="btn btn-orange"
    *ngIf="!cases?.length"
    [class.disabled]="!hasProduct"
    (click)="openNewModal(newCaseForm)">
    {{'cases.commons.create_case' | translate}}
  </button>
</div>

<mat-menu #casesMenu>
  <button
    mat-menu-item
    *ngFor="let case of cases"
    (click)="openDetailModal(caseDetail, case.public_id)"
  >
    {{case.title}}
  </button>
  <button
    mat-menu-item
    [class.disabled]="!hasProduct"
    (click)="openNewModal(newCaseForm)">
    {{'cases.commons.create_case' | translate}}
  </button>
</mat-menu>

<ng-template #newCaseForm>
  <mh-modal [close]="closeModal" class="gray-background">
    <mh-new-case-form
      [users]="users"
      [productItem]="productItem"
      [survey]="survey"
      (onCreate)="afterCreate($event)"
    ></mh-new-case-form>
  </mh-modal>
</ng-template>

<ng-template #caseDetail>
  <mh-modal [close]="closeModal" class="gray-background">
    <mh-case-detail
      [users]="users"
      [caseId]="selectedCaseId"
      (onUpdate)="afterUpdate($event)"
    >
    </mh-case-detail>
  </mh-modal>
</ng-template>

<ng-template #noProductTooltip>
  <div>{{'cases.messages.no_product' | translate}}</div>
</ng-template>
