<div class="filter-table"  >
  <mat-form-field *ngIf="!waitingDetail">
    <input matInput (keyup)="applyFilter($event.target.value)" [placeholder]="('commons.search' | translate)">
  </mat-form-field>

  <div class="area_table" [ngStyle]="tableAreaStyle">
    <mat-table  class="table-mh sticky centered-header centered-content" [ngStyle]="tableContentStyle" [class.invisible]="waitingDetail" #table [dataSource]="dataSource" [matSortActive]="firstColumn" matSortDisableClear matSortDirection="asc" matSort>

      <ng-container *ngFor="let key of headerTable; let i=index" matColumnDef="{{key}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header  [class.first]="i===0">
          {{ (i===0 ? (typeCustomer | translate ) : key) }}
        </mat-header-cell>

        <mat-cell *matCellDef="let element" [class.first]="i===0">
          <ng-container [ngSwitch]="key">
            <ng-template [ngSwitchCase]="i===0 ? key : false">
              <div class="first-column">
                {{element[key]}}
              </div>
            </ng-template>
            <ng-template ngSwitchDefault>
              {{element[key] | formatBy: formatBy}}
            </ng-template>
          </ng-container>
        </mat-cell>

        <ng-container *ngIf="showTotals">

          <mat-footer-cell [class.first]="i===0" *matFooterCellDef>
            <div class="full-width" [ngSwitch]="key">
              <ng-template [ngSwitchCase]="i===0 ? key : false">
                <b>{{'commons.'+totalBy | translate }}</b>
              </ng-template>

              <ng-template ngSwitchDefault>
                {{totals[key] | formatBy: formatBy}}
              </ng-template>
            </div>
          </mat-footer-cell>
        </ng-container>

      </ng-container>
      <mat-header-row *matHeaderRowDef="headerTable"></mat-header-row>
      <mat-row [class.pointer]="showDetail" *matRowDef="let row; columns: headerTable;" (click)="showDetail ? openDialog(row) : null"></mat-row>
      <ng-container *ngIf="showTotals">
        <mat-footer-row *matFooterRowDef="headerTable"></mat-footer-row>
      </ng-container>
    </mat-table>
  </div>

</div>

<ng-template #confirmModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <i class="close far fa-times" (click)="closeDialog()"></i>
    <h4 class="title">{{'commons.details' | translate }}</h4>
  </div>

  <div class="modal-body">
    <div class="dialog_options">
      <div class="content-detail">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-template>
