export class Period{
  actual: number | null = null;
  previous: number | null = null;
  variation: number | null = null;
  total_of;
  invisible = false;

  formatActual;
  formatPrevious;
  formatVariation;

  constructor()
  constructor(actual,previous,variation,total_of)
  constructor(actual?,previous?,variation?,total_of?){
    this.actual    = actual    || null;
    this.previous  = previous  || null;
    this.variation = variation || null;
    this.total_of  = total_of  || null;
  }


  calculateVaration(){
    if(this.actual && this.previous){
      const diference = this.actual - this.previous;
      const variation = this.previous > 0 ? ((diference) / this.previous * 100) : 0;
      this.variation = variation;
    }else{
      return this.variation = null;
    }
  }

  addInKey(key: 'actual' | 'previous', value: any){
    if(this[key]===null && value === null){
      this[key]=null;
    }else{
      this[key] = this[key] + value;
    }
  }

}
