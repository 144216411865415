<a href="javascript:void(0)" *ngIf="otasErrors" [matMenuTriggerFor]="errorsOtas">
  <div class="ota-error-menu">
    <i class="fas fa-exclamation-triangle"></i>
  </div>
</a>

<mat-menu #errorsOtas xPosition="before">
  <div class="list-group">
    <ng-container *ngFor="let ota of otasErrors">
      <div class="list-group-item ota-error-container"
        *ngIf="(ota.error.public_error && currentUser.admin) || currentUser.superAdmin">
        <div class="d-flex justify-content-between mb-1">
          <h5>{{ota.ota_name}}:</h5>
          <a [href]="manualLink" target="_blank">
            {{('admin.otas.error.reset_link' | translate)}}
          </a>
        </div>
        <div class="error-body">
          <p><strong>{{('admin.otas.error.since' | translate)}}:</strong> {{ota.since | date:'dd-MM-y'}}</p>
          <p><strong>{{('admin.otas.error.last_update' | translate)}}:</strong> {{ota.last_update | date:'dd-MM-y'}}</p>
          <p><strong>{{('admin.otas.error.error' | translate)}}:</strong> {{ota.error.translates |
            translateObject:'text':currentLanguage }}</p>
          <p><strong>{{('admin.otas.error.type_error' | translate)}}:</strong> {{(getTranslateTypeError(ota) | translate)}}</p>
        </div>
      </div>
    </ng-container>
  </div>
</mat-menu>
