import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MailParameters } from '../shared/models/mail-parameters';
import { MailSetup } from '../shared/models/mail-setup';

@Injectable({
  providedIn: 'root'
})
export class MailingService {

  constructor(
    private http: HttpClient
  ) { }

  getEmailSenderConf(survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}/emails/sender`;
    return this.http.get(url);
  }

  getEmailSetupConf(survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}/emails/setup`
    return this.http.get(url);
  }

  getEmailPreview(survey_id: number, language_id: number) {
    const url = `${environment.surveysPath}${survey_id}/emails/setup/preview`
    const params: HttpParams = new HttpParams()
      .set('language_id', language_id.toString());
    return this.http.get(url, {responseType: 'text', params});
  }

  editEmailSenderConf(survey_id: number, emailConf: MailParameters) {
    const url = `${environment.surveysPath}${survey_id}/emails/sender`;
    return this.http.put(url, emailConf);
  }

  editMailSetupConf(survey_id: number, setupConf: MailSetup[]) {
    const url = `${environment.surveysPath}${survey_id}/emails/setup`
    return this.http.put(url, setupConf);
  }

  sendMailTest(survey_id: number, language_id: number, email: string) {
    const url = `${environment.surveysPath}${survey_id}/emails/setup/test`
    const params = new HttpParams()
      .set('email', email)
      .set('language_id', language_id.toString());
    return this.http.post(url, {},{params});
  }

  cloneSetupMailing(survey_id: number, source_survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}/emails/setup/clone/${source_survey_id}`;
    return this.http.put(url, {});
  }
}
