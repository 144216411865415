<h1 class="title">{{'shared.semantic.categories.modal_title_'+commentOrReview() | translate:({type: ('shared.semantic.plural.'+commentOrReview()+'.'+filter | translate)})}} <span>{{area.area.nameArea() | translate }}</span></h1>
<div class="wait_central" [hidden]="!waiting">
  <mh-spinner-load *ngIf="waiting"></mh-spinner-load>
</div>
<ng-container *ngIf="!waiting">

  <div class="empty" *ngIf="listResults.length==0">
    {{'shared.semantic.categories.empty_'+commentOrReview() | translate }}
  </div>

  <ng-container >
    <div class="secction_reviews">
      <div class="summary_themes">
        <h3 class="title_topics">{{ 'shared.semantic.trending_topic' | translate }}</h3>
        <ul class="list_theme">
          <li *ngFor="let theme of themes">
            <div class="theme" [class.selected]="theme.category==filterSelected" [class.not_filter]="filterSelected=='all'" (click)="changeThemeFilter(theme.category)">
              <span  mhFindTranslate [language]="language" [translates]="theme.translates" [keyTranlsate]="'text'"></span>({{theme.total}})
            </div>
          </li>
        </ul>
      </div>
      <div class="see_all" [class.not_filter]="filterSelected=='all'">
        <span class="link" (click)="resetThemeFileter()">{{'shared.semantic.categories.see_all_'+getKeyData() | translate }}</span>
      </div>
      <ul class="list_reviews">
        <li *ngFor="let data of listResults" [hidden]="!showThisElement(data)">
          <ng-container *ngIf="getKeyData()=='reviews'">
            <mh-review [review]="data" [hotelDto]="hotelDto" [customer]="customer"></mh-review>
          </ng-container>

          <ng-container *ngIf="getKeyData()=='comments'">
            <mh-survey-summary [survey]="data" [from]="'semantic'" [productId]="productId" (showingSurvey)="toggleModal($event)"></mh-survey-summary>
          </ng-container>

        </li>
      </ul>
    </div>
  </ng-container>

</ng-container>

