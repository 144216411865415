import { Component, OnInit, Input } from '@angular/core';

import { UtilService } from "app/shared/util.service";

@Component({
  selector: 'widget-guest-info',
  templateUrl: './guest-info.component.html',
  styleUrls: ['./guest-info.component.scss']
})
export class GuestInfoComponent implements OnInit {

  @Input()
  countryCode:string;

  @Input()
  name: string;

  @Input()
  lastName: string;

  @Input()
  email: string;

  @Input()
  phone: string;

  @Input()
  satisfaction;

  @Input()
  reservationId: string;

  @Input()
  id:number;

  @Input()
  layoutView: 'list-view' | 'card-view' | any;

  @Input()
  integrationChannel;

  @Input()
  product;

  @Input()
  isFlagUp = false;

  @Input()
  isFlagDown = true;

  letters: string;
  fullName: string;

  constructor(
    private utilService: UtilService,
  ) { }

  ngOnInit() {
    this.setName();
  }


  setName(){
    const name      = this.name ? this.name[0] : '';
    const lastName  = this.lastName ? this.lastName[0] : '';
    this.fullName   = (this.name+' '+this.lastName).toLocaleLowerCase();
    this.letters    = name+lastName;
  }

  get isCardView() { return this.layoutView === 'card-view'}
  get classCountry() {
    if(this.layoutView === 'card-view'){
      return 'justify-content-center'
    } else return null
  }
  get classAnonymous() {
    if(this.layoutView === 'card-view'){
      return null
    } else return 'd-flex align-items-center'
  }
  get isAirline() { return this.utilService.customerIsAirline };
  get isRetail() { return this.utilService.customerIsRetail };
  get isClinic(){ return this.utilService.customerIsClinic}
  get typeTitle(){
    if(this.isRetail) return 'commons.client';
    if(this.isClinic) return 'commons.patient';
    if(this.isAirline) return 'commons.passenger';

    return 'commons.guest';
  }
  get defaultPhoto() {
    return 'https://statics.myhotel.io/uploads/fidelity-suite/users/default_user.png';
  }
  get isAnonymous() {
    return this.integrationChannel?.id === 3 || false;
  }

  get isPrestay() {
    return this.product === 'prestay';
  }

  get isFromModal() {
    return this.layoutView === 'modal';
  }
}
