import { Component, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'mh-core-lib-circular-desc-chart',
  templateUrl: './circular-desc-chart.component.html',
  styleUrls: ['./circular-desc-chart.component.scss'],
})
export class CoreLibCircularDescChartComponent implements OnInit {
  @Input() time;
  @Input() dueDate;

  isExpired = false;
  remainingPercentage = 0;
  dueDateText = '';
  dueTimeText = '';

  ngOnInit(): void {
    this.getPercentages();
    this.setTooltipDateText();
  }

  getPercentages() {
    if (this.time?.remaining_time < 0) {
      this.isExpired = true;
      return;
    }
    const total = this.time?.total_time;
    const remaining = this.time?.remaining_time;
    this.remainingPercentage = Math.round((remaining * 100) / total);
  }

  setTooltipDateText() {
    if (this.time?.remaining_time < 0 && !this.dueDate) {
      return;
    }

    this.dueDateText = DateTime.fromISO(this.dueDate?.split(' ')[0]).toFormat('dd/LL/yyyy');
    this.dueTimeText = this.dueDate?.split(' ')[1];
  }
}
