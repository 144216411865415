<article class="tw-flex tw-space-x-4">
    <button
        class="tw-rounded-full md:tw-w-fit tw-w-14 tw-h-14 tw-bg-white tw-text-main tw-shadow-1 tw-flex tw-justify-center md:tw-px-7 md:tw-py-2 focus:tw-outline-0"
        (click)="isOpen = !isOpen"
        cdkOverlayOrigin #trigger="cdkOverlayOrigin"
        [class.!tw-bg-main]="isOpen"
        [class.!tw-text-white]="isOpen"
        >
        <i class="fas fa-filter tw-self-center"></i>
        <span class="tw-hidden md:tw-inline-block tw-ml-2 tw-text-base tw-font-medium tw-self-center" [class.!tw-text-white]="isOpen">
            {{'core-lib.selectors.guest.filter_by' | translate}}
        </span>
    </button>

    <button 
      class="tw-rounded-full tw-bg-white tw-text-main tw-shadow-1 tw-w-14 tw-h-14 hover:tw-text-white hover:tw-bg-main tw-transition-all focus:tw-outline-0" 
      *ngIf="hasActiveFilters"
      (click)="cleanFilters()"
    >
        <div class="tw-relative">
        <i class="fas fa-filter tw-self-center"></i>
        <i class="fas fa-times-circle tw-absolute tw-text-red-500 tw-text-small tw-right-3.5"></i>
        </div>
    </button>
</article>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPanelClass]="['tw-absolute', 'tw-inset-x-0']"
>
    
<div class="tw-bg-white tw-shadow-1 tw-rounded tw-flex tw-flex-col tw-mt-3" id="statusInvoiceFilterOverlay">

  <div class="tw-flex tw-flex-col xl:tw-flex-row xl:tw-border-b-2 xl:tw-border-border-light-gray">
      <div class="tw-min-w-[140px] xl:tw-min-h-[180px] tw-max-w-[140px] tw-max-h-[180px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-pb-1 tw-pt-3 tw-px-3 xl:tw-p-3">
        <div class="tw-flex">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{'configuration.invoice.status' | translate}}</span>
        </div>
        <div>
          <mh-core-lib-checkbox-input
              [options]="options"
              [translatePrefix]="'configuration.invoice.'"
              (optionClicked)="updateFilter($event)">
          </mh-core-lib-checkbox-input>
        </div>
      </div>
  </div>
</div>
    
</ng-template>
