export class PrestayMenu{
  type:string;
  nick_id: string;
  link:string;

  constructor(type,nick_id){
    this.type = type;
    this.nick_id = nick_id;
    this.link = `/${nick_id}/prestay/${type}/index`;
  }
}
