<div class="invisible">
  <div class="row no-gutters align-items-center fade-rows">
    <ng-container *ngFor="let row of rows">
      <ng-container *ngIf="row === 'TOP'">
        <ng-container *ngTemplateOutlet="colTemplate;context:{items: drawTop}"></ng-container>
      </ng-container>
      <ng-container *ngIf="row === 'MIDDLE'">
        <ng-container *ngTemplateOutlet="colTemplate;context:{items: drawMiddle}"></ng-container>
      </ng-container>
      <ng-container *ngIf="row === 'BOTTOM'">
        <ng-container *ngTemplateOutlet="colTemplate;context:{items: drawBottom}"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <ng-content></ng-content>
</div>

<ng-template #colTemplate let-items="items">
  <div class="{{col}} col-element" *ngFor="let item of items">
    <div class="content-loader">
      <div *ngIf="item === 1" class="loader" [ngStyle]="style"></div>
      <div *ngIf="item === 0" class="loader no-loader" [ngStyle]="style"></div>
    </div>
  </div>
</ng-template>
