<div class="price_area" [class.has_discount]="properties.show_aux_price" *ngIf="properties.price_type.id==1">
  <div class="price"     [hidden]="!properties.show_price">{{ (isOrdersPage ? transformPrice(reservation_unit_price) : transformPrice(properties.price)) | currency:money.code:(money.code+' '+money.symbol):'':(money.code === 'USD' ) ? 'en' : ''  }}</div>
  <div class="aux_price" [hidden]="!properties.show_aux_price">{{ (transformPrice(properties.aux_price) | currency:money.code:(money.code+' '+money.symbol):'':(money.code === 'USD' ) ? 'en' : '') }}</div>
</div>

<div class="percent_area" *ngIf="properties.price_type.id==2">
  <div class="perecent">{{properties.referral_percent}}% <span>{{'models.product.referral_type.'+properties.referral_type.name | translate }}</span></div>
</div>


