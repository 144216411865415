import { Component } from '@angular/core';
import { UtilService } from "../../../../shared/util.service";


@Component({
  selector: 'mh-online-coverage-section',
  templateUrl: './online-coverage-section.component.html',
})
export class OnlineCoverageSectionsComponent{
  util;
  constructor(private utilService:UtilService) {
    this.util = this.utilService
  }
}
