import {Language} from './language.model';
import {Country} from './country.model';
export class Guest {
  nickName:string;
  lastName: string | any;
  lastname?: string | any;
  name: string | any;
  guessId: number | any;//deprecated
  email: string | any;
  checkin: Date | any;
  checkout: Date | any;
  answerDate: Date;
  room: number | any;
  country: Country;
  language: Language;
  phone_number:string;
  phone: string;
  id=0;
  flag_app_android:boolean = false;
  reservation_id:any;
  reservation_channel='';
  show_checkin?: boolean;
  show_checkout?: boolean;
  managed;
  integration_channel?: { id: string; soruce: string };
  flag_email_valid?: boolean;

  static createFromApi(object: any) {
    return (new Guest()).createFromApi(object);
  }

  constructor()
  constructor(lastName: string, name: string,  email: string,room: number)
  constructor(lastName: string, name: string,  email: string,room: number, checkin: Date, checkout: Date)
  constructor(lastName?: string, name?: string, email?: string, room?: number, checkin?: Date, checkout?: Date,guessId?: number, country?: string)
  constructor(lastName?: string, name?: string, email?: string, room?: number, checkin?: Date, checkout?: Date,guessId?: number, country?: string, language?: string) {
    this.lastName = lastName;
    this.name = name;
    this.guessId = guessId;
    this.email = email;
    this.checkin = checkin;
    this.checkout = checkout;
    this.room = room;
  }

  createFromApi(data){
    this.lastName         = data.last_name || data?.lastname;
    this.name             = data.name;
    this.guessId          = data.guess_id || data.id;
    this.id               = data.guess_id || data.id;
    this.email            = data.email;
    this.checkin          = this.createDate(data.check_in_date || data?.checkin);
    this.checkout         = this.createDate(data.check_out_date || data?.checkout);
    this.room             = data.room || 0;
    this.country          = data.country  ? new Country(data.country.code || data?.country?.code2, data.country.name, data.country.continent) : new Country();
    this.language         = data.language ? new Language(data.language.id,data.language.code) : new Language();
    this.phone_number     = data.phone_number;
    this.phone = data.phone;
    this.flag_app_android = data.flag_app_android ? data.flag_app_android===1 : false;
    this.reservation_id       = data.reservation_id;
    this.reservation_channel  = data.reservation_channel;
    this.show_checkin = data.show_checkin || true;
    this.show_checkout = data.show_checkout || true;
    this.managed = data?.managed;
    this.flag_email_valid = data?.flag_email_valid;
    return this;
  }

  fullName(): string{
    return (this.name+" "+this.lastName).toLowerCase();
  }

  fullNameHtml(): string{
    return (this.name+' <span>'+this.lastName+'</span>').toLowerCase();
  }

  initialesName(): string{
    let name = this.name ? this.name[0] : '';
    let lastName = this.lastName ? this.lastName[0] : '';
    return name+'<span>'+lastName+'</span>';
  }

  stayTime(): string{
    let diffDays = this.stayDistance();
    return diffDays===1 ? 'single' : 'plural';
  }
  stayDistance(): number{
    return this.stayTimeDistance();
  }
  stayTimeDistance():number{
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    let utc1          = Date.UTC(this.checkin.getFullYear(), this.checkin.getMonth(), this.checkin.getDate());
    let utc2          = Date.UTC(this.checkout.getFullYear(), this.checkout.getMonth(), this.checkout.getDate());
    let diffDays      = Math.floor((utc2 - utc1) / _MS_PER_DAY);
    return diffDays;
  }

  hasPhone(){
    return !!this.phone || !!this.phone_number;
  }

  hasEmail(){
    return this.email && this.email !== '';
  }

  createDate(value){//bug sufari
    let stringDate = (typeof value === "number") ? value : (value+'').replace(/\s/, 'T');
    let date:Date  = new Date(stringDate);
    date   = date.getFullYear() ? date : new Date(value); //bug safari
    return date;
  }


}
