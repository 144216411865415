import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../_services/index';
import {ForgotPasswordService} from "./forgot-password.service";

@Component({
    templateUrl: 'forgot-password.component.html',
    styleUrls: ['../../assets/scss/forgot-password/forgot-password.scss'],
    providers:[ForgotPasswordService]
})

export class ForgotPasswordComponent{

  validates= ['email'];
  form;
  waiting:boolean = false;


  constructor(private forgotPasswordService: ForgotPasswordService,private fb: FormBuilder,private alertService: AlertService,) {
    this.form = this.fb.group({
      email:             ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]],
    });
  }

  get email() {              return this.form.get('email'); }

  getInputType(key){
    if(key==="email") return "email";
    if(key==="phone") return "phone";
    return "text";
  }

  saveData(){
    let form = this.form.value;
    this.waiting = true;
    this.forgotPasswordService.recoveryPassword(form.email).subscribe(response=>{
      if(response === true){
        this.alertService.success('forgot_password.changed')
      }else if(response["404"]){
        this.alertService.error('errors.user.not_found');
      }else{
        this.alertService.error('errors.login.something_went_wrong');
      }
      this.waiting = false;
    },error=>{
      console.log(error)
      this.alertService.error('errors.login.something_went_wrong');
      this.waiting = false;
    })
  }

}