import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Section } from 'app/survey-public/models';

@Component({
  selector: 'mh-survey-public',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  @Input() survey:any;
  @Input() answerId: number;
  @Input() answers:any[];
  @Input() language:string;
  @Input() languageId:number;
  @Input() surveyLanguageId:number;
  @Input() answered:boolean;
  @Input() preview = false;
  @Input() page: number;
  @Input() answersComments;

  @Output() answer = new EventEmitter<any>();

  sections: Section[] = [];

  constructor() { }

  ngOnInit() {
    this.sections = this.survey.sections
      .filter((s:Section) => !this.page || this.page === s.page);

    this.sections.sort((a,b)=> {
        if(a.order > b.order) return 1;
        else if(a.order === b.order) {
          if(a.page > b.page) return 1;
          return -1;
        }
        return -1;
    });
  }
}
