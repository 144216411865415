<div class="action" (click)="openModal(setProduct)">
  <i class="icon_action {{action.icon}}"></i>
  <span class="show_action">{{title | translate }}</span>
</div>



<ng-template #setProduct let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="title">{{title | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="fade_waiting" [hidden]="!waiting">
      <mh-spinner-load></mh-spinner-load>
    </div>

    <div class="actions" [hidden]="waiting">
      <mh-edit-product-access *ngIf="action.label=='access'" [product]="product" [customer]="customer" [user]="user" (waitingState)="setWaiting($event)"></mh-edit-product-access>
      <mh-edit-product-alerts *ngIf="action.label=='alerts'" [product]="product" [customer]="customer" [user]="user" (waitingState)="setWaiting($event)"></mh-edit-product-alerts>
    </div>

  </div>
</ng-template>