export class Country{
  code:string;
  name:string;
  continent:string;
  iso:string;
  constructor()
  constructor(code,name,continent)
  constructor(code?,name?,continent?){
    this.code      = code      || 'xx';
    this.name      = name      || 'xx';
    this.continent = continent || 'xx';
    this.iso       = this.code.toLowerCase();
  }
}