<section id="summary">
  <mh-wait-chain *ngIf="waiting" [currentChain]="currentChain" [block]="callingInterchain" [total]="totalSizeChain" [totalBlock]="totalSizeBlock"></mh-wait-chain>
  <widget-filter-chain *ngIf="showFilter" [hidden]="waiting" [customers]="chain" (actives)="filterCustomers($event)" [filterProducts]="['online']"></widget-filter-chain>

  <ng-container>

    <div class="container_table" [class.waiting]="waiting">
      <div class="area_table" [ngStyle]="tableAreaStyle">
        <mat-table  class="table-mh sticky centered-header" #table [ngStyle]="tableContentStyle" [dataSource]="dataSource" matSortActive="general" matSortDisableClear matSortDirection="desc" matSort>
          <!-- Position Column -->
          <ng-container *ngFor="let key of headerTable" matColumnDef="{{key}}">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
            <div [ngSwitch]="key">
                {{"inter-chain.online.summary."+key | translate:{date:currentYear} }}
            </div>
          </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="{'fixed-cell' : key==='customer'}">

              <div class="cell_data">
                <div [ngSwitch]="key">
                  <ng-template [ngSwitchCase]="'customer'">
                    <customer-detail [customer]="element[key]" [setup]="customersDetails" *ngIf="!waiting"></customer-detail>
                  </ng-template>


                  <ng-template [ngSwitchCase]="(['general','accumulated'].includes(key) ? key : '')">
                    <mh-box-score
                      [value]="(element[key].actual | formatScore:2)"
                      [type]="cellClassCompare(element[key].actual, element[key].previous)"
                      [type_number]="'percent'"
                      [previous_value]="(getVariation(element,key) | formatPercent)"
                      [previous]="('inter-chain.summary.table.previous_value' | translate:({score: element[key].previous}))"
                    ></mh-box-score>
                  </ng-template>

                  <ng-template [ngSwitchCase]="'nps'">
                    <mh-box-score
                      [value]="npsFormat(element[key].actual)"
                      [type]="cellClassCompare(element[key].actual, element[key].previous)"
                      [type_number]="'nps'"
                      [previous_value]="(getVariation(element,key))"
                      [previous]="('inter-chain.summary.table.previous_value' | translate:({score: npsFormat(element[key].previous)}))"
                    ></mh-box-score>
                  </ng-template>


                  <ng-template [ngSwitchCase]="(['coverage_comments','promoter_comments','detractor_comments'].includes(key) ? key : '')">
                    <mh-box-score
                      [value]="(element[key] | formatCoverage:'actual')"
                      [type]="cellClassCompare(element[key].actual, element[key].previous)"
                      [type_number]="'percent'"
                      [previous_value]="(getVariation(element,key) | formatPercent)"
                      [previous]="('inter-chain.summary.table.previous_value' | translate:({score: (element[key] | formatCoverage:'previous') }))"
                    ></mh-box-score>
                  </ng-template>

                  <ng-template ngSwitchDefault>
                      <mh-box-score
                      [value]="(element[key].actual) ? (element[key].actual) : '-'"
                      [type]="cellClassCompare(element[key].actual, element[key].previous)"
                      [type_number]="'percent'"
                      [previous_value]="(getVariation(element,key) | formatPercent)"
                      [previous]="('inter-chain.summary.table.previous_value' | translate:({score: element[key].previous}))"
                    ></mh-box-score>
                  </ng-template>

                </div>
              </div>

            </mat-cell>
            <mat-footer-cell [ngClass]="{'fixed-cell' : key==='customer'}" *matFooterCellDef>
              <div class="full-width" [ngSwitch]="key">
                <ng-template [ngSwitchCase]="'customer'">
                  <b>{{'commons.total' | translate }}</b>
                </ng-template>

                <ng-template [ngSwitchCase]="(['tripAdvisorRanking'].includes(key) ? key : false )">
                  -
                </ng-template>

                <ng-template ngSwitchDefault>
                  <mh-footer-total-label
                    [actualValue]="getTotal(key).actual"
                    [previousValue]="getTotal(key).previous"
                    [numberType]="(['coverage_comments','promoter_comments','detractor_comments'].includes(key) ? 'percentage' : key)"
                  ></mh-footer-total-label>
                </ng-template>
              </div>
            </mat-footer-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="headerTable"></mat-header-row>
          <mat-row *matRowDef="let row; columns: headerTable;"></mat-row>
          <mat-footer-row *matFooterRowDef="headerTable"></mat-footer-row>
        </mat-table>
      </div>
    </div>

  </ng-container>

</section>
