import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Language } from 'app/shared/model';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  constructor(private http: HttpClient) { }

  getCommentsForCase(caseId: number): Observable<Object> {
    const url = `${environment.casesPath}${caseId}/comments`

    return this.http.get(url);
  }

  getLogsForCase(caseId: number, languageId: number | Language): Observable<Object> {
    const url = `${environment.casesPath}${caseId}/audits`;
    const params: HttpParams = new HttpParams().set('language_id', languageId.toString());
    return this.http.get(url, { params });
  }

  saveComment(comment: any) {
    if (comment.id)
      return this.updateComment(comment);

    return this.createComment(comment);
  }

  updateComment(comment: any) {
    const url = `${environment.casesPath}${comment.case_id}/comments/${comment.id}`

    return this.http.put(url, comment);
  }

  createComment(comment: any) {
    const url = `${environment.casesPath}${comment.case_id}/comments`

    return this.http.post(url, comment);
  }
}

