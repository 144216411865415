import { Guest } from './guest.model';
import { Satisfaction } from './satisfaction.model';
import { Utils } from './utils';

export class Question extends Utils{

  id:number;
  order:number;
  statement :string;
  alternatives:Alternative[] = [];
  sub_questions:SubQuestion[] | any = [];
  has_sub_question:boolean;
  is_conditional:boolean=false;
  type=0;
  sort=0;
  class="question";

  constructor(id,order,statement,sort=0){
    super();
    this.id        = id;
    this.order     = order;
    this.statement = this.formatText(statement);
    this.sort      = sort;
    this.is_conditional = false;
  }

  createFromApi(data,answers,question=undefined){

    let memory: any  = question ? question : this;

    for(let item of data.alternatives){
      memory.has_sub_question   = item.alternatives.length>0
      memory.type               = item.alternative_type_id;
      if(!memory.is_conditional) memory.is_conditional = item.conditional || (item.alternatives.find(x=>x.conditional) !== undefined);
      if(memory.has_sub_question){
        let new_question   = new SubQuestion(item.id,item.order,item.text);
        memory.sub_questions.push(new_question);
        memory.createFromApi(item,answers,new_question)
      }else{
        let alternative            = new Alternative();
        alternative.createFromApi(item,answers);
        memory.alternatives.push(alternative);
      }
    }
    return this;
  }

  createQuestion(data, parent=undefined){

  }

  get hasSubQuestions(){
    return this.has_sub_question;
  }


}

export class SubQuestion extends Question{
  constructor(id,order,statement){
    super(id,order,statement,0);
    this.class="subquestion";
  }
}

export class Alternative extends Utils{
  id:number;
  order:number;
  statement:string;
  note:number;
  alternative_type: any;
  alternative_type_id:string;
  conditional:boolean;
  conditional_questions:Question[] = []
  answers: AnswerQuestion[] = [];
  sub_alternatives: any;

  createFromApi(data,answers=[]){
    this.id                    = data.id;
    this.order                 = data.order;
    this.statement             = this.formatText(data.text);
    this.note                  = data.note
    this.alternative_type      = data.alternative_type
    this.alternative_type_id   = data.alternative_type_id
    this.conditional           = data.conditional
    this.conditional_questions = []

    for(let conditional of data.conditional_questions){
      let question = new Question(conditional.id, conditional.order, conditional.statement);
      question.createFromApi(conditional,answers);
      this.conditional_questions.push(question);
    }

    if(answers){
      if(Object.keys(answers).length>0 && data.guest_ids.length > 0){
        for(let guest_id of data.guest_ids){
          let answer = new AnswerQuestion();
          answer.createFromApi(guest_id,answers);
          this.answers.push(answer);
        }
      }
    }

    return this;
  }

}

export class AnswerQuestion{
  id:number;
  survey_id:number;
  guest:Guest;
  date:Date;
  open_date:Date;
  sent_date:Date;
  satisfaction:Satisfaction= new Satisfaction();
  answered_survey;
  constructor(){}

  createFromApi(guest_id, data){
    let answer = data[guest_id];
    if(answer){
      this.id = answer.answered_survey.id;
      this.survey_id                = answer.survey_id;
      this.date                     = new Date(answer.answered_survey.date);
      this.open_date                = new Date(answer.open_date);
      this.sent_date                = new Date(answer.sent_date);
      this.satisfaction.id          = answer.answered_survey.satisfaction.id;
      this.satisfaction.score       = answer.answered_survey.satisfaction.score;
      this.satisfaction.description = answer.answered_survey.satisfaction.description;
      this.answered_survey          = answer.answered_survey;
      let guest                     = new Guest();
      guest.createFromApi(answer.guest)
      this.guest = guest;
    }
    return this;
  }
}
