import { Period } from 'app/shared/model/index';

function calculateWeightedSum(area:any, key:string){
  return (area.rating[key] * area.answers[key]) || null;
}

function getAverageAreas(areas:any[]){
  const weightedSum: any   = new Period();
  const qtyAnswer: any     = new Period();

  areas.forEach(area=>{
    weightedSum.addInKey('actual', calculateWeightedSum(area,'actual'));
    weightedSum.addInKey('previous', calculateWeightedSum(area,'previous'));
    qtyAnswer.addInKey('actual',(area.answers.actual || null));
    qtyAnswer.addInKey('previous',(area.answers.previous || null));
  })

  weightedSum.actual   = weightedSum.actual/qtyAnswer.actual;
  weightedSum.previous = weightedSum.previous/qtyAnswer.previous;

  weightedSum.calculateVaration();
  qtyAnswer.calculateVaration();

  areas.push({
    area_id: -1,
    rating: weightedSum,
    answers: qtyAnswer
  });
}


export {
  calculateWeightedSum,
  getAverageAreas,
}
