import { Component, OnInit } from '@angular/core';
import { User } from 'app/shared/model';
import { UtilService } from 'app/shared/util.service';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

declare var hbspt: any

@Component({
  selector: 'mh-hubspot-support',
  templateUrl: './hubspot-support.component.html',
  styleUrls: ['./hubspot-support.component.scss']
})
export class HubspotSupportComponent implements OnInit {

  public showResetButton: boolean = false;
  public currentLang: string;
  public sub: Subscription;
  public labelsTranslations: any;
  public LANG_FORM_ID = {
    'es': environment.hubspotFormIdEs,
    'en': environment.hubspotFormIdEn,
    'pt': environment.hubspotFormIdPt
  };

  constructor(private utilService: UtilService, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.sub = this.utilService.isLanguageChanged().subscribe(response=>{
      this.currentLang = response;
      this.createHubspotForm(this.user, this.currentLang).then(() => this.showResetButton = true);
    });
    this.createHubspotForm(this.user, this.language).then(() => this.showResetButton = true);
  }

  createHubspotForm(user: User, language: string) {
    const translateService = this.translate;
    const langFormIdRef = this.LANG_FORM_ID;

    return new Promise(function (resolve, reject) {
      translateService.get([
        'support.send_form'
      ]).subscribe(res => {
        const sendTranslation = res['support.send_form'];
        hbspt.forms.create({
          region: environment.hubspotRegion,
          portalId: environment.hubspotPortalId,
          formId: langFormIdRef[language],
          target: "#hubspotForm",
          locale: language === 'pt' ? 'pt-br' : language,
          translations: {
            en: {
              submitText: sendTranslation
            },
            pt: {
              submitText: sendTranslation
            }
          },
          onFormReady($form){
            let emailInput;
            [...$form[0].elements].forEach(element => {
              if(element.name === 'email') emailInput = element;
            });
            emailInput.value = user.email;
            emailInput.dispatchEvent(new Event('input', { bubbles: true }));
          },
          onFormSubmitted() {
            resolve(true);
          }
        });
      })
    });
  }

  resetForm() {
    this.showResetButton = false;
    this.createHubspotForm(this.user, this.language);
  }

  get user() {
    return this.utilService.getCurrentUser();
  }
  get language() {
    return this.currentLang ? this.currentLang : this.utilService.getCurrentLanguage();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }
  
}
