import { Component, Inject, OnInit } from '@angular/core';
import { CoreLibModalComponent } from '../modal/modal.component';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';

@Component({
  selector: 'mh-core-lib-email-rate-modal',
  templateUrl: './email-rate-modal.component.html',
  styleUrls: ['./email-rate-modal.component.scss'],
})
export class CoreLibEmailRateModalComponent extends CoreLibModalComponent {
  constructor(protected dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: any) {
    super(dialogRef, data);
  }

  get status() {
    return this.data.classes.state.replace('_', '-');
  }

  get type() {
    return this.data.type;
  }

  get tooltipTranslate() {
    return this.data.tooltipTranslate;
  }

  get currentDateFilter() {
    return this.data.currentFilter.label;
  }

  get validEmailPercentage() {
    return this.data.validEmailPercentage;
  }

  get guestsWithValidEmail() {
    return this.data.guestsWithValidEmail;
  }

  get totalGuests() {
    return this.data.totalGuests;
  }

  get emailsCount() {
    return this.data.emailsCount;
  }
}
