<ng-container *ngIf="isCreate else itemDetail">
  <ng-container *ngTemplateOutlet="itemCreate">
  </ng-container>
</ng-container>


<ng-template #itemCreate>
  <div class="tw-relative">
    <article class="tw-px-6 tw-py-4 tw-flex tw-flex-col tw-text-main-text">

      <section class="tw-self-center tw-flex tw-flex-col tw-items-center tw-gap-0.5 tw-mb-2">
        <span class="tw-font-medium">{{survey.guest.name}} {{survey?.guest?.lastname || survey?.guest?.lastName}}</span>
        <span class="tw-font-medium">{{survey.guest.email}}</span>
        <span class="tw-font-medium">{{survey.guest.phone}}</span>
        <span class="tw-flex tw-items-center tw-gap-3">
          <span [ngStyle]="{'width': 20+'px', 'height': 20+'px'}" class="flag-icon flag-icon-squared flag-icon-{{survey.guest.country.code.toLowerCase()}} tw-rounded-full"></span>
          <span class="tw-text-sm tw-font-medium tw-self-center">{{survey.guest.country.name}}</span>
        </span>
      </section>

      <section class="tw-flex tw-flex-col tw-gap-1">
        <div class="tw-flex">
          <span class="tw-basis-3/5 tw-font-medium"><i class="fas fa-portrait tw-mr-3"></i> {{'commons.fly_code' | translate}}</span>
          <span class="tw-basis-2/5 tw-font-bold">{{survey.guest.reservation_id}}</span>
        </div>
        <div class="tw-flex tw-justify-between">
          <span class="tw-basis-3/5 tw-font-medium"><i class="far fa-calendar-alt tw-mr-3"></i> {{'core-lib.widgets.guest.checkin' | translate}}</span>
          <span class="tw-basis-2/5 tw-font-bold">{{survey.guest.checkin | formatDate: 'es'}}</span>
        </div>
        <div class="tw-flex tw-justify-between">
          <span class="tw-basis-3/5 tw-font-medium"><i class="fas fa-calendar-alt tw-mr-3"></i> {{'core-lib.widgets.guest.checkout' | translate}}</span>
          <span class="tw-basis-2/5 tw-font-bold">{{survey.guest.checkout | formatDate: 'es'}}</span>
        </div>
        <div class="tw-flex tw-justify-between">
          <span class="tw-basis-3/5 tw-font-medium"><i class="fas fa-suitcase-rolling tw-mr-3"></i> {{'commons.stay_nights.stay' | translate}}</span>
          <span class="tw-basis-2/5 tw-font-bold">
            <ng-container *ngIf="stay <= 1 ">
              {{'commons.stay_nights.single' | translate}}
            </ng-container>
            <ng-container *ngIf="stay > 1">
              {{'commons.stay_nights.plural' | translate: {number: stay} }}
            </ng-container>
          </span>
        </div>
        <div class="tw-flex tw-justify-between">
          <span class="tw-basis-3/5 tw-font-medium"><i class="fas fa-bed tw-mr-3 tw-w-3"></i> {{'commons.room' | translate}}</span>
          <span class="tw-basis-2/5 tw-font-bold">{{survey.guest.room}}</span>
        </div>
      </section>

    </article>

    <article class="nps-section {{survey?.nps?.nps || survey?.nps}} tw-py-2 xl:tw-py-0.5 2xl:tw-py-2 tw-text-center tw-font-semibold tw-text-white">
      <span class="tw-font-semibold tw-self-center">{{survey?.nps?.nps || survey?.nps | titlecase}}</span>
    </article>

    <article class="tw-px-6 tw-py-4 tw-flex tw-flex-col tw-text-main-text">

      <section class="tw-flex tw-flex-col tw-mt-5 tw-mb-2">
        <article class="tw-flex tw-justify-between">
          <span class="tw-basis-3/5 tw-font-medium"><i class="fas fa-check"></i> {{'shared.survey.rate' | translate}}</span>
          <div class="tw-basis-2/5 tw-flex tw-gap-3 tw-items-center">
            <span class="tw-font-bold tw-text-2xl tw-text-main">{{survey.satisfaction.score}}</span>
            <span class="tw-font-bold">|</span>
            <span class="tw-font-bold">5.0</span>
          </div>
        </article>
      </section>
      <section class="tw-flex tw-flex-col tw-gap-2">
        <div class="tw-flex">
          <span class="tw-basis-3/5 tw-font-medium"><i class="fas fa-file-alt tw-mr-1"></i> {{'followup.survey.filter.survey' | translate}}</span>
          <span class="tw-basis-2/5 tw-font-bold">{{survey.survey_name | translateObject: 'title'}}</span>
        </div>
        <div class="tw-flex tw-justify-between">
          <span class="tw-basis-3/5 tw-font-medium"><i class="fas fa-dot-circle tw-mr-1"></i> {{'core-lib.selectors.guest.filters_title.origin' | translate}}</span>
          <span class="tw-basis-2/5 tw-font-bold">{{survey.origin_name}}</span>
        </div>
        <div class="tw-flex tw-justify-between">
          <span class="tw-basis-3/5 tw-font-medium"><i class="fas fa-calendar-check tw-mr-1"></i> {{'commons.date_answer' | translate}}</span>
          <span class="tw-basis-2/5 tw-font-bold">{{survey.answer.date | formatDate: 'es'}}</span>
        </div>
      </section>
    </article>
  </div>
</ng-template>

<ng-template #itemDetail>
  <div class="tw-hidden md:tw-block">
    <ng-container *ngTemplateOutlet="desktopDetail"></ng-container>
  </div>
  <div class="md:tw-hidden">
    <ng-container *ngTemplateOutlet="mobileDetail"></ng-container>
  </div>
</ng-template>

<ng-template #mobileDetail>
  <div class="survey-info-section tw-py-3 tw-text-center tw-bg-background-gray tw-font-bold tw-text-main-text">
    {{'commons.info_survey' | translate}}
  </div>
  <div class="tw-pt-16 tw-pb-6 tw-flex tw-flex-col tw-text-main-text tw-gap-1">
    <div class="tw-flex tw-flex-col tw-items-center">
      <span class="tw-font-semibold tw-text-sm">{{survey.guest.name}} {{survey.guest.last_name}}</span>
      <span class="tw-text-sm">{{survey.guest.email}}</span>
      <div class="tw-flex tw-gap-5">
        <span class="tw-text-sm">Tel: {{survey.guest.phone_number || '-'}}</span>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-[4fr_2fr]">
      <div class="tw-flex tw-gap-1">
        <i class="fas fa-portrait"></i>
        <span class="tw-text-sm">{{'commons.fly_code' | translate}}</span>
      </div>
      <span class="tw-font-semibold">{{survey.guest.reservation_id}}</span>
    </div>
    <div class="tw-grid tw-grid-cols-[4fr_2fr]">
      <div class="tw-flex tw-gap-1">
        <i class="fas fa-calendar"></i>
        <span class="tw-text-sm">{{'commons.checkin' | translate}}</span>
      </div>
      <span class="tw-font-semibold">{{survey.guest.check_in_date | formatDate}}</span>
    </div>
    <div class="tw-grid tw-grid-cols-[4fr_2fr]">
      <div class="tw-flex tw-gap-1">
        <i class="fas fa-calendar-alt"></i>
        <span class="tw-text-sm">{{'commons.checkout' | translate}}</span>
      </div>
      <span class="tw-font-semibold">{{survey.guest.check_out_date | formatDate}}</span>
    </div>
    <div class="tw-grid tw-grid-cols-[4fr_2fr]">
      <div class="tw-flex tw-gap-1">
        <i class="fas fa-bed tw-text-sm"></i>
        <span class="tw-text-sm">{{'commons.room' | translate}}</span>
      </div>
      <span class="tw-font-semibold">{{survey.guest.room}}</span>
    </div>
  </div>
  <div class="nps-title-section nps-section {{survey?.answered_survey?.nps?.nps || survey?.answered_survey?.nps}} tw-text-center tw-font-bold tw-py-1.5 tw-text-white">
    {{'qualifys.'+(survey?.answered_survey?.nps?.nps || survey?.answered_survey?.nps) | translate | titlecase}}
  </div>

  <div class="tw-mt-14 tw-flex tw-flex-col tw-text-main-text tw-gap-1">
    <div class="tw-grid tw-grid-cols-[4fr_2fr]">
      <div class="tw-flex tw-gap-1">
        <i class="fas fa-check tw-self-center"></i>
        <span class="tw-text-sm tw-self-center">{{'shared.survey.rate' | translate}}</span>
      </div>
      <div class="tw-flex tw-gap-2">
        <span class="tw-text-main tw-text-3xl tw-font-bold">{{survey.answered_survey.satisfaction.score}}</span>
        <div class="tw-border-r-2 tw-h-[3ch] tw-border-main-text tw-self-center"></div>
        <span class="tw-font-medium tw-self-end tw-text-lg">5.0</span>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-[4fr_2fr]">
      <div class="tw-flex tw-gap-1">
        <i class="fas fa-calendar-check"></i>
        <span class="tw-text-sm">{{'commons.response_date' | translate}}</span>
      </div>
      <span class="tw-font-semibold">{{survey.answered_survey.date | formatDate}}</span>
    </div>
  </div>
</ng-template>

<ng-template #desktopDetail>
  <div class="tw-px-5 tw-py-6 tw-flex tw-gap-5">
    <!-- info huesped -->
    <section class="tw-min-w-[200px] tw-flex tw-flex-col tw-text-main-text">
      <span class="tw-font-semibold tw-text-sm">{{survey.guest.name}} {{survey.guest.last_name}}</span>
      <span>{{survey.guest.email}}</span>
      <span>{{'forms.phone' | translate}}: {{survey.guest.phone_number}}</span>
      <span>{{'commons.survey_code' | translate}}: <span class="tw-font-semibold">{{survey.guest.reservation_id}}</span></span>
      <a href="{{url}}" target="_blank" class="tw-text-main tw-font-semibold">{{'followup.summary.comments.see_survey' | translate}}</a>
    </section>

    <!-- info encuesta -->
    <section class="tw-flex tw-gap-5 tw-text-main-text tw-items-center">
      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-calendar"></i>
        <span class="tw-text-sm tw-text-center">{{'commons.checkin' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm tw-text-center">{{survey.guest.check_in_date | formatDate}}</span>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-calendar-alt"></i>
        <span class="tw-text-sm tw-text-center">{{'commons.checkout' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm tw-text-center">{{survey.guest.check_out_date | formatDate}}</span>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-bed"></i>
        <span class="tw-text-sm tw-text-center">{{'commons.room' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm tw-text-center">{{survey.guest.room}}</span>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-calendar-check"></i>
        <span class="tw-text-sm tw-text-center">{{'commons.response_date' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm tw-text-center">{{survey.answered_survey.date | formatDate}}</span>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-2 tw-items-center">
        <mh-core-lib-nps-face
            [size]="'tw-text-3xl'"
            [nps]="survey.answered_survey.nps"
          ></mh-core-lib-nps-face>
        <span class="tw-font-semibold">{{'qualifys.'+survey.answered_survey.nps | translate | titlecase}}</span>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-2 tw-items-center">
        <i class="fas fa-check tw-text-xl"></i>
        <div class="tw-flex tw-gap-2 tw-items-baseline">
          <span class="tw-text-main tw-text-xl tw-font-medium">{{survey.answered_survey.satisfaction.score}}</span>
          <span>|</span>
          <span class="tw-font-medium tw-text-sm">5.0</span>
        </div>
      </div>
    </section>
  </div>

</ng-template>
