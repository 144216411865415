<div class="box_image">
  <div class="thumbnail" [class.uploading]="uploading">
    <div class="fade_uploading">
      <div class="row align-items-center">
        <div class="col-12">
          <mh-spinner-classic ></mh-spinner-classic>
        </div>
      </div>
    </div>
    <img [src]="image" [ngStyle]="inputStyle" class="responsive" alt="image cropped" *ngIf="image">
  </div>
  <div class="input-group btn_change_image" *ngIf="!disabled" >
    <div class="custom-file upload-button">
      <ng-container *ngIf="textButton==='change'">
        <input type="file" class="custom-file-input"  #fileInput (click)="fileInput.value = null" (change)="fileChangeEvent($event)" />
        <label class="custom-file-label" >{{'forms.change_image' | translate }}</label>
      </ng-container>
      <ng-container *ngIf="textButton==='attached'">
        <input type="file" class="custom-file-input"  #fileInput accept="image/*" (change)="loadImageNoCropp(fileInput.files)" />
        <label class="custom-file-label" >{{'forms.upload_image' | translate }}</label>
      </ng-container>
    </div>
  </div>
</div>


<ng-template #setImageSize let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{'prestay.configurations.products.chain.modal_title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="cut_image" >
      <div class="content_cropper">

        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="ratio"
          [resizeToWidth]="600"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
      </div>
      <div class="content_buttons">
        <div class="row no-gutters align-items-center">
          <div class="col-6">
            <button type="button" class="btn btn-orange" (click)="cancelEditImage()">{{'forms.cancel' | translate}}</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange" (click)="acceptEditImage()">{{'forms.accept' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
