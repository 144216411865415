import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';

import { SemanticSurveysFilterPipe } from './semantic/semantic-surveys-filter.pipe';
import { SemanticProductsFilterPipe } from './semantic/semantic-products-filter.pipe';
import { SemanticNotationFilterPipe } from './semantic/semantic-notation-filter.pipe';
import { SemanticThemeFilterPipe } from './semantic/semantic-theme-filter.pipe';
import { SemanticAreaFilterPipe } from './semantic/semantic-area-filter.pipe';
import { SemanticMatchFilterPipe } from './semantic/semantic-match.filter.pipe';

import { FollowupOnsiteNPSFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-nps-filter.pipe';
import { FollowupOnsiteFilterSelectPipe } from 'app/pipes/followup-onsite/followup-onsite-filter-select.pipe';
import { FollowupGuestFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-guest-filter.pipe';
import { FollowupOnsiteCountriesFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-countries-filter.pipe';
import { FollowupOnsiteRoomsFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-rooms-filter.pipe';
import { FollowupOnsiteLanguagesFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-languages-filter.pipe';
import { FollowupOnsiteSurveysFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-surveys-filter.pipe';
import { FollowupOnsiteOriginSurveyFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-origin-survey-filter.pipe';
import { FollowupOnsiteOriginNameFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-origin-name-filter.pipe';
import { FollowupOnsiteChannelFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-channel-filter.pipe';
import { FollowupOnsiteCasesFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-cases-filter.pipe';
import { FollowupOnsiteContactedFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-contacted-filter.pipe';

import { FidelityDeskStatusFilter } from './fidelity-desk/fidelity-desk-status.pipe';
import { FidelityDeskPriorityPipe } from './fidelity-desk/fidelity-desk-priority.pipe';
import { FidelityDeskAuthorPipe } from './fidelity-desk/fidelity-desk-author.pipe';
import { FidelityDeskAssignedPipe } from './fidelity-desk/fidelity-desk-assigned.pipe';
import { FidelityDeskProductPipe } from './fidelity-desk/fidelity-desk-product.pipe';
import { FidelityDeskTypePipe } from './fidelity-desk/fidelity-desk-type.pipe';
import { FidelityDeskAreasPipe } from './fidelity-desk/fidelity-desk-areas.pipe';
import { FidelityDeskStagePipe } from './fidelity-desk/fidelity-desk-stage.pipe';
import { FidelityDeskSeenPipe } from './fidelity-desk/fidelity-desk-seen.pipe';
import { FidelityDeskTeamPipe } from './fidelity-desk/fidelity-desk-team.pipe';
import { OnlineReviewsOtaFilterPipe } from './online-reviews/online-reviews-ota-filter.pipe';
import { OnlineReviewsAnswerFilterPipe } from './online-reviews/online-reviews-answer-filter.pipe';
import { OnlineReviewsNpsFilterPipe } from './online-reviews/online-reviews-nps-filter.pipe';
import { OnlineReviewsQualificationFilterPipe } from './online-reviews/online-reviews-qualification-filter.pipe';
import { OnlineReviewsReasonFilterPipe } from './online-reviews/online-reviews-reason-filter.pipe';
import { OnlineReviewsLanguageFilterPipe } from './online-reviews/online-reviews-language-filter.pipe';

const semanticPipes = [
  SemanticSurveysFilterPipe,
  SemanticProductsFilterPipe,
  SemanticNotationFilterPipe,
  SemanticThemeFilterPipe,
  SemanticAreaFilterPipe,
  SemanticMatchFilterPipe,
];

const followupOnsitePipes = [
  FollowupOnsiteNPSFilterPipe,
  FollowupOnsiteFilterSelectPipe,
  FollowupGuestFilterPipe,
  FollowupOnsiteCountriesFilterPipe,
  FollowupOnsiteRoomsFilterPipe,
  FollowupOnsiteLanguagesFilterPipe,
  FollowupOnsiteSurveysFilterPipe,
  FollowupOnsiteOriginSurveyFilterPipe,
  FollowupOnsiteOriginNameFilterPipe,
  FollowupOnsiteChannelFilterPipe,
  FollowupOnsiteCasesFilterPipe,
  FollowupOnsiteContactedFilterPipe,
];

const onlineReviewsPipes = [
  OnlineReviewsOtaFilterPipe,
  OnlineReviewsAnswerFilterPipe,
  OnlineReviewsNpsFilterPipe,
  OnlineReviewsQualificationFilterPipe,
  OnlineReviewsReasonFilterPipe,
  OnlineReviewsLanguageFilterPipe,
];

const fidelityDeskPipes = [
  FidelityDeskStatusFilter,
  FidelityDeskPriorityPipe,
  FidelityDeskAuthorPipe,
  FidelityDeskAssignedPipe,
  FidelityDeskProductPipe,
  FidelityDeskTypePipe,
  FidelityDeskAreasPipe,
  FidelityDeskStagePipe,
  FidelityDeskSeenPipe,
  FidelityDeskTeamPipe,
];

@NgModule({
  declarations: [semanticPipes, followupOnsitePipes, fidelityDeskPipes, onlineReviewsPipes],
  imports: [CommonModule],
  exports: [semanticPipes, followupOnsitePipes],
  providers: [TitleCasePipe],
})
export class PipesModule {}
