import { HttpEventType } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FilesService } from 'app/shared/services/files.service';

@Component({
  selector: 'mh-core-lib-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class CoreLibFileUploaderComponent implements AfterViewInit, OnChanges {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @Input() customerId: number;
  @Input() completedActions = false;
  @Input() style: 'input' | 'chips' = 'input';
  @Output() filesUploaded: EventEmitter<{ file_key: string; file_name: string }[]> = new EventEmitter();
  @Output() uploadingFilesProgress: EventEmitter<boolean> = new EventEmitter();
  @Output() emitFileInput: EventEmitter<HTMLInputElement> = new EventEmitter();

  fileTypesAccepted = [
    'image/*',
    'video/*',
    'application/pdf',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ];
  uploadingFiles = false;
  files: File[] = [];
  maxFileSize = 10;
  maxFileSizeError = false;
  maxNumberOfFiles = 4;
  maxNumberOfFilesError = false;

  constructor(private fileService: FilesService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.completedActions && changes.completedActions.currentValue) {
      this.files = [];
    }
  }

  ngAfterViewInit(): void {
    // Emit the file input element to use it in the parent component
    this.emitFileInput.emit(this.fileInput.nativeElement);
  }

  handleFileInputClick(fileInput: HTMLInputElement) {
    fileInput.value = '';
    fileInput.click();
  }

  onFileInput(event) {
    this.uploadingFiles = true;
    this.maxFileSizeError = false;
    this.maxNumberOfFilesError = false;
    this.uploadingFilesProgress.emit(true);
    this.files = [...event.target.files, ...this.files];
    const filesToUpload = [...event.target.files];
    const filesToSubmit = [];

    if (filesToUpload.length > this.maxNumberOfFiles) {
      this.handleError('maxNumberOfFilesError');
      return false;
    }

    if (this.files.length > this.maxNumberOfFiles) {
      this.maxNumberOfFilesError = true;
      this.uploadingFiles = false;
      this.files.shift();
      return false;
    }

    filesToUpload.every((file: File) => {
      let fileData;
      const size = file.size;
      const mbSize = parseInt((size / (1024 * 1024)).toFixed(2));
      if (mbSize > this.maxFileSize) {
        this.handleError('maxFileSizeError');
        return false;
      }

      const data: FormData = new FormData();
      data.append('attachment', file);
      this.fileService.uploadPrivateWithEvents(this.customerId, data).subscribe((res) => {
        // if (res.type === HttpEventType.UploadProgress) {} // It can be used to show progress
        if (res.type === HttpEventType.Response) {
          fileData = {
            file_key: res.body.name,
            file_name: file.name,
          };
          filesToSubmit.push(fileData);
          this.uploadingFiles = filesToSubmit.length !== filesToUpload.length;
          this.uploadingFilesProgress.emit(this.uploadingFiles);
          if (!this.uploadingFiles) this.filesUploaded.emit(filesToSubmit);
        }
      });
      return true; // return true to continue the loop
    });
  }

  handleError = (error: string) => {
    this[error] = true;
    this.uploadingFiles = false;
    this.files = [];
  };
}
