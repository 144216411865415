<div class="edit_summaries" *ngIf="!waiting">

  <h5 class="edit_title" [innerHtml]="(modalTitle | translate:{user:user} )"></h5>
  <ul id="access_edit" class="list_switch">
    <li>
      <mat-slide-toggle
        [id]="summary.id"
        (change)="setSummary($event)"
        [checked]="summary.state">
        {{('configuration.users.modal.summaries.active_summary' | translate:{customer:customer})}}
      </mat-slide-toggle>
    </li>
  </ul>

  <div class="bottom">
    <button type="submit" (click)="saveChanges()" class="btn btn-orange">{{'forms.save_changes' | translate}}</button>
  </div>
</div>
