<article class="tw-rounded-lg tw-shadow-1 tw-p-6 tw-gap-3 tw-mb-2.5 tw-flex tw-flex-col md:tw-flex-row">
  <section class="tw-flex tw-flex-col tw-text-main-text md:tw-min-w-[200px] md:tw-max-w-[200px] lg:tw-min-w-[300px] lg:tw-max-w-[300px]">
    <span class="tw-font-semibold tw-text-sm tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis">{{guest.name}} {{guest.lastName}}</span>
    <a class="tw-text-xs tw-font-medium tw-hidden md:tw-block tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-text-main" href="mailto:{{guest.email}}" target="_blank">
    {{guest.email | lowercase}}
    </a>
    <p class="tw-text-xs tw-font-medium tw-hidden md:tw-block ">
    {{'forms.phone' | translate}}: <a class="tw-font-semibold tw-text-sm tw-text-main" [href]="'https://api.whatsapp.com/send?phone='+ guest.phone_number +'&text=' + ('commons.guest_greetings' | translate)" tarjet="_blank">{{guest.phone_number}}</a>
    </p>
    <p class="tw-text-xs tw-font-medium tw-hidden md:tw-block">
    {{'commons.fly_code' | translate}}: <span class="tw-font-semibold tw-text-sm">{{guest.reservation_id}}</span>
    </p>
    <p class="tw-text-xs tw-font-medium">
    {{'commons.date' | translate}}: <span class="tw-font-semibold tw-text-sm">{{commentDate | formatDate: currentLanguage}}</span>
    </p>
    <section class="flag tw-gap-1 tw-mt-1 tw-hidden md:tw-flex" *ngIf="areCountry">
      <span [ngStyle]="{'width': 25+'px', 'height': 25+'px'}" class="flag-icon flag-icon-squared flag-icon-{{countryFlag}} tw-rounded-full"></span>
      <span class="tw-text-sm tw-font-medium tw-self-center">{{countryName}}</span>
    </section>
  </section>
  <section class="tw-flex-auto">
    <p *ngFor="let comment of comments" class="tw-text-main-text tw-text-sm tw-max-w-7xl tw-py-2">
    {{comment.text}}
    </p>
  </section>
  <section class="tw-self-center md:tw-self-start">
    <mh-core-lib-button
      [isSecondary]="true"
      [text]="('shared.semantic.categories.see_survey' | translate)"
      (clicked)="handleClick()"
      >
    </mh-core-lib-button>
  </section>
</article>
