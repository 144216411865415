export class GuestPS{
  checkin: Date;
  checkout: Date;
  country_code: string;
  creation: Date;
  customer_id: number;
  email: string;
  flag_email_valido: true
  id: number;
  lastname: string;
  name: string;
  reservation_id: string;
  resv_status: number;
  room: string;
  display_name : string;
  constructor(){

  }

  createFromApi(data){
    this.checkin              = new Date(data.checkin);
    this.checkout             = new Date(data.checkout);
    this.country_code         = data.country_code;
    this.creation             = new Date(data.creation);
    this.customer_id          = data.customer_id;
    this.email                = data.email;
    this.flag_email_valido    = data.flag_email_valido;
    this.id                   = data.id;
    this.lastname             = data.lastname || '';
    this.name                 = data.name || '';
    this.reservation_id       = data.reservation_id;
    this.resv_status          = data.resv_status;
    this.room                 = data.room;
    this.display_name         = `${this.name} ${this.lastname}`.toLocaleLowerCase();
    return this;
  }


}

