<div class="container_table">
  <div class="area_table" [ngStyle]="tableAreaStyle">
    <mat-table  class="sticky table-mh centered-header centered-content"
      matSortDisableClear matSortDirection="desc" matSort #table
      [matSortActive]="matSortActive"
      [ngStyle]="tableContentStyle"
      [dataSource]="dataSource"
      [matSortActive]="matSortActive" >

      <ng-container *ngFor="let key of headerTable" [matColumnDef]="key">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <div [ngSwitch]="key">
            {{'inter-chain.executive.table.'+(key==='factor' ? factor : key) | translate:translateData }}
          </div>
        </mat-header-cell>

        <mat-cell *matCellDef="let element" [ngClass]="{'fixed-cell' : key==='customer'}">
          <div [ngSwitch]="key">
            <ng-template [ngSwitchCase]="(['variation','annual_variation'].includes(key) ? key : false )">
              <div class="inline variation">{{element[key] | formatBy:(selected === 'nps' ? 'nps' : 'percent')}}</div>
              <div class="inline content-arrow">
                <widget-utils-arrow-variation [number]="element[key]" ></widget-utils-arrow-variation>
              </div>
            </ng-template>

            <ng-template [ngSwitchCase]="(['properties_quantity'].includes(key) ? key : false )">
              {{element[key]}}
            </ng-template>

            <ng-template ngSwitchDefault>
              {{['factor'].includes(key) ? element[key]: selected === 'iro' ? (element[key] | formatBy: 'percent') : (element[key] | formatBy:formatNumber) }}
            </ng-template>
          </div>
        </mat-cell>


        <ng-container *ngIf="showTotals">
          <mat-footer-cell [ngClass]="{'fixed-cell' : key==='customer'}" *matFooterCellDef>
            <div class="full-width" [ngSwitch]="key">
              <ng-template [ngSwitchCase]="['factor'].includes(key) ? key : false">
                <b>{{'commons.total' | translate }}</b>
              </ng-template>

              <ng-template [ngSwitchCase]="'tripAdvisorRanking'">
                -
              </ng-template>

              <ng-template [ngSwitchCase]="(['properties_quantity'].includes(key) ? key : false )">
                {{totals[key]}}
              </ng-template>

              <ng-template [ngSwitchCase]="(['variation','annual_variation'].includes(key) ? key : false )">
                <div class="inline variation">{{totals[key] | formatBy:(selected === 'nps' ? 'nps' : 'percent')}}</div>
                <div class="inline content-arrow">
                  <widget-utils-arrow-variation [number]="totals[key]" ></widget-utils-arrow-variation>
                </div>
              </ng-template>

              <ng-template ngSwitchDefault>
                {{selected === 'iro' ? (totals[key] | formatBy: 'percent'): totals[key] | formatBy:formatNumber}}
              </ng-template>
            </div>
          </mat-footer-cell>
        </ng-container>

      </ng-container>



      <mat-header-row *matHeaderRowDef="headerTable"></mat-header-row>
      <mat-row *matRowDef="let row; columns: headerTable;"></mat-row>
      <ng-container *ngIf="showTotals">
        <mat-footer-row *matFooterRowDef="headerTable"></mat-footer-row>
      </ng-container>
    </mat-table>

  </div>
</div>
