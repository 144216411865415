import { Component, OnInit, Input } from '@angular/core';
import { Period } from 'app/shared/model';



@Component({
  selector: 'widget-period-formater',
  templateUrl: './period-formater.component.html',
  styleUrls: ['./period-formater.component.scss']
})
export class PeriodFormaterComponent implements OnInit {

  @Input()
  period: Period;

  @Input()
  invert = false;

  @Input()
  format: string;


  constructor() { }

  ngOnInit() {

  }

  get variation(){
    if(this.period && this.period.variation){
      return this.period.variation*this.variationDirection
    }else{
      return null;
    }

  }

  get variationFormat(){
    if(this.format.includes('area_')) return 'percent';
    if(['nps'].includes(this.format)){
      return this.format;
    } else{
      return 'percent'
    }
  }

  get valueFormat(){
    if(this.format.includes('area_')) return 'average';
    return this.format;
  }

  get variationDirection(){
    return this.invert ? -1 : 1;
  }

  get tooltip(){
    if(['pms', 'percent'].includes(this.format)){
      return this.period?.actual ? `${this.period.actual}%` : '0%';
    }
    if(['average','rating'].includes(this.format)){
      return this.period.actual
    }
    if(this.format.includes('area_')){
      return this.period.actual
    }

    return null;
  }

}
