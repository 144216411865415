import { Component,ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-precheckin-guest-detail-table',
  templateUrl: './precheckin-guest-detail-table.component.html',
  styleUrls: ['./precheckin-guest-detail-table.component.scss']
})
export class PrecheckinGuestDetailTableComponent {

  @Input()
  dataTable

  @Input()
  waitingDownload = false;

  @Output()
  file: EventEmitter<any> = new EventEmitter<any>();

  language;
  language_id:number | any;

  headerTable:string[] = [
    'number_question',
    'question',
    'answer',
  ];

  dataSource = new MatTableDataSource([]);

  currentDownload;


  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private utilService: UtilService) { }

  ngOnChanges(){
    this.createTable();
  }

  createTable(){
    this.language_id  = this.utilService.getLanguageId();
    this.language     = this.utilService.getCurrentLanguage();

    this.dataSource = new MatTableDataSource(this.dataTable);
    const self = this;
    this.dataSource.sortingDataAccessor = function (data, sortHeaderId) {

      let value: any = data[sortHeaderId];
      if(sortHeaderId==='number_question'){
        value = 10 * +value;
      }

      if(sortHeaderId==='question'){
        value = value.translates.find(x=>x.language_id === self.language_id).label;
      }

      if(sortHeaderId==='answer'){
        value = value.answer;
      }

      if (typeof value === 'string' && !value.trim()) {
        return value;
      }
      return isNaN(+value) ? value : +value;
    };
    this.dataSource.sort = this.sort;
  }


  getFile(filename, answerId) {
    this.currentDownload = answerId;
    this.waitingDownload = true;
    this.file.emit(filename);
  }

  isAnswer(answerId) {
    return this.currentDownload === answerId;
  }


}
