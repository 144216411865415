import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { CoreLibModalComponent } from '../modal/modal.component';

@Component({
  selector: 'mh-confirmation-answer-survey-dialog',
  templateUrl: './confirmation-answer-survey-dialog.component.html',
  styleUrls: ['./confirmation-answer-survey-dialog.component.scss'],
})
export class ConfirmationAnswerSurveyDialogComponent extends CoreLibModalComponent implements OnInit {
  public type: string;
  public userEmail: string;
  public options: { id: number; name: string }[] = [{ id: 3, name: 'whatsapp' }];

  constructor(protected dialogRef: DialogRef, @Inject(DIALOG_DATA) public data) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.type = this.data?.type;
    this.userEmail = this.data?.userEmail;
  }

  handleConfirmSend() {
    this.close(true);
  }
}
