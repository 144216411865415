import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadPageService {

  constructor(private http: HttpClient) { }

  checkFileExists(filePath) {
    const url = `${environment.customerPath}commons/check/file`;
    const params = new HttpParams()
      .set('filePath', filePath)
      .set('isPublic', true);
    return this.http.get(url, { params })
  }
}

