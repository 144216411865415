import { Component, Inject } from '@angular/core';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { NotificationsService } from '../../services/notifications.service';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';

@Component({
  selector: 'mh-mute-notifications-modal',
  templateUrl: './mute-notifications-modal.component.html',
  styleUrls: ['./mute-notifications-modal.component.scss'],
})
export class MuteNotificationsModalComponent {
  public muteOptions: number[] = [8, 24, 48];
  public hourSelected = 0;
  public success = false;

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    protected dialogRef: DialogRef,
    private notificationsService: NotificationsService,
  ) {}

  handleChange(option: number) {
    this.hourSelected = option;
  }

  async muteNotifications() {
    const userId = this.data.currentUser.id;
    try {
      await this.notificationsService.pauseNotifications(userId, this.hourSelected).toPromise();
      this.success = true;
    } catch (error) {
      console.error(error);
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
