import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { SmartRepliesModalComponent } from './smart-replies-modal/smart-replies-modal.component';
import { ConfigComponent } from './config/config.component';
import { SmartRepliesRoutingModule } from './smart-replies-routing.module';
import { SmartRepliesComponent } from './smart-replies.component';
@NgModule({
  declarations: [SmartRepliesModalComponent, ConfigComponent, SmartRepliesComponent],
  imports: [CommonModule, SharedModule, SmartRepliesRoutingModule],
})
export class SmartRepliesModule {}
