<div class="header-menu-mobile" *ngIf="isMenuOpen && isMobile" [@widthExpandHeader]="navState">
  <div class="d-flex justify-content-center align-items-center h-100">
    <img class="inline w-100" [src]="myhotel_logo" alt="logo" (error)="setLogo(true)">
  </div>
</div>

<ng-container *ngIf="isMenuOpen && isMobile else headerMenuMobile">
  <ng-container *ngTemplateOutlet="headerMenuMobileOpen"></ng-container>
</ng-container>

<ng-template #headerContent>
  <div class="content_header">
    <div class="menu_mobile" (click)="toggleNavbar()" [hidden]="isMenuOpen">
      <i class="fal fa-bars"></i>
    </div>

    <div class="logo_area before-inline" [hidden]="!isMenuOpen || isMobile">
      <a [routerLink]="['/']">
        <img class="inline" [src]="myhotel_logo" alt="logo" (error)="setLogo(true)">
      </a>
    </div>

    <div class="header_area" [class.menu-mobile-opened]="isMobile && isMenuOpen">
      <div class="row no-gutters justify-content-between align-items-center">
        <div class="col-8">
          <div class="actions">
            <div class="row content_name_hotel no-gutters align-items-end">
              <div class="col-10 col-sm-10 col-md-6 col-lg-6 col-xl-6">
                <div class="ml-3">
                  <div class="align-items-center">
                    <div>
                      <mh-hotel-finder
                        *ngIf="!waitUpdateCustomer"
                        [currentClient]="hotel"
                        [isCorporative]="true"
                        [currentUser]="currentUser"
                        [currentChain]="chain"
                        [isMobile]="isMobile"
                        [isNavbarCorp]="isCorporative"
                        (updated)="updateCustomer($event)"
                        (isReady)="isReady($event)"
                        ></mh-hotel-finder>
                      <mh-spinner-classic *ngIf="waitUpdateCustomer"></mh-spinner-classic>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2 col-sm-2 col-md-6 col-lg-6 col-xl-6 align-self-center" [hidden]="false">
                <div class="ml-3" [class.ml-5]="!isMobile" *ngIf="is_super_admin">
                  <mh-header-button
                    *ngIf="!waitUpdateCustomer"
                    [currentUser]="currentUser"
                    [userProfile]="userProfile"
                    [availableLanguages]="availableLanguages"
                    [customer]="hotel"
                    [size]="35"
                    [isMobile]="isMobile"
                    (updated)="updateCustomer($event)"
                    (isReady)="isReady($event)"
                    ></mh-header-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4" *ngIf="!waitUpdateCustomer">
          <div class="flex-row-reverse d-flex">
            <ng-container *ngFor="let type of headersButtons">
              <ng-container *ngIf="(isMobile && (type === 'menu' || type === 'notifications')) || !isMobile">
                <div class="mx-2">
                  <mh-header-button
                    [type]="type"
                    [customer]="hotel"
                    [userProfile]="userProfile"
                    [availableLanguages]="availableLanguages"
                    [currentUser]="currentUser"
                    [currentLanguage]="currentLanguage"
                    [currentLanguageId]="currentLanguageId"
                    [isRetail]="isRetail"
                    [isMobile]="isMobile"
                    [detectedCases]="detectedCases"
                    (gaEvent)="ga($event)"
                    (updatedLanguage)="switchLanguage($event)"
                    (createCaseEvent)="openNewModal()"
                    (openMenuEvent)="openMenuHandler($event)"
                    >
                  </mh-header-button>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

      </div>

    </div>
  </div>
</ng-template>


<ng-template #headerMenuMobileOpen>
  <div class="wrapper">
    <ng-container *ngTemplateOutlet="headerContent"></ng-container>
  </div>
</ng-template>

<ng-template #headerMenuMobile>
  <ng-container *ngTemplateOutlet="headerContent"></ng-container>
</ng-template>

<ng-container *ngIf="!waiting">
  <mh-flags></mh-flags>
</ng-container>
