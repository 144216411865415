import {CustomerProfile} from './customer_profile.model';


export class Language{
  id:number;
  constructor(){
    this.id = 1;
  }
}

export class Profile{
  id_pipedrive: string;
  company_position:string;
  type_company_position: string;
  department: string;
  email:string;
  language:Language;
  last_name:string;
  name:string;
  mobile_phone:string;
  landline: string;
  language_code:number;
  phone: string;
  customers:CustomerProfile[] = [];
  constructor(id_pipedrive?, company_position?, type_company_position?, department?, email?, language?, last_name?, name?, mobile_phone?, landline?){
    this.id_pipedrive          = id_pipedrive          || '';
    this.company_position      = company_position      || '';
    this.type_company_position = type_company_position || '';
    this.department            = department            || '';
    this.email                 = email                 || '';
    this.language              = language              || new Language();
    this.last_name             = last_name             || '';
    this.name                  = name                  || '';
    this.mobile_phone          = mobile_phone          || '';
    this.landline              = landline              || '';
    this.language_code         = this.language.id;

  }
}

export class Role {
  id   : number;
  type : string;
  code : string;
}

export class Department {
  id   : number;
  type : string;
  code : string;
}
export class UserProfile{
  id:number;
  user_id:number;
  admin:boolean;
  customers:CustomerProfile[] = [];
  super_admin:boolean;
  username:string;
  profile:Profile;
  send_mail_new_user:boolean;
  pipedrive_id:string;
  first_name:string;
  last_name:string;
  email:string;
  role:Role;
  company_position: string;
  department: Department;
  phone_country_code: string;
  phone_number: string;
  mobile_country_code: string;
  mobile_number: string;
  language_id: number;
  flag_admin: number;
  profile_photo:string = 'https://statics.myhotel.io/uploads/fidelity-suite/users/default_user.png';
  profile_photo_active:string = 'https://statics.myhotel.io/uploads/fidelity-suite/users/active_user.png';
  profile_photo_raw: object | null = null;

  constructor( id?, admin?, customers?, super_admin?, username?, profile?, send_mail_new_user?, photo?) {
    this.id = id || 0;
    this.admin = admin || false;
    this.customers = customers || [];
    this.super_admin = super_admin  || false;
    this.username = username || '';
    this.profile = profile || new Profile();
    this.send_mail_new_user = send_mail_new_user || false;
    this.profile_photo = photo || this.profile_photo
  }

  initialesName(){
    let name = this.profile.name ? this.profile.name[0] : '';
    let last_name = this.profile.last_name ? this.profile.last_name[0] : '';
    return name+'<span>'+last_name+'</span>';
  }
}
