<ng-container *ngFor="let resource of sortedResources; let last = last; let index = index">
  <ng-container *ngIf="resource && resource.type && resource.type?.name === 'comment'">
    <ng-container *ngTemplateOutlet="comment;context:{resource, last}"></ng-container>
  </ng-container>
  <ng-container *ngIf="resource && resource.modifications">
    <ng-container *ngTemplateOutlet="log;context:{resource, last, index}"></ng-container>
  </ng-container>
</ng-container>

<ng-template #comment let-comment="resource" let-lastResource="last">
  <div class="tw-flex tw-gap-5 tw-my-3 tw-px-3 tw-relative">
    <div
      class="tw-h-full tw-w-px tw-border-l tw-absolute tw-left-[20px] tw-border-border-gray tw-z-10"
      *ngIf="!lastResource"
    ></div>
    <div class="tw-bg-white tw-h-fit tw-z-20">
      <i class="fas fa-user tw-text-font-gray tw-text-xl"></i>
    </div>
    <div class="tw-border tw-border-slate-300 tw-rounded-lg tw-px-3 tw-py-1.5 tw-grid tw-grid-cols-[14px_100%] tw-gap-2 tw-w-min-comment">
      <i class="fas fa-comment tw-text-sm tw-text-main-text"></i>
      <div class="tw-inline-flex tw-flex-col tw-gap-1.5 tw-grow">
        <div class="tw-flex tw-justify-between tw-w-max-files">
          <span class="tw-text-main-text tw-font-semibold"
            >{{getNameValue(comment.author_id)}}
            <span class="tw-text-sm tw-text-font-gray tw-font-normal"
              >{{getCompanyPosition(comment.author_id)}}</span
            ></span
          >
          <span class="tw-text-main-text">{{comment.created_at | formatDate: language: true}}</span>
        </div>
        <div class="tw-text-main-text tw-w-max-files">
          <mh-user-mention
            [users]="users"
            [encryptedUser]="true"
            [text]="comment.text"
            [files]="comment.attachments"
            [fileName]="null"
            [waitingDownload]="waitingFileDownload"
            (downloadFile)="handleDowloadEvent($event)"
          ></mh-user-mention>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #log let-log="resource" let-lastResource="last" let-index="index">
  <div
    class="tw-flex tw-gap-5 tw-my-3 tw-px-3 tw-relative tw-cursor-pointer"
    (click)="expanded[index] = !expanded[index]"
  >
    <div
      class="tw-h-full tw-w-px tw-border-l tw-absolute tw-left-[20px] tw-border-border-gray tw-z-10"
      *ngIf="!lastResource"
    ></div>
    <div class="tw-bg-white tw-h-fit tw-z-20">
      <i class="fas fa-user tw-text-font-gray tw-text-xl"></i>
    </div>
    <div class="tw-border tw-border-slate-300 tw-rounded-lg tw-px-3 tw-py-1.5 tw-flex tw-gap-2 tw-grow tw-max-w-[97%]">
      <i class="fas {{log.icon}} tw-text-sm tw-text-main-text"></i>
      <div class="tw-flex tw-flex-col tw-gap-1.5 tw-grow tw-w-[96%]">
        <div class="tw-flex tw-justify-between">
          <span class="tw-text-main-text tw-font-semibold"
            >{{getNameValue(log.user_id)}}
            <span class="tw-text-sm tw-text-font-gray tw-font-normal">{{getCompanyPosition(log.user_id)}}</span></span
          >
          <span class="tw-text-main-text">{{log.timestamp | formatDate: language: true}}</span>
        </div>
        <div class="tw-text-main-text">
          {{'logs.actions.'+log.type | translate}}:
          <strong>{{'logs.resources.' + log.resourceName + '.title' | translate}}</strong>
        </div>
        <div [@heightExpand]="expanded[index] ? '*' : 'void'">
          <div>{{'logs.changes_description' | translate}}:</div>
          <div *ngFor="let change of log.changes">
            <li *ngIf="!EXCLUDED_FIELDS.includes(change?.name) && !change.description.includes('related_area') ">
              <ng-container *ngIf="change.actual?.translates && !BASE_FIELDS.includes(change.name); else baseChange">
                {{'logs.resources.' + log.resourceName + '.fields.' + change.name | translate}}:
                <strong
                  mhFindTranslate
                  [language]="language"
                  [translates]="change.actual?.translates"
                  [keyTranlsate]="'name'"
                  [capitalize]="true"
                ></strong>
              </ng-container>
              <ng-template #baseChange>
                <ng-container
                  *ngIf="log.resourceName !== 'review_reply' && change.name !== 'translation'; else translation"
                >
                  <ng-container [ngSwitch]="change.name">
                    <ng-container *ngSwitchCase="'related_area'">
                      <ng-container *ngIf="!change.previous && change.actual">
                        <span
                          [innerHtml]="('logs.changes.ADD.AREA' | translate: {actual: {name: getAreasValue(change, 'actual')} })"
                        >
                        </span>
                      </ng-container>
                      <ng-container *ngIf="change.previous && !change.actual">
                        <span
                          [innerHtml]="('logs.changes.DELETE.AREA' | translate: {previous: {name: getAreasValue(change, 'previous')} })"
                        >
                        </span>
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngSwitchCase="(change.name === 'priority' || change.name === 'state' || change.name === 'case_type') ? change.name : ''"
                    >
                      <span
                        [innerHTML]="'logs.changes.EDIT.FIELD_TYPE' | translate: {type: ('logs.resources.case.fields.'+change.name | translate),actual: getTranslates(change.actual.translates),previous: getTranslates(change.previous.translates)}"
                      ></span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'subscription'">
                      <ng-container *ngIf="change.actual">
                        <span
                            [innerHTML]="'logs.changes.ADD.USER_SUBSCRIBED' | translate: {name: getUser(log.sub_resource_id)} "
                            ></span>
                      </ng-container>
                      <ng-container *ngIf="!change.actual">
                        <span
                            [innerHTML]="'logs.changes.DELETE.USER_SUBSCRIBED' | translate: {name: getUser(log.sub_resource_id)} "
                            ></span>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <ng-container>
                        {{'logs.resources.' + log.resourceName + '.fields.' + change.name | translate}}:
                        <span
                          [innerHtml]="('logs.changes.' + change.operation + '.' + change.type | translate: (getTranslateParam(change)))"
                        >
                        </span>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-template #translation>
                  <ng-container *ngIf="log.resourceName !== 'review_reply' else translateReviewLog">
                    {{'logs.resources.' + log.resourceName + '.fields.' + change.actual.fieldName | translate}}:
                    <span
                      [innerHtml]="('logs.changes.' + change.operation + '.' + change.type | translate: (getTranslateParamForTranslation(change)))"
                    >
                    </span>
                  </ng-container>
                </ng-template>
                <ng-template #translateReviewLog>
                  {{'logs.resources.' + log.resourceName + '.review_replied' | translate}}:
                  <span> {{change.description}} </span>
                </ng-template>
              </ng-template>
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
