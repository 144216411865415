<mh-core-lib-loader-wrapper
    class="tw-h-96"
    [type]="'spinner'"
    [loading]="loading"
    className="tw-self-center" 
    spinnerClassName="tw-text-[50px]">
        <div class="tw-flex tw-justify-end">
            <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
        </div>
        <div class="tw-p-4 tw-max-h-[100%] tw-overflow-auto">
            <table class="tw-w-full tw-mt-2">
                <tr class="tw-border-b">
                    <th class="tw-pb-6">{{ 'commons.permission' | translate }}</th>
                    <th class="tw-pb-6 tw-text-center"*ngFor="let role of roles; trackBy: roleTrack">{{ 'models.user_profile.roles.' + role.id | translate }}</th>
                </tr>
            
                <tr class="tw-border-b" *ngFor="let action of actions">
                    <td class="tw-py-2">
                        <div class="tw-flex tw-flex-col tw-justify-center">
                            <p class="tw-font-bold">{{ action.title }}</p>
                            <p class="tw-italic">{{ action.subTitle }}</p>
                        </div>
                    </td>
                    <td *ngFor="let role of roles; trackBy: roleTrack" class="tw-py-2">
                        <div class="tw-flex tw-justify-center" *ngIf="action[role.name] === 'active'">
                            <img class="tw-text-center tw-align-middle" src="https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/user-manager/check.png"/>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
</mh-core-lib-loader-wrapper>
