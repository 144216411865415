<div class="tw-relative">
  <article class="tw-rounded-lg tw-shadow-1 tw-px-6 tw-py-3 tw-flex tw-flex-col md:tw-flex-row tw-gap-5 md:tw-gap-20 tw-text-main-text tw-min-h-[110px] hover:tw-cursor-pointer hover:tw-border-main hover:tw-border-2" [class.tw-border-2]="case.stagnant" [class.tw-border-cases-stagnant]="case.stagnant" (click)="handleBoxClick()">
    <section class="tw-flex tw-flex-col md:tw-basis-64 tw-gap-1 tw-justify-center">
      <p class="tw-font-bold">{{case.title}}</p>
      <p class="tw-font-medium">{{'cases.commons.case' | translate}}: <span class="tw-font-bold">{{case.public_id}}</span> <i *ngIf="case.stagnant" class="fas fa-exclamation-circle tw-text-cases-stagnant tw-text-sm" [mhTooltip]="'logs.resources.case.fields.stagnant' | translate"></i></p>
    </section>
    <section class="tw-flex tw-w-full tw-gap-3 md:tw-gap-0 tw-relative">
      <article class="tw-self-start md:tw-self-center tw-flex tw-justify-start" [ngClass]="{'md:tw-basis-1/6': !isHistoryPage, 'md:tw-basis-48': isHistoryPage, 'tw-basis-1/4': isHistoryPage, 'tw-basis-1/3': !isHistoryPage}">
        <div class="tw-flex tw-flex-col md:tw-hidden">
          <span>{{'commons.date' | translate}}:</span>
          <span class="tw-font-bold">{{case.created_at | formatDate}}</span>
        </div>
        <div class="tw-hidden md:tw-block">
          <span>{{case.created_at | formatDate}}</span>
        </div>
      </article>
      <article class="tw-self-start md:tw-self-center tw-flex tw-justify-start" [ngClass]="{'md:tw-basis-1/6': !isHistoryPage, 'md:tw-basis-48': isHistoryPage, 'tw-basis-1/4': isHistoryPage, 'tw-basis-1/3': !isHistoryPage}" *ngIf="isHistoryPage">
        <div class="tw-flex tw-flex-col md:tw-hidden">
          <span>{{'cases.commons.closed' | translate}}</span>
          <span>{{case.last_state_change | formatDate}}</span>
        </div>
        <div class="tw-hidden md:tw-block">
          <span>{{case.last_state_change | formatDate}}</span>
        </div>
      </article>
      <article class="tw-hidden tw-self-center md:tw-flex tw-justify-start" [ngClass]="{'md:tw-basis-1/6': !isHistoryPage, 'md:tw-basis-48': isHistoryPage}">{{case.author.first_name}} {{case.author.last_name}}</article>
      <article class="tw-self-start md:tw-self-center tw-flex tw-justify-start" [ngClass]="{'md:tw-basis-1/6': !isHistoryPage, 'md:tw-basis-48': isHistoryPage, 'tw-basis-1/3': !isHistoryPage, 'tw-basis-1/4': isHistoryPage}">
        <div class="tw-flex tw-flex-col md:tw-hidden">
          <span>{{'cases.commons.assigned_to' | translate}}:</span>
          <span class="tw-font-bold">{{case.assigned.first_name}} {{case.assigned.last_name}}</span>
        </div>
        <div class="tw-hidden md:tw-block">
          {{case.assigned.first_name}} {{case.assigned.last_name}}
        </div>
      </article>
      <article class="tw-self-start md:tw-self-center tw-flex tw-justify-start" [ngClass]="{'md:tw-basis-1/6': !isHistoryPage, 'md:tw-basis-48': isHistoryPage, 'tw-basis-1/3': !isHistoryPage, 'tw-basis-1/4': isHistoryPage}">
        <div class="tw-flex tw-flex-col md:tw-hidden">
          <span>{{'cases.commons.status' | translate}}:</span>
          <span class="tw-font-bold">{{case.state.translates | translateObject: 'name' | titlecase}}</span>
        </div>
        <div class="tw-hidden md:tw-block">
          {{case.state.translates | translateObject: 'name' | titlecase}}
        </div>
      </article>
      <article class="tw-hidden tw-self-center md:tw-flex tw-justify-start" [ngClass]="{'md:tw-basis-1/6 ': !isHistoryPage, 'md:tw-basis-48': isHistoryPage}">{{(case.product_item?.product || ('cases.commons.no_product_tag' | translate)) | titlecase}}</article>
      <article class="tw-hidden tw-self-center md:tw-flex tw-justify-start" [ngClass]="{'md:tw-basis-1/6': !isHistoryPage, 'md:tw-basis-48': isHistoryPage}">
        <span class="{{case.priority.color_code}} tw-flex tw-justify-center tw-text-white tw-font-semibold tw-px-2 tw-py-1 tw-rounded-full tw-min-w-[70px]">
          {{case.priority.translates | translateObject: 'name' | titlecase}}
        </span>
      </article>
    </section>
  </article>
  <div [class.tw-hidden]="!isSuperAdmin" class="delete-trigger tw-cursor-pointer tw-absolute tw-flex tw-justify-center tw-items-center tw-w-[30px] tw-h-[30px] tw-right-1.5 tw-top-2.5 tw-text-main-text hover:tw-text-main-orange tw-transition-all"
      (click)="isOpen = !isOpen"
      cdkOverlayOrigin #trigger="cdkOverlayOrigin"
    >
    <i class="fas fa-ellipsis-v"></i>
  </div>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayPositions]="overlayPositions"
  >
  <div #deleteMenu class="tw-flex tw-flex-col tw-mt-1.5 tw-bg-white tw-rounded tw-shadow-1">
    <mh-core-lib-actions-wrapper
        slug="cases_cases_case_delete"
      >
      <button class="focus:tw-outline-0 hover:tw-text-red-500 tw-px-2.5 tw-py-2 tw-transition-color tw-text-main-text" (click)="handleDeleteCase()">{{'cases.commons.delete_case' | translate}}</button>
    </mh-core-lib-actions-wrapper>
  </div>
</ng-template>
