export default class Answer {
  id?: number;
  answered_survey_id: number;
  question_id: number;
  answer_alternative_id: number;
  answer_text?: string;
  sub_alternative_id?: number;
  selected: boolean;
  waiting: boolean;
  conditional_alternative_id: number;

  tempId?: string;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  isAnswered() {
    return !!this.selected ||
      !!this.answer_text;
  }
}
