import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { HotelService } from 'app/shared/hotel/hotel.service';
import { Log } from 'app/shared/logs/types';

@Component({
  selector: 'mh-logs-list',
  templateUrl: './logs-list.component.html',
  styleUrls: ['./logs-list.component.scss']
})
export class LogsListComponent implements OnInit {
  //fixed options
  PAGE_SIZE_OPTIONS = [10, 20, 50]

  @Input() logs: Log[]
  @Input() language:any;

  pageEvent: PageEvent;

  commercialExecutivesList = [];

  constructor(private hotelService:HotelService,) { }

  async ngOnInit() {
    try {
      const hotelBase = await this.hotelService.getBase().toPromise();
      this.commercialExecutivesList = hotelBase['commercial_executives'];
    } catch {
      this.commercialExecutivesList = [];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.logs) this.refreshPageEvent();
  }

  handlePaginate = (event: PageEvent) => {
    this.pageEvent = event;
  }

  refreshPageEvent() {
    this.pageEvent = <PageEvent>{
      pageIndex: 0,
      pageSize: (this.pageEvent || {pageSize: 20}).pageSize,
      length: 0,
    }
  }
}
