import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { animation } from 'app/shared/utils/animation-custom';

@Component({
  selector: 'mh-core-lib-generic-selector',
  templateUrl: './generic-selector.component.html',
  styleUrls: ['./generic-selector.component.scss'],
  animations: [ animation.rotate ]
})
export class CoreLibGenericSelectorComponent implements OnInit {

  selectedOptions = [];
  firstTimeOpen = true;

  @Input()
  isOpen = false;

  @Input()
  options = [];

  @Input()
  translatePrefix = '';

  @Input()
  placeholder = '';

  @Input()
  closeAtClick = false;

  @Input()
  verifyClickId = '';

  @Input()
  displaySelectedValue = 'value';

  @Input()
  isCountry = false;

  @Input()
  additionalContainerClasses = '';

  @Input()
  isSimpleSelection = false;

  @Input()
  selectedOptionsFromParent = [];

  @Output()
  optionClicked = new EventEmitter<any>();

  @Output()
  closedOptions = new EventEmitter();

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const result = this.checkClickInside(event.target);
    if ( !result && !this.firstTimeOpen ) {
      this.isOpen = false;
      this.closedOptions.emit();
    }
    this.firstTimeOpen = false;
  }

  ngOnInit(): void {
    if (this.selectedOptionsFromParent.length > 0) {
      this.selectedOptions = this.selectedOptionsFromParent.filter((option) => option.selected);
    }
  }

  checkClickInside(target) {
    if ( target.id === this.verifyClickId ) {
      return true;
    } else if ( target.parentNode ) {
      return this.checkClickInside(target.parentNode)
    } else {
      return false;
    }
  }


  toggle() {
    this.isOpen = !this.isOpen;
  }

  handleClick(option) {
    if ( this.closeAtClick ) {
      this.toggle();
    }
    if(this.isSimpleSelection) {
      this.selectedOptions = [option];
    } else {
      this.selectedOptions.push(option);
    }
    this.optionClicked.emit(this.selectedOptions);
  }

  handleRemove(inputOption) {
    this.selectedOptions = this.selectedOptions.filter(option => {
      return option.value !== inputOption.value;
    })
    this.optionClicked.emit(this.selectedOptions);
  }
}
