export class PeriodItem{

  customer_id: number;
  items:PeriodItemDetails[] = [];

  constructor(customer_id){
    this.customer_id = customer_id
  }
}

export class PeriodItemDetails{
  type:string
  details:PeridoValueItem[] = [];

  constructor(type){
    this.type = type;
    this.details.push(new PeridoValueItem());
  }
}

export class PeridoValueItem{
  date:Date;
  actual:PeriodValues;
  previous:PeriodValues;
  variation:number;
}

export class PeriodValues{
  value: number=0;
}