<div class="container_table">
  <div class="area_table" [ngStyle]="getTableStyle()">
    <div class="explain">
      * {{'inter-chain.prestay.explain_usd' | translate }}
    </div>
    <mat-table  class="table-general table-summary fix_first_column center_info scores sticky" #table [dataSource]="dataSource" matSortActive="total_price" matSortDisableClear matSortDirection="desc" matSort>
      <ng-container *ngFor="let key of headerTable" matColumnDef="{{key}}">
        <mat-header-cell [class]="key" *matHeaderCellDef mat-sort-header>
          <div [ngSwitch]="key">
            {{'inter-chain.prestay.summary.table.'+key | translate }}
          </div>
          <div [ngSwitch]="key">
            <i *ngIf="!blackListHeaderTooltip.includes(key)" container="body" class="tooltip-icon fas fa-question-circle tooltip-icon-header" [ngbTooltip]="('inter-chain.prestay.summary.table.tooltips.'+key | translate)"></i>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="{'fixed-cell' : key==='customer'}" [class]="key">

          <div class="cell_data {{key}}">
            <div [ngSwitch]="key">
              <ng-template [ngSwitchCase]="'customer'">
                <customer-detail [customer]="element[key]" [setup]="customersDetails"></customer-detail>
              </ng-template>

              <ng-template [ngSwitchCase]="'total_price'">
                <widget-period-indicator
                  [variation]="element[key]?.variation"
                  [currentValue]="element[key]?.actual"
                  [previousValue]="element[key]?.previous"
                  [variationFormat]="(element[key]?.variation | formatPercent )"
                  [previousFormat]="(element[key]?.previous | currency:currency.code:(currency.code+' '+currency.symbol))"
                  [currentFormat]="(element[key]?.actual | currency:currency.code:(currency.code+' '+currency.symbol))"
                  [style]="'light'"
                ></widget-period-indicator>
              </ng-template>

              <ng-template [ngSwitchCase]="['email_rate','order_rate'].includes(key) ? key : false">
                <widget-period-indicator
                  [variation]="element[key]?.variation"
                  [currentValue]="element[key]?.actual"
                  [previousValue]="element[key]?.previous"
                  [variationFormat]="(element[key]?.variation | formatPercent )"
                  [previousFormat]="(element[key]?.previous | formatPercent)"
                  [currentFormat]="(element[key]?.actual | formatPercent)"
                ></widget-period-indicator>
              </ng-template>


              <ng-template ngSwitchDefault>
                <widget-period-indicator
                  [variation]="element[key]?.variation"
                  [currentValue]="(element[key]?.actual) ? (element[key]?.actual) : '-'"
                  [previousValue]="element[key]?.previous"
                  [variationFormat]="(element[key]?.variation | formatPercent )"
                  [previousFormat]="(element[key]?.previous)"
                  [currentFormat]="(element[key]?.actual)"
                ></widget-period-indicator>
              </ng-template>

            </div>
          </div>

        </mat-cell>
        <mat-footer-cell [ngClass]="{'fixed-cell' : key==='customer'}" [class]="key" *matFooterCellDef>
          <div class="full-width" [ngSwitch]="key">
            <ng-template [ngSwitchCase]="'customer'">
              <b>{{'commons.total' | translate }}</b>
            </ng-template>
            <ng-template [ngSwitchCase]="'total_price'">
              <mh-footer-total-label
                [actualValue]="(getTotal(key).actual | currency:currency.code:(currency.code+' '+currency.symbol))"
                [previousValue]="(getTotal(key).previous | currency:currency.code:(currency.code+' '+currency.symbol))"
                [numberType]="key"
                [style]="'light'"
              ></mh-footer-total-label>
            </ng-template>

            <ng-template [ngSwitchCase]="['email_rate','order_rate'].includes(key) ? key : false">
              <mh-footer-total-label
                [actualValue]="(getAverage(key).actual | formatPercent)"
                [previousValue]="(getAverage(key).previous | formatPercent)"
                [numberType]="'key'"
                [style]="'light'"
              ></mh-footer-total-label>
            </ng-template>


            <ng-template ngSwitchDefault>
              <mh-footer-total-label
                [actualValue]="getTotal(key).actual"
                [previousValue]="getTotal(key).previous"
                [numberType]="(['semantic_positive'].includes(key) ? 'percentage' : key)"
              ></mh-footer-total-label>
            </ng-template>
          </div>
        </mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="headerTable"></mat-header-row>
      <mat-row *matRowDef="let row; columns: headerTable;"></mat-row>
      <mat-footer-row *matFooterRowDef="headerTable"></mat-footer-row>
    </mat-table>
  </div>



</div>
