import { InputTranslate } from 'app/shared/model/form/input.model';
import { Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import { UtilService } from 'app/shared/util.service';

@Directive({
  selector: '[mhFindTranslate]',
})
export class FindTranslateDirective {

  @Input() translates: InputTranslate[] = [];

  @Input() keyTranlsate = 'label';

  @Input() capitalize: boolean = false;

  @Input()
  language:string='es';

  @Output() inputTranslate: EventEmitter<string> = new EventEmitter();

  element$: ElementRef;

  constructor(el: ElementRef, private utilService:UtilService) {
    this.element$ = el;
  }

  ngOnChanges(){
    const translateText = this.utilService.findInputTranslate(this.translates,this.language, this.keyTranlsate);
    this.inputTranslate.emit(translateText)
    this.element$.nativeElement.innerText = this.capitalize ?
      this.capitalizeText(translateText) : translateText;
  }

  capitalizeText(text: string): string {
    try {
      return text
        .split(' ')
        .map(text => text[0].toUpperCase() + text.slice(1))
        .join(' ');
    } catch {
      return '-';
    }
  }
}
