import { Injectable } from '@angular/core'
import { BehaviorSubject, forkJoin } from 'rxjs';
import { Survey, SurveyAnswer, Answer } from 'app/survey-public/models';
import { SurveyAnswerPublicService } from './survey-answer-public.service';
import { UtilService } from 'app/layouts/main';

@Injectable({
  providedIn: 'root'
})
export class SurveyAnswerPublicFacade {
  private survey$ = new BehaviorSubject<any>(null)
  private surveyAnswers$ = new BehaviorSubject(new SurveyAnswer())
  private loading$ = new BehaviorSubject<boolean>(false)
  private language$ = new BehaviorSubject<string>('es')
  private languageId$ = new BehaviorSubject<any>(1)
  private dataReady$= new BehaviorSubject<boolean>(false);


  generalInfo;
  sections;
  conditionalQuestions;
  answerResponse;

  constructor(
    private surveyService:SurveyAnswerPublicService,
    private utils: UtilService,
  ) {
    this.language$.next(this.utils.getCurrentLanguage())
    this.languageId$.next(this.utils.getCurrentLanguageId());
    this.utils.isLanguageChanged()
      .subscribe(this.updateLanguage.bind(this))
  }

  loadAll(surveyId:number, answerId:number) {
    this.loading$.next(true);
    this.dataReady$.next(false);

    forkJoin({
      general: this.surveyService.getGeneralInfo(surveyId),
      sections: this.surveyService.getSections(surveyId),
      conditional_questions: this.surveyService.getConditionalQuestions(surveyId),
      answerResponse: this.surveyService.getAnswerPublic(surveyId, answerId),
    }).subscribe(({general, sections, conditional_questions, answerResponse}) => {
      const survey = Survey.createFromApi({...general, sections, conditional_questions})
      const surveyAnswers = this.formatAnswers(answerResponse);
      survey.updateAnswers(surveyAnswers.answers)
      // NEED FOR TEXTS TO COME
      this.saveSurveysAndAnswers(survey, surveyAnswers, answerId)
      this.setAnswers(surveyAnswers);
      this.setSurvey(survey);

      this.generalInfo = general;
      this.sections = sections;
      this.conditionalQuestions = conditional_questions;
      this.answerResponse = answerResponse;
      this.dataReady$.next(true);

      this.loading$.next(false);
    });
  }

  loadAnswers(survey: Survey, answerId:number) {
    this.dataReady$.next(false);
    this.loading$.next(true);

    this.surveyService.getAnswerPublic(survey.id, answerId).subscribe((answerResponse) => {
      const surveyAnswers = this.formatAnswers(answerResponse);
      survey.updateAnswers(surveyAnswers.answers)
      // NEED FOR TEXTS TO COME
      this.saveSurveysAndAnswers(survey, surveyAnswers, answerId)
      this.setAnswers(surveyAnswers);
      this.answerResponse = answerResponse;
      this.setSurvey(survey);
      this.loading$.next(false);
    this.dataReady$.next(true);
    });
  }

  private saveSurveysAndAnswers(survey: Survey, surveyAnswers, answerId:number) {
    const surveysAndAnswersValue = this.surveyService.getSurveysAndAnswersValue()
    const newSurveysAndAnswers = {survey,surveyAnswers: {...surveyAnswers, answerId}}
    const values = surveysAndAnswersValue ? [
      ...surveysAndAnswersValue,
      newSurveysAndAnswers
    ] : [newSurveysAndAnswers]

    this.surveyService.surveysAndAnswers.next(values)
  }

  updateLanguage() {
    this.language$.next(this.utils.getCurrentLanguage());
    this.languageId$.next(this.utils.getCurrentLanguageId());
  }

  setSurvey(survey: Survey) {
    this.survey$.next(survey);
  }

  setAnswers(surveyAnswers: SurveyAnswer) {
    this.surveyAnswers$.next(surveyAnswers)
  }

  getGeneralInfo() {
    return this.generalInfo;
  }

  getSections() {
    return this.sections;
  }

  getConditionalQuestions() {
    return this.conditionalQuestions;
  }

  getAnswerResponse() {
    return this.answerResponse;
  }

  isReady$() {
    return this.dataReady$.asObservable();
  }

  getSurvey$() { return this.survey$.asObservable(); }
  getSurveyAnswers$() { return this.surveyAnswers$.asObservable(); }
  getLanguage$() { return this.language$.asObservable(); }
  getLanguageId$() { return this.languageId$.asObservable(); }
  isLoading() { return this.loading$.asObservable(); }

  formatAnswers(rawAnswers:any) {
    // basically, grab all alternatives
    // then separate them flat_map by sub_alt if any is found
    // this is to transform to what the survey needs
    const conditionals = rawAnswers.reduce((acum:any[], answer:any) => {
      const question_conditionals = answer.childs.map((alt:any) => {
          const conditional_alternative_id = alt.childs && alt.childs[0] ? alt.childs[0].id : alt.id;
          return (
            alt.conditional_questions
              || alt.childs && alt.childs[0] && alt.childs[0].conditional_questions
              || []
            ).map((a:any) => ({...a, conditional_alternative_id}))
        }).reduce((a,b) => [...a, ...b], [])

      return [
        ...acum,
        ...question_conditionals
      ]
    }, [])

    const answers = [...rawAnswers, ...conditionals]
      .reduce((acum:any[], answer:any) => [
        ...acum,
        ...answer.childs.map((alternative:any) => ({
          conditional_alternative_id: answer.conditional_alternative_id,
          answer_text: alternative.text,
          question_id: answer.id,
          answer_alternative_id: alternative.id,
          selected: true,
          sub_alternative_ids: ((alternative.childs || []).length > 0)
            ? alternative.childs.map((sub:any) => sub.id)
            : [null]
        }))
      ], [])
      .reduce((acum:Answer[], answer:any) => [
        ...acum,
        ...(answer.sub_alternative_ids || [null])
          .map((id:number) => ({...answer, sub_alternative_id: id}))
      ], [])

    return SurveyAnswer.createFromApi({answers});
  }
}
