export class StoreCurrentDates {
  static readonly type = '[Date] Store Current Dates';
  constructor(public currentDates: any) {}
}

export class StoreTrendCurrentDates {
  static readonly type = '[Date] Store Trend Current Dates';
  constructor(public trendCurrentDates: any) {}
}

export class StoreCurrentFilter {
  static readonly type = '[Date] Store Current Filter';
  constructor(public currentFilter: any) {}
}

export class StoreTrendCurrentFilter {
  static readonly type = '[Date] Store Trend Current Filter';
  constructor(public trendCurrentFilter: any) {}
}
