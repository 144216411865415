import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'widget-calendar-date',
  templateUrl: './calendar-date.component.html',
  styleUrls: ['./calendar-date.component.scss']
})
export class CalendarDateComponent implements OnInit {

  @Input()
  label:string;

  @Input()
  date:Date;

  @Input()
  type: 'normal' | 'enphasis';

  @Input()
  layoutView: 'list-view' | 'card-view'

  constructor() { }

  ngOnInit() {
  }

  get isCardView() { return this.layoutView === 'card-view'}
  get classCalendar() {
    if(this.layoutView === 'list-view'){
      return 'text-center'
    }
    if(this.layoutView === 'card-view'){
      return 'adjust-card-view'
    }
  }

  get classDateCalendar() {
    if(this.isCardView) {
      return 'text-left';
    } 
  }

}
