import { Component } from '@angular/core';
import { UtilService } from "../../../shared/util.service";
import { SummaryService } from "./summary.service";

@Component({
  selector: 'app-configuration-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['../../../../assets/scss/configuration/summary.scss'],
  providers:[SummaryService]
})
export class ConfigurationSummaryComponent {

  alerts = ["daily", "weekend", "monthly"]

  constructor(private utilService:UtilService) {
    this.utilService.isCustomerChanged().subscribe(hotel => { this.initSummary(); });
  }

  ngAfterContentInit(){
    if(typeof this.utilService.currentHotel !== "undefined"){
      this.initSummary();
    }
  }



  initSummary(){

  }





}