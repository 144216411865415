import { StyleBaseModule } from './../style-base/style-base.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule }            from "@angular/router";
import { ImageCropperModule } from 'ngx-image-cropper';
import {
  FormsModule,
  ReactiveFormsModule
}                                  from '@angular/forms';

import { NgbModule }                from '@ng-bootstrap/ng-bootstrap';


import { HalfPieComponent }         from './pie/half-pie/half-pie.component';
import { PeriodSimpleComponent }      from './period/period-simple/period-simple.component';
import { ArrowVariationComponent }    from './utils/arrow-variation/arrow-variation.component';
import { CardRateComponent }          from './cards/card-rate/card-rate.component';
import { ModalTemplateComponent }     from './modal/modal-template/modal-template.component';
import { ModalFaqComponent }     from './modal/modal-faq/modal-faq.component';
import { PeriodIndicatorComponent }   from './period/period-indicator/period-indicator.component';

import { GuestInfoComponent } from './guest/guest-info/guest-info.component';
import { CountryFlagComponent } from './utils/country-flag/country-flag.component';
import { CircleLettersComponent } from './utils/circle-letters/circle-letters.component';
import { CalendarDateComponent } from './utils/calendar-date/calendar-date.component';
import { SortOptionsComponent } from './utils/sort-options/sort-options.component';
import { ArrowLabelComponent } from './utils/arrow-label/arrow-label.component';
import { GuestStayNightsComponent } from './guest/guest-stay-nights/guest-stay-nights.component';
import { LabelValueSingleComponent } from './utils/label-value-single/label-value-single.component';
import { CardConversionComponent } from './cards/card-conversion/card-conversion.component';
import { TrendsComponent } from './trends/trends.component';
import { FilterChainComponent } from './filters/filter-chain/filter-chain.component';
import { GenericFilterComponent } from './filters/generic-filter/generic-filter.component';

import { TranslateOptionsComponent } from './translate/translate-options.component';

import {
  BoxElementComponent,
  BoxSpinnerComponent,
  BoxScoreComponent,
  WaitTableComponent,
  WaitGraphComponent,
  WaitChainComponent,
  WaitFullComponent,
  HeavyQueryComponent,
  SpinnerClassicComponent,
  SpinnerLoadComponent,
  NotImageComponent,
  UserInitialsComponent,
  UserThumbnailComponent,
  PhotoOrDefaultPipe,
  TablePeriodComponent,
  AddProductComponent,
  SemanticBarComponent,
  MobileNotAvailableComponent,
  CustomerDetailComponent,
  ArrowComponent,
  ExcerptComponent,
  FooterTotalLabelComponent,
  DatePickerElementComponent,
  ChangeImageComponent,
  ChangeLanguageComponent
} from './elements/index';
import { TrendsLegendComponent } from './trends/trends-legend/trends-legend.component';
import { TrendsTableComponent } from './trends/trends-table/trends-table.component';
import { DialogComponent } from './forms/dialog/dialog.component';
import { AccordionTranslateComponent } from './admin/accordion-translate/accordion-translate.component';
import { FormAlertComponent } from './forms/alerts/form-alerts.component';
import {ListOptionsComponent} from './utils/list-options/list-options.component';
import { PeriodFormaterComponent } from './period/period-formater/period-formater.component';
import { PeriodFormaterRankingComponent } from './period/period-formater-ranking/period-formater-ranking.component';
import { TablePeriodsComponent } from './tables/table-periods/table-periods.component';
import { CardRateSummaryComponent } from './cards/card-rate-summary/card-rate-summary.component';
import { CardRateSummaryItemComponent } from './cards/card-rate-summary/card-rate-summary-item/card-rate-summary-item.component';
import { CardNpsComponent } from './cards/card-nps/card-nps.component';
import { CardNpsItemComponent } from './cards/card-nps/card-nps-item/card-nps-item.component';
import { CardGuestCommentComponent } from './cards/card-guest-comment/card-guest-comment.component';
import { SurveysAnswerComponent } from '../../shared/surveys/surveys-answer.component';
import { SemanticDetailComponent } from 'app/shared/semantic';

import { CardSemanticIndicatorComponent } from './cards/card-semantic-indicator/card-semantic-indicator.component';
import { WrapperWaitComponent } from './boxes/wrapper-wait/wrapper-wait.component';
import { CardOtaComponent } from './cards/card-ota/card-ota.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatCardModule } from '@angular/material/card'
import { CardUserThumbnailComponent } from './cards/card-user-thumbnail/card-user-thumbnail.component';
import { GuestIconsComponent } from './utils/guest-icons/guest-icons.component';
import { GuestRateInfoComponent } from './guest/guest-rate-info/guest-rate-info.component'
import { GuestQualifyComponent } from './guest/guest-qualify/guest-qualify.component'
import { GuestSurveyInfoComponent } from './guest/guest-survey-info/guest-survey-info.component'

import { CheckSelectGenericFilterPipe } from 'app/shared/pipe';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    PeriodSimpleComponent,
    HalfPieComponent,
    ArrowVariationComponent,
    CardRateComponent,
    ModalTemplateComponent,
    ModalFaqComponent,
    PeriodIndicatorComponent,


    GuestInfoComponent,
    GuestRateInfoComponent,
    GuestQualifyComponent,
    GuestSurveyInfoComponent,
    CountryFlagComponent,
    CircleLettersComponent,
    CalendarDateComponent,
    SortOptionsComponent,
    ArrowLabelComponent,
    GuestStayNightsComponent,
    LabelValueSingleComponent,
    CardConversionComponent,
    TrendsComponent,
    FilterChainComponent,
    GenericFilterComponent,
    ListOptionsComponent,
    DialogComponent,
    AccordionTranslateComponent,
    FormAlertComponent,

    BoxElementComponent,
    BoxSpinnerComponent,
    BoxScoreComponent,
    WaitTableComponent,
    WaitGraphComponent,
    WaitChainComponent,
    WaitFullComponent,
    HeavyQueryComponent,
    SpinnerClassicComponent,
    SpinnerLoadComponent,
    NotImageComponent,
    UserInitialsComponent,
    UserThumbnailComponent,
    TablePeriodComponent,
    AddProductComponent,
    SemanticBarComponent,
    MobileNotAvailableComponent,
    CustomerDetailComponent,
    ArrowComponent,
    ExcerptComponent,
    FooterTotalLabelComponent,
    DatePickerElementComponent,
    ChangeImageComponent,
    ChangeLanguageComponent,
    TrendsLegendComponent,
    TrendsTableComponent,
    PeriodFormaterComponent,
    PeriodFormaterRankingComponent,
    TablePeriodsComponent,
    CardRateSummaryComponent,
    CardRateSummaryItemComponent,
    CardNpsComponent,
    CardNpsItemComponent,
    CardGuestCommentComponent,
    SurveysAnswerComponent,
    SemanticDetailComponent,
    CardSemanticIndicatorComponent,
    WrapperWaitComponent,
    CardOtaComponent,
    TranslateOptionsComponent,
    CardUserThumbnailComponent,
    PhotoOrDefaultPipe,
    GuestIconsComponent,
    CheckSelectGenericFilterPipe
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    StyleBaseModule,
    RouterModule,
    ImageCropperModule,
    MatCardModule,
    NgxDaterangepickerMd.forRoot({customRangeLabel: 'Personalizado', applyLabel: 'Done', separator: ' - '}),
    MatTooltipModule
  ],
  exports : [
    PeriodSimpleComponent,
    PeriodIndicatorComponent,
    HalfPieComponent,
    CardRateComponent,
    CardConversionComponent,
    ModalTemplateComponent,
    ModalFaqComponent,
    GuestInfoComponent,
    GuestRateInfoComponent,
    GuestQualifyComponent,
    GuestSurveyInfoComponent,
    CountryFlagComponent,
    CalendarDateComponent,
    SortOptionsComponent,
    ArrowLabelComponent,
    GuestStayNightsComponent,
    LabelValueSingleComponent,
    TrendsComponent,
    FilterChainComponent,
    GenericFilterComponent,
    ListOptionsComponent,
    DialogComponent,
    AccordionTranslateComponent,
    FormAlertComponent,
    ArrowVariationComponent,
    PeriodFormaterComponent,
    PeriodFormaterRankingComponent,
    TablePeriodsComponent,
    CardRateSummaryComponent,
    CardRateSummaryItemComponent,
    CardNpsComponent,
    CardGuestCommentComponent,
    SurveysAnswerComponent,
    SemanticDetailComponent,
    CardSemanticIndicatorComponent,
    TranslateOptionsComponent,

    BoxElementComponent,
    BoxSpinnerComponent,
    BoxScoreComponent,
    WaitTableComponent,
    WaitGraphComponent,
    WaitChainComponent,
    WaitFullComponent,
    HeavyQueryComponent,
    SpinnerClassicComponent,
    SpinnerLoadComponent,
    NotImageComponent,
    UserInitialsComponent,
    UserThumbnailComponent,
    TablePeriodComponent,
    AddProductComponent,
    SemanticBarComponent,
    MobileNotAvailableComponent,
    CustomerDetailComponent,
    ArrowComponent,
    ExcerptComponent,
    FooterTotalLabelComponent,
    DatePickerElementComponent,
    ChangeImageComponent,
    ChangeLanguageComponent,

    ImageCropperModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    CardOtaComponent,
    NgxDaterangepickerMd,
    CardUserThumbnailComponent,
    WrapperWaitComponent,
    GuestIconsComponent,
  ]
})
export class WidgetsModule { }
