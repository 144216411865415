import {Component,Input,SimpleChanges,  Output, EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup,FormArray } from '@angular/forms';
import { FormEditor } from '../../../../shared/utils/form-editor';



@Component({
  selector: 'mh-customer-hotel-images-form',
  templateUrl: './hotel-images-form.component.html',
  styleUrls: ['./hotel-images-form.component.scss'],
  providers:[FormEditor]
})
export class CustomerHotelImagesFormComponent{

  @Input()
  parentForm:FormGroup;

  @Input()
  hotelBase;

  @Input()
  viewType;

  @Input()
  hotelDto;

  @Input()
  disabled=false;



  @Output()
  isReady: EventEmitter<string> = new EventEmitter();

  @Output()
  waiting: EventEmitter<boolean> = new EventEmitter();


  constructor(private fb: FormBuilder) {

  }

  ngOnInit(){
    this.isReady.emit("images");
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.hotelDto !== undefined) this.setFormImages();
  }

  setFormImages() {
    let control = <FormArray>this.parentForm.controls.images;
    this.hotelDto.images.forEach(image => {
      control.push(this.fb.group({
        deleted :     image.deleted,
        description : image.description,
        id :          image.id,
        logo :        image.logo,
        path :        image.path,
        url :         image.url,
        raw :         {data:null}
      }))
    })
  }

  get images() {           return <FormArray>this.parentForm.get('images'); }

  updateImage($event, image){
    image.controls['raw'].setValue($event,{emitEvent: true});
  }

  changedImage(state:boolean){
    this.waiting.emit(state);
  }

}
