import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SurveyService } from 'app/features/surveys/surveys.service';
import { PrestayService } from 'app/prestay/prestay.service';
import { ProductId } from '../enums';
import { CheckSetup, CheckSetupChildren } from '../model/check-setup.model';
import { OnlineService } from 'app/online/online.service';
import { UtilService } from '../util.service';

@Component({
  selector: 'mh-modal-check-setup',
  templateUrl: './modal-check-setup.component.html',
  styleUrls: ['./modal-check-setup.component.scss']
})
export class ModalCheckSetupComponent implements OnInit {

  @Input() checkSetup: any
  @Input() survey: any
  @Input() surveys: any
  loading: boolean
  message: string
  messageClass: string

  constructor(
    private prestayService: PrestayService,
    private surveyService: SurveyService,
    private onlineService: OnlineService,
    private utilService: UtilService,
    public modal: NgbActiveModal
  ) { }

  ngOnInit() {

    if (!this.checkSetup) {
      this.loadSetupByCustomerId()
    } else {
      if (this.survey) {
        this.checkSetup = CheckSetupChildren.addTypeToSetup(this.checkSetup, this.survey)
      }
    }
  }

  private async loadSetupByCustomerId() {
    this.loading = true
    try {
      const response: CheckSetup = await this.serviceResponse
      if (response) {
        if (this.surveys) {
          this.checkSetup = CheckSetup.addTypeToSetupChildren(response, this.surveys)
        } else {
          this.checkSetup = response
        }
      } else {
        this.message = "Sin registros por el momento."
        this.messageClass = 'alert alert-warning';
      }
      this.loading = false
    } catch (err) {
      this.loading = false
      this.message = err || "Estamos presentando problemas por el momento."
      this.messageClass = 'alert alert-danger';
    }
  }

  get customerId() { return this.utilService.currentHotel.id; }
  get productName() { return this.utilService.getProductNameFromUrl() }

  get serviceResponse(): any {
    switch (this.productName) {
      case 'PreStay':
        return this.prestayService.getSetupByCustomerId(this.customerId)
      case 'FollowUp':
        return this.surveyService.getSetupByCustomerId(this.customerId, ProductId.FollowUp)
      case 'Online':
        return this.onlineService.getSetupByCustomerId(this.customerId)
      case 'OnSite':
        return this.surveyService.getSetupByCustomerId(this.customerId, ProductId.Onsite)
    }
  }

  processStatusClass(status: string) {
    return {
      'setup-ok': status === 'Ok',
      'setup-warning': status === 'Warning',
      'setup-error': status === 'Error'
    }
  }

}
