import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ActivitiesService } from 'app/shared/cases';
import { UtilService } from 'app/shared/util.service';
import { UsersState } from 'app/states/users/users.state';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { FilesService } from 'app/shared/services/files.service';
import {CustomersState} from 'app/states/customers/customers.state';

@Component({
  selector: 'mh-core-lib-record-case-detail',
  templateUrl: './record-case-detail.component.html',
  styleUrls: ['./record-case-detail.component.scss']
})
export class CoreLibRecordCaseDetailComponent implements OnInit {

  ACTIVITY_COMMENTY_TYPE = {id: 1}

  recordForm;
  waitingFilesUploadStatus: any[] = [];

  mentionState = null;

  @Input()
  caseData;

  @Output()
  submitted = new EventEmitter();

  uploadSubscriptions$: Subscription[] = [];

  @ViewChild('file_input')
  fileInput: ElementRef;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private activitiesService: ActivitiesService,
    private utilService: UtilService,
    private snackbar: MatSnackBar,
    private filesService: FilesService,
  ) {}

  ngOnInit(): void {
    this.setForm()
  }

  ngOnDestroy() {
    this.uploadSubscriptions$.forEach((subs) => {
      subs.unsubscribe();
    });
  }

  setForm() {
    this.recordForm = this.fb.group({
      id: [],
      author_id: [this.utilService.currentUser.id],
      case_id: [this.caseData.id],
      type: [this.ACTIVITY_COMMENTY_TYPE],
      text: ["", Validators.maxLength(2000)],
      attachments: this.fb.array([]),
    })
  }

  handleClickFileInput() {
    this.fileInput.nativeElement.click();
  }

  handleFileInput($event) {
    const files = $event.srcElement.files;
    const fromSingleInput = true;
    this.handleDroppedFile(files, fromSingleInput);
    this.fileInput.nativeElement.value = '';
  }

  handleDroppedFile($event, fromSingleInput = false) {
    if(!fromSingleInput)
      $event.preventDefault();
    // this.isDraggingFile = false;
    const files: any[] = (!fromSingleInput) ?
      [...$event.dataTransfer.files] :
      [...$event];
    files.forEach(file => {
      const size = file['size'];
      const maxSize = parseInt((size / (1024*1024)).toFixed(2));
      if(5 < maxSize) return;

      const obj = {
        name: file.name,
        uploading: true
      };
      this.waitingFilesUploadStatus.push(obj);
      const data: FormData = new FormData();
      data.append('attachment', file);
      this.uploadSubscriptions$.push(
        this.filesService.uploadPrivate(this.currentCustomerId, data)
          .subscribe((response) => {
            if(response && response.name) {
              const fileObj = {
                file_key: response.name,
                file_name: file.name
              };
              this.attachmentsControl.push(this.fb.control(fileObj));
              this.waitingFilesUploadStatus = this.waitingFilesUploadStatus.filter(fileStatus => fileStatus.name !== file.name);
            }
          })
      );
    });
  }

  handleSubmit() {
    this.replaceMentionsBeforeSubmit();
    this.activitiesService.saveComment(this.recordForm.value).subscribe((response) => {
      this.showNotification('snackbar-panel-success', 'Registro creado');
      this.resetForm();
      this.submitted.emit();
      this.attachmentsControl.clear();
    });
  }

  resetForm() {
    this.recordForm.get('text').reset();
    this.recordForm.get('text').setValue('');
    this.recordForm.get('attachments').reset();
  }

  replaceMentionsBeforeSubmit() {
    const value = this.recordForm?.value?.text;
    const matches = value.match(/@\[(.*?)\]/g) || [];
    matches.forEach((match) => {
      const mention = this.mentionState.find((mention) => mention.key === match);
      if (mention) {
        const text = this.recordForm?.value?.text.replace(match, mention.value);
        this.recordForm.get('text').setValue(text);
      }
    });
  }

  showNotification(panelClass, message) {
    const snackbarOptions = <MatSnackBarConfig>{
      duration: 6000,
      panelClass: panelClass,
      verticalPosition: 'top'
    };

    this.openSnackBar(snackbarOptions, message);
  }

  openSnackBar(snackbarOptions, message) {
    this.snackbar.open(message, 'x', snackbarOptions);
  }

  handleRemoveFile(filekey) {
    const index = this.attachments.findIndex(attach => attach.file_key === filekey);
    this.attachmentsControl.removeAt(index);
  }

  setMentionState($event) {
    this.mentionState = $event;
  }

  get currentCustomerId() {
    return this.store.selectSnapshot(CustomersState.currentCustomer).id;
  }

  get attachmentsControl() {
    return this.recordForm.get('attachments') as FormArray;
  }

  get attachments() {
    const control = this.attachmentsControl;
    return control.value;
  }


  get users() {
    return this.store.selectSnapshot(UsersState.currentUsers);
  }

  get isUploadingFile() {
    return this.waitingFilesUploadStatus.some(file => file.uploading);
  }

}
