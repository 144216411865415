<form class="form-guest">
  <mat-form-field [floatLabel]="'never'" #searchForm>
    <mat-icon matPrefix>search</mat-icon>
    <input matInput (keyup.enter)="setSurveyFromInput($event)" [placeholder]="('followup.guests.find_filter' | translate: {guests:(typeTranslate | translate)} ) | lowercase" #search  [matAutocomplete]="auto" [formControl]="stateCtrl">
    <button type="button" mat-button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="onCloseSearch()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete [displayWith]="guestName" #auto="matAutocomplete" (optionSelected)="emitSurvey($event)">
      <mat-option *ngFor="let survey of filteredGuest" [value]="survey" class="info">
        <ng-container *ngTemplateOutlet="guestTemplate;context:{guest: (survey.guest ? survey.guest : survey), survey: survey}"></ng-container>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>

<ng-template #guestTemplate let-guest="guest" let-survey="survey">

  <div class="row no-gutters result_form_guest">
    <div class="col-12">
      <div class="name">{{guest.name}} {{guest.lastName ? guest.lastName : guest.lastname}}</div>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="d-flex w-100">
      <div class="email flex-grow-1">{{guest.email}}</div>
      <div class="country flex-shrink-1 ml-2">{{guest.country ? guest.country.name : guest.country_code}}</div>
    </div>
  </div>

</ng-template>




