import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FilterCurrencyService {

  constructor(private http:HttpClient) { }


  getMoneys(){
    const url=`${environment.customerCurrencies}`;
    return this.http.get(url)
  }
}
