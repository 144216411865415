import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngxs/store';
import { StoreCurrentCustomer, StoreFullCurrentChain, StoreRelativeCurrentChain } from 'app/states/customers/customers.actions';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  //
  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  storeCustomers(customer,userId) {
    const url = `${environment.customerPath}customers/${customer.id}/corporative`;
    const params = new HttpParams().set('user_id', userId);
    const fullRequest = this.http.get(url);
    const relativeRequest = this.http.get(url, {params});
    const requests = {
      full: fullRequest,
      relative: relativeRequest
    };
    forkJoin(requests).subscribe(({full, relative}: {full: any, relative: any}) => {
      const storeFull = full.customers;
      const storeRelative = relative.customers;

      const storeCurrentCustomerAction = new StoreCurrentCustomer(customer);
      const storeFullAction = new StoreFullCurrentChain(storeFull);
      const storeRelativeAction = new StoreRelativeCurrentChain(storeRelative);
      this.store.dispatch(storeCurrentCustomerAction);
      this.store.dispatch(storeFullAction);
      this.store.dispatch(storeRelativeAction);
    });
  }

  getCurrentUserRole(userId) {
    const url = `${environment.customerPath}v3/users/find`;
    const params = new HttpParams().set('user_id', userId);
    return this.http.get(url, { params });
  }
}
