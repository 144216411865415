export class Satisfaction{
  id:number;
  description:string;
  score:number;
  nps:string;
  constructor()
  constructor(id, description, score)
  constructor(id?, description?, score?){
    this.id = id;
    this.description = description;
    this.score = score;
  }
}