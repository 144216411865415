<div class="box row no-gutters align-items-center" *ngIf="isUserPage; else profileConfiguration">
  <div class="offset-md-2 col-md-8 offset-xl-3 col-xl-6">
    <mh-box-element [title]="'configuration.profile.title' | translate">
      <div class="box_initials">
        <mh-user-thumbnail [user]="userProfile" [size]="200"></mh-user-thumbnail>
        <div class="user_info">
          <div class="name">
            {{ userProfile.profile.name }} <span>{{ userProfile.profile.last_name }}</span>
          </div>
          <div class="username">{{ userProfile.username }}</div>
          <ul class="other_info">
            <li>
              <label>{{ 'forms.e-mail' | translate }}</label>
              <div class="info">{{ userProfile.profile.email }}</div>
            </li>
            <li>
              <label>{{ 'forms.job_position' | translate }}</label>
              <div class="info">{{ userProfile.profile.company_position }}</div>
            </li>
            <li>
              <label>{{ 'forms.phone' | translate }}</label>
              <div class="info">{{ userProfile.profile.phone }}</div>
            </li>
            <li>
              <label>{{ 'forms.language' | translate }}</label>
              <div class="info">{{ 'languages.id.' + userProfile.profile.language.id | translate }}</div>
            </li>
          </ul>
        </div>

        <div class="actions">
          <mh-core-lib-button
            class="tw-mx-6"
            [isDefault]="true"
            [text]="'commons.edit' | translate"
            (click)="editUser()"
          ></mh-core-lib-button>
          <mh-edit-user-password [user]="currentProfile" [style]="'btn-action btn-orange'"></mh-edit-user-password>
        </div>
      </div>
    </mh-box-element>
  </div>
</div>

<ng-template #profileConfiguration>
  <mh-profile-configuration
    [customer]="customer"
    [user]="currentProfile"
    [type]="'summaries'"
    [icon]="'inbox'"
    [title]="'configuration.users.modal.summaries.title_top'"
    [style]="'link-icon'"
    [currentProfile]="currentProfile"
    [is_super_admin]="is_super_admin"
    [is_admin]="is_admin"
    [userIds]="userIds"
    [isGlobal]="isGlobal"
    [setupType]="setupType"
  >
  </mh-profile-configuration>
</ng-template>
