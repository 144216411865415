export class Money {
  code: string;
  value: number;
  description:string;
  symbol = '$';

  constructor(code, value, description){
    this.code = code;
    this.value = value;
    this.description = description;
  }
}
