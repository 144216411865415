import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalService } from './confirmation-modal.service'
import { FormControl } from '@angular/forms';

@Component({
  selector: 'mh-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  private _translatePath:string;
  private modal:any
  private serviceSubscriptions: Subscription[] = [];

  acceptTransaltion = 'commons.yes';
  cancelTranslation = 'commons.no';

  @ViewChild('modal', {static: true}) templateModal:TemplateRef<any>;

  data = [];
  dataControl = new FormControl();

  constructor(
    private confirmationModalService:ConfirmationModalService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.initListeners();
  }

  ngOnDestroy() {
    this.serviceSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  public cancel = () => { this.close(false); }
  public accept = () => { this.close(true); }

  public close(confirm:boolean) {
    this.dataControl.reset(null, { emitEvent: false });
    this.confirmationModalService
      .closeModalSubject.next(confirm);
    this.modal.close(confirm)
  }

  private open({ translatePath, data }) {
    this._translatePath = translatePath;
    this.dataControl.reset();
    this.confirmationModalService.usersIdsToSubscribe = [];
    this.data = data;
    this.acceptTransaltion = (this.data && this.data.length > 0) ?
      'cases.commons.subscribe_users' :
      'commons.yes';
    this.cancelTranslation = (this.data && this.data.length > 0) ?
      'forms.cancel' :
      'commons.no';
    this.modal = this.modalService.open(this.templateModal);
    this.modal.result.then(
      null, () => this.confirmationModalService.closeModalSubject.next(false)
    )
  }

  get translatePath() {return this._translatePath;}

  initListeners() {
    this.serviceSubscriptions = [
      this.confirmationModalService.openModalSubject
        .subscribe(this.open.bind(this)),
      this.dataControl.valueChanges
        .subscribe((data: number[]) =>
          this.confirmationModalService.usersIdsToSubscribe = data),
    ];
  }
}
