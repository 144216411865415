import { Component, Inject, OnInit } from '@angular/core';
import { CoreLibModalComponent } from '../modal/modal.component';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

@Component({
  selector: 'mh-response-reply-modal',
  templateUrl: './response-reply-modal.component.html',
  styleUrls: ['./response-reply-modal.component.scss'],
})
export class CoreLibResponseReplyModalComponent extends CoreLibModalComponent implements OnInit {
  public review;
  public customer;
  public title;
  public subtitle;
  public image;
  public body;
  public type;
  public date;

  constructor(protected dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: any, private router: Router) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.review = this.data?.review;
    this.customer = this.data?.customer;
    this.title = this.data?.title;
    this.subtitle = this.data?.subtitle;
    this.image = this.data?.image;
    this.body = this.data?.body;
    this.type = this.data?.type;
    this.date = DateTime.now().toFormat('dd/LL/yyyy - HH:mm');
  }

  goToIntegrations() {
    const ota = this.review?.ota.name === 'Booking.com' ? 'booking' : this.review?.ota.name.toLowerCase();
    this.router.navigate([`/online/integraciones/${ota}`]);
    this.close();
  }

  goToBooking() {
    window.open('https://www.booking.com', '_blank');
  }
}
