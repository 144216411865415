<div *ngIf="productType; else noItem" class="product-wrapper">
  <ng-container *ngIf="item else loading">
    <a target="_blank"
      [routerLink]="productLink"
      [queryParams]="productParams"
    >
      <div class="product-link"></div>
    </a>
    <div [ngSwitch]="productType" class="white-bg">
      <mh-survey-summary *ngSwitchCase="FOLLOWUP_SURVEY"
        [survey]="survey || item"
        [productId]="'productId'"
        [viewType]="'guest'"></mh-survey-summary>
      <mh-survey-summary *ngSwitchCase="ONSITE_SURVEY"
        [survey]="survey || item"
        [productId]="'productId'"
        [viewType]="'guest'"></mh-survey-summary>
      <mh-review *ngSwitchCase="ONLINE_REVIEW"
        [review]="item"
        [customer]="customer"
        [hotelDto]="hotelDto"
      ></mh-review>
      <mh-prestay-precheckin-guest *ngSwitchCase="PRESTAY_PRECHECKIN"
        [guest]="item"
      ></mh-prestay-precheckin-guest>
      <mh-prestay-order-guest *ngSwitchCase="PRESTAY_ORDER"
        [item]="item"
        [keys]="PRESTAY_ORDER_KEYS"
        [money]="money"
        [viewType]="'orders'"
      ></mh-prestay-order-guest>
      <div *ngSwitchDefault>{{'cases.messages.product_error' | translate}}</div>
    </div>
  </ng-container>
</div>

<ng-template #noItem>
  <mat-card class="align-center">
    <strong>{{'cases.commons.no_product_related' | translate}}</strong>
  </mat-card>
</ng-template>

<ng-template #loading>
  <div class="whit-bg">{{'waiting.messages.waiting' | translate}}</div>
</ng-template>
