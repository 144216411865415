<article class="tw-grid tw-place-content-center">
  <div class="tw-relative">
    <div class="progress_box tw-flex tw-justify-center tw-items-center">
      <div class="tw-absolute tw-z-10 tw-grid tw-text-center">
        <ng-container *ngIf="isExpired; else noExpiredTemplate">
          <span [mhTooltip]="'cases.table_view.due_on' | translate: { date: dueDateText, time: dueTimeText }" position="above"><i class="far fa-alarm-exclamation tw-text-gray-500"></i></span>
        </ng-container>

        <ng-template #noExpiredTemplate>
          <ng-container *ngIf="time?.time_type?.type_time === 'DATE'; else noDateTemplate">
            <span [mhTooltip]="time?.little_text_remaining" position="above"><i class="far fa-calendar tw-text-gray-500"></i></span>
          </ng-container>

          <ng-template #noDateTemplate>
            <div class="tw-flex tw-flex-col" [mhTooltip]="time?.time_type ? ('cases.table_view.due_date_and_time' | translate: { date: dueDateText, time: dueTimeText }) : ''" position="above">
              <span class="tw-text-base tw-leading-none">{{ time?.little_text_remaining.split(' ')[0] || '-' }}</span>
              <span *ngIf="time?.time_type" class="tw-text-2xs tw-leading-none">{{ time?.little_text_remaining.split(' ')[1] }}</span>
            </div>
          </ng-template>
        </ng-template>
      </div>
      <mat-progress-spinner
        class="graph graph_box -tw-scale-x-100 {{ time?.remaining_color }}"
        [strokeWidth]="3"
        [diameter]="'55'"
        [mode]="'determinate'"
        [value]="remainingPercentage"
      >
      </mat-progress-spinner>
      <mat-progress-spinner
        class="graph total_graph"
        [strokeWidth]="3"
        [diameter]="'55'"
        [mode]="'determinate'"
        [value]="100"
      >
      </mat-progress-spinner>
    </div>
  </div>
</article>