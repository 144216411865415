import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from "../../../environments/environment";
import { map } from 'rxjs/operators';
import { Question } from "../../shared/model/index";

@Injectable()
export class SurveyService {

  constructor(private http:HttpClient) { }

  public getSurveyService(dates:any,customerId:string,languageId, productId:string,surveysIds){
    const url=`${environment.followupPath}surveys/general/satisfactions/by_question`;
    const params:HttpParams = new HttpParams()
      .set("start_date",dates.initDate)
      .set("end_date",dates.endDate)
      .set("customer_id",customerId)
      .set("language_id",languageId)
      .set("product_id", productId)
      .set("survey_id", surveysIds);

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }

  public getSurveyComments(dates:any,customerId:string, productId:string, languageId,surveysIds){
    const url=`${environment.followupPath}summary/surveys/comments/feeds_survey`;
    const params:HttpParams = new HttpParams()
      .set("start_date",dates.initDate)
      .set("end_date",dates.endDate)
      .set("customer_id",customerId)
      .set("product_id", productId)
      .set("language_id", languageId)
      .set("surveys_ids", surveysIds);
    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }

  public getSurvey(answeredId, productId:string, surveyId:number) {
    const url = `${environment.followupPath}surveys/answereds/${answeredId}`;
    const params: HttpParams = new HttpParams()
      .set("product_id", productId);
    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }

  fillQuestion(data:any): Question[]{
    const allQuestions: Question[] = [];
    for(let item_question of data.survey.questions){
      let question = new Question(item_question.id,item_question.order,item_question.statement,item_question.sort);
      question.createFromApi(item_question, data.guest);
      allQuestions.push(question);
    }
    allQuestions.sort(function(a,b) {return (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0);} );
    return allQuestions;
  }
}
