import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mh-core-lib-prestay-case',
  templateUrl: './prestay-case.component.html',
  styleUrls: ['./prestay-case.component.scss']
})
export class CoreLibPrestayCaseComponent implements OnInit {

  @Input('prestay')
  item;

  @Input()
  source: 'detail' | 'create' = 'create';

  @Input()
  customerId = '';

  constructor() { }

  ngOnInit(): void {
  }

  get isCreate() {
    return this.source === 'create';
  }

  get url() {
    const date = this.item.guest.checkin.split(' ')[0];
    return `/prestay/precheckin?guest=${this.item.guest.id}&customer=${this.customerId}&date=${date}`
  }

}
