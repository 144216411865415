import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'widget-card-semantic-indicator',
  templateUrl: './card-semantic-indicator.component.html',
  styleUrls: ['./card-semantic-indicator.component.scss']
})
export class CardSemanticIndicatorComponent implements OnInit {

  @Input()
  title = '';

  @Input()
  positives = 0;

  @Input()
  negatives = 0;

  @Input()
  waiting = false;

  private neutralValue = 0;

  constructor() { }

  ngOnInit() {
  }


  get neutrals(){
    return Math.abs(100 - (this.positives + this.negatives));
  }

  get stylePositive(){
    return {
      width: `${this.calculatePercent(this.positives)}%`
    }
  }

  get styleNegative(){
    return {
      width: `${this.calculatePercent(this.negatives)}%`
    }
  }

  get styleNeutral(){
    return {
      width: `${this.showNeutral ? this.neutrals : 0}%`
    }
  }

  get delta(){
    return this.showNeutral ? 0 : 100*this.neutralValue;
  }

  get showNeutral(){
    return this.neutralValue !== 0;
  }

  setNeutralValue(action:'in' | 'out'){
    this.neutralValue = action === 'in' ? 1 : 0;
  }

  calculatePercent(value){
    if(this.showNeutral) return value;
    return this.neutrals/2 + value;
  }

}
