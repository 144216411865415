import { Component, Input, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { animation } from 'app/shared/utils/animation-custom';

@Component({
  selector: 'mh-core-lib-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  animations: [animation.rotate],
})
export class CoreLibSelectInputComponent implements OnInit {
  #_options = [];
  #_placeholder = '';
  inputControl = new FormControl('');
  displayValue = '';

  @Input()
  openBox = false;

  @Input()
  nestedControl = null;

  @Input()
  nestedTypeOption = '';

  @Input()
  iconClass = 'far fa-angle-down';

  @Input()
  displayFn;

  @Input()
  customClasses;

  @Input()
  formGroup: FormGroup;

  @Input()
  keyControl;

  @Input()
  selectedOption = null;

  @Input() set options(options) {
    this.#_options = options;
  }

  @Input() set placeholder(placeholder) {
    this.#_placeholder = placeholder;
  }

  @Input()
  inputId = '';

  @Input()
  disabled = false;

  @Output()
  optionChanged = new EventEmitter();

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const result = this.checkClickInside(event.target);
    if (!result) {
      this.openBox = false;
    }
  }

  ngOnInit(): void {
    if (this.selectedOption) {
      this.selectOption(this.selectedOption, false);
    }
  }

  toggleBox() {
    if (!this.disabled) {
      this.openBox = !this.openBox;
    }
  }

  selectOption(option, emit = true) {
    if (this.nestedControl) {
      this.formGroup.get(this.nestedControl).get(this.keyControl).setValue(option[this.nestedTypeOption]);
    } else {
      this.formGroup.get(this.keyControl).setValue(option);
    }
    this.displayValue = this.displayFn(option);
    this.#_placeholder = this.displayFn(option);
    this.openBox = false;
    if (emit) {
      this.optionChanged.emit(option);
    }
  }

  checkClickInside(target) {
    const idsToCheck = ['optionsMenu', this.inputId];
    const classesToCheck = ['select-option'];
    if (idsToCheck.some((id) => id === target.id) || classesToCheck.some((clas) => target.className?.includes(clas))) {
      return true;
    } else if (target.parentNode) {
      return this.checkClickInside(target.parentNode);
    } else {
      return false;
    }
  }

  get options() {
    return this.#_options;
  }

  get placeholder() {
    return this.#_placeholder;
  }
}
