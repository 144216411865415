<section class="md:tw-flex tw-justify-evenly">
  <article class="tw-flex tw-flex-col md:tw-justify-center md:tw-basis-3/5">
    <small class="tw-text-sm tw-font-medium">{{detail.order}} {{detail.question_translates | translateObject}}</small>
    <small class="tw-text-main-text tw-font-medium tw-text-sm">{{detail.sub_question_translates | translateObject}}</small>
  </article>

  <article class="tw-flex tw-shadow-2xl tw-rounded-lg tw-mt-3 tw-mb-5 md:tw-min-w-[270px] tw-min-h-[84px]">
    <div class="tw-min-w-[10px] tw-rounded-tl-lg tw-rounded-bl-lg {{'type_'+(detail.qualification?.id || 0)}}"></div>
    <section class="tw-flex tw-flex-col tw-py-2 tw-ml-1 tw-grow" [class.tw-justify-center]="detail.answers < 1">
      <small *ngIf="detail.qualification" class="tw-font-medium tw-text-small tw-self-center tw-p-2 {{'type_'+(detail.qualification?.id || 0)}} title">{{('qualifys.type_'+detail.qualification?.id  | translate | titlecase)}}</small>
      <section class="tw-flex tw-justify-center">
        <span class="tw-font-semibold tw-text-main-text tw-text-2xl tw-self-start">
          {{detail.answers}}
        </span>
        <span class="tw-text-sm tw-text-main-text tw-self-start tw-ml-1">{{'commons.answers' | translate}}</span>
        <div class="tw-border tw-border-main-font tw-h-5 tw-mx-2 tw-self-center"></div>
        <span class="tw-font-medium tw-text-main-text tw-text-2xl tw-self-center">
          {{detail.score}}
        </span>
        <span class="tw-text-sm tw-text-main-text tw-self-start tw-ml-1">{{'commons.rating' | translate}}</span>
      </section>
    </section>
  </article>
</section>
