import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EditProductService } from "../../edit-product.service";
import { User,Hotel } from "../../../../shared/model/index";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'app/shared/util.service';
import { FormBuilder,  FormArray, FormControl, UntypedFormGroup } from '@angular/forms';


@Component({
  selector: 'mh-edit-product-alerts',
  templateUrl: './edit-product-alerts.component.html',
  styleUrls: ['../../../../../assets/scss/configuration/product-edit.scss'],
  providers:[EditProductService]
})
export class EditProductAlerts{
  @Input()
  customer:Hotel;

  @Input()
  user:User;

  @Input()
  product:string;

  @Output() waitingState = new EventEmitter<boolean>();

  waiting:boolean=false;
  confirmationModal: any;
  productAlerts: any;
  alertsForm: any

  newAlerts = {
    active: true,
    detractor: false,
    passive: false,
    promoter: false
  }
  
  currentMassiveAlert: any;
  currentMassiveValue: any;
  disabledToggles = false;

  constructor(
    private editProductService:EditProductService,
    private modalService: NgbModal,
    private utilService: UtilService,
    private fb: FormBuilder
  ){}

  ngOnChanges() {
    switch(this.product) {
      case 'online':
        this.getOtasAlerts();
        break;
      default:
        this.getSurveysAlerts();
        break;
    }
  }

  getSurveysAlerts() {
    this.waiting = true;
    this.editProductService.getSurveyAlerts(this.customer.id, this.user.id, this.productId).subscribe(response => {
      this.waiting = false;
      this.waitingState.emit(this.waiting);
      if(response) {
        this.productAlerts = response;
        this.setForm();
        this.setAlertsToggles();
      }
    })
  }

  getOtasAlerts() {
    this.waiting = true;
    this.editProductService.getOtasAlerts(this.customer.id, this.user.id).subscribe(response => {
      this.waiting = false;
      this.waitingState.emit(this.waiting);
      if(response) {
        this.productAlerts = response;
        this.setForm();
        this.setAlertsToggles();
      }
    })

  }

  async editAlert(key, value, index) {
    this.disabledToggles = true;
    const control = this.getFormControl(index);
    control!.get(key)!.patchValue(value);
    if(key === 'active') this.changeTogglesByActiveStatus(control, value);
    const response = await this.editAlerts();
    if(response) this.disabledToggles = false;
    this.setAlertsToggles();
  }

  changeTogglesByActiveStatus(control, value) {
    const keys = ['detractor', 'promoter', 'passive'];
    keys.forEach(key => {
      control.get(key).patchValue(value);
    });
  }

  async editAlerts() {
    return (!this.isOnline) ? await this.editProductService.editSurveyAlerts(this.customer.id, this.user.id, this.productId, this.surveys.value).toPromise() : await this.editProductService.editOtasAlerts(this.customer.id, this.user.id, this.otas.value).toPromise();
  }

  setAlertsToggles() {
    const productAlerts = !this.isOnline ? this.surveys.value : this.otas.value;
    this.newAlerts.active = productAlerts.every(alert => alert.active);
    this.newAlerts.detractor = productAlerts.every(alert => alert.detractor) && this.newAlerts.active;
    this.newAlerts.passive = productAlerts.every(alert => alert.passive) && this.newAlerts.active;
    this.newAlerts.promoter = productAlerts.every(alert => alert.promoter) && this.newAlerts.active;
  }

  setForm() {
    this.alertsForm = this.fb.group({
      surveys: this.fb.array([]),
      otas: this.fb.array([])
    })
    this.fillForm();
  }

  fillForm() {
    this.productAlerts.forEach(item => {
      const obj = new UntypedFormGroup({
        active: new FormControl(item.active),
        detractor: new FormControl(item.detractor),
        passive: new FormControl(item.passive),
        promoter: new FormControl(item.promoter),
        customer_id: new FormControl(item.customer_id),
        user_id: new FormControl(item.user_id),
      });
      if (!this.isOnline) obj.addControl('survey', new FormControl(item.survey));
      else obj.addControl('ota', new FormControl(item.ota));
      if (!this.isOnline) this.surveys.push(obj);
      else this.otas.push(obj);
    })
  }

  openConfirmationModal(confirmationModal, type, value, e) {
    this.currentMassiveAlert = type;
    this.currentMassiveValue = value;
    this.confirmationModal = this.modalService.open(confirmationModal, {
    })

    this.confirmationModal.result.then(action => {
      if(!action) e.source.checked = !value;
    }, (reason) => {
      e.source.checked = !value;
    })
  }

  syncMassiveToggles(type, value) {
    if(type === 'active') {
      const form = (!this.isOnline) ? this.surveys : this.otas;
      form.controls.forEach(control => {
        control!.get('active')!.patchValue(value)
        this.changeTogglesByActiveStatus(control, value)
      })
    }
  }

  async handleConfirmationModalAction(action) {
    if(action) {
      this.disabledToggles = true;
      this.syncMassiveToggles(this.currentMassiveAlert, action);
      this.newAlerts[this.currentMassiveAlert] = this.currentMassiveValue;
      const form = (!this.isOnline) ? this.surveys : this.otas;
      form.controls.forEach((control, index) => {
        const field = this.getFormControl(index);
        const active = field!.get('active')!.value;
        if(active) field!.get(this.currentMassiveAlert)!.patchValue(this.currentMassiveValue);
      })
      this.setAlertsToggles();
      const response = await this.editAlerts();
      if(response) this.disabledToggles = false;
      this.confirmationModal.close(true);
    } else {
      this.newAlerts[this.currentMassiveAlert] = !this.currentMassiveValue;
      this.confirmationModal.close(false);
    }

  }

  get language() {
    return this.utilService.getCurrentLanguage();
  }

  get productId() {
    return (this.product === 'followup') ? 1 : 2;
  }

  get surveys() {
    return this.alertsForm.get('surveys') as FormArray;
  }

  get otas() {
    return this.alertsForm.get('otas') as FormArray;
  }

  get currentForm() {
    return !this.isOnline ? this.surveys : this.otas;
  }

  get isOnline() {
    return this.product === 'online';
  }

  get arrayName () {
    return !this.isOnline ? 'surveys' : 'otas';
  }

  getFormControl(index) {
    const control = (!this.isOnline) ? this.surveys : this.otas;
    return control.at(index);
  }

}
