<h5 class="edit_title" [innerHtml]="('configuration.users.modal.alerts.title' | translate:{'product': product})"></h5>
<div>
    <ng-container *ngTemplateOutlet="newList"></ng-container>
</div>

<ng-template #newList>
  <ng-container *ngIf="alertsForm">
    <div class="row no-gutters">
      <div class="col-4">&nbsp;</div>

      <div class="col-2 d-flex flex-column align-items-center">
        <div>{{'alerts.status_alert' | translate}}</div>
        <div class="w-100">
          <div class="general-status-alert">
            <mat-slide-toggle [disabled]="disabledToggles" [checked]="newAlerts.active" (change)="openConfirmationModal(confirmationModal, 'active', $event.checked, $event)"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="col-2 d-flex flex-column align-items-center">
        <div>{{'alerts.detractor' | translate}}</div>
        <div class="w-100">
          <div class="general-status-alert">
            <mat-slide-toggle [disabled]="disabledToggles" [checked]="newAlerts.detractor" (change)="openConfirmationModal(confirmationModal, 'detractor', $event.checked, $event)"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="col-2 d-flex flex-column align-items-center">
        <div>{{'alerts.passive' | translate}}</div>
        <div class="w-100">
          <div class="general-status-alert">
            <mat-slide-toggle [disabled]="disabledToggles" [checked]="newAlerts.passive" (click)="openConfirmationModal(confirmationModal, 'passive', !newAlerts.passive, $event)"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="col-2 d-flex flex-column align-items-center">
        <div>{{'alerts.promoter' | translate}}</div>
        <div class="w-100">
          <div class="general-status-alert">
            <mat-slide-toggle [disabled]="disabledToggles" [checked]="newAlerts.promoter" (click)="openConfirmationModal(confirmationModal, 'promoter', !newAlerts.promoter, $event)"></mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>

    <ng-container [formGroup]="alertsForm">
      <ng-container [formArrayName]="arrayName">
        <div class="row mt-3 mb-3 no-gutters survey" [class.p-1]="isOnline" *ngFor="let control of currentForm.controls; index as i">
          <div class="col-4">
            <div class="survey-name ml-3">
              <ng-container *ngIf="!isOnline">
                <span *ngIf="control.value.survey.version !== 1"mhFindTranslate [translates]="control.value.survey.translates" [keyTranlsate]="'title'" [language]="language"></span>
                <span *ngIf="control.value.survey.version === 1">
                  {{control.value.survey.type.type}}
                </span>
              </ng-container>
              <ng-container *ngIf="isOnline">
                <div class="d-flex">
                  <img [src]="('https://statics.myhotel.io/logos/otas/square/'+control.value.ota.id+'.png')" alt="" class="ota-logo mr-3" [class.desactive]="!control.value.ota.active">
                  <div class="d-flex align-items-center">
                    {{control.value.ota.name}}
                  </div>
                </div>

              </ng-container>
            </div>
          </div>
          <div class="col-2 d-flex flex-column align-items-center justify-content-center">
            <div class="general-status-alert w-100 h-100">
              <mat-slide-toggle [disabled]="disabledToggles" [checked]="control.value.active" (change)="editAlert('active', $event.checked, i)"></mat-slide-toggle>
            </div>
          </div>
          <div class="col-2 d-flex flex-column align-items-center justify-content-center">
            <div class="general-status-alert h-100 w-100">
              <mat-slide-toggle *ngIf="control.value.active" [disabled]="disabledToggles" [checked]="control.value.detractor" (change)="editAlert('detractor', $event.checked, i)"></mat-slide-toggle>
            </div>
          </div>
          <div class="col-2 d-flex flex-column align-items-center justify-content-center">
            <div class="general-status-alert w-100 h-100">
              <mat-slide-toggle *ngIf="control.value.active" [disabled]="disabledToggles" [checked]="control.value.passive" (change)="editAlert('passive', $event.checked, i)"></mat-slide-toggle>
            </div>
          </div>
          <div class="col-2 d-flex flex-column align-items-center justify-content-center">
            <div class="general-status-alert h-100 w-100">
              <mat-slide-toggle *ngIf="control.value.active" [disabled]="disabledToggles" [checked]="control.value.promoter" (change)="editAlert('promoter', $event.checked, i)"></mat-slide-toggle>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #confirmationModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    {{'commons.save_changes' | translate}}
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-center">
            <span class="confirmation-msg">
              {{'alerts.confirmation' | alertsTranslate:currentMassiveAlert | async}}
            </span>
          </div>
        </div>
        <div class="col-6">
          <button class="btn btn-primary" (click)="handleConfirmationModalAction(true)">{{'forms.accept' | translate}}</button>
        </div>
        <div class="col-6">
          <button class="btn btn-secondary" (click)="handleConfirmationModalAction(false)">{{'forms.cancel' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
