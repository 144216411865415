<mh-alerts-wrapper>

  <section class="flex flex-row justify-between">
    <div>
      <mh-core-lib-datepicker
        [currentDates]="currentDates"
        [currentFilter]="currentFilterDate"
        (datepickerUpdated)="handleDatepickerUpdated($event)">
      </mh-core-lib-datepicker>
    </div>
  </section>
  <mh-box-element [title]="('inter-chain.prestay.trends.title' | translate )" *ngIf="fidelityPermisions.includes('interchain_prestay_trends_result')">
    <div class="row align-items-center">
      <div class="col-8">
        <widget-filter-chain-zone [productId]="productId" (chainChanged)="setChain($event)" [disabled]="!isReady"></widget-filter-chain-zone>
      </div>
      <div class="col-4 align-right">
        <widget-modal-faq
          [faqs]="faqs"
          [prefixTranslate]="prefixTranslateFAQ"
        ></widget-modal-faq>
      </div>
    </div>
    <mh-chain-prestay-trends [currentDates]="currentDates" [chain]="chainFilter" (isReady)="dataIsReady($event)"></mh-chain-prestay-trends>
  </mh-box-element>

</mh-alerts-wrapper>
