import { Component, Input } from '@angular/core';
import { UserProfile } from 'app/shared/model';

@Component({
  selector: 'mh-user-initials',
  templateUrl: './user-initials.component.html',
  styleUrls: ['../../../../../../assets/scss/shared/elements/user-initials.scss'],
})
export class UserInitialsComponent{
  @Input()
  user:UserProfile;
  @Input()
  satisfactionType:string;
  @Input()
  size:number=50;
  constructor(){}
}
