import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fidelityDeskProduct'
})
export class FidelityDeskAreasPipe implements PipeTransform {

  transform(cases, filters) {
    let filteredCases = [ ...cases ];
    if(filters) {
      const filter = filters.find(filter => filter.name === 'areas');
      const filtering = filter.options.some(option => option.isFiltering);
      if(filter && filtering) {
        const options = filter.options.filter(option => !!option.isFiltering).map(option => option.id?.toString() || 'null');
        filteredCases = cases.filter(item => {
          const value = item.related_areas.map(area => area.area_entity.id);
          return value.some(val => options.includes(val.toString()))
        })
      }
    }
    return filteredCases;
  }

}
