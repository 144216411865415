<div class="tw-p-1 tw-border-b-2 tw-border-main">
  <i class="fal fa-search tw-text-main tw-mr-2 tw-cursor-pointer" (click)="updateValue()"></i>
  <input class="tw-bg-inherit tw-outline-0 tw-text-main-font placeholder:tw-text-main"
        placeholder="{{placeholder}}"
        [value]="value"
        [disabled]="isDisabled"
        [style.width.px]="widthPx"
        (input)="onInput($event.target.value)"
        (keyup.enter)="updateValue()"/>
  <i class="far fa-times tw-text-main tw-cursor-pointer" *ngIf="value.length > 0" (click)="onInput(''); updateValue()"></i>
</div>
