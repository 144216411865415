<section id="filter_search" [class.showFilters]="showFilters">
  <h3 class="title" (click)="toggleFilters()" ><mat-icon class="inline">filter_list</mat-icon><span class="inline">{{'shared.filters.search.title' | translate:({count:count}) }}</span></h3>

  <div class="row no-gutters">
    <div class="filter col" *ngFor="let filter of filterOptions">

        <div class="name">
          {{"shared.filters.names."+filter.slug+".title" | translate }}:
        </div>

        <div class="form-check" *ngFor="let option of filter.options">

          <mat-checkbox
            [value]="option.value"
            (change)="addFilter()"
            [(ngModel)]="option.checked">
            <span>{{"shared.filters.names."+filter.slug+"."+option.value | translate }}</span>
          </mat-checkbox>


        </div>


        <div class="form-check">
          <mat-checkbox
            [value]="filter.slug"
            (change)="groupFilter(filter)"
            [(ngModel)]="filter.checked">
            <span class="all">{{"shared.filters.names."+filter.slug+".all" | translate }}</span>
          </mat-checkbox>
        </div>

    </div>
  </div>
</section>