import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Ota } from 'app/new-fs/online/otas/models/Ota.model';
@Injectable({
  providedIn: 'root',
})
export class OtasService {
  constructor(private http: HttpClient) {}

  public findOtas(dates: any, customerId: string) {
    const url = `${environment.onlineOtasUrl}`;
    const params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customer_id', customerId);
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public findHistoricOtas(dates: any, customerId: string, segmentedBy = 'month') {
    const url = `${environment.onlineOtasHistoricUrl}`;
    const params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customer_id', customerId)
      .set('segmented_by', segmentedBy);
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public getOtasList() {
    const url = `${environment.onlineOtasActive}`;
    const params: HttpParams = new HttpParams();
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public getOtasByClient(customerId) {
    const url = `${environment.onlinePath}otas/customer/${customerId}`;
    const params: HttpParams = new HttpParams();
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public findHotel(id) {
    const url = `${environment.customerById}` + id;
    const params: HttpParams = new HttpParams();
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public getOtasByConfigClient(customerId): Observable<Ota[]> {
    const url = `${environment.onlinePath}customers/${customerId}/otas`;
    const params: HttpParams = new HttpParams();
    return this.http.get<Ota[]>(url, { params: params }).pipe(map((response) => response));
  }

  launchScraper(customerId, otaId) {
    const url = `${environment.onlinePath}customers/${customerId}/otas/${otaId}/launch-scraper`;
    return this.http.post(url, {});
  }
}
