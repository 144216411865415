import { Component, Input } from '@angular/core';
import { UtilService } from "../../../shared/util.service";
import { ProfileService } from "../profile.service";
import {User, Hotel, SuperAdminProperties} from "../../../shared/model/index";

@Component({
  selector: 'profile-superadmin-properties',
  templateUrl: './super-admin-properties.component.html',
  styleUrls: ['../../../../assets/scss/configuration/profile.scss'],
  providers:[ProfileService]
})
export class SuperAdminPropertiesComponent {
  @Input()
  currentProfile: User;

  superAdminWaiting:boolean = true;
  hotelDemo:Hotel;
  superAdminProperties:SuperAdminProperties;
  users:User[];
  waitingUsers:boolean=true;
  selectedUserId:number;
  saveDataSuperAdmin:boolean = false;
  liveDemo:boolean;


  constructor(
    private utilService:UtilService,
    private profileService:ProfileService
  ) {
    this.utilService.isLoadCustomerReady().subscribe(x=>this.initSuperAdmin())
  }

  ngOnChanges() {
    if(this.currentProfile){
      this.initSuperAdmin();
    }
  }

  ngOnInit() {
    if(this.currentProfile){
      this.initSuperAdmin();
    }
  }

  initSuperAdmin(){
    this.superAdminProperties = this.currentProfile.superAdminProperties
    this.liveDemo = this.superAdminProperties.live_demo;
    if(this.currentProfile.isSuperAdmin()) this.getSuperAdminProperties();
  }


  updateCustomerDemo(hotel:Hotel){
    this.hotelDemo = hotel;
    this.superAdminProperties.id_fake_customer = this.hotelDemo.id;
    this.superAdminProperties.live_demo = false
    this.getUsersInCustomer(this.hotelDemo.id);
  }

  getSuperAdminProperties(){
    let hotel = this.utilService.getHotel(this.superAdminProperties.id_fake_customer);
    if(hotel){
      this.hotelDemo = hotel;
      this.getUsersInCustomer(this.hotelDemo.id)

    }
    this.superAdminWaiting = false;
  }

  getUsersInCustomer(customerId){
    this.waitingUsers = true;
    this.profileService.usersInCustomer(customerId).subscribe((response:any) => {
      this.users = response.map(x=>(new User()).createFromApi(x))
      this.users.sort(function(a,b) {return (a.customers.length < b.customers.length) ? 1 : ((b.customers.length < a.customers.length) ? -1 : 0);} );
      this.users = this.users.filter(x=>x.admin)
      let fakeUser = this.users.find(x=>x.id===this.superAdminProperties.id_fake_user);
      if(fakeUser){
        this.setFakeUser(fakeUser);
      }else{
        if(this.users) this.setFakeUser(this.users[0]);
      }
      this.waitingUsers = false;
    })
  }

  hasHotelDemo(){
    return this.hotelDemo !== undefined;
  }

  setFakeUser(user:User){
    this.selectedUserId = user.id;
    this.superAdminProperties.id_fake_user = this.selectedUserId;
    this.utilService.setDemoInterChain(user)
  }

  isDisableDemo(){
    return (this.hotelDemo===undefined || this.selectedUserId===undefined || this.saveDataSuperAdmin)
  }

  setLiveDemo($event){
    this.liveDemo = $event.checked
  }

  saveSuperAdminOptions(){
    this.saveDataSuperAdmin = true;
    this.superAdminProperties.live_demo = this.liveDemo;
    let fake_user     = this.utilService.getCurrentFakeUser()
    let fake_customer = this.utilService.getCurrentHotel()
    this.superAdminProperties.id_fake_user = fake_user.id;
    this.superAdminProperties.id_fake_customer = fake_customer.id;

    this.profileService.saveSuperAdminData(this.superAdminProperties).subscribe(response => {
      this.utilService.currentUser.superAdminProperties = this.superAdminProperties;
      this.utilService.updateCurrentUser();
      this.utilService.setDemo(this.liveDemo);
      this.saveDataSuperAdmin = false;
    })
  }

  userName(user){
    let name = user.displayName();
    if(user.isAdmin()){
      name = name + '<span> (admin)</span>';
    }
    return name;
  }


}
