import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientMH } from '../utils/http-myhotel-client';
import { Observable } from 'rxjs/internal/Observable';
import { FileData } from 'src/app/shared/model/file-data.model';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(private httpDownload: HttpClientMH, private httpClient: HttpClient) {}

  uploadPrivate(customerId, file): Observable<FileData> {
    const url = `${environment.customerPath}customers/${customerId}/files/upload/private`;
    return this.httpDownload.uploadFile(url, file);
  }

  uploadPrivateWithEvents(customerId, file): Observable<HttpEvent<FileData>> {
    const url = `${environment.customerPath}customers/${customerId}/files/upload/private`;
    return this.httpDownload.uploadFileWithEvents(url, file);
  }

  uploadImgHeaderSurvey(customerId, surveyId, file) {
    const url = `${environment.customerPath}customers/${customerId}/surveys/${surveyId}/files/upload`;
    return this.httpDownload.uploadFile(url, file);
  }

  downloadFile(customerId, key) {
    const url = `${environment.customerPath}customers/${customerId}/files/private?key=${key}`;
    const isPdf = false;
    this.httpDownload.downloadFile(url, key, isPdf);
    return this.httpDownload.getFile();
  }

  downloadFileToShow(customerId: number, key: string) {
    const url = `${environment.customerPath}customers/${customerId}/files/private?key=${key}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/octet-stream').set('Accept', '*/*');
    return this.httpClient.get(url, { headers, responseType: 'blob' });
  }

  getFileExtension(file) {
    return file.name.split('.').pop();
  }
}
