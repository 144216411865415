import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import { Case } from 'app/shared/cases/types';
import { UtilService } from 'app/layouts/main';
import { CasesService } from 'app/shared/cases/services/cases.service';
import { Observable, Subscription} from 'rxjs';
import { ConfirmationModalService } from 'app/shared/confirmation-modal';
import { filter, startWith, map } from 'rxjs/operators';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { FidelityDeskState } from 'app/states/fidelity-desk/fidelity-desk.state';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SurveyService } from 'app/features/surveys/surveys.service';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'mh-case-detail',
  templateUrl: './case-detail.component.html',
  styleUrls: ['./case-detail.component.scss']
})
export class CaseDetailComponent implements OnInit, OnDestroy {
  #caseInput;

  @Input("case") set caseInput(value) {
    this.#caseInput = value;
  }

  @Input() caseId: string;
  @Input() users: any[];
  usersList = [];

  @Output() onUpdate: EventEmitter<Case> = new EventEmitter();

  case: Case;
  subscribers: any[] = [];
  priorityOptions: any[] = [];
  stateOptions: any[] = [];
  caseTypesOptions: any[] = [];

  editing: boolean = false;
  error: boolean = false;
  message: string | null = null;
  lastChange:Date = new Date();
  subscribeLoading: boolean = false;

  caseForm: FormGroup;

  canEdit: Boolean = false;
  canChangeState: Boolean = false;
  canChangeAssigned: Boolean = false;

  assignedControl = new FormControl();
  subs: Subscription[] = [];

  currentAssignedId: number;

  usersToSubscribe = [];

  areasChips = [];
  areaControl = new FormControl();
  areas = [];
  filteredAreas
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('areaInput', {read: MatAutocompleteTrigger}) areaInput: MatAutocompleteTrigger;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Select(FidelityDeskState.casesTotal) casesTotal$: Observable<any[]>

  constructor(
    private utilService: UtilService,
    private casesService: CasesService,
    private fb: FormBuilder,
    private confirmService: ConfirmationModalService,
    private activatedRoute: ActivatedRoute,
    private config: NgSelectConfig,
    private translate: TranslateService,
    protected store: Store,
    private surveyService: SurveyService
  ) { }

  async ngOnInit() {
    this.fetchData();
    this.initListeners();
    this.mapUsersWithDisplayName();
    this.config.clearAllText = '';
    await this.getAreas();
    this.setAreaChips();
  }

  fetchData() {
    // el add es para esperar a que termine de fetchear el base
    // esto es dado que necesitamos el base para asignar las traducciones
    // esto es solo un problema dad a que el caso puede o no ser fetcheado
    // sino hubiese ocupado un forkJoin
    this.fetchBase(this.fetchCase.bind(this));
  }

  fetchCase() {
    if (this.caseInput) {
      this.setCase(this.caseInput);
      this.fetchSubscribers();
      return new Observable();
    }

    return this.casesService.getByPublicId(this.caseId, this.currentHotelId)
      .subscribe(_case => {
        // lo de state y priority es por los translates
        this.setCase({
          ..._case,
          priority: this.priorityOptions.find(p => p.id === _case.priority.id),
          state: this.stateOptions.find(p => p.id === _case.state.id),
        });
        this.fetchSubscribers();
        this.ga('open-case', this.case.id)
      }, this.setError);
  }

  fetchSubscribers() {
    this.casesService.getSubscribers(this.caseInput.id)
      .subscribe(this.setSubscribers, this.setError);
  }

  fetchBase(callback?: Function): Subscription {
    return this.casesService.getBase()
      .subscribe(d => this.setBase(d, callback), this.setError)
  }


  setCase = (_case: Case) => {
    this.case = _case || <Case>{};
    this.setPermissions();
    this.currentAssignedId = this.caseInput.assigned.id;
    this.assignedControl.setValue(this.currentAssignedId);
    if (!this.canChangeState) {
      this.assignedControl.disable();
    }
  }

  setPermissions = () => {
    const currentUserId = this.currentUserId
    const authorId = this.case.author!.id;
    const assignedId = this.case.assigned!.id;
    const isAdmin = this.isCurrentUserAdmin;

    this.canEdit = isAdmin || authorId === currentUserId;
    this.canChangeState = isAdmin || [authorId, assignedId].includes(currentUserId);
    this.canChangeAssigned = isAdmin || [authorId, assignedId].includes(currentUserId)
  }

  setSubscribers = (subscribers: any[]) => {
    this.subscribers = subscribers || [];
    this.loadUsersToSubscribe();
  }

  setBase = ({priorities, states, case_types}, callback?: any) => {
    this.priorityOptions = priorities;
    this.stateOptions = states;
    this.caseTypesOptions = case_types;
    callback();
  }

  setError = (error: any) => {
    if(error) this.processCaseError(error?.code)
  }

  handleEdit() {
    this.editing = true;
    this.caseForm = this.fb.group({
      title: [this.case.title, [Validators.required]],
      description: [this.case.description, [Validators.required]],
    })
    this.ga('edit-detail-form', this.case.id)
  }

  handleEditCancel() {
    this.editing = false;
  }

  handleEditSave() {
    if(this.caseForm?.invalid) {
      this.caseForm.markAllAsTouched();
      return;
    }
    const related_areas = this.areasChips.map(area => ({area_entity: {...area}}));
    const caseForSave = this.formatCase({
      ...this.case,
      ...this.caseForm.value,
      related_areas
    });

    this.casesService.save(caseForSave)
      .subscribe(this.afterEditSave, this.afterEditSaveFailed);
  }

  afterEditSave = () => {
    this.case = {
      ...this.case,
      ...this.caseForm.value,
    }
    this.generalMessage('cases.messages.edit_succeed', false)
    this.editing = false;
    this.onUpdate.emit(this.case);
    this.refreshDate();
    this.ga('edit-detail-save', this.case.id)
  }

  afterEditSaveFailed(error: any) {
    if(error) this.processCaseError(error?.code)
  }

  handleAssignedChange(arg) {
    this.confirmService.withConfirmation(
      'cases.messages.assigned_change_confirm',
      () => this.onAssignedChange(arg),
    );
  }

  onAssignedChange(user) {
    this.casesService.updateAssigned(this.case.id, user.id)
      .subscribe(
        () => this.afterAssignedChanged(user),
        this.afterAssignedChangeFailed,
      )
  }

  afterAssignedChanged = (user: any) => {
    this.generalMessage('cases.messages.assigned_change_succeed', false)
    this.onUpdate.emit(this.case);
    this.refreshDate();
    this.ga('edit-assigned', this.case.id)
  }

  afterAssignedChangeFailed = (error: any) => {
    this.case.assigned = { ... this.case.assigned };
    if(error) this.processCaseError(error?.code)
  }

  handleStateChange(arg:any) {
    this.confirmService.withConfirmation(
      'cases.messages.state_change_confirm',
      () => this.onStateChange(arg),
    )
  }

  handleCaseTypeChange(args: any) {
    this.confirmService.withConfirmation(
      'cases.messages.type_change_confirm',
      () => this.onCaseTypeChange(args.value),
    )
  }

  onCaseTypeChange(case_type_input: any) {
    const { case_type, ...newCase } = this.case;
    if (case_type_input) newCase['case_type'] = case_type_input;
    this.casesService.update(newCase).subscribe(() => {
      this.afterSelectChanged('type', case_type);
    })
  }

  onStateChange({value: state}) {
    this.casesService.updateState(this.case.id, state.id)
      .subscribe(
        () => this.afterSelectChanged('state', state),
        this.afterStateChangedFailed,
      )
  }

  afterSelectChanged = (select: string, option: any) => {
    const type = (select === 'type') ? select : 'state';
    const message = (select === 'type') ?
      'cases.messages.type_edit_change_succeed' :
      'cases.messages.state_change_succeed'
    this.generalMessage(message, false)
    this.onUpdate.emit(this.case);
    this.refreshDate();
    this.ga(`edit-${type}`, option?.id || '-')
  }

  afterStateChangedFailed = (error: any) => {
    if(error) this.processCaseError(error?.code)
  }

  handlePriorityChange(arg:any) {
    this.confirmService.withConfirmation(
      'cases.messages.priority_change_confirm',
      () => this.onPriorityChange(arg),
    )
  }

  onPriorityChange({value: priority}) {
    this.casesService.updatePriority(this.case.id, priority.id)
      .subscribe(
        () => this.afterPriorityChanged(priority),
        this.afterPriorityChangedFailed
      );
  }

  afterPriorityChanged = (priority: any) => {
    this.generalMessage('cases.messages.priority_change_succeed', false)
    this.onUpdate.emit(this.case);
    this.refreshDate();
    this.ga('edit-priority', priority.id)
  }

  afterPriorityChangedFailed = (error: any) => {
    if (error) this.processCaseError(error?.code)
  }

  private processCaseError(code: number) {
    const errorTranslate = this.utilService.getCaseErrorTranslate(code)
    this.generalMessage(errorTranslate, true)
  }

  private generalMessage = (errorTranslatePath: string = 'waiting.messages.wait_fail', isError: boolean) => {
    this.error = isError;
    this.message = errorTranslatePath;
    setTimeout(() => {
      this.message = null
    }, 5000)
  }

  handleSubscribe() {
    this.confirmService.withConfirmation(
      'cases.messages.subscribe_confirm',
      () => this.onSubscribe(),
    )
  }

  onSubscribe() {
    this.subscribeLoading = true;
    this.casesService.subscribe(this.case.id)
      .subscribe(this.afterSubscribe, this.setError);
  }

  afterSubscribe = () => {
    this.subscribeLoading = false;
    this.subscribers = [this.currentUser, ...this.subscribers];
    this.generalMessage('cases.messages.subscribe_succeed', false)
    this.refreshDate();
    this.ga('subscribe', this.currentUserId);
  }

  handleUnsubscribe() {
    this.confirmService.withConfirmation(
      'cases.messages.unsubscribe_confirm',
      () => this.onUnsubscribe(),
    )
  }

  onUnsubscribe() {
    this.subscribeLoading = true;
    this.casesService.unsubscribe(this.case.id)
      .subscribe(this.afterUnsubscribe, this.setError);
  }

  afterUnsubscribe = () => {
    this.subscribeLoading = false;
    this.subscribers = this.subscribers.filter(s => s.id !== this.currentUserId)
    this.generalMessage('cases.messages.unsubscribe_succeed', false)
    this.refreshDate();
    this.ga('unsubscribe', this.currentUserId);
  }

  refreshDate() {this.lastChange = new Date();}

  areEqual(obj1: any, obj2: any) { return obj1.id === obj2.id; }

  formatCase(_case = this.case) {
    const { assigned, author } = _case;
    return {
      ..._case,
      assigned: {id: assigned.id},
      author: {id: author?.id},
    };
  }

  get language() { return this.utilService.getCurrentLanguage(); }
  get isCurrentUserSubscribed() {
    return !!this.subscribers.find(s => s.id === this.currentUserId)
  }
  get isCurrentUserInvolved() {
    return this.case && (
      (this.case.author && this.case.author.id) === this.currentUserId ||
      (this.case.assigned && this.case.assigned.id) === this.currentUserId
    )
  }
  get isCurrentUserAdmin() {
    const user = this.utilService.currentUser;

    return user.isAdmin() || user.isSuperAdmin();
  }
  get currentHotelId() { return this.utilService.currentHotel.id; }
  get currentUserId() { return this.utilService.currentUser.id; }
  get currentUser() {
    const {id, profile: {name: first_name, last_name}} = this.utilService.getCurrentUser();

    return { id, first_name, last_name };
  }

  ga(label?:string, value?:number) {
    const gaEventAction = `form-detail-${this.currentPath}`
    this.utilService.ga('cases', gaEventAction, label, value);
  }
  get currentPath() {
    const possitblePaths: any = ['user', 'history', 'active'];
    const currentPath = this.activatedRoute.routeConfig?.path;

    if (possitblePaths.includes(currentPath)) return currentPath;

    return 'product';
  }

  get isAirline() { return this.utilService.customerIsAirline }
  get isRetail() { return this.utilService.customerIsRetail };
  get isClinic() { return this.utilService.customerIsClinic };

  get titleTranslate() {
    if(this.isRetail) return 'commons.client';
    if(this.isClinic) return 'commons.patient';
    if(this.isAirline) return 'commons.passenger';
    return 'commons.guest';
  }

  customSearchFn(term: string, item) {
    term = term.toLowerCase();
    return `${item.first_name} ${item.last_name}`.toLowerCase().indexOf(term) > -1 ||
      item.company_position.toLowerCase().indexOf(term) > -1  ||
      item.email.toLowerCase().indexOf(term) > -1;
  }

  initListeners(): void {
    this.subs = [
      ...this.subs,
      this.assignedControl.valueChanges
        .pipe(
          filter(value => typeof value === 'number' && this.currentAssignedId !== value)
        )
        .subscribe((userId) => {
          const user = this.users.find((user) => user.id === userId);
          this.handleAssignedChange(user);
        }),
      this.translate.get('commons.not_found')
        .subscribe((value) => {
          this.config.notFoundText = value;
        }),
      this.casesTotal$
        .subscribe((cases: Case[]) => {
          this.fetchSubscribers();
          const currentStateCase = cases.find((c) => this.caseInput.id === c.id);
          if (currentStateCase) {
            this.case = currentStateCase;
            this.currentAssignedId = currentStateCase.assigned.id;
          }
        }),
    ];
  }

  get isSelectClearable() {
    return String(this.currentAssignedId) !== String(this.assignedControl?.value);
  }

  mapUsersWithDisplayName() {
    this.usersList = this.users.map((user) => {
      return {
        ...user,
        displayName: `${user.first_name} ${user.last_name} (${user.company_position})`,
      }
    });
  }

  loadUsersToSubscribe() {
    this.usersToSubscribe = this.usersList.filter((user) => {
      const userFound = this.subscribers.find((subscriber) => subscriber.id === user.id);
      return !userFound && ![this.case.assigned.id, this.case.author.id].includes(user.id) ? user : null;
    });
  }

  subscribeUserOpenModal() {
    this.confirmService.withConfirmation(
      'cases.messages.subscribe_users_confirm',
      () => this.subscribeUsers(),
      () => {},
      this.usersToSubscribe,
    );
  }

  subscribeUsers() {
    this.subscribeLoading = true;
    this.casesService.updateSubscribers(this.case, this.confirmService.usersIdsToSubscribe)
      .subscribe((data) => {
        if (data === true) {
          this.onUpdate.emit(this.case);
          this.generalMessage('cases.messages.subscribe_succeed', false)
          this.subscribeLoading = false;
        }
      }, this.afterPriorityChangedFailed);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  async getAreas() {
    this.areas = await this.translate.get('areas').toPromise();
    const { areas, areas_translates }: any = await this.surveyService.getBase(this.utilService.getCurrentCustomer().id).toPromise()
    if (areas) {
      const mappedAreas = this.mapAreas(areas_translates, areas);
      this.areas = mappedAreas;
      this.initAreasFilter();
    }
  }

  mapAreas(areas_translates, areas) {
    return areas_translates.reduce((prev, curr) => {
      const considerArea = areas.find(area => area.id === curr.area_id);
      if (!considerArea) {
        return [
          ...prev
        ]
      }
      const existArea = prev.findIndex(area => area.id === curr.area_id);
      const key = (curr && curr.language.code === 'es') ?
        'clasification_text' :
        (curr && curr.language.code === 'en') ?
        'clasification_text_en' :
        'clasification_text_pt';
      if ( existArea >= 0 ) {
        prev[existArea][key] = curr.name;
        return [
          ...prev
        ]

      } else {
        return [
          ...prev,
          {
            id: curr.area_id,
            [key]: curr.name
          }
        ];
      }
    }, []);
  }

  setAreaChips(): void {
    this.areasChips = this.caseInput.related_areas.map(({ area_entity }) => {
      const ptTranslation = this.areas.find((area) => area.id === area_entity.id)?.clasification_text_pt;
      return {
        ...area_entity,
        clasification_text_pt: ptTranslation ?? null,
      };
    });
  }

  initAreasFilter() {
    this.filteredAreas = this.areaControl.valueChanges.pipe(
        startWith(''),
        map((area: string | null) => this.areasFilter(area)));
  }

  add(event: MatChipInputEvent): void {
    const inputValue = event.input;
    if (inputValue) inputValue.value = '';
    const value = this.areas.find(area => {
      return (area.clasification_text === inputValue)
        ? true
        : area.clasification_text_en === inputValue
    });
    if (value) {
      this.areasChips.push(value);
    }
    this.areaControl.setValue(null);
  }

  remove(area: any): void {
    const index = this.areasChips.indexOf(area);
    if (index >= 0) {
      this.areasChips.splice(index, 1);
    }
    this.handleEdit();
    this.handleEditSave();
  }

  selected({option}): void {
    const inputValue = option.value;
    const value = this.areas.find(area => area.clasification_text === inputValue.clasification_text);
    if (value) {
      this.areasChips.push(value);
    }
    this.areaControl.setValue(null);
    this.handleEdit();
    this.handleEditSave();
    setTimeout(() => this.areaInput.openPanel(), 200);
  }

  areasFilter(value: string | any): string[] {
    const availableAreas = this.areas.map(area => {
      const chip = this.areasChips.find(chip => chip.id === area.id);
      if ( !!!chip ) return area
    }).filter(area => area !== undefined)
    if (value) {
      const filterValue = (typeof(value) === 'string') ?
        value.toLowerCase() :
        (this.language === 'es') ?
        value.clasification_text :
        (this.language === 'en') ?
        value.clasification_text_en :
        value.clasification_text_pt;
      return availableAreas.filter(option => {
        if (this.language === 'es') {
          const { clasification_text } = option;
          return clasification_text.toLowerCase().includes(filterValue)
        } else if ( this.language === 'en' ) {
          const { clasification_text_en } = option;
          return clasification_text_en.toLowerCase().includes(filterValue)
        } else {
          const { clasification_text_pt} = option;
          return clasification_text_pt.toLowerCase().includes(filterValue)
        }
      });
    } else {
      return availableAreas.slice();
    }
  }

  get caseInput() {
    return this.#caseInput;
  }
}
