import { Component, Input, EventEmitter, Output, ViewChild, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UtilService } from "../../util.service";
import { Survey } from 'app/shared/model/index';
import { FollowupGuestFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-guest-filter.pipe'
import {MatInput} from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';

const followupGuestFilterPipe = new FollowupGuestFilterPipe()

@Component({
  selector: 'mh-filter-guest',
  templateUrl: './filter-guest.component.html',
  styleUrls: ['./filter-guest.component.scss'],
})
export class FilterGuestComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  listGuest: Survey[];

  @Input()
  type: 'guest' | 'survey' | 'orders' = 'guest';

  @Output()
  selected: EventEmitter<Survey> = new EventEmitter();

  @ViewChild('searchForm')
  searchForm: any;

  selectedSurvey: Survey;
  waiting = true;
  stateCtrl = new FormControl();
  filteredGuest: Survey[] = [];
  isGuestSelected = false;
  currentSelecction;
  filterGuestSubs: Subscription;

  constructor(
    private utilService: UtilService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    const guestId = this.route.snapshot.queryParamMap.get('guest');
    if (guestId) {
      const guestFound = this.listGuest.find((survey) => survey.guest.id === +guestId);
      this.currentSelecction = guestFound || null;
      this.stateCtrl.setValue(guestFound || '');
    }
  }

  ngOnChanges() {
    this.startFilter();
  }

  startFilter() {
    if(this.listGuest) {
      this.filterGuestSubs = this.stateCtrl.valueChanges
        .pipe(
          startWith(''),
          map(data => followupGuestFilterPipe.transform(this.listGuest, data)),
        ).subscribe((filteredGuestList) => {
          this.filteredGuest = this.isGuestSelected && this.stateCtrl.value !== '' ? [] : filteredGuestList;
          if (this.isGuestSelected && this.filteredGuest.length > 0 && this.stateCtrl.value === '') {
            this.emitValue(null);
          }
          this.isGuestSelected = false;
        })
    }
  }

  onCloseSearch() {
    this.emitValue(null);
    this.isGuestSelected = false;
    this.stateCtrl.setValue('');
  }

  emitSurvey(event) {
    const emitValueFn = (survey, value) => {
      if (this.currentSelecction?.id === value?.id) this.emitValue(null);
      setTimeout(() => {
        this.emitValue(survey)
        this.currentSelecction = value;
        this.isGuestSelected = true;
        this.stateCtrl.setValue(survey);
      });
    };

    const value = event.option.value;
    if(this.type === 'survey') {
      const survey = this.listGuest.find(guest => (guest && guest.guest.id === value.guest.id))
      emitValueFn(survey, value)
      return;
    }
    const guestType = (this.type === 'orders') ?
    value.guest : value;
    const guest = this.listGuest.find(guest => {
      guest = (this.type === 'orders') ?
      guest.guest : guest;
      return guest.id === guestType.id;
    });
    if(guest) {
      emitValueFn(guest, (this.type === 'orders') ? value.guest : value)
    }
  }

  setSurveyFromInput(event) {
    const value = event.srcElement.value;
    const guest = followupGuestFilterPipe.transform(this.listGuest, value, true)
    if(guest) {
      this.selectedSurvey = guest;
      this.isGuestSelected = true;
      this.emitValue(guest);
    }
  }

  async emitValue(value) {
    const control: MatInput = this.searchForm._control;
    control.focused = false;
    this.selected.emit(value);
  }

  guestName(survey) {
    if (survey) {
      const guest = survey.guest ? survey.guest : survey;
      const lastname = (guest.lastName ? guest.lastName : guest.lastname) || '';
      const name = guest.name + ' ' + lastname;
      return name;
    }
  }

  get isRetail(){ return this.utilService.customerIsRetail}
  get isClinic(){ return this.utilService.customerIsClinic}
  get isAirline(){ return this.utilService.customerIsAirline}
  get typeTranslate(){
    if(this.isRetail) return 'commons.clients';
    if(this.isClinic) return 'commons.patients';
    if(this.isAirline) return 'commons.passengers';

    return 'commons.guests';
  }

  ngOnDestroy() {
    if (this.filterGuestSubs) this.filterGuestSubs.unsubscribe();
  }
}
