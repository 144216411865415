<div class="row">
  <div class="col content_filter">

    <ng-container>
      <div ngbDropdown #myDrop="ngbDropdown" [placement]="placement" class="d-inline-block" *ngIf="show_toggle">
        <button type="button" class="btn btn-outline-primary btn-open" [id]="filterId" (click)="toggleHotelList(myDrop)" ngbDropdownToggle></button>
        <div ngbDropdownMenu aria-labelledby="hotelsList">
          <form [formGroup]="stateForm" class="showing_hotels_list">
            <mat-form-field>
              <input #inputAuto matInput [matAutocomplete]="autoGroup" [placeholder]="('commons.search' | translate )" autofocus formControlName="stateGroup">
                <mat-autocomplete #autoGroup="matAutocomplete" [disableRipple]="false"  (optionSelected)="updateCustomer($event)">
                  <ng-container *ngIf="selecteable" >
                    <span class="select_all" (click)="selectAll()">{{'commons.select_all' | translate}}</span>
                  </ng-container>

                  <mat-option [hidden]="!loadingAdminHotels">
                    <div class="waiting_load">
                      <mh-spinner-classic [color]="'gray'"></mh-spinner-classic>
                    </div>
                  </mat-option>
                  <mat-option [hidden]="(stateGroupOptions | async)?.length!=0 || loadingAdminHotels || forceToogle">
                    <div class="empty">{{emptyMessage | translate }}</div>
                  </mat-option>


                  <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="('countries.'+group.country_code | translate)" [class.none]="group.country_code=='none'" [hidden]="loadingAdminHotels">
                    <mat-option *ngFor="let hotel of group.customers" [value]="hotel.name" [attr.data-hotel-id]="hotel.id">
                      <div class="row align-items-center no-gutters">
                        <div class="col-2" *ngIf="!is_super_admin">
                          <img class="logo_hotel" [src]="hotel.urlLogo" alt="logo hotel">
                        </div>
                        <div class="col-{{ is_super_admin ? 12 : 10 }}">
                          <div class="hotel_name">
                            <div class="ellipsis">{{ hotel.name }}<span [hidden]="!is_super_admin"> ({{hotel.id}})</span></div>
                          </div>
                        </div>
                      </div>
                    </mat-option>
                </mat-optgroup>

              </mat-autocomplete>
            </mat-form-field>
          </form>
        </div>
      </div>
    </ng-container>


  </div>
</div>

