<mh-core-lib-actions-wrapper 
[slug]="slug" 
[skipValidation]="isValid" 
[message]="message"
>
  <div [innerHTML]="text" class="tw-whitespace-pre-wrap tw-text- tw-font-[Rubik]"></div>
</mh-core-lib-actions-wrapper>
<mat-divider *ngIf="areFiles"></mat-divider>
<div *ngIf="areFiles">
  <ng-container *ngFor="let file of files">
    <ng-container *ngIf="file | checkDownloadStatus: waitingDownload">
      <mh-spinner-classic></mh-spinner-classic>
    </ng-container>
    <ng-container *ngIf="!(file | checkDownloadStatus: waitingDownload)">
      <ng-container *ngTemplateOutlet="fileTemplate; context: { file: file }">
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #fileTemplate let-file="file">
  <div class="mt-2">
    <h6>{{'cases.files.attachment' | translate}}</h6>
    <span class="tw-block tw-truncate">{{file.file_name}}</span>
    <span class="tw-block tw-cursor-pointer tw-truncate" (click)="downloadFile.emit(file.file_key)">{{'cases.files.download' | translate}} <mat-icon>file_download</mat-icon></span>
  </div>
</ng-template>
