<table *ngIf="!isOneRowGrid; else npsGrid">
  <thead>
    <tr [class.min-max]="hasMinMaxTexts">
      <td></td>
      <td class="header" *ngFor="let sub of alternatives[0].sub_alternatives; let first = first; let last = last">
        <div *ngIf="first && hasMinMaxTexts">
          <span class="first-column">
            {{question.translates | translateObject:'min_text':language}}
          </span>
        </div>

        <div *ngIf="last && hasMinMaxTexts">
          <span class="last-column">
            {{question.translates | translateObject:'max_text':language}}
          </span>
        </div>

        {{sub.translates | translateObject:'text':language}}
      </td>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let alternative of alternatives">
      <tr class="alternative-row"
        [class.invalid_alternative]="answered && !alternative.isValid(answers)"
      >
        <td>
          {{alternative.translates | translateObject:'text':language}}
        </td>
        <td *ngFor="let sub of alternative.sub_alternatives" class="align-center">
          <mat-radio-button
            [name]="alternative.id.toString()"
            [value]="sub.score"
            [checked]="isSelected(sub, alternative)  && !isDisabled(alternative)"
            [disabled]="isDisabled(alternative)"
            (change)="handleChange(alternative, sub)"
            class="alternative-container"
          >
            <i *ngIf="isSelected(sub, alternative) && isDisabled(alternative)" class="icon-fas-circle"></i>
          </mat-radio-button>
        </td>
      </tr>

      <!-- Conditionals -->
      <ng-container *ngFor="let sub of alternative.sub_alternatives">
        <ng-container *ngIf="activeSubAlternatives[sub.id]">
          <tr *ngFor="let condQuestion of conditionalQuestions[sub.id]">
            <td [attr.colspan]="alternative.sub_alternatives.length + 1">
              <div class="conditional-container">
                <mh-survey-question-public
                  [question]="condQuestion"
                  [answers]="answers"
                  [answered]="answered"
                  [conditionalQuestions]="[]"
                  [language]="language"
                  [conditionalId]="sub.id"
                  [preview]="preview"
                  [surveyLanguageId]="surveyLanguageId"
                  [languageId]="languageId"
                  (answer)="answer.emit($event)"
                ></mh-survey-question-public>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
  </tbody>
</table>

<ng-template #npsGrid>
  <div class="nps-grid">
    <div class="nps-grid-toggle-group">
      <mat-button-toggle-group>
        <mat-button-toggle *ngFor="let option of alternatives[0].sub_alternatives"
          [disabled]="isDisabled(alternatives[0])"
          [checked]="isSelected(option, alternatives[0])"
          (change)="handleChange(alternatives[0], option)"
        >
          {{option.translates | translateObject:'text':language}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <ng-container *ngFor="let sub of alternatives[0].sub_alternatives">
    <div class="conditional-container" *ngIf="activeSubAlternatives[sub.id]">
      <div *ngFor="let question of conditionalQuestions[sub.id]">
        <mh-survey-question-public
          [question]="question"
          [answers]="answers"
          [answered]="answered"
          [conditionalQuestions]="[]"
          [language]="language"
          [conditionalId]="sub.id"
          [preview]="preview"
          [surveyLanguageId]="surveyLanguageId"
          [languageId]="languageId"
          [productId]="productId"
          [answersComments]="answersComments"
          (answer)="answer.emit($event)"
        ></mh-survey-question-public>
      </div>
    </div>
  </ng-container>
</ng-template>
