import { Component, Input } from '@angular/core';
import { UserProfile,CustomerProfile } from '../../../../shared/model/index';

@Component({
  selector: 'mh-user-customers-show',
  templateUrl: './user-customers-show.component.html',
  styleUrls: ['../../../../../assets/scss/configuration/customers-show.scss'],
  providers:[]
})
export class UserCustomersShowComponent{

  showAll:boolean=false;

  @Input()
  user:UserProfile;
  customers:CustomerProfile[]=[];

  constructor(){

  }

  ngOnChanges() {
    this.customers = this.user.customers;
    let index = this.customers.findIndex(x=>x.id===12);
    if(index>-1) this.user.customers.splice(index,1);
    this.showAll = (this.customers.length <= 2  )
  }



  toggleShowAll(){
    this.showAll = !this.showAll;
  }


}

