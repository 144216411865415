<div class="content_semantic">

  <div class="waiting">
    <mh-spinner-load *ngIf="data.waiting"></mh-spinner-load>
  </div>

  <ul class="list_data" *ngIf="!data.waiting">
    <ng-container *ngFor="let category of data.data">
      <li>
        <div class="row align-items-center category_box">
          <div class="col-md-9 col-lg-5">
            <span><i class="fa fa-comments"></i>({{category.total}})</span>
            <div class="label ellipsis">{{getLabel(data.type,category)}}</div>
          </div>
          <div class="col-md-3 col-lg-7">
            <mh-semantic-bar
              [positivePercentage]="category.positives_percentage"
              [neutralPercentage]="category.neutrals_percentage"
              [negativePercentage]="category.negatives_percentage"
              [positiveLabel]="category.positives"
              [neutralLabel]="category.neutrals"
              [negativeLabel]="category.negatives"
              ></mh-semantic-bar>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
</div>



