import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { CoreLibModalComponent } from '../modal/modal.component';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { Communications } from 'app/shared/notifications/models/communications';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'app/shared/notifications/services/notifications.service';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-core-lib-notifications-popup-modal',
  templateUrl: './notifications-popup-modal.component.html',
  styleUrls: ['./notifications-popup-modal.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [style({ opacity: 0 }), animate('800ms', style({ opacity: 1 }))]),
      transition('* => void', [animate('800ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class CoreLibNotificationsPopupModalComponent extends CoreLibModalComponent implements OnInit {
  @Output() emitUpdate = new EventEmitter<boolean>();
  public videos: Communications[] = [];
  public currentSlide = 0;
  public viewedTime = 0;
  public intervalId: ReturnType<typeof setInterval>;
  public todayDate = new Date();

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private notificationService: NotificationsService,
    private utilService: UtilService,
  ) {
    super(dialogRef, data);
  }

  ngOnInit() {
    this.videos = this.data?.news;
    if (this.data.timer) this.initTimer();
    this.emitPopupViewedGAEvent();
  }

  initTimer() {
    this.intervalId = setInterval(() => {
      this.viewedTime += 1;
    }, 1);
  }

  stopTimer() {
    clearInterval(this.intervalId);
    this.intervalId = null;
  }

  handleResetTimer() {
    this.stopTimer();
    this.viewedTime = 0;
    this.initTimer();
  }

  handleCurrentSlideUpdate(event) {
    this.currentSlide = event;
    this.emitPopupViewedGAEvent();
  }

  async handleSentimentClick(communication: Communications, status: string) {
    this.stopTimer();

    if (['OMITTED', 'READ'].includes(communication.notifications_status)) {
      return;
    }

    try {
      const url = `${environment.notificationsPath}notification-news/${communication.id}/users/${this.data.currentUser.id}/status/${status}`;
      const params = { customerId: this.data.currentCustomerId, viewedTime: this.viewedTime };
      const res = await this.notificationService.changeNotificationStatus(url, params).toPromise();
      if (res) {
        if (status === 'READ') {
          const link = communication.translates[0].link;
          window.open(link, '_blank');
        }
        this.notificationService.emitUpdateOnNews();
        this.videos = this.videos.filter((video) => video.id !== communication.id); // update the list of videos
        const formattedDate = this.utilService.formatDate(this.todayDate);
        this.utilService.ga(
          'popups',
          `click-${status === 'READ' ? 'positive' : 'negative'}-sentiment-button`,
          'popup-viewed-date',
          formattedDate,
        );
        if (this.videos.length === 0) {
          this.close(); // close the modal when there are no more videos
          return;
        }
        this.viewedTime = 0; // Set to 0 the viewed time
        this.initTimer(); // Init the timer again
      }
    } catch (error) {
      console.error(error);
      this.close();
      this.viewedTime = 0;
    }
  }

  emitPopupViewedGAEvent() {
    this.utilService.ga('popups', 'show-popup', 'popup-title', this.videos[this.currentSlide]?.translates[0]?.title);
  }

  handleClosePopup() {
    this.utilService.ga('popups', 'click-close-button');
    this.close();
  }
}
