<div class="row order_id align-items-center no-gutters">
  <div class="col">
      <span>{{'models.order.order_id' | translate}}: </span>
      <span>MH-{{order.id}}</span>
  </div>
  <div class="col quantity_service">
    <span class="light">{{'commons.quantity_services' | translate }}</span> {{order.total_quantity}}
  </div>
  <div class="col-2 download" >
    <button class="btn btn-link btn-icon-left" (click)="downloadPDF()" [attr.disabled]="downloadingPDF ? true : null">
      <i class="far fa-cloud-download"></i>
      <span>{{'shared.survey-answer-modal.download' | translate}}</span>
    </button>
    <div class="wait_pdf">
      <mh-spinner-classic [hidden]="!downloadingPDF"></mh-spinner-classic>
    </div>
  </div>
</div>
<mat-table  class="table-mh sticky" #table [dataSource]="dataSource"  matSortDisableClear matSort>
  <ng-container *ngFor="let key of headerTable" matColumnDef="{{key}}">


    <ng-container [ngSwitch]="key">
      <ng-template [ngSwitchCase]="['image'].includes(key) ? key : false">
        <mat-header-cell *matHeaderCellDef [class]="key">
          <div class="text_bold">{{'models.order.'+key | translate }}</div>
        </mat-header-cell>
      </ng-template>

      <ng-template ngSwitchDefault>
        <mat-header-cell *matHeaderCellDef mat-sort-header [class]="key">
            <div class="text_bold">{{'models.order.'+key | translate }}</div>
        </mat-header-cell>
      </ng-template>
    </ng-container>



    <mat-cell *matCellDef="let element" [class]="key">

      <ng-container [ngSwitch]="key">
        <ng-template [ngSwitchCase]="'image'">
          <div class="product_image">
            <img [src]="element.product.image" alt="Service Image" class="image">
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'service'">
          <span  mhFindTranslate [language]="language" [translates]="element.product.translates" [keyTranlsate]="'name'"></span>
        </ng-template>

        <ng-template [ngSwitchCase]="'quantity'">
            <div class="text_quantity col-2">
            {{element.reservation[key]}}
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'comment'">
            <div class="text_comment">
            {{element.reservation[key]}}
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'price'">
          <div class="unique_price">
            <mh-prestay-product-price [properties]="element.product.properties[0]" [currency]="currency" [reservation_unit_price]="element.reservation.unit_price"></mh-prestay-product-price>
          </div>
        </ng-template>


        <ng-template ngSwitchDefault>
          {{element.reservation[key]}}
        </ng-template>

      </ng-container>

    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="headerTable"></mat-header-row>
  <mat-row *matRowDef="let row; columns: headerTable;"></mat-row>
</mat-table>

<div class="total_price">
  {{'models.order.total' | translate }} {{(order.total_price | currency:money.code:(money.code+' '+money.symbol))}}
</div>
