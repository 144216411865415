import { UtilService } from 'app/layouts/main';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserProfile } from 'app/shared/model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {EditUserService} from '../../../edit/edit-user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'conf-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: [
    './set-password.component.scss',
    '../../../../../assets/scss/configuration/users.scss'
  ]
})
export class SetPasswordComponent implements OnInit, OnDestroy {

  @Input()
  user:UserProfile;

  modal:NgbModalRef;
  waitSending = false;

  controlForm:FormGroup;
  sendingData = false;

  inputs = ['new_password','confirm_password'];
  equalPass= false;

  passChanged = false;
  newPassword = '';
  encryptPassword = '';
  showPassword = false;
  subscription: Subscription;

  constructor(private modalService: NgbModal,private fb: FormBuilder, private editUserService:EditUserService, private utilService:UtilService) {}

  ngOnInit() {
  }

  createForm(){
    this.passChanged = false;
    this.controlForm = this.fb.group({
      id:                 this.user.id,
      new_password:       ['',[Validators.required, Validators.minLength(8)]],
      confirm_password:   ['',[Validators.required, Validators.minLength(8)]],
    });

    this.subscription = this.controlForm.valueChanges.subscribe(val => {
      this.newPassword = val.new_password;
      this.equalPass = this.newPassword === val.confirm_password;
    });
  }

  openDialog(content){
    this.createForm();

    this.modal = this.modalService.open(content, {
      windowClass: 'modal-lg modal-confirm'
    })
  }

  closeDialog(){
    if(this.modal) this.modal.close();
  }

  saveData(){
    this.sendingData = true;
    this.editUserService.setPassword(this.controlForm.value).subscribe(response=>{
      this.encryptPassword = this.utilService.encryptString(this.newPassword);
      this.sendingData = false;
      this.passChanged = true;
    },error=>{
      this.sendingData = false;
      this.utilService.sendAlert();
      this.closeDialog();
    })
  }

  togglePassword(){
    this.showPassword = !this.showPassword;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
