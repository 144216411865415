import { State, Action, StateContext, Selector } from '@ngxs/store';
import { StoreUserRole, StoreUsersActions } from './users-actions.actions';
import { Injectable } from '@angular/core';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';

export type UsersActions = {
  active: boolean;
  comment: string;
  super_admin: boolean;
  id: number;
  type_id: number;
  product_id: number;
  slug: string;
  title: string;
};

interface UsersActionsModel {
  actions: UsersActions[];
  role: string;
}

@State<UsersActionsModel>({
  name: 'usersActions',
  defaults: {
    actions: [],
    role: '',
  },
})
@Injectable()
export class UsersActionsState {
  constructor(private usersManagementService: UsersManagmentService) {}
  @Selector()
  static role(state: UsersActionsModel) {
    return state.role;
  }

  @Selector()
  static getActions(state: UsersActionsModel) {
    return state.actions;
  }

  @Action(StoreUsersActions)
  async storeUsersActions(context: StateContext<UsersActionsModel>) {
    const actions = await this.usersManagementService.getUserActions().toPromise();
    context.patchState({
      actions,
    });
  }

  @Action(StoreUserRole)
  storeUserRole(context: StateContext<UsersActionsModel>, action: StoreUserRole) {
    context.patchState({
      role: action.role,
    });
  }
}
