export class FormEditor{

  editorOption={}
  language= 'es';

  constructor() {}

  editorCommonsOptions(){
    return {
      language: this.language,
      toolbar: ['bold', 'italic', '|', 'undo', 'redo','|','alignment'],
      fontSizeConfig: {
        options: [ 9, 10, 11, 12, 13, 14, 15 ]
      },
      alignment: {
        options: [ 'left', 'right','center']
      },
    }
  }

  loadProperties(languague){
    this.setLanguage(languague)
    this.editorOption['short_description']      = this.editorCommonsOptions();
    this.editorOption['description']            = this.editorCommonsOptions();
    this.editorOption['description']['toolbar'] = this.editorOption['description']['toolbar'].concat(['bulletedList','fontSize']);

  }

  create(){


  }

  private setLanguage(languague){
    this.language = languague === 'pt' ? 'pt_br' : languague;
  }


}
