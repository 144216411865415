import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store'
import { Observable } from "rxjs";

import { Survey } from 'app/survey-public/models';
import { UtilService } from "app/shared/util.service";
import { FollowupOnsite } from 'app/states/followup-onsite/followup-onsite.actions'
import { FollowupOnsiteState, FollowupOnsiteGuestFilter } from 'app/states/followup-onsite/followup-onsite.state'

@Component({
  selector: 'mh-select-multiple-surveys',
  templateUrl: './select-multiple-surveys.component.html',
  styleUrls: ['./select-multiple-surveys.component.scss']
})
export class SelectMultipleSurveysComponent implements OnInit {

  @Select(FollowupOnsiteState.filters) filters$: Observable<FollowupOnsiteGuestFilter>

  @Input() surveys: Survey[]

  constructor(
    private utilService: UtilService,
    private store: Store,
  ) { }

  ngOnInit() {
  }

  setSurveyFilter(survey) {
    if(survey) {
      let surveyFilter = []
      survey.selected.forEach(data => surveyFilter.push(data.value))
      this.store.dispatch(new FollowupOnsite.ChangeSurveyFilter(surveyFilter))
    }
  }

  get language() { return this.utilService.getCurrentLanguage(); }
  get filterPrefix() { return 'followup.survey.filter' }

}
