import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UtilService } from 'app/shared/util.service'
import { Select, Store } from '@ngxs/store';
import { UsersState } from 'app/states/users/users.state';

@Component({
  selector: 'mh-case-product-actions',
  templateUrl: './case-product-actions.component.html',
  styleUrls: ['./case-product-actions.component.scss']
})
export class CaseProductActionsComponent implements OnInit {

  @Select(UsersState.currentUsers) users$;

  @Input() productItem: any;
  @Input() cases: any[] = [];
  @Input() sourceWindow: string = 'unknown';
  @Input() showFlex: boolean
  @Input() flexColumn: boolean;
  @Input() alignCenter: boolean;
  @Input() justifyCenter: boolean;
  @Input() survey: any;
  @Input() createCase = false;

  hasProduct: boolean = false;
  selectedCaseId: string | null;
  modal: NgbModalRef;

  @ViewChild('newCaseForm') newCaseForm:any;

  constructor(
    private modalService: NgbModal,
    private _utilService: UtilService,
    private store: Store
  ) { }

  ngOnInit() {
    this.setHasProduct();
  }

  ngAfterViewInit() {
    this.handleCreateCaseForAlert();
  }

  handleCreateCaseForAlert() {
    if (this.createCase) this.openNewModal(this.newCaseForm);
  }

  public openNewModal(template: TemplateRef<any>) {
    const status = this.hasProduct ? 'enabled' : 'disabled';
    this._utilService.ga('cases', 'click', `create-case-${this.sourceWindow}-${status}`)

    if(!this.hasProduct) return

    this.openModal(template)
  }

  public openDetailModal(template: TemplateRef<any>, caseId: string) {
    if(!this.hasProduct) return

    this.selectedCaseId = caseId;
    this.openModal(template)
  }

  private openModal(template: TemplateRef<any>) {
    this.modal = this.modalService.open(template, {
      windowClass: 'modal-md'
    });
  }

  afterUpdate = (event:any) => {
    const idx = this.cases.findIndex(c => c.id === this.selectedCaseId);
    this.cases[idx] = {
      ...this.cases[idx],
      ...event,
    };
    this.closeModal();
  }

  afterCreate = (event:any) => {
    this.cases = [...this.cases, event];
    this.closeModal();
  }

  setHasProduct() {
    this.hasProduct = this._utilService.currentHotel['cases'];
  }

  public closeModal = () => {
    this.selectedCaseId = null;
    this.modal.close();
  }

  public get insideModal() { return /modal/.test(this.sourceWindow); }
  public get isSuperAdmin() { return this._utilService.currentUser.isSuperAdmin(); }
  public get users() { 
    return this.store.selectSnapshot(UsersState.currentUsers);
  }
}
