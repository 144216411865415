import { Component, Input, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { TrendLegendData } from '../trends-legend/trend-legend.model';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../../../shared/util.service';
import { HtmlHelper } from 'app/_helpers/html-helper.service';

@Component({
  selector: 'widget-trends-table',
  templateUrl: './trends-table.component.html',
  styleUrls: ['./trends-table.component.scss']
})
export class TrendsTableComponent {

  @Input()
  firstColumn = 'hotel'

  @Input()
  trendSelected;

  @Input()
  lineChartData: TrendLegendData[] = [];

  @Input()
  lineChartLabels: any[] = [];

  @Input()
  showDetail = false;

  @Input()
  formatScore = false;

  @Output()
  detailSelected: EventEmitter<any> = new EventEmitter();

  headerTable:string[] = [];
  waitingDetail = false;
  modal:NgbModalRef;


  totals = {}
  hasTotal = false;

  totalBy = 'total';


  dataSource: any = new MatTableDataSource([]);
  dataTable: any[]  = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('confirmModal', { static: true })
  confirmModal: TemplateRef<any>;


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(
    private utilService: UtilService,
    private modalService: NgbModal,
    private htmlHelper: HtmlHelper
  ) {  }


  ngOnChanges(): void {
    this.createTable();
  }

  openDialog(row){
    this.detailSelected.emit(row);
    this.modal = this.modalService.open(this.confirmModal, {
      windowClass: 'modal-xl'
    });
  }

  closeDialog(){
    if(this.modal) this.modal.close();
  }

  createTable(){
    this.totalBy = ['note','nps','ranking_areas'].includes(this.trendSelected) ? 'average' : 'total';
    this.dataTable = [];
    this.totals = {};
    this.headerTable = [this.firstColumn].concat(this.lineChartLabels);
    this.lineChartData.forEach(data=>{
      const result = {};
      result[this.firstColumn] = data.label;

      data.data.forEach((period,i)=>{
        const key = this.lineChartLabels[i];
        result[key] = period
        this.totals[key] = 0;
      })
      this.dataTable.push(result)
    })
    const dataTotalIndex = this.lineChartData.findIndex(x=>x['isAverage'])
    this.hasTotal   = dataTotalIndex > -1;
    const dataTotal = this.hasTotal ? this.dataTable[dataTotalIndex] : [];
    const data      = this.hasTotal ? this.dataTable.filter(x=>x.hotel !== dataTotal.hotel) : this.dataTable;
    this.dataSource = new MatTableDataSource(data);

    this.lineChartLabels.forEach(key=>{
      const data       = this.dataSource;
      const filterData = data.filteredData.map(x=>x[key]);
      if(filterData.length === 0) {
        filterData.push(null)
      }
      let total        = this.utilService.sumArray(filterData);
      if(!this.hasTotal && this.totalBy==='average'){
        const universe = data.filteredData.length - filterData.filter(x=>x===null).length;
        total = universe > 0 ? total/universe : null;
      }else if(this.hasTotal){
        total = dataTotal[key];
      }

      this.totals[key] = total;

    })

    this.dataSource.sortingDataAccessor = function (data, sortHeaderId) {
      let value = data[sortHeaderId];
      if(['sent_date' ,'entry_date','order_date'].includes(sortHeaderId)){
        value = new Date(value);
      }

      if (typeof value === 'string' && !value.trim()) {
        return value;
      }
      return isNaN(+value) ? value : +value;
    };
    this.dataSource.sort = this.sort;
  }

  get showTotals(){
    return this.trendSelected!=='ranking' && this.dataTable.length>2;
  }

  get isRetail(){ return this.utilService.customerIsRetail }
  get isAirline() { return this.utilService.customerIsAirline }
  get typeCustomer() { 
    if(this.isRetail) return 'commons.store';
    if(this.isAirline) return 'commons.airline';
    return 'commons.hotel';
  }

  get tableAreaStyle(){
    return this.htmlHelper.getTableAreaStyle(this.utilService.screenHeight);
  }

  get tableContentStyle(){
    return this.htmlHelper.getTableContentStyle(this.headerTable,160);
  }

  get formatBy(){
    if(['ranking_areas','note'].includes(this.trendSelected)) return 'average';
    return this.trendSelected;
  }

}
