import { Product, ProductProperties } from "./ps-product.model";

export class ProductAdd{
  id:number;
  guest_id:number = 0;
  quantity:number = 1;
  comment:string = '';
  product:Product;
  product_property:ProductProperties;
  reserved:boolean = false;
  constructor(guest_id,product){
    this.guest_id = guest_id;
    this.product = product;
  }

  createFromApi(response){
    this.id               = response.id;
    this.guest_id         = response.guest_id;
    this.reserved         = response.reserved;
    this.product_property = response.product_property;
    this.quantity         = response.quantity;
    this.comment          = response.comment;
    return this;
  }
}
