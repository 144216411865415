import { Component, OnInit, Input } from '@angular/core';
import { animation } from 'app/shared/utils/animation-custom';
import { FilesService } from 'app/shared/services/files.service';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-core-lib-activities-records-changes',
  templateUrl: './activities-records-changes.component.html',
  styleUrls: ['./activities-records-changes.component.scss'],
  animations: [animation.heightExpand],
})
export class CoreLibActivitiesRecordsChangesComponent implements OnInit {
  EXCLUDED_FIELDS = ['updated_at', 'id', 'deleted', 'created_at', 'lastActivity', 'typeid', ',item_id', 'product_id'];
  EXCLUDED_DESCRIPTIONS = ['related_area'];
  BASE_FIELDS = ['priority', 'state', 'case_type'];

  @Input()
  resources = [];

  @Input()
  users = [];

  @Input()
  customerId;

  @Input()
  language = 'es';
  expanded = [];
  waitingFileDownload: any[] = [];

  constructor(private filesService: FilesService, private utilService: UtilService) {}

  ngOnInit(): void {
    this.expanded = this.resources.map(() => false);
  }

  getNameValue(id) {
    const user = this.users.find((user) => user.id === id);
    return user ? `${user.first_name} ${user.last_name}` : '-';
  }

  getCompanyPosition(id) {
    const user = this.users.find((user) => user.id === id);
    return user ? `- ${user.company_position}` : '';
  }

  getUser(userId) {
    const userFound = this.users.find((user) => user.id === userId);
    return userFound ? userFound.complete_name : '';
  }

  getValueOrDash(value: any) {
    return value === false ? false : value || '-';
  }

  getTranslateParam(change: any) {
    return {
      actual: this.getValueOrDash(this.getAreasValue(change, 'actual') || this.getAreasValue(change, 'tkpi')),
      previous: this.getValueOrDash(
        this.getAreasValue(change, 'previous') || this.getAreasValue(change, 'tkpi_previous'),
      ),
    };
  }

  getTranslates(translates) {
    return this.utilService.findInputTranslate(translates, this.language, 'name');
  }

  // el for Translation hace referencia al nombre del cambio
  getTranslateParamForTranslation(change: any) {
    return {
      actual: this.getValueOrDash(change.actual && change.actual.fieldValue),
      previous: this.getValueOrDash(change.previous && change.previous.fieldValue),
    };
  }

  getAreasValue(change, type) {
    if (change && !change.name) return '-';

    if (type === 'actual' && change.name !== 'related_area') return change.actual;
    if (type === 'previous' && change.name !== 'related_area') return change.previous;
    if (type === 'tkpi' && change.name !== 'related_area') return change.tkpi_value_dto.actual;
    if (type === 'tkpi_previous' && change.name !== 'related_area') return change.tkpi_value_dto.previous;

    if (type === 'actual' && change.name === 'related_area') {
      return this.language === 'es'
        ? change.actual?.area_entity.clasification_text || '-'
        : change.actual?.area_entity.clasification_text_en || '-';
    }

    if (type === 'previous' && change.name === 'related_area') {
      return this.language === 'es'
        ? change.previous?.area_entity.clasification_text || '-'
        : change.previous?.area_entity.clasification_text_en || '-';
    }

    if (type === 'tkpi' && change.name === 'related_area') {
      return this.language === 'es'
        ? change.tkpi_value_dto.actual?.area_entity.clasification_text || '-'
        : change.tkpi_value_dto.actual?.area_entity.clasification_text_en || '-';
    }

    if (type === 'tkpi_previous' && change.name === 'related_area') {
      return this.language === 'es'
        ? change.tkpi_value_dto.previous?.area_entity.clasification_text || '-'
        : change.tkpi_value_dto.previous?.area_entity.clasification_text_en || '-';
    }
  }

  handleDowloadEvent($event) {
    const downloadObj = {
      file_key: $event,
      downloading: true,
    };
    this.waitingFileDownload.push(downloadObj);
    const key = $event;
    this.filesService.downloadFile(this.customerId, key).subscribe(() => {
      this.waitingFileDownload = this.waitingFileDownload.map((file) => {
        if (file.file_key === key) file.downloading = false;
        return file;
      });
    });
  }

  get sortedResources() {
    if (this.resources) {
      return this.resources.filter(Boolean).sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1));
    }
    return [];
  }
}
