import { Directive, HostListener, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[navbarActions]'
})
export class NavbarActionsDirective {

  @Input('navbarActions') parentName: string;
  @Input() item: any;
  @HostBinding('class.active') isActive = false;


  constructor() { }

  @HostListener('click', ['$event']) handleClick(event: Event) {
    let section = (<HTMLInputElement>event.currentTarget).getAttribute('data-section')
    this.toogleActive(section);
  }

  ngOnInit(){
    this.toogleActive(this.item.flag)
  }


  private toogleActive(section) {
    this.isActive = this.parentName.includes(section)
  }
}