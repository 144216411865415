<mh-box-element [title]="title">
  <div class="box box-semantic" (mouseenter)="setNeutralValue('in')" (mouseleave)="setNeutralValue('out')" >

    <ng-container *ngIf="waiting">
      <widget-wrapper-wait [width]="3" [drawTop]="[1,0,1]" [drawBottom]="[0,0,0]" [drawMiddle]="[1,0,1]" [height]="30">
        <ng-container *ngTemplateOutlet="cardSemanticIndicator"></ng-container>
      </widget-wrapper-wait>
    </ng-container>
    <div *ngIf="!waiting">
      <ng-container *ngTemplateOutlet="cardSemanticIndicator"></ng-container>
    </div>

  </div>
</mh-box-element>

<ng-template #cardSemanticIndicator>
  <div class="row align-items-center no-gutters full-height">
    <div class="col-6">
      <div class="box-number negative">
        <div class="title">{{'inter-chain.executive.table.semantic_negative' | translate}}</div>
        <div class="number">{{negatives | formatPercent:true:false:true}}</div>
      </div>
    </div>

    <div class="col-6">
      <div class="box-number positive">
        <div class="title">{{'inter-chain.executive.table.semantic_positive' | translate}}</div>
        <div class="number">{{positives | formatPercent:true:false:true}}</div>
      </div>
    </div>
  </div>
</ng-template>
