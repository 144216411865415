<div class="guest_box" [attr.data-guest-id]="id">
  <ng-container *ngIf="isFlagUp">
  <ng-container *ngIf="isPrestay && countryCode && countryCode.toUpperCase() !=='XX'; else notCountry" >
    <div class="flag" [class.centered]="isFromModal">
      <widget-country-flag [countryCode]="countryCode" [size]="50"></widget-country-flag>
      <div class="country_name">{{ 'countries.'+countryCode | translate }}</div>
    </div>
  </ng-container>
  </ng-container>
  <div class="guest_info" [ngClass]="classAnonymous" *ngIf="isAnonymous && !isPrestay">
    <img
      [src]="defaultPhoto"
      alt="user profile photo"
      class="mr-3 thumbnail-img"
      [class.my-3]="isCardView"
      />
    <div class="name ellipsis">{{ 'commons.anonymous' | translate:{type: (typeTitle | translate)} }}</div>
  </div>

  <div class="guest_info" *ngIf="!isAnonymous">
    <div class="name ellipsis" [title]="fullName | titlecase">{{fullName}}</div>
    <div class="personal-detail ellipsis" *ngIf="email" >
      <a [href]="'mailto:'+(email | lowercase)">{{email | lowercase}}</a>
    </div>
    <div class="personal-detail ellipsis" *ngIf="!isPrestay">
      {{ 'commons.phone_short' | translate }}:
      <span *ngIf="phone; else elseEmpty">
        <a target="_blank" [href]="'https://api.whatsapp.com/send?phone='+ phone +'&text=' + ('commons.guest_greetings' | translate)">{{phone}}</a>
      </span>
    </div>
    <div class="personal-detail ellipsis" *ngIf="!isCardView && !isPrestay && !isFromModal">
      {{ 'commons.fly_code' | translate }}:
      <span *ngIf="reservationId; else elseEmpty">{{reservationId}}</span>
    </div>
    <ng-container *ngIf="isFlagDown">
    <ng-container *ngIf="!isPrestay && countryCode && countryCode.toUpperCase() !=='XX'">
      <div class="flag" [class.centered]="isFromModal" [ngClass]="classCountry">
        <widget-country-flag [countryCode]="countryCode" [size]="'20'"></widget-country-flag>
        <div class="country_name personal-detail">{{ 'countries.'+countryCode | translate }}</div>
      </div>
    </ng-container>
    </ng-container>

  </div>

</div>

<ng-template #notCountry>
  <div class="letters">
    <ng-container *ngIf="isPrestay">
      <widget-circle-letters [letters]="letters" [satisfaction]="satisfaction" [size]="50"></widget-circle-letters>
    </ng-container>
  </div>
</ng-template>

<ng-template #elseEmpty>
  -
</ng-template>
