<section class="tw-relative tw-cursor-pointer tw-w-full" id="{{ inputId }}" (click)="toggleBox()" [formGroup]="formGroup" cdkOverlayOrigin #trigger="cdkOverlayOrigin" #inputSection>
  <input
    class="tw-w-full tw-rounded-xl focus-visible:tw-border-border-main focus-visible:tw-outline-0 tw-py-2 tw-px-5 tw-border-border-light-gray tw-text-main-text focus-visible:tw-border-2 tw-border disabled:tw-bg-white tw-cursor-pointer tw-select-none {{
      customClasses
    }}"
    [placeholder]="placeholder"
    [value]="displayValue"
    [ngClass]="{ '!tw-bg-second-disabled': disabled }"
    readonly
  />
  <input [formControlName]="keyControl" hidden />
  <div
    class="tw-absolute tw-flex tw-justify-center tw-right-[10px] tw-inset-y-0 tw-w-[20px] tw-h-[20px] tw-m-auto tw-text-main"
    [@rotate]="openBox ? '180' : 0"
  >
    <i class="{{ iconClass }}"></i>
  </div>
</section>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="openBox"
  [cdkConnectedOverlayWidth]="inputWidth"
>
  <mh-core-lib-generic-box #optionsMenu id="optionsMenu" customClass="tw-mt-2 !tw-py-2">
    <section class="tw-py-2 tw-max-h-[160px] tw-overflow-auto tw-w-full"  [style.width]="(inputWidth - 24) + 'px'">
      <ng-container *ngFor="let option of options">
        <div
          class="tw-py-1.5 tw-text-main-text tw-cursor-pointer hover:tw-text-main-orange tw-transition-all select-option"
          (click)="selectOption(option)"
        >
          {{ displayFn(option) }}
        </div>
      </ng-container>
    </section>
  </mh-core-lib-generic-box>
</ng-template>
