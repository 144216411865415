import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from 'app/shared/util.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'widget-half-pie',
  templateUrl: './half-pie.component.html',
  styleUrls: ['./half-pie.component.scss']
})
export class HalfPieComponent implements OnInit {

  @Input()
  percent = 0;

  @Input()
  tooltip: any;

  @Input()
  title = '';

  @Input()
  modalTitle = '';

  @Input()
  legend = '';

  @Input()
  qualify: 'very_good' | 'good' | 'regular' | 'bad' | 'very_bad';

  @Input()
  noData = false;

  @Input()
  waiting = false;

  @Input()
  showModal = true;

  color = 'primary';
  mode = 'determinate';
  value = 0;
  formatPercent = '0%';

  modal:NgbModalRef;

  constructor(private modalService: NgbModal, private utilService: UtilService) {}

  ngOnInit(){}

  ngOnChanges() {
    this.formatPercent = this.utilService.formatPercent(this.percent);
    this.value = this.percent && this.percent >0 ? this.percent/2 : 0;
  }

  openDialog(content){
    this.modal = this.modalService.open(content, {
      windowClass: ''
    })
  }

  closeDialog(){
    if(this.modal) this.modal.close();
  }

}
