<mh-surveys-answer-guest #calling (showingSurvey)="showSurvey($event)" ></mh-surveys-answer-guest>

<div class="h-100" [attr.data-id]="survey.id" *ngIf="!survey.deleted">
  <div class="user_info_box h-100">

    <div class="wrapp_user h-100">
      <div class="row no-gutters" [class.justify-content-between]="fromComponent !== 'summary'"[ngClass]="{'align-items-center':viewType!='comment', 'adjust-row': isCardView}">
        <div [ngClass]="gridFirst" class="align-self-center" [attr.guest-id]="survey.guest.id">
          <div class="contacted-container" *ngIf="survey?.guest?.managed && viewType!='modal'">
            <div class="icon" [ngClass]="{ 'icon-card': isCardView }">
              <i class="fas fa-check-circle"
                placement="right"
                [ngbTooltip]="('cases.tooltips.managed_case' | translate:{ name: this.survey?.guest?.managed?.user?.profile?.complete_name || '', date: (survey?.guest?.managed?.action_date | date: 'dd-MM-y')})"></i>
            </div>
            <div class="contacted" [ngClass]="{ 'contacted-card': isCardView }">
            </div>
          </div>
          <div class="user_info">
            <widget-guest-info
              [countryCode]="survey.guest.country.code"
              [name]="survey.guest.name"
              [lastName]="survey.guest.lastName"
              [email]="survey.guest.email"
              [phone]="survey.guest.phone || survey.guest.phone_number"
              [reservationId]="survey.guest.reservation_id"
              [satisfaction]="survey.satisfaction.id"
              [layoutView]="viewType"
              [integrationChannel]="survey.integration_channel">
            </widget-guest-info>
          </div>
          <ng-container *ngIf="viewType!='modal' && !url.includes('huespedes')">
            <div role="button" class="see_survey" [class.see_survey_aligned]="fromComponent === 'summary'" (click)="calling.showModal(survey,productId,from + '_detail'); sendInfoToGA(survey)">
              {{'shared.semantic.categories.see_survey' | translate }}
            </div>
          </ng-container>
        </div>
        <div [ngClass]="gridSecond">
          <div class="center_info">

            <ng-container *ngFor="let comment of survey.comments">
              <div class="comment_box" [hidden]="viewType!='comment'">
                <p class="comment">{{previewComment(comment)}}</p>
                <div class="see_all" (click)="seeAllComment()" *ngIf="comment.hasExcerpt()" [hidden]="excerpt_is_open">
                  <span>{{ 'online.reviews.see_all_comment' | translate }}</span><i class="fal fa-chevron-down"></i>
                </div>

                <div class="action_box translate" (click)="translate(comment)"
                  *ngIf="surveyLanguage!=currentLanguage && comment.translate==undefined ">
                  <i class="fal fa-language"></i>
                  <span>{{'commons.translate' | translate }}</span>
                </div>

                <div class="result_comment">
                  <div class="wait_translate">
                    <mh-spinner-load
                      [hidden]="!waitTranslate || comment.translate!=undefined || waitTranslateId != comment.id ">
                    </mh-spinner-load>
                  </div>
                  <div class="body">{{comment?.translate}}</div>
                </div>

                <div *ngIf="comment.hasSentimentInside()" class="semantic">
                  <mh-semantic-header [productId]="productId" [commentId]="comment.id"></mh-semantic-header>
                </div>
              </div>
            </ng-container>

            <div class="aditional_info" [hidden]="!['guest','modal'].includes(viewType)">

              <div class="stay_time">
                <div class="row no-gutters align-items-center">
                  <div [ngClass]="gridSecondCol" *ngIf="isCardView && !isAnonymous">
                    <widget-guest-stay-nights
                      [icon]="'fas fa-barcode'"
                      [value]="survey.guest.reservation_id"
                      [text]="('commons.fly_code' | translate )"
                      [layoutView]="layoutView">
                    </widget-guest-stay-nights>
                  </div>
                  <div [ngClass]="gridSecondCol" *ngIf="!isAnonymous">
                    <ng-container *ngTemplateOutlet="calendar;context:{date: checkinDate, label: checkinLabel}">
                    </ng-container>
                  </div>
                  <div [ngClass]="gridSecondCol" *ngIf="!isAirline && !isAnonymous">
                    <ng-container *ngTemplateOutlet="calendar;context:{date: checkoutDate, label: checkoutLabel}">
                    </ng-container>
                  </div>
                  <div [ngClass]="gridSecondCol" *ngIf="!isAirline && !isAnonymous">
                    <widgets-arrow-label
                      [isAirline]="isAirline"
                      [labelInNigths]="showLabelNights"
                      [days]="survey.guest.stayDistance()"
                      [label]="showLabelNights ? ((productId=='2' ? 'commons.stay_nights.will_host' : 'commons.stay_nights.hosted') | translate) : null"
                      [layoutView]="layoutView">
                    </widgets-arrow-label>
                  </div>
                  <div [ngClass]="gridSecondCol" *ngIf="!isAnonymous && survey.guest?.room!==0">
                    <widget-guest-stay-nights
                      [icon]="isAirline ? 'fas fa-plane' : 'fas fa-bed'"
                      [value]="survey.guest.room"
                      [text]="('commons.'+placeTranslate | translate )"
                      [layoutView]="layoutView">
                    </widget-guest-stay-nights>
                  </div>
                  <div [ngClass]="gridSecondCol" [class.order-2]="isCardView" *ngIf="viewType!='modal'">
                    <div class="content_date">
                      <widget-calendar-date
                        [label]="('commons.date_answered' | translate)"
                        [date]="survey.answer.date"
                        [type]="'enphasis'"
                        [layoutView]="layoutView">
                      </widget-calendar-date>
                    </div>
                  </div>
                  <div [ngClass]="gridSecondCol" *ngIf="showQualify">
                    <widget-guest-qualify
                      [survey]="survey"
                      [layoutView]="layoutView">
                    </widget-guest-qualify>
                  </div>
                  <div [ngClass]="gridSecondCol" *ngIf="isCardView && viewType!='modal'">
                    <widget-guest-survey-info
                      [survey]="survey"
                      [icon]="'fas fa-user-circle'"
                      [value]="(survey.survey_name | translateObject:'title':language)"
                      [text]="('semantic.comments.item.survey_name' | translate)"
                      [layoutView]="layoutView">
                    </widget-guest-survey-info>
                  </div>
                  <div [ngClass]="gridSecondCol" *ngIf="isCardView && viewType!='modal'">
                    <widget-guest-survey-info
                      [survey]="survey"
                      [icon]="'fas fa-dot-circle'"
                      [value]="survey.origin_name"
                      [text]="('followup.survey.filter.origin_name'| translate)"
                      [layoutView]="layoutView">
                    </widget-guest-survey-info>
                  </div>
                  <div [ngClass]="gridSecondCol" [class.order-2]="isCardView" *ngIf="viewType!='modal'">
                      <widget-guest-rate-info
                        [survey]="survey"
                        [icon]="'fas fa-check'"
                        [value]="(survey.satisfaction.score | formatScore:2)"
                        [text]="('followup.summary.survey-comments.rate'| translate )"
                        [layoutView]="layoutView">
                      </widget-guest-rate-info>
                    </div>
                  <div class="text-center" [class.order-3]="isCardView" [ngClass]="gridSecondCol" *ngIf="viewType!='modal'">
                    <div [class.action-section]="isCardView" [ngClass]="{'d-flex align-items-center flex-column': !isCardView}">
                      <div *ngIf="viewType!='modal' && url.includes('huespedes')" [ngClass]="{'mb-2': !isCardView}">
                        <button class="btn btn-white" (click)="calling.showModal(survey,productId,from + '_detail');sendInfoToGA(survey)">
                          {{'shared.semantic.categories.see_survey' | translate }}
                        </button>
                      </div>
                      <mh-case-product-actions *ngIf="showCases"
                        [productItem]="productItem"
                        [cases]="survey.cases"
                        [sourceWindow]="from"
                        [survey]="survey"
                        class="mx-2"
                        data-html2canvas-ignore
                      ></mh-case-product-actions>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
      <ng-container *ngIf="viewType!='modal'">
        <div class="top_actions">
          <i class="far fa-angle-down" mat-icon-button [matMenuTriggerFor]="menu"></i>
          <mat-menu #menu="matMenu">
            <div class="action_top" mat-menu-item (click)="openDialog(show_dialog)" *ngIf="can_edit_survey">
              <i class="far fa-minus-circle"></i>
              <span>{{'shared.survey.delete_survey' | translate }}</span>
            </div>
            <div class="action_top" mat-menu-item (click)="markSurveyContacted()" *ngIf="!survey?.guest?.managed && url.includes('huespedes')">
              <span>{{'shared.survey.contacted_survey' | translate }}</span>
            </div>
            <div class="action_top" mat-menu-item (click)="openNewModal(newCaseForm)" *ngIf="survey?.cases?.length > 0">
              <span>{{'cases.commons.create_case' | translate }}</span>
            </div>
          </mat-menu>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #elseEmpty>
  -
</ng-template>
<ng-template #tooltipComment>
  <div [innerHtml]="('followup.summary.comments.tooltips.comment' | translate: ({guest: survey.guest}) )"></div>
</ng-template>

<ng-template #calendar let-date="date" let-label="label">
  <div class="content_date">
    <widget-calendar-date
      [label]="('commons.'+label | translate )"
      [date]="date"
      [layoutView]="layoutView">
    </widget-calendar-date>
  </div>
</ng-template>

<ng-template #show_dialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="title"
      [innerHtml]="('shared.survey.confirm_delete' | translate:({user: util.currentUser }) )">
    </h4>
  </div>

  <div class="modal-body">
    <div class="dialog_options">
      <div class="fade_waiting" [hidden]="!waitDelete">
        <mh-spinner-load></mh-spinner-load>
      </div>

      <div class="row" [hidden]="waitDelete">
        <div class="col-6">
          <button (click)="deleteAnswerSurvey()" class="btn btn-success">{{'commons.yes' | translate }}</button>
        </div>
        <div class="col-6">
          <button (click)="closeDialog()" class="btn btn-secondary">{{'commons.no' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #newCaseForm>
  <mh-modal [close]="closeModal" class="gray-background">
    <mh-new-case-form
      [users]="users"
      [productItem]="productItem"
      [survey]="survey"
      (onCreate)="afterCreate($event)"
    ></mh-new-case-form>
  </mh-modal>
</ng-template>
