import { Injectable }         from '@angular/core';
import { Hotel, Guest,User, PrestayMenu,ProductAdd }       from '../../../shared/model/index';
import { JwtHelperService }   from '@auth0/angular-jwt';
import { Subject }            from 'rxjs/internal/Subject';
import { Observable } from 'rxjs';
import { PSPropertiesForm } from 'app/shared/model/prestay/ps-properties-form.model';
import { SessionService } from 'app/_services/session.service';
import { AuthenticationService } from 'app/_services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PrestayUtilService {
  nickname: any;
  show_counter=false;
  jwtHelper: JwtHelperService = new JwtHelperService();
  customersReady      = new Subject<Guest>();
  refreshOrderSubj    = new Subject<boolean>();
  btnSendOrderSubj    = new Subject<string>();
  productsAdded:ProductAdd[] = [];
  subjectModalDetail = new Subject<any>();
  subjectPrecheckinDone = new Subject<any>();
  currentOrderConfirmed = false;
  reservationId         = 0;
  currentCurrency   = 'USD';
  currentLanguage   = 'es';
  waitConfirmOrder = false;
  loadingConfirmOrder = false;

  constructor(
    private authenticationService: AuthenticationService,
    private sessionService: SessionService){

  }

  getCurrentHotel(){
    return this.sessionService.getCurrentHotelPS();
  }

  setCurrenHotel(hotel:Hotel){
    this.sessionService.setCurrenHotelPS(hotel);
  }

  getCurrentHotelConfig(){
    return this.sessionService.getCurrentHotelPSConfig();
  }

  setCurrenHotelConfig(config:PSPropertiesForm){
    this.sessionService.setCurrentHotelPSConfig(config);
  }
  
  getCurrentGuest(){
    return this.sessionService.getCurrentGuest();
  }

  setCurrenGuest(guest:Guest){
    this.sessionService.setCurrenGuest(guest);
  }

  getGuestLoginToken() {
    this.sessionService.getGuestLoginToken();
  }

  setGuestLoginToken(token) {
    this.sessionService.setGuestLoginToken(token);
  }

  getGuestToken() {
    return this.sessionService.getGuestToken();
  }

  setGuestToken(token) {
    this.sessionService.setGuestToken(token);
  }

  setNickId(nickId) {
    this.nickname = nickId;
  }

  isCustomerReady(){
    return this.customersReady.asObservable();
  }

  isOrderRefreshed(){
    return this.refreshOrderSubj.asObservable();
  }

  isPressButtonSendOrder(){
    return this.btnSendOrderSubj.asObservable();
  }

  pressSendOrder(){
    this.btnSendOrderSubj.next();
  }

  restetOrder(){
    this.currentOrderConfirmed = false;
    this.productsAdded = [];
    this.refreshOrderSubj.next(true);
  }


  login(){
    let token: any  = this.getGuestToken();
    let decode = this.jwtHelper.decodeToken(token);
    let hotel  = this.getCurrentHotel();
    if(hotel.id===decode.customerId){
      let guest = new Guest();
      guest.createFromApi(decode.guest)
      this.setCurrenGuest(guest);
      this.customersReady.next(guest)
    }else{
      this.logout();
    }
  }

  loginByAdmin(hotel:Hotel,user:User){

    let guest      = new Guest();
    guest.id       = 0;
    let date       = new Date();
    guest.checkin  = this.plusHours(date,12);
    guest.checkout = this.plusHours(date,15);
    guest.name     = user.name;
    guest.lastName = user.lastName;
    guest.email    = user.email;
    const currentJWT: any = localStorage.getItem('currentJWT'); 
    let data        = JSON.parse(currentJWT);
    this.setCurrenGuest(guest);
    this.setCurrenHotel(hotel);
    this.setGuestToken(data.token);
  }

  isLogged(){
    let token: any = this.getGuestToken();
    let guest = this.getCurrentGuest();
    return guest && !this.jwtHelper.isTokenExpired(token);
  }

  logout(){
    localStorage.removeItem('currentPrestayHotelConfigurations'); // Sacar esta lógica de acá
    this.authenticationService.logout();
  }

  plusHours(date,hours){
    return new Date(date.setHours(date.getHours()+hours));
  }

  getMenu(){
    let hotel = this.getCurrentHotel()
    let nick  = this.nickname || hotel.nick_id;
    return [
      new PrestayMenu('services',nick),
      new PrestayMenu('precheckin',nick)
    ]
  }

  isModalDetailChange(): Observable<any> {
    return this.subjectModalDetail.asObservable();
  }

  isDoneCheckin(): Observable<any> {
    return this.subjectPrecheckinDone.asObservable();
  }

  emitModalDetail(info){
    this.subjectModalDetail.next(info);
  }

  emitDoneCheckin(){
    this.subjectPrecheckinDone.next(true);
  }

  prestayPath(url){
    return `/${this.nickname}/prestay/${url}`;
  }

}
