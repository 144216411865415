import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mh-core-lib-view-guests-selector',
  templateUrl: './view-guests-selector.component.html',
  styleUrls: ['./view-guests-selector.component.scss'],
})
export class CoreLibViewGuestsSelectorComponent {
  @Input() options: string[] = [];
  @Input() selectedType: string;
  @Input() disabled: boolean = false;
  @Output() selectedOption = new EventEmitter<string>();

  handleSelectedOption(option: string) {
    if (this.disabled) { // emit null for disabled value
      this.selectedOption.emit(null);
      return;
    } 
    this.selectedType = option;
    this.selectedOption.emit(option);
  }
}
