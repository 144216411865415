<ng-container *ngIf="isCreate else itemDetail">
  <ng-container *ngTemplateOutlet="itemCreate">
  </ng-container>
</ng-container>

<ng-template #itemCreate>
  <div class="tw-relative">

    <article class="tw-px-6 tw-py-4 tw-flex tw-flex-col tw-text-main-text">

      <section class="tw-self-center tw-flex tw-flex-col tw-items-center tw-gap-0.5 tw-mb-2">
        <span class="tw-font-medium">{{order.guest.name}} {{order.guest.lastname}}</span>
        <span class="tw-text-sm">{{order.guest.email}}</span>
        <span class="tw-text-sm">{{order.guest.phone}}</span>
        <span class="tw-flex tw-items-center tw-gap-3">
          <span [ngStyle]="{'width': 20+'px', 'height': 20+'px'}" class="flag-icon flag-icon-squared flag-icon-{{order.guest.country_code.toLowerCase()}} tw-rounded-full"></span>
          <span class="tw-text-sm tw-font-medium tw-self-center">{{'countries.'+order.guest.country_code | translate}}</span>
        </span>
      </section>

      <section class="tw-flex tw-flex-col tw-gap-1">
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="fas fa-portrait tw-mr-2 tw-w-4"></i> <span>{{'models.prestay_login.reservation_code' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{order.guest.reservation_id}}</span>
        </div>
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="fas fa-barcode tw-mr-2 tw-w-4"></i> <span>{{'models.order.order_id' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{order.order.id}}</span>
        </div>
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="fas fa-calendar-alt tw-mr-2 tw-w-4"></i> <span>{{'models.order.created_at' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{order.order.created_at | formatDate: 'es'}}</span>
        </div>
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="far fa-calendar-alt tw-mr-2 tw-w-4"></i> <span>{{'models.order.checkin' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{order.guest.checkin | formatDate: 'es'}}</span>
        </div>
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="fas fa-calendar-alt tw-mr-2 tw-w-4"></i> <span>{{'models.order.checkout' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{order.guest.checkout | date: 'shortTime'}}</span>
        </div>
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="fas fa-bed tw-mr-2 tw-w-4"></i> <span>{{'models.order.room' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{order.guest.room}}</span>
        </div>
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="fas fa-concierge-bell tw-mr-2 tw-w-4"></i> <span>{{'models.order.total_quantity' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{order.order.total_quantity}}</span>
        </div>
        <div class="tw-flex tw-gap-2">
          <span class="tw-basis-3/5 tw-font-medium">
            <i class="fas fa-usd-circle tw-mr-2 tw-w-4"></i> <span>{{'models.order.total_price' | translate}}</span>
          </span>
          <span class="tw-basis-2/5 tw-font-bold">{{order.order.total_price | currency}}</span>
        </div>
      </section>

    </article>

  </div>
</ng-template>

<ng-template #itemDetail>
  <div class="tw-hidden md:tw-block">
    <ng-container *ngTemplateOutlet="desktopDetail"></ng-container>
  </div>
  <div class="md:tw-hidden">
    <ng-container *ngTemplateOutlet="mobileDetail"></ng-container>
  </div>
</ng-template>

<ng-template #mobileDetail>
  <div class="tw-px-2 tw-py-6 tw-flex tw-flex-col tw-text-main-text">
    <!-- info huesped -->
    <span class="tw-font-semibold tw-text-sm">{{order.guest.name}} {{order.guest.last_name}}</span>
    <span class="tw-text-sm">{{order.guest.email}}</span>
    <div class="tw-flex tw-gap-5">
        <i class="fas fa-calendar"></i>
        <span class="tw-text-sm">{{'models.order.created_at' | translate}}: <span class="tw-font-semibold">{{order.orders[0].created_at | formatDate}}</span></span>
    </div>
    <div class="tw-flex tw-gap-5">
        <i class="fas fa-barcode"></i>
        <span class="tw-text-sm">{{'models.order.reservation_id' | translate}}: <span class="tw-font-semibold">{{order.guest.reservation_id}}</span></span>
    </div>
    <div class="tw-flex tw-gap-5">
        <i class="fas fa-barcode"></i>
        <span class="tw-text-sm">{{'models.order.order_id' | translate}}: <span class="tw-font-semibold">MH-{{order.orders[0].id}}</span></span>
    </div>
    <div class="tw-flex tw-gap-5">
        <i class="fas fa-shopping-cart"></i>
        <span class="tw-text-sm">{{'models.order.total_quantity'}}: <span class="tw-font-semibold">{{order.orders.length}}</span></span>
    </div>
    <div class="tw-flex tw-gap-5">
        <i class="fas fa-dollar-sign"></i>
        <span class="tw-text-sm">{{'models.order.total_price' | translate}}: <span class="tw-font-semibold">{{order.totals[0].total_price}}</span></span>
    </div>
    <a href="{{url}}" target="_blank" class="tw-text-main tw-font-semibold">{{'commons.see_detail' | translate}}</a>
  </div>

</ng-template>

<ng-template #desktopDetail>
  <div class="tw-px-5 tw-py-6 tw-flex">
    <!-- info huesped -->
    <section class="tw-min-w-[200px] tw-flex tw-flex-col tw-text-main-text">
      <span class="tw-font-semibold tw-text-sm">{{order.guest.name}} {{order.guest.last_name}}</span>
      <span class="tw-text-sm">{{order.guest.email}}</span>
      <a href="{{url}}" target="_blank" class="tw-text-main tw-font-semibold">{{'commons.see_detail' | translate}}</a>
    </section>

    <!-- info orden -->
    <section class="tw-flex tw-gap-5 tw-text-main-text">
      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-calendar"></i>
        <span class="tw-text-sm">{{'models.order.created_at' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm">{{order.orders[0].created_at | formatDate}}</span>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-calendar"></i>
        <span class="tw-text-sm">{{'models.order.reservation_id' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm">{{order.guest.reservation_id}}</span>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-calendar"></i>
        <span class="tw-text-sm">{{'models.order.order_id' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm">MH-{{order.orders[0].id}}</span>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-calendar"></i>
        <span class="tw-text-sm">{{'models.order.total_quantity' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm">{{order.orders.length}}</span>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-1 tw-items-center">
        <i class="fas fa-calendar"></i>
        <span class="tw-text-sm">{{'models.order.total_price' | translate}}</span>
        <span class="tw-font-semibold tw-text-sm">{{order.totals[0].total_price}}</span>
      </div>
    </section>

  </div>
</ng-template>
