import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import { UtilService } from 'app/layouts/main';

@Component({
  selector: 'widget-modal-faq',
  templateUrl: './modal-faq.component.html',
  styleUrls: ['./modal-faq.component.scss']
})
export class ModalFaqComponent implements OnInit {

  @Input()
  faqs;

  @Input()
  prefixTranslate;

  @Input()
  showWeightedAverage: boolean = false;

  explainedFormula:string;

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService,
    private utilService: UtilService) {


      this.utilService.isLanguageChanged().subscribe(response=>{
        this.setTranslateAverage();
      });
    }

  ngOnInit() {
    this.setTranslateAverage();
  }

  setTranslateAverage() {
    this.translate.get(['commons.average_formula']).subscribe(response => {
      const average_formula = response['commons.average_formula'];

      this.explainedFormula   = average_formula.explained_formula;
    });
  }
  openModal(content) {
    this.modalService.open(content, {
      windowClass: 'modal-xl modal-general'
    });
  }

}
