<div class="tw-relative tw-h-full">
  <div class="tw-w-full tw-text-center tw-py-2 tw-bg-main-text tw-relative tw-h-[15%] tw-grid tw-place-content-center">
    <span class="tw-font-semibold tw-text-lg tw-text-white">{{'admin.otas.modal_title' | translate}}</span>
    <div class="tw-absolute tw-w-[16px] tw-h-[16px] tw-inset-y-0 tw-my-auto tw-right-[10px] tw-grid tw-place-content-center">
      <button class="focus:tw-outline-0" (click)="handleClose()">
        <i class="fas fa-times tw-text-xl tw-text-white"></i>
      </button>
    </div>
  </div>
  <div class="tw-pt-3 tw-px-3 tw-grid tw-grid-cols-[40%_repeat(4,_1fr)]" [ngClass]="{'tw-h-[85%]': isMenuContextCorporative, 'tw-overflow-y-scroll': isMenuContextCorporative}" >
    <div class="tw-font-bold">
      {{'navbar.product.default.properties' | translate}}
    </div>
    <div class="tw-font-bold tw-text-center">
      Google
    </div>
    <div class="tw-font-bold tw-text-center">
      Facebook
    </div>
    <div class="tw-font-bold tw-text-center">
      Booking
    </div>
    <div class="tw-font-bold tw-text-center">
      TripAdvisor
    </div>
    
    <ng-container *ngIf="otasErrors && otasErrors.length > 0">
      <ng-container *ngFor="let otaError of otasErrors">
        <div class="tw-py-5 tw-border-t-2">
          {{otaError.customer.name}}
        </div>
        <ng-container *ngFor="let otaId of OTAS">
          <div class="tw-grid tw-place-content-center tw-border-t-2">
            <ng-container *ngIf="isOtaPresent(otaId, otaError.status) else empty">
              <ng-container *ngIf="existError(otaId, otaError.status) else ok">
                <button class="tw-bg-[#ff2021] tw-text-white tw-font-semibold tw-px-1.5 tw-py-0.5 tw-rounded-md" (click)="handleConnect(otaError.customer, otaId)">
                  {{'commons.connect' | translate}}
                </button>
              </ng-container>

              <ng-template #ok>
                  <div class="tw-w-[16px] tw-h-[16px] tw-rounded-full"
                  [ngStyle]="{'background-color': statusColorMap[customerOtaMap?.get( otaError?.customer?.id )?.get(otaId)?.status_color] || 'transparent'}"
                  >
                  </div>
              </ng-template>
              
            </ng-container>
          </div>
        </ng-container>
    </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #empty>
  <div></div>
</ng-template>
