import { Component, Input,Output,EventEmitter, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms';
import { UtilService } from 'app/shared/util.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export class Option{
  public name: string;
  public value: any;
  public original: any;
  constructor(value:any,name:string, original:any){
    this.name     = name;
    this.value    = value;
    this.original = original;
  }
}

@Component({
  selector: 'widget-list-options',
  templateUrl: './list-options.component.html',
  styleUrls: ['./list-options.component.scss']
})
export class ListOptionsComponent implements OnInit {
  @Input()
  list:any[]=[]

  @Input()
  preset:string;

  @Input()
  name:string='not_defined';

  @Input()
  prefixTranslate='';

  @Input()
  selected:any; // Solo si cache es false

  @Input()
  label;

  @Input()
  tooltip = null;

  @Input()
  labelAction;

  @Input()
  keyValue;

  @Input()
  keyName;

  @Input()
  disabled=false;

  @Input()
  cache=true;

  @Input()
  description;

  @Input()
  viewType: 'normal' | 'button' = 'normal';

  @Input()
  size: 'normal' | 'responsive' = 'normal';

  @Input()
  autocomplete: boolean

  @Input()
  style: string = '';

  @Output()
  select: EventEmitter<Option> = new EventEmitter();

  selectEmited;

  options: Option[] = [];
  filteredOptions: Observable<Option[]>
  storage_option: string = 'list-options';

  private hasTranslatePoint=false;
  autoControl = new FormControl();


  constructor(
    private utilService: UtilService,
  ){
  }

  ngOnInit(){
    if (this.disabled) {
      this.autoControl.disable();
    }
    this.storage_option = this.storage_option+'-'+this.name;
    this.loadOptions();
  }

  private initAutocomplete() {
    if (this.selected) {
      this.autoControl.setValue(this.utilService.getTranslatedValue(this.getConvertedSelected(this.selected)))
    }
    
    this.filteredOptions = this.autoControl.valueChanges
    .pipe(
      startWith(''),
      map(option => option ? this.filterOptions(option) : this.options.slice())
    )
  }

  private getConvertedSelected(selected: any) {
    if(selected.length < 3 && (typeof selected === "string")) { // if selected length is less than 3 chars, it is country. Ex. 'CL'
      return `countries.${selected}`
    } else { // it is city and number. Ex. '4687'
      const select = this.options.find(x => x.value===selected);
      return select?.name
    }
  }

  private filterOptions(value: string) {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.original.name.toLowerCase().indexOf(filterValue) === 0);
  }

  loadOptions(){
    this.options = [];
    if(this.prefixTranslate !== '' && !this.hasTranslatePoint){
      this.prefixTranslate = this.prefixTranslate+'.';
      this.hasTranslatePoint = true;
    }
    this.getPreset()
    for(let element of this.list){
      let value = this.keyValue ? element[this.keyValue] : element;
      let name  = this.getNameOption(element);
      this.options.push(new Option(value,name,element))
    }

    if (this.selected === undefined && this.cache) {
      this.getOptionSelected();
      this.emitSelect();
    }
    if (this.autocomplete && this.selected) {
      this.initAutocomplete();
    }
  }

  ngOnChanges(changes) {
    if(changes.list) this.loadOptions();
  }

  displayFn(opt: string) {
    if (!opt) return '';
    return this.utilService.getTranslatedValue(opt)
  }

  getOptionSelected(){
    const value = localStorage.getItem(this.storage_option) || this.options[0].value;
    if(this.cache) this.selected = value;
  }

  changeMetric(select: any){
    this.selected = select
    localStorage.setItem(this.storage_option, this.selected);
    this.emitSelect();
  }

  emitSelect(){
    const select = this.options.find(x => this.autocomplete ? x.name===this.selected : x.value===this.selected.value);
    this.selectEmited = select?.value;
    this.select.emit(select);
  }

  getNameOption(element){
    let nameKey = this.keyName ? element[this.keyName] : element;
    let name    = element[this.keyValue]===null ? 'forms.none' : this.prefixTranslate+nameKey;

    if(this.preset==='percents') name = name+'%';
    return name;
  }

  getPreset(){
    if(this.preset==='percents' && this.list.length===0){
      let size = new Array(21);
      for (let i = 0; i < size.length; ++i) {
        this.list.push((i*5));
      }
    }
  }

}



