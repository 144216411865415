export const LINKS_UPSELL_PS = [
  {
    productName: 'checkin',
    es: 'https://myhotel.cl/fidelity-web-check-in/?utm_source=fidelity&utm_medium=button&utm_id=upsell_servicios',
    pt: 'https://myhotel.cl/pt/fidelity-check-in-pt/?utm_source=fidelity&utm_medium=button&utm_id=upsell_servicios',
    en: 'https://myhotel.cl/en/fidelity-web-check-in-en/?utm_source=fidelity&utm_medium=button&utm_id=upsell_servicios'
  },
  {
    productName: 'service',
    es: 'https://myhotel.cl/fidelity-upsell/?utm_source=fidelity&utm_medium=button&utm_id=upsell_servicios',
    pt: 'https://myhotel.cl/pt/fidelity-upsell-pt/?utm_source=fidelity&utm_medium=button&utm_id=upsell_servicios',
    en: 'https://myhotel.cl/en/fidelity-upsell-en/?utm_source=fidelity&utm_medium=button&utm_id=upsell_servicios'
  },
];
