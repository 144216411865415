<mh-alerts-wrapper>
  <mh-core-lib-content>
    <section head class="tw-flex tw-flex-col tw-justify-center">
      <mh-voices-alert class="tw-inline-block tw-self-center" *ngIf="showBetaAlert"></mh-voices-alert>
      <ng-container *ngIf="!isConfigPage">
        <mh-core-lib-header-content *ngIf="!loading">
          <section class="tw-flex tw-justify-center md:tw-justify-between">
            <article class="tw-flex tw-space-x-4" [class.tw-hidden]="origins.length === 0">
              <article>
                <mh-core-lib-datepicker
                  [currentDates]="currentDates"
                  [currentFilter]="currentFilterDate"
                  (datepickerUpdated)="handleDatepickerUpdated($event)"
                >
                </mh-core-lib-datepicker>
              </article>
              <article>
                <mh-origin-selector
                  [origins]="origins"
                  (selectedEmitter)="filterByOrigin($event)"
                ></mh-origin-selector>
              </article>
            </article>

            <article class="tw-hidden sm:tw-block">
              <mh-core-lib-button class="tw-mt-6 tw-mr-2" [isSecondary]="true" [text]="('voices.opinions.wizard.close_tutorial' | translate)" (click)="voicesService.showWizard = false" *ngIf="voicesService.showWizard && origins.length === 0"></mh-core-lib-button>
              <mh-core-lib-button class="tw-mt-6 tw-mr-2" [isSecondary]="true" [text]="('voices.opinions.wizard.open_tutorial' | translate)" (click)="voicesService.showWizard = true" *ngIf="!voicesService.showWizard && origins.length === 0"></mh-core-lib-button>
              <mh-core-lib-button class="tw-mt-6" [isDefault]="true" [text]="('voices.opinions.origins' | translate)" [width]="200" (click)="openOrigins()"></mh-core-lib-button>
            </article>
          </section>
        </mh-core-lib-header-content>
      </ng-container>
    </section>

    <ng-container main-content>
      <router-outlet></router-outlet>
    </ng-container>
  </mh-core-lib-content>
</mh-alerts-wrapper>
