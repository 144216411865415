import Alternative from './Alternative.model'
import Answer from './Answer.model'

export default class Question {
  id: number;
  required: boolean;
  alternatives: Alternative[];
  conditional_order: number;
  created: string; // date string, could be transformed
  flag_pregunt_standard: boolean;
  order: number
  tags: any[]
  conditional_alternative_id?: number;

  _conditional_questions: {[questionId:number]: {id: number, conditionalIds: number[]}} | any;
  conditional_question_id: number; // this is for when it comes as relationship

  valid:boolean = true;

  static createFromApi(obj: any) {
    return Object.assign((new Question()), {
      ...obj,
      alternatives: obj.alternatives.map(a => Alternative.createFromApi(a)),
    })
  }

  updateState(answers: Answer[]) {
    this.alternatives.forEach(alt => alt.updateState(answers));

    this.valid = this.isAnswered() || !this.required
  }

  isAnswered() {
    if (this.type === 'grid') {
      return this.alternatives.map(a => a.answered).every(Boolean)
    }

    return this.alternatives.map(a => a.answered).some(Boolean)
  }

  get conditional_questions() {
    if (!this._conditional_questions)
      this.setConditionalQuestions()

    return this._conditional_questions
  }

  setConditionalQuestions() {
    if (this.type !== 'grid') {
      this._conditional_questions = this.alternatives
        .reduce((conditionals, alt) => [...conditionals, ...alt.conditional_questions], []);
      return;
    }

    this._conditional_questions = this.alternatives
      .reduce((subs, alt) => [...subs, ...alt.sub_alternatives], [])
      .reduce((conditionals, alt) => [...conditionals, ...alt.conditional_questions], [])
  }

  get type() {
    return this.alternatives[0].alternative_type.name
  }
  get typeId() {
    return this.alternatives[0].alternative_type.id
  }
}
