import { Component, Input } from '@angular/core';
import { User,Hotel } from '../../../../shared/model/index';
import { Action } from '../action.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../../users.service';
@Component({
  selector: 'mh-user-products-edit',
  templateUrl: './user-product-edit.component.html',
  styleUrls: ['../../../../../assets/scss/configuration/product-edit.scss'],
  providers:[UsersService]
})
export class UserProductEditComponent{

  @Input()
  action:Action;

  @Input()
  customer:Hotel;

  @Input()
  user:User;

  @Input()
  product:string;

  title:string='';
  waiting=true;

  constructor(private modalService: NgbModal){

  }

  ngOnChanges() {
    this.title = 'configuration.users.actions.'+this.action.label
  }

  openModal(content) {
    this.modalService.open(content, {
      windowClass: 'modal-confirm left',
      size: 'xl'
    });
  }

  setWaiting($wating){
    setTimeout(_ => {
      this.waiting = $wating;
    });
  }


  is(action){
    return this.action.label === action
  }

}

