import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../../../../environments/environment";
import { map } from 'rxjs/operators';
@Injectable()
export class ChangeImageService {

  constructor(private http:HttpClient) { }

  uploadImage(customer_id,user_id,file,extension){
    const url=`${environment.customerPath}customer/${customer_id}/files/upload`
    const data = {
      file: file,
      user_id: user_id,
      extension: extension
    }
    return this.http.post(url,data)
      .pipe(
        map(response=>response)
      );
  }
}
