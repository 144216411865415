<mh-surveys-answer-guest #calling ></mh-surveys-answer-guest>

<div class="box box-comment">
  <div class="row no-gutters">
    <div class="col-2">
      <widget-guest-info
        [countryCode]="guest?.country?.code"
        [name]="guest?.name"
        [lastName]="guest?.lastName"
        [email]="guest?.email"
        [phone]="guest?.phone_number"
        [reservationId]="guest.reservation_id"
        [integrationChannel]="survey[0].surveys[0].integration_channel">
      </widget-guest-info>

      <button class="btn btn-link" (click)="calling.showModal(surveyGuest,productId)">
        {{'shared.semantic.categories.see_survey' | translate }}
      </button>

    </div>
    <div class="col-10">
      <div class="comment">
        <div class="original" *ngIf="!isDateType">{{commentGuest?.text}}</div>
        <div class="original" *ngIf="isDateType">{{comment}}</div>

        <button class="btn btn-link btn-icon-left" (click)="translate(comment)" *ngIf="surveyLanguage!=currentLanguage && !isDateType" [class.invisible]="!canTranslateComment">
          <i class="fal fa-language"></i>
          <span>{{'commons.translate' | translate }}</span>
        </button>

        <div class="translate">
          <div class="wait-translate">
            <mh-spinner-classic
              [hidden]="!waitTranslate">
            </mh-spinner-classic>
          </div>
          <div class="body" *ngIf="textTranslate">
            <span class="language-point" >{{currentCodeLanguage}}</span>
            <span>{{textTranslate}}</span>
          </div>
        </div>

        <div class="semantic" *ngIf="showSemantic">
          <mh-semantic-detail
            [productId]="productId"
            [semanticAreas]="commentGuest.semanticAreas"
            [showDetail]="!isRetail"></mh-semantic-detail>
        </div>

      </div>
    </div>
  </div>
</div>
