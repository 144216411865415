<div class="tw-flex tw-flex-col {{ additionalContainerClasses }}">
  <section class="tw-self-center tw-rounded-md tw-border tw-border-border-light-gray tw-px-2 tw-py-1 tw-relative tw-cursor-pointer tw-w-full" (click)="toggle()" *ngIf="selectedOptions.length === 0">
    {{placeholder}}
    <i [@rotate]="isOpen ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-absolute tw-right-2 tw-top-2"></i>
  </section>


  <ng-container *ngIf="selectedOptions.length > 0">
    <section class="tw-bg-white tw-rounded-md tw-max-h-[90px] tw-shadow-xl tw-p-1 tw-cursor-pointer" (click)="toggle()">
      <ng-container *ngFor="let option of selectedOptions">
        <div class="tw-m-1 tw-rounded-full tw-py-1 tw-px-2 tw-font-medium tw-text-xs tw-text-white tw-bg-main tw-max-w-fit tw-inline-block">
          <ng-container *ngIf="!isCountry">
            {{option[displaySelectedValue]}}
          </ng-container>
          <ng-container *ngIf="isCountry">
            {{translatePrefix + option.text | translate}}
          </ng-container>

          <span class="tw-ml-1 tw-bg-white tw-text-main tw-rounded-full tw-w-fit tw-px-1 tw-cursor-pointer chip-no-close" (click)="handleRemove(option)">
            <i class="fas fa-times tw-text-3xs"></i>
          </span>
        </div>
      </ng-container>
    </section>
  </ng-container>

  <section *ngIf="isOpen" class="tw-self-center tw-shadow-xl tw-rounded-md tw-mt-2 tw-py-1 tw-px-2 tw-w-full tw-overflow-y-auto tw-bg-white tw-z-10 tw-max-h-[200px]">
    <ul>
      <li *ngFor="let option of options" (click)="handleClick(option)" class="tw-px-2 tw-py-1 tw-cursor-pointer tw-text-main-text hover:tw-text-main tw-transition-all generic-option">
        {{translatePrefix + option.text | translate}}
      </li>
    </ul>
  </section>

</div>
