<button
  class="tw-rounded-full md:tw-w-fit tw-w-14 tw-h-14 tw-bg-white tw-text-main tw-shadow-1 tw-flex tw-justify-center md:tw-px-7 md:tw-py-2 focus:tw-outline-0"
  (click)="isOpen = !isOpen"
  cdkOverlayOrigin #trigger="cdkOverlayOrigin"
  [class.!tw-bg-main]="isOpen"
  [class.!tw-text-white]="isOpen"
>
  <i class="fas fa-search tw-self-center"></i>
  <span class="tw-hidden md:tw-inline-block tw-ml-2 tw-text-base tw-font-medium tw-self-center tw-text-main-text" [class.!tw-text-white]="isOpen">
    <ng-container *ngIf="!isAirline && !isRestaurant">
      {{'core-lib.selectors.guest.button' | translate}}
    </ng-container>
    <ng-container *ngIf="isAirline">
      {{'core-lib.selectors.guest.button_passenger' | translate}}
    </ng-container>
    <ng-container *ngIf="isRestaurant">
      {{'core-lib.selectors.guest.button_customer' | translate}}
    </ng-container>
  </span>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
>
<div id="overlaySelectorGuest" class="tw-mt-3">

  <mh-core-lib-generic-box customClass="tw-h-fit">
    <mh-core-lib-autocomplete-input
        [options]="guests"
        [openBox]="true"
        [showGlassIcon]="true"
        [hasFirstTimeOptions]="hasOptions"
        [showOptions]="false"
        (writtenText)="writtenText.emit($event)"
        >
    </mh-core-lib-autocomplete-input>
  </mh-core-lib-generic-box>
</div>
</ng-template>
