import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'mh-voices-alert',
  templateUrl: './voices-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoicesAlertComponent implements OnInit, OnDestroy {
  @Select(DefaultLanguageState.languageCode) languageReady$: Observable<any>;
  url;
  langSubscription: Subscription;

  constructor(private store: Store) {}

  ngOnInit(): void {
    const langCode = this.store.selectSnapshot(DefaultLanguageState.languageCode);
    this.url = langCode === 'pt' ? 'https://youtu.be/NOomBGPHffs' : 'https://youtu.be/N_rqupe1Av8';
    this.langSubscription = this.languageReady$.subscribe((code) => {
      this.url = code === 'pt' ? 'https://youtu.be/NOomBGPHffs' : 'https://youtu.be/N_rqupe1Av8';
    });
  }

  ngOnDestroy(): void {
    this.langSubscription.unsubscribe();
  }
}
