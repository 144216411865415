<div class="room text-wrap" [class.row]="isCardView" [ngClass]="classStay">
  <div class="col-2 text-center" *ngIf="isCardView">
    <i [ngClass]="icon"></i>
  </div>
  <div class="text" [ngClass]="classText">
    {{text}}
  </div>
  <div class="room-num" [class.col-5]="isCardView">
    <ng-container *ngIf="value; else elseEmpty">
      {{value}}
    </ng-container>
  </div>
</div>

<ng-template #elseEmpty>
  -
</ng-template>
