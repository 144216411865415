import { Log, LogType, User } from 'app/shared/logs/types'
import { Hotel } from 'app/shared/model';
import RESOURCE_PRODUCT_DICT from './resource_product_dict'

export function getUserIds(rawLogs: any[]): string[] {
  const newRawLogsUserId = rawLogs.filter(log => log.user_id >= 0)
  const newRawLogsUserTargetId = rawLogs.filter(log => log.user_target_id >= 0)
  return [...new Set([...newRawLogsUserId.map((log:any) => log.user_id), ...newRawLogsUserTargetId.map((log:any) => log.user_target_id)])]
}

export function getCustomerIds(rawLogs: any[]): string[] {
  const newRawLogsCustomerId = rawLogs.filter(log => log.customer_id >= 0)
  return [...new Set(newRawLogsCustomerId.map((log:any) => log.customer_id))]
}

export function transformLogs(logs: any[], users: User[], customers: Hotel[], isSuper: Boolean): Log[] {
  const filteredLogs = removeDuplicateObjects(logs)
    .filter(log => isPermittedLog(log, isSuper));

  return filteredLogs.map((log:any): Log => {
    let changes = (log.modifications || [])
      .filter((change: any) => isValidChange(change, log)) //filtrar modificaciones que no se quieren mostrar
      .map((change: any) => transformBoolean(change)) // algunos flags vienen con una estructura distina
      .map((change: any) => transformSpacedKeys(change)) // necesario por algunos cambios del backend

    const REVIEW_REPLY_RESOURCE = 'review_reply';

    if (REVIEW_REPLY_RESOURCE.includes(log.resource_name) || ['answered_survey', 'surveys', 'recalculate_stats'].includes(log.resource_name)) {
      changes = [log.log_content];
    }

    return {
      user: users.find(u => u.id === log.user_id),
      userTarget: users.find(u => u.id === log.user_target_id),
      customer: customers.find(c => c.id === log.customer_id),
      timestamp: new Date(log.timestamp),
      type: LogType[log.request_method as keyof LogType],
      requestMethodType: log.request_method_type,
      resourceName: log.resource_name,
      resourceId: log.resource_id,
      subResourceId: log.sub_resource_id,
      product: getLogProduct(log),
      changes,
    }
  })
}

function transformBoolean(change: any) {
  if (typeof change.actual === "string") { return change; }

  if ((change.actual === null || change.actual.active === undefined) &&
      change.previous === null || change.previous.active === undefined) { return change; }

  return {
    ...change,
    actual: change.actual && {...change.actual, name: change.actual.active},
    previous: change.previous && {...change.previous, name: change.previous.active}
  }
}
function transformSpacedKeys(change: any) {
  return {
    ...change,
    name: change.name.replace(' ', '_')
  }
}
function removeDuplicateObjects(objects: Object[]) {
  return objects
    .filter((obj, idx) => {
      const firstIdx = objects.findIndex(obj2 => equalObjects(obj['id'], obj2['id']))

      return idx === firstIdx;
    })
}
function equalObjects(obj1: object, obj2: object) {
  return Object.entries(obj1)
    .reduce((acum, [key, val]) => acum && obj2[key] === val, true)
}
function getLogProduct(log: any): string {
  // if review, puede ser onsite o followup
  if (log.resource_name === 'review') {
    return log.product === 4 ? 'onsite' : 'followup'
  }

  return Object.keys(RESOURCE_PRODUCT_DICT)
    .find(key => RESOURCE_PRODUCT_DICT[key].includes(log.resource_name))
    || 'other';
}

export function transformUsers(rawUsers: any[]): User[] {
  return rawUsers.map(rawUser => <User>({
    ...rawUser,
    title: rawUser.company_position,
    firstName: rawUser.first_name,
    lastName: rawUser.last_name,
    fullName: `${rawUser.first_name} ${rawUser.last_name}`,
  }))
}

function isValidChange(change:any, log:any) {
  //TODO: incluir logica para filtrar por permisos de usuarios.
  if((NOT_SHOWN_CHANGES[log.resource_name] || []).includes(change.name)) {
    return false
  }
  return true
}

function isPermittedLog(log, isSuper) {
  if (isSuper) return true

  return !(SUPER_ADMIN_CHANGES.includes(log.resource_name))
}

const SUPER_ADMIN_CHANGES = [
  'alert'
]
//TODO: parece que esto ya no se esta ocupando
const NOT_SHOWN_CHANGES = {
  customer: [
    '.country.code',
    '.country.code2',
    '.city.latitude',
    '.city.longitude',
    '.city.id',
    '.city.utc_offset',
    '.city.time_zone_id',
    '.city.country.code2',
    '.city.country_code',
    '.city.population',
    '.city.country.name',
    '.city.country.continent',
    '.city.country.language.code',
    '.city.country.language.id',
    '.property_type.id',
    '.currency.country',
    '.currency.unit',
    '.currency.symbol',
    '.currency.name',
    '.currency.id',
    '.country.continent',
    '.country.language.id',
    '.country.language.code',
    '.language.id',
    '.city.district',
  ]
}
