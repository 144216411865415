import { Component} from '@angular/core';
import { LayoutsComponent, UtilService } from '../../main/index';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import {
  GoogleAnalyticsService,
} from '../../../_services/google-analytics.service';
import { SessionService } from 'app/_services';

@Component({
    templateUrl: './public-theme.component.html',
    styleUrls: ['./public-theme.component.scss'],
})
export class PublicThemeComponent extends LayoutsComponent{

  widget:SafeResourceUrl;
  version:string;
  year;
  envName;
  sanitizer;
  util;
  myhotelLogoLogin = ''

  constructor(
    utilService:UtilService,
    sanitizer: DomSanitizer,
    private sessionService: SessionService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private activedRoute: ActivatedRoute){
    super(utilService)
    const idGA = environment.googleAnalyticsKey;
    this.googleAnalyticsService.appendGaTrackingCode(idGA);
    this.util = utilService;
    this.sanitizer = sanitizer;
    utilService.isLanguageChanged().subscribe(response=>{
      this.refresWidgetUrl();
    })
    this.theme = "public";
    this.version = utilService.getAppVersion();
    let date     = new Date();
    this.year    = date.getFullYear();
    this.envName = `${environment.envName}` === 'production' ? '' : `${environment.envName}`;
    this.refresWidgetUrl();
    this.activedRoute.queryParams.subscribe(params => {
      this.currentLanguage = params['language'] || utilService.getCurrentLanguage();
      this.setLanguage(this.currentLanguage)
    });
  }

  ngOnInit() {
    this.setLogo();
  }

  forgotPass(){
    this.util.ga("public","click","forgot password");
  }

  refresWidgetUrl(){
    let currentLanguage = this.util.getCurrentLanguage();
    let url = `${environment.loginWidget}`+'-'+currentLanguage;
    this.widget  = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  setLogo(error = false) {
    const urlBase = 'https://mh-statics.s3.sa-east-1.amazonaws.com/documents/Fidelity-logos/login-logo';
    this.myhotelLogoLogin = `${urlBase}.png`;
    if (error) {
      this.myhotelLogoLogin = `${urlBase}.gif`;
    }
  }

  get canRedirect() {
    return !this.sessionService.getCurrentHotelPSConfig();
  }

}
