import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EditUserService } from "../../edit-user.service";
import { Hotel,UserProfile } from "../../../../shared/model/index";


@Component({
  selector: 'mh-new-user',
  templateUrl: './new-user.component.html',
  providers:[EditUserService]
})
export class NewUser{

  @Input()
  customer:Hotel;

  user:UserProfile;

  waiting=true;
  @Output() waitingState = new EventEmitter<boolean>();
  @Output() closeModal   = new EventEmitter<boolean>();

  constructor(){}

  ngOnChanges() {
    this.waitingState.emit(this.waiting);
    this.user = new UserProfile();
  }

  setWaiting($waiting){
    this.waiting = $waiting;
    this.waitingState.emit($waiting);
  }

  setModal($state){
    this.closeModal.emit(true);
  }


}



