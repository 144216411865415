export class StoreCasesList {
  static readonly type = '[Desk] Store Cases List'
  constructor(public cases: any) {}
}

export class ChangeStatusFilter {
  static readonly type = '[Desk] Change Status Filter';
  constructor(public status: any) {}
}

export class ChangePriorityFilter {
  static readonly type = '[Desk] Change Priority Filter';
  constructor(public priority: any) {}
}

export class ChangeTeamFilter {
  static readonly type = '[Desk] Change Team Filter';
  constructor(public team: any) {}
}

export class ChangeStageFilter {
  static readonly type = '[Desk] Change Stage Filter';
  constructor(public stage: any) {}
}

export class ChangeSeenFilter {
  static readonly type = '[Desk] Change Seen Filter';
  constructor(public seen: any) {}
}

export class ChangeProductFilter { 
  static readonly type = '[Desk] Change Product Filter';
  constructor(public product: any) {}
}

export class ChangeAuthorFilter {
  static readonly type = '[Desk] Change Author Filter';
  constructor(public author: any) {}
}

export class ChangeAssignedFilter {
  static readonly type = '[Desk] Change Assigned Filter';
  constructor(public assigned: any) {}
}

export class ChangeCaseTypeFilter {
  static readonly type = '[Desk] Change Case Type Filter';
  constructor(public caseType: any) {}
}

export class ChangeAreasFilter{
  static readonly type = '[Desk] Change Areas Filter';
  constructor(public areas: any) {}
}

export class FilterClear {
  static readonly type = '[Desk] Filter Clear';
}

export class ChangeBase {
  static readonly type = '[Desk] Change Base';
  constructor(public base: any) {}
}
