import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'fidelityDeskSeen',
})
export class FidelityDeskSeenPipe implements PipeTransform {
  transform(cases, filters) {
    let filteredCases = cases;
    if (filters) {
      const filter = filters.find((filterState) => filterState.name === 'seen');
      const filtering = filter.options.some((option) => option.isFiltering);
      if (filter && filtering) {
        const options = filter.options.filter((option) => option.isFiltering).map((option) => option.viewed);
        filteredCases = cases.filter((item) => options.includes(item.viewed));
      }
    }
    return filteredCases;
  }
}
