<form [formGroup]="caseForm" class="tw-flex tw-flex-col tw-gap-3">

  <article>
    <mh-core-lib-actions-wrapper
        [slug]="skipValidation ? 'cases_cases_assigned_change' : ''"
        [message]="message"
        [skipValidation]="skipValidation"
      >
      <label class="tw-normal-case tw-text-gray-400 tw-text-base tw-font-medium tw-mb-1">{{ 'cases.table_view.assigned' | translate }}</label>
      <mh-core-lib-autocomplete-input
          [options]="isOfTeam ? usersFromTeam : users"
          [openBox]="false"
          [filterFn]="autocompleteFilterFn"
          [displayOptionsFn]="displayUsersOptions"
          [placeholder]="'cases.table_view.assigned' | translate"
          [selectedOption]="caseData.assigned"
          iconClass="fal fa-chevron-down !tw-text-gray-400"
          customClassInput="!tw-pl-3 !tw-font-[Rubik] placeholder:!tw-text-main-small tw-border-gray-300 !tw-rounded-md !tw-min-w-0"
          customClassBox="tw-absolute tw-z-10"
          customClassOptions="tw-font-[Rubik] tw-font-normal tw-text-base tw-text-black"
          (optionSelected)="handleAssignOption($event)"
          ></mh-core-lib-autocomplete-input>
    </mh-core-lib-actions-wrapper>
  </article>

  <article class="tw-mt-1">
    <label class="tw-normal-case tw-text-gray-400 tw-text-base tw-font-medium tw-mb-1">{{ 'cases.detail_modal.associated_team' | translate }}</label>
    <div class="tw-flex tw-justify-between tw-items-center tw-py-2 tw-px-3 tw-bg-white tw-text-notification-text-time tw-border tw-border-gray-300 tw-rounded-md">
      <span class="tw-text-notification-text-time">{{ caseData?.team_details?.name || '-' }}</span>
      <span class="tw-text-zinc-400"><i class="fal fa-chevron-down"></i></span>
    </div>
  </article>

  <article class="tw-mt-1">
    <label class="tw-normal-case tw-text-gray-400 tw-text-base tw-font-medium tw-mb-1">{{ 'cases.detail_modal.source' | translate }}</label>
    <div class="tw-flex tw-justify-between tw-items-center tw-py-2 tw-px-3 tw-text-notification-text-time tw-border tw-bg-white tw-border-gray-300 tw-rounded-md">
      <span class="tw-text-notification-text-time" *ngIf="caseData?.product_item; else externalCase">
        {{ 'cases.table_view.products.' + (caseData?.product_item?.product_id === 4 ? caseData?.product_item?.type?.name : caseData?.product_item?.product_id) | translate }}
      </span>
      <ng-template #externalCase>
        <span class="tw-text-notification-text-time">{{ caseData?.guest_params ? ('cases.table_view.guest' | translate) : ('cases.table_view.external' | translate) }}</span>
      </ng-template>
    </div>
  </article>

  <article>
    <mh-core-lib-actions-wrapper
        [slug]="skipValidation ? 'cases_cases_priority_change' : ''"
        [message]="message"
        [skipValidation]="skipValidation"
      >
      <label class="tw-normal-case tw-text-gray-400 tw-text-base tw-font-medium tw-mb-1">{{'core-lib.dialog.case.form.priority' | translate}}</label>
      <mh-core-lib-select-input
          [inputId]="'select-detail-case-priority'"
          [options]="priorities"
          [displayFn]="displayFnPriorities"
          [placeholder]="'core-lib.input.dialog_cases.priority' | translate"
          [keyControl]="'priority'"
          [formGroup]="caseForm"
          [selectedOption]="caseData.priority"
          [customClasses]="'!tw-font-[Rubik] tw-border-gray-300 !tw-rounded-md'"
          [iconClass]="'fal fa-chevron-down !tw-text-gray-400'"
          (optionChanged)="handlePriorityChange($event)"
          ></mh-core-lib-select-input>
    </mh-core-lib-actions-wrapper>
  </article>

  <article>
    <mh-core-lib-actions-wrapper
        [slug]="skipValidation ? 'cases_cases_type_change' : ''"
        [message]="message"
        [skipValidation]="skipValidation"
      >
      <label class="tw-normal-case tw-text-gray-400 tw-text-base tw-font-medium tw-mb-1">{{'cases.detail_modal.incident_type' | translate}}</label>
      <mh-core-lib-select-input
          [inputId]="'select-detail-case-type'"
          [options]="types"
          [displayFn]="displayFnTypes"
          [placeholder]="'cases.detail_modal.incident_type' | translate"
          [keyControl]="'case_type'"
          [formGroup]="caseForm"
          [selectedOption]="caseData.case_type"
          [customClasses]="'!tw-font-[Rubik] tw-border-gray-300 !tw-rounded-md'"
          [iconClass]="'fal fa-chevron-down !tw-text-gray-400'"
          (optionChanged)="handleUpdateCaseType()"
          ></mh-core-lib-select-input>
    </mh-core-lib-actions-wrapper>
  </article>

  <article>
    <mh-core-lib-actions-wrapper
        [slug]="skipValidation ? 'cases_cases_areas_edit' : ''"
        [message]="message"
        [skipValidation]="skipValidation"
      >
      <label class="tw-normal-case tw-text-gray-400 tw-text-base tw-font-medium tw-mb-1">{{'cases.detail_modal.areas' | translate}}</label>
      <div class="tw-relative">
        <div *ngIf="savingAreas" class="tw-absolute tw-w-full tw-flex tw-h-full tw-bottom-0 tw-bg-opacity-50 tw-bg-gray-300 tw-rounded-md">
          <mh-core-lib-loader-wrapper
            class="tw-w-full"
            [type]="'spinner'"
            [loading]="savingAreas"
            [spinnerClassName]="'tw-text-3xl'">
          </mh-core-lib-loader-wrapper>
        </div>
        <mh-core-lib-tag-input
            [options]="areas"
            [displayChipsFn]="displayChipsFn"
            [displayChipsListFn]="displayChipsFn"
            [allSelected]="false"
            [checkedAllOption]="false"
            [showAutocompleteInput]="true"
            [inputTagFilter]="inputTagFilter"
            [displayCheckInput]="false"
            [inputSelectedOptions]="selectedAreas"
            [boxOpen]="false"
            [customClasses]="'!tw-font-[Rubik] !tw-mb-0'"
            [parentCustomClass]="'tw-border-gray-300 !tw-rounded-md !tw-min-h-0'"
            (optionClicked)="handleAreasInput($event)">
        </mh-core-lib-tag-input>
      </div>
    </mh-core-lib-actions-wrapper>
  </article>

  <article>
    <mh-core-lib-actions-wrapper
        [slug]="skipValidation ? 'cases_cases_subscribed_edit' : ''"
        [message]="message"
        [skipValidation]="skipValidation"
      >
      <label class="tw-normal-case tw-text-gray-400 tw-text-base tw-font-medium tw-mb-1">{{'cases.detail_modal.followers' | translate}}</label>
      <mh-core-lib-tag-input
          [options]="usersToSubscribe"
          [displayChipsFn]="displayUsersOptions"
          [displayChipsListFn]="displayUsersOptions"
          [allSelected]="false"
          [checkedAllOption]="false"
          [showAutocompleteInput]="true"
          [inputTagFilter]="autocompleteFilterFn"
          [displayCheckInput]="false"
          [inputSelectedOptions]="subscribers"
          [inputTagFilter]="autocompleteFilterFn"
          [boxOpen]="false"
          [showCloseBtnFn]="showCloseBtnFnSubscribers"
          [customClasses]="'!tw-font-[Rubik] !tw-mb-0'"
          [parentCustomClass]="'tw-border-gray-300 !tw-rounded-md !tw-min-h-0'"
          (optionClicked)="handleSubscribersChange($event)"
          ></mh-core-lib-tag-input>
    </mh-core-lib-actions-wrapper>
  </article>
</form>
