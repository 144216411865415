import { Component } from '@angular/core';
import {UtilService} from "app/shared/util.service";
import { Hotel } from 'app/shared/model/hotel';
import { DateService } from 'app/shared/services/date.service';
import { Router } from '@angular/router';

@Component({
  selector: 'mh-chain-followup-summary',
  templateUrl: './summary-section.component.html',
  styleUrls: ['./summary-section.component.scss']
})
export class SummarySectionComponent {

  productId = 1;

  chain: Hotel[] = [];
  chainFilter: Hotel[] = [];
  isReady = false;
  faqs = (new Array(2).fill(0));
  prefixTranslateFAQ = 'inter-chain.followup.summary.faq';

  constructor(
    private utilService: UtilService,
    private dateService: DateService,
    private router: Router
  ){

  }

  ngOnInit(): void {
    this.getProductById();
    this.utilService.setGeneralFade(false);
    this.chain = this.utilService.getChain(this.utilService.getProductById(this.productId));
  }

  getProductById() {
    this.productId = this.router.url.includes('followup') ? 1 : 2;
  }

  setChain(chain: Hotel[]){    
    this.dataIsReady(chain.length !== 0);
    this.chainFilter = chain;
  }

  dataIsReady(state){
    setTimeout(_ => {
      this.isReady = state;
    },200);
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates)
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }

  get fidelityPermissions(){
    return this.utilService.getFidelityConfigurationsByProduct('interchain','interchain_followup') || [];
  }

}
