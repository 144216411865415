import { Component, OnDestroy, OnInit } from '@angular/core';
import {UtilService} from "../shared/util.service";
import {EmailsService} from '../_services/emails.service';
import { HotelDto, Language } from 'app/shared/model';
import { plainToClass } from 'class-transformer';
import { SupportService } from './support.service';
import { ProductsService } from '../../app/_services/products.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AdminCustomerService } from 'app/admin/customers/admin-customer.service';
import {TranslateService} from '@ngx-translate/core';
import { HotelService } from 'app/shared/hotel/hotel.service';
import { Subscription } from 'rxjs';

export interface FormResponse{
  id:number;
  translate:TranslateResponse[];
}

export interface TranslateResponse{
 language:Language;
 option:string;
}

@Component({
  selector: 'mh-support',
  templateUrl: './support.component.html',
  styleUrls: ['../../assets/scss/support/support.scss'],
  providers:[EmailsService,HotelService,SupportService,AdminCustomerService]
})
export class SupportComponent implements OnInit, OnDestroy {

  currentUser;
  currentHotel;
  currentHotelDto;
  currentProduct;
  questionOptions:FormResponse[] = [];
  troubles:FormResponse[] = [];
  inconveniences:FormResponse[] = [];
  productsFull: any = [];
  products:string[] = [];
  allOtas:string[] = [];
  options: any = [];
  attachment: any = [];

  waiting = true;
  send_success = false;
  keys = [
    'question',
    'product',
    'otas',
    'items',
    'period',
    'inconveniences',
    'troubles',
    'details'
  ];
  controlForm:FormGroup;
  sendingData = false;
  subscription: Subscription;

  constructor(
     private utilService:UtilService,
     private emailsService:EmailsService,
     private hotelService:HotelService,
     private productsService: ProductsService,
     private adminCustomerService: AdminCustomerService,
     private supportService: SupportService,
     private translate: TranslateService,
     private fb: FormBuilder) {
       this.currentUser  = this.utilService.getCurrentUser();
       this.currentHotel = this.utilService.getCurrentHotel();
       this.utilService.isCustomerChanged().subscribe(response=>{
         this.init();
        })
  }
  ngOnInit() {

    this.init();


  }

  init(){
    this.loadInfo();
    this.setProductsList();
    this.adminCustomerService.getOtas(this.utilService.currentHotel.id).subscribe((response:any)=>{
      response.forEach(element => {
        if(element.active)
          this.allOtas.push(element.name);
      });
    });
    this.setQuestionsList();
    this.setInconveniencesList();
    this.setTroublesList();
  }

  setProductsList(){
    this.products = [];
    this.productsFull = this.productsService.getNavbarItems();
    this.productsFull.forEach(product => {
      this.products.push(product.product);
    });
    this.products = this.products.filter(x=>!['support','admin_manager'].includes(x));
  }

  setQuestionsList() {
    this.supportService.getProblemsList().subscribe((response:any) => {
      this.questionOptions = response.questionsOptions;
    });
  }

  setInconveniencesList() {
    this.supportService.getInconveniencesList().subscribe((response:any) => {
      this.inconveniences = response.inconveniencesOptions;
    });
  }

  setTroublesList() {
    this.supportService.getTroublesList().subscribe((response:any) => {
      this.troubles = response.troublesOptions;
    });
  }

  updateOption() {
    if(this.option===0)
      this.options = this.allOtas;
    if([1,2,3].includes(this.option))
      this.options = this.products;
    if(this.option===4)
      this.options = this.inconveniences;
    if(this.option===6)
      this.options = this.troubles;
  }

  setCurrentProduct(product) {
    this.currentProduct = product;
  }

  setAttachment(attachment){
    let base_64 = attachment.split(',')[1];
    let content_type = attachment.split(',')[0].split(';')[0].slice(5);
    let filename = `${new Date().getTime()}.${content_type.split('/')[1]}`
    this.attachment = this.attachment.concat([{
      "content_type": content_type,
      "filename": filename,
      "base_64": base_64
    }]);
  }

  createForm(question=null){
    this.controlForm = this.fb.group({
      question:         [question, [Validators.required]],
      product:          null,
      otas:             null,
      items:            null,
      period:           null,
      inconveniences:   null,
      troubles:         null,
      details:          [null, [Validators.required]],
      //captures:       [null, [Validators.required]]
      user:             `${this.currentUser.name} ${this.currentUser.lastName}`,
      email:            this.currentUser.email,
      user_phone:       this.currentUser.profile.phone,
      position_person:  this.currentUser.profile.company_position,
      hotel:            this.currentHotel.id,
      status_licence:   this.currentHotelDto.licence_type,
      client_type:      this.currentHotelDto.property_type.description,
      chain_name:       this.currentHotelDto.chain_property ? this.currentHotelDto.chain_property.brand : '-',
      city:             this.currentHotelDto.city.name,
      country_code:     this.currentHotelDto.city.country_code,
      language:         this.currentHotelDto.language.code
    });

    this.subscription = this.controlForm.get('question')?.valueChanges.subscribe(value => {
      this.clearForm();
      this.setValidators(parseInt(value));
    });
  }
  setValidators(value) {
    this.keys.forEach(element => {
      this.controlForm.get(element)?.clearValidators();
    });
    switch(value) {
      case 0:
        this.setValidator(['otas', 'details']);
        break;
      case 1:
      case 2:
        this.setValidator(['product', 'details']);
        break;
      case 3:
        this.setValidator(['product', 'items', 'period', 'details']);
        break;
      case 4:
        this.setValidator(['inconveniences', 'details']);
        break;
      case 6:
        this.setValidator(['product', 'troubles','details']);
        break;
      case 5:
      case 7:
        this.setValidator(['details']);
        break;
    }
  }

  setValidator(array:string[]) {
    this.keys.forEach(element => {
      if(array.includes(element))
        this.controlForm.get(element)?.setValidators([Validators.required])
      else
        this.controlForm.controls[element].setErrors(null)
    })
  }

  clearForm() {
    this.keys.forEach(key=>{
      if(key!=='question') {
        this.controlForm.get(key)?.setValue(null, {emitEvent: false})
      }
    })
  }

  sendData(){
    this.sendingData = true;
    const EMAIL_TO = 'support@myhotel.zendesk.com';
    const FROM_EMAIL = this.createFromEmail();
    const REPLY_TO = this.currentUser.email;
    let result = "";
    let keys = Object.keys(this.controlForm.value);
    keys.forEach(key => {
      if(this.controlForm.value[key])
        if(key==="question") {
          result += `<b>${this.translateKey(key)}</b>: ${this.questionOptions[parseInt(this.controlForm.value[key])].translate[0].option}<br>`;
        } else {
          if((['product','items'].includes(key)))
            result += `<b>${this.translateKey(key)}</b>: ${this.translateKey(this.controlForm.value[key],true)}<br>`;
          else if((['troubles','inconveniences'].includes(key)))
            result += `<b>${this.translateKey(key)}</b>: ${this.controlForm.value[key].translate[0].option}<br>`;
          else
            result += `<b>${this.translateKey(key)}</b>: ${this.controlForm.value[key]}<br>`;
        }
    });
    this.emailsService.send(EMAIL_TO,'Nuevo Ticket FS | '+this.currentHotel.name,'Ticket de soporte | '+this.currentHotel.name,result, FROM_EMAIL, REPLY_TO,this.attachment).subscribe(response=>{
      this.sendingData = false;
      this.send_success = true;
    });
  }

  createFromEmail() {
    const email = this.currentUser.userName.split('@');
    return `${email[0]}_${this.currentHotel.id}@myhotel.cl`;
  }

  translateKey(key, products=false){
    let translate:any;
    let type = (this.isRetail()) ? 'retail' : 'default';
    if(!products)
      this.translate.get(`support.${key}`).subscribe((result) => {
        translate = result;
      });
    else
      this.translate.get(`navbar.product.${type}.${key}`).subscribe((result) => {
        translate = result;
      });
    return translate;
  }

  loadInfo(){
    this.waiting = true;
    this.utilService.setGeneralFade(true);
    this.hotelService.getHotel(this.currentHotel.id).subscribe(response=>{
      this.currentHotel = this.utilService.getCurrentHotel();
      this.currentHotelDto = plainToClass<HotelDto, Object>(HotelDto, response);
      this.utilService.setGeneralFade(false);
      this.createForm();
      this.waiting = false;
    });
  }

  isRetail() {
    return this.utilService.customerIsRetail;
  }

  get language(){
    return this.utilService.getCurrentLanguage();
  }

  get option(){
    return this.controlForm.value.question;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
